'use strict';

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Campo di tipo entity su entity esterna
 * 
 * @class  
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BssStatiOrdine(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssstatiordine', 
        //extends: 'bssinput',
        template: require("./bssstatiordine.html"),
        controller: ["$scope", "$rootScope", "$filter", "$timeout", "$http", "$state", "ErrorManager", "DSFactory", require("./BssStatiOrdineController")],
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssstatiordine'
            },
        }
    }));
}

module.exports = BssStatiOrdine;