'use strict';

/**
 * Component relativo all'azione
 * 
 * @class
 * @example 
 * <toolbar-action disabilita="ac.disabilita" azione="ac.azione" icona="ac.icona"></toolbar-action>
 */
function ActionCmp(){
    this.bindings = {
        azione: "=",
        icona: "=",
        disabilita: "=",
        descrizione: "=",
        mostra: "=",
        nascondi: "=",
        combokey: "=",
        identificativo: "="
    };
    this.template = require("../templates/action.html");
    this.controller = "ActionCtrl as ac";
}

module.exports = ActionCmp;