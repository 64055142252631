"use strict";

/**
 * Factory per la creazione delle opzioni di kendo window
 * 
 * @class 
 * @example
 * new FinestreCfgFactory({title: 'Finestra di Prova'}); //ritorna {opzioni: {title: 'Finestra di Prova', ...}}
 */
function FinestreCfgFactory(){
    function Costruttore(opzioni){
        this.opzioniBase = {
            actions: ['Refresh', 'Minimize', 'Maximize', 'Close'],
            appendTo: document.body, //non vincola il trascinamento all'elemento specificato nell'elemento padre
            autoFocus: true, //all'apertura porta in primo piano
//            content: { //OBBLIGATORIO potrebbe anche essere una semplice stringa
//                url: null,
//                dataType: "json",
//                iframe: false,
//                template: null,
//            },
            draggable: true,
            //maxHeight: 800,
            //maxWidth:  800,
            //minHeight: 800,
            //minWidth:  800,
            width: 800,
            height: 800,
            modal: true,
            pinned: false, //false non si muove con la pagina
            position: {
                top: 200,
                left: '50%',
            },
            resizable: true,
            title: '', //Obbligatorio
            visible: false,
            /*
            per eventi e metodi: http://docs.telerik.com/kendo-ui/api/javascript/ui/window
            */
        };
        
        this.opzioni = angular.extend({}, this.opzioniBase, opzioni);
    }
    
    return Costruttore;
}

module.exports = FinestreCfgFactory;