'use strict';

/**
 * Factory che genera un campo del form fatto con formly
 * 
 * @class
 * @param {Valida} Valida
 */
function FieldFactory(Valida){
    
    /**
     * arrToIdLabel - converte un array di stringhe in un array di oggetti
     * Ogni oggetto conterrà le property id e label equivalenti alla stringa
     * originale
     * @private
     * @memberOf FieldFactory
     * @param  {Array} arr description
     * @return {Array<Objects>}     ritorna un nuovo array non tocca l'originale
     */
    function arrToIdLabel(arr, campoValue, campoLabel){
        var newArr = [];
        for(var x = 0; x < arr.length; x++){
            var newObj = {};
            newObj[campoValue || 'id'] = arr[x];
            newObj[campoLabel || 'label'] = arr[x];
            newArr.push(newObj);
        }
        return newArr;
    }
    
    /**
     * 
     * @memberOf FieldFactory
     * @constructor
     * @param {Object} campoCfg //es. FieldService.cfgCampoDaGrid() quindi {key, tipo, label, placeholder, colonna, modello, defaultValue}
     * @param {Boolean} readOnly
     * @param {Object} opzioni //possibilità di aggiungere o sovrascrivere qualunque proprietà del campo
     */
    function Costruttore(campoCfg, readOnly, opzioni){
        
        
        
        this.key = campoCfg.key;
        this.tipo = campoCfg.tipo;
        this.label = campoCfg.label;
        this.defaultValue = campoCfg.defaultValue;
        this.placeholder = campoCfg.placeholder || "Inserisci " + campoCfg.label;        
        this.colonna = campoCfg.colonna;
        this.modello = campoCfg.modello;
        this.validators = {};
        this.asyncValidators = {};
        
        this.opzioni ={};

        // if(this.tipo === "templateasis"){
        //     this.opzioni = {template: campoCfg.template}
        //     return
        // }
        
        if(campoCfg.id){
            this.opzioni.id = campoCfg.id;
        }
        
        for(var i in campoCfg.modello.validations){
            if(Valida.sync[i]){
                this.validators[i] = Valida.sync[i] ;
            }
            if(Valida.async[i]){
                this.asyncValidators[i] = Valida.async[i];
            }
            
            if(!Valida.sync[i] && !Valida.async[i]){
                console.warn(i + ": regola di validazione da definire");
            }
            
            if(['confirm', 'required_without', 'required_without_all'].indexOf(i) !== -1){
                this.opzioni.extras = {validateOnModelChange: true};
            }
        }
                        
        this.readOnly = readOnly;
        
        var to = {optionsAttr: "bs-options"};
        to.label = this.label;
        if( ['bsslaum', 'bssmultiselect'].indexOf(this.tipo) === -1){            
            to.placeholder = this.placeholder;
        }
        else {
            to.placeholder = "'" + this.placeholder + "'";

        }        
        
        this.opzioni = $.extend(true, {}, this.opzioni, {
            key: this.key,
            type: this.tipo,
            templateOptions: to,
            data: {
                readOnly: this.readOnly,
                colonna: this.colonna,
                modello: this.modello,
            },
            validators: this.validators,
            asyncValidators: this.asyncValidators
        }, opzioni);
        
        if(this.modello.xtype === 'integer'){
            this.opzioni.ngModelElAttrs = {"num-fract": "0", "num-fixed": "false"};
        }

        if(this.modello.xtype === 'coords'){
            this.opzioni.ngModelElAttrs = {"num-fract": "6"};
        }

        
        if(this.colonna.values){
            
            //copio i valori predefiniti per la colonna come opzioni della select
            this.opzioni.templateOptions.options = this.colonna.values;
            
            //ottengo le proprietà da usare rispettivamente come valore e label
            if(typeof this.modello.data !== 'undefined'){//prendo dalla cfg del form                
                var campoValue = this.modello.data.valueProp || 'value';
                var campoLabel = this.modello.data.labelProp || 'text';
            }
            else {//uso quelli di default
                var campoValue = 'value';
                var campoLabel = 'text';
            }
            
            //per ogni opzione se non è un oggetto lo trasformo in oggetto
            _.map(this.opzioni.templateOptions.options, function(opzione){
                if(_.isString(opzione)){
                    var obj = {};
                    obj[campoLabel] = opzione;
                    obj[campoValue] = opzione;
                    opzione = obj;
                }
                return opzione;
            });
        }
        
        //console.log("opzioni del campo: ", this.opzioni);
    }
    
    return Costruttore;
}

module.exports = FieldFactory;