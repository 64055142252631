'use strict';

/**
 * Fornisce le rotte di base
 * 
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 * @returns {undefined}
 */
function Routes($stateProvider, $urlRouterProvider, $locationProvider){
    var self = this;
    
    //rimuove #dall'url importante definire base nel file index.php (preso da env.php)
    $locationProvider.html5Mode(true);
    
    //$urlRouterProvider.otherwise("/login");
    $urlRouterProvider
        .otherwise(function($injector, $location){        
            var state = $injector.get("$state");
            console.log("route non trovata", $location, state)
            state.go("login");
            return $location.path();
        })


    $stateProvider
        .state('home', {
            url: "/home",
            views: {
                "maincontent.custommodule" : {
                    template: require("../../templates/home.html"),   
                    controller: ['$state', '$timeout', function($state, $timeout){
                        //se esiste lo state homecustom faccio il redirect
                        $timeout(function(){
                            var hcstate = $state.get("homecustom");
                            if(hcstate !== null){
                                return $state.go(hcstate, {}, {reload: true})
                            }
                        })
                        
                    }]                
                },
            },
            middleware: ['loggato']
        })
        .state('nonTrovata', {
            url: "/non-trovata",
            views: {
                "maincontent.notfound" : {
                    template: require("../../templates/notfound.html"),      
                    controller: function(){
                        console.log("pagina non trovata");
                    }
                },
            },
            middleware: ['loggato']
        })
        ;
}

module.exports = Routes;