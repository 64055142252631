'use strict';

var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

/**
 * Controller relativo al component ToolbarCmp
 * 
 * @class
 * @param {ToolbarSrv} ToolbarS
 */
function ToolbarCtrl(ToolbarS, SideMenuSrv, $scope, $sce, $state, $stateParams, ActFct, $http, $q, $timeout, recorderService){
    angular.extend(this, EsponiMixin.prototype);
    var self = this;
    this.rs = recorderService;
    this.ts = ToolbarS;
    this.$state = $state;  
    this.$sp = $stateParams;
    this.$http = $http;
    this.$q = $q;
    this.$timeout = $timeout
    this.sce = $sce;
    this.sm = SideMenuSrv;

    $( document ).ajaxStop(function() {
        console.log("disabilitatutto tutte le richieste ajax fatte da kendo sono finite")
        self.ts.disabilitatutto = 0
    });

    this.uscita = new ActFct("fa fa-times",  this.esponi({metodo: "inFrame", parametri: []}), this.esponi({metodo: "esci", parametri: []}), 0, null, "TOOLBAR.ACTIONUSCITA", "alt+shift+esc");
    this.aiuto = new ActFct("fa fa-question",  this.esponi({metodo: "inFrame", parametri: []}), this.esponi({metodo: "guida", parametri: []}), 0, null, "TOOLBAR.ACTIONAIUTO", "alt+shift+f1");
    this.cmdvocali = new ActFct("fas fa-microphone-alt", this.esponi({metodo: "recorderAvailable", parametri: []}), this.esponi({metodo: "comandiVocali", parametri: []}), 0, null, "TOOLBAR.ACTIONCMDVOCAL", "alt+shift+v", null, "comandivocalitoolbar")
    this.preferiti = new ActFct("fas fa-star", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), null/*self.esponi({metodo: "salvaCfgUtente", parametri: []})*/, 10, null, "TOOLBAR.PREFERITI", null, [
        //new ActFct("fa fa-save", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "salvaCfgUtente", parametri: []}), null, null, "GRIDNAV.SALVACFG", "alt+shift+c"),
        {special: "preferiti", }
    ]),


    this.cmdStd  = [
        this.uscita,
        this.aiuto,
        this.preferiti
        //this.cmdvocali
    ];

    
}

ToolbarCtrl.prototype.esci = esci;
ToolbarCtrl.prototype.inFrame = inFrame;
ToolbarCtrl.prototype.recorderAvailable = recorderAvailable;
ToolbarCtrl.prototype.mostraComunque = mostraComunque;
ToolbarCtrl.prototype.nascondi = nascondi;
ToolbarCtrl.prototype.guida = guida;
ToolbarCtrl.prototype.comandiVocali = comandiVocali;

function recorderAvailable() {
    var self = this;
    return self.inFrame() || ! self.rs.isAvailable();// || ! self.rs.isReady;
}

//vedi https://assistenzabss@bitbucket.org/assistenzabss/dialogflowtest.git
function comandiVocali(){
    var self = this;
    self.$timeout(function(){
        var data = {
            audio: self.ts.recordedInput,
            state: self.$state.current.name,
            url: self.$state.current.url,
            params: self.$state.params,                            
        }

        self.$http({
            method: "POST",
            url: "https://bssgest.svi:5443/api/v1/basicai",
            headers: {'Content-Type': undefined},
            data: data,
            transformRequest: function(data, headerGetter){
                var formData = new FormData();
                angular.forEach(data, function(value, key){
                    formData.append(key, value)
                });
                return formData;
            }
        }).then(function(resp){
            console.log("resp", resp)
            var intent = resp.data.intent;
            
            if(resp.data.audio){
                var snd = new Audio(resp.data.audio);
                snd.play();
                snd.addEventListener("ended", function(){
                    snd.currentTime = 0;
                    gestisciintent();
                })
            }
            else {
                gestisciintent();
            }

            function gestisciintent(){

                if(intent === 'modulo'){
                    var mappamoduli = {
                        "modalità pagamento": "modalitapagamenti",
                        "modalità pagamenti": "modalitapagamenti",
                    }
        
                    if(typeof resp.data.parameters === 'object'){
                        var parametri = resp.data.parameters;
                        var campi  = parametri.fields;
                        if(typeof campi.modulo !== "undefined" ){
                            var modulo = campi.modulo[campi.modulo.kind];
                            var basepath = mappamoduli[modulo];
                            self.$state.go(basepath)
                        }
                    }
                }
            }

            
        })
    })
    
}

function nascondi(){
    var self = this;
    //per nascondere del tutto la toolbar devo    
    //(iniframe && (nessun action provider || (action provider && tutti vuoti) ))  
    
    
    var esito = !(self.mostraComunque()) && (self.inFrame() && (!self.ts.actionsProviders.length));
    return esito;
}

function inFrame(){
    var esito = (this.$sp.view && this.$sp.view === 'iframe');
    return esito;
}

function mostraComunque(){
    var esito = (this.$sp.forceToolBar && this.$sp.forceToolBar === 'true');
    return esito;
}

function esci(){
    console.log("parametri di stato: ", this.$sp);
    return this.$state.go("home", null, {reload: true});
}

function guida(){
    var self = this;
    var parametri = JSON.stringify(self.$state.params)

    /**
     * al momento gestisco solo guide che si trovano sotto guide/{nomestato|codmodulo}
     */
    var stato = self.$state.params.codmodulo ? self.$state.params.codmodulo : self.$state.current.name;
    
    var phppath = "guide/"+utils.ambiente+"/"+stato;

    var src = ""+phppath;
    var srcdef = "guide/404/index.php"
    //console.log(stato, parametri)


    self.$http({
        method: 'GET',
        url: "guide/checkguide.php",
        params: {path: phppath}
    }).then(function(resp){
        console.log("ok reperimento guida!", resp)
        var contenuto = "<iframe width='100%' height='1800' frameborder='0' src='"+src+"' />";
        self.testoguida = self.sce.trustAsHtml(contenuto);
        self.helpWin.open().center();
    }, function(resp){
        console.log("errore reperimento guida!", resp)
        var contenuto = "<iframe width='100%' height='1800' frameborder='0' src='"+srcdef+"' />";
        self.testoguida = self.sce.trustAsHtml(contenuto);
        self.helpWin.open().center();
    })

    

    /*
    var metodoGuida = "overrideGuida_"+stato;
    
    var parametri = {stato: stato+"()", parametri: JSON.stringify(self.$state.params)};

    //console.log("stato da guida in toolbar", metodoGuida, stato, self.ts, self.ts[metodoGuida]);

    var guidaProvider = self.ts[metodoGuida] ? self.ts[metodoGuida]() : self.$http({method: 'POST', params: parametri, url: utils.urls.wsbase + '/guidadastato/guidamodulo'});
    
    guidaProvider
    //self.$q.when("fake")
    .then(function(resp){
        self.testoguida = self.sce.trustAsHtml(resp.data || "lorem ipsum");
        self.helpWin.open().center();
    }, function(err){
        self.aiuto.$translate("TOOLBAR.GUIDANONDISPONIBILE", []).then(function(_defaultMessage){
            self.testoguida = "Guida non disponibile per questo modulo";//prendere da traduzioni
            self.helpWin.open().center();
        });
    });
    */
}

module.exports = ToolbarCtrl;