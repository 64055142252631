"use strict";

/**
 * Classe che configura il servizio di traduzione
 * @class
 * @param {$translateProvider} $tp
 */

require("./jsontraduzioni.js")

function GlobalTranslateCfg($tp){
    
    
    for(var lingua in bssjsontraduzioni){
        $tp.translations(lingua, bssjsontraduzioni[lingua])
    }
    
    $tp
        //.useLoader('$translatePartialLoader', {urlTemplate: '{part}{lang}.json'})
        .useSanitizeValueStrategy('escapeParameters')
        .preferredLanguage("it")
        .fallbackLanguage(["it"])
        .useLocalStorage();
    ;
           
}

module.exports = GlobalTranslateCfg;