'use strict';

function StateChangeManager($rootScope, $q) {
    var self = this;
    
    self.request = function (config) {

        if (!config.timeout) {
            config.cancel = $q.defer();
            config.timeout = config.cancel.promise;
        }
        return config;
        
        
    };
    
    self.responseError = function (response) {
        if(response.config !== undefined){
            if (response.config.timeout.$$state.value === 'Cancel!') {
                return $q.when('bypassed');
            }            
        }
        return $q.reject(response);
    };
    
    return self;
}

module.exports = StateChangeManager;