
'use strict';

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/traduzioni/scripts/index.js");
require("moduleFold/validatori/scripts/index.js");
require("tinycolor2");
require("angularjs-color-picker");
require("../styles/validation.scss");
require("../styles/bssfile.scss");
require("../styles/bsscombo.scss");
require("../styles/bssinputtags.scss");
require("../styles/bsssingleselect.scss");
require("../styles/templateasis.scss");
require("../styles/bssmultiselect.scss");
require("../styles/bsstab.scss");
require("../styles/autosave.scss");
require("../styles/style.scss");
require("../styles/bsslegendwrapper.scss");



/**
 * @module bss/formbuilder
 * @description
 * Permette di generare i form 
 * 
 */
var bssformbuilder = angular.module("bss.formbuilder", [
        'ngMessages',
        "angularFileUpload",
        'formly',
        'formlyBootstrap',
        'jsonFormatter',
        'ngSanitize',
        'ui.select',
        'ui.bootstrap',
        'ui-iconpicker',
        'ngMask',
        'angularMoment',
        'cfp.hotkeys',
        'underscore',
        'kendo.directives',
        "checklist-model",
        "datetime",
        "dynamicNumber",
        "ng.jsoneditor",
        "angularTrix",
        "pascalprecht.translate",
        "ngFileUpload",
        "color.picker",
        "bss.notifiche",
        "bss.toolbar",
        "bss.traduzioni",
        "bss.validatori",
         ])
    
    //configurazioni di base
    .run(["formlyConfig", "formlyValidationMessages", require("./cfgs/BlockChromeAutocomplete")])
    .run(["formlyConfig", "formlyValidationMessages", require("./cfgs/ValidatePolicy")])
    
    //wrapper
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssBaseWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssLegendWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssErrorWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssReadOnlyWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssLabelWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssTabWrapper")])
    .run(["formlyConfig", "formlyValidationMessages", require("./wrappers/BssFormPanelWrapper")])

    //tipi di campo
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/input/BssInput")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/iconpicker/BssIconpicker")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/booleancheckbox/BssBooleanCheckbox")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/combo/BssCombo")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/datetime/BssDateTime")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/date/BssDate")])    
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/file/BssFile")])    
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/file2/BssFile2")])    
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/syncfile/BssSyncfile")])    
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/jsoneditor/BssJsonEditor") ])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/numeric/BssNumeric")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/plaintext/BssPlainText")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/select/BssSelect")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/textarea/BssTextarea")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/time/BssTime")])    
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/wysiwyg/BssWysiwyg")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/password/BssPassword")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/multiselect/BssMultiselect")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/singleselect/BssSingleselect")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/localita/BssLocalita")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/multilocalita/BssMultilocalita")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/treeviewcheckbox/BssTreeviewcheckbox")])
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/placeautocomplete/BssPlaceautocomplete")])   
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/tags/BssTags")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/localizedstring/BssLocalizedString")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/zoom/BssZoom")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/calendario/BssCalendario")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/rangedate/BssRangedate")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/laum/BssLaum")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/colorpicker/BssColorpicker")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/statiordine/BssStatiOrdine")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/mappastatitracking/BssMappaStatiTracking")])          
    .run(["formlyConfig", "formlyValidationMessages", "Valida", require("./campi/templateasis/TemplateAsIs")])          
    

    //direttive che aggiungono comportamenti particolari ai diversi tipi di campo
    //ngmodel per campi di tipo file
    .directive("fileread", require("./campi/file/FileReadDir"))
    .directive("filesyncread", ["$timeout", require("./campi/syncfile/FileSyncReadDir")])

    //alla pressione del tasto invio esegue un azione
    .directive("onEnter", require("./navigation/OnEnterDir"))


    //alla pressione del tasto invio si deve comportare come alla pressione del tasto tab
    .directive("enterAsTab", require("./navigation/EnterAsTabDir"))

    //annulla il tab se il campo è invalido
    .directive("undoTab", require("./navigation/UndoTabDir"))
    
    //implementa salvataggio automatico all'uscita/digitazione
    .directive("bssautosave", ["$q", "$timeout", "$http", "ErrorManager", require("./AutoSave")])
    
    //nei form raggruppati in tab al submit se ci sono errori evidenzia il tab che contiene campi con errori
    .directive("formTabHasError", require("./FormTabHasError"))

    //converte un valore numerico osservato in parole
    .directive("bssn2wobs", ["$http", require("./N2WDirective")])    
    
    //factory di campi del form
    .factory("FieldFactory", ["Valida", require("./FieldFactory")])
    
    //servizio che fa da ponte tra kendo grid e un campo 
    .service("FieldService", [require("./FieldService")])
    
    //factory che fa da ponte tra kendo grid e un intero form
    .service("FormDaGridFactory", ["ActionFactory", "FieldFactory", "FieldService", "ToolbarSrv", "ErrorManager", require("./FormDaGridFactory")])
    .factory("FormDaHeaderFactory", ["ActionFactory", "FieldFactory", "FieldService", "ToolbarSrv", "ErrorManager", require("./FormDaHeaderFactory")])
;


module.exports = bssformbuilder;