'use strict';

require("../styles/toolbar.scss");
require("moduleFold/traduzioni/scripts/index.js");


var ToolbarCmpI = require("./ToolbarCmp");
var ActionCmpI = require("./ActionCmp");
var ToggleActionCmpI = require("./ToggleActionCmp");
var DropdownActionsCmpI = require("./DropdownActionsCmp");

/**
 * @module bss/toolbar
 * @description Modulo che genera la toolbar
 */
var bsstoolbar = angular.module("bss.toolbar", ["bss.traduzioni", "ui-notification"])
    .factory("ActionFactory", ["$translate", "hotkeys", require("./ActionFactory")])
    .factory("ToggleActionFactory", ["$translate", "hotkeys", require("./ToggleActionFactory")])
    .service("ToolbarSrv", ["$rootScope", "$timeout", "$http", "$interval", "$state", "Notification", "Bloccaschermo", "LoginService", "SideMenuSrv", require("./ToolbarSrv")])
    .controller("ToolbarCtrl", ["ToolbarSrv", "SideMenuSrv", "$scope", "$sce", "$state", "$stateParams", "ActionFactory", "$http", "$q", "$timeout", "recorderService", require("./ToolbarCtrl")])
    .controller("ActionCtrl", ["hotkeys", "ToolbarSrv", require("./ActionCtrl")])
    .controller("ToggleActionCtrl", ["hotkeys", "ToolbarSrv", require("./ToggleActionCtrl")])
    .component("bssToolbar", new ToolbarCmpI())
    .component("toolbarAction", new ActionCmpI())
    .component("toolbarToggleAction", new ToggleActionCmpI())
    .component("toolbarDropdownActions", new DropdownActionsCmpI())

;

module.exports = bsstoolbar;