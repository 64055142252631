"use strict";

/**
 * Tutte le classi che estendono EsponiMixin sono in grado di esporre alcuni metodi
 * es. possibile esporre delle action alla toolbar
 * 
 * @class
 * 
 * @example
 * angular.extend(this, EsponiMixin.prototype, AltroMixin.prototype);
 * var azione = this.esponi({metodo: 'altrometodo', parametri: ['foo', 'bar', 'baz']); //usato per aggiungere azioni alla toolbar
 * 
 */
function EsponiMixin(){}

EsponiMixin.prototype.esponi = esponi;


/**
 * Espone un metodo della classe con alcuni parametri
 * 
 * @memberOf EsponiMixin.prototype {metodo: '', parametri: []}
 * @param {Object} esponibile
 * @returns {Function}
 */
function esponi(esponibile){
    var self = this;
    return function(){        
        if(self[esponibile.metodo] !== undefined){
            return self[esponibile.metodo].apply(self, esponibile.parametri);
        }        
    }        
}

module.exports = EsponiMixin;