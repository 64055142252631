'use strict';

require("../styles/compatibility.scss");

/**
 * 
 * @example
 * <compatibility idarticolo='1' /> 
 * 
 */
function Compatibility(){
    return {
        restrict: 'E',
        template: require("./templates/compatibility.html"),
        scope: {
            idarticolo: '=',
            catstat: '=?'
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", function(s, $http, $q, $timeout, ErrorManager){      
            var self = this;
            self.moment = moment;
            self.compatibilityList = [];

            s.$watch(function(){
                return s.idarticolo
            }, function(n, o){
                console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.caricamento = true;
                    console.log("idarticolo cambiato reinizializzo", s.idarticolo);

                    self.inizializza().then(function(listacomp){
                        self.caricamento = false;
                    })
                }
            })                                            
            self.inizializza = function(){
                console.log("inizializzo maschera compatibilità", s)
                self.idarticolo = s.idarticolo;
                self.loading = true;
                self.catstat = s.catstat;

                return $q.all([
                    $http({
                        method: "GET",
                        url: utils.urls.wsbase + "/tktypeauto/fromproduct/" + self.idarticolo,
                    }),
                    $http({
                        method: "POST",
                        url: utils.urls.wsbase + "/articolirdc/compatibility",
                        data: $.param({id: self.idarticolo})
                    })
                ]).then(function(resps){

                    self.ktypesList = resps[0].data;
                    self.compatibilityList = resps[1].data;
                    self.loading = false;
                    return resps;
                })
                
                // if(s.catstat.ktype){
                //     return $http({
                //         method: "GET",
                //         url: utils.urls.wsbase + "/tktypeauto/fromproduct/" + self.idarticolo,
                //     }).then(function(resp){
                //         self.ktypesList = resp.data;
                //         return resp.data
                //     })
                // } else {
                //     return $http({
                //         method: "POST",
                //         url: utils.urls.wsbase + "/articolirdc/compatibility",
                //         data: $.param({id: self.idarticolo})
                //     }).then(function(resp){
                //         self.compatibilityList = resp.data;
                //         return resp.data
                //     })
                // }
                
            }            
            
        }],
        controllerAs: "coliCtrl"
    };
}

module.exports = Compatibility;