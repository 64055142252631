'use strict';

function PermessiModuliManager($http, $q){
    this.$http = $http;
    this.$q = $q;
}

PermessiModuliManager.prototype.calcolaParametriAggiornamento = calcolaParametriAggiornamento;
PermessiModuliManager.prototype.aggiornaPermessi = aggiornaPermessi;
PermessiModuliManager.prototype.rimuoviEccezioniGruppo = rimuoviEccezioniGruppo;
PermessiModuliManager.prototype.rimuoviEccezioneUtente = rimuoviEccezioneUtente;
PermessiModuliManager.prototype.revocaEccezione = revocaEccezione;
PermessiModuliManager.prototype.revocaEccezioneMenuUtente = revocaEccezioneMenuUtente;

function calcolaParametriAggiornamento(dataItem){
    var self = this;
    
    console.log("aggiornapermessi: ", dataItem);
    
    var parametri = {
        idmodulo: dataItem.id,
    };
    
    var livello = dataItem.idgruppo ? 'idgruppo' : 'idutente';
    parametri[livello] = dataItem[livello];
    
    var availableOps = ['inserisci', 'modifica', 'leggi', 'stampa', 'cancella'];
    
    var mappa = {
        inserisci: 'create',
        modifica: 'update',
        leggi: 'read',
        cancella: 'delete',
        stampa: 'print'
    };
    
    var opIta = _.filter(availableOps, function(op){
        return dataItem[op];
    });
    
    parametri.operazioni = _.map(opIta, function(op){
        return mappa[op];
    }).join(",");
    
    console.log("aggiorna permessi: ", parametri);
    
    return parametri;
}

function aggiornaPermessi(dataItem){
    var self = this;
    
    var parametri = self.calcolaParametriAggiornamento(dataItem);
    
    return self.$http({
        url: utils.urls.wsbase + "/permissionmanager/permessimodulo",
        method: 'POST',
        data: $.param(parametri)        
    });
}

function rimuoviEccezioniGruppo(dataItem){
    var self = this;
    
    var parametri = self.calcolaParametriAggiornamento(dataItem);
    delete parametri.operazioni;
    console.log("parametri rimuovi eccezioni gruppo: ", parametri);
    return self.$http({
        url: utils.urls.wsbase + "/permissionmanager/revocaEccezioni",
        method: 'POST',
        data: $.param(parametri)        
    });
}

function rimuoviEccezioneUtente(dataItem){
    var self = this;
    
    var parametri = self.calcolaParametriAggiornamento(dataItem);
    delete parametri.operazioni;
    console.log("parametri rimuovi eccezione utente: ", parametri);
    return self.$http({
        url: utils.urls.wsbase + "/permissionmanager/permessimodulo",
        method: 'POST',
        data: $.param(parametri)        
    });
}

function revocaEccezione(dataItem){
    var self = this;
    
    var parametri = self.calcolaParametriAggiornamento(dataItem);
    delete parametri.operazioni;
    console.log("parametri revoca eccezione: ", parametri);
    return self.$http({
        url: utils.urls.wsbase + "/permissionmanager/revocaeccezioni",
        method: 'POST',
        data: $.param(parametri)        
    });

}

/**
 * @memberof PermessiModuliManager
 * @param {type} parametri {idmenu,idutente}
 * @returns {Promise}
 */
function revocaEccezioneMenuUtente(parametri){
    var self = this;
    return self.$http({
        url: utils.urls.wsbase + "/menumanager/rimuovieccezioneutente",
        method: 'POST',
        data: $.param(parametri)        
    });
}
module.exports = PermessiModuliManager;