'use strict';

function FormTabHasError() {
    return {
        restrict: 'E',
        scope: {
            form: '=',
            fields: '=',
            tab: '=',      
            tabs: '=',
        },
        template: require("./templates/formtabhaserror.html"),
        link: function($scope){     
            //console.log("controller direttiva tab has error");
            $scope.tab.conErrori = false;
            
            $scope.$watch(function(){
                return $scope.form.$submitted;
            }, function(n){
                //console.log("submit form con tab");
                if(n){
                    $scope.nerrori = $scope.erroriTabAttuale().length;
                }
                else {
                    $scope.nerrori = 0;
                }
            });
            
            //ritorna la lista dei campi con errore
            $scope.erroriTabAttuale = function(){                                
                
                //console.log("stati form: touched", $scope.form.$touched, "pristine", $scope.form.$pristine, "invalid", $scope.form.$invalid, "submitted",  $scope.form.$submitted, "dirty", $scope.form.$dirty);
                
                var campierrore = _.pluck(_.uniq(_.flatten(_.map($scope.form.$error, function(errori, tipoerrore){
                    return errori;
                }))), '$name');
                //console.log("erroriTabAttuale) tutti campi con errore (anche di altre schede: ", campierrore);
                
                var scheda = _.findWhere($scope.fields[0].fieldGroup, {key: $scope.tab.key});
                //console.log("erroriTabAttuale) scheda: ", scheda);
                
                var listaNomi = _.pluck(scheda.fieldGroup, "name");  
                //console.log("erroriTabAttuale) lista campi scheda: ", scheda);
                                
                var campierrorescheda =  _.filter(campierrore, function(campoerrore){
                    return _.indexOf( listaNomi,  campoerrore ) !== -1;
                });
                //console.log("erroriTabAttuale) campi di questa scheda con errori: ", campierrorescheda);
                
                if(campierrorescheda.length){
                    $scope.tab.conErrori = true;
                }
                
                var tabConErrori = _.filter($scope.tabs, function(tab){
                    return tab.conErrori;
                });
                
                //console.log("vado alla tab con errori! ");
                
                if(tabConErrori.length){
                    $scope.form.mostratab = tabConErrori[0].key;
                }                
                                
                return campierrorescheda;
                
            };
        }
    }
}

module.exports = FormTabHasError;