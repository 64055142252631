'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo di tipo input:text
 * 
 * @class
 * 
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSInput(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssinput',
        template: require("./bssinput.html"), // "src/bsslib/formbuilder/scripts/campi/input/bssinput.html",
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssinput'
            },
        }
        
    }));
};


module.exports = BSSInput;