'use strict';


function ModuleInitializerMixin(){


}

ModuleInitializerMixin.prototype.inizializza = inizializza;
ModuleInitializerMixin.prototype.gestisciParametriAggiuntivi = gestisciParametriAggiuntivi;
ModuleInitializerMixin.prototype.aggiungiFunzionalitaToolbar = aggiungiFunzionalitaToolbar;


function inizializza(dspar, gridpar){
    var self = this;        
    
    if(dspar !== undefined){
        self.dsfi = new self.dsf(self.parametri.strentity, self.parametri.codapp, self.parametri.codmodulo, dspar);     
    }
    else {
        self.dsfi = new self.dsf(self.parametri.strentity, self.parametri.codapp, self.parametri.codmodulo);     
    }    



    const terminaInizializz = function(){

        return self.ds.read().then(function(){
            return self.$timeout(function(){         

                /*
                waitUntil(function(){
                    return self.griglia !== null && self.griglia !== undefined;
                }).then(function(){
                */
                    if(self.griglia !== null && self.griglia !== undefined){
                        self.griglia.bind("dataBound", function(e){
                            var griglia = e.sender;
                            var righe = griglia.tbody.find("tr");           
    
                            if(!(gridpar?.escludiAzioni ?? []).includes("modifica")){
                                righe.dblclick(function(e){     
                                    self.gfi.modifica();
                                });
                            }

                            
                        });

                        self.gestisciParametriAggiuntivi();   
                    }                    
                /*    
                }, function(err){
                    console.log("impossibile recuperare la griglia, doppio click potrebbe non funzionare!")
                })
                */                                                               
                
            }).then(function(){
                if(self.fa !== undefined){
                    self.$timeout(function(){
                        self.aggiungiFunzionalitaToolbar();
                    });
                }
                
            });
        }); 

    }
    
    
    return self.dsfi.inizializza().then(function(ds){
        self.ds = ds;
        
        if(gridpar !== undefined){
            var opz = angular.extend({dataSource: self.ds}, {
                
            }, gridpar);
            self.gfi = new self.gf(self.parametri.strentity, self.parametri.codapp, self.parametri.codmodulo, opz);   
        }
        else {
            self.gfi = new self.gf(self.parametri.strentity, self.parametri.codapp, self.parametri.codmodulo, {dataSource: self.ds}); 
        }                    
        
        self.gfi.inizializza().then(function(gridcfg){
            self.gridCfg = gridcfg;
            
            
            return terminaInizializz()
        });      
        
    });
}


function gestisciParametriAggiuntivi(){
    var self = this;
        if(self.parametri.mode === 'crea') self.gridCfg.crea();

        self.griglia.one("dataBound", function(e){                        
            var griglia = e.sender;
            var datasource = griglia.dataSource;
            var gn = new self.gridCfg.gn(e.sender);
            var filtro = {logic: 'and', filters: [{field: datasource.options.schema.model.id, operator: 'incr', value: self.parametri.selezione}]};                        
            


            return self.$http({
                method: "POST",
                url: datasource.options.transport.read.url,
                data: $.param({filter: filtro})
            }).then(function(resp){
                if(self.parametri.selezione) {
                    if(['modifica', 'duplica', "visualizza"].includes(self.parametri.mode)){
                        var numriga = resp.data.numeroRiga;                            
                        gn.vaiA(numriga, true);
                        var clint = setInterval(function(){
                            if(griglia.select().length){
                                clearInterval(clint);
                                self.gridCfg[self.parametri.mode]();
                            }
                        }, 100)
                        
                        return;
                    }
                }
             });
            
            
        });

}

function aggiungiFunzionalitaToolbar(){
    var self = this;
    self.fa.griglia = self.griglia;
    self.fa.aggiornaProvider();
    if(self.fa.griglia){
        self.fa.griglia.dataSource.bind("change", function(){
            self.fa.aggiornaProvider();
        });
    }
    
}


module.exports = ModuleInitializerMixin;