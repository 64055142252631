'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Fornisce il campo per l'inserimento di data e ora
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSRangedate(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssrangedate',
        extends: 'bssinput',
        template:  require("./bssrangedate.html"),
        defaultOptions:{
            ngModelElAttrs: {
                'cssCategory': 'bssrangedate',
            },
            validators: {
                
            },
            controller: ["$scope", function($scope){  
                $scope.tmp = {};

                $scope.maxDate = moment().toDate();
                $scope.rebind = moment().toDate();

                $scope.reinizializza = function(campo){
                    if(campo !== 'a'){
                        $scope.tmp.da = moment().toDate();
                        $scope.tmp.a = moment().toDate();
                    }
                    else {
                        $scope.tmp.a = angular.copy($scope.tmp.da);
                    }

                    $scope.model[$scope.options.key] = {da: $scope.tmp.da, a: $scope.tmp.a};
                }

                $scope.controlla = function(){
                    if(typeof $scope.tmp.da === 'undefined' || $scope.tmp.da > moment().toDate()) {
                        $scope.tmp.da = moment().toDate();
                    }
                    if(typeof $scope.tmp.a === 'undefined' || $scope.tmp.a < $scope.tmp.da){
                        $scope.tmp.a = angular.copy($scope.tmp.da);
                    }
                    if($scope.tmp.a > moment().toDate()){
                        $scope.tmp.a = moment().toDate();
                    }

                    $scope.model[$scope.options.key] = {da: $scope.tmp.da, a: $scope.tmp.a};
                }

                $scope.reinizializza();
            }],
        },
    }));
}

module.exports = BSSRangedate;