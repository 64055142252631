'use strict';

function RouteModuloBaseMixin(){
}


RouteModuloBaseMixin.prototype.registra = registra;
//RouteModuloBaseMixin.prototype.registraDettaglio = registraDettaglio;
//RouteModuloBaseMixin.prototype.registraDaCfg = registraDaCfg;


function registra(){
    var self = this;
    var cfg = {};
    
    cfg.stato = self.stato || 'moduli';
    cfg.url = self.url || "/moduli?nomeistanza&strentity&codapp&codmodulo&selezione&view&mode&forceToolBar";
    cfg.views = {};
    
    if(self.views){
        cfg.views = self.views;
    }
    else {
        cfg.views[self.viewName || "maincontent.custommodule"] = {
            controller: self.controller   || ["$http", "$q", "$timeout", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", require("../ModulobaseCtrl")],
            controllerAs: self.moduloCtrl || "moduloCtrl", //alias controller
        }     
        
        cfg.views[self.viewName || "maincontent.custommodule"].template = self.template || require("moduleFold/genericidentity/scripts/templates/genericmanager.html");

        
//        if(self.template){
//            cfg.views[self.viewName || "maincontent.custommodule"].template = self.template;
//        }
//        else {
//            console.log("utilizzo templateUrl da gestire");
//            cfg.views[self.viewName || "maincontent.custommodule"].templateUrl = self.templateUrl || "src/bsslib/genericidentity/scripts/templates/genericmanager.html";
//        }
    }

    cfg.middleware = self.middleware || ['loggato'];
    cfg.data = self.data || "bss.modulobase"; 
    
    cfg.resolve = {
        identifica: self.identifica || ['$state', '$stateParams', function(st, sp){   
            var self = this;
            var tohash = self.toString()+JSON.stringify(sp);
            var identificatore = cryptofoo.hash('whirlpool', tohash);
            return identificatore;
        }]
    };        

    //se dett è definito inizializzo anche la vista di dettaglio
    if(self.dett){
        var defDettView = {views: self.dett.views || {
            "maincontent.details": {
                template: require("moduleFold/genericidentity/scripts/templates/detail.html"), //templateUrl: "src/bsslib/genericidentity/scripts/templates/detail.html",
                controller: ["$http", "$q", "$timeout", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", require("../DetailbaseCtrl")],
                controllerAs: "detailCtrl" //alias controller
            }
        }};

        cfg.views = angular.extend(cfg.views, defDettView.views);
    }
    
    //console.log(cfg);
    
    self.$stateProvider.state(cfg.stato, cfg);
}



module.exports = RouteModuloBaseMixin;