'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /tipidoc
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} TipidocFunzioniAggiuntive
 */
function TipidocCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, $filter, ActionFactory, ErrorManager, TipidocFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = TipidocFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.$filter = $filter;
    self.em = ErrorManager;

    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = {
        strentity: "ttipodocumento",
        codmodulo: "BSGTTipDoc"
    };

   
    /**
     * Per evitare di scrivere la logica nel file delle funzioni aggiuntive osservo proceduradocrebind.
     * Infatti se questa proprietà di TipidocFunzioniAggiuntive si aggiora indica che è stato cliccato 
     * il tasto presente nella toolbar e quindi devo inizializzare la procedura di mappatura documenti/procedura
     */
    $scope.$watch(
        function(){
            return self.fa.proceduradocrebind;
        }, 
        function(n, o){
            if(n !== o){
                self.initProceduraDoc();
            }
        }
    )

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype);

    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza();
    
}

TipidocCtrl.prototype.initProceduraDoc = initProceduraDoc;
TipidocCtrl.prototype.salvaProcedura = salvaProcedura;
TipidocCtrl.prototype.ripristinaProcedure = ripristinaProcedure;
TipidocCtrl.prototype.blurmappa = blurmappa;
TipidocCtrl.prototype.resetmappa = resetmappa;
TipidocCtrl.prototype.setmappa = setmappa;
TipidocCtrl.prototype.apripopupmappa = apripopupmappa;

/**
 * @description
 * Inizializzazione procedura di associazione documenti a procedura.
 * Questo permette di indicare, all'esecuzione di una procedura su un tipo documento di partenza, quali tipi di documenti verranno generati.
 */
function initProceduraDoc(){
    var self = this;
    self.disabilitamappa = {};
    self.statosalva = {};
    // console.log("inizializzo la procedura di mappa documenti")

    self.$q.all([
        self.$http({method: "POST", url: utils.urls.wsbase + "/ttipodocumento/cercatutti"}),//elenco tipi documento
        self.$http({method: "POST", url: utils.urls.wsbase + "/tproceduredoc/cercatutti"}),//elenco procedure
        self.$http({method: "POST", url: utils.urls.wsbase + "/trproceduredoc/cercatutti"})//elenco associazioni preesistenti
    ]).then(function(resps){
        self.docs = resps[0].data;
        self.procedure = resps[1].data;

        /**
         * per consentire un accesso diretto all'associazione senza cercare di volta in volta riorganizzo le associazioni come una mappa 
         * in cui la chiave è formata da {idtipodoc}_{idprocedura} e il valore è il tipodoc generato
         */
        self.mappeori = resps[2].data.reduce(function(obj, m){
            obj[m.docda.id+"_"+m.procedura.id] = m.doca
            return obj;
        }, {});

        self.ripristinaProcedure();
        self.proceduradocwin.open().center();
    })

    
}

function salvaProcedura(indice){
    var self = this;
    console.log("salvo", self.mappe[indice])
    self.statosalva[indice] = "mappesalvaincorso";    

    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/trproceduredoc/salva",
        data: $.param({elemento: JSON.stringify(self.mappe[indice])})
    }).then(function(resp){
        self.statosalva[indice] = "mappesalvasuccesso"
        return resp;
    }, function(err){
        self.statosalva[indice] = "mappesalvaerrore"
        return err
    })

    // return self.$timeout(function(){
    //     return self.statosalva[indice] = "mappesalvasuccesso"
    // }, 300)

}

function ripristinaProcedure(){
    var self = this;
    self.mappe = JSON.parse(JSON.stringify(self.mappeori));
    self._mappe = Object.keys(self.mappeori).reduce(function(obj, indice){
        if(!self.nou(self.mappeori[indice])){
            obj[indice] = self.mappeori[indice].nome;
            self.disabilitamappa[indice] = true;
        } else {
            obj[indice] =  null;
        }        
        return obj;
    }, {})
}

function blurmappa(indice) {
    var self = this;
    var term = self.formmappe['mappa_'+indice].$viewValue;
    if(term !== null && typeof term !== 'undefined'){
        term = term.toUpperCase();
    }
    
    self.$q.when(self.$filter('filter')(self.docs, term)).then(function(opzioni){
        console.log("opzioni che matchano", opzioni);
        if (opzioni.length === 1) {
            self.setmappa(indice, opzioni[0])
            
        } else {
            //$event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("TIPIDOC.NODOCFOUND", "warning");
                //self.resetmappa(indice)
            }
            self.apripopupmappa(indice)
            
        }
    })
}

function resetmappa(indice, senzasalvare){
    var self = this;
    self._mappe[indice] = null;
    var partindice = indice.split("_");        
    self.mappe[indice] = {doca: null, procedura: {id: partindice[1]}, docda: {id: partindice[0]}};
    self.disabilitamappa[indice] = false;
    if(senzasalvare === true){

    } else {
        self.salvaProcedura(indice);
    }
    
}

/**
 * @description
 * Aggiorna la mappa tipidoc/procedura. Si occupa anche di salvare l'associazione sul server
 * @param {*} indice - dato da idtipodoc_idprocedura
 * @param {*} valore - oggetto tipodoc generato 
 */
function setmappa(indice, valore){
    var self = this;
    var partindice = indice.split("_");
    self._mappe[indice] = valore.nome;
    self.mappe[indice] = {doca: valore, procedura: {id: partindice[1]}, docda: {id: partindice[0]}};
    self.disabilitamappa[indice] = true;
    self.salvaProcedura(indice).then(function(){
        self.docwin.close();
    });
}

function apripopupmappa(indice){
    var self = this;
    self.indiceMappaSel = indice;
    self.docwin.open().center();
}

module.exports = TipidocCtrl;
