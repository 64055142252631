'use strict';


/**
* Controller relativo al template della route /ricercadoc
*
* @param {type} ToolbarSrv
* @param {type} ActionFactory
* @param {type} $http
* @param {type} $q
* @param {type} $timeout
* @param {type} $translate
* @param {type} ErrorManager
*/
function RicercadocFunzioniAggiuntive(ToolbarSrv, ActionFactory, $http, $q, $timeout, $translate, $state, ErrorManager){
  var self = this;
  self.ts = ToolbarSrv;
  self.af = ActionFactory;
  self.$http = $http;
  self.$q = $q;
  self.$timeout = $timeout;
  self.$translate = $translate;
  self.$state = $state;
  self.em = ErrorManager;
  var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
  var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
  var StampaDettaglioMixin = require("moduleFold/mixins/StampaDettaglioMixin");
  angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype, StampaDettaglioMixin.prototype);
}

RicercadocFunzioniAggiuntive.prototype.aggiornaProvider = aggiornaProvider;

/**
* @memberof RicercadocFunzioniAggiuntive
*
* Registra azioni nella toolbar
*
*/
function aggiornaProvider(){
  var self = this;



}

module.exports = RicercadocFunzioniAggiuntive;
