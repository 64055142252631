'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/modalitapagamenti
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function ModalitapagamentiRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "modalitapagamenti",
        url: "/modalitapagamenti",
        //templateUrl: "bsscarrier/modalitapagamenti/scripts/templates/modalitapagamenti.html",
        template: require("../templates/modalitapagamenti.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "GridCfgFactory", "ToolbarSrv", "identifica", "$scope", "$compile", "ActionFactory", "ErrorManager", "FormDaGridFactory", "Valida", "ModalitapagamentiFunzioniAggiuntive", require("../ModalitapagamentiCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = ModalitapagamentiRoutes;
