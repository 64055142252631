"use strict";

/**
 * Si occupa di interagire con il localStorage.
 *
 * @class
 */
function SessioneService(){
    this.sessione ={};
    this.copiaDaBrowser();
    
}

SessioneService.prototype.aggiorna = aggiorna;
SessioneService.prototype.cancellaSessione = cancellaSessione;
SessioneService.prototype.copiaDaBrowser = copiaDaBrowser;

/**
 * 
 * Copia la sessione dal localStorage del browser
 * 
 * @memberOf SessioneService
 */
function copiaDaBrowser(){
    if(localStorage.sessione) {
        this.sessione = JSON.parse(localStorage.sessione);
    }
}

/**
 * Aggiunge una chiave a SessioneService e aggiorna sessionstorage
 * 
 * @memberOf SessioneService
 * @param {type} chiave
 * @param {type} valore
 */
function aggiorna(chiave, valore){
    this.sessione[chiave] = valore;
    localStorage.sessione = JSON.stringify(this.sessione);
}


/**
 * Cancella la sessione (SessioneService e localStorage)
 * 
 * @memberOf SessioneService
 */
function cancellaSessione(){
    //localStorage.clear();
    localStorage.removeItem("sessione");
    this.sessione = {};
}


module.exports = SessioneService;