'use strict';

/**
 * Factory che crea le opzioni della griglia e gestisce gli eventi principali legati alla griglia per aggiornare la toolbar.
 * 
 * @param {type} GCfgP
 * @param {type} ToolbarS
 * @param {type} GridNav
 * @param {type} $timeout
 * @param {type} ActFct
 * @param {type} $rootScope
 * @param {type} $state
 * @param {type} $stateParams
 * @param {type} $translate
 * @param {type} GridIdentity
 * @param {type} FormDaGridFactory
 */
function GridFactory(GCfgP, ToolbarS, GridNav, $timeout, ActFct, $rootScope, $state, $stateParams, $translate, GridIdentity, FormDaGridFactory, ErrorManager, $q, $window, StatoApp){
    /**
     * Setta le opzioni di base
     * 
     * @memberOf GridFactory
     * @constructor
     * @param {type} strentity
     * @param {type} codapp
     * @param {type} codmodulo
     * @param {type} opzioni
     */
    function Costruttore(strentity, codapp, codmodulo, opzioni){
        GridIdentity.apply(this, [true, opzioni]);
        this.strentity = strentity;
        this.codapp = codapp;
        this.codmodulo = codmodulo;
        this.opzioniUtente = opzioni;
        this.gn = GridNav;
        this.af = ActFct;
        this.ts = ToolbarS;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.tr = $translate;
        this.timeout = $timeout;   
        this.fdgf = FormDaGridFactory;
        this.gcfgf = GCfgP;
        this.em = ErrorManager;
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.statoapp = StatoApp;
    }
    
    var gii = require("moduleFold/genericidentity/scripts/GridIdentity");
    var gi = new gii();
    angular.extend(Costruttore.prototype, gi.prototype);
    
    Costruttore.prototype.inizializza = inizializza;
    Costruttore.prototype.aggiornaForm = aggiornaForm;
    Costruttore.prototype.controlloCambioStato = controlloCambioStato;
    Costruttore.prototype.mostraGriglia = mostraGriglia;
    Costruttore.prototype.crea = crea;
    Costruttore.prototype.modifica = modifica;
    Costruttore.prototype.duplica = duplica;
    Costruttore.prototype.visualizza = visualizza;
    Costruttore.prototype.salvacrea = salvacrea;
    Costruttore.prototype.salvamodifica = salvamodifica;
    Costruttore.prototype.elimina = elimina;
    Costruttore.prototype.convertiModelloRaggruppato = convertiModelloRaggruppato;
    Costruttore.prototype.sincronizzaDS = sincronizzaDS;
    Costruttore.prototype.setMode = setMode;
    Costruttore.prototype.getmodello = getmodello;
    Costruttore.prototype.getmodellocrea = getmodellocrea;
    Costruttore.prototype.getmodellomodifica = getmodellomodifica;
    Costruttore.prototype.raggruppaModello = raggruppaModello;
    Costruttore.prototype.appianaModello = appianaModello;
    
    /**
     * Istanzia GridCfgFactory per ottenere tutte le opzioni della griglia.
     * Quindi registra il cambio di selezione e l'aggiornamento del datasource, per aggiornare la toolbar.
     * 
     * @memberOf GridFactory
     * @returns {Object} configurazione comprensiva di event handler
     */     
    function inizializza(){
        var self = this;
        
        return new this.gcfgf(self.strentity, self.codapp, self.codmodulo, self.opzioniUtente).then(function(cfg){



            cfg.columns = cfg.columns.map(function(col){                
                if(bssRecursiveGetter(col, "filterable.cell", null) !== null){//la colonna ha il filtro nell'header della colonna
                    var model = cfg.dataSource.options.schema.model.fields[col.field];
                    if(![null, undefined].includes(model.datatextfield)){//il modello dello stesso campo ha datatextfield quindi i valori della colonna sono oggetti
                        if([null, undefined].includes(col.filterable.cell.dataTextField)){//se la column non definisce dataTextField lo copio dal model
                            col.filterable.cell.dataTextField = col.field + "." + model.datatextfield
                        }
                    }
                }
                return col;
            })


            
            self.grigliaOpts = cfg;            
            self.setMode('griglia');
            self.ascoltaCambioSelezione();
            self.ascoltaCambioDS();
            
            return self;
        });
    }
    
    function setMode(mode){
        var self = this;
        self.mode = mode;
        self.ts.mode = mode;
    }
    
    /**
     * @memberOf GridFactory
     */
    function mostraGriglia(){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.setMode("griglia");     
        });
        
        
        
    }
    
    /**
     * 
     * @memberOf GridFactory
     */
    function crea(){
        var self = this;
        
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.setMode("form");
            self.ts.griglia.clearSelection();
            self.saveMode = 'crea';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Inserisci', self.formObj);
            self.$rootScope.$emit("inmodifica");  
            self.form.inVisualizza = false;      
        });
        
        
    }
    
    /**
     * @memberOf GridFactory
     */
    function modifica(){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.setMode("form");
            self.saveMode = 'modifica';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Modifica', self.formObj);
            self.$rootScope.$emit("inmodifica");   
            self.form.inVisualizza = false;   
        });
                
    }
    
    /**
     * @memberOf GridFactory
     */
    function duplica(){
        var self = this;
        
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.setMode("form");
            self.saveMode = 'crea';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Duplica', self.formObj);
            self.$rootScope.$emit("inmodifica");   
            self.form.inVisualizza = false;
        });
         
        
    }

    function visualizza(){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.setMode("form");
            self.saveMode = 'modifica';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Modifica', self.formObj);
            self.$rootScope.$emit("invisualizza");   
            self.form.inVisualizza = true;
        });
    }

    
    /**
     * Override di aggiornaForm di GridIdentity
     * 
     * @memberOf GridFactory
     */     
    function aggiornaForm(){
        
        var self = this;

        //console.log("aggiorno form: ", self.formObj);
        
        self.formObj.$submitted = false;
        
        if(self.ts.selezione.length){
            self.saveMode = 'modifica';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Modifica', self.formObj);           
        }
        else {
            self.saveMode = 'crea';
            self.form = new self.fdgf("", self.ts.griglia, null, null, 'Inserisci', self.formObj);
        }
        
    }
    
    /**
     * 
     * Il controllo standard non va bene in quanto tutti i moduli di base hanno lo stesso self.$state.current.data.modulo
     * 
     * @memberOf GridFactory
     * @param {Array<Array<ActionFactory>>} providers
     */
    function controlloCambioStato(providers){
        var self = this;
        
        var tohash = self.$state.current.name+JSON.stringify(self.$stateParams);
        var idAttuale = cryptofoo.hash('whirlpool', tohash);        
                
        if(idAttuale === self.ts.idAttuale){
            self.ts.setProviders(providers, true);
        }
        else {
            self.ts.svuotaProviders();
        }
    }  
    
    /**
     * Preso un modello raggruppato lo converte in un oggetto semplice (chiave valore)
     * 
     * @memberOf GridFactory
     */
    function convertiModelloRaggruppato(modelloRaggruppato){
        var self = this;
        if(modelloRaggruppato.maintab){//se esiste effettivamente il raggruppamento
            return _.reduce(_.map(modelloRaggruppato.maintab, function(contenutoTab, nomeTab){
                //console.log("itero tra le tab di maintab", contenutoTab, nomeTab);
                return contenutoTab;
            }), function(solo, gruppo){

                _.map(gruppo, function(v, k){
                    solo[k] = v;
                });
                return solo;
            });
        }
        
        return modelloRaggruppato;
    }

    function appianaModello(modelloRaggruppato){
        var self = this;
        var mod = self.convertiModelloRaggruppato(modelloRaggruppato);
        return angular.extend({}, modelloRaggruppato.originale, mod);
    }

    function raggruppaModello(modello, header){
        var self = this;
        //console.log("modello liscio converto in raggruppato", header, modello);
        if(typeof modello !== 'undefined' && header.campi[0].key === 'maintab' ){
            if(header.campi[0].fieldGroup.constructor === Array){
                var gruppi = header.campi[0].fieldGroup; 
                var modello2 = {maintab: {}, originale: modello};
                _.each(gruppi, function(gruppo){
                    var chiave = gruppo.key;
                    modello2.maintab[chiave] = {};
                    _.each(gruppo.fieldGroup, function(campo){
                        var valore = modello[campo.key];
                        if(campo.type === 'bssdate'){
                            valore = moment(valore, "YYYY-MM-DD").toDate();
                        }
                        modello2.maintab[chiave][campo.key] = valore;
                    })
                });
                modello = modello2;
            }
        }
        return modello;
    }
    
    /**
     * 
     * @memberOf GridFactory
     */
    function sincronizzaDS(ds, operazione){
        var self = this;
        if(!ds.hasChanges()){
            self.em.showT("GRIDDS.NOEDIT",  "warning");
            return self.$q.all(['noedit']);
        }
        else {
            return self.timeout(function(){
                console.log("incremento disabilitatutto", "inizio sincronizzaDS")
                self.ts.disabilitatutto++;
                console.log("sincro da gridfactory", self.ts);
                return ds.sync().then(function(resp){
                        console.log("successo sincro da gridfactory", self.ts);                
                        console.log("decremento disabilitatutto", "fine sincronizzaDS in successo")
                        self.ts.disabilitatutto--;
                        return self.$q.when(self.em.show(operazione + " andata a buon fine ", "success"));
                    }, function(resp){
                        console.log("decremento disabilitatutto", "fine sincronizzaDS in errore")
                        self.ts.disabilitatutto--;
                        ds.cancelChanges();
                        console.log("errore", resp.responseText);
                        return self.$q.when(self.em.errorCB({data: JSON.parse(resp.responseText)}));
                    })
            })
            
        }
        
    }
    
    function getmodello(){
        var self = this;
        return self["getmodello"+self.saveMode]();
    }

    function getmodellocrea(){
        var self = this;
        return self.convertiModelloRaggruppato(self.form.modello);
    }
    
    /**
     * 
     * @memberOf GridFactory
     */
    function salvacrea(){

        var self = this;
        
        var datasource = self.ts.griglia.dataSource;
        var campi = self.getmodello();
        var el = datasource.add(campi);

        return self.sincronizzaDS(datasource, "crea");
    }
            
    function getmodellomodifica(){
        var self = this;  
        
        var modello = self.form.modello; 
        
        var campi = {}; 
        if(modello.maintab){
            for(var tab in modello.maintab){
                for(var campo in modello.maintab[tab]){   
                    campi[campo] = modello.maintab[tab][campo]  
                }
            }
        }
        else{
            for(var x = 0; x < self.form.campi.length; x++){
                var campo = self.form.campi[x].key;
                campi[campo] = modello[campo];
            }
        }
        return campi;
    }
    
    /**
     * 
     * @memberOf GridFactory
     */
    function salvamodifica(){
        var self = this;        
        var datasource = self.ts.griglia.dataSource;        
        var campoId = datasource.options.schema.model.id;
        var modello = self.form.modello;                
        var idRecord = modello[campoId];
        var actualdataitem = datasource.get(idRecord);
        var campi = self.getmodello();

        for(var campo in campi){         
            actualdataitem.set(campo, campi[campo]);
        }


        return self.sincronizzaDS(datasource, "modifica");
        
    }
    
    /**
     * 
     * @memberOf GridFactory
     */
    function elimina(){
        var self = this;
        var griglia = self.ts.griglia;
        var datasource = self.ts.griglia.dataSource;      
        //var selezione = self.ts.selezione[0];
        var righeSel = griglia.select();        
        
        self.tr("MODULEMANAGER.CONFIRMELIMINA", {entity: self.strentity}).then(function(messaggio){
            if(confirm(messaggio)){
                return self.timeout(function(){
                    console.log("procedo con la cancellazione da gridfactory di ", righeSel);
                    //da testare
                    
                    //datasource.remove(righeSel);
                    for(var x = 0; x < righeSel.length; x++){
                        griglia.removeRow(righeSel[x]); //non aggiorna il datasource dopo la cancellazione
                    }
                    

                    if(!datasource.hasChanges()){
                        self.em.showT("GRIDDS.NOEDIT",  "warning");
                        return self.$q.all(['noedit']);
                    }
                    else {
                        return self.sincronizzaDS(datasource, "elimina");

                    }
                    
                });
            }
        });
    }
    
    
    return Costruttore;  
    
}

module.exports = GridFactory;