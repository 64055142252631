'use strict';


/**
* Controller relativo al template della route /listacodici
*
* @param {type} ToolbarSrv
* @param {type} ActionFactory
* @param {type} $http
* @param {type} $q
* @param {type} $timeout
* @param {type} $translate
* @param {type} ErrorManager
*/
function ListacodiciFunzioniAggiuntive(ToolbarSrv, ActionFactory, $http, $q, $timeout, $translate, $state, ErrorManager){
  var self = this;
  self.ts = ToolbarSrv;
  self.af = ActionFactory;
  self.$http = $http;
  self.$q = $q;
  self.$timeout = $timeout;
  self.$translate = $translate;
  self.$state = $state;
  self.em = ErrorManager;
  var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
  var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
  var StampaDettaglioMixin = require("moduleFold/mixins/StampaDettaglioMixin");
  angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype, StampaDettaglioMixin.prototype);
}

ListacodiciFunzioniAggiuntive.prototype.aggiornaProvider = aggiornaProvider;
ListacodiciFunzioniAggiuntive.prototype.azione = azione;

/**
* @memberof ListacodiciFunzioniAggiuntive
*
* Registra azioni nella toolbar
*
*/
function aggiornaProvider(){
  var self = this;

  /*codice stampa dettaglio*/
  /*
  var azioniGN = self.ts.actionsProviders.gridNavigation.exposed;
  azioniGN = _.map(azioniGN, function(azione){
      //if(azione.descrizione === 'Stampe Modulo'){
      if(azione.chiavetraduzione === 'GRIDNAV.STAMPA'){
          azione.disabilita = self.esponi({metodo: "dimSelezioneInMultipleMode", parametri: ["lt", 1, ["form", "griglia"]]});
          var stampaDettaglio = new self.af("fa fa-info",  self.esponi({metodo: "dimSelezioneInMultipleMode", parametri: ["lt", 1, ["form", "griglia"]]}), self.esponi({metodo: "stampa", parametri: ['/listacodici/stampa']}), 999, null, "GRIDNAV.STAMPADETTAGLIO");            
          azione.azioni.push(stampaDettaglio);
      }
      return azione;
  });
  self.$timeout(function(){
      self.ts.setProviders({gridNavigation: {exposed: azioniGN }}, false);
  }, 1000);
  */
  /*fine codice stampa dettaglio*/



  /* es. di azione, in questo caso occorre definire il metodo mostraTimeLine
  var azione1 = new self.af("fa fa-history",  self.esponi({metodo: "dimSelezioneConfronta", parametri: ["ne", 1]}), self.esponi({metodo: "azione", parametri: []}), 999, null, "LISTACODICI.SHOWHISTORY");

  self.ts.setProviders(listacodiciprovider: {exposed: [
  azione1
]}});
*/
}

/**
* @memberof ListacodiciFunzioniAggiuntive
*
* azione di esempio
*
*/
function azione(){
  //var istanzaSel = this.ts.griglia.dataItem(this.ts.griglia.select());
  //var url = this.$state.href('statoAzione', {/*parametri*/});
  //window.open(url, '_blank');
}

module.exports = ListacodiciFunzioniAggiuntive;
