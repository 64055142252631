'use strict';

/**
 * Direttiva che legge il contenuto di un file da un campo di tipo file
 * 
 * @class
 * @returns {angularDirective}
 */
function FileReadDir(){
    return {
        scope: {
            fileread: "="
        },
        require: "ngModel",
        link: function (scope, element, attributes, ngModel) {
            element.bind("change", function (changeEvent) {

                var reader = new FileReader();
                scope.$apply(function () {
                    scope.fileread = null;
                });
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileread = loadEvent.target.result;
                    });
                }
                if(changeEvent.target.files.length){
                    var file = changeEvent.target.files[0];
                    reader.readAsDataURL(file);
                    ngModel.$setViewValue(file);
                }
            });
        }
    }
}

module.exports = FileReadDir;