"use strict";



$.ajaxSetup({
    /**
    * Hook per $.ajax di jQuery, prima dell'invio della richiesta popola l'header con il token se presente in sessione.
    * In tutto il progetto viene utilizzato il servizio $http di angular, tuttavia alcuni widget (specialmente quelli di kendo) 
    * utilizzano $.ajax di jQuery
    * 
    */
    beforeSend: function(xhr, settings) {
        bssXhrPool.push(xhr);
        if(typeof localStorage.sessione !== "undefined"){
            var sobj = JSON.parse(localStorage.sessione);
            
            if(sobj.linguaSelezionata){
                xhr.setRequestHeader('Accept-Language', sobj.linguaSelezionata);
            }

            if(typeof sobj.partizione !== "undefined"){
                if(typeof sobj.partizione.esercizio !== "undefined"){
                    var esercizio = sobj.partizione.esercizio;
                    xhr.setRequestHeader('BSS-ESERCIZIO', esercizio);
                }
    
                if(typeof sobj.partizione.azienda !== "undefined"){
                    var azienda = sobj.partizione.azienda;
                    xhr.setRequestHeader('BSS-AZIENDA', azienda);
                }
                
            }

            if(keycloak !== 'undefined' && keycloak !== null && keycloak.token !== null && typeof keycloak.token !== 'undefined'){
                //console.log("=================>richiesta da jquery ho il token di keycloak", settings, keycloak.token)
                //keycloak.updateToken(30).then(function(refreshed){
                    xhr.setRequestHeader('Authorization', 'Bearer ' + keycloak.token);
                //})
                
            }
        }
        
        
    },
    error: function(xreq, textstatus, message){
        if(textstatus === 'timeout'){
            alert("La richiesta è andata in timeout")
        }
    },
    complete: function(xhr, status){
        try{
            var json = JSON.parse(xhr.responseJSON);
            // if(keycloak !== 'undefined' && keycloak !== null && keycloak.token !== null && typeof keycloak.token !== 'undefined'){
                
            //     keycloak.updateToken(30)
            // }
            console.log("resp con json da jquery", json)
        } catch(err){/*response non è JSON la ignoro*/}
    }
});

/**
 * Hook che viene eseguito prima di inviare ogni richiesta lanciata con $http.
 * Se presente in sessione il token lo aggiunge agli header, per fornire autenticazione.
 * 
 * @class
 * @param {SessionService} SessionSrv
 * @param {LoginService} LoginService
 * @returns {Object} ritorna un oggetto che rappresenta la configurazione della richiesta
 */
function TokenInterceptor(SessionSrv){
    var self = this;
    self.ss = SessionSrv;

    var isLoggedIn = function(){
        return typeof keycloak !== 'undefined' && keycloak !== null && keycloak.token !== null && typeof keycloak.token !== 'undefined'
    }
    
    
    return {
        request: function (config) {
            
            if(typeof self.ss.sessione.linguaSelezionata !== "undefined"){
                config.headers['Accept-Language'] = self.ss.sessione.linguaSelezionata;
            }

            if(typeof self.ss.sessione.partizione !== "undefined"){
                var partizione = self.ss.sessione.partizione;
                if(typeof partizione.esercizio !== "undefined"){
                    config.headers['BSS-ESERCIZIO'] = partizione.esercizio;
                }
                if(typeof partizione.azienda !== "undefined"){
                    config.headers['BSS-AZIENDA'] = partizione.azienda;
                }
            }

            if(isLoggedIn()) {
                // il token era", keycloak.token)
                return keycloak.updateToken(30).then(function(refreshed){
                    //console.log(refreshed ? 'aggiornato ' + keycloak.token : 'aggiornamento token non necessario')
                    var loginpath = new RegExp(/webservice\/.*\/login/);
                    //alert(JSON.stringify(self.ss.sessione));
                    if(isLoggedIn() && !loginpath.test(config.url)){
                        config.headers['Authorization'] = "Bearer " + keycloak.token;
                    }  
                    
                    return config;
                }).catch(function(err){
                    console.log("errore da update token ", err)
                    delete config.headers["Authorization"];
                    return config;
                })
                
            } 
            
            return config;

        },  
        response: function(resp){
            
            try{
                var json = JSON.parse(resp.data);
                var url  = resp.config.url;
                console.log("response ajax angularjs intercettata", json, url);
            } catch(err){/*la response non è json la ignoro*/}
            return resp;
        },
        responseError: function(rejection){
            if(rejection.config.headers["X-bss-check-feature-availability"]){
                console.log("la funzionalità richiesta non è disponibile", rejection.config.headers["X-bss-check-feature-availability"])
                throw rejection;
            }

            if(rejection.config.timeout){
                //self.em.showT("LOGIN.TIMEOUT")
                throw rejection;
            }

            var inj = angular.element(document.body).injector();
            self.em = inj.get("ErrorManager");     
            if(rejection.config.responseType === 'arraybuffer'){
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(rejection.data));
                rejection.data = JSON.parse(decodedString);
            }
            self.em.errorCB(rejection);
            throw rejection;
        }
    };
}

module.exports = TokenInterceptor;