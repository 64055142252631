'use strict';

require("srcFold/bssricambiweb/documentistd/scripts/templates/nuovoindirizzo.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/righedocumentistd.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/totalidocumentistd.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/corrieridocumentistd.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/riepilogodoc.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/formrighe/A.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/formrighe/C.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/formrighe/D.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/formrighe/T.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/formrighe/S.html");
require("srcFold/bssricambiweb/documentistd/scripts/templates/storico.html");

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/documentistd
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function DocumentistdRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "documentistd",
        url: "/documentistd/:raggrdoc?iddoc",
        //templateUrl: "ricambiweb/documentistd/scripts/templates/documentistd.html",
        template: require("../templates/documentistd.html"),
        controller: ["$http", "$q", "$timeout", "$interval", "$state", "$stateParams", "$window", "$filter", "DSFactory", "GridFactory", "GridCfgFactory", "ToolbarSrv", "identifica", "$scope", "$rootScope", "$compile", "ActionFactory", "ErrorManager", "FormDaGridFactory", "FormDaHeaderFactory", "FieldFactory", "Valida", "DocumentistdFunzioniAggiuntive", "SessioneService", require("../DocumentistdCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = DocumentistdRoutes;
