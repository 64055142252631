'use strict';

/**
 * Espone tutte le regole di validazione distinguendo tra sincrone e asincrone.
 * 
 * @class
 * @param {factory|validatore} ConfirmFct
 * @param {factory|validatore} IsDateTimeFct
 * @param {factory|validatore} MaxFct
 * @param {factory|validatore} MaxLengthFct
 * @param {factory|validatore} MinFct
 * @param {factory|validatore} NullableFct
 * @param {factory|validatore} RequiredFct
 * @param {factory|validatore} SizeFct
 * @param {factory|validatore} UniqueFct
 */
function ValidaSrv(ConfirmFct, IsDateTimeFct, MaxFct, MaxLengthFct, MinFct, NullableFct, RequiredFct, LocalitarequiredFct, SizeFct, UniqueFct, RequiredIfFct, RequiredWithFct, RequiredWithoutFct, RequiredWithoutAllFct, ComparaFct, IntervalloScaglionabileFct){
    this.sync = {};
    this.async = {};
    this.sync.confirm = new ConfirmFct;
    this.sync.isDateTime = new IsDateTimeFct;    
    this.sync.max = new MaxFct;
    this.sync.maxLength = new MaxLengthFct;
    this.sync.min = new MinFct;
    this.sync.nullable = new NullableFct;
    this.sync.required = new RequiredFct;
    this.sync.localitarequired = new LocalitarequiredFct;
    this.sync.size = new SizeFct;
    this.sync.required_if = new RequiredIfFct;
    this.sync.required_with = new RequiredWithFct;
    this.sync.required_without = new RequiredWithoutFct;
    this.sync.required_without_all = new RequiredWithoutAllFct;
    this.sync.compara = new ComparaFct;
    this.sync.intervalloscaglionabile = new IntervalloScaglionabileFct;
    this.async.unique = new UniqueFct;    
}

ValidaSrv.prototype.validaForm = validaForm;
ValidaSrv.prototype.ripulisciMessaggio = ripulisciMessaggio;
ValidaSrv.prototype.touchErrorFields = touchErrorFields;

function validaForm(form, withoutTouch){
    var self = this;
    var esito = self.touchErrorFields(form, 0, withoutTouch) <= 0;
    form.$setSubmitted()
    return esito;
}

/**
 * Scorre tutti i campi ricorsivamente, i campi con errore vengono indicati come touched così da mostrare il messaggio di errore
 * Infine ritorna il numero di campi con errore incontrati
 * 
 * @param {*} form 
 * @param {*} conteggio 
 * @returns {Number} rirorna il numero di errori incontrati
 */
function touchErrorFields(form, conteggio, withoutTouch) {
    var self = this;
    if(conteggio === null || typeof conteggio === 'undefined'){
        conteggio = 0;
    }
    angular.forEach(form.$error, function (field) {
        angular.forEach(field, function(errorField) {
            if (!errorField.hasOwnProperty('$setTouched')) {
                self.touchErrorFields(errorField, conteggio, withoutTouch);
            } else {
                console.log("evidenzio errore per", errorField);
                if(withoutTouch !== true){
                    errorField.$setTouched();
                }
                errorField.$validate();
                conteggio++;
            }
        })
    });

    return conteggio;
}

/**
 * @description
 * I messaggi ottenuti dalle Factory di validazione utilizzano variabili di scope presenti solo utilizzando formly-form
 * 
 * @issue
 * Attualmente funziona solo per sostituire to.label (forse solo per required)
 * 
 * @todo
 * Gestire tutte le casistiche dei vari validatori
 * 
 * @param {*} messaggio 
 * @param {*} label 
 */
function ripulisciMessaggio(messaggio, label){
    var messaggio = messaggio.replace("+ to.label +", label);
    messaggio = messaggio.substring(1, messaggio.length-1);
    return messaggio
}

module.exports = ValidaSrv;