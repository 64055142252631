"use strict";

/**
 * Controlla che il valore sia non null in presenza di altri campi
 * 
 * @class
 * @param {$translate} $translate
 */
function RequiredIfFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf RequiredIfFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */     
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.REQUIREDWITH", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf RequiredIfFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */     
    function expression(viewValue, modelValue, scope){
        var valore = modelValue || viewValue || null;
        var esitoValore = valore !== null && typeof valore !== 'undefined';
        
        var parametri = scope.options.data.modello.validations.required_if.split(":");
        var altrocampo = parametri[0];
        scope.options.altroValoreConfronto = parametri[1];
        scope.options.altraLabel = "";


        if(scope.$parent.$parent.$parent !== null){
            var campiForm = scope.$parent.$parent.$parent.fields;
            var campoForm = _.findWhere(campiForm, {key: altrocampo});
            if( typeof campoForm !== 'undefined' ) scope.options.altraLabel = campoForm.templateOptions.label;
        }
             
        console.log("required_if campo: ", scope.to.label, "altro campo: ", scope.options.altraLabel , "valore ipotizzato altro campo: ", scope.options.altroValoreConfronto);
        
        var altroValore = scope.model[altrocampo];
        var esitoAltroValore = false;
        
        if(_.isObject(altroValore)){
            esitoAltroValore = (altroValore.value === scope.options.altroValoreConfronto);
        }
        else {
            esitoAltroValore = esitoAltroValore && (altroValore === scope.options.altroValoreConfronto);
        }
        
           
        if(esitoAltroValore && !esitoValore) return false;
        
        return true;
    }
        
    return Costruttore;
}

module.exports = RequiredIfFct;