'use strict';

function GrigliaPermessi($stateParams, DSFactory, GridCfgFactory, ToolbarSrv){
    function Costruttore(strentity){
        this.strentity = strentity;
        this.sp = $stateParams;
        this.dsf = DSFactory;
        this.gf = GridCfgFactory;
        this.ts = ToolbarSrv;
    }
    
    Costruttore.prototype.inizializza = inizializza;
    
    function inizializza(opzioniUtente){
        var self = this;
        
        
        
        return new self.dsf(self.strentity, self.codapp, self.codmodulo).inizializza().then(function(ds){
            self.ds = ds;
            
            var optGriglia = angular.extend({}, {
                dataSource: self.ds,
                dataBound: function() {
                    this.expandRow(this.tbody.find("tr.k-master-row").first());
                },
            }, opzioniUtente);
            
            return new self.gf(self.strentity, self.codapp, self.codmodulo, optGriglia).then(function(gridcfg){
                self.gridCfg = gridcfg;
                self.ds.read();
                return self.gridCfg;
            });
        });
    }
    
    
    return Costruttore;
}


module.exports = GrigliaPermessi;