'use strict';

const moment = require("moment");

require("../styles/incassi.scss");

/**
 * 
 * @example
 * <incassidir /> 
 * 
 */
function IncassiDir(){
    return {
        restrict: 'E',
        template: require("./templates/incassidir.html"),
        scope: {

        },
        controller: ["$sce", "$http", "$q", "$timeout", "$scope", "ErrorManager", "Valida", "FieldFactory", 
            function($sce, $http, $q, $timeout, $scope, ErrorManager, Valida, FieldFactory){      
            var self = this;
            
            self.sce = $sce;
            self.$http = $http;
            self.$q = $q;
            self.$timeout = $timeout;                                                
            self.valida = Valida;
            self.ff = FieldFactory;
            self.em = ErrorManager;

            self.nazioni = [];
            self.italia = null;


            self.inizializza = function(){
                var self = this;
            
                return self.$q.all(
                    [
                        self.$http({
                            url: utils.urls.wsbase + "/tnazioni/grid",
                            method: "POST",
                            data: $.param({
                                take: 300,
                                skip: 0,
                                page: 1,
                                pageSize: 300,                
                            })
                        })//0 - nazioni
                    ]
                ).then(function(resps){
                    self.nazioni = bssRecursiveGetter(resps[0], "data.results", []);
                    self.italia = self.nazioni.find(function(naz){
                        return bssRecursiveGetter(naz, "iso", "").toUpperCase() === "IT";
                    })
                    return resps;
                });
            
                
            }           
            
            /**
             * 
             * @param {('lista'|'giornalieri'|'statistiche')} tipo 
             */
            self.incassi = function(tipo){
            
                var self = this;                                                      

            
                if(self.valida.validaForm(self.formObj)){

                    var da = null, a = null;
                
                    if(moment(self.filtraincassi.da).isAfter(moment(self.filtraincassi.a))){
                        a = self.filtraincassi.da;
                        da = self.filtraincassi.a;                    
                    } else {
                        da = self.filtraincassi.da;
                        a = self.filtraincassi.a;
                    }

                    da = moment.utc(da).startOf("day").toDate();
                    a = moment.utc(a).endOf("day").toDate();
                    
                    var nazione = bssRecursiveGetter(self, "filtraincassi.nazione", "");

                    //per convertire una data in json (ISO 8601 YYYY-MM-DDTHH:mm:ssZ) basta fare JSON.stringify però purtroppo aggiunge degli apici
                    var dajsonstr = JSON.stringify(da);
                    var ajsonstr = JSON.stringify(a);

                    var parametri = angular.extend({}, {
                        da: dajsonstr.substring(1, dajsonstr.length-1),
                        a: ajsonstr.substring(1, ajsonstr.length-1),
                    });

                    var url = utils.urls.wsbase + "/documentirdc/statistiche";

                    if(['giornalieri', 'lista'].includes(tipo)){
                        parametri = angular.extend({}, parametri, {tipoincasso: tipo, nazione: JSON.stringify(nazione)})
                        url = utils.urls.wsbase + "/documentirdc/stampaincassi";
                    }

                    self.$http({
                        method: "POST",
                        url: url,
                        data: $.param(parametri),
                        headers: {type: 'application/octet-stream'},
                        responseType: 'arraybuffer'
                    }).then(function(resp){
                        self.file = new Blob([resp.data], { type: 'application/pdf' });
                        self.urlFile = self.sce.trustAsResourceUrl(URL.createObjectURL(self.file))+'#zoom=FitH&pagemode=none';
                        self.tipoFile = tipo;
                        self.em.show("File degli incassi generato", "success")
                        //@todo provare
                        //self.labelPDF = (tipo === 'lista' ? "Lista incassi " : tipo === 'giornalieri' ? "Incassi giornalieri " : "Statistiche ") + "da " + moment(da).format("DD-MM-YYYY") + " a " + moment(a).format("DD-MM-YYYY") + " per " + nazione.nazione
                        //saveAs(self.file, tipo+'.pdf');
                        // self.$timeout(function(){
                        //     angular.element("#pdfpreviewer").attr("src", self.urlFile);
                        // })
                    }, function(err){
                        self.em.errorCB(err);
                    })
                }
            }
            
            
            self.scarica = function() {
                var self = this;
                saveAs(self.file, 'incassi_' + self.tipoFile + '.pdf');
            }


            self.inizializza().then(function(){

                self.campi = [
                    new self.ff({
                        "key": "da",
                        "tipo": "bssdate", 
                        "label": "Dalla Data",
                        "colonna": {},
                        "modello": {validations: {required: true},}
                    },
                    false,
                    {
                        "className": "col-lg-4",                                
                        defaultValue: moment()/*.subtract(7,'d')*/.toDate()
                    }).opzioni,
                    new self.ff({
                        "key": "a",
                        "tipo": "bssdate", 
                        "label": "Alla Data",
                        "colonna": {},
                        "modello": {validations: {required: true},}
                    },
                    false,
                    {
                        "className": "col-lg-4",            
                        defaultValue: moment().toDate()                    
                    }).opzioni,
                    // new self.ff({
                    //     "key": "nazione",
                    //     "label": "Nazione",
                    //     "tipo": "bssselect", 
                    //     "colonna": {},
                    //     "modello": {}
                    // },
                    // false,
                    // {
                    //     "className": "col-lg-4",       
                    //     defaultValue: self.italia,
                    //     templateOptions: {
                    //         options: self.nazioni,
                    //         valueProp: 'id',
                    //         labelProp: 'nazione'
                    //     }
                    // }).opzioni
                ]

            })
            
        }],
        controllerAs: "incassidirCtrl"
    };
}

module.exports = IncassiDir;