'use strict';

function StatoApp($rootScope, $q, $timeout){
    var self = this;
    self.$timeout = $timeout;
    self.$q = $q;
    $rootScope.$on("inmodifica", function(e){
        self.stato = "inmodifica";
    });          
    
}

StatoApp.prototype.confermaProcedi = confermaProcedi;

function confermaProcedi(){
    var self = this;
    if(self.stato && self.stato==='inmodifica'){//alla condizione aggiungere se sono presenti o meno modifiche pendenti altrimenti non occorre chiedere
        return self.$timeout(function(){if(confirm("Alcuni form sono in attesa di essere salvati. Procedere senza salvare?")){
            self.stato = null;
            return true;
        }
        else {            
            return false;
        }});       
    }    
    return self.$q.when(true);
}


module.exports = StatoApp;