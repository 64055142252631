'use strict';

/**
 * direttiva per gestire i moduli preferiti
 * 
 */

function BssPreferitiDir(){
    return {
        template: require("../templates/bsspreferiti.html"),
        restrict: 'E',
        scope: {
            "indropdown": "="
        },
        controller: "PreferitiController",
        controllerAs: 'preferitiCtrl',
        bindToController: true
    }
}




module.exports = BssPreferitiDir;