'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Checkbox valorizzata anche quando non selezionata (di default è undefined)
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSBooleanCheckbox(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssboolcheckbox',
        template: require("./bssbooleancheckbox.html"),
        //wrapper: ['bootstrapHasError'],
        defaultOptions: {
            defaultValue: false,
            className: 'col-xs-12',
            ngModelAttrs: {
                ngChange: {
                    statement: 'ng-change',
                }
            },
            ngModelElAttrs: {
                'cssCategory': 'bssboolcheckbox'
            },
            templateOptions: {
                ngChange: function(value, options, scope, $event) {
//                    if(typeof scope.model[scope.options.key] === 'undefined'){
//                        scope.model[scope.options.key] = false;
//                    }
                }
            }
        },
    }));
}

module.exports = BSSBooleanCheckbox;