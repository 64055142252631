'use strict';

function AutoSave($q, $timeout, $http, ErrorManager){
    return {
        restrict: 'E',
        //require: 'ngModel',
        transclude: true,
        template: require("./templates/autosavetpl.html"),
        link: function(s, e, a, ctrl, transclude){  
//            s.salvataggio = s.asctrl.salvataggio;
//            console.log("stato iniziale: ", s.asctrl.salvataggio);
//            s.$watch(function(){
//                return s.asctrl.salvataggio;
//            }, function(n, o){
//                if (n != o) {
//                    console.log("link) stato salvataggio aggiornato: ", n);
//                }                                
//            });
        },
        controller: ["$q", "$timeout", "$http", "$scope", "$element", "$attrs", "ErrorManager", AutoSaveCtrl],
        controllerAs: 'asctrl',
        bindToController: true,
    };
}

function AutoSaveCtrl($q, $timeout, $http, $scope, $element, $attrs, ErrorManager){
    var self = this; 
    self.$timeout = $timeout;
    self.$element = $element;
    self.$scope = $scope;
    self.$attrs = $attrs;
    self.$http = $http;
    self.em = ErrorManager;
    
    self.salvataggio = {incorso: false, success: false, error: false};
    self.controllaAggiornamenti();

    
    
//    self.$scope.$watch(function(){
//        return self.salvataggio;
//    }, function(n, o){
//        if (n != o) {
//            console.log("stato salvataggio aggiornato: ", n);
//        }                                
//    }); 
}

AutoSaveCtrl.prototype.salva = salva;
AutoSaveCtrl.prototype.controllaAggiornamenti = controllaAggiornamenti;

function controllaAggiornamenti(){
    var self = this;
    
    var timeout = null;
    self.$scope.$watch(function(){
        return self.$attrs.elemento;
    }, function(n, o){       
        if (n != o) {
            console.log("variazione: ", n, o);
            if (timeout) {
                self.$timeout.cancel(timeout);
            }
            //console.log("watcher elemento: ", self.$attrs.elemento, n, o);
            var vocedaaggiornare = self.$attrs.elemento;
            timeout = self.$timeout(function(){
                //console.log("richiamo salva passando il valore: ", vocedaaggiornare);
                self.salva(vocedaaggiornare);
            }, 1000);
        }                                
    });    
}

function salva(valore){
    var self = this;
    console.log("salvo il valore: ", valore);
    self.salvataggio = {incorso: true, success: false, error: false};
    
    //da cancellare
    self.salvataggio = {incorso: false, success: true, error: false};
    
//    self.$http({
//        url: utils.urls.wsbase + self.$attrs.url,
//        data: $.param({elemento: valore}), 
//        method: 'POST',
//    }).then(function(resp){
//        self.em.show("Salvataggio avvenuto", "success");
//        self.salvataggio = {incorso: false, success: true, error: false};
//    }, function(resp){
//        self.em.errorCB(resp);
//        self.salvataggio = {incorso: false, success: false, error: true};
//    });

}

module.exports = AutoSave;