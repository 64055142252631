'use strict';

require("../styles/detart.scss");

/**
 * 
 * @example
 * <detart idarticolo='1' /> 
 * 
 */
function DetArt(){
    return {
        restrict: 'E',
        template: require("./templates/detart.html"),
        scope: {
            idarticolo: '=',
            intooltip: '=?'
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", "FieldFactory", "Valida", "$rootScope", function(s, $http, $q, $timeout, ErrorManager, FieldFactory, Valida, $rootScope){      
            var self = this;
            self.ff = FieldFactory;
            self.valida = Valida;
            self.$http = $http;
            self.em = ErrorManager;
            self.rs = $rootScope;

            s.$watch(function(){
                return s.idarticolo
            }, function(n, o){
                console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.idarticolo = s.idarticolo;   
                    
                    self.inizializza().then(function(resp){
                        self.caricamento = false;
                    })
                }
            })  


            /*self.rs.$on("detart.modifica_associazione_articolo_fornitore", function(e, assoc){
                self.nuovaAssAF = assoc;
            });*/

            self.campiformNuovaAssAF = [
                new self.ff({
                    key: 'fornitore', 
                    tipo: 'bsssingleselect', 
                    label: 'Associa al Fornitore',
                    colonna: {}, 
                    modello: {
                        validations: {required: true},
                        strentity: 'fornitori', 
                        codmodulo: 'a', 
                        datatextfield: 'ragsoc', 
                        datavaluefield: 'id', 
                        template: '#= ragsoc #',
                    }
                }, 
                false, 
                {
                    className: 'col-lg-4', 
                }).opzioni,
                new self.ff({
                    "key": "codartforn",
                    "tipo": "bssinput", 
                    "label": "con Cod. Articolo Fornitore",
                    "colonna": {},
                    "modello": {
                        validations: {required: true},
                    }
                },
                false,
                {
                    "className": "col-lg-4",        
                }).opzioni,
                new self.ff({
                    key: "prezzo", 
                    tipo: 'bssnumeric', 
                    label: 'al Prezzo',
                    placeholder: 'Prezzo',
                    colonna: {}, 
                    modello: {
                        validations: {required: true},
                    }
                }, 
                false, 
                {
                    className: 'col-lg-4', 
                    "ngModelElAttrs": {"num-neg": 'false', "num-fract": "2", "num-fixed": 'true'}
        
                }).opzioni
            ];

            self.creaAssAF = function(){

                var self = this;
            
                if(self.valida.validaForm(self.formNuovaAssAF)){
                    
                    // var dati = angular.extend({}, self.nuovaAssAF, {idarticolo: self.articoloAssociabile.articolo.id})
                    var dati = {
                        codice: self.nuovaAssAF.codartforn,
                        idarticolo: self.articolo.id,
                        idfornitore: self.nuovaAssAF.fornitore.id,
                        prezzo: self.nuovaAssAF.prezzo
                    };
                    console.log("posso creare una nuova associazione", dati)
                    self.inassociazione = true;
                    self.$http({
                        method: "POST",
                        url: utils.urls.wsbase + "/articolirdc/associafornitore",
                        data: $.param(dati)
                    }).then(function(resp){
                        self.em.showT("DOCUMENTISTD.NUOVAASSAF", "success");
                        self.nuovaAssAF = {};
                        self.formNuovaAssAF.$setPristine();
                        self.formNuovaAssAF.$setUntouched();
                        self.inassociazione = false;                        
                        self.inizializza()
                        self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore")
                    })
                }
                
            }

            self.forzaRefresh = function(){
                self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore")
            }
            
            self.inizializza = function(){
                console.log("inizializzo preview articolo", s)
                self.idarticolo = s.idarticolo;
                self.intooltip = s.intooltip || false;
                self.loading = true;
                return $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/articolirdc/cerca",
                    data: $.param({id: self.idarticolo})
                }).then(function(resp){
                    self.articolo = resp.data;
                    if(self.articolo.servizio !== true){
                        return $http({
                            url: utils.urls.wsbase + "/tcatstastd/" + self.articolo.catstat.id
                        }).then(function(resp){
                            self.catstat = resp.data
                            return {articolo: self.articolo, catstat: self.catstat};
                        })
                    } else {
                        return null
                    }
                    
                    
                })
            }
        }],
        controllerAs: "detartCtrl"
    };
}

module.exports = DetArt;