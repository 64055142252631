'use strict';

/**
 * Permette di ottenere un DataSource Kendo
 * 
 * @class
 * @param {DSCfgFactory} DSCfgP
 * @example
 * new DSFactory("tcomuni", "aaa", "aaa", null); //ritorna un datasource da passare ad esempio ad una KendoGrid
 */
function DSFactory(DSCfgP, ErrorManager){
    
    /**
     * 
     * @param {type} strentity
     * @param {type} codapp
     * @param {type} codmodulo
     * @param {type} opzioni
     */
    function Costruttore(strentity, codapp, codmodulo, opzioni){
        this.strentity = strentity;
        this.codapp = codapp;
        this.codmodulo = codmodulo;
        this.opzioniUtente = opzioni;
        
    }
    
    Costruttore.prototype.inizializza = inizializza;
    
    /**
     * Ottiene prima la configurazione del datasource, quindi istanzia un datasource kendo e lo ritorna nella promise
     * 
     * @memberOf DSFactory
     * @returns {promise}
     */
    function inizializza(){
        var self = this;
        return new DSCfgP(self.strentity, self.codapp, self.codmodulo, self.opzioniUtente).then(function(cfg){
            self.datasource = new kendo.data.DataSource(cfg);    
            //console.log("datasource", self.datasource)                  
            return self.datasource;
        });
    }
    
    return Costruttore;
}

module.exports = DSFactory;