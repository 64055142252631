'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @param {type} FieldFactory
 * @returns {undefined}
 */
function BSSMultilocalitaController($scope, $filter, $timeout, $http, $sce, ErrorManager, DSF, FieldFactory, Valida){                
    
//    console.log("campo localita: ", $scope.options.key, $scope.options.data.modello.validations);    
    
    $scope.stato = {comuneSelezionato: false};
    
//    console.log("scope multiselect: ", $scope);
    
    $scope.modello = $scope.$parent.$parent.$parent.model;
    
    $scope.modello.cercaper = 'cap';
    
    $scope.$watch(function(){
        return $scope.modello.cercaper;
    }, function(n, o){
        var multisel = $("#bssmultisellocalita").data("kendoMultiSelect"); 
        if(typeof multisel !== 'undefined'){
            console.log("multisel: ", multisel, n);
            multisel.dataSource.filter([]);
            multisel.setOptions({dataTextField: n || 'cap'}); 
        }
        
    });
    
    if($scope.model[$scope.options.key] === undefined){
        $scope.model[$scope.options.key] = {}; 
    }
    
    $scope.wizardlocalitaUrl = $sce.trustAsResourceUrl("wizardlocalita?mode=iframe");
    
    $scope.getCampoMultilocalita = function(){
        return new FieldFactory(
        {
            "id": "bssmultisellocalita",
            "key": "localita",
            "tipo": "bssmultiselect", //no
            "label": "Localita",
            "colonna": {},
            "modello": {
                "validations": {
                },
                strentity: "tcomuni", 
                codmodulo: 'a', 
                datatextfield: 'cap', 
                datavaluefield: 'id', 
                itemtemplate: '#= cap # - #= comune #',
                tagtemplate:  '#= cap # - #= comune #',
                groupField: 'group',
                readUrl: utils.urls.wsbase + '/tcomuni/ricerca',
                onChange: function(e){
                    $timeout(function(){
                        var valsel = e.sender.value();
                        var modelval = $scope.model.localita || [];
                        console.log("multisel localita change: ", valsel, modelval, valsel.length==modelval.length);
                        if(!valsel.length && valsel.length!==modelval.length){
                            $scope.model.localita = [];
                        }
                    });                    
                }
            }
        },
        false,
        {
            "className": "col-lg-9",                                
        }).opzioni;
    }
    
    $scope.getCampoCercaPer = function(){
        return new FieldFactory(
            {
                "key": "cercaper",
                "tipo": "bssselect", 
                "label": "Cerca Per",
                "colonna": {},
                "modello": {
                    "validations": {
                    }
                }
            },
            false,
            {
                "className": "col-lg-3",                
                templateOptions: {
                    options: [{value: 'cap', text: 'CAP'}, {value: 'comune', text: 'Comune'}]
                }
            }).opzioni;
    }
    
    $scope.campi = [
        $scope.getCampoCercaPer(),
        $scope.getCampoMultilocalita(),
    ];
}

module.exports = BSSMultilocalitaController;