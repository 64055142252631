"use strict";

/**
 * Controlla che il valore sia una data valida
 * 
 * @class
 * @param {$translate} $translate
 */
function IsDateTimeFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf IsDateTimeFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.ISDATETIME", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });

    }
    
    Costruttore.prototype.expression = expression;

    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf IsDateTimeFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */    
    function expression(viewValue, modelValue, scope){
        var valore = modelValue || viewValue || null;
        var invalidMap = ["anni", "mesi", "giorni", "ore", "minuti", "secondi", "millisecondi"];
        if(valore === null){
            return true;
        }
        console.log("valido " + valore);
        var valorev = moment(valore);
        console.log(valorev.toDate());
        scope.validacampo = {
            datetime: {
                cause: "",
                valido: true,
            },
        };
        if(!valorev.isValid()){
            scope.validacampo.datetime.valido = false;
            scope.validacampo.datetime.cause = invalidMap[valorev.invalidAt()];
        }
        return scope.validacampo.datetime.valido;
    }
        
    return Costruttore;
}

module.exports = IsDateTimeFct;