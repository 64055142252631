'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
const moment = require("moment");


/**
 * Controller relativo al template della route /statistiche
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} StatisticheFunzioniAggiuntive
 */
function StatisticheCtrl($http, $q, $timeout, $interval, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, StatisticheFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = StatisticheFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.em = ErrorManager;
    self.$interval = $interval;

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype);

    self.disabilitaricarica = false;
    self.saikuurl = utils.urls.saiku; 
    self.stato = [];
    
    self.ricarica();
}

StatisticheCtrl.prototype.ricarica = ricarica;
StatisticheCtrl.prototype.checkStatoSaiku = checkStatoSaiku;
StatisticheCtrl.prototype.pushlog = pushlog;

function ricarica(){
    var self = this;

    self.disabilitaricarica = true;
    self.tentativo = 0;
    self.stato = [];
    self.pushlog("riavvio il servizio");
    
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/statistiche/restartsaiku"
    }).then(function(resp){

        self.pushlog("controllo lo stato del servizio");

        self.checkStatoSaiku().then(function(stato){
            self.pushlog("Saiku in stato " + JSON.stringify(stato));
            if(stato === 200){
                self.disabilitaricarica = false;
                self.pushlog("apro la pagina di Saiku");
                window.open(self.saikuurl, '_blank').focus();
            }
        });

        
    })

}

function checkStatoSaiku() {
    var self = this;
    self.tentativo++;
    self.pushlog("tentativo nr. " + self.tentativo)
    return self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/statistiche/statussaiku"
    }).then(function(resp){
        if(resp.data === 404){
            self.pushlog("servizio non raggiungibile riprovo tra 5 secondi");

            return self.$timeout(function(){
                return self.checkStatoSaiku();
            }, 5000)
            
        }
        return resp.data;
    }, function(err){
        return err.status;
    })
}

function pushlog(messaggio){
    var self = this;
    self.stato.unshift("["+moment().format("DD-MM-YYYY HH:mm.ss.SSS")+"]" + messaggio)
}

module.exports = StatisticheCtrl;
