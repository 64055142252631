/**
 * @typedef {Object} MappaturaImport
 * @property {string} chiavepath - usage: cfg grid import e log, upload
 * @property {string} urlimport - usage: webservice import
 * @property {string} formato - header della grid delle importazioni
 * @property {string} label - label del campo di upload
 * 
 * 
 * @typedef {Object.<string, MappaturaImport>} MappaImport - la chiave è l'entity importata
 */


/**
 * @type {MappaImport}
 */
var mappaimportstd = {
    "articoli": {
        chiavepath: "ARTICOLI_I_CSV", //usage: cfg grid import e log, upload
        urlimport: "articolirdc/importcsv", //usage: import
        formato: "CSV", //header grid
        label: "Importa articoli", //label campo upload
    },
    "auto": {
        chiavepath: "ANAAUTO_I_XLS", //usage: cfg grid import e log, upload
        urlimport: "tmodelliauto/importxls", //usage: import
        formato: "XLS", //header grid
        label: "Importa Modelli Auto", //label campo upload
    },
}

module.exports = mappaimportstd;