'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione del campo plain text
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSPlainText(formlyConfig, formlyValidationMessages, Valida){
    var cfg = $.extend(true, {}, new BssBasicField(), {
        name: 'bssplaintext',
        template: require("./bssplaintext.html"),
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssplaintext'
            },
        }
    });
    formlyConfig.setType(cfg);
}

module.exports = BSSPlainText;