'use strict';

/**
 * Component relativo alla toolbar
 * 
 * @class
 * @example
 * <bss-toolbar></bss-toolbar> 
 */
function ToolbarCmp(){
    this.template = require("../templates/toolbar.html");
    this.controller = "ToolbarCtrl as toolbar";    
}

module.exports = ToolbarCmp;