'use strict';

/**
 * Blocca l'autocomplete di chrome che può dare problemi con alcuni tipi di campi e validazione.
 * 
 * @class
 * 
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BlockChromeAutocomplete(formlyConfig, formlyValidationMessages){
    // NOTE: This next line is highly recommended. Otherwise Chrome's autocomplete will appear over your options!
    formlyConfig.extras.removeChromeAutoComplete = true;
}


module.exports = BlockChromeAutocomplete;