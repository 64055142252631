'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/anagrafiche
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function AnagraficheRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "anagrafiche",
        url: "/anagrafiche?strentity",
        //templateUrl: "lib/anagrafiche/scripts/templates/anagrafiche.html",
        template: require("../templates/anagrafiche.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "FormDaHeaderFactory", "Valida", "FieldFactory", "DSFactory", "GridFactory", "GridCfgFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "ErrorManager", "AnagraficheFunzioniAggiuntive", require("../AnagraficheCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = AnagraficheRoutes;
