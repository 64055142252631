'use strict';

function Bloccaschermo($rootScope){
    this.logStati = [];
    this.attivo = false;
    var self = this;
    
    //spostato in toolbarsrv
    // $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
    //     if(angular.element("form:not(.ng-hide)").length){
    //         var risposta = confirm("Nella pagina sono presenti dei moduli da compilare, sei sicuro di voler procedere?");
    //         if(!risposta){
    //             event.preventDefault();
    //             return;
    //         }
    //     }
        
    //     if (toState.resolve) {
    //         console.log("mostro screensaver per cambio di stato");
    //         self.start();
    //     }
    // });
    //
    // $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
    //     if (toState.resolve) {
    //         console.log("nascondo screensaver per cambio di stato");
    //         self.stop();
    //     }
    // });
}

Bloccaschermo.prototype.start = start;
Bloccaschermo.prototype.stop = stop;

function start(){
    this.logStati.push(this.attivo);
    this.attivo = true;
    this.logStati.push(this.attivo);
}

function stop(){
    this.logStati.push(this.attivo);
    this.attivo = false;
    this.logStati.push(this.attivo);
}

module.exports = Bloccaschermo;