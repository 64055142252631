'use strict';

const { parseTwoDigitYear } = require("moment");

//da un array di oggetti ritona solo gli id
var onlyId = function(v){return (v !== null && v !== undefined) ? v.id : null }

//confronta 2 set return true se sono uguali
var eqSet = function(a, b){
    return a.size === b.size ? 
        a.every(function(v){ return b.includes(v)}) : false
}


/**
 * Costruisce una sidebar menu per il tema admin lte 2
 * 
 * @class
 * @param {type} $http
 * @param {type} $templateCache
 * @param {type} MenuManagerRS
 */
function AdminLTE2SideMenu($http, $timeout, $interval, $state, $templateCache, MenuManagerRS, FieldFactory, Valida, ErrorManager) {
    var self = this;
    this.rs = MenuManagerRS;
    this.$http = $http;
    this.$timeout = $timeout;
    this.tpl = "src/bsslib/sidebarmenu/scripts/templates/AdminLTE2SideMenuTpl.html";
    self.ss = angular.element(document.body).injector().get("SessioneService");   
    self.ff = FieldFactory; 
    self.valida = Valida;
    self.em = ErrorManager;
    self.$state = $state;

    $timeout(function(){
        self.sidebarCollapsed = $("body").hasClass("sidebar-collapse")
    })
    

}

AdminLTE2SideMenu.prototype.closeAllCtxmenu = closeAllCtxmenu;
AdminLTE2SideMenu.prototype.apriCtxmenuVoce = apriCtxmenuVoce;
AdminLTE2SideMenu.prototype.checkSidebarCollapsedStatus = checkSidebarCollapsedStatus;
AdminLTE2SideMenu.prototype.popupForm = popupForm;
AdminLTE2SideMenu.prototype.popupElimina = popupElimina;
AdminLTE2SideMenu.prototype.eliminaVoce = eliminaVoce;
AdminLTE2SideMenu.prototype.salvaVoce = salvaVoce;
AdminLTE2SideMenu.prototype.vocipreferite = vocipreferite;
AdminLTE2SideMenu.prototype.riordinapreferiti = riordinapreferiti;
AdminLTE2SideMenu.prototype.phpreferiti = phpreferiti;
AdminLTE2SideMenu.prototype.hintpreferiti = hintpreferiti;
AdminLTE2SideMenu.prototype.inpreferiti = inpreferiti;
AdminLTE2SideMenu.prototype.togglePreferito = togglePreferito;
AdminLTE2SideMenu.prototype.getVoci = getVoci;
AdminLTE2SideMenu.prototype.getVociRicercabili = getVociRicercabili;
AdminLTE2SideMenu.prototype.costruisciAlbero = costruisciAlbero;
AdminLTE2SideMenu.prototype.getAree = getAree;
AdminLTE2SideMenu.prototype.ottieniFigli = ottieniFigli;
AdminLTE2SideMenu.prototype.ottieniFigliDiretti = ottieniFigliDiretti;
AdminLTE2SideMenu.prototype.getMenu = getMenu;
AdminLTE2SideMenu.prototype.toggleVoceMenu = toggleVoceMenu;

function closeAllCtxmenu(){

    var allctxs = $(".k-context-menu").toArray().map(function(el){return $(el).data("kendoContextMenu")});
    allctxs.forEach(function(ctx) {
        ctx.close()
    })

}

function apriCtxmenuVoce(voce){
    var self = this;
    self.opzioniaggiuntive = null;
    
    var allctxs = $(".k-context-menu").toArray().map(function(el){return $(el).data("kendoContextMenu")})

    allctxs.forEach(function(ctx) {
        var idfilter = ctx.options.filter.split("#voce_")[1].split(".")[0];
        if(""+idfilter !== ""+voce.id && ctx.popup._activated){
            ctx.close()
        }
    })

    console.log("carico le voci aggiuntive per la voce menu", voce);

    if(voce.modulo !== null && voce.modulo !== undefined && !isNaN(voce.modulo.id)){

        /**
         * Ottiene le voci menu che rimandano al form di modifica della configurazione del modulo
         * 
         * @param {*} entity 
         */
        function ottienivoci(entity) {
            return self.$http({
                method: "POST",
                url: utils.urls.wsbase + "/moduli/getconfig",
                data: $.param({id: voce.modulo.id, entity: entity})
            }).then(function(resp){
                self.opzioniaggiuntive = resp.data                
                return self.opzioniaggiuntive;
            }, function(err){
                self.em.errorCB(err);         
                return err;       
            })
        }

        self.$http({
            method: "GET",
            url: utils.urls.wsbase + "/"+voce.modulo.strentity+"/entity"
        }).then(function(resp){
            var entity = resp.data.classe;
            ottienivoci(entity).then(function(){
                //se anche una sola delle opzioni aggiuntive è null richiamo header seguito da ottienivoci
                //test MODEL@com.bsssrl.ricambiweb_ent.ArticoloRdc@
                if(Object.values(self.opzioniaggiuntive).includes(null)){
                    self.$http({
                        url: utils.urls.wsbase + "/table/header",
                        method: "POST",
                        data: $.param({
                            entity: entity,
                            codapp: voce.modulo.codapp,
                            codmodulo: voce.modulo.codmodulo,
                            strentity: voce.modulo.strentity
                        })        
                    }).then(function(){
                        ottienivoci(entity)
                    })
                }
            })
        })
        
    }
}

function checkSidebarCollapsedStatus(){
    var self = this;
    self.$timeout(function(){
        self.sidebarCollapsed = $("body").hasClass("sidebar-collapse")
    }, 200)
}

function popupForm(voce, tipo, padre){
    var self = this;

    if(padre === undefined){
        padre = null;
    }

    if(tipo === undefined || tipo === null){
        tipo = 'Area';
    }

    


    if(voce === null || voce === undefined){//sono in aggiunta
        console.log("in aggiunta", tipo)
        self.vocemodello = {tipomenu: tipo, parentMenu: (padre !== null && padre.id !== undefined) ? padre.id : padre};
        self.inmodifica = false;
    } else {//sono in modifica
        console.log("in modifica", voce, tipo)
        self.vocemodello = angular.copy(voce);
        self.inmodifica = true;
    }

    self.vocecampi = [
        new self.ff({
            key: 'name', 
            tipo: self.inmodifica ? 'bssplaintext' : 'bssinput', 
            label: 'Codice',
            colonna: {}, 
            modello: {
                validations: {required: true},                
            }
        }, 
        false, 
        null).opzioni,    
        new self.ff({
            key: 'descrizione', 
            tipo: 'bssinput', 
            label: 'Descrizione',
            colonna: {}, 
            modello: {
                validations: {required: true},                
            }
        }, 
        false, 
        null).opzioni,        
        new self.ff({
            key: 'path', 
            tipo: 'bssinput', 
            label: 'Path',
            colonna: {}, 
            modello: {
                validations: {required: true},                
            }
        }, 
        false, 
        null).opzioni,
        new self.ff({
            key: 'icona', 
            tipo: 'bssinput', 
            label: 'Icona',
            colonna: {}, 
            modello: {
                validations: {required: true},                                
            }
        }, 
        false, 
        null).opzioni,
        new self.ff({
            key: 'ordine', 
            tipo: 'bssnumeric', 
            label: 'Ordine',
            colonna: {}, 
            modello: {
                validations: {required: true},    
                       
            }
        }, 
        false, 
        {
            "ngModelElAttrs": {"num-neg": 'false', "num-fract": "0", "num-fixed": 'false'}

        }).opzioni,
        
    ]


    if(self.inmodifica){
        self.vocecampi.unshift(new self.ff({
            key: 'tipomenu', 
            tipo: 'bssplaintext', 
            label: 'Tipo',
            colonna: {}, 
            modello: {}
        }, 
        false, 
        null).opzioni)           
    }




    if(!["Area", "Menu"].includes(tipo)){
        if(["SPALink", "Link", "ModuloJar"].includes(tipo)){//tipi con url
            self.vocecampi.push(new self.ff({
                key: 'url', 
                tipo: 'bssinput', 
                label: 'URL',
                colonna: {}, 
                modello: {
                    validations: {required: true},                
                }
            }, 
            false, 
            null).opzioni,)
        } else {//tipi con modulo
            self.vocecampi.push(new self.ff({
                key: 'modulo', 
                tipo: 'bsssingleselect', 
                label: 'Modulo',
                colonna: {}, 
                modello: {
                    validations: {required: true},        
                    strentity: "moduli",
                    codapp: "",
                    codmodulo: "",
                    datatextfield: 'modulo', 
                    datavaluefield: 'id', 
                    template: '#= modulo #',
                }
            }, 
            false, 
            null).opzioni)
        }
    
    }
    
    self.vocecampi.push(new self.ff({
        key: "solalettura",
        tipo: 'bssboolcheckbox', 
        label: 'Sola Lettura',
        colonna: {}, 
        modello: {
            validations: {
            },
        }
    }, 
    false, 
    null).opzioni,)

    self.formvocewin.open().center();
}

function popupElimina(voce){
    var self = this;

    self.voceselelimina = voce;

    self.confirmeliminavocewin.open().center()
}


function eliminaVoce(voce){
    var self = this;

    console.log("elimino", voce);

    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/menumanager/cancella",
        data: $.param({id: JSON.stringify([voce.id])})
    }).then(function(resp){
        console.log("eliminata voce", voce, resp)    
        self.voceselelimina = null;
        self.getMenu()
        self.em.show("Voce menu eliminata", "success")
        self.confirmeliminavocewin.close()
    }, function(err){
        self.em.errorCB(err)
    })

}

function salvaVoce(){
    var self = this;

    if(self.valida.validaForm(self.voceformObj)){
        //@todo

        var url = utils.urls.wsbase + "/menumanager/" + (self.inmodifica ? "modifica" : "crea");

        console.log("salvo la voce menu", self.vocemodello, url)

        self.caricasalva = true;

        self.$http({
            method: "POST",
            url: url,
            data: $.param({elemento: JSON.stringify(self.vocemodello)})
        }).then(function(resp){
            self.caricasalva = false;
            console.log("crea voce menu", self.vocemodello, resp)
            self.vocemodello = null;
            self.getMenu()
            self.em.show("Voce menu " + (self.inmodifica ? "modificata" : "aggiunta") + ". Aggiorna i permessi per vedere le modifiche." , "success")
            self.formvocewin.close()
        }, function(err){
            self.caricasalva = false;
            self.em.errorCB(err)
        })
        
    }
}


function vocipreferite(contesto){
    var self = this;
    var voci = [];
    if(contesto === 'toolbar'){        
        console.log("voci", self.voci, "preferiti", self.ss.sessione.homepref);
    }
    if(self.ss.sessione.homepref !== null && typeof self.ss.sessione.homepref !== 'undefined' && typeof self.voci !== 'undefined' && self.voci !== null) {
        voci = self.ss.sessione.homepref.map(function(voce){
            if(Array.isArray(self.voci)){
                return self.voci.find(function(v) {
                    return v.id === voce;
                })
            } else {
                return [];
            }
            
        }).filter(function(v){
            return v !== null && v !== undefined
        })
    }

    
    //gli id delle voci ottenute in precedenza
    var previd =  self.vp !== undefined ? self.vp.map(onlyId) : [];

    //gli delle voci ottenute ora
    var currid = voci.map(onlyId).filter(function(v){return v !== null});

    //aggiorno il controller solo se non ho voci pregresse e sono cambiati gli id
    if(self.vp === undefined || !eqSet(previd, currid)){
        self.vp = voci
    }
    
    return self.vp;
}

function riordinapreferiti(e){
    var self = this;
    var sortable = e.sender;

    // prevent the sortable from modifying the DOM
    e.sender.draggable.dropped = true;
    e.preventDefault();

    var preferiti = angular.copy(self.ss.sessione.homepref);

    preferiti.splice(
        e.newIndex, 0, preferiti.splice(e.oldIndex, 1)[0]
    );
    
    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/cfgutenti",
        data: $.param({nome: 'homepref', configurazione: JSON.stringify(preferiti)})
    }).then(function(resp){
        self.ss.aggiorna('homepref', preferiti)
    })
    
}

function phpreferiti(element){
    var self = this;
    return element.clone().addClass("placeholder").text("sposta qui");
}

function hintpreferiti(element){
    return element.clone().addClass("hint");
}

function inpreferiti(idvoce){
    var self = this;
    if(self.ss.sessione.homepref !== null && typeof self.ss.sessione.homepref !== 'undefined') {
        return self.ss.sessione.homepref.indexOf(idvoce) !== -1;
    } else {
        return false
    }

}

function togglePreferito(idvoce){
    var self = this;
    var preferiti = angular.copy(self.ss.sessione.homepref) || [];
    console.log("toggle preferito", idvoce, preferiti)
    if(self.inpreferiti(idvoce)){
        preferiti = preferiti.filter(function(preferito){
            return preferito !== idvoce
        })
    } else {
        preferiti.push(idvoce);
    }
    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/cfgutenti",
        data: $.param({nome: 'homepref', configurazione: JSON.stringify(preferiti)})
    }).then(function(resp){
        self.ss.aggiorna('homepref', preferiti)
    })
}

/**
 * 
 * @memberOf AdminLTE2SideMenu
 * 
 */
function getMenu() {
    var self = this;
    this.getVoci().then(function (vocimenu) {
        self.costruisciAlbero();
        //console.log("aree: ", self.aree);
    });
}

function getVociRicercabili(vocimenu) {
    var self = this;

    /**
     * @description 
     * Data una voce menu ritorna il tpl (non lo utilizzerò, mi serve come promemoria per costruire l'interfaccia)
     * @param {*} voce 
     */
    var map2link = function map2link(voce) {
        var tpl = "";

        switch (voce.tipomenu) {
            case 'SPALink':
                tpl = "<a ng-if=\"voce.url.length\" ui-sref=\"{{voce.url}}\">\n        <i class='{{voce.icona}}'></i>\n        <span>{{voce.descrizione}}</span>\n      </a>";
                break;

            case 'LinkModulo':
                tpl = "<a ui-sref=\"moduli({strentity: voce.modulo.strentity, codapp: voce.modulo.codapp, codmodulo: voce.modulo.codmodulo})\">\n          <i class='{{voce.icona}}'></i>\n          <span>{{voce.descrizione}}</span>\n      </a>";
                break;

            case 'Link':
                tpl = "<a ng-href=\"{{voce.url}}\" >\n          <i class='{{voce.icona}}'></i>\n          <span>{{voce.descrizione}}</span>\n      </a>";
                break;
        }

        return tpl;
    };

    /**
     * @description 
     * lista delle voci menu che hanno figli
     */
    var parentList = Array.from(new Set(vocimenu.map(function (voce) {
        return voce.parentMenu;
    }).filter(function (parent) {
        return parent !== null;
    }))).reduce(function (obj, parent) {
        obj[parent] = vocimenu.find(function (voce) {
            return voce.id === parent;
        });
        return obj;
    }, {});

    /**
     * @description
     * lista delle voci menu che NON hanno figli (foglie)
     */
    var leaves = vocimenu.filter(function (voce) {
        return typeof parentList[voce.id] === 'undefined';
    });

    /**
     * @description
     * mappa la lista delle foglie in un oggetto in cui le chiavi delle property sono i breadcrumb dei testi delle voci menu 
     * dalla radice alla foglia separate da '> '. i valori delle property sono le voci menù stesse 
     * cioè l'intero oggetto che rappresenta la foglia con l'aggiunta della property tpl (non verrà utilizzata serve da promemoria).
     */
    var breadCrumbedLeaves = leaves.map(function (leaf) {
        return ricostruisciBreadCrumbMenu(parentList, leaf, [leaf]).reverse();
    }).reduce(function (obj, breadCrumbArr) {
        var chiave = breadCrumbArr.reduce(function (str, breadcrumb, i) {
            return str + /* "<i class=" + breadcrumb.icona + "></i> " +*/ breadcrumb.descrizione + (i < breadCrumbArr.length - 1 ? " > " : "");
        }, "");
        obj[chiave] = breadCrumbArr[breadCrumbArr.length - 1];
        obj[chiave].tpl = map2link(breadCrumbArr[breadCrumbArr.length - 1]);
        return obj;
    }, {});

    /**
     * @description
     * partendo dalla foglia sale ricorsivamente fino alla radice creando un array in cui sono presenti
     * i testi delle voci menù incontrate. L'array ritornato presenta come primo elemento il testo della foglia, 
     * seguito dal testo del padre della foglia, seguito dal testo del padre del padre della foglia ... fino alla radice
     * @param {*} pl //parentList
     * @param {*} f //foglia
     * @param {*} b //array breadcrumb 
     * @returns {[String]} //b 
     */
    function ricostruisciBreadCrumbMenu(pl, f, b) {
        if (f.parentMenu === null) return b;
        var newf = pl[f.parentMenu];
        b.push(newf);
        return ricostruisciBreadCrumbMenu(pl, newf, b);
    }

    var vocidasaltare = [];
    
    self.listaBreadCrumbs = Object.keys(breadCrumbedLeaves);
    
    self.vociRicercabili = Object.values(breadCrumbedLeaves).reduce(function(res, val, indice){
        
        if(!["area", "menu"].includes(val.tipomenu.toLowerCase())){
            res[self.listaBreadCrumbs[indice]] = val;
        } else {
            //console.log("dovrei saltare", val)
            vocidasaltare.push(self.listaBreadCrumbs[indice]);
        }
        return res;

    }, {});

    self.listaBreadCrumbs = self.listaBreadCrumbs.filter(function(b){
        return !vocidasaltare.includes(b)
    });

    self.mappaUrlVoci = Object.values(self.vociRicercabili).reduce(function(obj, voce){
        if(typeof voce.url !== 'undefined' && voce.url !== null){
            obj[voce.url] = voce.descrizione;
        }        
        return obj;
    }, {})

    console.log("mappa voci menu", self.mappaUrlVoci);
    
    //console.log("breadcrumbs", self.listaBreadCrumbs, "voci ricercabili", breadCrumbedLeaves)
}

/**
 * 
 * @memberOf AdminLTE2SideMenu
 * 
 */
function getVoci() {
    var self = this;
    return self.rs.utente().then(function (resp) {
        var menuresponse = resp.data;
        var lista = menuresponse.list;
        //indica se mostrare o meno il pulsate per entrare in modalità modifica del menù
        self.vocieditabili = menuresponse.canedit;
        self.voci = _.map(lista, function (voce) {
            if (voce.icona === '' || voce.icona === null || voce.icona === undefined) {
                voce.icona = 'fa fa-cogs';
            }
            //se la voce menù è un link e l'url specificato è relativo, lo faccio diventare assoluto mettendo come prefisso il baseurl
            //in questo modo se installo il gestionale in una sotto cartella della webroot non avrò problemi
            if(voce.tipomenu === "Link"){
                if(voce.url.startsWith("/")){
                    voce.url = document.querySelector("base").href + voce.url.substring(1) 
                }                
            }
            return voce;
        });
        self.getVociRicercabili(self.voci);

        console.log("voci menu", self.voci)
        return self.voci;
    });
}

/**
 * 
 * @memberOf AdminLTE2SideMenu
 * 
 */
function getAree(voci) {
    var self = this;
    self.aree = _.filter(voci, function (voce) {
        return voce.tipomenu === 'Area';
    });
    return self.aree;
}

/**
 * 
 * @memberOf AdminLTE2SideMenu
 * 
 */
function costruisciAlbero() {
    var self = this;
    self.getAree(self.voci);
    _.map(self.aree, function (area, indice) {
        self.aree[indice].figli = self.ottieniFigli(self.voci, self.aree[indice]);
    });
    return self.aree;
}

/**
 * 
 * @memberOf AdminLTE2SideMenu
 * 
 */
function ottieniFigliDiretti(voci, nodo) {
    return _.filter(voci, function (voce) {
        return voce.parentMenu === nodo.id;
    });
}

/**
 * Ottiene ricorsivamente tutti i figli del nodo passato come secondo parametro
 * 
 * @memberOf AdminLTE2SideMenu
 * @param {type} voci
 * @param {type} nodo
 * @returns {array} figli tutti i figli del nodo
 */
function ottieniFigli(voci, nodo) {
    var self = this;
    var figli = _.sortBy(self.ottieniFigliDiretti(voci, nodo), function (figlio) {
        return figlio.ordine;
    });
    var nfigli = figli.length;
    for (var x = 0; x < nfigli; x++) {
        figli[x].figli = self.ottieniFigli(voci, figli[x]);
    }
    return figli;
}

function toggleVoceMenu($event){
    var self = this;
    var target = angular.element($event.target).closest(".treeview")  //angular.element($event.delegateTarget);
    var treemenu = angular.element(target.children("ul.treeview-menu.menu-open"));
    var collapsedsidebar = angular.element(document.body).hasClass("sidebar-collapse");
    var voceattiva = target.hasClass("active");

    if(collapsedsidebar && voceattiva){
        //console.log("la voce è attiva?", target.html(), voceattiva, "sidebar collapsed?", collapsedsidebar, "treemenu aperto?", treemenu.hasClass("menu-open"), treemenu);
        //console.log("chiudo il menu", target.attr("id"));
        self.$timeout(function(){
            treemenu.removeClass("menu-open");
            treemenu.css({display: "none"});
            target.removeClass("active");        
        })
        
    }
    
    

}

module.exports = AdminLTE2SideMenu;