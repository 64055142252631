'use strict';

/**
 * Rest client per l'endpoin menumanager
 * 
 * @class
 * @param {type} $http
 */
function MenuAccessManagerRS($http, ErrorManager){
    this.baseurl = utils.urls.wsbase + "/menuaccessmanager";
    this.$http = $http;
    this.em = ErrorManager;
    this.nomeEntity = 'menuaccessmanager';
}

var IdentityRS = require("moduleFold/genericidentity/scripts/IdentityRS");
angular.extend(MenuAccessManagerRS.prototype, IdentityRS.prototype);


MenuAccessManagerRS.prototype.cerca = cerca;
MenuAccessManagerRS.prototype.elimina = elimina;
MenuAccessManagerRS.prototype.gruppimenu = gruppimenu;
MenuAccessManagerRS.prototype.utentimenu = utentimenu;

/**
 * @memberOf MenuAccessManagerRS
 * @returns {promise}
 */
function cerca(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/cerca",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}


/**
 * @memberOf MenuAccessManagerRS
 * @returns {promise}
 */
function elimina(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/cancella",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuAccessManagerRS
 * @returns {promise}
 */
function gruppimenu(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/gruppimenu",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuAccessManagerRS
 * @returns {promise}
 */
function utentimenu(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/utentimenu",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

module.exports = MenuAccessManagerRS;