'use strict';

/**
 * Controller legato al template principale del modulo bss/menu
 * 
 * @class
 * @returns {MenuManagerCtrl}
 */
function MenuManagerCtrl(MenuIdentity, $timeout, ErrorManager, ToolbarSrv, SideMenuSrv){
    var self = this;
    this.mi = MenuIdentity;
    this.$timeout = $timeout;
    this.em = ErrorManager;
    this.inizializza();
    this.formOpts = {formState: {orientation: 'horizontal'}};
    this.ts = ToolbarSrv;
    this.ts.modulo = 'bss.menu';
    this.sms = SideMenuSrv;
}

MenuManagerCtrl.prototype.inizializza = inizializza;
MenuManagerCtrl.prototype.crea = crea;
MenuManagerCtrl.prototype.vaiAModifica = vaiAModifica;
MenuManagerCtrl.prototype.parentSelected = parentSelected;
MenuManagerCtrl.prototype.salva = salva;
MenuManagerCtrl.prototype.elimina = elimina;
MenuManagerCtrl.prototype.modifica = modifica;
MenuManagerCtrl.prototype.ricaricaMenuArea = ricaricaMenuArea;

/**
 * reinizializza la lista delle aree
 * 
 * @memberOf MenuManagerCtrl
 * @param {string} [idarea=null] se non è null ricarica l'albero relativo all'area
 * @param {kendoTreeNode} [nodoPadre=null] se non è null è il nodo padre da espandere per rendere visibile l'elemento su cui ho lavorato
 * @returns {inizializza}
 */
function inizializza(idarea, nodoPadre, nodo){
    var self = this;   
    
    //permette di creare l'albero per area e il form per l'inserimento e la modifica    
    this.menu = new this.mi();
    
    // genera select aree
    this.menu.generaFormAree().then(function(campi){
        self.formAree = {
            modello: {area: idarea},
            campi: campi
        };
    });
    
    self.menu.rs.lastmenuarea = null;
    
}

/**
 * 
 * @deprecated Non utilizzato kendo tree non permette di interagire con i nodi nascosti (fallisce expand e select)
 * @param {type} idarea
 * @param {type} nodoPadre
 * @param {type} nodo
 * @returns {undefined}
 */
function ricaricaMenuArea(idarea, nodoPadre, nodo){
    var self = this;
    self.menu.rs.menuarea({idarea: idarea}).then(function(menuarea){
        
        
        var hds = new kendo.data.HierarchicalDataSource({data: menuarea});
        
        //ottengo il menu ad albero
        self.menu.rs.lastmenuarea = hds;
        
        //setto il model del form di modifica
        self.menu.ricaricaFormModifica({id: idarea});
        
        
        if(nodoPadre) {
            self.kendoTree.expand(nodoPadre);
        }

        if(nodo){
            self.kendoTree.select(nodo);
        }
        
        return hds;
    });
}

/**
 * @memberOf MenuManagerCtrl
 * @returns {string} idarea
 */
function parentSelected(){
    //se ho selezionato una voce del menu
    if(typeof this.menu.voceSel !== 'undefined'){
        //e la suddetta voce non è un'Area o un Menu ritorno la voce selezionata stessa altrimenti ritorno l'area o null
        return this.formAree.modello.area || null; // (["Area", "Menu"].indexOf(this.menu.voceSel.tipo) !== -1) ?  this.menu.voceSel.id : (this.formAree.modello.area || null);
    }
    //altrimenti ritorno l'area o null
    return (this.formAree.modello.area || null);
    
}

/**
 * @memberOf MenuManagerCtrl
 */
function vaiAModifica(dataItem){
    var self = this;
    self.menu.voceSel = dataItem; 
    self.menu.ricaricaFormModifica({id: dataItem.id});
}

/**
 * @memberOf MenuManagerCtrl
 */
function crea(){
    var parSel = this.parentSelected();
    //this.menu = new this.mi(null, null, null, null, parSel, null);
    this.menu.modello = {parentMenu: parSel};
    this.menu.generaFormInserimento();
}

/**
 * @memberOf MenuManagerCtrl
 */
function salva(){
    var self = this;
    this.menu.rs.crea(this.menu.modello, this.menu.modello.modulo).then(function(voce){
        self.sms.getMenu();
        self.em.showT("MENU.VOCECREATA", "success");
        self.inizializza();
    });
}

/**
 * Modi
 * @memberOf MenuManagerCtrl
 */
function modifica(){
    var self = this;
    this.menu.rs.modifica({entity: JSON.stringify(this.menu.modello)}, this.menu.modello.id, this.menu.modello.modulo).then(function(voce){
        self.sms.getMenu();
        self.em.showT("MENU.VOCEMODIFICATA", "success");
        self.inizializza();
        
    });
}

/**
 * @memberOf MenuManagerCtrl
 */
function elimina(){
    var self = this;
    
    this.$timeout(function(){
        var procedo = confirm("Procedere con la cancellazione di " + self.menu.modello.descrizione + "?");
                
        if(procedo){
            //var nodo = self.kendoTree.findByText(self.menu.modello.descrizione);
            //var padre = self.kendoTree.parent(nodo);
            
            self.menu.rs.elimina({id: self.menu.modello.id}).then(function(){
                self.sms.getMenu();
                self.em.showT("MENU.VOCEELIMINATA", "success");
                self.inizializza(); 
            });
        }
    });
    

}



module.exports = MenuManagerCtrl;