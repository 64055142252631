'use strict';

function MenuPermessi(MenuManagerRS, DF, $q, $timeout){
    function Costruttore(dataItem, tabLivello, grigliaUtenti, opzioniAgg){
        var self = this;
        self.rs = MenuManagerRS;
        self.df = DF;
        self.tipo = dataItem.loginName ? 'usermanager': 'groupmanager';
        self.dati = dataItem;
        self.albero = [];
        self.opzioniAgg = opzioniAgg;
        self.tabLivello = tabLivello;
        self.$q = $q;
        self.$timeout = $timeout;
//        console.log("inizializzo menu permessi griglia utenti: ", grigliaUtenti);
        self.grigliaUtenti = grigliaUtenti;
    }
    
    Costruttore.prototype.ottieniAree = ottieniAree;
    Costruttore.prototype.ottieniFigli = ottieniFigli;
    Costruttore.prototype.ottieniMenu = ottieniMenu;
    Costruttore.prototype.creaAlbero = creaAlbero;
    Costruttore.prototype.costruisciDS = costruisciDS;
    Costruttore.prototype.opzioniAlbero = opzioniAlbero;
    Costruttore.prototype.calcolaElementiDaAggiornare = calcolaElementiDaAggiornare;
    
    function ottieniAree(){
        return this.rs.tuttearee();
    }
    
    function ottieniFigli(idarea){
        return this.rs.menuarea({idarea: idarea});
    }
    
    function ottieniMenu(){
        return this.rs.menu();
    }
    
    function creaAlbero(){
        var self = this;
        return self.ottieniMenu().then(function(menuarea){
            self.albero = menuarea;
            return self.albero; 
        });
    }
    
    function costruisciDS(){
        var self = this;
        return self.creaAlbero().then(function(albero){
            console.log(self.albero);
            self.ds = new kendo.data.HierarchicalDataSource({ data: self.albero});
            return self.ds;
        });
    }
    
    function opzioniAlberoOld(){
        var self = this;
        return self.costruisciDS().then(function(ds){
            self.opzioni = angular.extend({}, {
                dataSource: self.ds,
                checkboxes: {
                    checkChildren: false
                }
            }, self.opzioniAgg);
            return self.opzioni;
        });
    }
    
    function opzioniAlbero(){
        var self = this;
        var tabLivello = self.tabLivello;
        var idcontesto = self.dati.id;
        var contesto = self.dati.loginName ? 'idutente' : 'idgruppo';
        var parametri = {};
        var rs = self.rs;
        //var testoTabAttiva = tabLivello.select().text().toLowerCase();
        //var mappaContesto = {gruppi: "idgruppo", utenti: "idutente"};
        //var contesto = mappaContesto[testoTabAttiva];
        var intq = self.$q;
        var intto = self.$timeout;
        //var grigliaUtenti = self.grigliaUtenti;
        parametri[contesto] = idcontesto;
        self.opzioni = angular.extend({}, {
            dataSource: new kendo.data.HierarchicalDataSource({
                transport: {
                    read: {
                        type: "POST",
                        url: self.rs.baseurl + "/menuarea",
                        dataType: 'json',
                        cache: true,
                        data: parametri
                    }                    
                },
                schema: {
                    model: {
                        children: "items"
                    }
                }
            }),
            checkboxes: {
                checkChildren: false
            },
            check: function(e){
                var testoTabAttiva = tabLivello.select().text().toLowerCase();
                var mappaContesto = {gruppi: "idgruppo", utenti: "idutente"};
                var contesto = mappaContesto[testoTabAttiva];
                
                
                var elementiDaAggiornare = [];
                elementiDaAggiornare.push(e.sender.dataItem(e.node));   
                if(e.sender.options.checkboxes.checkChildren){
                    elementiDaAggiornare.concat(self.calcolaElementiDaAggiornare(e.sender.dataItem(e.node), elementiDaAggiornare)); 
                }
                e.sender.inmodifica = true;
                var arrop = [];
                for(var x = 0, y = 0; x < elementiDaAggiornare.length; x++){
                    y++;
                    var voce = elementiDaAggiornare[y-1];
                    var id = voce.id;
                    var testo = voce.text;
                    var attivare = voce.checked;
//                    console.log("no$timeout aggiorno lo stato del menu " + voce.text + " a " + attivare + " " + contesto + " " + idcontesto);
//                    arrop.push(intto(function(){
//                        console.log("operazione ", y-1 , " di ", elementiDaAggiornare.length, moment().format('X'));
//                        return "aggiorno lo stato del menu " + voce.text + " a " + attivare + " " + contesto + " " + idcontesto;
//                    }, 2000));
                    var parametri = {idmenu: voce.id, visibile: attivare};
                    parametri[contesto] = idcontesto;
                    arrop.push(rs.accessoutentegruppo(parametri));
                }
                intq.all(arrop).then(function(risposte){
                    console.log("modifiche effettuate posso uscire dalla modifica ", risposte);
                    e.sender.inmodifica = false;
                    e.sender.dataSource.read();
                    if(contesto === 'idgruppo' && self.grigliaUtenti !== undefined){
                        console.log("ho aggiornato il permesso per una voce menu da un gruppo per cui aggiorno la tabella degli utenti (cercare grigliaUtenti): ", self);
                        self.grigliaUtenti.dataSource.read();
                    }
                    
                });
            }
        }, self.opzioniAgg);
        return self.opzioni;
    }
    
    function calcolaElementiDaAggiornare(nodo, elementi){
        var self = this;
        for(var x = 0; x < nodo.items.length; x++){
            elementi.push(nodo.items[x]);
            if(nodo.items[x].hasChildren){
                elementi.concat(self.calcolaElementiDaAggiornare(nodo.items[x], elementi));
            }
        }
        return elementi;
    }
    
    return Costruttore;
}

module.exports = MenuPermessi;