'use strict';

require("moduleFold/sidebarmenu/scripts/templates/AdminLTE2SideMenuTpl.html");

/**
 * FORSE INUTILIZZATO VEDERE AdminLTE2SideMenu
 * @param {*} $rootScope 
 * @param {*} $q 
 * @param {*} $timeout 
 * @param {*} $filter 
 * @param {*} $httpParamSerializerJQLike 
 * @param {*} $http 
 * @param {*} $state 
 * @param {*} MenuManagerRS 
 */
function SideMenuSrv($rootScope, $q, $timeout, $filter, $httpParamSerializerJQLike, $http, $state, MenuManagerRS){
    var self = this;
    this.rs = MenuManagerRS;
    this.$filter = $filter;
    this.$state = $state;
    self.$http = $http;
    self.prova = "questa è una prova in sidemenusrv"

}

SideMenuSrv.prototype.costruisciDatasource = costruisciDatasource;
SideMenuSrv.prototype.scorriRicorsivo = scorriRicorsivo;
SideMenuSrv.prototype.getIdappFromCodapp = getIdappFromCodapp;
SideMenuSrv.prototype.getAlbero = getAlbero;
SideMenuSrv.prototype.getMenu = getMenu;



/**
 * memberOf SideMenuSrv
 *
 * @param  {array} albero lista degli elementi del menu (non è un albero)
 * @return {type}        albero delle voci menu per kendo.panelBar
 */
function costruisciDatasource(albero){
    var self = this;
    /**
     * prende solo le voci che sono aree
     */
    self.aree = self.$filter("filter")(albero, {tipomenu: 'Area'});
    var dataSource = [];
    
    
    for(var x = 0; x < self.aree.length; x++){
        var nuovoel = {
            text: /* "<i class='fa fa-"+self.aree[x].icona+"'> </i> " + */ self.aree[x].descrizione,
            encoded: false,
            cssClass: self.aree[x].tipomenu
        };
        
        /**
         * prende ricorsivamente tutti gli elementi figli (figli dei figli...)
         */
        self.scorriRicorsivo(self.aree[x], nuovoel, albero);
        
        
        /**
         * aggiunge il datasource il sottoalbero con radice nell'area
         */
        dataSource.push(nuovoel);
    }
    return dataSource;
}


/**
 * @memberOf SideMenuSrv
 *
 * @param  {nodo} radice description
 * @param  {elemento} el     description
 * @param  {array} albero description
 * @return {albero}        albero sotto radice
 */
function scorriRicorsivo(radice, el, albero){
    var self = this;
    var elementiFigli = self.$filter("filter")(albero, {parentMenu: radice.id});
    elementiFigli = self.$filter("orderBy")(elementiFigli, "+ordine")
    if(elementiFigli.length){
        el.items = [];
        for(var x = 0; x < elementiFigli.length; x++){
            //console.log("elementi menu:");
            //console.log(elementiFigli[x]);
            var nuovoel = {
                text: "<i class='fa fa-"+elementiFigli[x].icona+"'> </i> " + elementiFigli[x].descrizione + " >> ",
                encoded: false,
                cssClass: elementiFigli[x].tipomenu
            };
            // if(elementiFigli[x].tipomenu === 'Menu'){
            //   nuovoel.url = "index.html?codapp="+elementiFigli[x].applicazione.codapp+"&codmodulo="+elementiFigli[x].codmodulo+"&strentity="+elementiFigli[x].modulo.strentity+"&nomeistanza="+elementiFigli[x].modulo.strentity;
            // }
            if(elementiFigli[x].tipomenu === "Link"){
                nuovoel.url = elementiFigli[x].url;
            }
            
            if(elementiFigli[x].tipomenu === 'LinkModulo' || elementiFigli[x].tipomenu === 'ModuloJar'){
                //nuovoel.url = /*elementiFigli[x].url+*/"index.html?codapp="+elementiFigli[x].applicazione.codapp+"&codmodulo="+elementiFigli[x].modulo.codmodulo+"&strentity="+elementiFigli[x].modulo.strentity+"&nomeistanza="+(elementiFigli[x].nomeistanza || elementiFigli[x].modulo.strentity);
                if(typeof elementiFigli[x].modulo !== "undefined" && elementiFigli[x].modulo !== null){
                    nuovoel.url = self.$state.href("moduli", {
                        codmodulo: elementiFigli[x].modulo.codmodulo,
                        strentity: elementiFigli[x].modulo.strentity,
                        nomeistanza:(elementiFigli[x].nomeistanza || elementiFigli[x].modulo.strentity)
                    }, {reload: true});
                }
                else {
                    nuovoel.url = "moduloObjNonDefinito";
                }
                
            }
            
            if(elementiFigli[x].tipomenu === "SPALink"){
                nuovoel.url = self.$state.href(elementiFigli[x].url, {}, {reload:true});
            }
            self.scorriRicorsivo(elementiFigli[x], nuovoel, albero);
            el.items.push(nuovoel);
        }
    }
}

/**
 * @deprecated non utilizzato
 * @memberOf SideMenuSrv
 */
function getIdappFromCodapp(){
    return $http({
        method: "POST",
        url: utils.urls.wsbase + "/applicazioni/findbyname",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: $httpParamSerializerJQLike({codapp: getParameterByName("codapp")})
    });
}

/**
 * @deprecated non utilizzato
 * @memberOf SideMenuSrv
 */
function getAlbero(){
    return $http({
        method: "POST",
        url: utils.urls.wsbase + "/menumanager/utente",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: $httpParamSerializerJQLike({idapp: self.idapp})
    });
}

/**
 * @memberOf SideMenuSrv
 */
function getMenu(){
    var self = this;
    return self.rs.utente().then(function(response){
        self.albero = response.data;
        var dataSource = self.costruisciDatasource(self.albero);
        self.panelbarOpts = angular.extend({}, {dataSource: null, expandMode: 'single'}, {dataSource: dataSource});
        console.log(self.panelbarOpts);
        return self.albero;
    }, function(err){
        console.error("impossibile ottenere albero");
        console.error(err);
        throw err;
    });
}



module.exports = SideMenuSrv;