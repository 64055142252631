'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bssricambiwebFold/listacodici/styles/listacodici.scss");

var bsslistacodici = angular.module("bss.bssricambiweb.listacodici", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])
    .service("ListacodiciFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./ListacodiciFunzioniAggiuntive")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/ListacodiciRoutes")])
    .directive("listacodicidir", [require("./Listacodici")])
;

module.exports = bsslistacodici;
