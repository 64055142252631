"use strict";

/**
 * Confronto con altri campi
 * 
 * @class
 * @param {$translate} $translate
 */
function IntervalloScaglionabileFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf RequiredFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */     
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.INTSCA", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf RequiredFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */     
    function expression(viewValue, modelValue, scope){//applicare al campo 'ogni'
        var valore = modelValue || viewValue || null;
        var esitoValore = valore !== null && typeof valore !== 'undefined';

        var parti = scope.options.data.modello.validations.intervalloscaglionabile.split(":");

        var keyda = parti[0];
        var keya = parti[1];

        var valoreda = scope.model[keyda];
        var valorea = scope.model[keya];

        var campiForm = scope.$parent.$parent.$parent.fields;
        var campoda = _.findWhere(campiForm, {key: keyda});
        var campoa = _.findWhere(campiForm, {key: keya});
        
        var esitoValoreDa = valoreda !== null && typeof valoreda !== 'undefined';
        var esitoValoreA = valorea !== null && typeof valorea !== 'undefined';

        if(esitoValore && esitoValoreDa && esitoValoreA){
            if(valore === 0) return false;

            var resto = (valorea - valoreda) % valore;
            console.log( "intervallo scaglionabile? (" + valoreda + " - " + valorea + ") % " + valore + " = " + resto);

            return (resto === 0);
        }

        return true;
    }
        
    return Costruttore;
}

module.exports = IntervalloScaglionabileFct;