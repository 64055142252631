'use strict';

require("moduleFold/componenti/bssledio/styles/bssledio.scss");

/**
 * @example 
 * <div class="col-lg-4">
 *   <div class="col-lg-12">
 *       <bssledio prefisso="'I'" titolo="'ingressi'" color-off="'black'" n-led="8" stati="[false, false, true, true, false, true, true, false]"></bssledio>
 *   </div>   
 *   <div class="col-lg-12" style="margin-top: 1px;">
 *       <bssledio prefisso="'O'" titolo="'uscite'" color-off="'black'" n-led="6" stati="[false, false, true, false, true, false]"></bssledio>
 *   </div>
 * </div>
 * @type type
 */
var BSSLedIO = {
    bindings: {
        nLed: "=?",
        classOn: "=?",
        colorOn: "=?",
        classOff: "=?",
        colorOff: "=?",
        classWrap: "=?",
        stati: "=?",
        titolo: "=?",
        prefisso: "=?",
        classTestoLed: "=?",
        classWrapTestoLed: "=?",
    },
    controller: function(){

        var defaults = {
            nLed: 6,
            classOn: "bssledio bssledio-on",
            colorOn: "yellowgreen",
            classOff: "bssledio bssledio-off",
            colorOff: "#63687E",
            classWrap: "bssledio-wrapper",
            titolo: "",
            classTitleWrap: "bssledio-title-wrapper",
            stati: [],
            prefisso: "L",
            classTestoLed: "bssledio-caption",
            classWrapTestoLed: "bssledio-testo-wrapper",
        };
        
        function init(){
            //estendo i default
            for(var x in defaults){
                if(typeof this[x] === 'undefined'){
                    this[x] = defaults[x];
                }
            }
            for(x = 0; x < this.nLed; x++){
                
                if(typeof this.prefisso === 'undefined'){
                    this.testoLed[x] = defaults.prefisso + (x+1);
                }
                else {
                    this.testoLed[x] = this.prefisso + (x+1);  
                }
                
                if(typeof this.stati[x] === 'undefined'){
                    this.stati[x] = {
                        abilitato: false,
                        additionalInfo: {}
                    };
                }
            }
        }
        
        function classeCss(stato){
            if(stato) return this.classOn;
            return this.classOff;
        }
        
        function clickLed(stato, indice){
            var self = this;
            console.log("cliccato sul led " + (indice+1) + "° - stato ", stato.additionalInfo);
            if(stato.cliccabile){
                console.log("lancio evento click");
                self.stati[indice].abilitato = !self.stati[indice].abilitato;
            }            
            else {
                console.log("non cliccabile");
            }
        }
        
        
        this.testoLed = [];
        this.init = init;
        this.classeCss = classeCss;
        this.clickLed = clickLed;
        
        this.$onInit = function(){
            this.init();
        };
        
        
    },
    template: require("./templates/bssledio.html")
};

module.exports = BSSLedIO;