'use strict';

/**
 * Fornisce la configurazione di base dei middleware
 * 
 * @class 
 * @param {$middlewareProvider} $middlewareProvider
 */
function MiddlewareCfg($middlewareProvider){
    
    /** Definizione di tutti i middleware di base dal modulo bss/router */
    $middlewareProvider.map({
        loggato: ["LoginService", "$state", function(LoginService, $state){
            if(LoginService.isLoggedIn()){
                this.next();
            } else {
                //richiede il modulo bss/autentica sicuramente presente (LoginService)                
                var thenRedirect = JSON.stringify({name: this.route, params: this.params})  //$state.href(this.route, this.params, {absolute: true})
                ignorebeforeunload = true;
                this.redirectTo("login", {
                    thenRedirect: encodeURIComponent(thenRedirect)
                });
            
            }
        }],
        ospite: ["LoginService", function(LoginService){
            if(!LoginService.isLoggedIn()){                
                this.next();
            }  else {      
                ignorebeforeunload = true;          
                this.redirectTo("home");
            }                          
        }],
        tutti: [function(){
            this.next();    
        }],
        nessuno: [function(){}],
        noninmodifica: ["StatoApp", function(StatoApp){
            var self = this;
//            console.log("middleware non in modifica: ", StatoApp);
//            if(StatoApp.stato === 'inmodifica'){
//                if(confirm("Alcuni form sono in attesa di essere salvati. Procedere senza salvare?")){
//                    StatoApp.stato = null;
//                    this.next();
//                }
//            }    
//            else {
//                this.next();
//            }
//            
//            
            StatoApp.confermaProcedi().then(function(conferma){
                if(!conferma) return;
                self.next();
            });
                            
        }]
    });
    
    $middlewareProvider.global("noninmodifica");
}

module.exports = MiddlewareCfg;