"use strict";

var defaultRead = require("./utils").defaultRead;

/**
 * Factory delle opzioni di un DataSource Kendo estende CfgFactory
 * 
 * @class
 * @param {CfgFactory} CfgF
 * @return {Object} opzioni di un DataSource Kendo
 */
function DSCfgFactory(CfgF, ToolbarS, $http, ErrorManager){
    
    /**
     * 
     * @memberOf DSCfgFactory
     * @constructor
     * @param {type} strentity
     * @param {type} codapp
     * @param {type} codmodulo
     * @param {type} opzioni
     * @param {boolean} avoidInit se true non inizializza le opzioni
     */
    function Costruttore(strentity, codapp, codmodulo, opzioni, avoidInit){
        var self = this;
        self.ts = ToolbarS;
        self.$http = $http;
        self.em = ErrorManager;
        
        CfgF.apply(this, [strentity, codapp, codmodulo, opzioni]);
        
        this.opzioniOverride = opzioni;
        
        this.opzioniBase = {
                      
            baseUrl: this.baseUrl,
            serverSorting: true,
            serverPaging: true,
            serverGrouping: false,
            serverFiltering: true,
            serverAggregates: false,
            pageSize: 50,
            sort: [
                /*es.
		{field: 'campo1', dir: 'asc'},
		{field: 'campo2', dir: 'desc'},
                 */
            ],
            error: function(e){
                console.log("errore datasource: ", e);
                if(e.sender._destroyed.length !== 0) {
                    console.log("_destroyed non vuoto ricarico il datasource!");
                    this.read();
                }
                var errore = bssRecursiveGetter(e, "xhr.responseJSON", null);
                if(errore !== null){
                    self.em.errorCB({data: errore, status: e.xhr.status});
                }
                

            },
            change: function (e) {
                // if (e.action == "itemchange") {//save on blur
                //     this.sync();
                //     var scope = angular.element($("section.content")).scope();
                //     scope.$apply(function(){
                //         scope.notifiche.show("Operazione eseguita con successo", "success");
                //     });
                // }
            },
            transport: {
                /*OBBLIGATORIO se i dati sono remoti
                 */
                create: {
                    type: "POST",
                    url: this.baseUrl + "/crea",
                    dataType: 'json',
                    cache: true,
                    /*
			data: function(){
				//return dei dati da passare sempre
			}
                     */
                },
                read: {
                    type: "POST",
                    url: this.baseUrl + "/grid",
                    dataType: 'json',
                    cache: true,
                    /*
			data: function(){
				//return dei dati da passare sempre
			}
                     */
                },
                update: {
                    type: "POST",
                    url: this.baseUrl + "/modifica",
                    dataType: 'json',
                    cache: true,
                    /*
			data: function(){
				//return dei dati da passare sempre
			}
                     */
                },
                destroy: {
                    type: "POST",
                    url: this.baseUrl + "/cancella",
                    dataType: 'json',
                    cache: true,                    
                },

            },
            requestStart: function(e){
                console.log("incremento disabilitatutto", e)
                self.ts.disabilitatutto++;
                //non viene attesa la fine di questa richiesta1
                // if(keycloak !== null && keycloak !== undefined){
                //     if(keycloak.token !== null && keycloak.token !== undefined){
                //         return keycloak.updateToken(30).then(function(resp){
                //             console.log("update token da kendo", resp);
                //         })
                //     }
                // }
            },
            requestEnd: function(e){
                console.log("decremento disabilitatutto", e)
                self.ts.disabilitatutto --;
            },
//            requestEnd: function(e){
//                if(e.type === 'read' && e.response){
//                    var resp = e.response;
//                    if(resp.groups === null) delete resp.groups;
//                    if(resp.numeroRiga === null) delete resp.numeroRiga;
//                    //non serve non aggiorna la response
//                    return resp
//                }
//                
//            }
            /*
	data: function(){
		//OBBLIGATORIO SE I DATI SONO LOCALI
		//es.
		//return [{ name: "Jane Doe", age: 30 },{ name: "John Doe", age: 33 }]
	},
	aggregate: function(){
		//es.
		//return [{ field: "age", aggregate: "sum" }, { field: "age", aggregate: "min" }, { field: "age", aggregate: "max" }]
	},
             */
            /*
	esegue tutte le modifiche dello stesso tipo (inserimenti, modifiche, cancellazioni) in una sola richiesta
             */
            batch: true,
            /*
	richiama automaticamente sync ad ogni modifica
             */
            autoSync: false,
            schema: {
                /*
		permette di interpretare i dati
                 */
                type: "json",
                data: "results",    //queste 3 property indicano da dove prendere i rispettivi valori
                total: "total",     //this.data in questo caso si presenterà nella forma
                groups: "groups",  //{results: [{...},{...}], total: 200}
                aggregates: "aggregati",
                model: {
                    /*OBBLIGATORIO in ogni caso per evitare l'errore e.splice
			es
			id: 'id',
			fields: {
				campo1: {
					editable: true, nullable: false,
					defaultValue: 5, type: 'number',
					validation: { required: true, min: 1 }
				}
			}
                     */
                },        
                //        parse: function(response){         
                //            //qualunque cosa arrivi nelle response può essere modificata per essere conforme al model definito
                //        }
                
            },
        };
        
        if(avoidInit){}
        else {
            return this.inizializza(opzioni);
        }
    }    
    
    Costruttore.prototype = new CfgF; //controllare se funziona
    Costruttore.prototype.inizializza = inizializza;
    
    
    /**
     * Alle opzioni passate a runtime aggiunge quelle previste per il datasource kendo
     * 
     * @memberOf DSCfgFactory.prototype
     * @param {type} opzioni
     * @returns {promise} 
     */
    function inizializza(opzioni){
        var self = this;
        self.setOpzioni(opzioni);
        return self.asyncSetCfg().then(function(resp){
            var modellods = self.headers.model;
            
            //console.log("da ws", modellods, " overrides: ", self.opzioniOverride);
            
            if(self.opzioniOverride && self.opzioniOverride.options && self.opzioniOverride.options.schema && self.opzioniOverride.options.schema.model){
                angular.extend(modellods, self.opzioniOverride.options.schema.model);
            }
            self.opzioni.schema.model = modellods;             
            
            self.opzioni.filterMapper = function(filtri, campi){
                //se un campo ha datatextfield nel filtro va inviato come
                //filter.field = campo + '.' + datatextfield
                return _.map(filtri, function(filtro){
                    var campo = filtro.field;
                    var modello = campi[campo];
                    
                    //modello contiene la configurazione del campo
                    // campi[campo + '.' + datatextfield] è undefined
                    // quindi se modello è undefined sto applicando 2 volte la concatenazione
                    // evito dunque campo + '.' + datatextfield + '.' + datatextfield
                    //console.log("per il campo ", campo, " imposto il field per il filtro a ====");
                    if(typeof modello !== 'undefined' && modello.datatextfield){
                        campo = campo+"."+modello.datatextfield;
                    }
                    //console.log("===>", campo)
                    
                    filtro.field = campo;

                    //il filtro passato è di tipo data quindi lo passo nel formato 2019-02-27T09:09:43.188Z
                    if(typeof filtro['value'] !== 'undefined' && typeof filtro['value'].getMonth === 'function'){
                        var data = moment.utc(filtro['value'].toString()).toISOString(); //quando useremo il timestamp moment.utc(filtro['value'].toString()).format("x")
                        filtro['value'] = data;
                    }

                    return filtro;
                })

            }

            self.opzioni.sortMapper = function(ordini, campi){
                //se un campo ha datatextfield nel filtro va inviato come
                //filter.field = campo + '.' + datatextfield
                return _.map(ordini, function(ordine){
                    var campo = ordine.field;
                    var modello = campi[campo];
                    
                    //modello contiene la configurazione del campo
                    // campi[campo + '.' + datatextfield] è undefined
                    // quindi se modello è undefined sto applicando 2 volte la concatenazione
                    // evito dunque campo + '.' + datatextfield + '.' + datatextfield
                    if(typeof modello !== 'undefined' && modello.datatextfield){
                        campo = campo+"."+modello.datatextfield;
                    }
                    
                    ordine.field = campo;
                    return ordine;
                })

            }

            self.opzioni.transport.parameterMap =  function(data, type){
                //console.log("parameter map", data, type);
                //permette di modificare i dati prima di inviarli
                //es. utile se i filter, per come sono strutturati di default dalla grid
                //non sono compatibili con il codice lato server che li riceverà
                if( ["create", "update"].indexOf(type) > -1 ){
                    var daserializzare = null;
                    var dasalvare = null;
                    if(Array.isArray(data.models)){
                        console.log("batch mode on");     
                        daserializzare = data.models[0];
                    }
                    else {
                        console.log("batch mode off");
                        daserializzare = data;
                    }
                    dasalvare = $.param({elemento: JSON.stringify(daserializzare)});
                    return dasalvare;
                }
                
                if( ["destroy"].indexOf(type) > -1 ){
                    var todel = [];
                    if(Array.isArray(data.models)){
                        for(var x = 0; x < data.models.length; x++){
                            todel.push(data.models[x].id);
                        }
                    }
                    else {
                        todel.push(data.id);
                    }
                    return  $.param({id: JSON.stringify(todel)})
                }

                if( ["read"].indexOf(type) > -1 ){

                    if(self.opzioni.datiCfg === null || self.opzioni.datiCfg === undefined){
                        console.error("!!!ATTENZIONE!!! - daticfg non definito". self.opzioni)
                    }
                    data = defaultRead(data, self.opzioni, self.opzioniOverride);
                    
                }
                
                return data;
                
            };
                
            
            
            return self.opzioni;//new kendo.data.DataSource(self.opzioni);
        });
    }
    
    return Costruttore;
}

module.exports = DSCfgFactory;