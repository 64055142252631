'use strict';

/**
 * Component relativo all'azione di tipo toggle
 * 
 * @class
 * @example 
 * <toolbar-toggle-action disabilita="ac.disabilita" act1="ac.act1" act2="ac.act2"></toolbar-action>
 */
function ToggleActionCmp(){
    this.bindings = {
        disabilita: "=",
        ordine: "=",
        act1: "=",
        act2: "=",
    };
    this.template = require("../templates/toggleaction.html");
    this.controller = "ToggleActionCtrl as tac";
}

module.exports = ToggleActionCmp;