"use strict";

/**
 * @module bss/traduzioni
 * @description Modulo che configura il traduttore
 */
var bsstraduzioni = angular.module("bss.traduzioni", ["pascalprecht.translate"])
    .config(["$translateProvider", require("../cfgs/GlobalTranslateCfg")])
;

module.exports = bsstraduzioni;
