'use strict';

/**
 * Controller legato alla maschera di login
 * 
 * @class
 * @param {type} LoginService
 * @param {type} ErrorManager
 * @returns {LoginCtrl}
 */
function LoginCtrl(LoginService, ErrorManager, $state, $stateParams){
    var self = this;
    self.ls = LoginService;
    self.$state = $state;
    self.sp = $stateParams;
    self.em = ErrorManager;
    self.statoAccesso = {
        alertClass: 'info', //info | warning | danger | success
        testo: 'Ti stiamo mandando alla pagina di login',
        waiting: true,
    }

    keycloak.init({
        onLoad: 'login-required',
        enableLogging: true
    }).then(function(authenticated) {

        self.statoAccesso = {
            alertClass: 'info', //info | warning | danger | success
            testo: 'Stiamo recuperando le tue informazioni',
            waiting: true
        }

        if(!kcajaxedited){
            var kcproxied$ajax = kendo.jQuery.ajax;
            kendo.jQuery.ajax = function() {
                var ajaxori = this;
                var ajaxparams = arguments;

                if(keycloak !== null && keycloak !== undefined){
                    if(keycloak.token !== null && keycloak.token !== undefined){
                        kcajaxedited = true;
                        //console.log("=================>richiesta da jquery provo ad aggiornare il token")        
                        return keycloak.updateToken(30).then(function(refreshed){
                            //console.log("=================>richiesta da jquery token aggiornato?", refreshed)
                            return kcproxied$ajax.apply( ajaxori, ajaxparams )
                        }, function(err){
                            //console.log("=================>richiesta da jquery aggiorna token in errore", err)
                            return kcproxied$ajax.apply( ajaxori, ajaxparams )
                        })
                    }
                }
                //console.log("=================>richiesta da jquery non ho un token per keycloak")            
                return kcproxied$ajax.apply( ajaxori, ajaxparams )
            }
        }

        console.log("da keycloak init", authenticated)
        self.ls.logga().then(function(resp){
            //alert(JSON.stringify(resp.data, null, 2))
            if(self.sp.thenRedirect){
                var ignorebeforeunload = true;
                var destination = JSON.parse(decodeURIComponent(self.sp.thenRedirect))
                if(typeof destination !== 'undefined' && destination !== null){
                    self.$state.go(destination.name, destination.params)
                } else {
                    self.$state.go("home")     
                }
            } else {
                self.$state.go("home")                
            }
            
       
        }, function(error){
            //alert("Errore creazione sessione utente")
            self.statoAccesso = {
                alertClass: 'danger', //info | warning | danger | success
                testo: 'Impossibile recuperare le tue informazioni',
                retry: true,
            }
        })
        //dovrei fare il redirect alla home, ma dopo aver messo in sessione username, azienda ed esercizio
    }, function() {
        self.statoAccesso = {
            alertClass: 'danger', //info | warning | danger | success
            testo: 'Impossibile effettuare l\'accesso',
            retry: true
        }
    });

}

LoginCtrl.prototype.loginOnEnter = loginOnEnter;
LoginCtrl.prototype.classicLogin = classicLogin;
LoginCtrl.prototype.postLogin = postLogin;
LoginCtrl.prototype.defaultPostLogin = defaultPostLogin;

/**
 * Fa il submit quando premo invio
 * 
 * @memberOf LoginCtrl
 * @param {$event} event
 */
function loginOnEnter(event){
    var self = this;
    event = event || window.event;
    var code = event.keyCode || event.which;
    if(self.fc.signin.$valid && code===13){         
        self.classicLogin();
    }
}    


/**
 * Fa il login con username e password
 * 
 * @memberOf LoginCtrl
 * 
 */
function classicLogin(){
    var self = this;
    self.ls.logga(self.signin.username, self.signin.password).then(
        function(response){
            if(typeof response.data.postlogin !== 'undefined'){
                self.postLogin(response.data.postlogin);
            }
            else {
                self.defaultPostLogin();
            }
        },
        function(response){
            self.em.errorCB(response);
        }
    );
}

function defaultPostLogin(){
    var self = this;
    self.$state.go("home", null, {reload: true});
}

/**
 * 
 * @param {type} voce
 * @returns {undefined}Esegue il modulo di postlogin
 * 
 * @memberOf LoginCtrl
 */
function postLogin(voce){
    var self = this;
    self.$state.go(voce, null, {reload: true});
//    switch(voce){
//        case 'SPALink':
//            self.$state.go(voce.url, null, {reload: true});
//            break;
//        case 'LinkModulo':
//            self.$state.go("moduli", {strentity: voce.modulo.strentity, codapp: voce.modulo.codapp, codmodulo: voce.modulo.codmodulo}, {reload: true})
//            break;
//        case 'Link':
//            location.href = voce.url;
//            break;
//        default: 
//            self.defaultPostLogin();
//            break;
//    }
}

module.exports = LoginCtrl;