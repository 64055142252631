'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bsslibFold/disegnamodelli/styles/disegnamodelli.scss");

var bssdisegnamodelli = angular.module("bss.bsslib.disegnamodelli", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])
    .service("DisegnamodelliFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./DisegnamodelliFunzioniAggiuntive")])
    .filter("bssn2w", ['$http', '$q', '$rootScope', require("./N2WFilter")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/DisegnamodelliRoutes")])

;

module.exports = bssdisegnamodelli;
