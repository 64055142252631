'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/usermanager
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function UsermanagerRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "usermanager",
        url: "/usermanager",
        template: require("../templates/maintpl.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", 
                        "UsermanagerSrv", require("../UsermanagerCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}



module.exports = UsermanagerRoutes;
