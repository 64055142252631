'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/teststoragecommunications
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function TeststoragecommunicationsRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "teststoragecommunications",
        url: "/teststoragecommunications",
        //templateUrl: "lib/teststoragecommunications/scripts/templates/teststoragecommunications.html",
        template: require("../templates/teststoragecommunications.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "ErrorManager", "TeststoragecommunicationsFunzioniAggiuntive", require("../TeststoragecommunicationsCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = TeststoragecommunicationsRoutes;
