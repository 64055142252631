'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/listacodici
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function ListacodiciRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "listacodici",
        url: "/listacodici",
        //templateUrl: "ricambiweb/listacodici/scripts/templates/listacodici.html",
        template: require("../templates/listacodici.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "ErrorManager", "ListacodiciFunzioniAggiuntive", require("../ListacodiciCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = ListacodiciRoutes;
