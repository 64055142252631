'use strict';


/**
 * Service dei ws di modulemanager
 *
 * @class
 * @param {type} $http
 * @param {type} ErrorManager
 */
function ModulemanagerRS($http, ErrorManager, IdentityRS){
    this.baseUrl = utils.urls.wsbase + "/modulemanager";
    this.nsTraduzione = "MODULEMANAGERMANAGER";
    this.$http = $http;
    this.form = {menu: null};
    this.nomeEntity = 'modulemanager';
    this.em = ErrorManager;
}

var IdentityRS = require("moduleFold/genericidentity/scripts/IdentityRS");
angular.extend(ModulemanagerRS.prototype, IdentityRS.prototype);

ModulemanagerRS.prototype.cercadamenu = cercadamenu;
ModulemanagerRS.prototype.aggiornaRelazioneMenu = aggiornaRelazioneMenu;

/**
 * 
 * @memberOf ModulemanagerRS
 * @param {type} parametri
 * @returns {unresolved}
 */
function cercadamenu(parametri){
    var self = this;
    return self.$http({
        url: utils.urls.wsbase + "/menumodulimanager" + "/cercadamenu",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * 
 * @memberOf ModulemanagerRS
 * @param {type} parametri
 * @returns {unresolved}
 */
function aggiornaRelazioneMenu(parametri){
    var self = this;
    return self.$http({
        url: utils.urls.wsbase + "/menumodulimanager" + "/crea",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

module.exports = ModulemanagerRS;
