'use strict';

/**
 * Registrazione delle route per il modulo bss/autentica
 * 
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 * 
 */
function Routes($stateProvider, $urlRouterProvider, $locationProvider){
    $stateProvider
        .state('login', {
            url: "/login?thenRedirect",
            views: {
                "maincontent.custommodule" : {
                    template: require("../templates/login.html"),
                    controller: ["LoginService", "ErrorManager", "$state", "$stateParams", require("../LoginCtrl")],
                    controllerAs: 'login'
                },
            },
            middleware: ['ospite'],
            data: {
                modulo: 'bss.autentica'
            }
        })
    ;
}


module.exports = Routes;