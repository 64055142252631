'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bssricambiwebFold/listinistd/styles/listinistd.scss");

var bsslistinistd = angular.module("bss.bssricambiweb.listinistd", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])                                                           
    .service("ListinistdFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./ListinistdFunzioniAggiuntive")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/ListinistdRoutes")])
;

module.exports = bsslistinistd;
