"use strict";

/**
 * Si occupa di gestire gli eventi relativi al token.
 *
 * @class
 */
function TokenRTService(SessioneService, LoginService, $rootScope, $websocket){
    var self = this;
    
    self.$rs = $rootScope;
    self.ss = SessioneService;
    self.conn = null;
    self.$wss = $websocket;
    
//    self.$rs.$on("wss.login.utente", function(){
//        self.inizializza();
//    });
//    self.$rs.$on("wss.token.rinnova", function(){
//        self.conn.send(JSON.stringify({evento: "wss.token.rinnova", sessione: self.ss.sessione}));
//    });
//    self.$rs.$on("wss.logout.utente", function(){
//        self.conn.send(JSON.stringify({evento: "wss.logout.utente", sessione: self.ss.sessione}));
//        self.conn.close();
//    });
//    self.$rs.$on("wss.logout.scadenza", function(){
//        self.conn.send(JSON.stringify({evento: "wss.logout.scadenza", sessione: self.ss.sessione}));
//        self.conn.close();
//    });
//    self.$rs.$on("wss.logout.inattivita", function(){
//        self.conn.send(JSON.stringify({evento: "wss.logout.inattivita", sessione: self.ss.sessione}));
//        self.conn.close();
//    });
    
}

TokenRTService.prototype.inizializza = inizializza;

function inizializza(){
    var self = this;
    var wssurl = utils.urls.websocket;
//    if(self.conn === null || typeof self.conn === 'undefined'){
//        self.conn = self.$wss(wssurl);
//        self.conn.onOpen = function(e) {
//            console.log("Connection established!", e);
//        };
//        self.conn.onMessage = function(e) {
//            console.log("ricevuto messaggio: ", e, e.data);
//        };
//        self.conn.send(JSON.stringify({evento: "wss.login.utente", sessione: self.ss.sessione}));
//    }
    
    
}

module.exports = TokenRTService;