"use strict";

/**
 * Controlla che il valore sia maggiore di scope.options.data.modello.validations.min
 * 
 * @class
 * @param {$translate} $translate
 */
function MinFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf MinFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */     
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.MIN", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf MinFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */  
    function expression(viewValue, modelValue, scope){
        var valore =modelValue || viewValue || null;

        if(valore == null) return true;

        var valore = Number(modelValue || viewValue || null);
        
        if(typeof scope.options.data.modello.validations.min === 'undefined') return true;
        //console.log("controllo che il valore sia maggiore o uguale di " + scope.options.data.modello.validations.min)
        return (typeof valore === 'number' && ! isNaN(valore)) ? (valore >= Number(scope.options.data.modello.validations.min)) : true;
    }
        
    return Costruttore;
}

module.exports = MinFct;