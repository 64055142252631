"use strict";

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/sidebarmenu/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");


/**
 * 
 * @module bss/autentica
 * @description Si occupa di fornire autenticazione a tutto il progetto
 * 
 */
var bssautentica = angular.module("bss.autentica", ["bss.notifiche","bss.sidebarmenu", "bss.traduzioni"])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/Routes")])
    .service("SessioneService", [require("./SessioneService")])    
    .service("LoginService", ["$http", "$interval", "SessioneService", "ErrorManager", "$state", "SideMenuSrv", "FieldFactory", "$rootScope", "Valida", require("./LoginService")])
    .service("TokenRTService", ["SessioneService", "LoginService", "$rootScope", "$websocket", require("./TokenRTService")])
    .directive("afkdir", ["$interval", "LoginService", "ErrorManager", "$rootScope", "TokenRTService", require("./AFKDir")])
    .factory("TokenInterceptor", ["SessioneService", require("./TokenInterceptor")])
    .config(["$httpProvider", function($httpProvider){
        $httpProvider.interceptors.push('TokenInterceptor');
    }])
;

module.exports = bssautentica;