'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione del campo wysiwyg
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessage
 * @param {Valida} Valida
 */
function BSSWysiwyg(formlyConfig, formlyValidationMessage, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {        
        name: 'bsswysiwyg',
        template: require("./bsswysiwyg.html"),
        defaultOptions: {
            className: 'col-xs-12',
            ngModelElAttrs: {
                'cssCategory': 'bsswysiwyg'
            },    
            expressionProperties: {
                "templateOptions.enterAsTab": "false"
            }    
        }
    }));
}

module.exports = BSSWysiwyg;