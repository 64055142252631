'use strict';

function DisplayFileDir(){
    var updateElem = function (element) {
        return function (displayFile) {
            element.empty();

            var objectElem = {}
            if (displayFile && displayFile.type !== "") {
                if (displayFile.type === "pdf") {
                    objectElem = angular.element(document.createElement("object"));
                    objectElem.attr("data", displayFile.fileUrl);
                    objectElem.attr("type", "application/pdf");
                    objectElem.attr("height", "500px");
                    objectElem.attr("width", "100%");
                    
                }
                else {
                    objectElem = angular.element(document.createElement("img"));
                    objectElem.attr("width", "100%");
                    objectElem.attr("src", displayFile.fileUrl);
                }
            }
            element.append(objectElem);
        };
    };

    return {
        restrict: "EA",
        scope: {
            displayFile: "="
        },
        link: function (scope, element) {
            scope.$watch("displayFile", updateElem (element));
        }
    };
}

module.exports = DisplayFileDir;