'use strict';


/**
 * @module bss/validatori
 * @description 
 * Espone tramite il servizio 'Valida' tutte le regole di validazione come factory. 
 * Ogni regola è data da una callback di controllo e da un messaggio di errore tradotto.
 * Al momento per creare una nuova regola occorre aggiornare questo modulo.
 * Creare un nuovo factory per la regola. Fare l'inject del factory nel servizio "Valida".
 * Nel servizio valida aggiungere un istanza del factory come property distinguendo tra regole sincrone e asincrone
 * Todo:
 * - semplificare questo processo
 * - permettere l'aggiunta di regole da qualunque modulo (simile a come è possibile aggiungere action alla toolbar)
 * - tutte le factory fanno la stessa cosa, trovare il modo di ereditare il comportamento di default
 * - tutte le regole di validazione async sono unificabili eccetto per il messaggio
 * 
 */
var bssvalidatori = angular.module("bss.validatori", ["pascalprecht.translate", 'ngCookies'])
    .factory("ConfirmFct", ["$translate", require("./ConfirmFct")])
    .factory("IsDateTimeFct", ["$translate", require("./IsDateTimeFct")])
    .factory("MaxFct", ["$translate", require("./MaxFct")])
    .factory("MaxLengthFct", ["$translate", require("./MaxLengthFct")])
    .factory("MinFct", ["$translate", require("./MinFct")])
    .factory("NullableFct", ["$translate", require("./NullableFct")])
    .factory("RequiredFct", ["$translate", require("./RequiredFct")])
    .factory("LocalitarequiredFct", ["$translate", require("./LocalitarequiredFct")])
    .factory("SizeFct", ["$translate", require("./SizeFct")])
    .factory("UniqueFct", ["$translate", require("./UniqueFct")])
    .factory("RequiredIfFct", ["$translate", require("./RequiredIfFct")])
    .factory("RequiredWithFct", ["$translate", require("./RequiredWithFct")])
    .factory("RequiredWithoutFct", ["$translate", require("./RequiredWithoutFct")])
    .factory("RequiredWithoutAllFct", ["$translate", require("./RequiredWithoutAllFct")])
    .factory("ComparaFct", ["$translate", require("./ComparaFct")])
    .factory("IntervalloScaglionabileFct", ["$translate", require("./IntervalloScaglionabileFct")])
    .directive("nfv", ["$http", "Valida", "$interval", "$timeout", "$q", require("./NotFormlyValidator")])
    
    .service("Valida", [
        "ConfirmFct", 
        "IsDateTimeFct", 
        "MaxFct", 
        "MaxLengthFct", 
        "MinFct", 
        "NullableFct", 
        "RequiredFct", 
        "LocalitarequiredFct",
        "SizeFct", 
        "UniqueFct",
        "RequiredIfFct",
        "RequiredWithFct",
        "RequiredWithoutFct",
        "RequiredWithoutAllFct",
        "ComparaFct",
        "IntervalloScaglionabileFct",
        require("./ValidaSrv")
    ])
;


module.exports = bssvalidatori;