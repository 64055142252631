'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Fornisce il campo per l'inserimento di un ora
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSTime(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bsstime',
        extends: 'bssdatetime',
        defaultOptions:{          
            ngModelElAttrs: {
                'datetime': 'HH:mm:ss',
                //'datetime-model': "HH:mm:ss.sss",
                'cssCategory': 'bsstime',               
            },
            expressionProperties: {
                "templateOptions.placeholder": "' hh:mm:ss'"
            }
        },
    }));
}

module.exports = BSSTime;