'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/maxphoimport
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function MaxphoimportRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "maxphoimport",
        url: "/maxphoimport",
        //templateUrl: "ricambiweb/maxphoimport/scripts/templates/maxphoimport.html",
        template: require("../templates/maxphoimport.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "FieldFactory", "Valida", "ErrorManager", "MaxphoimportFunzioniAggiuntive", require("../MaxphoimportCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = MaxphoimportRoutes;
