'use strict';

/**
 *  Service relativo alla toolbar, chiunque ne faccia l'inject può fornire nuove azioni.
 *  Ha come proprietà gli actionProvider, e i dati selezionati su cui la maggiorparte delle action lavorano.
 *  
 * @class
 */
function ToolbarSrv($rootScope, $timeout, $http, $interval, $state, Notification, Bloccaschermo, LoginSrv, SideMenuSrv){
    var self = this;
    self.disabilitatutto = 0;
    self.notification = Notification;
    self.bloccaschermo = Bloccaschermo;
    self.ls = LoginSrv;
    self.sm = SideMenuSrv;
    this.svuotaProviders();
    
    
    //al cambio di stato chiudo tutte le notifiche
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){ 
        
        //avvio il bloccaschermo
        if (toState.resolve) {
            //console.log("mostro screensaver per cambio di stato");
            self.bloccaschermo.start();
        }

        self.notification.clearAll();

        //se loggato e non ho voci menu le carico
        if(self.ls.isLoggedIn()){            
            if(!self.sm.voci){
                //console.log("il menu non sembra configurato lo rigenero ", self.sm);
                self.sm.getMenu();
            }   
            
        }

    });
    

    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams, options){ 

        if(fromState.name !== 'login'){
            $rootScope.previousState = {
                state: fromState,
                params: fromParams
            }
        }
        
        
        //fermo il bloccaschermo
        if (toState.resolve) {
            //console.log("nascondo screensaver per cambio di stato");
            self.bloccaschermo.stop();
        }

        self.svuotaProviders();

        

        //carico il titolo del modulo corrente
        var parametri = {stato: toState.name+"()", parametri: JSON.stringify(toParams)};
        //console.log("ottengo il titolo da ", parametri);
        if(self.ls.isLoggedIn()){
            // $http({
            //     method: 'POST',
            //     params: parametri,
            //     url: utils.urls.wsbase + '/titolodastato/titolomodulo',                
            // }).then(function(resp){
            //     self.titoloh1 = resp.data.titolo;
            // }, function(err){
            //     self.titoloh1 = utils.ambiente;
            // });

            $rootScope.$watch(function(){
                return self.sm.mappaUrlVoci
            }, function(n, o){
                if(n !== null && n !== undefined){
                    var shr = $state.href($state.current.name, $state.params)
                    self.titoloh1 = n[shr];
                }
            })
        } 


    });
}

//numero ordine da dare alla prossima action, accetta un offset da applicare all'ordine dell'ultimo elemento
ToolbarSrv.prototype.getNextOrder = getNextOrder;
//valore di ordine massimo (ultima action)
ToolbarSrv.prototype.getMax = getMax;
//da tutte gli action provider crea un semplice array contenente solo le action
ToolbarSrv.prototype.getUniqueArray = getUniqueArray;
ToolbarSrv.prototype.svuotaProviders = svuotaProviders;
ToolbarSrv.prototype.setProviders = setProviders;

/**
 * Semplifica la struttade di ToolbarSrv.actionProviders in un semplice array di azioni
 * 
 * @memberOf ToolbarSrv
 * @returns {Array} array di azioni uniche
 */
function svuotaProviders(){
    this.actionsProviders = {};
}

/**
 * Setta i provider
 * 
 * @param {type} obj //{nomeprovider: {exposed: azioni}}
 * @param {type} reset
 * @returns {setProviders}
 */
function setProviders(providers, reset){
    //this.bs.start();
    if(reset){
        this.svuotaProviders();
        hotkeys.del();
        console.log("svuoto provider e hotkeys");
    }
    angular.extend(this.actionsProviders, providers);

    //this.bs.stop();
}

/**
 * Semplifica la struttara di ToolbarSrv.actionProviders in un semplice array di azioni
 * 
 * @memberOf ToolbarSrv
 * @returns {Array} array di azioni uniche
 */
function getUniqueArray(){
    var arr = [];
    for(var x in this.actionsProviders){
        arr = arr.concat(this.actionsProviders[x]);
    }
    return arr;
}

/**
 * Ottiene il numero di ordine massimo (ultimo elemento presente sulla toolbar)
 * 
 * @memberOf ToolbarSrv
 * @returns {number}
 */
function getMax(){
    return _.max(this.getUniqueArray(), 'ordine').ordine;
}

/**
 * Ottiene il prossimo numero da utilizzare per i pulsanti della toolbar.
 * Se è passato un offset l'ordine dell'ultimo elemento viene maggiorato dell'offset, altrimenti di 1
 * 
 * @memberOf ToolbarSrv
 * @param {number} [offset=1]
 * @returns {number}
 * 
 * @example
 * var ordineUltimoElemento = this.getMax(); //es 5
 * var ordineProssimoElemento = this.getNextOrder(); //ritorna 6
 * var ordineProssimoElementoConOffset = this.getNextOrder(10); //ritorna 15
 */
function getNextOrder(offset){
    return this.getMax() + (offset || 1);
}

module.exports = ToolbarSrv;