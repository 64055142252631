"use strict";

/**
 * Confronto con altri campi
 * 
 * @class
 * @param {$translate} $translate
 */
function ComparaFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf RequiredFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */     
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.COMPARA", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf RequiredFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */     
    function expression(viewValue, modelValue, scope){
        var valore = modelValue || viewValue || null;
        var esitoValore = valore !== null && typeof valore !== 'undefined';

        var parti = scope.options.data.modello.validations.compara.split(":");
        var segno = parti[0];
        var altrocampo = parti[1];
        var altrovalore = scope.model[altrocampo];
        var esitoAltroValore = altrovalore !== null && typeof altrovalore !== 'undefined';
        
        console.log("compara", valore, segno, altrovalore);

        var campiForm = scope.$parent.$parent.$parent.fields;
        var campoForm = _.findWhere(campiForm, {key: altrocampo});

        scope.opztrad = {};


        
        
        if(esitoValore && esitoAltroValore && typeof campoForm !== 'undefined'){
            scope.opztrad.altralabel = campoForm.templateOptions.label;

            switch(segno){
                case 'bt':
                    scope.opztrad.confrontolabel = ">";
                    return valore > altrovalore;
                    break;
                case 'lt':
                    scope.opztrad.confrontolabel = "<";
                    return valore < altrovalore;
                    break;
                case 'eq':
                    scope.opztrad.confrontolabel = "=";
                    return valore == altrovalore;
                    break;
                case 'ne':
                    scope.opztrad.confrontolabel = "<>";
                    return valore != altrovalore;
                    break;
                case 'be':
                    scope.opztrad.confrontolabel = ">=";
                    return valore >= altrovalore;
                    break;
                case 'le':
                    scope.opztrad.confrontolabel = "<=";
                    return valore <= altrovalore;
                    break; 
                default: 
                    console.log("segno non valido ", segno, "accettati: 'bt', 'be', 'lt', 'le', 'eq', 'ne'");   
            };
    
        }
        
        return true;
    }
        
    return Costruttore;
}

module.exports = ComparaFct;