'use strict';

/**
 * Controller del campo file
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 */
function BSSFileController($scope, $filter, FileUploader, ErrorManager, LoginService){
    $scope.bssfile = {
        uploadFile: null,
    };
    
    //    $scope.$watch(function(){
    //        return $scope.bssfile.uploadFile
    //    }, function(n, o){
    //        $scope.model[$scope.options.key] = n;
    //    });
    
    //console.log("fileuploader: ", FileUploader);
    
    $scope.uploader = new FileUploader({
        url: $scope.options.data.modello.uploadurl,
        alias: $scope.options.data.modello.alias || 'file',
        autoUpload: $scope.options.data.modello.autoUpload || true,
        queueLimit: $scope.options.data.modello.queueLimit || 1
    });
    
    $scope.uploader.onBeforeUploadItem = function (fileItem) {    
        if(typeof localStorage.sessione !== "undefined"){
            var sobj = JSON.parse(localStorage.sessione);
            fileItem.headers = {
                Authorization: 'Bearer ' + LoginService.isLoggedIn() ? LoginService.getToken() : null
            };
            console.log("aggiunto token ", fileItem.headers, $scope.options.data.modello)
        }        
    }
    
    $scope.uploader.onAfterAddingFile = function() {
        var progressbar = angular.element('.bssprogressbar');
        $scope.uploading = true;
        $scope.percentuale = 0;
        console.log("progresso: ", 0);
        progressbar.width('0%');
    };
    
    $scope.uploader.onProgressItem = function(fileitem, progress) {
        var progressbar = angular.element('.bssprogressbar');
        $scope.percentuale = progress;
        progressbar.width(progress+'%');
        $scope.uploading = fileitem.isUploading;
    };
    
    $scope.uploader.onSuccessItem = function(fileitem) {
        $scope.uploading = fileitem.isUploading;         
        console.log("successo", fileitem);       
        $scope.$emit('bssfile::caricato', {infoFile: fileitem});
    };
    
    $scope.uploader.onErrorItem = function(fileitem, response, status, header) {
        $scope.uploading = fileitem.isUploading;                
        $scope.$emit('bssfile::erroreCaricamento', {infoFile: fileitem, errResp: response});
        ErrorManager.show("Errore Caricamento File!");
    };
    
    $scope.uploader.onCompleteItem = function(fileitem) {
        $scope.uploading = fileitem.isUploading;
        
    };
    
}

module.exports = BSSFileController;