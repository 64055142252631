'use strict';

/**
 * Factory per la configurazione di una KendoGrid
 * 
 * @class
 * @param {CfgFactory} CfgF
 * @example
 * new GridCfgFactory("tcomuni", "app", "mod", null)
 */
function GridCfgFactory(CfgF, $timeout){
    /**
     * 
     * @memberOf GridCfgFactory
     * @constructor
     * @param {type} strentity
     * @param {type} codapp
     * @param {type} codmodulo
     * @param {type} opzioni
     * @param {boolean} avoidInit se true non inizializza le opzioni
     * @returns {Object} configurazione KendoGrid
     */
    function Costruttore(strentity, codapp, codmodulo, opzioni, avoidInit){
        var self = this;
        self.$timeout = $timeout;
        self.strentity = strentity;
        self.codapp = codapp;
        self.codmodulo = codmodulo;
        CfgF.apply(this, [strentity, codapp, codmodulo, opzioni]);
        
        this.opzioniBase = {
            lockedHeader: true, //header fisso
            scrollable: true,
            //dataSource : null, //obbligatorio da Datasource.js
            //width: '90%',
            //height: '100%',
            excel: {
                allPages:false,
                filterable: true
            },
            
            sortable: {
                mode: "multiple"
            },
            
            
            pageable: {
                messages: {
                    display: "{0} - {1} di {2} elementi", //{0} is the index of the first record on the page, {1} - index of the last record on the page, {2} is the total amount of records
                    empty: "Nessun elemento da mostrare",
                    page: "Pagina",
                    of: "di {0}", //{0} is total amount of pages
                    itemsPerPage: "elementi per pagina",
                    first: "Vai alla prima pagina",
                    previous: "Vai alla pagina precedente",
                    next: "Vai alla pagina successiva",
                    last: "Vai all'ultima pagina",
                    refresh: "Ricarica"
                },
                numeric: true,
                previousNext: true,
                buttonCount: 5,
                pageSizes: [50, 100, 200]
            },
            filterable: {                
                mode: "menu", //menu, row aggiunge anche la ricerca dall'header
                messages: {
                    info: "Mostra elementi il cui valore:", // sets the text on top of the filter menu
                    filter: "Filtra", // sets the text for the "Filter" button
                    clear: "Ripulisci", // sets the text for the "Clear" button
                    
                    // when filtering boolean numbers
                    isTrue: "è vero", // sets the text for "isTrue" radio button
                    isFalse: "è falso", // sets the text for "isFalse" radio button
                    selectValue: "-Seleziona un Valore-",
                    //changes the text of the "And" and "Or" of the filter menu
                    and: "E",
                    or: "O"
                },
                operators: {
                    //filter menu for "string" type columns
                    string: {
                        startswith: "Comincia con",
                        incr: "Incrementale",
                        contains: "Contiene",
                        eq: "Uguale a",
                        neq: "Diverso da",                
                        endswith: "Termina per",
                    },
                    //filter menu for "number" type columns
                    number: {
                        //incr: "Incrementale",
                        eq: "Uguale a",
                        neq: "Diverso da",
                        gte: "Maggiore o uguale a",
                        gt: "Maggiore di",
                        lte: "Minore o uguale a",
                        lt: "Minore di",
                    },
                    //filter menu for "date" type columns
                    date: {
                        //incr: "Incrementale",
                        eq: "Uguale a",
                        neq: "Diverso da",
                        gte: "Successiva o uguale al",
                        gt: "Successiva al",
                        lte: "Precedente o uguale al",
                        lt: "Precedente al",
                    },
                    enums: {
                        //incr: "Incrementale",
                        eq: "Uguale a",
                        neq: "Diverso da",
                    }
                }
            },
            columnMenu: {
                messages: {
                    columns: "Mostra colonne",
                    filter: "Filtra",
                    sortAscending: "Ordina (1,2,3)",
                    sortDescending: "Ordina (3,2,1)",
                    lock: "Blocca",
                    unlock: "Sblocca",
                }
            }, //menu contestuale per ogni colonna
            columnMenuInit: function (e) {
                var menu = e.container.find(".k-menu").data("kendoMenu");
                var field = e.field;
                var griglia = e.sender;
                
                var itemHtml = '<li class="k-item k-state-default" role="menuitem">' + 
                        '<span class="k-link bssrinominacolonna"><i class="fa fa-i-cursor"></i> Rinomina</span></li>';
                $(e.container).find("ul:first").append(itemHtml);
                
                // add an event handler
                menu.bind("select", function (e) {
                    var menuText = $(e.item).find(".bssrinominacolonna");
                    if (menuText.length) {
                        var colonna = _.findWhere(griglia.columns, {field: field});
                        //console.log("colonna prima di modificare", colonna);
                        var nuovonome = prompt("Rinomina la colonna: " + colonna.title||colonna.field);
                        if(nuovonome){

                            var colonneaggiornate = _.map(griglia.columns, function(col){
                                if(col.field === colonna.field){
                                    col.title = nuovonome;
                                }
                                return col;
                            });

                            griglia.setOptions("columns", colonneaggiornate);

                        }

                    } 
                });
                
            },
            
            pdf: {
                allPages: false
            },
            
            allowCopy: true,
            
            resizable: true,
            /*
            columnResize: function(e) {//evento colonna ridimensionata
                console.log(this.columns);
                console.log(e.column.field, e.newWidth, e.oldWidth);
            },
             */
            
            reorderable: true,
            noRecords: true,
            messages: {
                noRecords: "Nessun elemento da visualizzare"
            },
            
            /*
            columnReorder: function(e) {//evento colonne riordinate
                console.log(e.column.field, e.newIndex, e.oldIndex);
                console.log(this.columns);
            },
             */
            toolbar: false,
            columns: null, //OBBLIGATORIO //this.columns, //.concat([{ command: { text: "Dettagli", click: this.mostraDettagli }, title: " ", width: "180px"}]),
            
            selectable: utils.ambiente === "Ricambi Web" ? "row" : "multiple, row",
            // dovrebbe causare il problema dell'impossibilità di selezionare a saltare usando ctrl
            // navigate: function(e){
            //     var riga = $(e.element).closest("tr");
            //     var w = e.sender;
            //     if(typeof w !== 'undefined' || w !== null){
            //         w.clearSelection();
            //         w.select(riga);
            //     }
                
            // },
            
            navigatable: true,
            
            groupable: false/*{
            
                messages: {
                    empty: "Trascina qui una colonna per raggruppare i risultati"
                },
            }*/,
            editable: {
                mode: 'none',
                confirmation: false,
            },
            change: function(e){
                
                //e.sender.table.focus();
            },            
            /*dataBound: function(e){
                if(typeof pattuale !== 'undefined' && pattuale != this.dataSource.page() && rigadasel){//c'è stato un cambio di pagina per ricerca incrementale
                    console.log("cambio pagina e selezione riga");
                    _selezionaRiga(griglia, offset);
                }
            },*/
            /*
            change: function(e) {//selezione attiva
                var selectedRows = this.select();
            
                var scope = angular.element($(finestra + " .bssmodule-toolbar")).scope();
                if(scope){
                    var tmpnr = 0; var tmpids = [];
                    for(var i = 0; i < selectedRows.length; i++){
                        var tmpdata = this.dataItem(selectedRows[i]);
                        var tmpid = tmpdata[tmpdata["idField"]];
                        if($.inArray(tmpid, tmpids) == -1){
                            tmpids.push(tmpid);
                            tmpnr++;
                        }
            
                    }
            
                    scope.$apply(function(){
                        scope.nrowsel = tmpnr;
                    });
                }
            },
             */
            /*
            per eventi e metodi: http://docs.telerik.com/kendo-ui/api/javascript/ui/grid
             */            
        };
        
        if(avoidInit){}
        else {
            return this.inizializza(opzioni);
        }                
    }    
    
    Costruttore.prototype = new CfgF;
    
    Costruttore.prototype.inizializza = inizializza;
    
    /**
     * Ottiene le columns della griglia, crea tutta la configurazione e la ritorna in maniera asincrona come promise
     * @memberOf GridCfgFactory
     * @param {type} opzioni
     * @returns {promise} 
     */
    function inizializza(opzioni){
        var self = this;
        self.setOpzioni(opzioni);

        return self.asyncSetCfg().then(function(resp){
            var showFilterInRows = false;
            self.opzioni.columns = self.headers.columns.map(function(colonna){
                /**
                 * A livello di grid tramite filterable: {mode: 'menu'} o filterable: {mode: 'menu, row'} è possibile rispettivamente avere i filtri di colonna
                 * nel menù a tendina da solo o il menù a tendina e la ricerca per colonna con campo sempre visibile.
                 * 
                 * Tuttavia una volta abilitati per l'intera griglia non è possibile modificare tale impostazione per singola colonna.
                 *
                 * L'unica soluzione consiste nel nascondere il campo di ricerca nella riga quando non richiesto
                 * 
                 * A tal fine le column supportano la property showrowfilter
                 * 
                 * Se anche una sola colonna ha showrowfilter impostato a true allora imposto per l'intera griglia anche i filtri in modalità row
                 * 
                 * Se per la colonna showrowfilter è false, null o undefined allora tramite template nascondo il campo di ricerca veloce
                 *  
                 */
                var colfilcell = window['bssRecursiveGetter'](colonna, "filterable.cell", null);

                if(colonna.showrowfilter && typeof colonna.showrowfilter !== 'undefined' && colonna.showrowfilter !== null){//almeno una colonna ha i filtri in row
                    showFilterInRows = true;
                    /**
                     * per ricambi web il filtro di colonna non deve essere un autocomplete
                     */
                    if(utils.ambiente === "Ricambi Web"){
                        //console.log("|=========> modifico colonna per ricambi web da", colonna)
 
                        if(colfilcell !== null){
                            colonna.filterable.cell.template = function(args){
                                //aggiungo la classe css per uniformare l'aspetto
                                args.element.addClass("k-textbox")                                    
                            }
                        }

                        //console.log("|=========> modifico colonna per ricambi web in", colonna)                  
                        
                    }
                } 
                else {//per questa colonna nascondo la ricerca in row     
                    
                    if(colfilcell !== null){
                        colonna.filterable.cell.showOperators = false;
                        colonna.filterable.cell.template = function(args){
                            $(args.element).remove()
                        }
                    }
                }                                              
                return colonna;
            }); 
            if(showFilterInRows){
                if(self.opzioni.filterable.mode === 'menu'){
                    self.opzioni.filterable.mode = 'menu, row'
                }                
            }
            self.opzioni.form = self.headers.form;
            self.opzioni.formmodel = self.headers.formmodel;
            self.opzioni.nomeCfgGriglia = "GRID@"+self.entity+"@"+self.codmodulo;
            self.opzioni.nomeCfgForm = "FORM@"+self.entity+"@"+self.codmodulo;
            self.opzioni.strentity = self.strentity;
            self.opzioni.codapp = self.codapp;
            self.opzioni.codmodulo = self.codmodulo;
            self.opzioni.entity = self.entity;
            return self.opzioni;
        });
    }
    
    return Costruttore;
}

module.exports = GridCfgFactory;