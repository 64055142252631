'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo di tipo input:text
 * 
 * @class
 * 
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 * 
 * 
 */



function BSSIconpicker(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssiconpicker',
        template: require("./bssiconpicker.html"),
        controller: ["$scope", "$filter", "$timeout", "$http", "$sce", "ErrorManager", "DSFactory", "FieldFactory", "Valida", require("./BssIconpickerController")],
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssiconpicker'
            },
        }
        
    }));
};


module.exports = BSSIconpicker;