"use strict";

/**
 * Confronta 2 valori se sono uguali ritorna true
 * Da implementare
 * 
 * @class
 * @param {$translate} $translate
 */
function ConfirmFct($translate){
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf ConfirmFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.CONFIRM", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf ConfirmFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */
    function expression(viewValue, modelValue, scope){
        if(typeof scope.options.data.conferma === 'undefined') return true;
        var valore = modelValue || viewValue || null;
        console.log(scope.model);
        
        return valore === scope.model[scope.options.data.conferma];
    }
        
    return Costruttore;
}

module.exports = ConfirmFct;