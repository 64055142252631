'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo file
 * Imposta ngModel con il base64 del contenuto del file 
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 * @returns {undefined}
 */
function BSSFile2(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssfile2',
        template: require("./bssfile2.html"),
        defaultOptions: {
            ngModelElAttrs: {                
                'cssCategory': 'bssfile',
            }
        },
        //controller: ["$scope", "$filter", "Upload", "ErrorManager", require("./BssFile2Controller")] //definire il controller
    }));
};

module.exports = BSSFile2;