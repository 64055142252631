'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /teststoragecommunications2
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} Teststoragecommunications2FunzioniAggiuntive
 */
function Teststoragecommunications2Ctrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, Teststoragecommunications2FunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = Teststoragecommunications2FunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;

    localStorage.setItem("conta", 0);
    self.storage = {conta: localStorage.getItem("conta")};

    window.addEventListener("storage", function(e){
        console.log("evento storage 2", e);
        if(e.key === "conta"){
            self.storage = {conta: Number(e.newValue)}
        }
    })

    self.incrStorage = function(){
        var conta =  Number(localStorage.getItem("conta"));
        conta = isNaN(conta) ? (0) : (conta + 1);
        localStorage.setItem("conta", conta);
        self.storage = {conta: conta};
    }

}

module.exports = Teststoragecommunications2Ctrl;
