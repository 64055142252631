'use strict';

/**
 * UndoTab - direttiva che annulla il tab se il campo è invalido
 * PS. non funziona se la validazione avviene all'uscita dal campo
 * 
 * @class 
 * @returns {angularDirective} direttiva 
 *
 */
function UndoTabDir(){
    return {
        link: function(scope, element, attrs){
            
            if(attrs["undoTab"] === "true") {
                element.bind("keydown keypress", function (event) {
                    //console.log(attrs);
                    //console.log("pressione tasto");
                    if(event.which === 9) {
                        var elscope = angular.element(element).scope();
                        var elformcontrol = elscope.options.formControl[0];//angular.element(element).scope().options.formControl[0];
                        //console.log("form control pressione tab");
                        //console.log(elformcontrol);
                        elformcontrol.$untouched = false;
                        elformcontrol.$touched = true;
                        elscope.$apply();
                        //console.log(elscope.options.validation);
                        //console.log("tasto premuto tab");
                        
                        if(elformcontrol.$invalid){    
                            event.preventDefault();
                        }

                    }
                });
            }
            
        }
    }
}

module.exports = UndoTabDir;