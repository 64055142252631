"use strict";

/**
 * @module bss/formatters
 * @description fornisce i formatters per rendere gli output omegenei 
 */
var bssformatters = angular.module("bss.formatters", [])
    .filter("bssnumberfilter", require("./BSSNumberFilter.js"));
;

module.exports = bssformatters;