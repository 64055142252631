'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo file
 * Imposta ngModel con il base64 del contenuto del file 
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 * @returns {undefined}
 */
function BSSSyncFile(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bsssyncfile',
        template: require("./bsssyncfile.html"),
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bsssyncfile',
            }
        },
        controller: ["$scope", "$filter", "FileUploader", "ErrorManager", require("./BssSyncfileController")] //definire il controller
    }));
};

module.exports = BSSSyncFile;