'use strict';

/**
 * Controller relativo al component ToggleActionCmp
 * 
 * @class
 */
function ToggleActionCtrl(hotkeys, Toolbar){   
    var self = this;
    self.ts = Toolbar;

    [self.act1, self.act2].forEach(function(ac){
        if(ac.combokey){
            hotkeys.add({
                combo: ac.combokey,
                callback: function(){
                    if(!ac.disabilita()){
                        ac.azione()                    
                    }
                    else {
                        console.log("Pulsante disabilitato, non puoi eseguire: ", ac.descrizione);
                    }
                },
                description: ac.descrizione
            });

        }
    })        
    
}
    

module.exports = ToggleActionCtrl;