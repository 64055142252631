'use strict';

/**
 * Factory per l'ottenimento della configurazione di base di un modulo.
 * In realtà non viene quasi mai utilizzato direttamente, ma viene esteso da altri Factory.
 * 
 * @class
 * @param {$httpProvider} $http
 */
function CfgFactory($http, $timeout){
    
    /**
     * 
     * @memberOf CfgFactory
     * @constructor
     * @param {type} strentity
     * @param {type} codapp
     * @param {type} codmodulo
     * @param {type} opzioni sovrascriveranno e si aggiungeranno alle opzioni di base ottenute
     * @returns {CfgFactory.Costruttore}
     */
    function Costruttore(strentity, codapp, codmodulo, opzioni){
        this.strentity = strentity;
        this.codapp = codapp;
        this.codmodulo = codmodulo;
        this.$http = $http;
        this.$timeout = $timeout;
        this.baseUrl = utils.urls.wsbase + "/" + this.strentity;
        this.opzioniCfgBase = opzioni || {};
        this.opzioniBase = {foo: 'foo', bar: 'bar'};              
    }
    
    Costruttore.prototype.asyncSetEntity = asyncSetEntity;
    Costruttore.prototype.asyncSetHeaders = asyncSetHeaders;
    Costruttore.prototype.asyncSetCfg = asyncSetCfg;
    Costruttore.prototype.setOpzioni = setOpzioni;            
    
    /**
     * Permette di ottenere il jdni dell'entity in maniera asincrona
     * 
     * @memberOf CfgFactory.prototype
     * @returns {promise}
     */
    function asyncSetEntity(){
        var self = this;

        if(self.strentity.startsWith("com.bsssrl.")){
            self.entity = self.strentity;
            return self.$timeout(function(){
                return self.entity;
            })
        }

        return self.$http({
            cache: true,
            url:  self.baseUrl + "/entity"
        }).then(function(res){
            self.entity = res.data.classe;
            return res.data;
        }, function(res){
            throw res;
        });
    }
 
    /**
     * Permette di ottenere le colonne della griglia e il model del datasource
     * 
     * @memberOf CfgFactory.prototype
     * @returns {promise}
     */
    function asyncSetHeaders(){
        var self = this;
        var parametri = {
            entity: self.entity,
            codapp: self.codapp,
            codmodulo: self.codmodulo,
            strentity: self.strentity,
        };

        
        return self.$http(self.opzioniCfgBase.headerCfg || {
            url: utils.urls.wsbase + "/table/header",
            cache: true,
            method: 'GET',
            params: parametri//$.param(parametri)
        }).then(function(res){
            self.headers = res.data;
            return res.data;
        }, function(res){
            throw res;
        });   
    }
    
    /**
     * Richiama prima asyncSetEntity poi, in caso di successo, asyncSetHeaders
     * 
     * @memberOf CfgFactory.prototype
     * @returns {promise}
     */
    function asyncSetCfg() {
        var self = this;
        return self.asyncSetEntity().then(function(res){
            return self.asyncSetHeaders().then(function(res){
                self.cfg = res;
                return res;
            })                  
        });
    }
    
    /**
     * Ricava le opzioni estendendo quelle di base
     * 
     * @param {Object} opzioni
     * @returns {Object} 
     */    
    function setOpzioni(opzioni){
        //this.opzioni = angular.extend({}, this.opzioniBase, opzioni);
        var datiCfg = {
            datiCfg: {
                entity: this.entity || null,
                codapp: this.codapp || null,
                codmodulo: this.codmodulo || null,
                strentity: this.strentity || null,
            }
        };

        this.opzioni = bsslodash.merge({}, this.opzioniBase, opzioni, datiCfg);
        //console.log("===>Merge di", this.opzioniBase, opzioni, this.opzioni)
    }
    
    return Costruttore;
}

module.exports = CfgFactory;