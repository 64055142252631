'use strict';

/**
 * Factory che crea una action, ovvero un oggetto che condivide le proprietà con quelle dello scope di ActionCmp.
 * @class
 */
function ActionFactory($translate, hotkeys){

    /**
     * 
     * @memberOf ActionFactory
     * @constructor
     * @param {string} icona class fontawesome o bootstrap
     * @param {Function} disabilita è una funzione esposta da una classe che estende EsponiMixin
     * @param {Function} azione è una funzione esposta da una classe che estende EsponiMixin
     * @param {number} ordine ordine di apparizione sulla toolbar
     * @param {string} [descrizione=null] testo che verrà messo nell'attributo title
     * @param {string} chiavetraduzione chiave di traduzione IMPORTANTE: ha priorità maggiore rispetto a descrizione
     * 
     */
    function Costruttore(icona, disabilita, azione, ordine, descrizione, chiavetraduzione, combokey, azioni, identificativo){
        var self = this;
        this.icona = icona;
        this.icone = null;
        this.disabilita = disabilita;
        this.azione = azione;
        this.ordine = ordine;
        this.combokey = combokey || null;
        this.azioni = azioni || null;
        this.chiavetraduzione = chiavetraduzione;
        this.$translate = $translate;
        this.identificativo = identificativo || null;

        
        
        $translate(chiavetraduzione).then(function(descrTradotta){
            self.descrizione = descrTradotta;
        }, function(chiaveMancante){
            self.descrizione = descrizione;
        });
                
    }
    
    return Costruttore;
}




module.exports = ActionFactory;