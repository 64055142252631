'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /maxphoimport
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} MaxphoimportFunzioniAggiuntive
 */
function MaxphoimportCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, FieldFactory, Valida, ErrorManager, MaxphoimportFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = MaxphoimportFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.ff = FieldFactory;
    self.valida = Valida;
    self.em = ErrorManager;

    /* decommenta per popolare griglia toolbar e form del modulo base
    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = $stateParams;

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype);

    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza();
    */
   self.campi = [
    new self.ff({
        "key": "da",
        "tipo": "bssdatetime", 
        "label": "Dalla Data",
        "colonna": {},
        "modello": {validations: {required: true},}
    },
    false,
    {
        "className": "col-lg-6",                                
    }).opzioni,
    new self.ff({
        "key": "a",
        "tipo": "bssdatetime", 
        "label": "Alla Data",
        "colonna": {},
        "modello": {validations: {required: true},}
    },
    false,
    {
        "className": "col-lg-6",                                
    }).opzioni,
   ]
}

MaxphoimportCtrl.prototype.importa = importa;
MaxphoimportCtrl.prototype.ricaricaTimer = ricaricaTimer;

function importa(){
    var self = this;
    if(self.valida.validaForm(self.formObj)){
        self.inimport = true;
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/maxphoconnector/orderlist",
            data: $.param({
                from: moment(self.intervallo.da).format("YYYY-MM-DD HH:mm:ss.sss"), 
                to: moment(self.intervallo.a).format("YYYY-MM-DD HH:mm:ss.sss"), 
            })
        }).then(function(resp){
            self.em.showT("MAXPHOIMPORT.SUCCIMPORT", "success");
            self.inimport = false;
        }, function(err){
            self.inimport = false;
        })
    } else {
        self.em.showT("MAXPHOIMPORT.ERRINTERVAL", "error");
    }


}


function ricaricaTimer(){
    var self = this;
    
    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/maxphoconnector/reloadtimers",    
    }).then(function(resp){
        self.em.showT("MAXPHOIMPORT.TIMERRELOADED", "success");
    })
    


}

module.exports = MaxphoimportCtrl;
