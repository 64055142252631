'use strict';

require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/configuratore/scripts/index.js");
require("moduleFold/router/scripts/index.js");
require("moduleFold/traduzioni/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/genericidentity/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("../styles/grid.scss");

var GridResizer = require("moduleFold/estensioni/Grid/GridResizer.js");

/**
 * 
 * @module bss/griglia
 * @description Modulo che fornisce tutto il necessario a mostrare una KendoGrid e ad interagirci
 */
var bssgriglia = angular.module("bss.griglia", ["bss.toolbar", "bss.configuratore", "bss.router", "bss.traduzioni", "bss.notifiche", "bss.genericidentity", "bss.formbuilder"])
    .factory("GridCfgFactory", ["CfgFactory", "$timeout", require("./GridCfgFactory")])
    .factory("GridFactory", ["GridCfgFactory", "ToolbarSrv", "GridNavigator", "$timeout", "ActionFactory", "$rootScope", "$state", "$stateParams", "$translate", "GridIdentity", "FormDaGridFactory", "ErrorManager", "$q", "$window", "StatoApp", require("./GridFactory")])
    .factory("GridNavigator", ["$timeout", "$q", "LoginService", "$rootScope", "$state", "$stateParams", "FormDaHeaderFactory", "Valida", "ActionFactory", "ToggleActionFactory", "$http", "ErrorManager", "StatoApp", "ToolbarSrv", require("./GridNavigator")])        
    .directive("kHeaderColumnMenu", [require("./EvidenziaColonnaFiltrata")])
    .directive("bssRowDblClick", [require("./RowDblClick")])
;

//$(window).resize(function () {
//    new GridResizer();
//});

module.exports = bssgriglia;