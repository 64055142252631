'use strict';

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");


/**
 * 
 * @class  
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSCalendario(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bsscalendario',
        extends: 'bssinput',
        template: require("./bsscalendario.html"),
        controller: ["$scope", "$rootScope", "$filter", "$timeout", "$http", "ErrorManager", "DSFactory", require("./BssCalendarioController")],
        defaultOptions: {
            defaultValue: null,
            ngModelElAttrs: {
                'cssCategory': 'bsscalendario'
            },
        }
    }));
}

module.exports = BSSCalendario;