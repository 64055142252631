'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione del campo single select sincrono (autocomplete e select per valori predefiniti)
 *
 * NB: ngOptions è un attributo supportato solo dai tag select. Sebbene ui-select-single somigli a una select è in realtà composto da diversi div.
 * Occorre definire pertanto quale attributo andrà interpretato come ngOptions usando optionsAttr (es: optionsAttr: "bs-options").
 * Non è possibile definire optionsAttr in defaultOptions.templateOptions, in questo caso le opzioni verranno ignorate e genererà errore.
 * Occorre definirlo per ogni campo nell'oggetto templateOptions.
 * Affinchè questo tipo di campo funzioni occorre passare delle opzioni come array di oggetti.
 * Per interpretare le opzioni vengono utilizzati gli attributi defaultOptions.templateOptions.labelProp e defaultOptions.templateOptions.valueProp.
 * Esempio opzioni: [{id:1, label:'foo'}, {id:2, label:'bar'}];
 * Configurazione: defaultOptions.templateOptions.labelProp = "label"; defaultOptions.templateOptions.valueProp = "id";
 * Selezionando la prima opzione il model campo varrà 1 mentre la label che risulterà selezionata sarà 'foo'
 * Le opzioni devono essere fornite alla creazione del campo
 * la versione asincronà verrà implementata successivamente.
 * 
 * "tipoconsegna": {
 *   "campoCfg": {
 *     "key": "tipoconsegna",
 *     "tipo": "bssselect",
 *     "label": "Tipo Consegna",
 *     "colonna": {},
 *     "modello": {
 *       "validations": {
 *         "required": true
 *       }
 *     }
 *   },
 *   "readOnly": false,
 *   "opzioni": {
 *     "className": "col-lg-6",
 *     "templateOptions": {}
 *   }
 * }
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSSelect(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssselect',
        template: require("./bsssingleselect.html"), 
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssselect',
            },
            templateOptions: {
                //MODIFICA NECESSARIA A FAR FUNZIONARE I TEMPLATE IN MASTRO DETTAGLIO NELLA GRIGLIA DEL DETTAGLIO PER IL CAMPO BSSSELECT
                //ngOptions: "option[to.valueProp] as option in to.options | filter: $select.search",
                ngOptions: "option in to.options | filter: $select.search",
                valueProp: "value",
                labelProp: "text",
            },
            
        }
    }));
};

module.exports = BSSSelect;