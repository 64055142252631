'use strict';

/**
 * Factory per la grid di utenti identity
 *
 * @class
 * @returns {ModulemanagerGridFct.Costruttore}
 */
function ModulemanagerGridFct(ModulemanagerIdentity, ModulemanagerRS, DSCfgFactory, GridCfgFactory, ToolbarS, GridNav, $timeout, ActFct, $rootScope, $state, $translate, GridIdentity){

    /**
     *
     * @memberOf ModulemanagerGridFct
     * @constructor
     */
    function Costruttore(){
        this.identity = ModulemanagerIdentity;
        this.rs = ModulemanagerRS;
        var self = this;
        GridIdentity.apply(this);
    }

    Costruttore.prototype.elimina = elimina;
    Costruttore.prototype.getGridCfg = getGridCfg;
    Costruttore.prototype.getDSCfg = getDSCfg;

    /**
     * @memberOf ModulemanagerGridFct
     * @returns {Object}
     */
    function getGridCfg(){
        return {
            columns: [
                {field: 'esempio', title: 'Username', width: '120'},
            ]
        };
    }

    /**
     * @memberOf ModulemanagerGridFct
     * @returns {Object}
     */
    function getDSCfg(){
        var self = this;
        return {
            serverSorting: false,
            serverPaging: false,
            serverGrouping: false,
            serverFiltering: false,
            serverAggregates: false,
            transport: {
                read: {
                    type: "POST",
                    url: self.rs.baseUrl + "/cercatutti",
                    dataType: 'json',
                    cache: true,
                    /*
			data: function(){
				//return dei dati da passare sempre
			}
                     */
                },
            },
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        esempio: {type:'string'},
                    }
                }
            }
        };
    }

    /**
     * @memberOf ModulemanagerGridFct
     */
    function elimina(){
        var self = this;
        var selezione = self.ts.selezione[0];
        self.tr("MODULEMANAGERMANAGER.CONFIRMELIMINA", {entity: selezione.descrizione}).then(function(messaggio){
            if(confirm(messaggio)){
                self.timeout(function(){
                   console.log("procedo con la cancellazione di ", selezione);
                   //da testare
                   //self.rs.cancella({idutente: selezione.id})
                });
            }
        });
    }

    return Costruttore;
}

module.exports = ModulemanagerGridFct;
