'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /teststoragecommunications
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} TeststoragecommunicationsFunzioniAggiuntive
 */
function TeststoragecommunicationsCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, TeststoragecommunicationsFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = TeststoragecommunicationsFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    
    localStorage.setItem("conta", 0);
    self.storage = {conta: localStorage.getItem("conta")};

    window.addEventListener("storage", function(e){
        console.log("evento storage 1", e);
        if(e.key === "conta"){
            self.storage = {conta: Number(e.newValue)}
        }
    })

    self.incrStorage = function(){
        var conta =  Number(localStorage.getItem("conta"));
        conta = isNaN(conta) ? (0) : (conta + 1);
        localStorage.setItem("conta", conta);
        self.storage = {conta: conta};
    }

    self.dataSource = new kendo.data.PivotDataSource({
        type: "xmla",
        columns: [{ name: "[Periodo]"}],
        rows: [{ name: "[Tipo Documento]", expand: true}],
        measures: ["[Measures].[Quantità]", "[Measures].[Importo]", "[Measures].[Costo]", "[Measures].[Margine Valore]"],
        transport: {
            connection: {
                catalog: "TestIncassi",
                cube: "Incassi"
            },
            read: utils.urls.wsbase + "/mondrian/xmla"
            //read: "http://dan.svi:8091/xmla"
        },
        schema: {
            type: "xmla"
        },
        error: function (e) {
            console.log("errore:", e);            
        }
    });
    self.options = {
        excel: {
            fileName: "esportazione.xlsx",
            filterable: true
        },
        columnWidth: 200,
        height: 580,
        dataSource: self.dataSource,
        filterable: true
    };


}

module.exports = TeststoragecommunicationsCtrl;
