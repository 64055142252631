require("moduleFold/mastrodettaglio/styles/mastrodettagliov2.scss");

'use strict';


function MastroDettaglioMixinV2(){

}

MastroDettaglioMixinV2.prototype.disabilitaSalva = disabilitaSalva;
MastroDettaglioMixinV2.prototype.eliminasel = eliminasel;
MastroDettaglioMixinV2.prototype.evidenziarighe = evidenziarighe;
MastroDettaglioMixinV2.prototype.focusgriglia = focusgriglia;
MastroDettaglioMixinV2.prototype.openeditnuovo = openeditnuovo;
MastroDettaglioMixinV2.prototype.openeditTemplate = openeditTemplate;
MastroDettaglioMixinV2.prototype.osservaSelezione = osservaSelezione;
MastroDettaglioMixinV2.prototype.salvaesuccessivo = salvaesuccessivo;
MastroDettaglioMixinV2.prototype.salvamastrodettaglio = salvamastrodettaglio;
MastroDettaglioMixinV2.prototype.salvariga = salvariga;
MastroDettaglioMixinV2.prototype.selezioneSinc = selezioneSinc;
MastroDettaglioMixinV2.prototype.sostituiscisalva = sostituiscisalva;
MastroDettaglioMixinV2.prototype.toggleedit = toggleedit;
MastroDettaglioMixinV2.prototype.vaiprecedente = vaiprecedente;
MastroDettaglioMixinV2.prototype.vaisuccessivo = vaisuccessivo;
MastroDettaglioMixinV2.prototype.disabilitaricaricadettaglio = disabilitaricaricadettaglio;
MastroDettaglioMixinV2.prototype.getSelezione = getSelezione;

function getSelezione(){
    var self = this;
    var griglia = self.ts.griglia;
    var selezioneEls = Array.from((griglia?.select()) ?? []);
    var selezione = selezioneEls.map(e => griglia.dataItem(e));
    //console.log("selezione griglia", selezioneEls, selezione);
    var idtestata = selezione?.[0]?.id ?? 0;
    return idtestata;
}

function disabilitaricaricadettaglio() {
    var self = this;
    return self.righe.length !== 0 
        || self.righeeliminate.length !== 0
}

function openeditTemplate(mode){
    var self = this;
    var parametri = {};
    var selezione = self.getSelezione()[0];
    var id = typeof selezione !== 'undefined' ? selezione.id : 0;
    parametri[self.parametri.campotestata] = {id: id};
    self.openedit(parametri, mode);
}

function focusgriglia(){
    var self = this;
    var selezione = self.righeGrid.select();
    var cella = null;
    if(selezione.length){
        var dataItem = self.righeGrid.dataItem(selezione);
        cella = self.righeGrid.tbody.find("tr[data-uid='"+dataItem.uid+"'] td:visible:first");
    }
    else {
        cella = self.righeGrid.tbody.find("tr[role='row']:first td:visible:first");
    }
    console.log("sposto il focus sulla cella", cella);
    //cella = self.righeGrid.tbody.find("tr[role='row']:first td:visible:first");
    self.righeGrid.clearSelection();
    self.righeGrid.current(cella);    
}

function salvamastrodettaglio(){
    var self = this;
    var modello = {};

    /*
    //elimina id se vuoto
    self.righe = _.map(self.righe, function(riga){
        if(riga.id === ''){
            delete riga.id;
        }   
        return riga;
    });
    */

    var testata  = angular.extend({}, self.dataItemTestata);
    if(typeof self.gfi !== 'undefined') testata = angular.extend({}, testata, self.gfi.getmodello());

    modello[self.parametri.campotestata] = JSON.stringify(testata);
    modello[self.parametri.campoim] = JSON.stringify(self.righe);
    modello[self.parametri.campoe] = JSON.stringify(self.righedaeliminare);

    return self.$http({
        method: "POST", 
        url: utils.urls.wsbase + self.parametri.wssalvamd, 
        data: $.param(modello)
    });
    
}

function sostituiscisalva(){
    var self = this;
    
    self.gfi.salvacrea = function(){return self.salvamastrodettaglio();}
    self.gfi.salvamodifica = function(){return self.salvamastrodettaglio();}
    
    
}

function disabilitaSalva(){
    var self = this;
    return (self.getModalita() !== "form");
}

function osservaSelezione(){
    var self = this;
    
    var parametriStd = {
        wssalvamd: "/" + self.parametri.strentity + "/" + self.parametriDettaglio.strentity + "/creamasdet",
        campotestata: "testata",
        campoim: "imrighe",
        campoe: "erighe"
    };

    var parametriDettaglioStd = {
        idtestata: "idtestata",
        wsread: "/" + self.parametriDettaglio.strentity + "/righetestata"
    };

    self.parametri = angular.extend({}, parametriStd, self.parametri);
    self.parametriDettaglio = angular.extend({}, parametriDettaglioStd, self.parametriDettaglio);

    self.$scope.$on("kendoWidgetCreated", function(event, widget){
        if(widget === self.editRigheWin) {
            widget.bind("close", function(){
                self.focusgriglia();
            });

            widget.bind("activate", function(){   
                console.log(self.editRigheWin);             
                var fe = self.editRigheWin.element.find(':input:enabled:visible:first');
                fe.select();
                fe.focus();
            });

            widget.element.keydown(function(e){
                
                if(e.key === 'Escape'){
                    widget.close();
                }

                if(e.altKey){
                    e.preventDefault();

                    self.mostrasc = true;

                    if(e.key === 'ArrowLeft'){
                        self.vaiprecedente();
                    }

                    if(e.key === 'ArrowRight'){
                        self.vaisuccessivo();
                    }

                    if(e.key === 's'){
                        self.salvaesuccessivo();
                    }

                    if(e.shiftKey){
                        console.log(e.key);
                        if(e.key === 'S'){
                            self.salvariga();                        
                        }
                    }

                    if(e.key === 'a'){
                        self.opzioniformriga.resetModel(); 
                        self.editRigheWin.close()
                    }

                    if(e.key === 'e'){
                        self.confermaDelWin.open().center();
                    }
                }


            });

            widget.element.keyup(function(e){
                if(e.key === 'Alt'){
                    self.mostrasc = false;
                }
            });
        }
    });
    
    //al cambio della selezione ripopola la grid delle righe
    self.$scope.$watch(function(){
        return self.getSelezione();
    }, function(n , o){
        console.log("vecchia selezione o", o, "nuova selezione", n);

        self.righe = [];
        self.righeeliminate = [];
        self.righedaeliminare = [];
        self.righeGridMostra = false;
        

        var testata = {id: 0};

        if(n !== 0){
            var griglia = self.ts.griglia;
            var selezioneEls = Array.from((griglia?.select()) ?? []);
            var selezione = selezioneEls.map(e => griglia.dataItem(e));
            console.log("selezione griglia", selezioneEls, selezione);
            testata = selezione?.[0] ?? {id: 0};
        }

        self.dataItemTestata = testata;
    
        var opzAdd = {                    
            serverPaging: false,                    
            pageSize: 999999,
            transport:{
                read: {
                    url: utils.urls.wsbase + self.parametriDettaglio.wsread, 
                    data: function(){        
                        var parametri = {};                                        
                        parametri[self.parametriDettaglio.idtestata] = testata.id; 
                        if(typeof self.parametriDettaglio.fnAdditionalParameters !== 'undefined' && self.parametriDettaglio.fnAdditionalParameters !== null){
                            parametri = angular.extend({}, parametri, self.parametriDettaglio.fnAdditionalParameters() || {})                               
                        }   
                        return parametri;                                                            
                    },                    
                    method: 'POST' 
                }
            }
        };
        
        new self.dsf(self.parametriDettaglio.strentity, null, self.parametri.codmodulo, opzAdd).inizializza().then(function(ds){
            new self.gcf(self.parametriDettaglio.strentity, null, self.parametri.codmodulo, null, true).inizializza({pageable: false}).then(function(gridcfg){                       
                
                

                self.righeds = ds;
                gridcfg.dataSource = ds;   
                gridcfg.dataBound = function(e){
                    var griglia = e.sender;

                    var righe = griglia.tbody.find("tr");       
                    console.log("righe aggiornate", righe);

                    griglia.table.on("keydown", function(e){
                        if(e.altKey){
                            e.preventDefault();
                            self.mostrasc = true;
                            if(e.key === 'n'){                                
                                self.openeditnuovo();
                            } 
                            if(e.key === 'm'){
                                var dataItem = griglia.dataItem(griglia.select());   
                                if(dataItem === null){
                                    self.openeditnuovo();
                                }  
                                else {
                                    self.openedit(dataItem, "Modifica");
                                }
                            }
                            if(e.key === 'e'){
                                if(!self.righeselvuote()){
                                    self.confermaDelWin.open().center();
                                }
                            }
                        }
                    });

                    griglia.table.on("keyup", function(e){
                        if(e.key === 'Alt'){
                            self.mostrasc = false;
                        }
                    });

                    righe.dblclick(function(e){
                        var dataItem = griglia.dataItem(e.currentTarget);     
                        self.openedit(dataItem, "Modifica");
                    });

                
                    self.$timeout(function(){
                        self.sostituiscisalva();
                    });
                    
                };
                self.righeOpts = angular.extend({}, gridcfg);
                self.righeRebind = testata.id;
                self.righeds.read()
                self.righeGridMostra = true;
            });
        });
        
        
        
    });
}

function toggleedit($event){
    var self = this;
    console.log($event.ctrlKey, $event.key, $event.currentTarget);
    if($event.ctrlKey && $event.key === 'e'){
        console.log('edit');
        self.openedit(self.righeGrid.dataItem($event.currentTarget), "Modifica")
        $event.preventDefault();
    }
}

function salvariga(){
    var self = this;
    
    if(self.valida.validaForm(self.formriga)){
        var mode = "crea";
        var actualdata = {};
        if(typeof self.modelriga.id !== 'undefined'){
            mode = "modifica";
            actualdata = self.righeGrid.dataItem(self.righeGrid.tbody.find("tr[data-uid='"+self.modelriga.uid+"']"))
            for(var x in self.modelriga){
                actualdata.set(x, self.modelriga[x]);
            }
        }
        else {
            actualdata = self.righeGrid.dataSource.add(self.modelriga);
        }
        self.righe.push(actualdata);
        self.righe = _.uniq(self.righe, function(riga){return riga.uid});
        self.righeeliminate = _.without(self.righeeliminate, _.findWhere(self.righeeliminate, {uid: actualdata.uid}));
        self.righedaeliminare = _.without(self.righedaeliminare, _.findWhere(self.righedaeliminare, {uid: actualdata.uid}));

        var riga = self.righeGrid.tbody.find("tr[data-uid='"+actualdata.uid+"']");

        self.evidenziarighe();

        self.editRigheWin.close();
        return actualdata;
    
    }
    return null;
}

function salvaesuccessivo(){
    var self = this;
    var riga = self.salvariga();
    if(riga !== null){
        self.$timeout(function(){
            var rigah = self.righeGrid.tbody.find("tr[data-uid='"+riga.uid+"']")[0];
            var indice = $(rigah).index();
            var rigasucc = self.righeGrid.tbody.find("tr:eq("+(indice+1)+")");
            if(rigasucc.length === 0){
                self.$timeout(function(){
                    self.openeditnuovo();
                })
                
            }
            else {
                self.selezioneSinc(rigasucc[0]).then(function(){
                    var modello = self.righeGrid.dataItem(rigasucc[0]);
                    self.openedit(modello, "Modifica");
                });                        
            }
        }, 300);
    }
    
    

    //trovare il modello del record successivo
    //self.openedit();
}

function vaisuccessivo(){
    var self = this;
    self.opzioniformriga.resetModel();
    var rigah = self.righeGrid.select();
    self.editRigheWin.close();
    var indice = $(rigah).index();
    var rigasucc = self.righeGrid.tbody.find("tr:eq("+(indice+1)+")");
    if(rigasucc.length){
        self.selezioneSinc(rigasucc[0]).then(function(){
            var modello = self.righeGrid.dataItem(rigasucc[0]);
            self.openedit(modello, "Modifica");
        });  
    }
    else {
        self.$timeout(function(){
            self.openeditnuovo();
        })
    }
}

function vaiprecedente(){
    var self = this;
    self.opzioniformriga.resetModel();
    var rigah = self.righeGrid.select();
    self.editRigheWin.close();
    var indice = $(rigah).index();
    var rigaprec = self.righeGrid.tbody.find("tr:eq("+(indice-1)+")");
    if(rigaprec.length){
        self.selezioneSinc(rigaprec[0]).then(function(){
            var modello = self.righeGrid.dataItem(rigaprec[0]);
            self.openedit(modello, "Modifica");
        });  
    }
    else {
        self.$timeout(function(){
            self.openeditnuovo();
        })
    }
}


function selezioneSinc(selettore){
    var self = this;
    var d = self.$q.defer();
    
    var self = this;
    var selAtt = self.righeGrid.select();
    self.righeGrid.clearSelection();
    
    self.righeGrid.one("change", function(e){
        var selNuova = self.righeGrid.select();
        if(selNuova !== selAtt){
            d.resolve("cambiata selezione");
        }
    });
    
    self.righeGrid.select(selettore);
    
    return d.promise;
}


function eliminasel(){
    var self = this;
    _.each(self.righeGrid.select(), function(riga){
        var modello = self.righeGrid.dataItem(riga);
        //self.righeGrid.dataSource.remove(modello);
        self.righeeliminate.push(modello);
        self.righeeliminate = _.uniq(self.righeeliminate, function(riga){return riga.uid});
        self.righedaeliminare = _.filter(self.righeeliminate, function(riga){ return riga.id !== "" && riga.id !== null;});
        self.righe = _.without(self.righe, _.findWhere(self.righe, {uid: modello.uid}));

        self.evidenziarighe();

        self.confermaDelWin.close();
        self.editRigheWin.close();
    })
    
}

function openeditnuovo(){
    var self = this;
    var obj = {};
    var selezione = self.getSelezione()[0];
    var testtid = (typeof selezione !== 'undefined' && selezione !== null) ? selezione.id : 0;
    obj[self.parametri.campotestata] = {
        id: testtid
    }

    self.openedit(obj, "Inserisci");
}

function evidenziarighe(){
    var self = this;

    _.each(self.righeeliminate, function(riga){
        var rigah = self.righeGrid.tbody.find("tr[data-uid='"+riga.uid+"']");
        rigah.removeClass("nuovorecord modificarecord eliminarecord");
        rigah.addClass("eliminarecord");
    });

    _.each(self.righe, function(riga){
        var rigah = self.righeGrid.tbody.find("tr[data-uid='"+riga.uid+"']");
        rigah.removeClass("nuovorecord modificarecord eliminarecord");
        if(riga.id == "" || riga.id === 0){
            rigah.addClass("nuovorecord");
        } 
        else {
            rigah.addClass("modificarecord");
        }
    })

}

module.exports = MastroDettaglioMixinV2;
