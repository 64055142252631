'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

/**
 * Controller relativo al template della route /wizardlocalita
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} WizardlocalitaFunzioniAggiuntive
 */
function WizardlocalitaCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, $geolocation, ActionFactory, ErrorManager, WizardlocalitaFunzioniAggiuntive, FormDaHeaderFactory, FieldFactory, WizardHandler){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = WizardlocalitaFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.fdhf = FormDaHeaderFactory;
    self.ff = FieldFactory;
    self.wh = WizardHandler;
    self.em = ErrorManager;
    self.$scope = $scope;
    self.$geolocation = $geolocation;
    
    self.mostraRicerca = false;        
        
    self.asyncCfgs  = {
        nazione: {
            tipolocalita: 'Nazioni',
            noInserimento: true,
            entityfiglia: 'regione',
            figli: ['regione', 'provincia', 'comune'],
            canexit: function(){
                return self.nazione.modelloSel.selnazioni !== null && 
                        self.nazione.modelloSel.selnazioni !== undefined;
            }
        },
        regione: {
            tipolocalita: 'Regioni',
            tipopadre: 'Nazioni',
            entitypadre: 'nazione',       
            entityfiglia: 'provincia',
            figli: ['provincia', 'comune'],
            padri: ['nazione'],
            canexit: function(){
                return self.regione.modelloSel.selregioni !== null && 
                        self.regione.modelloSel.selregioni !== undefined;
            },
            header: {
                url: utils.urls.wsbase + "/table/header",
                parametri: {
                    entity: "com.bsssrl.bssstdgestent.TRegioni",
                    codapp: '',
                    codmodulo: 'BWCRegi',
                    strentity: 'tregioni',
                }
            }                        
        },
        provincia: {
            tipolocalita: 'Province',
            tipopadre: 'Regioni',
            entitypadre: 'regione',            
            entityfiglia: 'comune',
            figli: ['comune'],
            padri: ['regione'],
            canexit: function(){
                return self.provincia.modelloSel.selprovince !== null && 
                        self.provincia.modelloSel.selprovince !== undefined;
            },            
            header: {
                url: utils.urls.wsbase + "/table/header",
                parametri: {
                    entity: "com.bsssrl.bssstdgestent.TProvince",
                    codapp: '',
                    codmodulo: 'BWCProv',
                    strentity: 'tprovince',
                }
            }                            
        },
        comune: {
            tipolocalita: 'Comuni',  
            tipopadre: 'Province',
            entitypadre: 'provincia',
            noSelezione: true,
            ultimo: true,
            padri: ['provincia', 'regione', 'nazione'],
            header: {
                url: utils.urls.wsbase + "/table/header",
                parametri: {
                    entity: 'com.bsssrl.bssstdgestent.TComuni',
                    codapp: '',
                    codmodulo: 'BWCComu',
                    strentity: 'tcomuni',
                }
            }                                     
        }
    };
    
    self.$timeout(function(){
        if(bssRecursiveGetter(self, "guidato.auto.campi", null) !== null){
            self.guidato.auto.campi = [self.getCampoGuidato()];
            self.getHeader('nazione');
        }
    });
    
}

WizardlocalitaCtrl.prototype.getHeader = getHeader;
WizardlocalitaCtrl.prototype.getCampoGuidato = getCampoGuidato;
WizardlocalitaCtrl.prototype.getCampoLocalita = getCampoLocalita;
WizardlocalitaCtrl.prototype.avanzaA = avanzaA;
WizardlocalitaCtrl.prototype.resetFigli = resetFigli;
WizardlocalitaCtrl.prototype.resetTutto = resetTutto;
WizardlocalitaCtrl.prototype.salva = salva;

function salva(entity){
    var self = this;
    var tipolocalita = self.asyncCfgs[entity].tipolocalita;
    var padri = self.asyncCfgs[entity].padri;
    
    
    
    var cfgHttp = {
        url: utils.urls.wsbase + "/t" + tipolocalita.toLowerCase() + "/crea",
        method: 'POST',
        
    };
    
    var modello = self[entity].modelloIns;
    
    
    _.map(padri, function(padre){
        modello[padre] = modello[padre].id;
    });
    
//    console.log("modello da salvare: ", modello, "padri", padri);

    
    cfgHttp.data = $.param({
        elemento: JSON.stringify(modello)
    });
    
//    console.log("cfg salva: ", cfgHttp);
     
    
    self.$http(cfgHttp).then(function(resp){
        //setto la selezione       
        if(self.asyncCfgs[entity].noSelezione !== true){
            self[entity].modelloSel["sel"+ tipolocalita.toLowerCase()] = resp.data.id;
            console.log("settata la selezione: ", self[entity].modelloSel);
            
        }
        
        //svuoto il form
        self[entity].opzioniIns.resetModel();
        //svuoto i form figli
        self.resetFigli(entity);
        //attivo la tab di selezione
        //self[entity].tabInsOrSel.select(0);
        self.em.show(entity+ " creata!", "success");
        if(self.asyncCfgs[entity].ultimo !== true){
            self.wh.wizard('wizardlocalita').next();        
        }
        else {
            self.wh.wizard('wizardlocalita').reset();    
            self.resetTutto();
        }
    });

}

function avanzaA(entity){
    var self = this;
    self.getHeader(entity); 
    console.log(self.wh.wizard('wizardlocalita'));
    self.wh.wizard('wizardlocalita').next();
}

function resetFigli(entity){
    var self = this;
    var cfgAttuale = self.asyncCfgs[entity];
    var figli = cfgAttuale.figli;
    _.map(figli, function(figlio){
        if(self[figlio].opzioniSel !== undefined){
            self[figlio].opzioniSel.resetModel();
        }
        if(self[figlio].opzioniIns !== undefined){
            self[figlio].opzioniIns.resetModel();
        }
    });
}

function resetTutto(){
    var self = this;
    _.map(self.asyncCfgs, function(cfg, nomecfg){
        if(self[nomecfg].opzioniSel !== undefined){
            self[nomecfg].opzioniSel.resetModel();
        }
        if(self[nomecfg].opzioniIns !== undefined){
            self[nomecfg].opzioniIns.resetModel();
        }
    });
}


function getCampoGuidato(){
    var self = this;
    
    var campo = new self.ff({
        key: "guidato", 
        tipo: 'bssplaceautocomplete', 
        label: "Localita Guidata",
        colonna: {}, 
        modello: {
            preview: true,
            opzioni: {
                //componentRestrictions: { country: 'au' },
                types: ['geocode'],
                //types: ['regions'] //non vanno regions ne cities
            }            
        }
    }, 
    false, 
    {className: 'col-lg-12'});
    
    //console.log("campo localita: ", campo)
    
    return campo.opzioni;
}

/**
 * @memberOf WizardlocalitaCtrl
 * @param {type} tipolocalita //plurale maiuscolo 'Nazioni', 'Regioni', 'Province', 'Comuni'
 * @returns {Object} opzioni //fieldfactory.opzioni
 */
function getCampoLocalita(entity){
    var self = this;  
    
    var cfgAttuale = self.asyncCfgs[entity];
    var tipolocalita = cfgAttuale.tipolocalita; //plurale prima lettera maiuscola
    var entitypadre = cfgAttuale.entitypadre; //da regione -> nazione da provincia -> regione
    var tipopadre = cfgAttuale.tipopadre; //da regione -> Nazioni da provincia -> Province
    var tabstrip = (self[entity] !== null && self[entity] !== undefined) ? self[entity].tabInsOrSel : null;
    var figli = cfgAttuale.figli;
    
    function gestisciCambio(e){
        var valore = e.sender.dataItem(e.item);
        //self.rs.accessoutentegruppo({idgruppo: valore.id, idmenu: self.menu.modello.id});
        //if(entity !== 'nazione'){
        //console.log("selezionato ", valore, "blocco l'inserimento"); 
        if(valore === null || valore === undefined || valore.id === "" ){
            self[entity].labelStep = null;
            tabstrip.enable(tabstrip.tabGroup.children().eq(1));
        }
        else {
            self[entity].labelStep = valore.label;
            tabstrip.disable(tabstrip.tabGroup.children().eq(1));
        }

        //}          
        //reset model figli
        self.resetFigli(entity);
    }
    
    var modello = {
        validations: {required: true},
        strentity: "t"+tipolocalita.toLowerCase(), 
        codmodulo: 'a', 
        datatextfield: 'label', 
        datavaluefield: 'id', 
//        itemtemplate: '#= label #',
        template: '#= label #',
        groupField: (tipolocalita !== 'Nazioni') ? 'group' : undefined,
        readUrl: utils.urls.wsbase + '/t' + tipolocalita.toLowerCase() + '/multiselectgrid',
        onSelect: gestisciCambio,
        onCascade: gestisciCambio,
        //dataRead: {idPadre: self.idlistino},moduloCtrl.regione.modelloSel
    };
    
    if(entity !== 'nazione'){
                
        modello.dataRead = {};
        modello.dataRead["id"+entitypadre] = 
                self[entitypadre].modelloSel["sel"+tipopadre.toLowerCase()].id;            
    
    }
    
    var campo = new self.ff({
        key: "sel"+tipolocalita.toLowerCase(), 
        tipo: 'bsssingleselect', 
        label: tipolocalita,
        colonna: {}, 
        modello: modello
    }, 
    false, 
    null);
    
    //console.log("campo localita: ", campo)
    
    return campo.opzioni;
}

function getHeader(entity){
    var self = this;
    
//    if(self[entity] === undefined){
//        self[entity] = {
//            selezione: {modello: {}},
//            inserimento: {modello: {}}
//        };
//    }
    
    if(self.asyncCfgs[entity].noSelezione === undefined){
        //console.log("prendo la cfg del campo selezione per la localita: ", self.asyncCfgs[entity].tipolocalita);
        if(![null, undefined].includes(self[entity])){
            self[entity].selezione = {campi: [                        
                self.getCampoLocalita(entity)
            ]}; 
        }
        
    }
    
    
    if(self.asyncCfgs[entity].noInserimento === undefined){
        return self.$http({
            url: self.asyncCfgs[entity].header.url,
            method: "POST",
            data: $.param(self.asyncCfgs[entity].header.parametri)
        }).then(function(resp){
            self["header"+entity] = resp.data;
            
            //metto i campi padre a plaintext e aggiungo al model la label da mostrare
            _.map(self["header"+entity].formmodel.fields, function(campo, nomecampo){
                if(self.asyncCfgs[entity].padri.indexOf(nomecampo) !== -1){
                    campo.type = 'bssplaintext';
                    campo.xtype = 'bssplaintext';
                    
                    var objCampoLabel = angular.copy(campo);
                    
                    self["header"+entity].formmodel.fields["label"+nomecampo] = objCampoLabel;
                    
                }
                return campo;
            });
            
            //nascondo i campi padre e aggiungo il campo della label
            _.map(self["header"+entity].form, function(colonna){
                if(self.asyncCfgs[entity].padri.indexOf(colonna.field) !== -1){                    
                    var colonnaLabel = angular.copy(colonna);
                    colonna.visibleFormInserisci = false;
                    colonnaLabel.field = "label"+colonna.field;
                    self["header"+entity].form.push(colonnaLabel);
                }
                return colonna;
            });
            
            console.log("configurazione model: ", self["header"+entity].formmodel.fields);
            console.log("configurazione campi: " , self["header"+entity].form);
            
            var form = new self.fdhf("", self["header"+entity]);
            //console.log("campi ", entity , form);
            self[entity].inserimento = form;
            //setto il model per gli elementi padre
            _.map(self.asyncCfgs[entity].padri, function(padre){
                //console.log(entity, "prendo il modello padre ", padre, "alla chiave sel", self.asyncCfgs[padre].tipolocalita);
                self[entity].modelloIns[padre] = {id: self[padre].modelloSel['sel'+self.asyncCfgs[padre].tipolocalita.toLowerCase()]};
                self[entity].modelloIns["label"+padre] = self[padre].labelStep;
            });
            //metto i campi padre a readonly
//            self[entity].inserimento.campi = _.map(self[entity].inserimento.campi, function(campo){
//                if(self.asyncCfgs[entity].padri.indexOf(campo.key) !== -1){
//                    console.log("metto a readonly il campo ", campo);
//                    //campo da mettere readOnly
//                    campo.templateOptions.ngReadOnly = "true";
//                }
//                return campo;
//            });
            
            return self["header"+entity];
        });
    }
    
}

module.exports = WizardlocalitaCtrl;
