'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSMultiselectController($scope, $filter, $timeout, $http, ErrorManager, DSF, $rootScope){
    
    $scope.dsf = DSF;
    
    var modello = $scope.options.data.modello;

    $scope.widget = {};

    
    //console.log("modello multiselect: ", modello);
    
//    var eccezione = _.some(["strentity", "codmodulo", "datatextfield", "datavaluefield"], function(el){
//        return (typeof modello[el] === 'undefined');
//    });
//    
//    if(eccezione){
//        //var messaggio = "Il campo '" + $scope.to.label + "' è di tipo combo, il modello dovrebbe avere: 'strentity', 'codmodulo', 'datatextfield', 'datavaluefield'. Vedi console per ulteriori dettagli" 
//        //ErrorManager.show(messaggio, "error");
//        var messaggioConsole = "Configurazione errata: " + JSON.stringify(modello);
//        ErrorManager.showT("FORMBUILDER.COMBOCFGERROR", "error", {campo: $scope.to.label});
//        throw messaggioConsole;
//       
//    }

    $scope.$on("kendoWidgetCreated", function(event, widget){
        // the event is emitted for every widget; if we have multiple
        // widgets in this controller, we need to check that the event
        // is for the one we're interested in.
        if (widget === $scope.widget[$scope.options.key]) {
            console.log(widget);
            $scope.classecss = "multisel-" + $scope.options.key;
            $rootScope.$emit("created-multisel-"+$scope.options.key, widget);
        }
    });
    
    
    $scope.multisel = {
        staticData: modello.staticData,
        strentity: modello.strentity,
        codmodulo: modello.codmodulo,
        codapp: modello.codapp || 'aaa',
        dataTextField: modello.datatextfield,
        dataValueField: modello.datavaluefield,
        itemTemplate: modello.itemtemplate || "#=" + modello.datatextfield + "#",
        footerTemplate: modello.footertemplate || "<div class='bssmultiselectFooterWrapper'><span class='bssmultiselectFooter'>Stai visualizzando <b>#= Math.min(instance.dataSource.data().length, instance.dataSource.pageSize()) #</b> di  <b>#= instance.dataSource.total() #</b> elementi.</span></div>",
        caricamento: true,
        maxSelectedItems: modello.maxSelectedItems || null,
        externalUrl: modello.externalUrl || null,
        externalUrlCfg: modello.externalUrlCfg || null,
        opzioniagg: modello.opzioniagg || {},
        onSelect: modello.onSelect || null,
        onDeselect: modello.onDeselect || null,
        onChange: modello.onChange || null,
        onOpen: modello.onOpen || null,
        groupField: modello.groupField || null,
        readUrl: modello.readUrl || null,
        dataRead: modello.dataRead || null,
        dataReadParameters: modello.dataReadParameters || [],
        modelOverride: modello.modelOverride || null,
        height: 325,
        clearButton: (_.isNull(modello.clearButton) || _.isUndefined(modello.clearButton))  ? true : modello.clearButton,
        //decommentare non appena il webservice /indicedaid sarà attivo
        virtual: modello.virtual || {
            itemHeight: 26,
            //mapValueTo: 'dataItem',
            valueMapper: function(options){
                var parametri = {};
                parametri[modello.datavaluefield] = options.value[modello.datavaluefield];
                $http({
                    url: utils.urls.wsbase + "/" + modello.strentity + "/indicedaid",
                    data: $.param(parametri)
                    //se mapValueTo vale dataItem (di default index)
//                    url: utils.urls.wsbase + "/" + modello.strentity + "/cerca",
//                    data: $.param(parametri),
//                    method: "POST",
                }).then(function(response){
                    console.log("response valuemapper");
                    options.success(response.data); 
                });
            }
        },        

        //se true aggiunge la voce di default 'tutti' NON FUNZIONA
        //voceTutti: modello.voceTutti
    }; 

    var usaDatiStatici = (Array.isArray($scope.multisel.staticData) && $scope.multisel.staticData.length > 0);

    if($scope.multisel.externalUrlCfg){
        $scope.multisel.externalUrl = $state.href($scope.multisel.externalUrlCfg.url,  $scope.multisel.externalUrlCfg.parametri);
    } 
    
    
    if($scope.multisel.groupField){ 
        $scope.multisel.opzioniagg.group = {field: $scope.multisel.groupField};
    }
    
    if($scope.multisel.readUrl){
        $scope.multisel.opzioniagg.transport = {read: { url: $scope.multisel.readUrl, type: 'POST' } };       
        if($scope.multisel.dataRead){
            if(typeof $scope.multisel.dataRead === 'function'){                
                $scope.multisel.opzioniagg.transport.read.data = function(){return $scope.multisel["dataRead"].apply(null, $scope.multisel.dataReadParameters)};
            }
            else {
                $scope.multisel.opzioniagg.transport.read.data = $scope.multisel.dataRead;                       
            }
        }
    }
    
    if($scope.multisel.modelOverride){
        $scope.multisel.opzioniagg.options = {
            schema: {
                model: $scope.multisel.modelOverride 
            } 
        };  
    } 
    
    var opzioni = {
        clearButton: $scope.multisel.clearButton,
        itemTemplate: $scope.multisel.itemTemplate,
        footerTemplate: $scope.multisel.footerTemplate,
        tagTemplate: $scope.multisel.itemTemplate,
        dataTextField: $scope.multisel.dataTextField,
        dataValueField: $scope.multisel.dataValueField,
        externalUrl: $scope.multisel.externalUrl,
        filter: $scope.multisel.filter || "contains",
        autoBind: true,
        //minLength: 0, //per mostrare le opzioni anche senza inserire niente occorre rimuovere minLength, impostandolo a 0 non funziona anche se la documentazione dice che di default vale 0
        maxSelectedItems: $scope.multisel.maxSelectedItems,
        //virtual: $scope.multisel.virtual,
        height: $scope.multisel.height,
        value: $scope.model[$scope.options.key]
    }; 
    
    if(_.isFunction($scope.multisel.onSelect)){
        opzioni.select = function(e){
            $scope.multisel.onSelect(e);
        }
    }

    if(_.isFunction($scope.multisel.onDeselect)){
        opzioni.deselect = function(e){
            $scope.multisel.onDeselect(e);
        }
    }


    if(_.isFunction($scope.multisel.onChange)){
        opzioni.change = function(e){
            $scope.multisel.onChange(e);
        }
    } 

    if(_.isFunction($scope.multisel.onOpen)){
        opzioni.open = function(e){
            $scope.multisel.onOpen(e);
        }
    } 

    if(usaDatiStatici){
        opzioni.dataSource = new kendo.data.DataSource({data: $scope.multisel.staticData});
        $scope.multisel.opzioni = opzioni;
        $scope.multisel.caricamento = false;
        console.log("valore campo", $scope.options.key, $scope.model[$scope.options.key])

    }
    else{
        new DSF($scope.multisel.strentity, $scope.multisel.codapp, $scope.multisel.codmodulo, $scope.multisel.opzioniagg).inizializza().then(function(kendoDS){                
            
            // opzioni.dataSource = kendoDS;  
            // kendoDS.bind("requestEnd", function(e){
            //     if(e.type === "read"){
            //         var res = e.response.results;
            //         var model = JSON.parse(JSON.stringify($scope?.model[$scope.options.key] ?? []))
            //         if(!Array.isArray(model)){
            //             model = [model];
            //         }
            //         model = model.map(function(val){
            //             return res.find(function(item){
            //                 return Number(item[opzioni.dataValueField]) === Number(val)
            //             })
            //         })
            //         $scope.model[$scope.options.key] = model;
            //         console.log("multiselect lettura appena finita", res, model)
    
            //     }
            // })

            console.log("valore campo", $scope.options.key, $scope.model[$scope.options.key])

            opzioni.dataSource = kendoDS;
            //console.log("kendoDS: ", kendoDS);
            $scope.multisel.opzioni = opzioni;
            $scope.multisel.caricamento = false;
        });
    }   

    $scope.apriLinkEsterno = function(){  
        $rootScope.genericwin.content("<iframe src='"+$scope.multisel.externalUrl+"' width='99%' height='99%' />");
        $rootScope.genericwin.open().center();
    }
}

module.exports = BSSMultiselectController;