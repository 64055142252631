'use strict';

/**
 * Controller relativo al template della route annidata di dettaglio /moduli/:idMaster
 * 
 * @returns {undefined}
 */
function DetailbaseCtrl($http, $q, $timeout, $stateParams, DSF, GF, ToolbarS, idstato, $scope){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$q = $q;    
    self.$timeout = $timeout;
    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = $stateParams;
    
    angular.extend(this, ModuloInitializerMixin.prototype);
    
    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza();
    
}


module.exports = DetailbaseCtrl;