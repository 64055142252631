'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo numerico
 */
/**
 * Configurazione del campo di tipo numerico
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSLocalizedString(formlyConfig, formlyValidationMessages, Valida){
    
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bsslocalizedstring',
        //extends: 'bssinput',
        template: require("./bsslocalizedstring.html"),
        controller: ["$scope", "$filter", "$timeout", "$http", "$sce", "ErrorManager", "DSFactory", "FieldFactory", "Valida", require("./BssLocalizedStringController")],        
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bsslocalizedstring'
            },
        },
    }));
}

module.exports = BSSLocalizedString;