'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bssricambiwebFold/tracciatifile/styles/tracciatifile.scss");

var bsstracciatifile = angular.module("bss.bssricambiweb.tracciatifile", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])                                                           
    .service("TracciatifileFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./TracciatifileFunzioniAggiuntive")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/TracciatifileRoutes")])
;

module.exports = bsstracciatifile;
