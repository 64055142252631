'use strict';

/**
 * Definisce le opzioni di base di qualunque campo del form, con le direttive per la navigazione
 * 
 * @class
 * 
 */
function BssBasicField(){
    this.overwriteOk = true;
    this.defaultOptions = {
        className: 'col-lg-6 col-xs-12',
        ngModelAttrs: {
            bssbasic: {
                bound: 'bssbasic',
                attribute: 'bssbasic',
            },
            ngReadonly: {
                bound: 'ng-readonly',
                attribute: 'ng-readonly',
            },
            enterAsTab: {
                bound: 'enter-as-tab',
                attribute: 'enter-as-tab',
            },
            undoTab: {
                bound: 'undo-tab',
                attribute: 'undo-tab',
            }
        },
        templateOptions: {
            bssbasic: true,
            enterAsTab: true,
            ngReadonly: "options.data.readOnly",
            undoTab: true,
            kendoTemplate: kendo.template
            // ngModelOptions: "{debounce: 500}",
        },
        templateManipulators: {
            postWrapper: [
                function(template, options, scope){
                    /**
                     * occorre definire per il campo expressionProperties['templateOptions.skipField']=condizione
                     * vedi bss.menu.MenuIdentity.getCampoUrl
                     */
                    //console.log("templateOptions: ", scope.to);
                    return "<div ng-class='{bssskippedfield: to.skipField}' ng-if='!to.skipField'>" + template + "</div>";                                  
                    //if(!scope.to.skipField) return template;
                }
            ]
        }
    };
}

module.exports = BssBasicField;