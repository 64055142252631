'use strict';

/**
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSCalendarioController($scope, $rootScope, $filter, $timeout, $http, ErrorManager, DSF){
    
    var modello = $scope.options.data.modello;
    
    var formatoperbs = "YYYY-MM-DD";

    $scope.opzioni = angular.extend({}, {
        
        startDate: modello.startDate || moment().toDate(),
        rebind: moment().toDate(),
        min: modello.min || moment().subtract(1, 'year').toDate(),
        max: modello.max || moment().toDate(),
        disabledDates: modello.disabledDates || [],
        navigate: modello.navigate || function(e){
            //calcolare le date da disabilitare
        }
        
    }, modello);
    
    /**
     * listener disabilita calendario
     * parametri: {
     *  chiave: per accertarmi che l'evento riguardi il campo in corso (uguale a $scope.options.key)
     *  anno: quattro cifre 2018
     *  mese: due cifre 01
     *  abilita: array di date che devono rimanere abilitate
     * }
     */
    $rootScope.$on("calendar-disabilita", function(e, parametri){
        if(parametri.chiave === $scope.options.key){
            var anno = parametri.anno;
            var mese = parametri.mese; 
            var giornidelmese = _.range(1, moment(anno+"-"+mese, "YYYY-MM").daysInMonth() + 1, 1);
            var disabilita = [];
            _.each(giornidelmese, function(giorno){
                var lpadgiorno = ("00" + giorno).slice(-2);
                if(parametri.abilita.indexOf(lpadgiorno) === -1){
                    disabilita.push(moment(giorno+"-"+mese+"-"+anno, 'DD-MM-YYYY').toDate())
                }
            });
            $scope.opzioni.disabledDates = disabilita;
            //$scope.opzioni.rebind = disabilita;
        }

    });
}

module.exports = BSSCalendarioController;