const moment = require("moment");

module.exports = {
    defaultRead: function(data, opzioni, opzioniOverride){

        //console.log("===>default read", data, opzioni, opzioniOverride);

        data.datiCfg = opzioni.datiCfg;
        
        if(data.filter){
            data.filter.filters = opzioni.filterMapper(data.filter.filters, opzioni.schema.model.fields);
        }

        if(data.sort){
            data.sort = opzioni.sortMapper(data.sort, opzioni.schema.model.fields);
        }

        if(bssRecursiveGetter(opzioniOverride, "parametriRead", null) !== null){
            data = bsslodash.merge(
                {}, 
                data, 
                (_.isFunction(opzioniOverride.parametriRead) ? opzioniOverride.parametriRead() : opzioniOverride.parametriRead),
            )
        }

        return data;
    },
    /**
     * si assicura che il filtro corrente abbia logic (default and) e l'array filters (di default [])    
     *  
     * @param {*} dataSource 
     */
    normalizeFilter: function(dataSource){

        var currentFilters = dataSource.filter();

        if(currentFilters === null || currentFilters === undefined){
            currentFilters = {};
        }

        if(currentFilters.logic === null || currentFilters.logic === undefined){
            currentFilters.logic = "and";
        }
        
        if(currentFilters.filters === null || currentFilters.filters === undefined || !Array.isArray(currentFilters.filters)){
          currentFilters.filters = [];
        }
        
        return currentFilters;       
    },
    
    enumerateDaysInInterval: enumerateDaysInIntervalFn
    
}

/**
 * Ottiene due oggetti di tipo moment e ritona la lista delle date comprese 
 * 
 * 
 * @param {import("moment").Moment} da 
 * @param {import("moment").Moment} a 
 * @param {import("moment").unitOfTime.DurationConstructor} [intervallo="day"] - indica l'unità di misura dell'intervallo di default è giornaliero
 * @param {import("moment").DurationInputArg1} [dimintervallo=1] - indica l'ammontare di default 1
 * @returns {import("moment").Moment[]} 
 */
function enumerateDaysInIntervalFn(da, a, intervallo = "day", dimintervallo = 1){

    if(a < da){//mi assicuro che la data di fine intervallo sia maggiore della data di inizio intervallo
        var tmp = a.clone();
        a = da.clone();
        da = tmp.clone()
    }

    /**
     * l'intervallo:
     * - inizia all'inizio della prima giornata dell'intervallo (alle 00:00:00)
     * - finisce alle fine dell'ultima giornata (alle 23:59:59)
     */        
    da.startOf("day");
    a.endOf("day");

    var lista = [];

    for(var i = da.clone(); i <= a; i.add(dimintervallo, intervallo)){
        lista.push(i.clone())
    }

    return lista
}