'use strict';

/**
 * Controller del campo localizedstring
 * 
 */
function BssLocalizedStringController($scope, $filter, $timeout, $http, $sce, ErrorManager, DSF, FieldFactory, Valida){                
    
    //console.log("Modello completo: ", $scope.model);
    //console.log("Chiave ", $scope.options.key);
    //console.log("Valore ", $scope.model[$scope.options.key]);


    if(typeof $scope.model[$scope.options.key] === 'undefined'){
        //console.log("Genero struttura, nessun valore definito per ", $scope.options.key);
        $scope.model[$scope.options.key] = {"testo": ""};
    }

    

    /*
    console.log($scope.model[$scope.options.key]);

    if($scope.model[$scope.options.key].testo == null){
        $scope.model[$scope.options.key].testo = '';
    }
    */
}

module.exports = BssLocalizedStringController;