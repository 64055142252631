"use strict";

/**
 * Controlla che il valore sia non null
 * 
 * @class
 * @param {$translate} $translate
 */
function RequiredWithoutFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf RequiredFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */     
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.REQUIREDWITHOUT", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf RequiredFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */     
    function expression(viewValue, modelValue, scope){
        var valore = modelValue || viewValue || null;
        var esitoValore = valore !== null && typeof valore !== 'undefined';
        
        var altriCampi = scope.options.data.modello.validations.required_without;
        
        scope.options.altreLabel = _.without(_.map(scope.options.data.modello.validations.required_without, function(campo){
            if(scope.$parent.$parent.$parent !== null){
                var campiForm = scope.$parent.$parent.$parent.fields;
                var campoForm = _.findWhere(campiForm, {key: campo});
                return bssRecursiveGetter(campiForm, "templateOptions.label", null);
            }
            else return null;            
        }), null);
        
        if(typeof scope.model !== 'undefined'){
            for(var x = 0; x < altriCampi.length; x++){            
                var altroValore = scope.model[altriCampi[x]];
                var esitoAltroValore = (altroValore !== null && typeof altroValore !== 'undefined' && altroValore !== '');            
                if(!esitoAltroValore && !esitoValore) return false;
            }
        }
        
        
        
        
        return true;
    }
        
    return Costruttore;
}

module.exports = RequiredWithoutFct;