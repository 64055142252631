'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/disegnamodelli
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function DisegnamodelliRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "disegnamodelli",
        url: "/disegnamodelli",
        //templateUrl: "bsslib/disegnamodelli/scripts/templates/disegnamodelli.html",
        template: require("../templates/disegnamodelli.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", "$filter", "$sce", "ActionFactory", "ErrorManager", "FieldFactory", "DisegnamodelliFunzioniAggiuntive", require("../DisegnamodelliCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
        
        middleware: ['tutti'], //temporaneo da eliminare
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = DisegnamodelliRoutes;
