'use strict';

require("moduleFold/slidein/styles/bssslidein.scss");

/**
 * 
 * @example
 * <bssslidein lockable='true' locked='false'>
 *  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at ligula commodo, lobortis neque eu, porttitor quam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent tincidunt purus non tincidunt mollis. Donec erat arcu, maximus vel lectus et, finibus pharetra neque. 
 * </bssslidein>
 * 
 */
function BSSSlidein(){
    return {
        restrict: 'E',
        template: require("./templates/bssslidein.html"),

        transclude: true,
        scope: {
            lockable: '=',
            locked: '=',
        },
        link: function(s,e,a){      
            e.find(".bss-slide-in-trigger").bind("click", function(){
                if(s.lockable === true){
                    s.locked = !s.locked;
                    console.log("bloccato", s.locked);
                    s.$apply();
                }
                else {
                    console.warn("non lockable");
                }
            });
            
            s.triggerClass = function(){
                if(s.locked){
                    return "fa fa-lock";
                }
                if(!s.locked){
                    return "fa fa-info faa-slow faa-shake animated";
                }
            }
        }
    };
}

module.exports = BSSSlidein;