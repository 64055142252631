'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione del campo textarea
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSTextarea(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {        
        name: 'bsstextarea',
        template: require("./bsstextarea.html"),
        defaultOptions: {
            className: 'col-xs-12',
            ngModelElAttrs: {
                'cssCategory': 'bsstextarea'
            },
        }
    }));
};

module.exports = BSSTextarea;