'use strict';

/**
 * EnterAsTab - direttiva simula tab alla pressione di enter (ATTENZIONE: non va con bssfile, bssselect, bssboolcheckbox, bssdatetime)
 *
 * @class 
 * @returns {angularDirective} direttiva 
 *
 */
function EnterAsTabDir(){
    return{
        link: function (scope, element, attrs) {
            //console.log("abilito tab on enter", attrs, element);
            element.bind("keydown keypress", function (event) {
                //console.log("pressione tasto");
                if(attrs.enterAsTab == "true" && event.which === 13) {
                    var elscope = angular.element(element).scope();
                    console.log("tasto premuto invio su", event.target.nodeName);
                    if(["TEXTAREA"].indexOf(event.target.nodeName) === -1){
                        event.preventDefault();
                    }                    
                    var elformcontrol = scope.options ? scope.options.formControl[0] : {$untouched: false, $touched: true, $valid: true};
                    elformcontrol.$untouched = false;
                    elformcontrol.$touched = true;
                    elscope.$apply();
                    if(elformcontrol.$valid){
                        var listacampi = angular.element("[enter-as-tab=true]");
                        //console.log(listacampi);
                        var nextIndex = (listacampi.index(element) + 1) % listacampi.size();
                        var elementToFocus = listacampi.eq(nextIndex);
                        //console.log(elementToFocus);

                        if(angular.isDefined(elementToFocus)){
                            elementToFocus.focus().select();
                        }
                    }
                }
            });
        }
    }
}

module.exports = EnterAsTabDir;