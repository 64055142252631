'use strict';

/**
 * Factory che ritorna un menu, tramite la property rs può accedere a tutti i webservice relativi all'endpoint menumanager.
 * Dispone dei metodi per la generazione della treeview kendo.
 * 
 * @class
 * @param {MenuManagerRS} MenuManagerRS
 * @returns {MenuIdentity}
 */
function MenuIdentity(MenuManagerRS, ModulemanagerRS, MenuAccessManagerRS, FieldFactory, $q, $timeout){
    
    /**
     * 
     * @memberOf(MenuIdentity)
     * @constructor
     * @param {type} codice
     * @param {type} descr
     * @param {type} pos
     * @param {string} tipo //es. Area, Link, SPALink
     * @param {type} parent
     * @param {type} url
     * @returns {MenuIdentity.Costruttore}
     */
    function Costruttore(codice, descr, pos, tipo, parent, url, modulo){
        this.modello = {
            name: codice,
            descrizione: descr,
            ordine: pos,
            tipomenu: tipo,
            parentMenu: parent,
            url: url,        
            modulo: modulo
        };
        
        this.ff = FieldFactory;
        this.rs = MenuManagerRS;
        this.mmrs = ModulemanagerRS;
        this.mamrs = MenuAccessManagerRS;
        
        this.alberoTpl = kendo.template("<i class='#: item.spriteCssClass #'></i> #: item.text #");

        var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
        angular.extend(this, EsponiMixin.prototype);
    }
    
    Costruttore.prototype.costruisciDaEsistente = costruisciDaEsistente;
    Costruttore.prototype.generaFormAree = generaFormAree;
    Costruttore.prototype.generaFormInserimento = generaFormInserimento;
    Costruttore.prototype.generaFormModifica = generaFormModifica;
    Costruttore.prototype.generaFormModificaArea = generaFormModificaArea;
    Costruttore.prototype.generaFormVisibilitaGruppi = generaFormVisibilitaGruppi;
    Costruttore.prototype.generaFormVisibilitaUtenti = generaFormVisibilitaUtenti;
    Costruttore.prototype.getCampoName = getCampoName;
    Costruttore.prototype.getCampoIcona = getCampoIcona;
    Costruttore.prototype.getCampoDescrizione = getCampoDescrizione;
    Costruttore.prototype.getCampoOrdine = getCampoOrdine;
    Costruttore.prototype.getCampoTipomenu = getCampoTipomenu;
    Costruttore.prototype.getCampoParentMenu = getCampoParentMenu;
    Costruttore.prototype.getCampoUrl = getCampoUrl;
    Costruttore.prototype.getCampoModulo = getCampoModulo;
    Costruttore.prototype.getCampoAree = getCampoAree;
    Costruttore.prototype.getCampoGruppi = getCampoGruppi;
    Costruttore.prototype.getCampoUtenti = getCampoUtenti;
    Costruttore.prototype.ricaricaFormModifica = ricaricaFormModifica;
    
    /**
     * Configurazione del campo form Area.
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoAree(){
        var self = this;
        return self.rs.tuttearee().then(function(resp){                       
                        
            return new self.ff({
                    key: 'area', 
                    tipo: 'bssselect', 
                    label: 'Seleziona Area', 
                    colonna: {values: resp, fetchAsObject: true}, 
                    modello: {}
                }, 
                false, 
                {
                    ngModelElAttrs: {
                        'on-select': 'onSelect($item, $model)'
                    },
                    className: 'col-xs-12',
                    templateOptions: {
                        labelProp: 'descrizione',
                        valueProp: 'id',
                    },
                    controller: ["$scope", "MenuManagerRS", function($scope, rs){
                        $scope.rs = rs;
                        $scope.onSelect = function(area, model){                            
                            if(area !== undefined){                                
                                return $scope.rs.menuarea({idarea: area.id}).then(function(menuarea){
                                    console.log("menu radicato in un area: ", menuarea);
                                    var hds = new kendo.data.HierarchicalDataSource({ 
                                        data: menuarea, 
                                        //dataSpriteCssClassField: "campochenonesiste",
                                        //dataImageUrlField: "campochenonesiste",
                                    });
                                    console.log("menu radicato in un area trasformato da kendo: ", hds);
                                    //ottengo il menu ad albero
                                    $scope.rs.lastmenuarea = hds;
                                    //setto il model del form di modifica
                                    self.ricaricaFormModifica({id: area.id});
                                    return hds;
                                });
                            }
                            else {
                                console.log("deseleziono!");
                                self.modello = null;
                                self.campi = [];
                                self.modalita = null;
                                $scope.rs.lastmenuarea = null; 
                                self.formObj = null;                              
                            }
                            
                        }
                        
                                
                    }],
                }).opzioni;
                
        }, function(resp){
            throw resp;
        });
        
    }
    
    /**
     * Configurazione del campo form Tipo. ToDo ottenere i values da webservice.
     * 
     * @memberOf MenuIdentity
     * @returns {promise} La promise è risolta con un un formly-field
     */
    function getCampoTipomenu(valori){
        return new this.ff({
                key: 'tipomenu', 
                tipo: 'bssselect', 
                label: 'Tipo', 
                colonna: {values: valori}, 
                modello: {validations: {required: true}}
            }, 
            false, 
            {className: 'col-xs-12'}).opzioni;
    }
    
    /**
     * Configurazione del campo form Codice
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoName(){
        return new this.ff({
                key: 'name', 
                tipo: 'bssinput', 
                label: 'Codice', 
                colonna: {}, 
                modello: {validations: {required: true}}}, 
                false, 
                {className: 'col-xs-12'}).opzioni;
    }
    
    /**
     * Configurazione del campo form Icona
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoIcona(){
        return new this.ff({
                key: 'icona', 
                tipo: 'bssiconpicker', 
                label: 'Icona', 
                colonna: {}, 
                modello: {validations: {required: true}}}, 
                false, 
                {
                    className: 'col-xs-12',
                    defaultValue: 'fas fa-cogs',
                }
            ).opzioni;
    }
    
    /**
     * Configurazione del campo form Descr
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoDescrizione(){
        return new this.ff({
                key: 'descrizione', 
                tipo: 'bssinput', 
                label: 'Descrizione', 
                colonna: {}, 
                modello: {validations: {required: true}}}, 
                false, 
                {className: 'col-xs-12'}).opzioni;
    }
    
    
    /**
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoGruppi(valori){
        var self = this;  
    
        return new this.ff({
                key: 'gruppi', 
                tipo: 'bssmultiselect', 
                colonna: {}, 
                modello: {
                    validations: {},
                    strentity: 'groupmanager', 
                    codmodulo: 'a', 
                    datatextfield: 'name', 
                    datavaluefield: 'id', 
                    onSelect: function(e){
                        var valore = e.sender.dataItem(e.item);
                        //self.rs.accessoutentegruppo({idgruppo: valore.id, idmenu: self.menu.modello.id});
                        console.log("associo gruppo ", valore, " a elemento: ", self.menu.modello.id);                  
                    },
                    onDeselect: function(e){
                        var valore = e.sender.dataItem(e.item);
                        console.log("disassocio elemento: ", valore);                  
                    }
                }
            }, 
            false, 
            {className: 'col-xs-10'}).opzioni;
    }
    
    
    /**
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoUtenti(valori){
        var self = this;  
    
        return new this.ff({
                key: 'utenti', 
                tipo: 'bssmultiselect', 
                colonna: {}, 
                modello: {
                    validations: {},
                    strentity: 'usermanager', 
                    codmodulo: 'a', 
                    datatextfield: 'name', 
                    datavaluefield: 'id', 
                    onSelect: function(e){
                        var valore = e.sender.dataItem(e.item);
                        //self.rs.accessoutentegruppo({idgruppo: valore.id, idmenu: self.menu.modello.id});
                        console.log("associo utente ", valore, " a elemento: ", self.menu.modello.id);             
                    },
                    onDeselect: function(e){
                        var valore = e.sender.dataItem(e.item);
                        console.log("disassocio elemento: ", valore);                  
                    }
                }
            }, 
            false, 
            {className: 'col-xs-10'}).opzioni;
    }
    
    
    /**
     * @memberOf MenuIdentity
     */
    function generaFormVisibilitaGruppi(idmenu){
        var self = this;
        //dopo aver ottenuto tutti i gruppi associati genero il multiselect
        return $q.all([self.mamrs.gruppimenu({idmenu: idmenu})]).then(function(risposte){
            console.log("visibilita gruppi: ", risposte[0].data);
            self.formGruppi = {
                modello: risposte[0].data,
                campi: [self.getCampoGruppi()]
            }
        });
    }
    
    /**
     * @memberOf MenuIdentity
     */
    function generaFormVisibilitaUtenti(idmenu){
        var self = this;
        //dopo aver ottenuto tutti gli utenti associati genero il multiselect
        return $q.all([self.mamrs.utentimenu({idmenu: idmenu})]).then(function(risposte){
            console.log("visibilita utenti: ", risposte[0].data);
            self.formUtenti = {
                modello: risposte[0].data,
                campi: [self.getCampoUtenti()]
            }
        });
    }
    
    
    /**
     * Configurazione del campo form Parent, nascosto se il tipo è 'Area'. ToDo ottenere values da webservice.
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoParentMenu(valori){
        var self = this;
        return new this.ff({
                key: 'parentMenu', 
                tipo: 'bssselect', 
                label: 'Padre', 
                colonna: {values: valori, fetchAsObject: true}, 
                modello: {validations: {required: true}}
            }, 
            false, 
            {   
                className: 'col-xs-12', 
                templateOptions: {
                    labelProp: 'descrizione',
                    valueProp: 'id'
                },
                expressionProperties: {
                    'templateOptions.skipField': '["Area"].indexOf(model.tipomenu.value) !== -1'
                }
            }
        ).opzioni;
    }
    
    /**
     * Configurazione del campo form Modulo, nascosto se il tipo è 'Area'. ToDo ottenere values da webservice.
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoModulo(valori){
        var self = this;
        return new this.ff({
                key: 'modulo', 
                tipo: 'bssselect', 
                label: 'Modulo', 
                colonna: {values: valori, fetchAsObject: true}, 
                modello: {validations: {required: true}}
            }, 
            false, 
            {   
                className: 'col-xs-12', 
                templateOptions: {
                    labelProp: 'modulo',
                    valueProp: 'id',
                },
                expressionProperties: {
                    'templateOptions.skipField': '["LinkModulo"].indexOf(model.tipomenu.value) === -1'
                }
            }
        ).opzioni;
    }
    
    
    /**
     * Configurazione del campo form Url, nascosto se il tipo non è 'Link' o 'SPALink'
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoUrl(){
        return new this.ff({
                key: 'url', 
                tipo: 'bssinput', 
                label: 'Url', 
                colonna: {}, 
                modello: {validations: {required: true}}}, 
                false, 
                {
                    className: 'col-xs-12', 
                    expressionProperties: {
                        'templateOptions.skipField': '["Menu", "Area", "LinkModulo"].indexOf(model.tipomenu.value) !== -1'
                    }
                }
            ).opzioni;
    }
    
    /**
     * Configurazione del campo form Pos (posizione)
     * 
     * @memberOf MenuIdentity
     * @returns {formly-field}
     */
    function getCampoOrdine(){
        return new this.ff(
                {key: 'ordine', 
                tipo: 'bssinput', 
                label: 'Posizione', 
                colonna: {}, 
                modello: {validations: {required: true}}}, 
                false, 
                {className: 'col-xs-12'}).opzioni;
    }
    
    /**
     * Genera il form di selezione area
     * 
     * @memberOf MenuIdentity
     * @returns {promise}
     */
    function generaFormAree(){
        return this.getCampoAree().then(function(selettore){
            return [selettore];
        });
    }
    
    /**
     * Genera il form di inserimento
     * 
     * @memberOf MenuIdentity
     * @returns {Object}
     */
    function generaFormInserimento(){
        var self = this;
        
        var paramParent = null;
        if(self.modello.parentMenu){
            paramParent = {idarea: self.modello.parentMenu};
        }
        else {
            if(self.modello.id){
                paramParent = {idarea: self.modello.id};

            }
            else {
                paramParent = {idarea: self.paramParent.id};
            }
        }

        return $q.all([self.rs.tipimenu(), self.rs.parent(paramParent), self.mmrs.cercatutti()]).then(function(risposte){
            self.modalita = "Inserimento";
            self.campi = [
                self.getCampoTipomenu(risposte[0]),
                self.getCampoName(),
                self.getCampoDescrizione(),
                self.getCampoParentMenu(risposte[1]),
                self.getCampoOrdine(),
                self.getCampoUrl(),
                self.getCampoIcona(),
                self.getCampoModulo(risposte[2])
            ]; 
            return self.campi;
        });         
    }
    
    /**
     * Genera il form di modifica
     * 
     * @memberOf MenuIdentity
     * @returns {Object}
     */
    function generaFormModifica(){
        var self = this;
        
        return self.generaFormInserimento().then(function(campi){
            self.modalita = "Modifica";
            self.campi = _.map(self.campi, function(el){//metti in sola lettura alcuni campi
                if(['tipomenu', 'name'].indexOf(el.key) !== -1){
                    el.data.readOnly = true;
                }
                return el;
            });
            return self.campi;
        });
    }
 
    /**
     * Genera il form di modifica per le aree
     * 
     * @memberOf MenuIdentity
     * @returns {Object}
     */
    function generaFormModificaArea(){
        var self = this;
        
        return self.rs.tipimenu().then(function(tipi){
            self.modalita = "Modifica";
            self.campi = [
                self.getCampoTipomenu(tipi),
                self.getCampoName(),
                self.getCampoDescrizione(),                
                self.getCampoOrdine(),
                //self.getCampoUrl(),
            ]; 
            
            self.campi = _.map(self.campi, function(el){
                if(['tipomenu', 'name'].indexOf(el.key) !== -1){
                    el.data.readOnly = true;
                }
                return el;
            });
            return self.campi;
        });    
    }
    
    /**
     * Da un id di kendoTreeNode ottiene il form di modifica e relativo model
     * 
     * @memberOf MenuIdentity
     * @param {type} parametri {id: idKendoTreeNode}
     * 
     */
    function ricaricaFormModifica(parametri){
        var self = this;
        self.paramParent = parametri;
        self.modello = null;
        self.campi = [];
        self.modalita = null;
        
        self.rs.cerca(parametri).then(function(formModel){
            self.costruisciDaEsistente(formModel.data).then(function(modello){
                self.generaFormModifica();
                //self.generaFormVisibilitaGruppi(parametri.id);
                //self.generaFormVisibilitaUtenti(parametri.id);
            });                        
        });
    }
    
    
    /**
     * Per generare un menuidentity da un oggetto preesistente (modifica)
     * 
     * @memberOf MenuIdentity
     * @param {MenuIdentity} oggetto
     * @returns {promise} modello modello relativo al form di modifica
     */
    function costruisciDaEsistente(oggetto){
        var self = this;
        if(oggetto.parentMenu){
            oggetto.parentMenu = oggetto.parentMenu.id;
        }        
        if(oggetto.tipomenu === 'LinkModulo'){
            return self.mmrs.cercadamenu({idmenu: oggetto.id}).then(function(resp){
                var res = resp.data;
                if(res.length){
                    oggetto.modulo = res[0];
                }
                self.modello = angular.extend({}, oggetto);
                return self.modello;
            });
        }
        else {
            self.modello = angular.extend({}, oggetto);
            return $q.when([self.modello]);
        }
    }
    
    return Costruttore;
}

module.exports = MenuIdentity;