'use strict';

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}


function backspace () {
    $(document).keydown(function(e){
        
        
        if (!inIframe() && e.which === 8){
            var isContentEditable = e.target.attributes.getNamedItem("contenteditable");
            if(["INPUT", "TEXTAREA", "SELECT"].indexOf(e.target.nodeName) === -1 && isContentEditable === null) { // you can add others here.
                e.preventDefault();   
            }
        }
    });   
    
    /*
    window.onbeforeunload=function(e){
        if(!inIframe() && !ignorebeforeunload){
            return 'Sicuro di voler procedere? Assicurati di aver salvato eventuali modifiche pendenti prima di procedere.';
        } else {
            ignorebeforeunload = false;
            e.preventDefault();   
            return false;
        }
        
    };*/
}

backspace();

module.exports = backspace;