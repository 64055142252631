'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione del campo editor di json
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSJsonEditor(formlyConfig, formlyValidationMessages, Valida){
    
    function parseJson(v){
        return JSON.stringify(v);
    }
    
    function formatJson(v){
        return JSON.parse(v);
    }
    
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {        
        name: 'bssjsoneditor',
        template: require("./bssjsoneditor.html"),
        defaultOptions: {
            defaultValue: '',
            className: 'col-xs-12',
            ngModelElAttrs: {
                'cssCategory': 'bssjsoneditor'
            },
            parsers: [parseJson],
            formatters: [formatJson],
        }
    }));
};

module.exports = BSSJsonEditor;