'use strict';

require("../styles/storicoxml.scss");
var path = require("path");

/**
 * 
 * @example
 * <storicoxml iddoc='1' /> 
 * 
 */
function StoricoXML(){
    return {
        restrict: 'E',
        template: require("./templates/storicoxml.html"),
        scope: {
            iddoc: '=',
            ricarica: '='
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", "FieldFactory", "Valida", "$rootScope", function(s, $http, $q, $timeout, ErrorManager, FieldFactory, Valida, $rootScope){      
            var self = this;
            self.ff = FieldFactory;
            self.valida = Valida;
            self.$http = $http;
            self.em = ErrorManager;
            self.rs = $rootScope;
            self.$timeout = $timeout;

            self.toAgree = [
                "il documento non sia stato già trasmesso"
            ]

            s.$watch(function(){
                return s.iddoc
            }, function(n, o){
                //console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.iddoc = s.iddoc;   
                    
                    self.inizializza().then(function(resp){
                        self.caricamento = false;
                    })
                }
            })  
            
            
            self.inizializza = function(){
                console.log("inizializzo preview articolo", s)
                self.iddoc = s.iddoc;
                self.loading = true;
                return $http({
                    method: "GET",
                    url: utils.urls.wsbase + "/documentistd/" + self.iddoc,
                }).then(function(resp){
                    self.documento = resp.data;  
                    self.loading = false;                 
                    self.mostraStoricoXML();                 
                })
            }


            self.mostraStoricoXML = function(){
                var self = this;
                
            
                self.$http({
                    url: utils.urls.wsbase + "/trasmissionife/" + self.documento.id,
                    method: "POST",
                }).then(function(resp){
                
                    self.storicoXML = resp.data;
                }, function(err){
                    self.em.errorCB(err);
                })
            }
            
            /**
             * serve a scaricare l'xml
             * 
             */
            self.scaricaDocumento = function(trasmissione){
                var self = this;
                self.$http({
                    url: utils.urls.wsbase + "/trasmissionife/download/"+trasmissione.id,
                    method: "POST",
                    headers: {type: 'application/octet-stream'},
                    responseType: 'arraybuffer'
                }).then(function(resp){
                    if(resp.data.byteLength !== 0){
                        var file = new Blob([resp.data], { type: 'application/xml' });
                        saveAs(file, path.basename(trasmissione.nomefile));
                    }
                }, function(err){
                    self.em.errorCB(err);
                })
            }
              
            self.rigeneraXML = function(){
                var self = this;
                self.$http({
                    url: utils.urls.wsbase + "/documentirdc/rigeneraxml/" + self.documento.id,
                    method: "POST",
                    headers: {type: 'application/octet-stream'},
                    responseType: 'arraybuffer'
                }).then(function(resp){
                    if(s.ricarica){
                        self.$timeout(function(){
                            location.reload();
                        })                        
                    } else {
                        if(resp.data.byteLength !== 0){
                            var file = new Blob([resp.data], { type: 'application/xml' });
                            saveAs(file, moment().format("DDMMYYYYHHmmss")+'.xml');
                        }
                        self.mostraStoricoXML();
                    }
                }, function(err){
                    self.em.errorCB(err);
                })
            }

            self.creapdfxmlNC = function(){
                var self = this;
                self.$http({
                    url: utils.urls.wsbase + "/documentirdc/creapdfxmlNC",
                    method: "POST",
                    headers: {type: 'application/octet-stream'},
                    responseType: 'arraybuffer',
                    data: $.param({
                        iddocumenti: JSON.stringify([self.documento.id])
                    })
                }).then(function(resp){                    
                    
                    if(s.ricarica){
                        self.$timeout(function(){
                            location.reload();
                        })                        
                    } else {
                        if(resp.data.byteLength !== 0){
                            var file = new Blob([resp.data], { type: 'application/xml' });
                            saveAs(file, moment().format("DDMMYYYYHHmmss")+'.xml');
                        }
    
                        self.mostraStoricoXML();
    
                    }

                }, function(err){
                    self.em.errorCB(err);
                })
            }

            self.inErroreXML = function(file){
                var self = this;
                self.$http({
                    url: utils.urls.wsbase + "/trasmissionife/eliminaxml/" + file.id,
                    method: "POST",
                    
                }).then(function(resp){   
                    if(s.ricarica){
                        self.$timeout(function(){
                            location.reload();
                        })                        
                    } else {                 
                        self.confeliminawin.close();
                        self.mostraStoricoXML();
                        self.em.show(resp.data.message, "success")
                    }
                }, function(err){
                    self.em.errorCB(err);
                })
            }

            self.inizializzaDelagree = function(){
                self.delagree = Array.from({length: self.toAgree.length}, function(v, i){
                    return false
                })
            }

            self.confermaEliminaDocumento = function(file){
                self.daCancellare = file;
                self.inizializzaDelagree();
                self.confeliminawin.open().center();
            }

            
            /**
             * il tasto per la cancellazione deve rimanere disabilitato finchè anche solo una condizione non è stata accettata
             * le condizioni si definiscono all'interno di self.toAgree per ogni condizione l'utente vedrà una checbox
             * per accettare le condizioni andranno spuntate le rispettive checkbox
             */
            self.disabilitaProcediCancellazioneXML = function(){
                //console.log("controllo se disabilitare======>", self.delagree);

                var esitocondnonaccettate = true;

                if(self.delagree !== null && self.delagree !== undefined){
                    var condizioninonaccettate = self.delagree.find(function(esito){
                        return !esito
                    })
    
                    esitocondnonaccettate = (condizioninonaccettate !== undefined);
    
                }

                return esitocondnonaccettate;
                
            }

        }],
        controllerAs: "storicoxmlCtrl"
    };
}

module.exports = StoricoXML;