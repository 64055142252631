'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Fornisce il campo per l'inserimento di data e ora
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSDateTime(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssdatetime',
        extends: 'bssinput',
        template:  require("./bssdatetime.html"),
        defaultOptions:{
            //defaultValue: moment("1970-01-01 00:00:00.000", "YYYY-MM-DD HH:mm:ss.sss").toDate(),
            ngModelElAttrs: {
                'datetime': 'dd-MM-yyyy HH:mm:ss',
                //'datetime-model': "yyyy-MM-dd HH:mm:ss.sss",
                'cssCategory': 'bssdatetime',
            },
            validators: {
                syncIsDateTime: Valida.isDateTime,
            },
            expressionProperties: {
                "templateOptions.placeholder": "' gg-mm-aaaa hh:mm:ss'"
            },
            /*controller: ["$scope", function($scope){  
                if($scope.model[$scope.options.key] === null || $scope.model[$scope.options.key] === "" || typeof $scope.model[$scope.options.key] === "undefined" ){//nel caso in cui la data è null il campo diventava ingestibile
                    $scope.model[$scope.options.key] = moment().toDate();
                }
            }],*/
        },
    }));
}

module.exports = BSSDateTime;