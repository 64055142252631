'use strict';

/**
 * Registrazione delle route per il modulo bss/modulemanager
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 * @returns {undefined}
 */

function Routes($stateProvider, $urlRouterProvider, $locationProvider){

}

module.exports = Routes;
