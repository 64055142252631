'use strict';



/**
 * Controller del campo colorpicker
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSColorpickerController($scope, $rootScope, $filter, $timeout, $http, $state, ErrorManager, DSF){
    $scope.opzioni = {
        // swatch
        swatch: true,
        swatchPos: 'right',
        swatchBootstrap: true,
        // popup
        round: true,
        pos: 'bottom left',
        // show/hide
        show: {
            swatch: true,
            focus: true,
        },
    };
    
}

module.exports = BSSColorpickerController;