"use strict";

/**
 * Classe che permette di fare confronti, utile per implementare 
 * strategy pattern. Creata come mixin per essere specializzata
 * assieme ad altre classi (ereditarietà multipla).
 * 
 * @class 
 * 
 * @example
 * angular.extend(this, ConfrontaMixin.prototype, AltroMixin.prototype);
 * this.eq(5,6); //ritorna false
 * //per utilizzarla ovunque
 * var cmInstantiator = require("moduleFold/mixins/ConfrontaMixin");
 * var cm = new cmInstantiator();
 * cm.eq(5,6); //ritorna false
 * 
 * 
 */
function ConfrontaMixin(){}

ConfrontaMixin.prototype.nou = nou;
ConfrontaMixin.prototype.nnou = nnou;
ConfrontaMixin.prototype.eq = eq;
ConfrontaMixin.prototype.ne = ne;
ConfrontaMixin.prototype.lt = lt;
ConfrontaMixin.prototype.le = le;
ConfrontaMixin.prototype.gt = gt;
ConfrontaMixin.prototype.ge = ge;
ConfrontaMixin.prototype.dimSelezioneConfronta = dimSelezioneConfronta;
ConfrontaMixin.prototype.dimSelezioneInMode = dimSelezioneInMode;
ConfrontaMixin.prototype.detectModeModifica = detectModeModifica;
ConfrontaMixin.prototype.dimSelezioneInMultipleMode = dimSelezioneInMultipleMode;

/**
 * @description
 * Ritorna true se valore è null o undefined
 * @param {*} valore 
 * @returns {boolean}
 */
function nou(valore){
    return typeof valore === 'undefined' || valore === null;
}

/**
 * @description
 * true se obj[vsd] è undefined o null false altrimenti
 * @param {string} vsd - parametri annidati separati da "." es. fattura.anagrafica.id
 * @param {object} obj - oggetto per cui verificare il parametro annidato
 * @return {bool} 
 */
function nnou(vds, obj){
    var self = this;
    var parti = vds.split(".");
    var curr = parti[0];
    var parts = parti.slice(1)
    var esito = self.nou(obj[curr]);
    return esito ? esito : parts.length ? self.nnou(parts.join("."), obj[curr]) : false
}

function dimSelezioneInMode(criterio, valore, mode){
    var self = this;
    return self.ts.mode !== mode || self.dimSelezioneConfronta(criterio, valore);

}

function dimSelezioneInMultipleMode(criterio, valore, listaMode){
    var self = this;
    return listaMode.indexOf(self.ts.mode) == -1 || self.dimSelezioneConfronta(criterio, valore);
}

function detectModeModifica(){
    var self = this;
    return self.ts.selezione[0].id  && self.ts.mode === 'form';
}

/**
 * Confronta la dimensione della selezione della griglia con un valore
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string} criterio un'altro dei metodi di confronto
 * @param {number} valore
 * @returns {Boolean}
 */
function dimSelezioneConfronta(criterio, valore){
    var self = this;
    
    var dimsel = 0;
    
    
    
    if(self.ts !== undefined){
        
        var sel = _.uniq(self.ts.griglia.select(), function(tr){ 
            //console.log("selezione", angular.element(tr).attr("data-uid"));
            return angular.element(tr).attr("data-uid"); 
        });
        
        var dimsel = sel.length;	
    }
        
    var esito = self[criterio](dimsel, valore);
    
    
    return esito;
}



/**
 * Confronto === 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function eq(op1, op2){
    return op1 === op2;
}

/**
 * Confronto !== 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function ne(op1, op2){    
    //console.log("confronta non uguali selezione", op1, "da valore di riferimento", op2);
    return op1 !== op2;
}

/**
 * Confronto < 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function lt(op1, op2){
    return op1 < op2;
}

/**
 * Confronto <= 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function le(op1, op2){
    return op1 <= op2;
}

/**
 * Confronto > 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function gt(op1, op2){
    return op1 > op2;
}

/**
 * Confronto >= 
 * 
 * @memberOf ConfrontaMixin.prototype
 * @param {string|number|object} op1
 * @param {string|number|object} op2
 * @returns {Boolean}
 */
function ge(op1, op2){
    return op1 >= op2;
}


module.exports = ConfrontaMixin;