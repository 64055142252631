'use strict';

/**
 * Direttiva che legge il contenuto di un file da un campo di tipo file
 * 
 * @class
 * @returns {angularDirective}
 */
function FileSyncReadDir($timeout){
    return {
        require: "ngModel",
        link: function (scope, element, attributes, ngModel) {
            element.bind("change", function (changeEvent) {                
                var files = element[0].files;                    
                ngModel.$setViewValue(files); 
            });
        }
    }
}

module.exports = FileSyncReadDir;