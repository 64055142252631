'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/statistiche
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function StatisticheRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "statistiche",
        url: "/statistiche",
        //templateUrl: "ricambiweb/statistiche/scripts/templates/statistiche.html",
        template: require("../templates/statistiche.html"),
        controller: ["$http", "$q", "$timeout", "$interval", "$state", "$stateParams", "DSFactory", "GridFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "ErrorManager", "StatisticheFunzioniAggiuntive", require("../StatisticheCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = StatisticheRoutes;
