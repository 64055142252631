'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo di tipo input:text
 * 
 * @class
 * 
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSPlaceautocomplete(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssplaceautocomplete',
        template: require("./bssplaceautocomplete.html"),
        controller: ["$scope", "$filter", "$timeout", "$http", "ErrorManager", "$sce", "$geolocation", require("./BssPlaceautocompleteController")],
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bssplaceautocomplete'
            },
        }
    }));
};


module.exports = BSSPlaceautocomplete;