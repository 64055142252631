'use strict';

/**
 * Wrapper che raggruppa i campi
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * 
 */
function BssTabWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "BssTabWrapper",
        template: require("./templates/bsstab.html"),
    })
}

module.exports = BssTabWrapper;