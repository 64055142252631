'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Fornisce il campo per l'inserimento di data
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {type} Valida
 */
function BSSDate(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssdate',
        extends: 'bssdatetime',
        defaultOptions:{      
            ngModelElAttrs: {
                'datetime': 'dd-MM-yyyy',
                //'datetime-model': "yyyy-MM-dd",
                'cssCategory': 'bssdate',  
            },
            expressionProperties: {
                "templateOptions.placeholder": "' gg-mm-aaaa'"
            }
        },
    }));
}

module.exports = BSSDate;