'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/menu/scripts/index.js");

var bssfileutilities = angular.module("bss.fileutilities", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar", "bss.menu"])
        .directive("displayFile", [require("./DisplayFileDir")])
//    .factory("GrigliaModuliPermessi", ["$q", "$timeout", "$stateParams", "DSCfgFactory", "GridCfgFactory", "ToolbarSrv", require("./GrigliaModuliPermessi")])
//    .service("PermessiModuliManager", ["$http", "$q", require("./PermessiModuliManager")])

;

module.exports = bssfileutilities;
