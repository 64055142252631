'use strict';

var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var StampaDettaglioMixin = require("moduleFold/mixins/StampaDettaglioMixin");
var path = require("path");

const moment = require("moment");

/**
 * @description
 * Controller relativo al template della route /ricercadoc/:raggrdoc?codprocedura
 * Ricercadoc permette di cercare tra i documenti con un form per filtrarli. A monte i documenti sono filtrati per :raggrdoc (es. ORDCLI).
 * Il segmento :raggrdoc è obbligatorio.
 * La toolbar permette le operazioni di CRUD e stampa sulla selezione.
 * 
 * Opzionalmente si può passare all'url in querystring codprocedura. Dal codice della procedura sarà possibile ottenere l'oggetto procedura.
 * Questo oggetto indica lo stato in ingresso dei documenti da dare in pasto alla generazione, lo stato in uscita dei documenti di partenza e lo stato iniziale 
 * dei documenti generati.
 * Ottenuta la procedura in toolbar verranno aggiungi 2 pulsanti che si abiliteranno a seconda della selezione e che permetteranno l'avanzamento di stato o 
 * l'annullamento della generazione.
 * 
 * Infine la procedura ha la proprietà 'avanzata'. Se questa è true sarà possibile per il raggruppamento ORDC passare procedura.statoin 
 * ad uno stato non immediatamente successivo (stampa, inviato per email, inviato tracciato al corriere). Lato server verranno eseguiti 
 * tutti i passaggi di stato intermedi, è semplicemente una scorciatoia per l'utente.
 * 
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} RicercadocFunzioniAggiuntive
 */
function RicercadocCtrl($http, $q, $timeout, $state, $stateParams, $filter, $sce, DSF, DCF, GF, GCF, GN, ToolbarS, idstato, $scope, ToggleActionFct, ActionFactory, ErrorManager, RicercadocFunzioniAggiuntive, SessioneService, SideMenuSrv){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.dcf = DCF;
    self.gf = GF;
    self.gcf = GCF;
    self.gn = GN;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = RicercadocFunzioniAggiuntive;
    self.af = ActionFactory;
    self.taf = ToggleActionFct;
    self.$state = $state;
    self.$sp = $stateParams;
    self.$filter = $filter;
    self.ss = SessioneService;
    self.em = ErrorManager;
    self.moment = moment;
    self.sm = SideMenuSrv;
    self.sce = $sce;
    
    self.toggledselgen = false;

   
    


    angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype, StampaDettaglioMixin.prototype);

    if(self.ss.sessione.flash){
        var flashmsg = self.ss.sessione.flash;
        var protocollo = flashmsg.documento.testata.tipodoc.codice+" #"+flashmsg.documento.testata.protocollo;
        var codicemsg = "RICERCADOC.CREATEDOCSUCCESS";
        if(flashmsg.contesto === 'modifica'){
            codicemsg = "RICERCADOC.EDITDOCSUCCESS";
        }
        self.em.showT(codicemsg, "success", {protocollo: protocollo})    
        self.ss.aggiorna("flash", null);
    }

    self.tipidoc = [];
    self.modelfiltri = {
        dadata: moment().startOf("year").toDate(),//.subtract(1, "month").toDate(),
        adata: moment().toDate()
    };

    self.raggruppamento = self.$sp.raggrdoc;

    self.contestodoc = self.raggruppamento === 'ORCA' ? {title: 'C', label: "CAR.NET"} : {title: "R", label: "RDC"}


    self.codprocedura = self.$sp.codprocedura;
    var procedurareq = null;
    var mappadocreq = null;
    var strentity = "documentistd/"+self.raggruppamento    
    


    if(!self.nou(self.codprocedura)){
        // procedurareq = self.$http({method: "GET", url: utils.urls.wsbase + "/tproceduredoc/" + self.codprocedura})

        var parametri = {
            take: 1,
            skip: 0,
            page: 1,
            pageSize: 1,
            filter: {
                logic: 'and',
                filters: [
                    {field: 'codice', value: self.codprocedura, operator: 'eq'}
                ]
            }
        };

        procedurareq = self.$http({
            method: "POST", 
            url: utils.urls.wsbase + "/tproceduredoc/grid",
            data: $.param(parametri)
        })

        //mappa documenti generati
        mappadocreq = self.$http({
            url: utils.urls.wsbase + "/cfgutenti/grid",
            method: "POST",
            data: $.param({
                take: 1,
                skip: 0,
                page: 1,
                pageSize: 1,
                filter: {
                    logic: 'and',
                    filters: [
                        {operator: 'eq', value: 'MAPPADOCDAISO', field: 'nomecfg'}
                    ]
                }
            })
        });
    }

    var dsfreq = new self.dsf(strentity, null, self.raggruppamento, {
        sort:  [{field: self.raggruppamento === 'RIMB' ? "dataDocumento" : "numero", dir: "desc"}/*{field: "dataDocumento", dir: "desc"}, {field: "dataRegistrazione", dir: "desc"}*/],
        pageSize: 200
        
    }).inizializza();
    
    self.$q.all([procedurareq, dsfreq, mappadocreq, self.cercaTipiDocDaRaggr()]).then(function (arr) {   

        if(!self.nou(arr[3])){
            self.tipodoccorrente = arr[3];
        }
        
        if(!self.nou(arr[0])) {
            self.procedura = bssRecursiveGetter(arr[0], "data.results", [null])[0];
            
            //self.ts.titoloh1 = self.procedura.descrizione;
            console.log("sono in generazione documenti per la procedura", self.procedura)
        }

        var ds = arr[1];

        if(!self.nou(arr[2])){
            var mappadocresult = bssRecursiveGetter(arr[2], "data.results", [null])[0];
            if(!self.nou(mappadocresult)){
                /**
                 * mappa che associa per raggruppamento > iso nazione di destinazione il documento da generare
                 */
                self.mappadociso = JSON.parse(bssRecursiveGetter(mappadocresult, "configurazione", null));
            }
        }

        new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) { 
            
            ds.transport.parameterMap = function(data, type){
                if(type === 'read'){

                    var filtri = angular.copy(self.modelfiltri);
                    var dadata = angular.copy(filtri.dadata);
                    var adata = angular.copy(filtri.adata);

                    if(moment.isDate(dadata) && moment.isDate(adata) && moment(adata).isBefore(moment(dadata))){
                        var tmp = angular.copy(dadata);
                        dadata = angular.copy(adata);
                        adata = angular.copy(tmp);
                    }                                        

                    if(moment.isDate(dadata)){
                        //startof e endof cambiano l'offset ne tengo traccia (bug di momentjs https://github.com/moment/moment-timezone/issues/208)
                        var offset = moment(dadata).utcOffset();
                        dadata = moment(dadata).startOf("day").add(offset, "minutes").toDate();
                        filtri.dadata = dadata; 
                    }
                    if(moment.isDate(adata)){
                        //startof e endof cambiano l'offset ne tengo traccia (bug di momentjs https://github.com/moment/moment-timezone/issues/208)
                        var offset = moment(adata).utcOffset();
                        adata = moment(adata).endOf("day").add(offset, "minutes").toDate();
                        filtri.adata = adata;
                    }

                    var codprocedura = bssRecursiveGetter(self, "procedura.codice", null);
                    var codext = bssRecursiveGetter(self, "modelfiltri.codext", null);
                    var soloDaFatturare = false;

                    switch (codprocedura) {
                        case 'Fatt':
                            soloDaFatturare = true;
                            break;
                        case 'NC':
                            soloDaFatturare = true;
                            //fatturati = true;  non ho modo di passarlo??? 
                        case 'RIMB':
                            soloDaFatturare = false;
                        default:
                            break;
                    }
                    
                    var newdata = angular.extend(
                        {}, 
                        data, 
                        _.isEmpty(self.modelfiltri) ? null : {filtriRicerca: JSON.stringify(filtri)}, 
                        {datiCfg:{
                            strentity: strentity,
                            codapp: null,
                            codmodulo: self.raggruppamento
                        }},
                        {
                            soloDaFatturare: soloDaFatturare,
                            codprocedura: self.codprocedura,
                            codext: codext            
                        }
                    )
                    //console.log("filtriRicerca aggiunti?", data, newdata);
                    return newdata;
                }
                return data;
            }


            gridcfg.change = function(e){
                var selectedRows = self.gridDocs.select();
                self.docSel = null;
                self.selezione = null;

                if(selectedRows.length){
                    var docsSel = selectedRows.toArray().map(function(row){
                        return self.gridDocs.dataItem(row);
                    });

                    self.selezione = angular.copy(docsSel)
                    
                    if(!self.nou(self.codprocedura)){
                        self.infoDocs(docsSel).then(function(resp){     
                            self.docsSel = docsSel;                       
                        }, function(err){
                            self.em.errorCB(err)
                        })
                    }
                    
                }
            }

            gridcfg.dataBound = function (e) {
                if (typeof self.gridDocs !== 'undefined') {
                    var griglia = e.sender;
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        
                        var riga = griglia.dataItem(griglia.select());
                        self.$state.go("documentistd", {raggrdoc: self.raggruppamento, iddoc: riga.id}, {reload: true})
                    })

                    var comandistampa = [
                        new self.af("fa fa-file-pdf", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "stampapdf", parametri: []}), null, null, "GRIDNAV.STAMPAGRIGLIAPDF", "alt+shift+k"),
                        new self.af("fa fa-info",  self.esponi({metodo: "dimSelezioneInMultipleMode", parametri: ["lt", 1, ["form", "griglia"]]}), self.esponi({metodo: "stampa", parametri: ["/documentistd/stampa", self.gridDocs]}), 999, null, "GRIDNAV.STAMPADETTAGLIO"),
                        new self.af("fas fa-hand-holding-usd",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "incassiPopup", parametri: []}), 999, null, "RICERCADOC.STAMPAINCASSI"),
                        new self.af("fas fa-clipboard-list",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "listacodiciPopup", parametri: []}), 1999, null, "RICERCADOC.STAMPALISTACODICI"),
                        new self.af("fas fa-clipboard-list",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "stamparicevuta", parametri: []}), 1999, null, "RICERCADOC.STAMPARICEVUTA"),                            
                    ];

                    if(!self.nou(bssRecursiveGetter(self.tipodoccorrente, "raggruppamento.label", null))){
                        var gruppotipodoc = bssRecursiveGetter(self.tipodoccorrente, "raggruppamento.label", null);
                        console.warn("mi baso sul codice di raggruppamento che è modificabile!!!")
                        if(gruppotipodoc !== null && ['FATT', 'MACRONCC'].includes(gruppotipodoc)){
                            comandistampa.push(
                                new self.af("fa fa-print",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["lt", 1, "griglia"]}), self.esponi({metodo: "stampadocmultipli", parametri: [true]}), 1999, null, "RICERCADOC.STAMPADOCS")

                            )
                        }

                    }

                    var azioni = [
                        new self.af("fa fa-sync", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "ricarica", parametri: []}), 4, null, "GRIDNAV.RICARICA", "alt+shift+r"),
                        new self.taf(
                            self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}),
                            9,
                            new self.af("fa fa-arrows-alt-h", self.esponi({metodo: "inSelectableMode", parametri: ["row"]}), self.esponi({metodo: "setSelectable", parametri: ["multiple, row"]}), null, null, "GRIDNAV.SINGLESEL", "alt+shift+1"),
                            new self.af("fa fa-arrows-alt-v", self.esponi({metodo: "inSelectableMode", parametri: ["multiple, row"]}), self.esponi({metodo: "setSelectable", parametri: ["row"]}), null, null, "GRIDNAV.MULTISEL", "alt+shift+2")
                        ),
                        new self.af("fa fa-print", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), null/*self.esponi({metodo: "salvaCfgUtente", parametri: []})*/, 15, null, "GRIDNAV.STAMPA", null, comandistampa),
                        new self.af("fa fa-plus",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "crea", parametri: []}), 6, null, "GRIDIDENTITY.CREA", "alt+shift++"),
                        new self.af("fa fa-edit", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "modifica", parametri: []}), 7, null, "GRIDIDENTITY.MODIFICA", "alt+shift+e"),
                        
                        new self.af("fas fa-comment-dots", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "storico", parametri: []}), 8, null, "RICERCADOC.STORICO", "alt+shift+s"),
                        new self.af("fa fa-trash", self.esponi({metodo: "dimSelezioneInMode", parametri: ["lt", 1, "griglia"]}), self.esponi({metodo: "confelimina", parametri: []}), 8, null, "GRIDIDENTITY.ELIMINA", "alt+shift+backspace"),
                        new self.af("fa fa-file-excel", self.esponi({metodo: "disabilitaTastoStoricoXML", parametri: []}), self.esponi({metodo: "mostraStoricoXML", parametri: []}), 9, null, "RICERCADOC.STORICOXML", ""),
                    ]

                    if(!self.nou(self.codprocedura)){
                        azioni.push(
                            new self.af("fas fa-palette", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "legenda", parametri: []}), 8, null, "RICERCADOC.LEGENDA", "alt+shift+l"),
                        );
                        var righe = griglia.tbody.find("tr").toArray();
                        var noustatoprocedura = self.nou(self.procedura.statoin)
                        righe.forEach(function(rigaEl){
                            var documento = griglia.dataItem(rigaEl);                            
                            var noustatodoc = self.nou(documento.statodoc)
                            if(noustatoprocedura && noustatodoc){
                                angular.element(rigaEl).removeClass("nonprocedurabile")
                                angular.element(rigaEl).addClass("procedurabile")
                            } else {
                                if(noustatodoc) documento.statodoc = {id: null};
                                if(noustatoprocedura) self.procedura.statoin = {id: null};
                                if(self.procedura.statoin.id !== documento.statodoc.id){
                                    angular.element(rigaEl).addClass("nonprocedurabile")
                                    angular.element(rigaEl).removeClass("procedurabile")
                                } else {
                                    angular.element(rigaEl).removeClass("nonprocedurabile")
                                    angular.element(rigaEl).addClass("procedurabile")
                                }
                            }

                            //come suffisso della classe css prendo il codice dello stato procedura
                            var suffissoclasse = bssRecursiveGetter(documento, "statoproc.codice", null);
                            
                            var codicestatotrasmtrasp = bssRecursiveGetter(documento, "trasmesso.codice", null);

                            //se l'ordine è in stato trasmissione GEST o NOTRASM questo ha priorità sullo statoproc
                            if(!self.nou(codicestatotrasmtrasp) && ["GEST", "NOTRASM"].includes(codicestatotrasmtrasp)){
                               suffissoclasse = codicestatotrasmtrasp;
                            }

                            angular.element(rigaEl).addClass("statoproc-"+suffissoclasse)
                            
                        })
                        azioni.push(new self.af("fas fa-file-alt", self.esponi({metodo: "nessunprocedurabile", parametri: []}), self.esponi({metodo: "aprigendoc", parametri: []}), 15, null, "RICERCADOC.GENDOC", null),)
                        azioni.push(new self.af("fas fa-tasks", self.esponi({metodo: "nessunannullabile", parametri: []}), self.esponi({metodo: "apriannulladoc", parametri: []}), 15, null, self.abilitaGestioneAvanzata() ? "RICERCADOC.GESTDOCFOR" : "RICERCADOC.UNDODOC", null),)
                        
                        
                    
                    }


                    if(self.codprocedura === 'OrdFor' || self.raggruppamento === "OrFo"){
                        azioni.push(new self.af("fas fa-stopwatch-20", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "forzaTimer", parametri: []}), 15, null, "RICERCADOC.FORZATIMER", null),)
                    }

                    if(self.raggruppamento === "MACRONCC"){
                        azioni.push(new self.af("fas fa-clipboard-list", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "genXMLNC", parametri: []}), 16, null, "RICERCADOC.GENERAXMLNC", null),)
                    }

                    azioni.push(new self.af("fas fa-file-pdf", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "genPreview", parametri: []}), 17, null, "RICERCADOC.DOCPREVIEW", null))

                    azioni.push(new self.af("fas fa-file-medical", self.esponi({metodo: "selezioneSingolaUk", parametri: []}), self.esponi({metodo: "regenUkDoc", parametri: []}), 18, null, "RICERCADOC.REGENUK", null))

                    azioni.push(new self.af("fas fa-info-circle", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "datiforncor", parametri: []}), 19, null, "RICERCADOC.INFOAGGIUNTIVE", null));

                    self.ts.mode = "griglia";
                    self.ts.griglia = self.gridDocs;
                    self.ts.setProviders({"ricercadocprovider": {exposed: azioni}}, false);
                    
                }
            }
            gridcfg.dataSource = ds;
            self.gridDocsOpts = angular.extend({}, gridcfg);
            self.gridDocsRebind = moment().format();
            

            ds.read().then(function(){
                //gridcfg.selectable = "multiple, row";
                if(!self.nou(self.codprocedura)){
                    self.setSelectable("multiple, row", true);
                }                
            });
        })
    })

    self.$q.all([
        self.$http({
            url: utils.urls.wsbase + "/ttipodocumento/" + self.raggruppamento + "/grid",
            method: "POST",
            data: $.param({ page: 1, pageSize: 9999, skip: 0, take: 9999 })
        }),//0 - tipi documento
    ])
    .then(function (resps) {
        self.tipidoc = resps[0].data.results;       
    }, function(err){
        self.em.errorCB(err);
    });
}

RicercadocCtrl.prototype.cercaTipiDocDaRaggr = cercaTipiDocDaRaggr;

function cercaTipiDocDaRaggr(){
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/ttipodocumento/grid",
        data: $.param({
            take: 1,
            skip: 0,
            page: 1,
            pageSize: 1,
            filter: {
                logic: 'AND',
                filters: [
                    {field: 'codice', operator: 'eq', value: self.raggruppamento}
                ]
            }
        })
    }).then(function(resp){
        var risultati = bssRecursiveGetter(resp, "data.results", []);
        var risultato = null;
        if(risultati.length){
            risultato = risultati[0];
        }
        return risultato;
    }, function(err){
        self.em.errorCB(err);
    })
}


RicercadocCtrl.prototype.forzaTimer = forzaTimer;


function forzaTimer(){
    var self = this;
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/documentirdc/invioautomaticotrasporti"
    }).then(function(resp){
        self.em.showT("RICERCADOC.TRACCIATIINVIATI", "success");
        self.gridDocs.dataSource.read();
    }, function(err){
        self.em.errorCB(err)
    })
}

/**
 * TOOLBAR STORICO XML
 */
RicercadocCtrl.prototype.listacodiciPopup = listacodiciPopup;
RicercadocCtrl.prototype.incassiPopup = incassiPopup;
RicercadocCtrl.prototype.mostraStoricoXML = mostraStoricoXML;
RicercadocCtrl.prototype.disabilitaTastoStoricoXML = disabilitaTastoStoricoXML;
RicercadocCtrl.prototype.genXMLNC = genXMLNC;
RicercadocCtrl.prototype.genPreview = genPreview;
RicercadocCtrl.prototype.selezioneSingolaUk = selezioneSingolaUk;
RicercadocCtrl.prototype.regenUkDoc = regenUkDoc;

function genXMLNC(){
    var self = this;



    var iddocumenti = self.ts.griglia.select().toArray().map(function(rigaEl){//conteggio procedurabili
        var riga = self.gridDocs.dataItem(rigaEl);
        return riga.id;
    });

    if(Array.isArray(iddocumenti) && iddocumenti.length){
        self.$http({
            url: utils.urls.wsbase + "/documentirdc/creapdfxmlNC",
            method: "POST",
            //headers: {type: 'application/octet-stream'},
            //responseType: 'arraybuffer',
            data: $.param({
                iddocumenti: JSON.stringify(iddocumenti)
            })
        }).then(function(resp){                    
            /*if(resp.data.byteLength !== 0){
                var file = new Blob([resp.data], { type: 'application/xml' });
                saveAs(file, moment().format("DDMMYYYYHHmmss")+'.xml');
            } */      
            var res = angular.extend({}, resp);
            if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
                res = angular.extend({}, res, {data: resp.data.bssmessages});
            }
            
            self.em.errorCB(res);            
        }, function(err){
            self.em.errorCB(err);
        })
    }

    
}

function genPreview(){
    var self = this;
    var arrsel =  self.ts.griglia.select().toArray();
    
    
    if(!self.nou(arrsel) && arrsel.length === 1){
        var rigael = arrsel[0];
        var riga = self.gridDocs.dataItem(rigael);
        var url = utils.urls.wsbase + "/documentirdc/anteprima/"+riga.id;
        
        self.$http({
            method: "GET",
            url: url,
            headers: {type: 'application/octet-stream'},
            responseType: 'arraybuffer'
        }).then(function(resp){
            self.file = new Blob([resp.data], { type: 'application/pdf' });
            self.urlFile = self.sce.trustAsResourceUrl(URL.createObjectURL(self.file))+'#zoom=FitH&pagemode=none&toolbar=0&navpanes=0';
            self.docpreviewWin.open().center();
        }, function(err){
            self.em.errorCB(err);
        })
    } else {
        self.em.showT("RICERCADOC.SEL1DOC")
    }
    

}

function incassiPopup(){
    var self = this;
    self.incassiwin.open().center();
}

function listacodiciPopup(){
    var self = this;
    self.listacodiciwin.open().center();
}

/**
 * @todo cambiare controllo se genera xml
 */
function disabilitaTastoStoricoXML() {
    var self = this;

    var esitoselsingola = self.dimSelezioneInMode("eq", 1, "griglia"); //true se in griglia e con solo un record selezionato
    var esitogenxml = false;

    if(esitoselsingola){//controllo che il doc selezionato generi xml
        var doc = self.gridDocs.dataItem(self.gridDocs.select());
        //devo ottenere generaxml dal tipodocumento
        esitogenxml = doc.tipodoc.generaxml;                
    }

    var esitodisabilita = !esitoselsingola || !esitogenxml;//disabilito il tasto se la selezione non è singola o se il doc non genera xml
    
    return esitodisabilita;
}

function mostraStoricoXML(){
    var self = this;
    self.documentoPerXML = self.gridDocs.dataItem(self.gridDocs.select());
    self.storicoxmlwin.open().center();
}

function selezioneSingolaUk(){
    var self = this;
    var esito = self.dimSelezioneInMode("ne", 1, "griglia");

    if(!esito){
        var doc = self.gridDocs.dataItem(self.gridDocs.select());
        var idReceiver = bssRecursiveGetter(doc, "receiver.id", null);
        esito = (idReceiver === null);
    }

    return esito;
}

function regenUkDoc(){
    var self = this;
    var doc = self.gridDocs.dataItem(self.gridDocs.select());
    var iddoc = doc.id;
    self.$http({
        url: utils.urls.wsbase + "/documentirdc/rigenerainvoice/" +iddoc,
        method: "POST"
    }).then(function(resp){
        self.em.showT("RICERCADOC.REGENUKSUCCESS", "success");    
    }, function(err){
        self.em.errorCB(err);
    });
}

/**
 * FINE TOOLBAR STORICO XML
 */


RicercadocCtrl.prototype.ricarica = ricarica;
RicercadocCtrl.prototype.inSelectableMode = inSelectableMode;
RicercadocCtrl.prototype.setSelectable = setSelectable;
RicercadocCtrl.prototype.ridimensionaGriglia = ridimensionaGriglia;
RicercadocCtrl.prototype.nessunprocedurabile = nessunprocedurabile;
RicercadocCtrl.prototype.nessunannullabile = nessunannullabile;
RicercadocCtrl.prototype.focusField = focusField;
RicercadocCtrl.prototype.stampapdf = stampapdf;
RicercadocCtrl.prototype.stamparicevuta = stamparicevuta;
RicercadocCtrl.prototype.resetfiltri = resetfiltri;
RicercadocCtrl.prototype.filtra = filtra;
RicercadocCtrl.prototype.crea = crea;
RicercadocCtrl.prototype.modifica = modifica;
RicercadocCtrl.prototype.storico = storico;
RicercadocCtrl.prototype.confelimina = confelimina;
RicercadocCtrl.prototype.elimina = elimina;
RicercadocCtrl.prototype.aprigendoc = aprigendoc;
RicercadocCtrl.prototype.apriannulladoc = apriannulladoc;
RicercadocCtrl.prototype.legenda = legenda;

function ricarica(){
    var self = this;
    self.gridDocs.dataSource.read();
}

function inSelectableMode(mode){
    var self = this;        
    return self.gridDocs.options.selectable === mode;

}

function setSelectable(mode, suppressNotification){
    var self = this;
    var label = "GRIDNAV." + (mode === "row" ? "SINGLESELENABLED" : "MULTISELENABLED");

    //@TODO sistemare appena seleziono qualcosa smette di funzionare
    var opzioni = {
        selectable: mode,
        columns: []
    };

    if(mode === "row"){
        opzioni.columns = self.gridDocs.options.columns.slice(1);                
    } else {
        var nuovecolonne = self.gridDocs.options.columns.slice();
        nuovecolonne.unshift({selectable: true, width: '40px'});
        opzioni.columns = nuovecolonne;        
    }

    //self.gridDocs.clearSelection();
    self.gridDocs.setOptions(opzioni);

    if(suppressNotification !== true){
        self.em.showT(label, "success");
    }
    
}

function ridimensionaGriglia(){
    var self = this;
    self.$timeout(function(){
        window.resizeGrid()
    }, 200)
    
}

function nessunprocedurabile(){
    var self = this;
    var nessunasel = self.dimSelezioneInMode("lt", 1, "griglia");
    if(!nessunasel){//ho selezionato qualcosa
        var procedurabilisel = self.ts.griglia.select().toArray().filter(function(rigaEl){//conteggio procedurabili
            return !angular.element(rigaEl).hasClass("nonprocedurabile")
        });
        return procedurabilisel.length === 0;
    } else {
        return true;
    }

}

function nessunannullabile(){
    var self = this;
    var nessunasel = self.dimSelezioneInMode("lt", 1, "griglia");
    if(!nessunasel){//ho selezionato qualcosa
        var annullabilisel = self.ts.griglia.select().toArray().filter(function(rigaEl){//conteggio annullabili
            return angular.element(rigaEl).hasClass("nonprocedurabile")
        });
        return annullabilisel.length === 0;
    } else {
        return true;
    }
}

function focusField($event) {
    var self = this;
    var el = $event.currentTarget;
    var oritype = el.type;
    el.type = 'text';
    el.setSelectionRange(0, el.value.length);
    el.type = oritype;
    //console.log("focus su campo", $event);
}

function stampapdf(){
    this.gridDocs.saveAsPDF();
}

function stamparicevuta(){
    var self = this;


    var listaid = Array.from(self.gridDocs.select()).map(function(rigaEl){
        var riga = self.gridDocs.dataItem(rigaEl);
        return riga.id;
    })
        

    
    return self.$http({
        url: utils.urls.wsbase + "/documentirdc/stamparicevuta",
        method: 'POST',
        data: $.param({iddocs: JSON.stringify(listaid)}),        
    }).then(function(resp){
        self.preservaSelezione(listaid)
        //gestire eventuali warning
        //self.em.showT("RICERCADOC.DOCGEN", "success");
        var res = angular.extend({}, resp);
        if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
            res = angular.extend({}, res, {data: resp.data.bssmessages});
        }
        
        self.em.errorCB(res);
            
        
    }, function(err){
        self.em.errorCB(err);
    });
}

function crea(){
    var self = this;
    self.$state.go("documentistd", {raggrdoc: self.raggruppamento}, {reload: true})
}

/**
 * 
 * @param {*} rigaSel //riga selezionata da inviare in modifica è opzionale di default è la riga selezionata dalla griglia principale 
 */
function modifica(rigaSel){
    var self = this;
    var riga = self.nou(rigaSel) ? self.gridDocs.dataItem(self.gridDocs.select()) : rigaSel;
    self.$state.go("documentistd", {raggrdoc: self.raggruppamento, iddoc: riga.id}, {reload: true})
}

/**
 * @todo aggiornare url webservice
 */
function storico(){
    var self = this;
    self.docselticket = self.gridDocs.dataItem(self.gridDocs.select());
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/documentistd/" + self.docselticket.id + "/eventi",
    }).then(function(resp){
        self.ticketdocumento = resp.data.map(function(t){
            t.data = moment.utc(t.dataora).format("LLLL")
            return t;
        });
        self.storicoticketwin.open().center();
    }, function(err){
        self.em.errorCB(err);
    })
    
}

function confelimina(){
    var self = this;
    self.confdelwin.open().center();
}

function elimina(force){

    var self = this;

    var ids = self.gridDocs.select().toArray().map(function(rigaEl){
        return self.gridDocs.dataItem(rigaEl).id
    });

    console.log("richiamo il web service di cancellazione per i record con id", ids);
    self.documentiBloccanti = [];
    if(self.nou(force)){
        force = false;
    }
    
    self.$http({
        url: utils.urls.wsbase + "/documentistd/"+self.raggruppamento+"/elimina",
        method: 'POST',
        data: $.param({ids: JSON.stringify(ids), force: !!force})
    }).then(function(resp){
        self.gridDocs.dataSource.read();
        self.documentiBloccanti = [];
        self.confdelwin.close();
        self.em.showT("RICERCADOC.DELSUCCESS", "success");        
    }, function(err){
        self.em.errorCB(err);
        if(err.data[0].type === 'warning'){//in description dovrei avere l'elenco dei documenti che verrebbero cancellati in cascata usando force a true
            self.documentiBloccanti = err.data[0].description; 
        }

    })
}

function aprigendoc(){
    var self = this;
    self.$timeout(function(){
        var selezione = self.ts.griglia.select().toArray()
            .filter(function(rigaEl){
                return !angular.element(rigaEl).hasClass("nonprocedurabile")
            })
            .map(function(rigaEl){
                return self.ts.griglia.dataItem(rigaEl)
            })
            // .filter(function(documento){
            //     var noudoc = self.nou(documento.statodoc);
            //     var nouproc = self.nou(self.procedura.statoin);
            //     var statodoc = !noudoc ? documento.statodoc.id : -1;
            //     var statoproc = !nouproc ? self.procedura.statoin.id : -2;
            //     return (noudoc && nouproc) || //entrambe nulli
            //         (!noudoc && !nouproc && statodoc === statoproc )
            // })
    
        self.selezionegen = null;
        self.generabili = null;
    
        self.docgenerabili(selezione).then(function(resp){
            self.generabili = resp
            self.toggledselgen = false;
            self.gendocwin.open().center();
        })
    })
    
}

function apriannulladoc(){
    var self = this;
    self.$timeout(function(){
        var selezione = self.ts.griglia.select().toArray()
            .filter(function(rigaEl){
                return angular.element(rigaEl).hasClass("nonprocedurabile")
            })
            .map(function(rigaEl){
                return self.ts.griglia.dataItem(rigaEl)
            })
            
        self.selezionegen = null;
        self.generabili = null;
    
        self.docgenerabili(selezione).then(function(resp){
            self.generabili = resp;
            self.toggledselgen = false;
            self.gestdocforwin.open().center();
        })
    })
}

function resetfiltri(){
    var self = this;

    self.filtri.$setPristine();
    self.filtri.$setUntouched();

    self.modelfiltri = {
        dadata: moment().startOf("year").subtract(1, "month").toDate(),
        adata: moment().toDate()
    };
}

function filtra(){
    var self = this;

    self.gridDocs.dataSource.read();
    
    
    
}

/**
 * ===================================================================== Tipo Doc =======================================================================================================
 */

RicercadocCtrl.prototype.svuotaidtipodoc = svuotaidtipodoc;
RicercadocCtrl.prototype.bluridtipodoc = bluridtipodoc;
RicercadocCtrl.prototype.setidtipodoc = setidtipodoc;
RicercadocCtrl.prototype.resetidtipodoc = resetidtipodoc;

function svuotaidtipodoc() {
    var self = this;
    console.log("svuoto il tipo documento")
    self.modelfiltri.idtipodoc = null;
    self.modelfiltri._idtipodoc = null;
    self.filtri.idtipodoc.$setViewValue("");
    self.disabilitaidtipodoc = false;

}

/**
 * @description 
 *  Quando esco dal campo idtipodoc, se per il termine di ricerca esiste un solo valore che matcha aggiorno il model 
 *  se ci sono più valori o nessun valore che matchano apro la finestra con i valori ammessi
 *  se non ci sono valori possibili il filtro viene svuotato e viene mostrato un warning, quindi vengono mostrate tutte le opzioni (non filtrate)
 * @param {*} $event 
 */
function bluridtipodoc($event) {
    var self = this;
    console.log("bluridtipodoc");

    var term = self.filtri.idtipodoc.$viewValue;

    if (typeof term === 'undefined') {
        return;
    }

    term = term.toUpperCase();
    var opzioni = self.$filter('filter')(self.tipidoc, term)
    
    if (opzioni.length === 1) {
        self.setidtipodoc(opzioni[0])
    } else {
        $event.stopImmediatePropagation();
        if (opzioni.length === 0) {
            self.em.showT("DOCUMENTISTD.NOTIPODOCFOUND", "warning");
            self.resetidtipodoc()
        }
        self.idtipodocwin.open().center();
    }
}


/**
 * aggiorna il termine di ricerca per idtipodoc e il model
 * aggiorna mostraanagrafica
 * @param {*} tdoc 
 */
function setidtipodoc(tdoc) {
    var self = this;
    self.modelfiltri.idtipodoc = tdoc.id;
    self.modelfiltri._idtipodoc = tdoc.codice + " - " + tdoc.nome;
    self.idtipodocwin.close();
    self.disabilitaidtipodoc = true;
}

function resetidtipodoc() {
    var self = this;
    self.svuotaidtipodoc();
}

/**
 * ===================================================================== Fine Tipo Doc =======================================================================================================
 */

/**
 * ===================================================================== Filiale Corr =======================================================================================================
 */

RicercadocCtrl.prototype.svuotaidfilialecorr = svuotaidfilialecorr;
RicercadocCtrl.prototype.bluridfilialecorr = bluridfilialecorr;
RicercadocCtrl.prototype.setidfilialecorr = setidfilialecorr;
RicercadocCtrl.prototype.getidfilialecorr = getidfilialecorr;
RicercadocCtrl.prototype.resetidfilialecorr = resetidfilialecorr;
RicercadocCtrl.prototype.initGrididfilialecorr = initGrididfilialecorr;

function svuotaidfilialecorr() {
    var self = this;
    console.log("svuoto il tipo documento")
    self.modelfiltri.idfilialecorr = null;
    self.modelfiltri._idfilialecorr = null;
    self.filtri.idfilialecorr.$setViewValue("");
    self.disabilitaidfilialecorr = false;

}

/**
 * @description 
 *  Quando esco dal campo idfilialecorr, se per il termine di ricerca esiste un solo valore che matcha aggiorno il model 
 *  se ci sono più valori o nessun valore che matchano apro la finestra con i valori ammessi
 *  se non ci sono valori possibili il filtro viene svuotato e viene mostrato un warning, quindi vengono mostrate tutte le opzioni (non filtrate)
 * @param {*} $event 
 */
function bluridfilialecorr() {
    var self = this;
    console.log("bluridfilialecorr");

    var term = self.filtri.idfilialecorr.$viewValue;

    self.getidfilialecorr().then(function(opzioni){
        if (opzioni.length === 1) {
            self.setidfilialecorr(opzioni[0])
        } else {
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOBRANCHFOUND", "warning");
                self.resetidfilialecorr()
            }
            self.initGrididfilialecorr();
            self.idfilialecorrwin.open().center();
        }
    })
}


/**
 * aggiorna il termine di ricerca per idfilialecorr e il model
 * aggiorna mostraanagrafica
 * @param {*} tdoc 
 */
function setidfilialecorr(filcorr) {
    var self = this;
    self.modelfiltri.idfilialecorr = filcorr.id;
    self.modelfiltri._idfilialecorr = filcorr.codice + " - " + filcorr.descrizione;
    self.idfilialecorrwin.close();
    self.disabilitaidfilialecorr = true;
}

function resetidfilialecorr() {
    var self = this;
    self.svuotaidfilialecorr();
}

/**
 * Ritorna una lista di al più 2 vettori
 * @returns {Promise<filialivett>}
 */
function getidfilialecorr() {
    var self = this;
    var term = self.filtri.idfilialecorr ? self.filtri.idfilialecorr.$viewValue : "";
    var strentity = "filialivett";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibilifilialicorr = resp.data.results;
        return self.possibilifilialicorr
    })        
}


function initGrididfilialecorr() {
    var self = this;
    var strentity = "filialivett";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setidfilialecorr(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.filtri.idfilialecorr ? self.filtri.idfilialecorr.$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.grididfilialecorr !== 'undefined') {
                    self.grididfilialecorr.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibilifilialicorr = self.grididfilialecorr.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {                        
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.grididfilialecorrOpts = angular.extend({}, gridcfg);
            self.grididfilialecorrRebind = moment().format();
            //return ds.read();
        })
    });
}

/**
 * ===================================================================== Fine Filiale Corr =======================================================================================================
*/

/**
 * ===================================================================== Fornitore =======================================================================================================
 */

RicercadocCtrl.prototype.svuotaidfornitore = svuotaidfornitore;
RicercadocCtrl.prototype.bluridfornitore = bluridfornitore;
RicercadocCtrl.prototype.setidfornitore = setidfornitore;
RicercadocCtrl.prototype.getidfornitore = getidfornitore;
RicercadocCtrl.prototype.resetidfornitore = resetidfornitore;
RicercadocCtrl.prototype.initGrididfornitore = initGrididfornitore;

function svuotaidfornitore() {
    var self = this;
    self.modelfiltri.idfornitore = null;
    self.modelfiltri._idfornitore = null;
    self.filtri.idfornitore.$setViewValue("");
    self.disabilitaidfornitore = false;

}

/**
 * @description 
 *  Quando esco dal campo idfornitore, se per il termine di ricerca esiste un solo valore che matcha aggiorno il model 
 *  se ci sono più valori o nessun valore che matchano apro la finestra con i valori ammessi
 *  se non ci sono valori possibili il filtro viene svuotato e viene mostrato un warning, quindi vengono mostrate tutte le opzioni (non filtrate)
 * @param {*} $event 
 */
function bluridfornitore() {
    var self = this;

    var term = self.filtri.idfornitore.$viewValue;

    self.getidfornitore().then(function(opzioni){
        if (opzioni.length === 1) {
            self.setidfornitore(opzioni[0])
        } else {
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NORIGAFORNFOUND", "warning");
                self.resetidfornitore()
            }
            self.initGrididfornitore();
            self.idfornitorewin.open().center();
        }
    })
}


/**
 * aggiorna il termine di ricerca per idfornitore e il model
 * aggiorna mostraanagrafica
 * @param {*} tdoc 
 */
function setidfornitore(forn) {
    var self = this;
    self.modelfiltri.idfornitore = forn.id;
    self.modelfiltri._idfornitore = forn.codice + " - " + forn.ragsoc;
    self.idfornitorewin.close();
    self.disabilitaidfornitore = true;
}

function resetidfornitore() {
    var self = this;
    self.svuotaidfornitore();
}

/**
 * Ritorna una lista di al più 2 fornitori
 * @returns {Promise<filialivett>}
 */
function getidfornitore() {
    var self = this;
    var term = self.filtri.idfornitore ? self.filtri.idfornitore.$viewValue : "";
    var strentity = "fornitori";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibilifornitori = resp.data.results;
        return self.possibilifornitori
    })        
}


function initGrididfornitore() {
    var self = this;
    var strentity = "fornitori";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setidfornitore(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.filtri.idfornitore ? self.filtri.idfornitore.$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.grididfornitore !== 'undefined') {
                    self.grididfornitore.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibilifornitori = self.grididfornitore.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.grididfornitoreOpts = angular.extend({}, gridcfg);
            self.grididfornitoreRebind = moment().format();
            //return ds.read();
        })
    });
}

/**
 * ===================================================================== Fine Fornitore =======================================================================================================
*/

/**
 * ===================================================================== Cod. Articolo =======================================================================================================
 */

RicercadocCtrl.prototype.svuotacodarticolo = svuotacodarticolo;
RicercadocCtrl.prototype.blurcodarticolo = blurcodarticolo;
RicercadocCtrl.prototype.setcodarticolo = setcodarticolo;
RicercadocCtrl.prototype.getcodarticolo = getcodarticolo;
RicercadocCtrl.prototype.resetcodarticolo = resetcodarticolo;
RicercadocCtrl.prototype.initGridcodarticolo = initGridcodarticolo;

function svuotacodarticolo() {
    var self = this;
    self.modelfiltri.codarticolo = null;
    self.modelfiltri._codarticolo = null;
    self.filtri.codarticolo.$setViewValue("");
    self.disabilitacodarticolo = false;

}

/**
 * @description 
 *  Quando esco dal campo codarticolo, se per il termine di ricerca esiste un solo valore che matcha aggiorno il model 
 *  se ci sono più valori o nessun valore che matchano apro la finestra con i valori ammessi
 *  se non ci sono valori possibili il filtro viene svuotato e viene mostrato un warning, quindi vengono mostrate tutte le opzioni (non filtrate)
 * @param {*} $event 
 */
function blurcodarticolo() {
    var self = this;

    var term = self.filtri.codarticolo.$viewValue;

    self.getcodarticolo().then(function(opzioni){
        if (opzioni.length === 1) {
            self.setcodarticolo(opzioni[0])
        } else {
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOARTICOLOFOUND", "warning");
                self.resetcodarticolo()
            }
            self.initGridcodarticolo();
            self.codarticolowin.open().center();
        }
    })
}


/**
 * aggiorna il termine di ricerca per codarticolo e il model
 * aggiorna mostraanagrafica
 * @param {*} tdoc 
 */
function setcodarticolo(art) {
    var self = this;
    self.modelfiltri.codarticolo = art.codarticolo;
    self.modelfiltri._codarticolo = art.codarticolo + " - " + art.descrizione;
    self.codarticolowin.close();
    self.disabilitacodarticolo = true;
}

function resetcodarticolo() {
    var self = this;
    self.svuotacodarticolo();
}

/**
 * Ritorna una lista di al più 2 fornitori
 * @returns {Promise<filialivett>}
 */
function getcodarticolo() {
    var self = this;
    var term = self.filtri.codarticolo ? self.filtri.codarticolo.$viewValue : "";
    var strentity = "articolirdc";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibilifornitori = resp.data.results;
        return self.possibilifornitori
    })        
}


function initGridcodarticolo() {
    var self = this;
    var strentity = "articolirdc";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setcodarticolo(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.filtri.codarticolo ? self.filtri.codarticolo.$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridcodarticolo !== 'undefined') {
                    self.gridcodarticolo.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibilifornitori = self.gridcodarticolo.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridcodarticoloOpts = angular.extend({}, gridcfg);
            self.gridcodarticoloRebind = moment().format();
            //return ds.read();
        })
    });
}

/**
 * ===================================================================== Fine Cod. Articolo =======================================================================================================
*/

/**
 * ===================================================================== Generazione Documenti ====================================================================================================
 */

RicercadocCtrl.prototype.docFigli = docFigli;
RicercadocCtrl.prototype.infoDocs = infoDocs;
RicercadocCtrl.prototype.abilitaGestioneAvanzata = abilitaGestioneAvanzata;
RicercadocCtrl.prototype.docgenerabili = docgenerabili;
RicercadocCtrl.prototype.generadoc = generadoc;
RicercadocCtrl.prototype.getdaprocessare = getdaprocessare;
RicercadocCtrl.prototype.generadocmultipli = generadocmultipli;
RicercadocCtrl.prototype.annulladocmultipli = annulladocmultipli;
RicercadocCtrl.prototype.stampadocmultipli = stampadocmultipli;
RicercadocCtrl.prototype.emaildocmultipli = emaildocmultipli;
RicercadocCtrl.prototype.tracciatidocmultipli = tracciatidocmultipli;
RicercadocCtrl.prototype.annullaspeddocmultipli = annullaspeddocmultipli;
RicercadocCtrl.prototype.toggleselgen = toggleselgen;
RicercadocCtrl.prototype.scaricaXML = scaricaXML;
RicercadocCtrl.prototype.preservaSelezione = preservaSelezione;
RicercadocCtrl.prototype.datiforncor = datiforncor;

function datiforncor(){
    var self = this;
    
    var doc = self.selezione?.[0] ?? null;

    if(doc!== null){
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/documentistd/infodoc",
            data: $.param({
                iddoc: doc.id
            })
        }).then(function(resp){
            self.docforncor = resp.data;
            self.datiforncorwin.open().center();
        })
    }

    

}



function docFigli(doc){
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tproceduredoc/getgenerati",
        data: $.param({iddoc: doc.id, idprocedura: self.procedura.id})
    })
    //self.$q.when({data: {results: [{tipodoc: {label: "FAKETIPODOC"}, numero: "FAKE001", intestatario: {label: "Mario Rossi Fake"}}]}})
    .then(function(resp){
        self.docfigli = resp.data.results;
        return resp;
    }, function(err){
        self.em.errorCB(err);
    })

}

function infoDocs(docs){
    var self = this;
    self.mappaIdUid = docs.reduce(function(obj, doc){
        obj[doc.id] = doc.uid;
        return obj}, {});
    

    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tproceduredoc/getinfodocs",
        data: $.param({iddocs: JSON.stringify(Object.keys(self.mappaIdUid)), idprocedura: self.procedura.id})
    })
    .then(function(resp){
        self.docfigli = !self.nou(resp.data.docgenerati) ? resp.data.docgenerati.results.sort(function(a, b){
            var codicea = bssRecursiveGetter(a, "anagrafica.codice", "");
            var codiceb = bssRecursiveGetter(b, "anagrafica.codice", "");
            var comparison = codicea.localeCompare(codiceb);
            return comparison;
        }) : [];
        
        self.anagrafiche = !self.nou(resp.data.anagrafiche) ? resp.data.anagrafiche.results.sort(function(a, b){
            var codicea = bssRecursiveGetter(a, "codice", "");
            var codiceb = bssRecursiveGetter(b, "codice", "");
            var comparison = codicea.localeCompare(codiceb);
            return comparison;
        }) : [];
                
        return resp;
    }, function(err){
        self.em.errorCB(err);
    })


}

/**
 * @description 
 * criterio che abilita, in base alla procedura i tasti stampa, invia email e invia tracciati
 */
function abilitaGestioneAvanzata(){
    var self = this;
    return !self.nou(self.procedura) &&
     self.raggruppamento === 'ORD' &&
        self.procedura.avanzata === true;
}

/**
 * @typedef {Object} TrproceduredocRespDataResult
 * @property {Object} docda
 * @property {Object} doca
 * @property {Object} procedura
 * 
 * @typedef {Object} TrproceduredocResp
 * @property {TrproceduredocRespDataResult[]} data
 */

function docgenerabili(selezione){
    var self = this;
    
    return self.$http({
        method: "POST", 
        url: utils.urls.wsbase + "/trproceduredoc/cercatutti"
    })    
    .then(
        /**
         * 
         * @param {TrproceduredocResp} resp 
         * @returns {Generabile}
         */
        function(resp){

            var tipidocselezione = _.uniq(selezione.map(function(rowdata){
                return rowdata.tipodoc.id;
            }));


            var mappeDaSelezione = resp.data.filter(function(mappa){
                return mappa.procedura.id === self.procedura.id &&
                    tipidocselezione.indexOf(mappa.docda.id) !== -1
            }).reduce(function(obj, mappa){
                obj[mappa.docda.id] = mappa.doca
                return obj;
            }, {})

            var generabili = selezione.map(function(documento){
                documento.genera = mappeDaSelezione[documento.tipodoc.id]
                if(self.nou(documento.genera)){//cerco di ottenere il tipo dalla mappa per raggr e iso
                    console.log("devo ottenere per il documento", documento.destinazione, documento.tipodoc, "cosa verrà generato usando la mappa", self.mappadociso)
                }
                return documento;
            })
            
            console.log("documenti generabili", generabili);
            return generabili;            
        }
    )
}

function generadoc(doc){
    var self = this;
    console.log("genero singolo da", doc);
}

function getdaprocessare () {
    var self = this;
    if(self.nou(self.selezionegen)) return [];
    var daprocessare = Object.values(self.selezionegen).filter(function(d){
        return !self.nou(d) && d;
    })
    return daprocessare;
}

/**
 * Fa la lettura della grid e seleziona gli id passati come parametro
 * 
 * @param {Number[]} ids 
 */
function preservaSelezione(ids){
    var self = this;

    self.gridDocs.dataSource.read().then(function(){
        ids.forEach(function(id){
            var item = self.gridDocs.dataSource.get(id);
            var riga = self.gridDocs.tbody.find("[data-uid='"+ item.uid +"']");
            console.log("riseleziono la riga con id", id, item.uid, item, riga);
            self.gridDocs.select(riga);
        })
    });
}

/**
 * @todo
 * completa generadocmultipli
 */
function generadocmultipli(){
    var self = this;
    
    var dasalvare = self.getdaprocessare();
    if(dasalvare.length){
        // var dasalvare = self.selezionegen.filter(function(d){
        //     return !self.nou(d) && d;
        // });        
        // var dasalvare = Object.values(self.selezionegen).filter(function(d){
        //     return !self.nou(d) && d;
        // }).map(function(doc){
        //     return doc.id
        // });
        self.generaincorso = true;
        console.log("genero multipli da", dasalvare);

        var reqbody = {
            iddocs: JSON.stringify(dasalvare), 
            idprocedura: self.procedura.id, 
            inviaemail: self.inviaemail,
            datafatturazione: moment().toDate()
        };

        if(self.codprocedura === 'Fatt' && !self.nou(self.datafatturazione) && moment(self.datafatturazione).isValid()){
            reqbody.datafatturazione = self.datafatturazione;
        }

        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/genera",
            data: $.param(reqbody)
        }).then(function(resp){
            self.generaincorso = false;
            self.gendocwin.close();
            self.preservaSelezione(dasalvare)
            //gestire eventuali warning
            //self.em.showT("RICERCADOC.DOCGEN", "success");
            var res = angular.extend({}, resp);
            if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
                res = angular.extend({}, res, {data: resp.data.bssmessages});
            }
            
            self.em.errorCB(res);
        }, function(err){
            self.generaincorso = false;
            self.em.errorCB(err);
        })

        
    } else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function annulladocmultipli(){
    var self = this;
    var dasalvare = self.getdaprocessare();
    self.erroriAnnullaGen = [];
    if(dasalvare.length){
        console.log("annullo multipli da", dasalvare);
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/annulla",
            data: $.param({iddocs: JSON.stringify(dasalvare), idprocedura: self.procedura.id, inviaemail: self.inviaemail})
        }).then(function(resp){
            
            /*
            *
            */
           var respconerrori = false;
                
            if(resp.status === 200){//se lo status è 200 
                //se sono presenti errori 
                respconerrori = Array.isArray(resp.data) && (resp.data.find(e => e.type === 'error') !== undefined);
                if(respconerrori === true){
                    console.log("devo gestire gli errori di generadocannulla", resp.data);
                    self.em.errorCB(resp);
                    //ottengo gli id dei documenti per cui l'annullamento è andato in errore
                    self.erroriAnnullaGen = resp.data.reduce(function(errori, errore){
                        errori[errore.description.padre.id] = errore.description
                        return errori;
                    }, {});
                    console.log("errori bloccanti per annulla")
                } else {
                    self.em.showT("RICERCADOC.ANNULLAGENSUCC", "success");
                    self.gestdocforwin.close();
                    self.gridDocs.dataSource.read();
                }
            } else {
                self.em.showT("RICERCADOC.ANNULLAGENSUCC", "success");
                self.gestdocforwin.close();
                self.gridDocs.dataSource.read();
            }
            
            
            
            
            //gestire eventuali warning
        }, function(err){
            console.log("errore generadoc", err)
        })        
    } else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function stampadocmultipli(dagrid){
    var self = this;

    var dasalvare = [];
    var idprocedura = bssRecursiveGetter(self, "procedura.id", 0);

    if(dagrid !== true){
        dasalvare = self.getdaprocessare();
    } else {
        var selectedRows = self.gridDocs.select();
        dasalvare = selectedRows.toArray().map(function(row){
            return self.gridDocs.dataItem(row);
        }).map(function(doc){
            return doc.id;
        });
    }
     
    if(dasalvare.length){
        console.log("stampa multipli da", dasalvare);
        self.operazioneincorso = true;
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/stampe",
            data: $.param({iddocs: JSON.stringify(dasalvare), idprocedura: idprocedura, inviaemail: self.inviaemail})
        }).then(function(resp){
            self.operazioneincorso = false;
            self.em.showT("RICERCADOC.STAMPAGENSUCC", "success");                 
            self.preservaSelezione(dasalvare);
            self.gestdocforwin.close();       
            //gestire eventuali warning
        }, function(err){
            self.operazioneincorso = false;
            self.em.errorCB(err)
        })        
    } else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function emaildocmultipli(){
    var self = this;
    var dasalvare = self.getdaprocessare();
    if(dasalvare.length){
        console.log("email multipli da", dasalvare);
        self.operazioneincorso = true;
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/inviamail",
            data: $.param({iddocs: JSON.stringify(dasalvare), idprocedura: self.procedura.id, inviaemail: self.inviaemail})
        }).then(function(resp){
            self.em.showT("RICERCADOC.EMAILGENSUCC", "success");
            self.gestdocforwin.close();            
            self.gridDocs.dataSource.read();
            self.operazioneincorso = false;
            //gestire eventuali warning
        }, function(err){
            self.operazioneincorso = false;
            self.em.errorCB(err);
        })
        
    } else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function tracciatidocmultipli(){
    var self = this;
    var dasalvare = self.getdaprocessare();
    if(dasalvare.length){
        console.log("tracciati multipli da", dasalvare);
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/inviatracciati",
            data: $.param({iddocs: JSON.stringify(dasalvare), idprocedura: self.procedura.id, inviaemail: self.inviaemail})
        }).then(function(resp){
            self.em.showT("RICERCADOC.TRACCIATIGENSUCC", "success");
            self.gestdocforwin.close();            
            self.gridDocs.dataSource.read();
            //gestire eventuali warning
        }, function(err){
            self.em.errorCB(err);
        })

    }else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function annullaspeddocmultipli(){
    var self = this;
    var dasalvare = self.getdaprocessare();
    if(dasalvare.length){
        console.log("tracciati multipli da", dasalvare);
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/generadoc/annullaspedizioni",
            data: $.param({iddocs: JSON.stringify(dasalvare), idprocedura: self.procedura.id, inviaemail: self.inviaemail})
        }).then(function(resp){
            self.em.showT("RICERCADOC.SPEDANNULLASUCC", "success");
            self.gestdocforwin.close();            
            self.gridDocs.dataSource.read();
            //gestire eventuali warning
        }, function(err){
            self.em.errorCB(err);
        })

    }else {
        self.em.showT("RICERCADOC.NODOCGEN", "warning")
    }
}

function toggleselgen(checked){
    var self = this;

    console.log("cambio self.toggledselgen da", self.toggledselgen, checked)
    //self.toggledselgen = (self.toggledselgen === undefined || self.toggledselgen === null || !self.toggledselgen);
    //console.log("a", self.toggledselgen, checked);
    self.toggledselgen = checked;

    if(self.selezionegen === null || self.selezionegen === undefined){
        self.selezionegen = [];
    }

    self.generabili.forEach(function(doc, indice){
        self.selezionegen[indice] = self.toggledselgen ? doc.id : false;
    })
}

function scaricaXML(doc){

    var self = this;

    
    self.$http({
        url: utils.urls.wsbase + "/trasmissionife/" + doc.id,
        method: "POST",
    }).then(function(resp){
        //ottengo la trasmissione che non è andata in errore
        var trasmissione = resp.data.find(function(trasmissione){
            return !trasmissione.errore;
        });

        if(!self.nou(trasmissione)){
            self.$http({
                url: utils.urls.wsbase + "/trasmissionife/download/" + trasmissione.id,
                method: "POST",
                headers: {type: 'application/octet-stream'},
                responseType: 'arraybuffer'
            }).then(function(resp){
                //ottengo la trasmissione che non è andata in errore
                if(resp.data.byteLength !== 0){
                    var file = new Blob([resp.data], { type: 'application/xml' });
                    saveAs(file, path.basename(trasmissione.nomefile));
                }                    
            }, function(err){
                self.em.errorCB(err);
            })
        }
        

    }, function(err){
        self.em.errorCB(err);
    })
}

 /**
 * =================================================================== Fine Generazione Documenti =================================================================================================
 */

 function legenda(){
     var self = this;
     self.legendawin.open().center();

 }

module.exports = RicercadocCtrl;
