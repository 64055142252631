require("moduleFold/mastrodettaglio/styles/mastrodettagliov2.scss");

'use strict';

/**
 * @TODO da testare per bene, modulo documentistd dovrebbe usare questo al posto di 
 * MastroDettaglioMixin e MastroDettaglioMixinV2
 */
function MastroDettaglioMixinV3(){}


MastroDettaglioMixinV3.prototype.disabilitaricaricadettaglio = disabilitaricaricadettaglio;
MastroDettaglioMixinV3.prototype.disabilitaSalva = disabilitaSalva;
MastroDettaglioMixinV3.prototype.eliminasel = eliminasel;
MastroDettaglioMixinV3.prototype.evidenziarighe = evidenziarighe;
MastroDettaglioMixinV3.prototype.focusgriglia = focusgriglia;
MastroDettaglioMixinV3.prototype.getModalita = getModalita;
MastroDettaglioMixinV3.prototype.getSelezione = getSelezione;
MastroDettaglioMixinV3.prototype.modificaRiga = modificaRiga;
MastroDettaglioMixinV3.prototype.openedit = openedit;
MastroDettaglioMixinV3.prototype.openeditnuovo = openeditnuovo;
MastroDettaglioMixinV3.prototype.openeditTemplate = openeditTemplate;
MastroDettaglioMixinV3.prototype.osservaSelezione = osservaSelezione;
MastroDettaglioMixinV3.prototype.righeseldiff = righeseldiff;
MastroDettaglioMixinV3.prototype.righeselvuote = righeselvuote;
MastroDettaglioMixinV3.prototype.salvaesuccessivo = salvaesuccessivo;
MastroDettaglioMixinV3.prototype.salvamastrodettaglio = salvamastrodettaglio;
MastroDettaglioMixinV3.prototype.salvariga = salvariga;
MastroDettaglioMixinV3.prototype.selezioneSinc = selezioneSinc;
MastroDettaglioMixinV3.prototype.sostituiscisalva = sostituiscisalva;
MastroDettaglioMixinV3.prototype.toggleedit = toggleedit;
MastroDettaglioMixinV3.prototype.vaiprecedente = vaiprecedente;
MastroDettaglioMixinV3.prototype.vaisuccessivo = vaisuccessivo;
MastroDettaglioMixinV3.prototype.riferimenti = riferimenti;
MastroDettaglioMixinV3.prototype.initDefault = initDefault;
MastroDettaglioMixinV3.prototype.initAllDefaults = initAllDefaults;
MastroDettaglioMixinV3.prototype.getStrentityProp = getStrentityProp;
MastroDettaglioMixinV3.prototype.parametriDettaglioStd = parametriDettaglioStd;

function initDefault(strentity, prop, defaultValue){
    var self = this;
    if(typeof self[prop] === 'undefined'){
        self[prop] = {};
    }
    if(typeof self[prop][strentity] === 'undefined'){
        self[prop][strentity] = defaultValue;
    }
    //console.log("inizializzato ", prop, "per", strentity, "con valore", defaultValue, self[prop][strentity])
}

function initAllDefaults(strentity, defaults){
    var self = this;
    var props = Object.keys(defaults);
    for(var i = 0; i < props.length; i++){
        self.initDefault(strentity, props[i], defaults[props[i]]);
    }
}

function getStrentityProp(strentity, prop){
    var self = this;
    var strentityProp = (typeof self[prop] !== "undefined") ? 
        self[prop][strentity] :
        null;
    return strentityProp
}

function riferimenti(strentity){
    var self = this;    
    if(typeof strentity !== 'undefined'){
        self.initAllDefaults(strentity, {
            righe: [],
            righeeliminate: [],
            righedaeliminare: [],
            righeGridMostra: false,
            editRigheWin: {},
            confermaDelWin: {},
            righeds: {},
           
        })
    }
    
    var rifs = {
        g: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeGrid") : self.righeGrid,
        r: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righe") : self.righe,
        rel: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeeliminate") : self.righeeliminate,
        rdel: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righedaeliminare") : self.righedaeliminare,
        pd: (typeof strentity !== 'undefined') ? angular.extend({}, self.parametriDettaglioStd(strentity), self.getStrentityProp(strentity, "parametriDettaglio")) : self.parametriDettaglio,
        erw:(typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "editRigheWin") : self.editRigheWin,
        cdw:(typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "confermaDelWin") : self.confermaDelWin,
        ofr:(typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "opzioniformriga") : self.opzioniformriga,
        rds: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeds") : self.righeds,
        rb: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeRebind") : self.righeRebind,
        rgm: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeGridMostra") : self.righeGridMostra,
        ropt: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "righeOpts") : self.righeOpts,
        fr: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "formriga") : self.formriga,
        mr: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "modelriga") : self.modelriga,
        fo: (typeof strentity !== 'undefined') ? self.getStrentityProp(strentity, "formObj") : self.formObj,
    }
    
    //console.log("riferimenti")
    //console.log(self.righe, rifs.r);
    //console.log(self.righeeliminate, rifs.rel);
    //console.log(self.righedaeliminare, rifs.rdel);
    //console.log("=========fine riferimenti==========")
    return rifs;

}

/**
 * modalita dalla testata grid o form
 */
function getModalita(){
    var self = this;
    if(typeof self.gridcfg === 'undefined') return 'form';
    return self.gridCfg.mode;
}

/**
 * selezione dalla toolbar (solo testata)
 */
function getSelezione(){
    var self = this;
    return self.ts.selezione;
}


function modificaRiga(strentity){
    var self = this;
    
    var rif = self.riferimenti(strentity);

    var dataItem = rif.g.dataItem(rif.g.select()); //<-- da aggiornare
    self.openedit(dataItem, "Modifica", strentity);
}

/**
 * 
 * @param {*} model 
 * @param {*} mode 
 */
function openedit(model, mode, strentity){
    var self = this;
    self.modelriga = angular.copy(model);
    var rif = self.riferimenti(strentity);

    var fo = new self.fgf("", rif.g, null, null, mode, self.formriga, true);

    /**
     * do la possibilità di sovrascrivere la configurazione del form
     */
    if(self.pd.editForm){
        fo = self.pd.editForm(fo);
    }

    rif.fo = fo

    rif.erw.open().center();
}

function righeseldiff(num, strentity){
    var self = this;
    var rif = self.riferimenti(strentity);

    if(typeof rif.g === 'undefined') return true;
    return rif.g.select().length != num;
}
function righeselvuote(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    if(typeof rif.g === 'undefined') return true;
    return rif.g.select().length == 0;
}

//=============== da V2

function disabilitaricaricadettaglio(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    //console.log("rif da disabilitaricaricadettaglio", rif);

    return rif.r.length !== 0 
        || rif.rel.length !== 0
}

function disabilitaSalva(){
    var self = this;
    return (self.getModalita() !== "form");

}


function eliminasel(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);

    _.each(rif.g.select(), function(riga){
        var modello = g.dataItem(riga);
        //self.righeGrid.dataSource.remove(modello);
        rif.rel.push(modello);
        rif.rel = _.uniq(rif.rel, function(riga){return riga.uid});
        rif.rdel = _.filter(rif.rel, function(riga){ return riga.id !== "" && riga.id !== null;});
        rif.r = _.without(rif.r, _.findWhere(rif.r, {uid: modello.uid}));

        self.evidenziarighe(strentity);

        rif.cdw.close();
        rif.erw.close();
    })
}

function evidenziarighe(strentity){
    var self = this;

    var rif = self.riferimenti(strentity);


    _.each(rif.rel, function(riga){
        var rigah = rif.g.tbody.find("tr[data-uid='"+riga.uid+"']");
        rigah.removeClass("nuovorecord modificarecord eliminarecord");
        rigah.addClass("eliminarecord");
    });

    _.each(rif.r, function(riga){
        var rigah = rif.g.tbody.find("tr[data-uid='"+riga.uid+"']");
        rigah.removeClass("nuovorecord modificarecord eliminarecord");
        if(riga.id == "" || riga.id === 0){
            rigah.addClass("nuovorecord");
        } 
        else {
            rigah.addClass("modificarecord");
        }
    })

}

function focusgriglia(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);

    var selezione = rif.g.select();
    var cella = null;
    if(selezione.length){
        var dataItem = rif.g.dataItem(selezione);
        cella = rif.g.tbody.find("tr[data-uid='"+dataItem.uid+"'] td:visible:first");
    }
    else {
        cella = rif.g.tbody.find("tr[role='row']:first td:visible:first");
    }
    console.log("sposto il focus sulla cella", cella);
    //cella = rif.g.tbody.find("tr[role='row']:first td:visible:first");
    rif.g.clearSelection();
    rif.g.current(cella);    
}

function openeditnuovo(strentity){
    var self = this;
    var obj = {};
    var selezione = self.getSelezione()[0];
    var testtid = (typeof selezione !== 'undefined' && selezione !== null) ? selezione.id : 0;
    obj[self.parametri.campotestata] = {
        id: testtid
    }

    self.openedit(obj, "Inserisci", strentity);
}

function openeditTemplate(mode, strentity){
    var self = this;
    var parametri = {};
    var selezione = self.getSelezione()[0];
    var id = typeof selezione !== 'undefined' ? selezione.id : 0;
    parametri[self.parametri.campotestata] = {id: id};
    self.openedit(parametri, mode, strentity);
}
function parametriDettaglioStd(strentity){
    return {
        idtestata: "idtestata",
        wsread: "/" + strentity + "/righetestata"
    }        
};

function osservaSelezione(){
    var self = this;

    /**
     * NOTE
     * originariamente: wssalvamd: "/" + self.parametri.strentity + "/" + self.parametriDettaglio.strentity + "/creamasdet"
     * ci sono più strentity di dettaglio concordare con daniele, in teoria le strentity interessate sono le chiavi in imrighe ed erighe es. imrighe = {[strintity1]: [riga1, riga2, ...]}
     */
    var parametriStd = {
        wssalvamd: "/" + self.parametri.strentity + "/creamasdet", 
        campotestata: "testata",
        campoim: "imrighe",
        campoe: "erighe"
    };

    self.parametri = angular.extend({}, parametriStd, self.parametri);

    

    self.strentitylist = Object.keys(self.parametriDettaglio);

    self.strentitylist.forEach(function(strentity){

        var rif = self.riferimenti(strentity)
        
        self.$scope.$on("kendoWidgetCreated", function(event, widget){
            if(widget === rif.erw[strentity]) {
                widget.bind("close", function(){
                    self.focusgriglia(strentity);
                });
    
                widget.bind("activate", function(){   
                    //console.log(rif.erw[strentity]);             
                    var fe = rif.erw[strentity].element.find(':input:enabled:visible:first');
                    fe.select();
                    fe.focus();
                });
    
                widget.element.keydown(function(e){
                    
                    if(e.key === 'Escape'){
                        widget.close();
                    }
    
                    if(e.altKey){
                        e.preventDefault();
    
                        self.mostrasc = true;
    
                        if(e.key === 'ArrowLeft'){
                            self.vaiprecedente(strentity);
                        }
    
                        if(e.key === 'ArrowRight'){
                            self.vaisuccessivo(strentity);
                        }
    
                        if(e.key === 's'){
                            self.salvaesuccessivo(strentity);
                        }
    
                        if(e.shiftKey){
                            console.log(e.key);
                            if(e.key === 'S'){
                                self.salvariga(strentity);                        
                            }
                        }
    
                        if(e.key === 'a'){
                            rif.ofr.resetModel(); 
                            rif.erw.close()
                        }
    
                        if(e.key === 'e'){
                            rif.cdw.open().center();
                        }
                    }
    
    
                });
    
                widget.element.keyup(function(e){
                    if(e.key === 'Alt'){
                        self.mostrasc = false;
                    }
                });
            }
        });


    })


    
    
    //al cambio della selezione ripopola le grid delle righe
    self.$scope.$watch(function(){
        return self.getSelezione();
    }, function(n , o){
        self.righe = {};
        self.righeeliminate = {};
        self.righedaeliminare = {};
        self.righeGridMostra = {};
        self.righeds = {};
        self.righeOpts = {};
        self.righeRebind = {};

        var testata = {id: 0};

        if(typeof n !== 'undefined'){
            if(n !== null && typeof n[0] !== 'undefined'){
                testata = n[0];
            }
        }
        self.dataItemTestata = testata;


        
        self.strentitylist.forEach(function(strentity){

            var rif = self.riferimenti(strentity)

            rif.r = [];    
            rif.rel = [];
            rif.rdel = [];
            rif.rgm = false;
            var testata = {id: 0};

            var opzAdd = {                    
                serverPaging: false,                    
                pageSize: 999999,
                transport:{
                    read: {
                        url: utils.urls.wsbase + rif.pd.wsread, 
                        data: function(){        
                            var parametri = {};                                        
                            parametri[rif.pd.idtestata] = testata.id;       
                            parametri = angular.extend({}, parametri, rif.pd.fnAdditionalParameters() || {})                               
                            return parametri;                                                            
                        }, 
                        method: 'POST' 
                    }
                }
            };

            

            new self.dsf(strentity, null, self.parametri.codmodulo, opzAdd).inizializza().then(function(ds){
                new self.gcf(strentity, null, self.parametri.codmodulo, null, true).inizializza({pageable: false}).then(function(gridcfg){  
                    rif.rds = ds;
                    gridcfg.dataSource = ds;      
                    gridcfg.dataBound = function(e){
                        var griglia = e.sender;
    
                        var righe = griglia.tbody.find("tr");                   
    
                        griglia.table.on("keydown", function(e){
                            if(e.altKey){
                                e.preventDefault();
                                self.mostrasc = true;
                                if(e.key === 'n'){                                
                                    self.openeditnuovo(strentity);
                                } 
                                if(e.key === 'm'){
                                    var dataItem = griglia.dataItem(griglia.select());   
                                    if(dataItem === null){
                                        self.openeditnuovo(strentity);
                                    }  
                                    else {
                                        self.openedit(dataItem, "Modifica", strentity);
                                    }
                                }
                                if(e.key === 'e'){
                                    if(!self.righeselvuote()){
                                        rif.cdw.open().center();
                                    }
                                }
                            }
                        });
    
                        griglia.table.on("keyup", function(e){
                            if(e.key === 'Alt'){
                                self.mostrasc = false;
                            }
                        });
    
                        righe.dblclick(function(e){                                 
                            var dataItem = griglia.dataItem(e.currentTarget);     
                            self.openedit(dataItem, "Modifica", strentity);
                        });
    
                     
                        self.$timeout(function(){
                            self.sostituiscisalva();
                        });
                        
                    };
                    
                    rif.ropt = angular.extend({}, gridcfg);
                    rif.rb = moment().format();
                    rif.rds.read();
                    rif.rgm = true;
                });
            })
        })
    })

}

function salvaesuccessivo(strentity){
    var self = this;
    var riga = self.salvariga(strentity);
    var rif = self.riferimenti(strentity);
    if(riga !== null){
        self.$timeout(function(){
            var rigah = rif.g.tbody.find("tr[data-uid='"+riga.uid+"']")[0];
            var indice = $(rigah).index();
            var rigasucc = rif.g.tbody.find("tr:eq("+(indice+1)+")");
            if(rigasucc.length === 0){
                self.$timeout(function(){
                    self.openeditnuovo(strentity);
                })
                
            }
            else {
                self.selezioneSinc(rigasucc[0], strentity).then(function(){
                    var modello = rif.g.dataItem(rigasucc[0]);
                    self.openedit(modello, "Modifica", strentity);
                });                        
            }
        }, 300);
    }
}

function salvamastrodettaglio(){
    var self = this;
    var modello = {};

    var testata  = angular.extend({}, self.dataItemTestata);
    if(typeof self.gfi !== 'undefined') testata = angular.extend({}, testata, self.gfi.getmodello());

    modello[self.parametri.campotestata] = JSON.stringify(testata);


    modello[self.parametri.campoim] = {};
    modello[self.parametri.campoe] = {};

    self.strentitylist.forEach(function(strentity){
        var rif = self.riferimenti(strentity);
        modello[self.parametri.campoim][strentity] = rif.r;
        modello[self.parametri.campoe][strentity] = rif.rdel;

    })

    return self.$http({
        method: "POST", 
        url: utils.urls.wsbase + self.parametri.wssalvamd, 
        data: $.param(modello)
    });
}

function salvariga(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    if(self.valida.validaForm(rif.fr)){
        var mode = "crea";
        var actualdata = {};
        if(typeof rif.mr.id !== 'undefined'){
            mode = "modifica";
            actualdata = rif.g.dataItem(rif.g.tbody.find("tr[data-uid='"+rif.mr.uid+"']"))
            for(var x in rif.mr){
                actualdata.set(x, rif.mr[x]);
            }
        }
        else {
            actualdata = rif.g.dataSource.add(rif.mr);
        }
        rif.r.push(actualdata);
        rif.r = _.uniq(rif.r, function(riga){return riga.uid});
        rif.rel = _.without(rif.rel, _.findWhere(rif.rel, {uid: actualdata.uid}));
        rif.rdel = _.without(rif.rdel, _.findWhere(rif.rdel, {uid: actualdata.uid}));

        var riga = rif.g.tbody.find("tr[data-uid='"+actualdata.uid+"']");

        self.evidenziarighe(strentity);

        self.editRigheWin.close();
        return actualdata;
    
    }
    return null;
}

function selezioneSinc(selettore, strentity){
    var self = this;

    var rif = self.riferimenti(strentity);

    var d = self.$q.defer();
    
    var selAtt = self.righeGrid.select();
    rif.g.clearSelection();
    
    rif.g.one("change", function(e){
        var selNuova = rif.g.select();
        if(selNuova !== selAtt){
            d.resolve("cambiata selezione");
        }
    });
    
    rif.g.select(selettore);
    
    return d.promise;
}

function sostituiscisalva(){
    var self = this;

    self.gfi.salvacrea = function(){return self.salvamastrodettaglio();}
    self.gfi.salvamodifica = function(){return self.salvamastrodettaglio();}
}

function toggleedit($event, strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    console.log($event.ctrlKey, $event.key, $event.currentTarget);
    if($event.ctrlKey && $event.key === 'e'){
        console.log('edit');
        self.openedit(rif.g.dataItem($event.currentTarget), "Modifica", strentity)
        $event.preventDefault();
    }
}

function vaiprecedente(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    rif.ofr.resetModel();
    var rigah = rif.g.select();
    rif.erw.close();
    var indice = $(rigah).index();
    var rigaprec = rif.g.tbody.find("tr:eq("+(indice-1)+")");
    if(rigaprec.length){
        self.selezioneSinc(rigaprec[0], strentity).then(function(){
            var modello = rif.g.dataItem(rigaprec[0]);
            self.openedit(modello, "Modifica", strentity);
        });  
    }
    else {
        self.$timeout(function(){
            self.openeditnuovo(strentity);
        })
    }
}

function vaisuccessivo(strentity){
    var self = this;
    var rif = self.riferimenti(strentity);
    rif.ofr.resetModel();
    var rigah = rif.g.select();
    rif.erw.close();
    var indice = $(rigah).index();
    var rigasucc = rif.g.tbody.find("tr:eq("+(indice+1)+")");
    if(rigasucc.length){
        self.selezioneSinc(rigasucc[0], strentity).then(function(){
            var modello = rif.g.dataItem(rigasucc[0]);
            self.openedit(modello, "Modifica", strentity);
        });  
    }
    else {
        self.$timeout(function(){
            self.openeditnuovo(strentity);
        })
    }
}

module.exports = MastroDettaglioMixinV3;