'use strict';

/**
 * Setta il content type di ogni richiesta di tipo post lanciata con $http ad "application/x-www-form-urlencoded".
 * 
 * @class
 * @param {$httpProvider} $http
 * @returns {undefined}
 */
function RunNgHttpLikeJQAjax($http) { 
    $http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8"; 
}

module.exports = RunNgHttpLikeJQAjax;