'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /disegnamodelli
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} DisegnamodelliFunzioniAggiuntive
 */
function DisegnamodelliCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, $filter, $sce, ActionFactory, ErrorManager, FieldFactory, DisegnamodelliFunzioniAggiuntive) {
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = DisegnamodelliFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.ff = FieldFactory;
    self.$scope = $scope;
    self.$filter = $filter;
    self.$sce = $sce;

    self.tabSel = 0;
    self.modellolayout = {};



    self.campiTmp = {//andrà presa da configurazione
        indirizzo: {
            "campoCfg": {
                "key": "indirizzo",
                "tipo": "bssinput",
                "label": "Indirizzo",
                "colonna": {},
                "modello": {
                    "validations": {
                        "required": true
                    }
                }
            },
            "readOnly": false,
            "opzioni": {
                "className": "col-lg-6"
            }
        },
        data: {
            "campoCfg": {
                "key": "data",
                "tipo": "bssdate",
                "label": "Data",
                "colonna": {},
                "modello": {
                    "validations": {
                        "required": true
                    }
                }
            },
            "readOnly": false,
            "opzioni": {
                "className": "col-lg-6"
            }
        },
        beneficiario: {
            "campoCfg": {
                "key": "beneficiario",
                "tipo": "bssinput",
                "label": "Beneficiario",
                "colonna": {},
                "modello": {
                    "validations": {
                        "required": true
                    }
                }
            },
            "readOnly": false,
            "opzioni": {
                "className": "col-lg-6"
            }
        },
        ignora: {
            template: "<div class='clearfix'><br></div>"
        },
        importo: {
            "campoCfg": {
                "key": "importo",
                "tipo": "bssnumeric",
                "label": "Importo",
                "colonna": {},
                "modello": {
                    "validations": {
                        "required": true
                    },
                },  
                
            },
            "readOnly": false,
            "opzioni": {
                "className": "col-lg-6",
                "ngModelElAttrs": {
                    "num-neg": 'false', "num-fract": "2", "num-fixed": 'true',
                },
                
                
            }
        },
        importo_txt: {
            key: "importo_txt",            
            value: null,
            wrapper: ['bsslabel', 'bssbase'],
            template: "<div class='form-control'>{{model.importo_txt}}</div><input type='hidden' ng-model='model.importo_txt' bssn2wobs osserva='model.importo'>",
            templateOptions: {
                label: "Importo in Lettere",
                className: "col-lg-6"
            },
        },
        timbro: {
            "campoCfg": {
                "key": "timbro",
                "tipo": "bsswysiwyg",
                "label": "Timbro",
                "colonna": {},
                "modello": {
                    "validations": {
                        "required": true
                    }
                }
            },
            "readOnly": false,
            "opzioni": {
                "className": "col-lg-12"
            }
        }

    }

    self.configurazione = {
        sfondo: require("../images/assegno.png"),
        campi: []
    };

   self.generacampi();
}


DisegnamodelliCtrl.prototype.generacampi = generacampi;
DisegnamodelliCtrl.prototype.generamodellolayout = generamodellolayout;
DisegnamodelliCtrl.prototype.campogetkey = campogetkey;
DisegnamodelliCtrl.prototype.campogetlabel = campogetlabel;
DisegnamodelliCtrl.prototype.listakeys = listakeys;
DisegnamodelliCtrl.prototype.altricampi = altricampi;
DisegnamodelliCtrl.prototype.settalayout = settalayout;
DisegnamodelliCtrl.prototype.mostradiv = mostradiv;
DisegnamodelliCtrl.prototype.getValore = getValore;
DisegnamodelliCtrl.prototype.getTipo = getTipo;

function getTipo(chiave){
    var self = this;
    return _.findWhere(self.configurazione.campi, {key: chiave}).type;
}

function getValore(chiave){
    var self = this;
    var tipo = self.getTipo(chiave);
    var valore = self.modello[chiave];
    if(tipo === 'bssdate') valore = moment(valore).format("DD/MM/YYYY");
    if(tipo === 'bsswysiwyg') valore = self.$sce.trustAsHtml(valore);
    return valore;
}

function generacampi(){
    var self = this;
    //self.$http({method: 'GET', url: utils.urls.wsbase + "/cfgutenti/find/CFGFormModelloAssegnoTest"})
    self.$q.when({"data": [{configurazione: JSON.stringify(self.campiTmp)}]})
        .then(function(resp){
            self.campiOriginali = JSON.parse(resp.data[0].configurazione);
            self.generamodellolayout();
            self.listacampi = self.listakeys();
            _.each(self.campiOriginali, function(campo){
                if(campo.campoCfg){
                    self.configurazione.campi.push(new self.ff(campo.campoCfg, campo.readOnly, campo.opzioni).opzioni);
                }
                else {
                    self.configurazione.campi.push(campo);
                }
            })
        })
}

function generamodellolayout(){
    var self = this;
    var contatore = 0;
    _.each(self.campiOriginali, function(campo, indice){
        var chiave = self.campogetkey(campo)
        if(typeof chiave !== 'undefined'){
            self.modellolayout[chiave] = {
                x: 0,
                y: 25*contatore,
                w: 150,
                h: 25
            };
            contatore++;
        }
    });
}

function campogetkey(cfg){
    return cfg.campoCfg ? cfg.campoCfg.key : cfg.key;
}

function campogetlabel(cfg){
    if(cfg.campoCfg || cfg.templateOptions){
        return cfg.campoCfg ? cfg.campoCfg.label : cfg.templateOptions.label;    
    }
    return null;
}

function listakeys(){
    var self = this;
    var lista = _.filter(_.map(self.campiOriginali, function(campo){
        if(campo.campoCfg || campo.templateOptions){
            return {key: self.campogetkey(campo), label: self.campogetlabel(campo)};
        }
    }), function(campo){
        return typeof campo !== 'undefined' && campo !== null;
    });
    lista.unshift({key: null, label: ''});
    return lista;
}

function altricampi(key){
    var self = this;
    return _.filter(self.listacampi, function(campo){
        return key !== campo.key;
    })
}

function settalayout(prop, campo, copiada){
    var self = this;
    console.log("copio", prop, "per", campo, "da", copiada);
    self.modellolayout[campo][prop] = self.modellolayout[copiada[prop]][prop];
}

function mostradiv(chiave){
    var self = this;
    var sfondo = angular.element("#sfondomodello");
    var possfondo = {
        x: sfondo.prop("offsetLeft"),
        y: sfondo.prop("offsetTop")
    }

    var dati = self.modellolayout[chiave];

    var stile = {
        position: 'absolute', 
        left: Number(possfondo.x)+Number(dati.x || 0), 
        top: Number(possfondo.y)+Number(dati.y || 0),
        width: Number(dati.w || 0),
        height: Number(dati.h || 0),
        "z-index": 999,
        display: 'block'
    };
    
    return stile;
}

module.exports = DisegnamodelliCtrl;
