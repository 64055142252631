'use strict';

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");


/**
 * Campo di tipo templateasis legge dalla column il template
 * 
 * @class  
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function TemplateAsIs(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'templateasis', 
        //extends: 'bssselect',
        template: require("./templateasis.html"),
        controller: ["$scope", "$sce", "$compile", "$rootScope", "$filter", "$timeout", "$http", "$state", "ErrorManager", "DSFactory", require("./TemplateAsIsController")],
        defaultOptions: {
            className: 'col-xs-12',
            ngModelElAttrs: {
                'cssCategory': 'templateasis'
            },
        }
    }));
}

module.exports = TemplateAsIs;