'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /articoli
 * 
 * @todo
 * atform:
 * {
    "raggruppamento": {
      "key": "dtl",
      "label": "Dettaglio"
    },
    "field": "articolodtl",
    "visibleFormModifica": true,
    "visibleFormInserisci": false,
    "template": "<div class='col-lg-12' ng-if='moduloCtrl.ts.selezione[0]'><div class='col-lg-6'><prezziarticolo idarticolo='moduloCtrl.ts.selezione[0].id' /></div><div class='col-lg-6'><compatibility idarticolo='moduloCtrl.ts.selezione[0].id' /></div></div>"
  }

  atgrid
  {
    "field": "articolodtl",
    "hidden": true,
    "visibleFormModifica": true,
    "visibleFormInserisci": false,
    "showrowfilter": false,
    "template": "#='-'#"
  }

  atmodel
  "articolodtl": {
      "editable": true,
      "insertable": false,
      "duplicable": false,
      "type": "string",
      "xtype": "templateasis"
      "validations": {
        "nullable": true
      }
    }
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} ArticoliFunzioniAggiuntive
 */
function ArticoliCtrl($http, $q, $timeout, $interval, $state, $stateParams, DSF, GF, GCF, ToolbarS, idstato, $scope, $compile, ActionFactory, ErrorManager, ArticoliFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$interval = $interval;
    self.$q = $q;
    self.fa = ArticoliFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.em = ErrorManager;
    self.moment = moment;
    self.$scope = $scope;
    self.$compile = $compile;

    self.nascondifiltri = true;

    self.listaEntity = ["marca", "modello", "serie", "categoria"];

    //entity in cascata relazione padre figlio
    self.regoleCascata = {
        marca: "modello",
        modello: "serie",
        serie: "categoria"
    }

    //entity in cascata relazione figlio padre, inutilizzata
    self.regoleCascataRev = Object.keys(self.regoleCascata).reduce(function(obj, chiave){
        var estensione = {};
        estensione[self.regoleCascata[chiave]] = chiave;
        return angular.extend({}, obj, estensione);
    }, {})

    

    //strentity corrispondenti alle entity
    self.mappeStrentity = {
        marca: "tmarcheauto",
        modello: "tmodelliauto",
        serie: "tserieauto",
        categoria: "tcatmerrdc"
    }

    self.labelInput = {
        marca: {
            label: 'Marca',
            multi: 'marche'
        },
        modello: {
            label: 'Modelli',
            multi: 'modello'
        },
        serie: {
            label: 'Serie',
            multi: 'serie'
        },
        categoria: {
            label: 'Categoria',
            multi: 'categorie'
        },
    }

    self.mappeLabel = {
        marca: function(obj){
            return obj.codice + " - " + obj.descrizione;
        },
        modello: function(obj){
            return obj.descrizione;
        },
        serie: function(obj){
            return obj.serie;
        },
        categoria: function(obj){
            return obj.codice + " - " + obj.descrizione;
        }
    }
    
    self.filtriEntity = {
        marca: function(){
            return {};
        },
        modello: function(){
            return {idmarca: self.marca.id};
        },
        serie: function(){
            return {idmodello: self.modello.id};
        },
        categoria: function(){
            return {idserie: self.serie.id};
        }
    }

    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = angular.extend({}, $stateParams, {strentity: "articolirdc", codmodulo: "BRWArtRD"+self.$sp.catstat});

    // console.log("parametri", self.parametri);

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype);

    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.$http({
        url: utils.urls.wsbase + "/tcatstastd/" + self.$sp.catstat 
    }).then(function(resp){
        self.catstat = resp.data;

        self.inizializza({
            //group: [{field: 'catmer'}],
            //serverGrouping: true,
            
            parametriRead: function(){
                console.log("parametri read", self.filtri);
                var parRicerca = {catstat: self.$sp.catstat};
    
                if(typeof self.filtri !== 'undefined' && self.filtri !== null && self.filtri.$valid && self.filtri.$submitted){
                    parRicerca = angular.extend({}, parRicerca, {
                        idmarca: (![null, undefined].includes(self.marca)) ? self.marca.id : undefined,
                        idmodello: (![null, undefined].includes(self.modello)) ? self.modello.id : undefined,
                        idserie: (![null, undefined].includes(self.serie)) ? self.serie.id : undefined,
                    });
                    if(self.categoria !== null && typeof self.categoria !== 'undefined'){
                        parRicerca = angular.extend({}, parRicerca, {idcatmer: self.categoria.id});
                    }
                }
    
                if(typeof self.codartforn !== 'undefined' && self.codartforn !== null && self.codartforn.length){
                    parRicerca = angular.extend({}, parRicerca, {codartforn: self.codartforn});
                }

                if(typeof self.ktype !== 'undefined' && self.ktype !== null && self.ktype.length){
                    parRicerca = angular.extend({}, parRicerca, {ktype: self.ktype});
                }                
    
                return parRicerca;
            }
        }, {});
    })

    
    
    

    /**
     * gestione tooltip non va il nascondi, probabilmente perchè le tooltip sono collegate alle righe della griglia, queste vengono ridisegnate
     * (ad es. al ridimensionamento della finestra) e si perde il riferimento alla griglia
     */
    $scope.$on("kendoWidgetCreated", function(event, widget){
        if(widget === self.griglia){
            self.griglia.bind("dataBound", function(e){
                var griglia = e.sender;
                var righe = griglia.tbody.find("tr");                 
                var tps = {}
                var debouncersmanager = {}

                // righe.mouseenter(function(e){
                //     var dataItem = griglia.dataItem(e.currentTarget);
                //     var id = dataItem.uid;
                //     console.log("registro debouncer per ", id);
                //     debouncersmanager[id] = self.$timeout(function(){
                //         console.log("sei stato per più di 2 secondi sulla riga", dataItem);

                //         var tplstr = '<detart idarticolo="'+ dataItem.id +'" intooltip="true" />';

                //         var tpl = kendo.template(tplstr)
                //         tps[id] = $(e.currentTarget).kendoTooltip({
                //             autoHide: false,
                //             width: self.griglia.table.width(),
                //             content: tpl
                //         }).data("kendoTooltip");

                //         console.log("tps", tps[id])

                //         Object.keys(tps).forEach(function(idtp){
                //             if(Number(idtp) !== Number(id)) {
                //                 console.log("nascondo tp con id", idtp, id);
                //                 tps[idtp].destroy()
                //                 self.$timeout.cancel(debouncersmanager[idtp])
                //             } else {
                //                 console.log("mostro tp con id", id);
                //                 tps[id].show()
                //                 self.$compile(tps[id].popup.element)(self.$scope);
                //                 self.$scope.$digest();
                //                 self.$timeout.cancel(debouncersmanager[id])
                //             }
                //         })
                        

                        

                //         console.log("template kendo", tpl)
                //     }, 2500);
                // })
                
                // righe.mouseleave(function(e){
                //     var dataItem = griglia.dataItem(e.currentTarget);
                //     var id = dataItem.id;
                //     if(typeof tps[id] !== 'undefined' && tps[id] !== null){
                //         tps[id].destroy();
                //     }                    
                //     if(typeof debouncersmanager[id] !== 'undefined'){
                //         console.log("cancello debouncer per", debouncersmanager[id]);
                //         self.$timeout.cancel(debouncersmanager[id])
                //     }
                // })
            })            
        }
    })
    
}

ArticoliCtrl.prototype.disabledByEntity = disabledByEntity;
ArticoliCtrl.prototype.ridimensionaGriglia = ridimensionaGriglia;
ArticoliCtrl.prototype.handleBlurcercaauto = handleBlurcercaauto;
ArticoliCtrl.prototype.getGridResCercaAuto = getGridResCercaAuto;
ArticoliCtrl.prototype.setcercaauto = setcercaauto;
ArticoliCtrl.prototype.resetcercaauto = resetcercaauto;

/**
 * 
 * Indica se il focus si trova su un altro campo
 * Utile per vietare di svuotare un campo quando il focus è su un campo figlio
 * Evita il problema della ricerca per un entity con id entity padre undefined
 * 
 * @param {*} entity 
 * @return bool
 */
function disabledByEntity(entity){

    var self = this;
    var altreentity = self.listaEntity.filter(function(e){return e !== entity})
    return altreentity.includes(document.activeElement.id)

}

function ridimensionaGriglia(){
    var self = this;
    self.$timeout(function(){
        window.resizeGrid()
    }, 200)
}

function handleBlurcercaauto(){
    var self = this;

    self.$timeout(function(){

        if(self.disambiguaWin.options.visible){
            self.disambiguaWin.close();
            angular.element("#cercaauto").focus();
        } else {

            var term = self._cercaauto;
            var strentity = 'tserieauto'

            self.$http({
                method: "POST",
                url: utils.urls.wsbase + "/"+ strentity +"/grid",
                data: $.param({
                    page: 1,
                    take: 2,
                    skip: 0,
                    pageSize: 2,
                    search: term
                })
            }).then(function(resp){
                var res = resp.data.results;

                if(res.length === 1){//un solo risultato lo setto
                    self.setcercaauto(res[0])
                }
                if(res.length !== 1){
                    if(res.length === 0){//0 risultati svuoto la ricerca
                        term = "";
                        self.em.showT("RICERCAARTICOLI.NORES", "warning");
                    }
                    //mostro la grid per una ricerca più approfondita
                    self.getGridResCercaAuto(term, strentity);
                    self.disambiguacercaautoWin.open().center();
                }
            })
        }
    })
}

function getGridResCercaAuto(term, strentity){
    var self = this;


    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        console.log("selezionato da griglia ricerca rapida auto", dataItem)
        self.setcercaauto(dataItem)
        self.disambiguacercaautoWin.close();
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, "BRWSerAuto", null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, "BRWSerAuto", null, true).inizializza().then(function (gridcfg) {

            ds.transport.parameterMap = function (data, type) {
                var newdata = angular.extend({}, data, { search: term });
                return newdata
            }

            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridcercaauto !== 'undefined') {                                        
                    var griglia = e.sender;
                    
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridcercaautoOpts = angular.extend({}, gridcfg);
            self.gridcercaautoRebind = moment().format();            
        })
    });
}

function setcercaauto(val){
    var self = this;
    self.cercaauto = val;
    self._cercaauto = self.mappeLabel.marca(val.modello.costruttore) + " - " + self.mappeLabel.modello(val.modello) + " - " + self.mappeLabel.serie(val) ; //tmp
    self.setByEntity("marca", val.modello.costruttore);    
    self.setByEntity("modello", val.modello);    
    self.setByEntity("serie", val);
    self.cercaautoDisabilita = true;
    self.nascondifiltri = false;
    self.$timeout(function(){
        self.filtraArticoli();        
    })
}

function resetcercaauto(){
    var self = this;
    self.cercaauto = null;
    self._cercaauto = null;
    self.cercaautoDisabilita = false;
}


ArticoliCtrl.prototype.handleBlur = handleBlur;
ArticoliCtrl.prototype.getRes = getRes;
ArticoliCtrl.prototype.getGridRes = getGridRes;
ArticoliCtrl.prototype.setByEntity = setByEntity;
ArticoliCtrl.prototype.resetByEntity = resetByEntity;
ArticoliCtrl.prototype.resetInCascata = resetInCascata;
ArticoliCtrl.prototype.abilitaCampoEntity = abilitaCampoEntity;
ArticoliCtrl.prototype.listaParentEntity = listaParentEntity;
ArticoliCtrl.prototype.allParentDisabled = allParentDisabled;
ArticoliCtrl.prototype.filtraArticoli = filtraArticoli;
ArticoliCtrl.prototype.resetFiltraArticoli = resetFiltraArticoli;
ArticoliCtrl.prototype.refform = refform;

/**
 * @description
 * Gestisce l'uscita da un campo
 * il campo deve avere attributo name = {entity} e come ng-model = _{entity} dovrà inoltre esistere un campo con ng-model = {entity}
 * Richiama inizialmente {@see getRes}
 * Questo basta a sapere se:
 *  1. c'è un solo risultato, posso impostarne il valore
 *  2. non c'è nessun risultato, avviso l'utente e svuoto il termine di ricerca
 *  3. il numero di risultati è diverso da uno (anche quando non c'è risultato), apro la grid per permettere all'utente una ricerca più approfondita
 * @param {*} entity 
 */
function handleBlur(entity){
    var self = this;

    self.$timeout(function(){

        if(self.disambiguacercaautoWin.options.visible){
            self.disambiguacercaautoWin.close();
            angular.element("#"+entity).focus();
        } else {
            var term = self["_"+entity];
    
            self.entityDisambigua = entity;
    
            self.getRes(term, entity).then(function(res){
                if(res.length === 1){//un solo risultato lo setto
                    self.setByEntity(entity, res[0]);
                }
                if(res.length !== 1){
                    if(res.length === 0){//0 risultati svuoto la ricerca
                        term = "";
                        self.em.showT("RICERCAARTICOLI.NORES", "warning");
                    }
                    //mostro la grid per una ricerca più approfondita
                    self.getGridRes(term, entity);
                    //console.log("|========> sto per aprire disambiguawin, stato di disambiguacercaautowin", self.disambiguacercaautoWin)            
                    
                    self.disambiguaWin.open().center();
                    
                }
            })
        }
    })
    
}

/**
 * @description
 * Ottiene i risultati dell'entity per il termine di ricerca.
 * Richiesti campi searchable sull'entity (lato server).
 * Si limita a prelevare i primi 2 risultati. 
 * @param {*} term 
 * @param {*} entity 
 */
function getRes(term, entity){
    var self = this;
    
    
    var datapar = angular.extend({}, {
        page: 1,
        take: 2,
        skip: 0,
        pageSize: 2,
        search: term
    }, self.filtriEntity[entity]() || {})

    // var filtriCalcolati = self.calcolaFiltri(entity);
    // console.log("calcolo i filtri per ", entity, JSON.stringify(filtriCalcolati))
    // if(filtriCalcolati !== []){
    //     datapar = angular.extend({}, datapar, self.wrapFiltri(null, filtriCalcolati))
    // }

    var strentity = self.mappeStrentity[entity];

    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        data: $.param(datapar)
    }).then(function(resp){
        return resp.data.results;
    })
}

/**
 * @description
 * aggiorna il valore dell'entity nel form, disabilita e svuota gli altri campi richiamando {@see resetInCascata}
 * @param {*} entity 
 * @param {*} val 
 */
function setByEntity(entity, val){
    var self = this;
    self[entity] = val;
    self["_"+entity] = self.mappeLabel[entity](val);
    self[entity+"Disabilita"] = true;
    self.resetInCascata(entity);
    var nextentity = self.regoleCascata[entity];
    if(nextentity !== null && nextentity !== undefined){
        var nextel = document.getElementById(nextentity);
        var p = self.$interval(function(){
            if(!nextel.disabled){
                nextel.focus()
                self.$interval.cancel(p)
            }
            
        })        
    }
}

/**
 * @description
 * svuota un campo e svuota in cascata i campi figli {@see resetInCascata}
 * @param {*} entity 
 */
function resetByEntity(entity){
    var self = this;
    self[entity] = null;
    self["_"+entity] = null;
    self[entity+"Disabilita"] = false;
    self.resetInCascata(entity);
}

/**
 * @description
 * Inizializza datasource e grid per l'entity 
 * @param {*} term 
 * @param {*} entity 
 */
function getGridRes(term, entity){
    var self = this;

    var strentity = self.mappeStrentity[entity];

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setByEntity(entity, dataItem)
        self.disambiguaWin.close();
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, "RDCRA", null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, "RDCRA", null, true).inizializza().then(function (gridcfg) {

            ds.transport.parameterMap = function (data, type) {
                var newdata = angular.extend({}, data, { search: term }, self.filtriEntity[entity]());
                return newdata
            }

            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self['grid'+entity] !== 'undefined') {                                        
                    var griglia = e.sender;
                    
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self['grid'+entity+'Opts'] = angular.extend({}, gridcfg);
            self['grid'+entity+'Rebind'] = moment().format();            
        })
    });
}

/**
 * @description
 * Svuota e disabilita/abilita i campi figli
 * @param {*} entity 
 */
function resetInCascata(entity){
    var self = this;
    var figlioDiretto = self.regoleCascata[entity];
    if(typeof figlioDiretto !== "undefined"){
        self["_"+figlioDiretto] = null;
        self[figlioDiretto] = null;

        //il campo di entity va disabilitato se anche un solo campo padre non è disabilitato
        self[figlioDiretto+"Disabilita"] = !self.abilitaCampoEntity(entity);
        self.resetInCascata(figlioDiretto);
    }    
}

/**
 * @description
 * Ritorna true se tutti gli elementi padre di entity sono disabilitati, false altrimenti
 * @param {*} entity 
 * @returns {bool}
 */
function abilitaCampoEntity(entity){
    var self = this;    
    return self.allParentDisabled(entity)
}

function listaParentEntity(entity, arr){
    var self = this;
    var padre = self.regoleCascataRev[entity];
    
    if(typeof padre !== 'undefined'){
        arr.push(padre);
        self.listaParentEntity(padre, arr);
    }
    
    
    return arr;
}

function allParentDisabled(entity){
    var self = this;
    return self.listaParentEntity(entity, []).reduce(function(esito, padre){
        return esito && self[padre+"Disabilita"]
    }, true)
}

function filtraArticoli(){
    var self = this;
    self.$timeout(function(){
        self.filtri.$setSubmitted();
        self.griglia.dataSource.read();
    })
    
}

function resetFiltraArticoli(){
    var self = this;
    self.resetByEntity("marca");
    self.filtri.$setUntouched();
}

/**
 * @todo 
 * Se ho l'id articolo e manca la tab 'dettaglio' aggiungerla
 */
function refform(){
    var self = this;
    //var selettore = "[model='moduloCtrl.gridCfg.form.modello']"
    //var scopeform = angular.element(selettore).scope();
    //console.log(scopeform, scopeform.$$childHead.fields[0].data.tabs, self.ts.selezione);
    if(self.ts.selezione.length === 1){
        var idarticolo = self.ts.selezione[0].id;
    }
}



module.exports = ArticoliCtrl;
