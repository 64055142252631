'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

/**
 * @type {MappaImport}
 */
var mappaimportstd = require("./mappe");


/**
 * Controller relativo al template della route /importstd
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} ImportstdFunzioniAggiuntive
 */
function ImportstdCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, GCF, ToolbarS, idstato, $scope, ActionFactory, FieldFactory, Valida, ErrorManager, ImportstdFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = ImportstdFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.ff = FieldFactory;
    self.em = ErrorManager;
    self.valida = Valida;

    $scope.updateFiles = function(files) {
      self.$timeout(function(){
        self.files = files;
      })      
    };

    self.entity = self.$sp.entity;

    /**
     * @type {MappaturaImport}
     */
    self.mappata = mappaimportstd[self.entity];

    new self.dsf("filemanager/"+self.mappata.chiavepath+"", null, null, {}).inizializza().then(function(ds){
      new self.gcf("filemanager/"+self.mappata.chiavepath+"", null, null, {}, true).inizializza({}).then(function(gridcfg){
          gridcfg.dataSource = ds;
          //gridcfg.filterable = false;
          self.gridOpts = angular.extend({}, gridcfg);
          self.gridRebind = moment().format();
          ds.read();
      })
    });

    new self.dsf("filemanager/"+self.mappata.chiavepath+"_LOG", null, null, {}).inizializza().then(function(ds){
      new self.gcf("filemanager/"+self.mappata.chiavepath+"_LOG", null, null, {}, true).inizializza({}).then(function(gridcfg){
          gridcfg.dataSource = ds;
          //gridcfg.filterable = false;
          self.grid2Opts = angular.extend({}, gridcfg);
          self.grid2Rebind = moment().format();
          ds.read();
      })
    });

    
    
}

ImportstdCtrl.prototype.salva = salva;
ImportstdCtrl.prototype.uploadFiles = uploadFiles;

function uploadFiles(){
  var self = this;
  var fd = new FormData();

    /**
     * 
     * Data una lista di file (non è un array) presa da un input:file controlla quali file sono leggibili e quali no
     * Ritorna in una promise un array di booleani. Il booleano all'indice i-esimo indica se il rispettivo file nella lista
     * è leggibile.
     * Se FileReader non è supportato dal browser dell'utente considero leggibili tutti i file. In questo caso l'array di 
     * booleani contiene solo valori a true.
     * Es. la response della promise ritornata è [true, false, true], in questo caso il secondo file della lista non è leggibile
     * 
     * @param {*} formFiles - lista di file fa campo input:file multiple true
     * @return {Promise<Array<boolean>>}
     */
    function checkFileAvailability(formFiles){
      var files = Array.prototype.map.call(formFiles, angular.identity);
      
      var promises = files.map(function(file){return true});

      if("FileReader" in window){
        promises = files.map(function(file){
          return new Promise(function(res){
            var fr = new FileReader();
            fr.onload = function(e){res(true);}
            fr.onerror = function(e){if(e.srcElement.error.name === 'NotReadableError'){console.log("file non leggibile", file.name)}; res(false);}
            fr.readAsText(file)
          })
        });
      }

      return Promise.all(promises);
    }

    checkFileAvailability(self.files).then(function(resps){
      var filenonvalidi = [];
      var files = Array.prototype.map.call(self.files, angular.identity);
      files.forEach(function(file, indice){
        if(resps[indice]){//il  file all'indice è leggibile
          fd.append("file["+indice+"]", file);
        }        
        else {//il file all'indice non è leggibile
          filenonvalidi.push(file.name);
        }        
      })
      if(filenonvalidi.length){//almeno un file non leggibile chiedo all'utente se procedere solo per i file validi
        if(confirm("I seguenti file non risultano leggibili: " + filenonvalidi.join(", ") + ". Vuoi comunque procedere con gli altri file?")){
          carica(fd);
        }
      } else {//tutti i file validi, li carico
        carica(fd);
      }
      
    })
    
    function carica(fd){
      self.$http.post(utils.urls.wsbase + "/upload/"+self.mappata.chiavepath+"", fd, {
        withCredentials: true,
        headers: {'Content-Type': undefined },
        transformRequest: angular.identity
      }).success(function(resp){
        self.$http({
          url: utils.urls.wsbase + "/"+self.mappata.urlimport,
          method: "GET"
        }).then(function(resp){
          self.em.showT("IMPORTSTD.SUCCESSUPLOAD", "success")
          self.$state.reload();
        })      
      }).error(function(err){
        self.em.showT("IMPORTSTD.ERRORUPLOAD", "error")
        self.$state.reload();
      });
      console.log("procedo con l'upload dei file", self.files);
    }

    
}

function salva(pathcode, filename, mime){
  var self = this;
  self.$http({
    method: 'get',
    url: utils.urls.wsbase + "/download/"+pathcode+"/"+filename,
    headers: {type: 'application/octet-stream'},
    responseType: 'arraybuffer'
  }).then(function(resp){
    var file = new Blob([resp.data], { type: mime });
    saveAs(file, filename);
  })
}

module.exports = ImportstdCtrl;