'use strict';

/**
 * Applica la validazione all'uscita dal campo
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function ValidatePolicy(formlyConfig, formlyValidationMessages){    
    formlyConfig.extras.validateOnModelChange = true;
}

module.exports = ValidatePolicy;