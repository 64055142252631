'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @param {type} FieldFactory
 * @returns {undefined}
 */
function BSSLaumController($scope, $rootScope, $filter, $timeout, $http, $sce, $state, ErrorManager, DSF, FieldFactory, Valida){                

    //FUNZIONA SOLO PER SELEZIONE SINGOLA
    $scope.dsf = DSF;
    
    var modello = $scope.options.data.modello;

    $scope.idml = {};
    $scope.idml[$scope.options.key] = null;
    $scope.modellaum = {};
    $scope.modellaum[$scope.options.key] = [];

    if(typeof $scope.model[$scope.options.key] !== 'undefined' && $scope.model[$scope.options.key] !== null){
        if($scope.model[$scope.options.key].id){
            $scope.idml[$scope.options.key] = $scope.model[$scope.options.key].id;
            $scope.modellaum[$scope.options.key][0] = $scope.model[$scope.options.key].um.id;
        }
    }
    
    //console.log("modello multiselect: ", modello);
    
//    var eccezione = _.some(["strentity", "codmodulo", "datatextfield", "datavaluefield"], function(el){
//        return (typeof modello[el] === 'undefined');
//    });
//    
//    if(eccezione){
//        //var messaggio = "Il campo '" + $scope.to.label + "' è di tipo combo, il modello dovrebbe avere: 'strentity', 'codmodulo', 'datatextfield', 'datavaluefield'. Vedi console per ulteriori dettagli" 
//        //ErrorManager.show(messaggio, "error");
//        var messaggioConsole = "Configurazione errata: " + JSON.stringify(modello);
//        ErrorManager.showT("FORMBUILDER.COMBOCFGERROR", "error", {campo: $scope.to.label});
//        throw messaggioConsole;
//       
//    }
    
    
    $scope.multisel = {
        strentity: modello.strentity,
        codmodulo: modello.codmodulo,
        codapp: modello.codapp || 'aaa',
        dataTextField: modello.datatextfield,
        dataValueField: modello.datavaluefield,
        itemTemplate: modello.itemtemplate || "#=" + modello.datatextfield + "#",
        footerTemplate: modello.footertemplate || "<div class='bssmultiselectFooterWrapper'><span class='bssmultiselectFooter'>Stai visualizzando <b>#= Math.min(instance.dataSource.data().length, instance.dataSource.pageSize()) #</b> di  <b>#= instance.dataSource.total() #</b> elementi.</span></div>",
        caricamento: true,
        maxSelectedItems: modello.maxSelectedItems || null,
        opzioniagg: modello.opzioniagg || {},
        onSelect: modello.onSelect || null,
        onDeselect: modello.onDeselect || null,
        onChange: modello.onChange || null,
        groupField: modello.groupField || null,
        readUrl: modello.readUrl || null,
        dataRead: modello.dataRead || null,
        modelOverride: modello.modelOverride || null,
        height: 325,
        externalUrl: modello.externalUrl || null,
        externalUrlCfg: modello.externalUrlCfg || {url: 'moduli', parametri: {strentity:"unitamisura", codmodulo: "BSGTUM", mode: "crea", view: "iframe", forceToolBar: true}},
        //decommentare non appena il webservice /indicedaid sarà attivo
        virtual: modello.virtual || {
            itemHeight: 26,
            //mapValueTo: 'dataItem',
            valueMapper: function(options){
                var parametri = {};
                parametri[modello.datavaluefield] = options.value[modello.datavaluefield];
                $http({
                    url: utils.urls.wsbase + "/" + modello.strentity + "/indicedaid",
                    data: $.param(parametri)
                    //se mapValueTo vale dataItem (di default index)
//                    url: utils.urls.wsbase + "/" + modello.strentity + "/cerca",
//                    data: $.param(parametri),
//                    method: "POST",
                }).then(function(response){
                    console.log("response valuemapper");
                    options.success(response.data); 
                });
            }
        },        

        //se true aggiunge la voce di default 'tutti' NON FUNZIONA
        //voceTutti: modello.voceTutti
    }; 
    
    if($scope.multisel.externalUrlCfg){
        $scope.multisel.externalUrl = $state.href($scope.multisel.externalUrlCfg.url,  $scope.multisel.externalUrlCfg.parametri);
    } 
    
    if($scope.multisel.groupField){ 
        $scope.multisel.opzioniagg.group = {field: $scope.multisel.groupField};
    }
    
    if($scope.multisel.readUrl){
        $scope.multisel.opzioniagg.transport = {read: { url: $scope.multisel.readUrl, type: 'POST' } };       
        if($scope.multisel.dataRead){
            $scope.multisel.opzioniagg.transport.read.data = $scope.multisel.dataRead;       
        }
    }
    
    if($scope.multisel.modelOverride){
        $scope.multisel.opzioniagg = angular.extend({}, $scope.multisel.opzioniagg, {
            model: $scope.multisel.modelOverride 
        });
    } 
    
    var opzioni = {
        itemTemplate: $scope.multisel.itemTemplate,
        footerTemplate: $scope.multisel.footerTemplate,
        tagTemplate: $scope.multisel.itemTemplate,
        dataTextField: $scope.multisel.dataTextField,
        dataValueField: $scope.multisel.dataValueField,
        filter: $scope.multisel.filter || "contains",
        autoBind: true,
        externalUrl: $scope.multisel.externalUrl,
        //minLength: 0, //per mostrare le opzioni anche senza inserire niente occorre rimuovere minLength, impostandolo a 0 non funziona anche se la documentazione dice che di default vale 0
        maxSelectedItems: 1,//$scope.multisel.maxSelectedItems,
        //virtual: $scope.multisel.virtual,
        height: $scope.multisel.height,
    }; 
    
    if(_.isFunction($scope.multisel.onSelect)){
        opzioni.select = function(e){
            $scope.multisel.onSelect(e);
        }
    }

    if(_.isFunction($scope.multisel.onDeselect)){
        opzioni.deselect = function(e){
            $scope.multisel.onDeselect(e);
        }
    }


    if(_.isFunction($scope.multisel.onChange)){
        opzioni.change = function(e){
            $scope.multisel.onChange(e);
        }
    } 

    /*
    $scope.multisel.opzioniagg = angular.extend({}, $scope.multisel.opzioniagg, {
        schema: {
            parse: function(data){
                console.log("parse bsslaum", data);
                data.results = _.map(data.results, function(risultato) {
                    if(typeof risultato.um === 'undefined'){
                        risultato = {
                            id: 0,
                            um: {
                                um: risultato
                            }
                        }
                    }
                    return risultato
                });
                return data;
            }
        }
    })
    */
              
    new DSF($scope.multisel.strentity, $scope.multisel.codapp, $scope.multisel.codmodulo, $scope.multisel.opzioniagg).inizializza().then(function(kendoDS){                
        //$scope.multisel.opzioni.dataSource = kendoDS;  

        //opzioni.dataTextField = "um.um.simbolo";
        //opzioni.itemTemplate = "#= typeof um.um.simbolo !== 'undefined' ? um.um.simbolo : '' #"

        opzioni.clearButton = false;

        opzioni.select = function(e){
            var elsel = e.item;
            var w = e.sender;
            var datosel = w.dataItem(elsel);
            console.log("elemento selezionato: ", elsel, datosel);
            var id = $scope.idml[$scope.options.key] ? $scope.idml[$scope.options.key] : 0; 
            $scope.model[$scope.options.key] = {id: id, um : { um: datosel}}
        };


        opzioni.deselect = function(e){
            var w = e.sender;
            $timeout(function(){
                var selezione = $scope.modellaum[$scope.options.key];
                if(selezione === null){
                    $scope.model[$scope.options.key] = null;
                }
            });
        }

        opzioni.open = function(e){
            var w = e.sender;
            w.dataSource.read();
        }

        opzioni.dataSource = kendoDS;
        //console.log("kendoDS: ", kendoDS);
        $scope.multisel.opzioni = opzioni;
        $scope.multisel.caricamento = false;

        
    });

    $scope.apriLinkEsterno = function(){  

        $rootScope.genericwin.content("<iframe src='"+$scope.multisel.externalUrl+"' width='99%' height='99%' />");
        $rootScope.genericwin.open().center();
    }
}

module.exports = BSSLaumController;