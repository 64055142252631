'use strict';

/**
 * Direttiva da aggiungere a un elemento della pagina. Gestisce l'interazione utente e l'età del token
 * 
 * @class
 * @param {type} $interval
 * @param {type} LoginService
 * @param {type} ErrorManager.notifier.
 * @returns {AFKDir.AFKDirAnonym$0}
 */
function BssPreferitiDir(){
    return {
        template: require("../templates/boxbsspreferiti.html"),
        restrict: 'E',   
        scope: {
            "indropdown": "="
        }     
    }
}




module.exports = BssPreferitiDir;