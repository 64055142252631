'use strict';

/**
 * Controller relativo al component ActionCmp
 * 
 * @class
 */
function ActionCtrl(hotkeys, Toolbar){   
    var self = this;
    self.ts = Toolbar;
    
    if(self.combokey){
        hotkeys.add({
            combo: self.combokey,
            callback: function(){
                if(!self.disabilita()){
                    self.azione()                    
                }
                else {
                    console.log("Pulsante disabilitato, non puoi eseguire: ", self.descrizione);
                }
            },
            description: self.descrizione
        });
        //self.combo = {};
        //self.combo[self.combokey] = self.azione;
    }
    
    //console.log(this.icona);
    if(self.icona !== undefined && self.icona.constructor === Array){
        self.icone = angular.copy(self.icona);
    }

    
}
    

module.exports = ActionCtrl;