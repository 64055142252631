'use strict';

function BSSTagsController($scope, $filter, $timeout, $http, ErrorManager, DSF){
    $scope.tagRemoved = function(e){
//        console.log("tag rimosso: ", Array.isArray($scope.model[$scope.options.key]), $scope.model[$scope.options.key].length);
        if(Array.isArray($scope.model[$scope.options.key]) && $scope.model[$scope.options.key].length === 0){
            $scope.model[$scope.options.key] = null;
        }
    }
}

module.exports = BSSTagsController;