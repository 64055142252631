'use strict';
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function TemplateAsIsController($scope, $sce, $compile, $rootScope, $filter, $timeout, $http, $state, ErrorManager, DSF){
    
    var self = this;
    self.$scope = $scope;

    self = angular.extend(self, ConfrontaMixin.prototype);

    //permette di conoscere l'id del modello corrente e viene usato anche nella costruzione dell'id dell'elemento in cui il template viene inserito
    $scope.idmodello = self.getIdFromModel();
    console.log("modello id:", $scope.idmodello);

    var compiled = $compile($scope.options.data.colonna.template)($scope);
    $timeout(function(){
        var contenuto = compiled;
        var id = "#tai_" + $scope.options.key + "_" + $scope.idmodello;
        jQuery(id).html(contenuto);
    })
    //console.log("contenuto", content);
    //$scope.contenuto = $sce.trustAsHtml($scope.options.data.colonna.template);    

}

TemplateAsIsController.prototype.getIdFromModel = getIdFromModel;

/**
 * Accede all'id anche se il model è raggruppato
 * @param {ngScope} parscope 
 */
function getIdFromModel(parscope){
    var self = this;
    var intscope = parscope || self.$scope;

    if(!self.nou(intscope)){
        if(!self.nou(intscope.model)){//se lo scope ha il modello
            var model = intscope.model;
            if(!self.nou(model.id)){//se il modello ha id lo ritorno
                return model.id;
            }
            else {//se il modello non ha id 
                if(!self.nou(intscope.$parent)){//se lo scope ha il parent
                    return self.getIdFromModel(intscope.$parent)
                }
            }
        } else {//se lo scope ha parent
            if(!self.nou(intscope.$parent)){
                return self.getIdFromModel(intscope.$parent)
            }
        }
    }    
}

module.exports = TemplateAsIsController;