'use strict';

/**
 * Direttiva da aggiungere a un elemento della pagina. Gestisce l'interazione utente e l'età del token
 * 
 * @class
 * @param {type} $interval
 * @param {type} LoginService
 * @param {type} ErrorManager.notifier.
 * @returns {AFKDir.AFKDirAnonym$0}
 */
function AFKDir($interval, LoginService, ErrorManager, $rootScope, TokenRTService){
    return {
        restrict: 'A',
        link: function($scope, $element){
            var self = this;
            self.$rs = $rootScope;
            self.ls = angular.element(document.body).injector().get("LoginService");
            self.ss = angular.element(document.body).injector().get("SessioneService");
            self.em = angular.element(document.body).injector().get("ErrorManager");
            self.trs = TokenRTService;
            var $interval = angular.element(document.body).injector().get("$interval"); 
            //    self.ss.notAFKFrom = null;
            self.ss.aggiorna("notAFKFrom", null);
            self.tolleranzaAFK = 3600; //@warn cambiato secondi di inattività prima di logout 900
            
            $interval(function(){
                if(self.ls.isLoggedIn()){ 
                    //se l'utente è loggato inizializzo il websocket
                    self.trs.inizializza();                    
                }
            }, 2000);
            
            $interval(function(){
                self.inScadenza();
            }, 2000);
            
            // $interval(function(){
            //     self.inattivita();
            // }, 2000);                        
            
            // $element.bind("click", function(e){self.postponiAFK(e);});
            // $element.bind("mouseenter", function(e){self.postponiAFK(e);});
            // $element.bind("keyup", function(e){self.postponiAFK(e);});
            
            /**
             * Rinnovo il token se sono nella soglia di scadenza
             * 
             * @memberOf AFKDirLink
             * @param {$event} $event
             */
            self.postponiAFK = function($event){
                ////console.log("postponiAFK");
                //se loggato e il token è nella soglia di scadenza rinnovo il token
                var self = this;
                if(self.ls.isLoggedIn()){  
                    //            self.ss.notAFKFrom = moment();
                    self.ss.aggiorna("notAFKFrom", moment());
                    var soglia = 60 || self.ss.sessione.soglia;
                    var scadenza = moment(self.ss.sessione.expiration);
                    //            var differenza = moment.duration(scadenza.diff(self.ss.notAFKFrom)).asSeconds();
                    var differenza = moment.duration(scadenza.diff(self.ss.sessione.notAFKFrom)).asSeconds();
                    //console.log("DA POSTPONI AFK attivita registrata alle: ", moment(self.ss.notAFKFrom).format("DD-MM-YYYY HH:mm:ss.sss"), "scadenza prevista ", scadenza.format("DD-MM-YYYY HH:mm:ss.sss"));
                    //console.log("DA POSTPONI AFK: " + differenza + " secondi alla scadenza, soglia di "+soglia+" secondi, cioè sotto la soglia, in caso di attività, rinnovo");
                    if(differenza > 0 && differenza < soglia){//il token sta per scadere rinnovo
                        //console.log("DA POSTPONI AFK: rinnovo");
                        self.ls.rinnovaToken();
                    }
                }
                else {
                    //            self.ss.notAFKFrom = null;
                    self.ss.aggiorna("notAFKFrom", null);
                }
            };
            
            /**
             * Controllo se il token è in scadenza
             * 
             * @memberOf AFKDirLink
             */
            self.inScadenza = function(){
                var self = this;

                if(self.ls.isLoggedIn()){
                    if(self.ss.sessione.notAFKFrom === null){
                        self.postponiAFK();
                    }
                    var scadenza = moment(self.ss.sessione.expiration);
                    var differenza = moment.duration(scadenza.diff(moment())).asSeconds();
                    //console.log("DA INTERVAL SCADENZA scadenza token tra " + differenza + " secondi");
                    if(differenza <= 0){
                        //console.log("DA INTERVAL SCADENZA token scaduto: " + "sono le "+moment().toDate()+" forzo uscita perchè il token risulta scaduto alle " + scadenza.toDate());                
                        self.$rs.$emit("wss.logout.scadenza");
                        self.ss.cancellaSessione();                              
                        self.ls.notAFKFrom = 0;
                        self.em.notifier.warning({message: "<i class='fa fa-times'></i> Il token è scaduto. Rieffettua il login per procedere", delay: 9999});
                        self.ls.logoutWin.center().open();
                        return;
                    }
                }
            };
            
            
            /**
             * Gestisce l'inattività
             * 
             * @memberOf AFKDirLink
             */
            self.inattivita = function(){
                var self = this;
                //        if(self.ls.isLoggedIn() && self.ss.notAFKFrom !== null){
                if(self.ls.isLoggedIn() && self.ss.sessione.notAFKFrom !== null){
                    
                    //            var inattivita = moment.duration(moment().diff(self.ss.notAFKFrom)).asSeconds();    
                    var inattivita = moment.duration(moment().diff(self.ss.sessione.notAFKFrom)).asSeconds();    
                    
                    //            self.ss.inattivita = inattivita;
                    self.ss.aggiorna("inattivita", inattivita);
                    
                    self.ss.tolleranzaAFK = self.tolleranzaAFK;
                    //            console.log("DA INTERVAL INATTIVITA inattivo da " + inattivita);           
                    //            if(self.ss.inattivita >= self.tolleranzaAFK){
                    if(self.ss.sessione.inattivita >= self.tolleranzaAFK){
                        
                        //console.info("DA INTERVAL INATTIVITA Inattivitità da " + inattivita + " secondi, secondi di inattività tollerata: " + self.tolleranzaAFK);
                        //                self.ss.inattivita = 0;
                        self.$rs.$emit("wss.logout.inattivita");
                        self.ss.aggiorna("inattivita", 0);
                        
                        self.ss.cancellaSessione();
                        
                        //                self.ss.notAFKFrom = 0;
                        self.ss.aggiorna("notAFKFrom", 0);
                        
                        
                        
                        self.em.notifier.warning({message: "<i class='fa fa-times'></i> Rilevata inattività da "+ (self.tolleranzaAFK / 60) +" minuti. Rieffettua il login per procedere", delay: 9999});
                        self.ls.logoutWin.center().open();                                   
                    }
                }
            };
        }
    }
}




module.exports = AFKDir;