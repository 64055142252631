'use strict';

var getNestedKey = function(obj, key) {
    return key.split(".").reduce(function(o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}

/**
 * @description
 * Direttiva che fa da adapter alla validazione in formly
 * 
 * @usage
 * nfv="['rule1', 'rule2:rule2par1:rule2par2', ..., 'ruleN']"
 * 
 * @issues
 * A seconda della regola di validazione i parametri potrebbero necessitare delle modifiche.
 * Per gestire le modifiche e adattare i messagi usare ValidaSrv.ripulisciMessaggio
 * 
 * @param {*} $http 
 * @param {*} Valida 
 * @param {*} $interval 
 * @param {*} $timeout 
 */
function NotFormlyValidator($http, Valida, $interval, $timeout){
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            /**
             * mi aspetto un array di stringhe nel formato validatore:parametro1:parametro2:...:parametroN ["min:1", "between:1:2", "nullable:false"]
             */
            nfv: "=", 
        },
        link: function(s, e, a, n){     
            //console.log("notformlyvalidator", s, e, a, n);                               
            
            /**
             * aggiungo allo scope la struttura che potrebbe servire alla validazione
             */
            if(typeof getNestedKey(s, "options.data.modello.validations") === 'undefined'){
                s.options = {
                    data: {
                        modello: {
                            validations: {}
                        }
                    }
                } 
            }

            if(typeof getNestedKey(s, "to.label") === 'undefined'){                
                s.to = {
                    label: n.$name
                } 
            }

            /**
             * aggiungo allo scope la struttura che potrebbe servire a mostrare i messaggi di errore
             */
            if(typeof getNestedKey(n, "validation.messages") === 'undefined'){
                n.validation = {                
                    messages: {}                    
                } 
            }

            /**
             * scorro tutti i validatori importati per il campo
             */
            (s.nfv || []).forEach(function(validatorAsString){
                var partival = validatorAsString.split(":");
                var validator = partival[0];
                var parametri = partival.slice(1);


                s.options.data.modello.validations[validator] = parametri.length === 1 ? parametri[0] : parametri;
                var currVal = null;

                function handleCurrVal(currVal, mv, vv){

                    if(typeof currVal !== 'undefined' && currVal !== null){
                        //console.log("currVal", validator, currVal, currVal.__proto__);

                        /**
                         * se il messaggio è definito aggiorno lo scope e cancello il controllo
                         */
                        function checkMessage(){
                            if(typeof currVal.message !== 'undefined'){
                                $interval.cancel(checkTimer);
                                $timeout.cancel(timeout);
                                checkTimer = undefined;
                                timeout = undefined;
                                n.validation.messages[validator] = currVal.message;                    
                            }
                        }

                        /**
                         * provo a recuperare il messaggio per il validatore
                         */
                        var checkTimer = $interval(checkMessage, 100);
                        
                        /**
                         * se è trascorso più di un secondo e non sono riuscito a recuperare il messaggio interrompo il controllo 
                         * e setto un messaggio di default
                         */
                        var timeout = $timeout(function(){
                            console.log("tempo scaduto, non sono riuscito a trovare il messaggio", currVal);
                            s.options.validation.messages[validator] = "Errore senza messaggio per " + validator; 
                            $interval.cancel(checkTimer);
                            checkTimer = undefined;
                        }, 1000);                    
                    
                        //problema currVal.message viene settato in maniera asincrona
                        return currVal.__proto__.expression(vv, mv, s);
                    }  
                }

                if(typeof Valida.sync[validator] !== "undefined"){
                    n.$validators[validator] = function(mv, vv){
                        return handleCurrVal(Valida.sync[validator], mv, vv);
                    }
                };

                if(typeof Valida.async[validator] !== "undefined"){
                    n.$asyncValidators[validator] = function(mv, vv){
                        return handleCurrVal(Valida.async[validator], mv, vv);
                    } 
                }

                //console.log("ctrl field aggiornato", n);
              
            })
        }
    }
}

module.exports = NotFormlyValidator;