'use strict';

/**
 * Controller del campo file
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 */
function BSSSyncFileController($scope, $filter, FileUploader, ErrorManager){
    /*
    $scope.fileNameChanged = function(el){
        console.log(el[0].files);
        //$scope.model[$scope.options.key] = angular.copy(el[0].files);
    }
    */
}

module.exports = BSSSyncFileController;