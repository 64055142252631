"use strict";

/**
 * Controlla che il valore is unico (asincrono).
 * Da implementare.
 * 
 * @class
 * @param {$translate} $translate
 */
function UniqueFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf UniqueFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */      
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.UNIQUE", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;
    
    
    /**
     * Applica la regola di validazione e ritorna una promise resolved o rejected
     * 
     * @memberOf UniqueFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */     
    function expression(viewValue, modelValue, scope){
        return true;
    }
        
    return Costruttore;
}

module.exports = UniqueFct;