'use strict';

function BSSResizable(){
  return {
    restrict: 'A',
    link: function(s,e,a){
      var Rx = require('rxjs/Rx');
      var start = null;
      var observables = {
        mouseDowns: Rx.Observable.fromEvent(e, "mousedown"), 
        mouseMoves: Rx.Observable.fromEvent(window, "mousemove"), 
        mouseUps: Rx.Observable.fromEvent(window, "mouseup")
      };
      
      observables.mouseDowns.forEach(function(event){
          start = true;
      });
      
      observables.mouseMoves.forEach(function(event){
          if(start !== null){
            e[0].style.left = event.x +"px";
            e[0].style.top = event.y +"px";
            console.log(event.x, event.y);
          }
        
      });
      
      observables.mouseUps.forEach(function(event){
          start = null;
      });
    
    }
  }
}

module.exports = BSSResizable;