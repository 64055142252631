'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /fatture
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} FattureFunzioniAggiuntive
 */
function FattureCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, FattureFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = FattureFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;

    self.loading = true;

    var listaisoprimalettera = [
        "FR",
        "UK",
        "DE",
        "AT"
    ]

    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tiva/grid",
        data: $.param({
            page: 1,
            skip: 0,
            take: 9999,
            pageSize: 9999
        })
    }).then(function(resp){
            self.listaLink = resp.data.results.reduce(function(obj, tivarecord){
                var iso = tivarecord.nazione.iso.toUpperCase();
                if(iso === "GB"){
                    iso = "UK";
                }
                var nazione = tivarecord.nazione.nazione;
            
                obj["Fattura " + nazione] = "/ricercadoc/Fa" + (iso === "IT" ? "tt" : iso);
                obj["N. Credito " + nazione] = "/ricercadoc/NCC" + (iso === "IT" ?  "L" : (listaisoprimalettera.includes(iso) ? iso[0] : iso));
        
                
                return obj;
            }, {});

            self.loading = false;

        }, function(err){
            self.em.errorCB(err);
            self.loading = false;

        })

}

module.exports = FattureCtrl;
