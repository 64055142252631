'use strict';

const moment = require("moment");

require("../styles/prezziarticolo.scss");

/**
 * 
 * @example
 * <prezziarticolo idarticolo='1' /> 
 * 
 */
function PrezziArticolo(){
    return {
        restrict: 'E',
        template: require("./templates/prezziarticolo.html"),
        scope: {
            idarticolo: '=',
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", "$rootScope", "FormDaHeaderFactory", "Valida", function(s, $http, $q, $timeout, ErrorManager, $rootScope, FormDaHeaderFactory, Valida){      
            var self = this;
            self.moment = moment;
            self.rs = $rootScope;
            self.fdhf = FormDaHeaderFactory;
            self.em = ErrorManager;
            self.valida = Valida;
            self.campiformNuovoPrezzo = [];
            self.valutaeur = null;


            self.getValutaByCodice = function(codice){
                return $http({
                    url: utils.urls.wsbase + "/tvaluta/grid",
                    method: "POST",
                    data: $.param({
                        take: 1, 
                        skip: 0,
                        page: 1,
                        pageSize: 1,
                        filter: {logic: 'and', filters: [
                            {field: 'codice', operator: 'eq', value: codice}
                        ]}
                    })
                }).then(function(resp){
                    return resp.data.results[0];
                }, function(err){
                    self.em.errorCB(err);
                })
            }

            self.inizializzaModel = function(){
                self.nuovoPrezzo = {
                    articolo: self.articolo, 
                    valuta: self.valutaeur, 
                    dataInizio: moment().toDate(), 
                    dataFine: moment("9999-12-31", "YYYY-MM-DD").toDate(), 
                    status: {
                        "value": "A",
                        "text": "ATTIVO"
                    }
                }
            }

            self.configuraForm = function(){
                if(!self.campiformNuovoPrezzo.length || self.valutaeur === null){
                    return self.getValutaByCodice("EUR").then(function(resp){
                        self.valutaeur = resp;
                        return $http({
                            url: utils.urls.wsbase + "/table/header",
                            method: "POST",
                            data: $.param({
                                entity: "com.bsssrl.bssstdgestent.PrezzoStd",
                                codapp: "a",
                                codmodulo: "detart",
                                strentity: "prezzistd"
                            })        
                        }).then(function(resp){
                            self.cfgFormNuovoPrezzo = new self.fdhf("", resp.data, null, null, self.formNuovoPrezzo, true);
                            console.log("cfgform nuovo", self.cfgFormNuovoPrezzo);
                            self.campiformNuovoPrezzo = self.cfgFormNuovoPrezzo.campi;
                            self.inizializzaModel();
                            return self.articolo;
                        }, function(err){
                            self.em.errorCB(err);
                        })
                    })

                    
                } else {
                    self.inizializzaModel();
                    return $q.when(self.articolo);
                }

                
            }
            

            s.$watch(function(){
                return s.idarticolo
            }, function(n, o){
                console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.caricamento = true;
                    console.log("idarticolo cambiato reinizializzo", s.idarticolo);

                    self.inizializza().then(function(articolo){
                        self.caricamento = false;
                        self.filtraprezzi()
                    })
                }
            })

            self.dateF = function(){
                if(self.modelfiltriprezzi !== null && typeof self.modelfiltriprezzi !== 'undefined'){
                    return {
                        da: moment(self.modelfiltriprezzi.datada).format("DD-MM-YYYY"),
                        a: moment(self.modelfiltriprezzi.dataa).format("DD-MM-YYYY"),
                    }    
                }
            }


            self.rs.$on("detart.aggiornata_associazione_articolo_fornitore", function(){
                self.filtraprezzi();
            })

            
                    
            self.inizializza = function(){
                console.log("inizializzo maschera prezzi", s)
                self.idarticolo = s.idarticolo;
                self.loading = true;
                self.modelfiltriprezzi = {
                    datada: moment().toDate(),
                    dataa: moment().toDate()
                }
                return $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/articolirdc/cerca",
                    data: $.param({id: self.idarticolo})
                }).then(function(resp){
                    self.articolo = resp.data;
                    
                    return self.configuraForm();
                }, function(err){
                    self.articolo = {id: self.idarticolo};
                    return self.articolo;
                })
            }

            self.filtraprezzi = function(){

                var orida = moment.utc(self.modelfiltriprezzi.datada)
                var oria = moment.utc(self.modelfiltriprezzi.dataa)
                var da = orida.isAfter(oria) ? oria : orida;
                var a = orida.isAfter(oria) ? orida: oria;
                da = da.set({hour: 0, minute: 0, second: 0, millisecond: 0});
                a = a.set({hour: 23, minute: 59, second: 59, millisecond: 999});

                var filtri = {idarticolo: self.idarticolo, datada: da.toISOString(), dataa: a.toISOString()};
                console.log("filtro i prezzi per ", filtri);
                
                return $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/prezzistd/ricerca",
                    data: $.param(filtri)
                })
                // return $q.when({
                //     data: {prezziven: [], prezziacq: []}
                // })
                .then(function(resp){
                        self.dati = {
                            prezziacq: resp.data.prezziacquisto.results,
                            prezziven: resp.data.prezzivendita.results
                        }

                        return self.dati
                    })
            }

            self.evidenziafornitore = function(prezzo){
                
            }

            self.aggiungiPrezzo = function(){                
                if(self.valida.validaForm(self.formNuovoPrezzo)){
                    $http({
                      method: 'POST',
                      url: utils.urls.wsbase + "/prezzistd/crea",
                      data: $.param({elemento: JSON.stringify(self.nuovoPrezzo)})
                    }).then(function(resp){
                        
                        self.formNuovoPrezzo.$setPristine();
                        self.formNuovoPrezzo.$setUntouched();
                        self.inizializzaModel();

                        self.aggiungiPrezzoWin.close();
                        self.em.showT("ARTICOLI.AGGIUNTOPREZZIARTICOLO", "success");
                        self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore");
                    }, function(err){
                        self.em.errorCB(err);
                    })
                }
            }

            self.confirmElimina = function(prezzo){
                self.prezzosel = angular.copy(prezzo);
                self.eliminaPrezzoWin.open().center();

            }

            self.eliminaPrezzo = function(){
                $http({
                    method: 'POST',
                    url: utils.urls.wsbase + "/prezzistd/cancella",
                    data: $.param({id: JSON.stringify([self.prezzosel.id])})
                  }).then(function(resp){
                      
                    self.prezzosel = null;
                    self.eliminaPrezzoWin.close();
                    self.em.showT("ARTICOLI.ELIMINAPREZZIARTICOLO", "success");
                    self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore");
                  }, function(err){
                      self.em.errorCB(err);
                  })

                
            }
            
        }],
        controllerAs: "paCtrl"
    };
}

module.exports = PrezziArticolo;