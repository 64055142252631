'use strict';

/**
 * Wrapper che raggruppa i campi
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * 
 */
function BssFormPanelWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "BssFormPanelWrapper",
        template: require("./templates/bssformpanel.html"),
        
    })
}

module.exports = BssFormPanelWrapper;

