"use strict";

/**
 * Servizio che gestisce il login e il logout
 * 
 * @class
 * @param {$httpProvider} $http
 * @param {SessioneService} SessioneSrv
 */
function LoginService($http, $interval, SessioneSrv, ErrorManager, $state, SideMenuSrv, FieldFactory, $rootScope, Valida){
    var self = this;
    self.loginUrl = utils.urls.wsbase + "/internalloginservice/login";
    self.$http = $http;
    
    self.ss = SessioneSrv;
    self.em = ErrorManager;
    self.$state = $state;
    self.sm = SideMenuSrv;
    self.ff = FieldFactory;
    self.$rs = $rootScope;
    self.campiFormCambiaPass = [self.getCampoNuovaPassword()];
    self.valida = Valida;

    var kccheck = $interval(function(){
        if(keycloak !== undefined && keycloak.token !== undefined){
            $interval.cancel(kccheck);
            kccheck = undefined;
            self.multiAzEsEnabled().then(function(){
                self.campiFormContesto = [
                    new FieldFactory({
                        key: "azienda", 
                        tipo: 'bsssingleselect', 
                        label: 'Azienda',
                        colonna: {}, 
                        modello: {
                            validations: {
                                required: true
                            },
                            strentity: "aziende", 
                            codmodulo: null, 
                            datatextfield: 'codazi', 
                            datavaluefield: 'codazi', 
                            template: '#= codazi #',
                        }
                    }, 
                    false, 
                    {
                        className: 'col-lg-6', 
                    }).opzioni,
                    new FieldFactory({
                        key: "esercizio", 
                        tipo: 'bsssingleselect', 
                        label: 'Esercizio',
                        colonna: {}, 
                        modello: {
                            validations: {
                                required: true
                            },
                            strentity: "esercizi", 
                            codmodulo: null, 
                            datatextfield: 'codesercizio', 
                            datavaluefield: 'codesercizio', 
                            template: '#= codesercizio #',
                        }
                    }, 
                    false, 
                    {
                        className: 'col-lg-6', 
                    }).opzioni,
                ];
            })
        } else {
            console.log("====> keycloak non attivo, ricontrollo tra poco")
        }
    }, 300)
    
}


LoginService.prototype.logga = logga;
LoginService.prototype.isLoggedIn = isLoggedIn;
LoginService.prototype.isSistemista = isSistemista;
LoginService.prototype.getToken = getToken;
LoginService.prototype.rinnovaToken = rinnovaToken;    
LoginService.prototype.logout = logout; 
LoginService.prototype.getCampoNuovaPassword = getCampoNuovaPassword; 
LoginService.prototype.cambiaPass = cambiaPass; 
LoginService.prototype.evidenziaErroriCambiaPass = evidenziaErroriCambiaPass;
LoginService.prototype.cambiaContesto = cambiaContesto;
LoginService.prototype.initFormContesto = initFormContesto;
LoginService.prototype.multiAzEsEnabled = multiAzEsEnabled;

function isSistemista(){
    var self = this;
    var esito = false;

    if(self.isLoggedIn()){
        var risorsa = utils.kccfg['risorsa-sistemista'];
        var ruolo = utils.kccfg["ruolo-sistemista"];
        var kcruolirisorsa = bssRecursiveGetter(keycloak.tokenParsed.resource_access, risorsa+".roles", null)

        if(kcruolirisorsa !== null){
            esito = kcruolirisorsa.includes(ruolo)
        }
        
    }

    return esito;

}

/**
 * @description 
 * inizializza il model form per cambiare il contesto e apre la finestra contenente il form
 */
function initFormContesto(){
    var self = this;
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/aziende/entity",
        //headers: {"X-bss-check-feature-availability": "aziende"}
    }).then(function(resp){//questa funzionalità è disponibile solo se aziende/esercizio è attivo
        self.formContesto.model = self.ss.partizione;
        self.cambiaContestoWin.open().center();
    })
    
}


function multiAzEsEnabled(){
    var self = this;
    return self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/aziende/entity",
        //headers: {"X-bss-check-feature-availability": "aziende"}
    })
}

/**
 * @description
 * Aggiorna il contesto dell'utente e lo riporta alla home
 */
function cambiaContesto(){
    var self = this;

    if(self.valida.validaForm(self.formContesto.form)){
        console.log("aggiorno il contesto in base a", self.formContesto.modello)
        var modello = self.formContesto.modello;
        self.ss.aggiorna("partizione", {azienda: modello.azienda.codazi, esercizio: modello.esercizio.codesercizio})
        self.cambiaContestoWin.close();
        window.location.reload(true);
    } 

}

/**
 * Evidenzia gli errori nel form di cambio password. Se sono presenti errori torna true altrimenti false
 * 
 * @memberOf LoginService
 * 
 * @returns {Boolean}
 */
function evidenziaErroriCambiaPass(){
    var self = this;
    var esito = false;
    if(self.formCambiaPass.$error){
        angular.forEach(self.formCambiaPass.$error, function (field) {
            angular.forEach(field, function(errorField){
                esito = true;
                errorField.$setTouched();
            });
        });
        self.formCambiaPass.$setSubmitted();
    }
    return esito;
}

/**
 * Cambia la password dell'utente loggato
 * 
 * @memberOf LoginService
 * 
 */
function cambiaPass(){
    var self = this;
    // if(!self.evidenziaErroriCambiaPass()){//non ci sono errori procedo con il cambio della password
    //     return self.$http({
    //         url: utils.urls.wsbase + "/usermanager/cambiapassword",
    //         data: $.param(self.modelloFormCambiaPass),
    //         method: "POST"
    //     }).then(function(resp){
    //         self.opzioniFormCambiaPass.resetModel();
    //         self.cambiaPassWin.close();
    //         self.em.showT("LOGIN.CAMBIOPASSUTENTESUCCESS", "success");
    //     });
    // }
    if(self.isLoggedIn()){
        keycloak.accountManagement()
    }
    
}

function getCampoNuovaPassword(){
    var self = this;  
    
    return new self.ff({
        key: 'password', 
        tipo: 'bsspassword', 
        label: 'Nuova Password',
        colonna: {}, 
        modello: {
            validations: {required: true},
        }
    }, 
    false, 
    {className: 'col-xs-12'}).opzioni;
}

/**
 * Rinnova token soglia e scadenza token
 * 
 * @memberOf LoginService
 * @returns {promise}
 */
function rinnovaToken(){
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/bwclogin/rinnova"
    }).then(function(response){
        self.ss.aggiorna("token", response.data.bsstoken);
        self.ss.aggiorna("expiration", response.data.expiration);
        self.ss.aggiorna("soglia", response.data.soglia);
        self.$rs.$emit("wss.token.rinnova");
        return response;
    }, function(response){
        self.em.errorCB(response);
        throw response;
    });
}


/**
 * dice se l'utente è loggato o meno (per ora ritorna sempre false)
 * 
 * @memberOf LoginService
 * @returns {Boolean}
 */
function isLoggedIn(){
    return typeof keycloak !== 'undefined' && keycloak !== null && keycloak.token !== null && typeof keycloak.token !== 'undefined';    
}

function getToken(){
    var self = this;
    var token = self.isLoggedIn() ?  keycloak.token : null;
    return token
}

/**
 * Effettua il login, se nessuna credenziale è fornita usa quelle di default (solo per i test)
 * 
 * @memberOf LoginService.prototype
 * @returns {promise}
 * 
 * @example
 * LoginService.logga("mario", "rossi").then(function(resp){
 *      //gestisco il successo del login
 *  }, function(err){
 *      //gestisco l'errore al login
 *  });
 */
function logga(){
    
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/utenti"
    }).then(function(res){
        self.sm.voci = false;
        self.ss.aggiorna('idutente', res.data.idutente);
        self.ss.aggiorna('utente', res.data.username);        
        self.ss.aggiorna('apgf', res.data.apgf);        

        
        var partinsess = bssRecursiveGetter(self, "ss.sessione.partizione", null); //recupero la partizione dalla sessione

        if(partinsess === null){
            self.ss.aggiorna('partizione', {azienda: res.data.azienda || "*", esercizio: res.data.esercizio || "*"});
        }
        
        return self.$http({method: "GET", url: utils.urls.wsbase + "/cfgutenti/findconf/homepref"}).then(function(resp){
            //console.log("aggiorno i preferiti", resp);
            if(typeof resp.data !== 'undefined' && resp.data !== null){
                if(typeof resp.data.configurazione !== 'undefined' && resp.data.configurazione !== null){
                    var preferiti = JSON.parse(resp.data.configurazione);
                    self.ss.aggiorna('homepref', preferiti)
                }                
            }
            self.$rs.$emit("wss.login.utente");
            return res;
        })
    }, function(res){
        throw res;
    })
}


/**
 * Gestisce il logout
 * 
 * @memberOf LoginService
 */
function logout(){
    var self = this;

    self.$rs.$emit("wss.logout.utente");
    self.ss.cancellaSessione();
    //ignorebeforeunload = true;
    if(self.isLoggedIn()){
        keycloak.logout(self.$state.href("login"))
    } else {
        self.$state.go("login", null,{reload: true});        
        location.reload(true);
    }
    
    // function logoutThen(resp){
    //     self.$rs.$emit("wss.logout.utente");
    //     self.ss.cancellaSessione();
        
    //     self.$state.go("login", null,{reload: true});
    //     location.reload(true);
    //     return resp;
    // }
    
    // return self.$http({
    //     method: "POST",
    //     url: utils.urls.wsbase + "/internalloginservice/logout"
    // }).then(logoutThen, logoutThen);
}



module.exports = LoginService;
