'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSZoomController($scope, $filter, $timeout, $http, $state, ErrorManager, DSF, GCF){

    var s = $scope;

    var modello = $scope.options.data.modello;

    //s.labelfields = a.labelfields.split(";");
    s.labelfields = modello.labelfields;
    s.strentity = modello.strentity;
    s.codapp = modello.codapp;
    s.codmodulo = modello.codmodulo;

    /*
    if (s.dataitem) {
        s.ricerca = s.dataitem[s.labelfields[0]];
    }
    */
    if(s.model[s.options.key]){
        s.ricerca = s.model[s.options.key][s.labelfields[0]];
    }

    s.grigliainizializzata = false;

    s.inizializzagriglia = function (successcb) {
        if (!s.grigliainizializzata) {
            new DSF(s.strentity, s.codapp || null, s.codmodulo || null, null).inizializza().then(function (ds) {
                new GCF(s.strentity, s.codapp || null, s.codmodulo || null, null, true).inizializza().then(function (gridcfg) {

                    gridcfg.dataSource = ds;

                    gridcfg.dataBound = function (e) {
                        var griglia = e.sender;
                        var righe = griglia.tbody.find("tr");
                        righe.dblclick(function (e) {
                            var dataItem = griglia.dataItem(e.currentTarget);
                            console.log(dataItem, "selezione");
                            s.ricerca = dataItem[s.labelfields[0]];
                            s.cerca(true);
                            s.finestra.close();
                        });
                    };

                    s.gridoptions = angular.extend({}, gridcfg);
                    s.gridrebind = moment().format();
                    s.ds = ds;
                    s.grigliainizializzata = true;
                    //console.log("ricerca", s.ricerca, s.ds);
                    s.cerca(true);
                    successcb();

                })
            })
        }
        else {
            successcb();
        }

    }


    s.handlekd = function ($event) {
        if ($event.ctrlKey && $event.key == 'F3') {
            s.aprigriglia();
            $event.preventDefault();
        }
        if ($event.key == 'Tab' || $event.key == 'Enter') {
            s.cerca();
            $event.preventDefault();
        }

    };

    s.aprigriglia = function () {

        function success() {
            s.settafiltri();
            s.ds.read().then(function (resp) {
                s.finestra.open().center();
            });
        }

        s.inizializzagriglia(success);

    }

    s.cerca = function (noopen) {


        function success() {
            s.settafiltri();
            $timeout(function () {
                s.ds.read().then(function (resp) {
                    s.total = s.griglia.dataSource.total();
                    if (s.total === 1) {
                        s.ricerca = s.ds.data()[0][s.labelfields[0]];
                        //s.dataitem = s.ds.data()[0];
                        s.model[s.options.key] = s.ds.data()[0];
                        
                        //$rootScope.$emit("bsszoom.aggiornamento", s.dataitem);
                        $rootScope.$emit("bsszoom.aggiornamento", s.model[s.options.key]);
                        $(e).next().find("input").focus();
                        //console.log("selezione: ", s.dataitem);
                    }
                    if (s.total !== 1 && noopen !== true) {
                        s.dataitem = {};
                        $(e).find("input.bsszoomfield").focus();
                        s.finestra.open().center();
                    }

                });
            });
        }

        s.inizializzagriglia(success);

    }

    s.handleblur = function () {
        console.log("blur");
        s.cerca();
    }

    s.settafiltri = function () {
        if (typeof s.ricerca !== 'undefined' && s.ricerca !== '' && s.ricerca !== null) {
            s.ds.filter({
                logic: 'or', filters: _.map(s.labelfields, function (labelfield) {
                    return { field: labelfield, operator: 'contains', value: s.ricerca }
                })
            });
        }
    }

}

module.exports = BSSZoomController;