'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/menu/scripts/index.js");
require("moduleFold/permessi/styles/permessi.scss");

var bsspermessi = angular.module("bss.permessi", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar", "bss.menu"])
    .factory("GrigliaPermessi", ["$stateParams", "DSFactory", "GridCfgFactory", "ToolbarSrv", require("./GrigliaPermessi")])
    .factory("MenuPermessi", ["MenuManagerRS", "DSFactory", "$q", "$timeout", require("./MenuPermessi")])
    .factory("GrigliaModuliPermessi", ["$q", "$timeout", "$stateParams", "DSCfgFactory", "GridCfgFactory", "ToolbarSrv", require("./GrigliaModuliPermessi")])
    .service("PermessiModuliManager", ["$http", "$q", require("./PermessiModuliManager")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/PermessiRoutes")])
;

module.exports = bsspermessi;
