"use strict";


require("../styles/stileglobale.scss");
require("moduleFold/../extincluder/scripts/index.js");
require("../styles/gsstyle.scss");
require("moduleFold/dragresize/scripts/index.js");
require("moduleFold/fileutilities/scripts/FileSaver.js");
require("moduleFold/ngcomejq/scripts/index.js");
require('moduleFold/statoapplicazione/scripts/index.js');
require("moduleFold/estensioni/underscore/utilities.js");
require("moduleFold/slidein/scripts/index.js");
require("moduleFold/formatters/scripts/index.js");
require("moduleFold/finestre/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/router/scripts/index.js");
require("moduleFold/sidebarmenu/scripts/index.js");
require("moduleFold/autentica/scripts/index.js");
require("moduleFold/bloccaschermo/scripts/index.js");
require("moduleFold/modulobase/scripts/index.js");
require("moduleFold/permessi/scripts/index.js");
require("moduleFold/usermanager/scripts/index.js");
require('moduleFold/gruppiutenti/scripts/index.js');
require('moduleFold/wizardlocalita/scripts/index.js');
require('moduleFold/fileutilities/scripts/index.js');
require('moduleFold/componenti/index.js');
require('moduleFold/disegnamodelli/scripts/index.js');
require("moduleFold/bsszoomfield/scripts/index.js");
require("moduleFold/anagrafiche/scripts/index.js");
require("moduleFold/teststoragecommunications/scripts/index.js");
require("moduleFold/teststoragecommunications2/scripts/index.js");

require("moduleFold/../bsscarrier/modalitapagamenti/scripts/index.js");


//dipendenze specifiche ricambiweb

require("bssricambiwebFold/tracciatifile/scripts/index.js");
require("bssricambiwebFold/listinistd/scripts/index.js");
require("bssricambiwebFold/documentistd/scripts/index.js");
require("bssricambiwebFold/importstd/scripts/index.js");
require("bssricambiwebFold/maxphoimport/scripts/index.js");
require("bssricambiwebFold/ricercadoc/scripts/index.js");
require("bssricambiwebFold/articoli/scripts/index.js");
require("bssricambiwebFold/tipidoc/scripts/index.js");
require("bssricambiwebFold/amazonimport/scripts/index.js");
require("bssricambiwebFold/incassi/scripts/index.js");
require("bssricambiwebFold/connettoricorrieri/scripts/index.js");
require("bssricambiwebFold/listacodici/scripts/index.js");
require("bssricambiwebFold/statistiche/scripts/index.js");
require("bssricambiwebFold/fatture/scripts/index.js");
//finerequire


var bssentrypoint = angular.module("bss.entrypoint", [
    "ui.ace", 'ngclipboard', 'angular-timeline', 'mgo-angular-wizard', 'google.places', 
    'ngGeolocation', "ngMap", "ngTagsInput", /*"timer",*/ "slugifier", "angularAudioRecorder",
    'ds.clock', 
    "FileManagerApp", 
    'ngWebSocket', 
    'bss.dragresize',
    "bss.slidein", "bss.router", "bss.finestre", "bss.notifiche", "bss.datasource", "bss.ngcomejq", 
    "bss.toolbar", "bss.griglia", "bss.formbuilder", "bss.autentica", "bss.sidebarmenu", 
    "bss.bloccaschermo", "bss.modulobase", 
    "bss.formatters",
    "bss.statoapp",    
    "bss.permessi",     
    "bss.usermanager",     
    "bss.gruppiutenti",    
    'bss.wizardlocalita',
    "bss.fileutilities",
    "bss.componenti",
    "bss.zoomfield",
    "bss.bsslib.anagrafiche",
    "bss.bsslib.teststoragecommunications",
    "bss.bsslib.teststoragecommunications2",

    "bss.bsscarrier.modalitapagamenti",
    //inject ricambiweb

    "bss.bssricambiweb.tracciatifile",
    "bss.bssricambiweb.listinistd",
    "bss.bssricambiweb.documentistd",
    "bss.bssricambiweb.importstd",
    "bss.bssricambiweb.maxphoimport",
    "bss.bssricambiweb.ricercadoc",
    "bss.bssricambiweb.articoli",
    "bss.bssricambiweb.tipidoc",
    "bss.bssricambiweb.amazonimport",
    "bss.bssricambiweb.incassi",
    "bss.bssricambiweb.connettoricorrieri",
    "bss.bssricambiweb.listacodici",
    "bss.bssricambiweb.statistiche",
    "bss.bssricambiweb.fatture",
    //fineinject
])
    .config(['fileManagerConfigProvider', function (config) {
        var defaults = config.$get();
        var baseurl = 'http://localhost/fakedata/fakefiles.php';
        config.set({
            appName: 'Gestore File',
            defaultLang: 'it',
            listUrl:baseurl,
            uploadUrl:baseurl,
            renameUrl:baseurl,
            copyUrl:baseurl,
            moveUrl:baseurl,
            removeUrl:baseurl,
            editUrl:baseurl,
            getContentUrl:baseurl,
            createFolderUrl:baseurl,
            downloadFileUrl:baseurl,
            downloadMultipleUrl:baseurl,
            compressUrl:baseurl,
            extractUrl:baseurl,
            permissionsUrl:baseurl,
            basePath:'',
        });
    }])
    .controller("MainCtrl", [
        "$rootScope", 
        "$state", 
        "$stateParams",
        "$timeout", 
        "$http",
        "FinestreCfgFactory", 
        "ErrorManager", 
        "LoginService", 
        "DSFactory", 
        "GridFactory", 
        "FormDaGridFactory", 
        "SideMenuSrv",
        "StatoApp",
        "TokenRTService",
        "$window",
        "$translate",
        "ToolbarSrv",
        require("./MainCtrl")
    ]);
    

module.exports = bssentrypoint;