'use strict';


/**
 * Controller relativo al template della route /gruppiutenti
 *
 * @param {type} ToolbarSrv
 * @param {type} ActionFactory
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $translate
 * @param {type} ErrorManager
 */
function GruppiutentiFunzioniAggiuntive(ToolbarSrv, ActionFactory, $http, $q, $timeout, $translate, ErrorManager){
    var self = this;
    self.ts = ToolbarSrv;
    self.af = ActionFactory;
    self.$http = $http;
    self.$q = $q;
    self.$timeout = $timeout;
    self.$translate = $translate;
    self.em = ErrorManager;
    var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
    var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
    angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype);
}

GruppiutentiFunzioniAggiuntive.prototype.aggiornaProvider = aggiornaProvider;


/**
 * @memberof GruppiutentiFunzioniAggiuntive
 *
 * Registra azioni nella toolbar
 *
 */
function aggiornaProvider(){
    var self = this;
    /* es. di azione, in questo caso occorre definire il metodo mostraTimeLine
    var azione = new self.af("fa fa-history",  self.esponi({metodo: "dimSelezioneConfronta", parametri: ["ne", 1]}), self.esponi({metodo: "mostraTimeLine", parametri: []}), 999, null, "GRUPPIUTENTI.SHOWHISTORY");

    self.ts.setProviders({gruppiutentiprovider: {exposed: [
                azione
            ]}});
    */
}

module.exports = GruppiutentiFunzioniAggiuntive;
