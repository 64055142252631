'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");



/**
 * Controller relativo al template della route /amazonimport
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} AmazonimportFunzioniAggiuntive
 */
function AmazonimportCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, AmazonimportFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = AmazonimportFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.em = ErrorManager;
    self.fd = {cartellaselezionata: [], fileselezionati: []};

    $scope.aggiornafd = function(sorgente, files) {
        self.fd[sorgente] = [];
        
        Array.prototype.forEach.call(files, function(file, indice){
            self.fd[sorgente].push(file);
        })
        
        console.log("aggiornata sorgente", sorgente, self.fd[sorgente]);
    
    };
}

AmazonimportCtrl.prototype.upload = upload;

function upload(){
    var self = this;
    self.taskincorso = true;
    var fd = new FormData();

    self.fd.cartellaselezionata.concat(self.fd.fileselezionati).forEach(function(file, indice){
        fd.append("file["+indice+"]", file)
        console.log("file da aggiungere", file);
    })

    angular.element("#cartellaselezionata").val(null);
    angular.element("#fileselezionati").val(null);
    self.fd = {cartellaselezionata: [], fileselezionati: []};

    //self.$q.when(self.$timeout(()=> "ok", 1200)).then(resp => self.taskincorso = false)

    self.$http.post(utils.urls.wsbase + "/upload/ANA_AMAZON", fd, {
            withCredentials: true,
            headers: {'Content-Type': undefined },
            transformRequest: angular.identity
        }).success(function(resp){
          self.$http({
            url: utils.urls.wsbase + "/amazonrdc/importa",
            method: "GET"
          }).then(function(resp){
            self.taskincorso = false;

            //self.em.showT("AMAZONIMPORT.SUCCESSUPLOAD", "success")
            self.em.show(resp.data.message, resp.data.type);
          }, function(err){
            self.taskincorso = false;
          })
          
        }).error(function(err){
          self.taskincorso = false;
          self.em.showT("AMAZONIMPORT.ERRORUPLOAD", "error")
        });
}

module.exports = AmazonimportCtrl;
