'use strict';

require("../styles/codartforn.scss");

/**
 * 
 * @example
 * <codartforn idarticolo='1' /> 
 * 
 */
function CodArtForn(){
    return {
        restrict: 'E',
        template: require("./templates/codartforn.html"),
        scope: {
            idarticolo: '=',
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", "FieldFactory", "Valida", "$rootScope", function(s, $http, $q, $timeout, ErrorManager, FieldFactory, Valida, $rootScope){      
            var self = this;
            self.moment = moment;
            self.rs = $rootScope;
            self.em = ErrorManager;
            self.copiaInModifica = {id: null};
            self.ff = FieldFactory;
            self.valida = Valida;

            self.campiformNuovaAssAF = [
                new self.ff({
                    key: 'fornitore', 
                    tipo: 'bsssingleselect', 
                    label: 'Fornitore',
                    colonna: {}, 
                    modello: {
                        validations: {required: true},
                        strentity: 'fornitori', 
                        codmodulo: 'a', 
                        datatextfield: 'ragsoc', 
                        datavaluefield: 'id', 
                        template: '#= ragsoc #',
                    }
                }, 
                false, 
                {
                    className: 'col-lg-12', 
                }).opzioni,
                new self.ff({
                    "key": "codartforn",
                    "tipo": "bssinput", 
                    "label": "Cod. Art. Forn.",
                    "colonna": {},
                    "modello": {
                        validations: {required: true},
                    }
                },
                false,
                {
                    "className": "col-lg-6",        
                }).opzioni,
                new self.ff({
                    key: "prezzo", 
                    tipo: 'bssnumeric', 
                    label: 'Prezzo',
                    placeholder: 'Prezzo',
                    colonna: {}, 
                    modello: {
                        validations: {required: true},
                    }
                }, 
                false, 
                {
                    className: 'col-lg-6', 
                    "ngModelElAttrs": {"num-neg": 'false', "num-fract": "2", "num-fixed": 'true'}
        
                }).opzioni
            ];

            self.creaAssAF = function(){

                var self = this;
            
                if(self.valida.validaForm(self.formNuovaAssAF)){
                    
                    // var dati = angular.extend({}, self.nuovaAssAF, {idarticolo: self.articoloAssociabile.articolo.id})
                    var dati = {
                        codice: self.nuovaAssAF.codartforn,
                        idarticolo: self.idarticolo,
                        idfornitore: self.nuovaAssAF.fornitore.id,
                        prezzo: self.nuovaAssAF.prezzo
                    };
                    console.log("posso creare una nuova associazione", dati)
                    self.inassociazione = true;
                    $http({
                        method: "POST",
                        url: utils.urls.wsbase + "/articolirdc/associafornitore",
                        data: $.param(dati)
                    }).then(function(resp){
                        self.em.showT("DOCUMENTISTD.NUOVAASSAF", "success");
                        self.nuovaAssAF = {};
                        self.formNuovaAssAF.$setPristine();
                        self.formNuovaAssAF.$setUntouched();
                        self.inassociazione = false;                        
                        self.inizializza()
                        self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore")
                    })
                }
                
            }

            self.rs.$on("detart.aggiornata_associazione_articolo_fornitore", function(){
                self.inizializza();
            })

            s.$watch(function(){
                return s.idarticolo
            }, function(n, o){
                console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.caricamento = true;
                    console.log("idarticolo cambiato reinizializzo", s.idarticolo);

                    self.inizializza().then(function(articolo){
                        self.caricamento = false;
                    })
                }
            })            
                                
            self.inizializza = function(){
                self.idarticolo = s.idarticolo;
                self.loading = true;
                
                return $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/articolirdc/supplierscode",
                    data: $.param({id: self.idarticolo})
                }).then(function(resp){
                    self.cafList = resp.data;
                    return self.cafList
                })
            }

            self.eliminaAssociazione = function(assoc){
                
                $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/codicifornitori/cancella",
                    data: $.param({id: JSON.stringify([assoc.id])})
                }).then(function(resp){
                    self.confirmEliminaAssociazioneWin.close();
                    self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore");                    
                }, function(err){
                    self.em.errorCB(err);
                })
            }

            self.modificaAssociazione = function(assoc){
                console.log("modifica assoc.", assoc);
                if(assoc.codartforn !== ""){
                    $http({
                        method: "POST",
                        url: utils.urls.wsbase + "/codicifornitori/modifica",
                        data: $.param({elemento: JSON.stringify(assoc)})
                    }).then(function(resp){
                        self.copiaInModifica = {id: null};
                        self.rs.$emit("detart.aggiornata_associazione_articolo_fornitore");                    
                    }, function(err){
                        self.em.errorCB(err);
                    })
                }
            }

            self.vaiModificaAssociazione = function(assoc){
                self.copiaInModifica = angular.copy(assoc);                
            }

            
            
        }],
        controllerAs: "cafCtrl"
    };
}

module.exports = CodArtForn;