'use strict';

/**
 * Wrapper che aggiunge la label
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BssLabelWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "bsslabel",
        template: require("./templates/bsslabel.html"),
        types: ["bssinput", "bssiconpicker", "bsstextarea", "bssselect", "bssfile",  "bssfile2", "bsssyncfile",
            "bssnumeric", "bssdatetime", "bssdate", "bsstime", "bssplaintext", 
            "bsszoom", "bsspassword", "bssmultiselect", "bsslaum", "bsssingleselect", 
            "bssplaceautocomplete", "bsstags", "bsslocalizedstring", "bsszoom", "bsscalendario", "bsscolorpicker"],
    })
}

module.exports = BssLabelWrapper;