'use strict';

/**
 * 
 * @module bss/configuratore
 * @description Fornisce un Factory di configurazione di base
 */
var bssconfiguratore = angular.module("bss.configuratore",  [])
        .factory("CfgFactory", ["$http", "$timeout", require("./CfgFactory")]);

module.exports = bssconfiguratore;