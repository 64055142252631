'use strict';

/**
 * Wrapper per i campi di sola lettura
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BssReadOnlyWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "BssReadOnlyWrapper",
        template: require("./templates/bssreadonly.html"),
        types: ["bssinput", "bssiconwrapper", "bsstextarea", "bssselect", "bssfile", "bssfile2", "bsssyncfile",
            "bssboolcheckbox", "bssnumeric", "bssdatetime", "bssdate", "bsstime", 
            "bsszoom", "bsspassword", "bssmultiselect", "bsslaum", "bsssingleselect", 
            "bssplaceautocomplete", "bsslocalita", "bsstags", "bsslocalizedstring", "bsszoom", "bsscalendario", "bsscolorpicker"],
    })
}

module.exports = BssReadOnlyWrapper;