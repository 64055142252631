'use strict';

require("moduleFold/middleware/scripts/index.js");

/**
 * @module bss/router
 * @description Fornisce le route di base
 * 
 */
var bssrouter = angular.module("bss.router", ['bss.middleware'])
    .factory('StateChangeManager', ['$rootScope', '$q', require("./StateChangeManager")])
    .config(["$httpProvider", function($httpProvider){
        $httpProvider.interceptors.push("StateChangeManager");
    }])
    .controller("PreferitiController", ["SideMenuSrv", "$scope", function(sm, $scope){
        var self = this;
        self.sm = sm;            
        self.imgguida = require("incomuneFold/gestpref.gif");
        self.apriGuida = function(){
            self.guideWin.open().center()
        }
        self.utils = utils;
    }])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./cfgs/Routes")])
    .directive("bsspreferiti", [require("./BssPreferitiDir")])
    .directive("boxbsspreferiti", [require("./BoxBssPreferitiDir")])
    .directive('bssautofocus', ['$timeout', function($timeout) {
        return {
          restrict: 'A',
          link : function($scope, $element) {
            $timeout(function() {
              //console.log("campo input", $elemnt[0]);
              $element[0].focus();
            });
          }
        }
    }]);
;




module.exports = bssrouter;