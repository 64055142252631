'use strict';

/**
 * EnterAsTab - direttiva simula tab alla pressione di enter (ATTENZIONE: non va con bssfile, bssselect, bssboolcheckbox, bssdatetime)
 *
 * @class 
 * @returns {angularDirective} direttiva 
 *
 */
function OnEnterDir(){
    return{
        link: function (scope, element, attrs) {
            //console.log("abilito tab on enter", attrs, element);
            element.bind("focus", function(event){
                event.target.select()
            })            

            element.bind("keydown keypress blur", function (event) {
                //console.log("pressione tasto");
                
                var eventlist = [13]
                
                if(attrs.allowtab !== undefined){
                    if(attrs.allowtab === 'only'){
                        eventlist = [9];
                    } else {
                        eventlist.push(9);
                    }

                }

                if(eventlist.includes(event.which) || (!eventlist.includes(event.which) && event.type === "blur")) {
                    scope.$apply(function () {
                        scope.$eval(attrs.onEnter);
                    });
                }

            })
        }
    }
}

module.exports = OnEnterDir;