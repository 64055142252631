'use strict';

function EvidenziaColonnaFiltrata(){
    console.log("direttiva che mostra l'icona filtro");
    return {
        restrict: 'C',
        link: function(s,e,a){
            var original = angular.copy(e);
            console.log("salvo l'elemento originale: ", original[0].innerHTML);
            s.$watch(function(){
                return e.attr("class");
            }, function(n, o){
                if(e.hasClass("k-state-active")){                    
                    e.prepend("<i class='fa fa-filter'></i>")
                    console.log("ADD orig:", original[0].innerHTML, e[0].innerHTML);
                }
                else {
                    e[0].innerHTML = original[0].innerHTML;
                    console.log("RMV orig:", original[0].innerHTML, e[0].innerHTML);                    
                }
            });
        }
    }
}

module.exports = EvidenziaColonnaFiltrata;