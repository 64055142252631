'use strict';


function UsermanagerSrv(ToolbarSrv, ActionFactory, $http, $q, $timeout, $translate, ErrorManager){
    var self = this;
    self.ts = ToolbarSrv;
    self.af = ActionFactory;
    self.$http = $http;
    self.$q = $q;
    self.$timeout = $timeout;
    self.$translate = $translate;
    self.em = ErrorManager;
    var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
    var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
    angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype);
    console.log("usermanagersrv: ", self);
}



UsermanagerSrv.prototype.aggiornaProvider = aggiornaProvider;
UsermanagerSrv.prototype.mostraCambioPassword = mostraCambioPassword;


function aggiornaProvider(){
    var self = this;
    var azione = new self.af("fa fa-key",  self.esponi({metodo: "dimSelezioneConfronta", parametri: ["ne", 1]}), self.esponi({metodo: "mostraCambioPassword", parametri: []}), 999, null, "USERMANAGER.CAMBIAPASSWORDTITLE");
    self.ts.setProviders({usermanagerprovider: {exposed: [
        azione
    ]}});
}

function mostraCambioPassword(){
    var self = this;
    return self.$translate("USERMANAGER.CAMBIAPASSWORDPROMPT").then(function(promptText){
        
        self.$timeout(function(){           
            var nuovaPass = prompt(promptText);
            console.log("nuova password: ", nuovaPass, nuovaPass === null);
            if(nuovaPass !== '' && nuovaPass !== null && nuovaPass !== undefined){
                var utentesel = self.griglia.dataItem(self.griglia.select()); 
                var parametri = {
                    idutente: utentesel.id,
                    password: nuovaPass
                };

                console.log("parametri da inviare ad aggiornapassword ", parametri);
                
                //return self.$q.all(["parametri da inviare ad aggiornapassword "+JSON.stringify(parametri)]);
                
                return self.$http({
                    url: utils.urls.wsbase + "/usermanager/aggiornapassword",
                    method: 'POST',
                    data: $.param(parametri)
                }).then(function(){
                    self.em.showT("USERMANAGER.PASSWORDCAMBIATA", 'success');
                });
            }
            else {
                return self.$q.all(["nessuna password"]);
            }
        });
        
    });  
}

module.exports = UsermanagerSrv;