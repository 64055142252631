'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

function MastroDettaglioMixin(){
    
}

MastroDettaglioMixin.prototype.openedit = openedit;
MastroDettaglioMixin.prototype.salvariga = salvariga;
MastroDettaglioMixin.prototype.eliminasel = eliminasel;
MastroDettaglioMixin.prototype.righeselvuote = righeselvuote;
MastroDettaglioMixin.prototype.righeseldiff = righeseldiff;
MastroDettaglioMixin.prototype.modificaRiga = modificaRiga;
MastroDettaglioMixin.prototype.osservaSelezione = osservaSelezione;

MastroDettaglioMixin.prototype.getSelezione = getSelezione;
MastroDettaglioMixin.prototype.getModalita = getModalita;

function getSelezione(){
    var self = this;
    return self.ts.selezione;
}

function getModalita(){
    var self = this;
    if(typeof self.gridcfg === 'undefined') return 'form';
    return self.gridCfg.mode;
}

function osservaSelezione(){
    var self = this;
    //al cambio della selezione ripopola la grid delle righe
    self.$scope.$watch(function(){
        return self.getSelezione();
    }, function(n , o){
        console.log("selezione cambiata: ", n);
        if(Array.isArray(n) && n.length && typeof self.parametriDettaglio !== 'undefined'){
            var testata = n[0];
            self.$http({
                url: utils.urls.wsbase + self.parametriDettaglio.wsread,
                data: $.param({id: testata.id}),
                method: "POST"
            }).then(function(resp){
                
                var opzAdd = {
                    batch: true,
                    transport:{
                        read: {
                            url: utils.urls.wsbase + self.parametriDettaglio.wsread, 
                            data: function(){        
                                var parametri = {};                                        
                                parametri[self.parametriDettaglio.idtestata] = testata.id;                                   
                                return parametri;                                                            
                            }, 
                            method: 'POST' 
                        }
                    }
                };
                
                var parametri = {testata: testata.id, mode: 'inserisci'};
                self.urlAddRiga = self.$state.href("gestdatilinee", parametri);
                
                new self.dsf(self.parametriDettaglio.strentity, null, null, opzAdd).inizializza().then(function(ds){
                    new self.gcf(self.parametriDettaglio.strentity, null, null, null, true).inizializza().then(function(gridcfg){                       
                        self.righeds = ds;
                        gridcfg.dataSource = ds;
                        gridcfg.dataBound = function(e){
                            var griglia = e.sender;
                            var righe = griglia.tbody.find("tr");                   
                            righe.dblclick(function(e){                                 
                                var dataItem = griglia.dataItem(e.currentTarget);                                
                                self.openedit(dataItem, "Modifica");
                            })
                        };
                        self.righeOpts = angular.extend({}, gridcfg);
                        self.righeRebind = moment().format();
                        self.righeds.read();
                    });
                });
            });
        }
        
    });
}

function modificaRiga(){
    var self = this;
    var dataItem = self.righeGrid.dataItem(self.righeGrid.select());
    self.openedit(dataItem, "Modifica");
}

function righeseldiff(num){
    var self = this;
    if(typeof self.righeGrid == 'undefined') return true;
    return self.righeGrid.select().length != num;
}   

function righeselvuote(){
    var self = this;
    if(typeof self.righeGrid == 'undefined') return true;
    return self.righeGrid.select().length == 0;
}

function eliminasel(){
    var self = this;
    var ids = _.map(self.righeGrid.select(), function(riga){
        return self.righeGrid.dataItem(riga).id;
    });

    self.$http({
        method: 'POST',
        url: utils.urls.wsbase + self.parametriDettaglio.wscancella,
        data: $.param({id: JSON.stringify(ids)})
    }).then(function(){
        self.righeGrid.dataSource.read();
        self.confermaDelWin.close();
    })
    
}

function openedit(model, mode){
    var self = this;
    self.modelriga = angular.copy(model);
    self.formObj = new self.fgf("", self.righeGrid, null, null, mode, self.formriga, true);

    self.editRigheWin.open().center();
}

function salvariga(){
    var self = this;
    if(self.valida.validaForm(self.formriga)){
        var mode = "crea"
        if(self.modelriga.id){
            console.log("modifico", self.modelriga);
            mode = "modifica";
        }

        self.$http({
            method: 'POST',
            url: utils.urls.wsbase + self.parametriDettaglio["ws"+mode],
            data: $.param({elemento: JSON.stringify(self.modelriga)})
        }).then(function(){
            self.righeGrid.dataSource.read();
            self.editRigheWin.close();
        })
    
    }
}

module.exports = MastroDettaglioMixin;
