'use strict';

/**
 * Component relativo all'azione
 * 
 * @class
 * @example 
 * <toolbar-dropdown-actions disabilita="ac.disabilita" azione="ac.azione" icona="ac.icona" azioni="ac.azioni"></toolbar-action>
 */
function DropdownActionsCmp(){
    this.bindings = {
        azione: "=",
        icona: "=",
        disabilita: "=",
        descrizione: "=",
        mostra: "=",
        nascondi: "=",
        combokey: "=",
        azioni: "="
    };
    this.template = require("../templates/dropdownaction.html");
    this.controller = "ActionCtrl as ac";
}

module.exports = DropdownActionsCmp;