"use strict";

var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

/**
 * Genera l'intero form partendo dalla griglia
 * 
 * @class
 * @param {ActionFactory} ActFct
 * @param {FieldFactory} FieldFactory
 * @param {FieldService} FieldService
 * @param {ToolbarService} ToolbarService
 * @param {ErrorManager} ErrorManager
 */
function FormDaGridFactory(ActFct, FieldFactory, FieldService, ToolbarService, ErrorManager){
    
    /**
     * Ottiene tutti i campi ed espone il submit del form sulla toolbar .
     * 
     * @memberOf FormDaGridFactory
     * @constructor
     * @param {type} nome
     * @param {type} griglia
     * @param {type} azioni
     * @param {type} opzioni
     * @param {type} mode
     * @param {type} formController
     */
    function Costruttore(nome, griglia, azioni, opzioni, mode, formController, disconnettiToolbar){        
        this.griglia = griglia;
        this.gridDS = this.griglia.dataSource;
        this.gridModel = this.gridDS.options.schema.model;
        this.nome = nome;
        this.mode = mode;        
        this.campi = this["getCampi"+(mode || 'Inserisci')]();
        this.modello = this["getModello"+(mode || 'Inserisci')]();
        this.exposed = [];
        this.formController = formController;
        
        angular.extend(this, EsponiMixin.prototype, opzioni);
        
        if(azioni){
            this.exposed = azioni;
        }

        //console.log("creato form: -campi", this.campi, "-modello", this.modello)
        if(!disconnettiToolbar){
            ToolbarService.actionsProviders.formSubmitter = this;
        }
    }
    
    //ritorna tutti i campi della griglia che non sono nascosti
    Costruttore.prototype.getCampiDaGriglia = getCampiDaGriglia;
    //ritorna solo i campi inseribili
    Costruttore.prototype.getCampiInserisci = getCampiInserisci;
    //ritorna solo i campi editabili
    Costruttore.prototype.getCampiModifica = getCampiModifica;
    //ritorna solo i campi per il dettaglio
    Costruttore.prototype.getCampiDettaglio = getCampiDettaglio;
    //ritorna solo i campi duplicabili
    Costruttore.prototype.getCampiDuplica = getCampiDuplica;
    //ottiene il modello per l'inserimento
    Costruttore.prototype.getModelloInserisci = getModelloInserisci;
    //modello per la modifica
    Costruttore.prototype.getModelloModifica = getModelloModifica;
    //modello per il dettaglio
    Costruttore.prototype.getModelloDettaglio = getModelloDettaglio;
    //modello per duplica
    Costruttore.prototype.getModelloDuplica = getModelloDuplica;
    //submit default inserimento
    Costruttore.prototype.submitInserisci = submitInserisci;
    //gestisce il submit su un form non valido
    Costruttore.prototype.submitInvalid = submitInvalid;
    //avvisa nel caso in cui un form è stato inviato senza alcuna modifica
    Costruttore.prototype.nessunaModifica = nessunaModifica;
    //submit default modifica
    Costruttore.prototype.submitModifica = submitModifica;
    //metodo submit esponibile da utilizzare in base alla modalità
    Costruttore.prototype.getSubmitMethod = getSubmitMethod;
    //riordina i campi
    Costruttore.prototype.riordinaCampi = riordinaCampi;
    //lista univoca raggruppamenti
    Costruttore.prototype.listaRaggruppamenti = listaRaggruppamenti;
    //fornisce ai campi senza raggruppamento un raggruppamento di default
    Costruttore.prototype.settaRaggruppamentoDefault = settaRaggruppamentoDefault;
    //ritorna i campi raggruppati
    Costruttore.prototype.campiRaggruppati = campiRaggruppati;
    //se è presente anche un solo campo raggruppato torna i campi raggruppati altrimenti torna i campi semplicemente riordinati
    Costruttore.prototype.raggruppaCampi = raggruppaCampi;        
    
    /**
     * Ottiene tutti i campi dalla griglia
     * 
     * @memberOf FormDaGridFactory
     * @returns tutti i campi che non sono nascosti
     * 
     */
    function getCampiDaGriglia(){
        var campi = [];
        for(var x in this.gridModel.fields){
            var campoDaGrid = FieldService.cfgCampoDaGrid(this.griglia, x);
            if(campoDaGrid !== null){
                //prova classe css
                //decommenta per riprendere la configurazione dalle columns
                //var cfgColonnaGrid = _.findWhere(this.griglia.columns, {field: x});
                const cfgColonnaGrid = _.findWhere(this.griglia.options.form, {field: x});
                //console.log("cfg colonna griglia:", cfgColonnaGrid);
                
                var opzioniAggiuntive = {};
                
                if(cfgColonnaGrid.formstyleclass !== undefined && cfgColonnaGrid.formstyleclass !== null && cfgColonnaGrid.formstyleclass !== "" ){
                    opzioniAggiuntive.className = cfgColonnaGrid.formstyleclass;
                }

                if(![null, undefined, false, ''].includes(cfgColonnaGrid.hideExpression)){
                    opzioniAggiuntive.hideExpression = function(vv, mv, s){
                        console.log("dovrei nascondrere il campo", cfgColonnaGrid.field)
                        return true;
                    }; 
                    //cfgColonnaGrid.hideExpression;
                }
                
                if(cfgColonnaGrid.expressionProperties !== undefined && cfgColonnaGrid.expressionProperties !== null && cfgColonnaGrid.expressionProperties !== "" ){
                    opzioniAggiuntive.expressionProperties = cfgColonnaGrid.expressionProperties;
                }
                
                //console.log("campo da grid: ", campoDaGrid);
                var campo = new FieldFactory(campoDaGrid, false, opzioniAggiuntive);
                
                campi.push(campo.opzioni);
            }
        }
        
        var self = this;
        
        var mode = (self.mode === 'Duplica') ? 'Inserisci' : self.mode;
        
        return _.filter(campi, function(campo){
            
            return !FieldService.isCampoNascosto(campo, mode);
        });
    }
    
    
    /**
     * Riordina tutti i campi in base all'ordine delle colonne
     * 
     * @param {type} campi lista di campi non raggruppati e non ordinati
     * @memberOf FormDaGridFactory
     * @returns tutti i campi raggruppati
     */
    function riordinaCampi(campi){

        //decommentare per riprendere la configurazione dalle columns
        //var nomiCampiOrdinati = _.pluck(this.griglia.columns, "field");
        var nomiCampiOrdinati = _.pluck(this.griglia.options.form, "field");

        //console.log("lista ordinata", nomiCampiOrdinati, "campi nell'ordine iniziale: ", campi);
        var campiriordinati = _.filter(_.map(nomiCampiOrdinati, function(nomeCampo){
            return _.findWhere(campi, {key: nomeCampo});
        }), function(campo){
            return campo !== undefined;
        });
        return campiriordinati;
    }
    
    /**
     * Ottiene la lista dei raggruppamenti senza duplicati
     * 
     * @param {type} lista di campi
     * @memberOf FormDaGridFactory
     * @returns tutti nomi dei raggruppamenti
     */
    function listaRaggruppamenti(campi){
        return _.uniq(_.filter(_.map(campi, function(campo){
            return campo.data.colonna.raggruppamento;
        }), function(raggruppamento){
            return raggruppamento !== undefined;
        }), function(raggruppamento){
            return raggruppamento.key;
        });
    }
    
    /**
     * Fornisce un raggruppamento ai campi che sono sprovvisti di raggruppamento
     * 
     * @param {type} campi
     * @param {type} raggrDef oggetto {key,label} che rappresenta il raggruppamento di default
     * @returns {undefined}
     */
    function settaRaggruppamentoDefault(campi, raggrDef){
        return _.map(campi, function(campo, indice){
            if(!campo.data.colonna.raggruppamento) {
                campo.data.colonna.raggruppamento = raggrDef;
            }
            return campo;
        });
    }
    
    /**
     * Ritorna i campi nella struttura richiesta da formly per il raggruppamento
     * 
     * @param {type} campi
     * @param {type} raggruppamenti
     * @returns {undefined|Array|FormDaGridFactory.campiRaggruppati.campiraggruppati}
     */
    function campiRaggruppati(campi, raggruppamenti){
        var campiraggruppati = [{
            key: 'maintab', 
            wrapper: "BssTabWrapper",
            data: { tabs: raggruppamenti },
            fieldGroup: []
        }];

        //console.log("lista raggruppamenti ", raggruppamenti);

        _.map(raggruppamenti, function(raggruppamento){
            campiraggruppati[0].fieldGroup.push({
                "key": raggruppamento.key,
                "wrapper": "BssFormPanelWrapper",
                "data": {
                    "label": raggruppamento.label,
                    "key": raggruppamento.key
                },
                fieldGroup: _.filter(campi, function(campo){
                    return campo.data.colonna.raggruppamento.key === raggruppamento.key;
                })
            });
        });
        
        return campiraggruppati;
    }

    /**
     * Ottiene tutti i campi con gli opportuni raggruppamenti
     * 
     * @param {type} campi lista di campi non raggruppati
     * @memberOf FormDaGridFactory
     * @returns tutti i campi raggruppati
     */
    function raggruppaCampi(campi){
        //riordino i campi
        campi = this.riordinaCampi(campi);

        //se non sono presenti raggruppamenti ritorno i campi riordinati
        if(!this.listaRaggruppamenti(campi).length) return campi;
        
        //associo ai campi senza raggruppamento il raggruppamento di default    
        campi = this.settaRaggruppamentoDefault(campi, {key: 'Default', label: 'Altro'});

        //creo la struttura dei campi con il raggruppamento
        return this.campiRaggruppati(campi, this.listaRaggruppamenti(campi));   

    }

    
    /**
     * Ottiene tutti i campi che servono per l'inserimento
     * 
     * @memberOf FormDaGridFactory
     * @returns tutti i campi che non sono nascosti per l'inserimento
     * 
     */    
    function getCampiInserisci(){
        var tutticampi = this.getCampiDaGriglia();
        var campi = _.filter(tutticampi, function(campo){
            return campo.data.modello.insertable;
        });
        //console.log("vedi come sono raggruppati i campi in inserimento");
        return this.raggruppaCampi(campi);
    }
    
    
    /**
     * Ottiene tutti i campi che servono per la modifica
     * 
     * @memberOf FormDaGridFactory
     * @returns tutti i campi che non sono nascosti per la modifica
     * 
     */      
    function getCampiModifica(){
        var tutticampi = this.getCampiDaGriglia();
        var campi = _.map(tutticampi, function(campo){
            if(!campo.data.modello.editable){
                campo.type = 'bssplaintext';
            }
            return campo;
        });
        //console.log("vedi come sono raggruppati i campi in modifica");
        return this.raggruppaCampi(campi);
    }
    
    /**
     * Ottiene tutti i campi che servono per il dettaglio
     * 
     * @memberOf FormDaGridFactory
     * @returns tutti i campi che non sono nascosti per il dettaglio
     * 
     */  
    function getCampiDettaglio(){
        var tutticampi = this.getCampiDaGriglia();
        var campi = _.map(tutticampi, function(campo){
            campo.type = 'bssplaintext';
            return campo;
        });
        return this.raggruppaCampi(campi);
    }
    
    /**
     * Ottiene tutti i campi che servono per la duplicazione
     * 
     * @memberOf FormDaGridFactory
     * @returns tutti i campi che non sono nascosti per la duplicazione
     * 
     */     
    function getCampiDuplica(){
        return this.getCampiInserisci();
    }
    
    /**
     * Ottiene il modello per l'inserimento
     * 
     * @memberOf FormDaGridFactory
     * 
     */      
    function getModelloInserisci(){
        return {};
    }

    /**
     * Ottiene il modello per la modifica
     * 
     * @memberOf FormDaGridFactory
     * 
     */       
//    function getModelloModifica(){
//        return angular.copy(this.griglia.dataItem(this.griglia.select()));
//    }
    function getModelloModifica(){                       
        

        var self = this;
        
        var modelloSemplice = angular.copy(this.griglia.dataItem(this.griglia.select()));
        //console.log("modello prima del raggruppamento: ", modelloSemplice);
        //per ripristinare il comportamento senza raggruppamento basta decommentare questa riga
        //return modelloSemplice();
        
                
        var campiModifica = self.getCampiModifica();        

        
        if(campiModifica[0].key !== "maintab"){
            //console.log("modello semplice: ", modelloSemplice);
            return modelloSemplice;
        }


        //return modelloSemplice;
        
        
        //presuppongo che l'id non sia modificabile
        var modelloRaggruppato = {id: modelloSemplice[self.gridDS.options.schema.model.id]};
        
        modelloRaggruppato[campiModifica[0].key]  =  _.reduce(_.map(campiModifica[0].data.tabs, function(tab){
            var obj = {};
            var fgtab = _.findWhere(campiModifica[0].fieldGroup, {key: tab.key});
            obj[tab.key] = _.reduce(_.map(fgtab.fieldGroup, function(campo){
                var cv = {};
                cv[campo.key] = modelloSemplice[campo.key];
                return cv;
            }), function(plainobj, arritem){
                for(var x in arritem){
                    plainobj[x] = arritem[x];
                }
                
                return plainobj;
            });
            //console.log(obj);
            return obj;
        }), function(plaintabs, arrtab){
            for(var x in arrtab){
                plaintabs[x] = arrtab[x];
            }
            return plaintabs;
        });
        
        //console.log("campi modifica raggruppati: ", campiModifica, modelloRaggruppato);
        
        //console.log("modello raggruppato: ", modelloRaggruppato);
        
        
        //var modelloRaggruppato = {};
        //console.log("modello raggruppato: ", modelloRaggruppato);
        return modelloRaggruppato;
    }
     
    /**
     * Ottiene il modello per la duplicazione
     * 
     * @memberOf FormDaGridFactory
     * 
     */        
    function getModelloDuplica(){
        var self = this;
        
        var tutticampi = this.getCampiDaGriglia();                        
        var campiDuplica = _.filter(tutticampi, function(campo){
            return campo.data.modello.duplicable; //da modificare in duplicable
        });
        var nomiCampi = _.pluck(campiDuplica, 'key');
        
        if(nomiCampi.length){
            var modelloSempliceFull = angular.copy(this.griglia.dataItem(this.griglia.select())); //compresi campi non duplicabili        
            var modelloSemplice = _.pick(modelloSempliceFull, nomiCampi);
        }
        else {
            var modelloSemplice = {};
        }        
        
        var campiDuplica = this.raggruppaCampi(campiDuplica);        
        
        if((!campiDuplica.length) || (campiDuplica[0].key !== "maintab")){
            console.log("nessun raggruppamento, modelloSemplice: ", modelloSemplice, " nomi campi: ", nomiCampi);
            return modelloSemplice;
        }
        
        var modelloRaggruppato = {};        
        modelloRaggruppato[campiDuplica[0].key]  =  _.reduce(_.map(campiDuplica[0].data.tabs, function(tab){
            var obj = {};
            var fgtab = _.findWhere(campiDuplica[0].fieldGroup, {key: tab.key});
            obj[tab.key] = _.reduce(_.map(fgtab.fieldGroup, function(campo){
                var cv = {};
                cv[campo.key] = modelloSemplice[campo.key];
                return cv;
            }), function(plainobj, arritem){
                for(var x in arritem){
                    plainobj[x] = arritem[x];
                }
                
                return plainobj;
            });
            //console.log(obj);
            return obj;
        }), function(plaintabs, arrtab){
            for(var x in arrtab){
                plaintabs[x] = arrtab[x];
            }
            return plaintabs;
        });
        
        return modelloRaggruppato;
    }
    
    /**
     * Ottiene il modello per il dettaglio
     * 
     * @memberOf FormDaGridFactory
     * 
     */    
    function getModelloDettaglio(){
        return this.getModelloModifica();
    }
    
    /**
     * Dice se il modello è invalido o meno, se il modello è invalido mostra tutti gli errori di tutti i campi.
     * 
     * @memberOf FormDaGridFactory
     * @returns {boolean} se invalido oppure no
     */    
    function submitInvalid(){//forse basta mettere il form come $submitted(è stato aggiornato error e label)
        if(this.formController.$invalid){
            angular.forEach(this.formController, function(field){  
                try{
                    field.$setTouched();
                } catch(e){}                
            });
            ErrorManager.show("Correggi gli errori prima di procedere.");
            return true;
        }
        return false;
    }
    
    /**
     * Avvisa se non ci sono modifiche
     * 
     * @memberOf FormDaGridFactory
     * 
     */        
    function nessunaModifica(){
        if(!this.gridDS.hasChanges()){
            ErrorManager.showT("FORMBUILDER.NESSUNAMODIFICA", "warning");
        }
    }
    
    /**
     * Submit di inserimento
     * 
     * @memberOf FormDaGridFactory
     * 
     */
    function submitInserisci(){
        if(!this.submitInvalid()) {
            this.gridDS.add(this.modello);
            this.nessunaModifica();
            return this.gridDS.sync();
        }
    }

    /**
     * Submit di modifica
     * 
     * @memberOf FormDaGridFactory
     * 
     */
    function submitModifica(){
        if(!this.submitInvalid()) {
            var actualdataitem = this.gridDS.get(this.modello[this.gridModel.id]);
            for(var y in this.campi){
                actualdataitem.set(y, this.modello[y]);
            }
            this.nessunaModifica();
            return this.gridDS.sync();
        }
    }
    
    /**
     * Ritorna il metodo da eseguire a seconda della modalità. 
     * Il metodo è ritornato in maniera da poter essere passato come action della toolbar.
     * 
     * @memberOf FormDaGridFactory
     * @returns metodo oppure null
     * 
     */       
    function getSubmitMethod(mode){
        if(["Inserisci", "Duplica"].indexOf(mode) !== -1){
            return {metodo: "submitInserisci", parametri: []};
        }
        if(["Modifica"].indexOf(mode) !== -1){
            return {metodo: 'submit' + mode, parametri: []};
        }
        return null;
    }
    
    return Costruttore;
    
}

module.exports = FormDaGridFactory;