/**
 * 
 * @namespace estensioni prototype
 * @param {regex} seps 
 * @return {string} str stringa con ogni parola con la prima lettera in maiuscolo
 * @example
 * var stringhe = ["trentino alto d\'adige", "questa è una prova senza apice", "l'aquila", "singola"];
 * for(var x = 0; x < stringhe.length; x++){
 *  console.log(stringhe[x], " =>capitalized=> " ,stringhe[x].bssCapitalize([/ /, /'/]));
 * }
 * 
 */
String.prototype.bssCapitalize = function (seps){
//    try{
//        var str = this;
//        console.log("str bssCapitalize", str);
//        str = str.toLowerCase();
//        if(!seps){
//            seps = [/ /, /'/];
//        }
//        for(var x = 0; x < seps.length; x++){
//            str = (str.split(seps[x]).map(function(parola){
//                return parola[0].toUpperCase() + parola.substr(1);
//            })).join(seps[x].toString().replace(/\//g, ""));
//        }
//        return str;
//    } catch(err){
//        console.log("err bssCapitalize", err);
//    }
    
}


/**
 * Presa una stringa a codifica fissa la spezza in una collection
 * 
 * @namespace estensioni prototype
 * @param {obj} columnMap
 * @param {regex} rowSep 
 * @return {Array<Objects>} results array di oggetti
 * @example
 * "20141231;20160101;20151212;19880928".bssSplitAsCollection({anno:4,mese:2,giorno:2}, /;/);
 * // => [{anno: "2014", giorno: "31", mese: "12"}, {anno: "2016", giorno: "01", mese: "01"}]
 * 
 */
String.prototype.bssSplitAsCollection = function(columnMap, rowSep){
//    try{
//        var self = this;
//        console.log("str bssSplitAsCollection", str);
//        var results = [];
//        var rows = self.split(rowSep);
//        for(var x = 0; x < rows.length; x++){
//            var rowAsStr = rows[x];
//            var row = {};
//            var ltot = 0;
//            for(var y in columnMap){
//                var l = columnMap[y];
//                row[y] = rowAsStr.substr(ltot, l);
//                ltot += l;
//            }
//            results.push(row);
//        }
//
//        return results;
//    } catch(err){
//        console.log("err bssSplitAsCollection", err);
//    }
    
}