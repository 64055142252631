"use strict";

function N2WDirective($http){
    return {
        restrict: 'A',
        link: function(s, e, a){
            s.$watch(a.osserva, function(n, o){
                if(typeof n !== 'undefined'){
                    $http({
                        method: 'GET',
                        url: "http://bssec.svi/testnw/"+n
                    }).then(function(resp){
                        s.model[s.options.key] = resp.data;
                    })
                }                
            })
        }     
    }
}

module.exports = N2WDirective;