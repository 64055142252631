'use strict';

function BssPlaceautocompleteController($scope, $filter, $timeout, $http, ErrorManager, $sce, $geolocation){
    //console.log("place autocomplete opzioni: ", $scope.options.data.modello.opzioni);
    
    $scope.parsePlace = function(place){
        var compAddr = place.address_components;
        
        var comune =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['locality', 'administrative_area_level_3']).length;
        }));
        
        var cap = _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['postal_code']).length;
        }));
        
        var provincia =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['administrative_area_level_2']).length;
        }));
        
        var regione =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['administrative_area_level_1']).length;
        }));
        
        var nazione =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['country']).length;
        }));
        
        var strada =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['route']).length;
        }));
        
        var numcivico =  _.first(_.filter(compAddr, function(comp){
            return _.intersection(comp.types, ['street_number']).length;
        }));
        
        console.log("comp. addr: ", compAddr);
        
        var localita = {
            comune: {
                comune: null,
                cap: null
            },
            regione: {
                regione: null,
            },
            provincia: {
                provincia: null,
                iso: null
            },
            nazione: {
                nazione: null,
                iso: null
            },            
            indirizzoFormattato: place.formatted_address,
            coordinate: place.geometry.location,
            url: $sce.trustAsResourceUrl(place.url+"&output=embed")
        };
        
        if(place.url){
            localita.url = $sce.trustAsResourceUrl(place.url+"&output=embed")
        }
        else {
            localita.url = $sce.trustAsResourceUrl("https://www.google.com/maps/embed/v1/place?key="+utils.gmapkey+"&q=place_id:"+place.place_id);
            
        }
        
        if(comune !== undefined){
            localita.comune.comune = comune.long_name;
        }
        if(cap !== undefined){
            localita.comune.cap = cap.long_name;
        }
        if(provincia !== undefined){
            localita.provincia.provincia = provincia.long_name;
            localita.provincia.iso = provincia.short_name;
        }
        if(regione !== undefined){
            localita.regione.regione = regione.long_name;
        }        
        if(nazione !== undefined){
            localita.nazione.nazione = nazione.long_name;
            localita.nazione.iso = nazione.short_name;
        }
        if(strada !== undefined){
            localita.strada = strada.long_name;
        }
        if(numcivico !== undefined){
            localita.numcivico = numcivico.long_name;
        }
        
        return localita;
    }
    
    $scope.getLocation = function(){

        $geolocation.getCurrentPosition({
            timeout: 20000,
            enableHighAccuracy: true
        }).then(function(position) {
            $scope.coordUtente = position;

            $scope.reverseGeocode($scope.coordUtente.coords.latitude, $scope.coordUtente.coords.longitude);

        });
    
}

    $scope.reverseGeocode = function reverseGeocode(lat, lng){
        var self = this;
        var geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    $scope.postoSel = results[0];
                    $scope.localita = $scope.parsePlace(results[0]);
                    $scope.model[$scope.options.key] = $scope.localita.indirizzoFormattato;
                } else {
                    console.warning("Indirizzo non trovato alle coordinate ", latlng);
                }
            } else {
                self.em.show('Errore geolocalizzazione: ' + status);                
            }
        });
    }
    
    
    $scope.$on('g-places-autocomplete:select', function(e, place){
        $scope.postoSel = place;
        $scope.localita = $scope.parsePlace(place);                              
    });
}

module.exports = BssPlaceautocompleteController;