'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
var MastroDettaglioMixin = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixin");
var MastroDettaglioMixinV2 = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixinV2");



/**
 * Controller relativo al template della route /tracciatifile
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} TracciatifileFunzioniAggiuntive
 */                                    
function TracciatifileCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, GCF, ToolbarS, idstato, $scope, $compile, ActionFactory, ErrorManager, FormDaGridFactory, Valida, TracciatifileFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q
    self.fa = TracciatifileFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$scope = $scope;
    self.$compile = $compile;
    self.$sp = $stateParams;
    self.fgf = FormDaGridFactory;
    self.valida = Valida;

    self.righe = [];
    self.righeeliminate = [];
    self.righedaeliminare = [];

    self.parametri = {
        strentity: "ttracciati",
        //codmodulo: "BCARDiPaCo",
        //wssalvamd: "/dbplc/creamasdet",
        //campi request
        //campotestata: "testata", //campo che conterrà il model della testata
        //campoim: "imrighe", //campo che conterrà i model delle righe inserite/modificate
        //campoe: "erighe" //campo che conterrà i model delle righe eliminate (con id)
    };

    self.parametriDettaglio = {
        //idtestata: "idtestata",
        strentity: "tcampitracciato",
        //wsread: "/tfasciapeso/righetestata",
        //nascondicomandi: {crea: true, elimina: true}
    }


    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype, MastroDettaglioMixin.prototype, MastroDettaglioMixinV2.prototype);

    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza();
    
    //osserva la selezione testata e aggiorna le righe
    self.osservaSelezione();
}

module.exports = TracciatifileCtrl;
