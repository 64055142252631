'use strict';

function BloccaSchermoDir(BS){
    return {
        restrict: 'E',
        template: require("./templates/screensaver.html"),
        controller: ["Bloccaschermo", function(Bloccaschermo){
           this.bs = Bloccaschermo;     
        }],
        controllerAs: 'bsCtrl',
        bindToController: true
    }
}

module.exports = BloccaSchermoDir;