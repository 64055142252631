'use strict';


/**
 * Controller relativo al template della route /permessi
 *
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 */
function PermessiCtrl($q, $stateParams, $http, DSF, GF, ToolbarS, FieldFactory, idstato, $scope, GrigliaPermessi, MenuPermessi, GrigliaModuliPermessi, PermessiModuliManager){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.gp = GrigliaPermessi;
    self.ff = FieldFactory;
    self.mp = MenuPermessi;
    self.gmp = GrigliaModuliPermessi;
    self.$http = $http;
    self.pmm = PermessiModuliManager;
    self.griglieModuli = {};
    self.moduliCfg = {};
    
    self.tabOpts = {
        contentLoad: function(e){
            console.log("contetuto caricato: ", e.item.textContent);
            
            
        }
    };
    
    var tpl_old = "<span ng-class='\{voceEccezione\: #: item.eccezione #\, voceTree\: true}'>\n\
<i class='#: item.spriteCssClass #'></i>  \n\
#: item.text #\n\
<button class='btn btn-danger' title='Rimuovi Eccezione' ng-if='#: item.eccezione #' ng-click='moduloCtrl.rimuoviEccezione(\{idutente\: dataItem.id, idmenu\: \"#: item.id #\"\})' >\n\
<i class='fa fa-times'></i>\n\
</button>\n\
</span>";
    
    
    var tpl = "<span ng-class='\{voceEccezione\: #: item.eccezione #\, voceTree\: true}'>\n\
<i class='#: item.spriteCssClass #'></i>  \n\
#: item.text #\n\
<button class='btn btn-danger' title='Rimuovi Eccezione' ng-if='#: item.eccezione #' \n\
ng-click='moduloCtrl.rimuoviEccezioneUtente(dataItemParent, dataItem, moduloCtrl[dataItemParent.uid].menu)' >\n\
<i class='fa fa-times'></i>\n\
</button>\n\
</span>";
    
    self.templateMenu = kendo.template(tpl);
    
    self.menuForms = {
        'usermanager' : {},
        'groupmanager': {},
    };
    
    $q.all([new self.gp('groupmanager').inizializza(), new self.gp('usermanager').inizializza()]).then(function(cfgs){
        //console.log(cfgs);
        self.gruppi = cfgs[0];
        self.utenti = cfgs[1];
        
    });
}

PermessiCtrl.prototype.getFormMenu = getFormMenu;
PermessiCtrl.prototype.getFormModuli = getFormModuli;
PermessiCtrl.prototype.aggiornaPermessi = aggiornaPermessi;
PermessiCtrl.prototype.rimuoviEccezioniGruppo = rimuoviEccezioniGruppo;
PermessiCtrl.prototype.rimuoviEccezioneUtente = rimuoviEccezioneUtente;
PermessiCtrl.prototype.revocaEccezione = revocaEccezione;
PermessiCtrl.prototype.ridimensiona = ridimensiona;

function ridimensiona(){
    console.log("ridimensiono la finestra per visualizzare il contenuto delle grid");
    var originalSize = {w: window.innerWidth, h: window.innerHeight};
    window.resizeTo(originalSize.w-1, originalSize.h-1);
    window.resizeTo(originalSize.w, originalSize.h);
}

function getFormMenu(dataItem){
    var self = this;
    return new this.mp(dataItem, self.tabLivello, self.utentiGrid).opzioniAlbero();
}

function getFormModuli(dataItem){
    var self = this;
    return new this.gmp(dataItem).inizializza().then(function(risp){
        //console.log("configurazione griglia da permessictrl: ", risp.dataSource);
        self.moduliCfg[dataItem.uid] = risp;
        return risp;
    });
    
}

function aggiornaPermessi(dataItem, griglia){
    var self = this;
    
    self.inModificaModuli = true;
    
    self.pmm.aggiornaPermessi(dataItem).then(function(risp){
        self.inModificaModuli = false;
        griglia.dataSource.read();
    }, function(err){
        self.inModificaModuli = false;
    });

}

function rimuoviEccezioniGruppo(dataItem, griglia){
    var self = this;
    if(confirm("ATTENZIONE: Procedendo cancellerai le eccezioni dei permessi relativi al modulo '" + dataItem.modulo + "' per utenti appartenenti al gruppo '" + dataItem.gruppo + "'. Sei sicuro di voler procedere?")){
        self.revocaEccezione(dataItem, griglia);
    }
    
}

function rimuoviEccezioneUtente(utente, menu, albero){
    var self = this;

    var parametri = {idutente: utente.id, idmenu: menu.id};
    
    console.log("parametri rimuoviEccezioneUtente", utente, menu);
    if(confirm("ATTENZIONE: Procedendo cancellerai le eccezioni dei permessi relativi al menu '" + menu.text + "' per l'utente '" + utente.loginName + "'")){
        //qui dataitem deve avere idmenu e idutente e devo passare la griglia di pertinenza
        self.pmm.revocaEccezioneMenuUtente(parametri, albero).then(function(risp){
            self.inModificaModuli = false;
            
            albero.dataSource.read();
        }, function(err){
            self.inModificaModuli = false;
        });
    }
}

function revocaEccezione(dataItem, griglia){
    var self = this;
    self.pmm.revocaEccezione(dataItem).then(function(risp){
        self.inModificaModuli = false;
        
        if(!dataItem.loginName){
            //console.log("Ho revocato i permessi di un gruppo, ricarico la tab degli utenti. Dovrei vedere il messaggio 'contenuto caricato utenti'");
            self.tabLivello.reload("li:eq(1)");
        }
        //console.log("ricarico la griglia sulla quale sto lavorando, griglia di ", griglia);
        griglia.dataSource.read();
    }, function(err){
        self.inModificaModuli = false;
    });
}



module.exports = PermessiCtrl;
