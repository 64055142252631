'use strict';
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");

/**
 * Genera il form di inserimento partendo dall'header
 * 
 * @class
 * @param {ActionFactory} ActFct
 * @param {FieldFactory} FieldFactory
 * @param {FieldService} FieldService
 * @param {ToolbarService} ToolbarService
 * @param {ErrorManager} ErrorManager
 */
function FormDaHeaderFactory(ActFct, FieldFactory, FieldService, ToolbarService, ErrorManager){
    /**
     * Ottiene tutti i campi ed espone il submit del form sulla toolbar .
     * 
     * @memberOf FormDaHeaderFactory
     * @constructor
     * @param {type} nome
     * @param {type} header
     * @param {type} azioni
     * @param {type} opzioni
     * @param {type} formController
     */                        
    function Costruttore(nome, header, azioni, opzioni, formController, disconnettiToolbar){        
        this.header = header;
        //this.gridDS = this.griglia.dataSource;
        //this.gridModel = this.gridDS.options.schema.model;
        this.nome = nome;
        this.mode = "Inserisci";        
        this.campi = this.getCampiInserisci();
        this.modello = this.getModelloInserisci();
        this.exposed = [];
        this.formController = formController;
        
        angular.extend(this, EsponiMixin.prototype, opzioni);
        
        if(azioni){
            this.exposed = azioni;
        }

        //console.log("creato form: -campi", this.campi, "-modello", this.modello)
        if(!disconnettiToolbar){
            ToolbarService.actionsProviders.formSubmitter = this;
        }
    }
    
    //ritorna tutti i campi della griglia che non sono nascosti
    Costruttore.prototype.getCampiDaHeader = getCampiDaHeader;
    //ritorna solo i campi inseribili
    Costruttore.prototype.getCampiInserisci = getCampiInserisci;
    //ottiene il modello per l'inserimento
    Costruttore.prototype.getModelloInserisci = getModelloInserisci;
    //riordina i campi
    Costruttore.prototype.riordinaCampi = riordinaCampi;
    //lista univoca raggruppamenti
    Costruttore.prototype.listaRaggruppamenti = listaRaggruppamenti;
    //fornisce ai campi senza raggruppamento un raggruppamento di default
    Costruttore.prototype.settaRaggruppamentoDefault = settaRaggruppamentoDefault;
    //ritorna i campi raggruppati
    Costruttore.prototype.campiRaggruppati = campiRaggruppati;
    //se è presente anche un solo campo raggruppato torna i campi raggruppati altrimenti torna i campi semplicemente riordinati
    Costruttore.prototype.raggruppaCampi = raggruppaCampi;        
    
    /**
     * Ottiene tutti i campi dalla griglia
     * 
     * @memberOf FormDaHeaderFactory
     * @returns tutti i campi che non sono nascosti
     * 
     */
    function getCampiDaHeader(){
        var campi = [];
        for(var x in this.header.formmodel.fields){
            var campoDaHeader = FieldService.cfgCampoDaHeader(this.header, x);
            if(campoDaHeader !== null){
                //prova classe css
                //decommenta per riprendere la configurazione dalle columns
                //var cfgColonnaHeader = _.findWhere(this.griglia.columns, {field: x});
                
                const cfgColonnaHeader = _.findWhere(this.header.form, {field: x});
                //console.log("cfg colonna header:", cfgColonnaHeader);
                
                var opzioniAggiuntive = {};
                
                if(cfgColonnaHeader.formstyleclass !== undefined && cfgColonnaHeader.formstyleclass !== null && cfgColonnaHeader.formstyleclass !== "" ){
                    opzioniAggiuntive.className = cfgColonnaHeader.formstyleclass;
                }

                if(![null, undefined, false, ''].includes(cfgColonnaHeader.hideExpression)){
                    opzioniAggiuntive.hideExpression = "true"                
                }
                
                if(cfgColonnaHeader.expressionProperties !== undefined && cfgColonnaHeader.expressionProperties !== null && cfgColonnaHeader.expressionProperties !== "" ){
                    opzioniAggiuntive.expressionProperties = cfgColonnaHeader.expressionProperties;
                }
                
                //console.log("campo da header: ", campoDaHeader);
                var campo = new FieldFactory(campoDaHeader, false, opzioniAggiuntive);
                //console.log("campo da field factory (header)", campo);
                campi.push(campo.opzioni);
            }
        }
        
        var self = this;
        
        var mode = 'Inserisci';

        var campinonnascosti = campi.filter(function(campo){
            var nascosto = FieldService.isCampoNascosto(campo, mode);
            //console.log(campo.key, "è nascosto?", nascosto);
            return !nascosto;
        });

        //console.log("solo campi non nascosti", campinonnascosti);
        
        return campinonnascosti;
    }
    
    
    /**
     * Riordina tutti i campi in base all'ordine delle colonne
     * 
     * @param {type} campi lista di campi non raggruppati e non ordinati
     * @memberOf FormDaHeaderFactory
     * @returns tutti i campi raggruppati
     */
    function riordinaCampi(campi){

        //decommentare per riprendere la configurazione dalle columns
        //var nomiCampiOrdinati = _.pluck(this.griglia.columns, "field");
        var nomiCampiOrdinati = _.pluck(this.header.form, "field");

        //console.log("lista ordinata", nomiCampiOrdinati, "campi nell'ordine iniziale: ", campi);
        var campiriordinati = _.filter(_.map(nomiCampiOrdinati, function(nomeCampo){
            return _.findWhere(campi, {key: nomeCampo});
        }), function(campo){
            return campo !== undefined;
        });
        return campiriordinati;
    }
    
    /**
     * Ottiene la lista dei raggruppamenti senza duplicati
     * 
     * @param {type} lista di campi
     * @memberOf FormDaHeaderFactory
     * @returns tutti nomi dei raggruppamenti
     */
    function listaRaggruppamenti(campi){
        return _.uniq(_.filter(_.map(campi, function(campo){
            return campo.data.colonna.raggruppamento;
        }), function(raggruppamento){
            return raggruppamento !== undefined;
        }), function(raggruppamento){
            return raggruppamento.key;
        });
    }
    
    /**
     * Fornisce un raggruppamento ai campi che sono sprovvisti di raggruppamento
     * @memberOf FormDaHeaderFactory
     * @param {type} campi
     * @param {type} raggrDef oggetto {key,label} che rappresenta il raggruppamento di default
     * @returns {undefined}
     */
    function settaRaggruppamentoDefault(campi, raggrDef){
        return _.map(campi, function(campo, indice){
            if(!campo.data.colonna.raggruppamento) {
                campo.data.colonna.raggruppamento = raggrDef;
            }
            return campo;
        });
    }
    
    /**
     * Ritorna i campi nella struttura richiesta da formly per il raggruppamento
     * @memberOf FormDaHeaderFactory
     * @param {type} campi
     * @param {type} raggruppamenti
     * @returns {undefined|Array|FormDaGridFactory.campiRaggruppati.campiraggruppati}
     */
    function campiRaggruppati(campi, raggruppamenti){
        var campiraggruppati = [{
            key: 'maintab', 
            wrapper: "BssTabWrapper",
            data: { tabs: raggruppamenti },
            fieldGroup: []
        }];

        //console.log("lista raggruppamenti ", raggruppamenti);

        _.map(raggruppamenti, function(raggruppamento){
            campiraggruppati[0].fieldGroup.push({
                "key": raggruppamento.key,
                "wrapper": "BssFormPanelWrapper",
                "data": {
                    "label": raggruppamento.label,
                    "key": raggruppamento.key
                },
                fieldGroup: _.filter(campi, function(campo){
                    return campo.data.colonna.raggruppamento.key === raggruppamento.key;
                })
            });
        });
        
        return campiraggruppati;
    }

    /**
     * Ottiene tutti i campi con gli opportuni raggruppamenti
     * 
     * @param {type} campi lista di campi non raggruppati
     * @memberOf FormDaHeaderFactory
     * @returns tutti i campi raggruppati
     */
    function raggruppaCampi(campi){
        //riordino i campi
        campi = this.riordinaCampi(campi);

        //se non sono presenti raggruppamenti ritorno i campi riordinati
        if(!this.listaRaggruppamenti(campi).length) return campi;
        
        //associo ai campi senza raggruppamento il raggruppamento di default    
        campi = this.settaRaggruppamentoDefault(campi, {key: 'Default', label: 'Altro'});

        //creo la struttura dei campi con il raggruppamento
        return this.campiRaggruppati(campi, this.listaRaggruppamenti(campi));   

    }

    
    /**
     * Ottiene tutti i campi che servono per l'inserimento
     * 
     * @memberOf FormDaHeaderFactory
     * @returns tutti i campi che non sono nascosti per l'inserimento
     * 
     */    
    function getCampiInserisci(){
        var tutticampi = this.getCampiDaHeader();
        //console.log("campi: ", tutticampi);
        var campi = _.filter(tutticampi, function(campo){
            //console.log("campo: ", campo);
            return campo.data.modello.insertable;
        });
        //console.log("vedi come sono raggruppati i campi in inserimento");
        return this.raggruppaCampi(campi);
    }
    
    
    
    /**
     * Ottiene il modello per l'inserimento
     * 
     * @memberOf FormDaHeaderFactory
     * 
     */      
    function getModelloInserisci(){
        return {};
    }
    
    return Costruttore;
}

module.exports = FormDaHeaderFactory;