"use strict";

/**
 * @module bss/notifiche
 * @description Modulo che permette di usare le notifiche nell'angolo basso a destra di tipo, info, warning, error, success
 */
var bssnotifiche = angular.module("bss.notifiche", ["ui-notification", "ui.router", "pascalprecht.translate"])    
    // .decorator("Notification", ["$delegate", function($delegate){   
    //     window.notifdeleg = $delegate;     
    //     console.log("decorating Notification", $delegate.prototype);        
    //     return $delegate
    // }])
    .config(["NotificationProvider", require("./NotifierCfg")])
    .service("ErrorManager", ['Notification', '$state', '$translate', '$sce', '$timeout', require("./ErrorManagerSrv")])    
;

module.exports = bssnotifiche;