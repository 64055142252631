'use strict';

require("moduleFold/router/scripts/index.js");
require("moduleFold/traduzioni/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");

/**
 * 
 * @module bss/genericidentity
 * @description fornisce le classi da estendere per un modulo relativo alle identity
 */
var bssws = angular.module("bss.genericidentity", ["bss.router", "bss.traduzioni", "bss.griglia", "bss.datasource", "bss.toolbar", "bss.notifiche"])
    .service("IdentityRS", [require("./IdentityRS")])
    .factory("GridIdentity", ["DSCfgFactory", "GridCfgFactory", "ToolbarSrv", "GridNavigator", "$timeout", "ActionFactory", "$rootScope", "$state", "$translate", "ErrorManager", "StatoApp", "Valida", require("./GridIdentity")])
;

module.exports = bssws;