"use strict";

/**
 * Gestisce tutta la messaggistica di notifica dell'applicazione
 * 
 * @class
 * @param {type} Notification
 * @param {type} $state
 * @param {type} $translate
 * @returns {ErrorManagerSrv}
 */
function ErrorManagerSrv(Notification, $state, $translate, $sce, $timeout){
    var self = this;
    self.notifier = Notification;
    self.trsrv = $translate;
    self.$timeout = $timeout;
    self.$sce = $sce;
}


ErrorManagerSrv.prototype.show = show;
ErrorManagerSrv.prototype.showT = showT;
ErrorManagerSrv.prototype.getPredefinedMessage = getPredefinedMessage;
ErrorManagerSrv.prototype.errorCB = errorCB;
ErrorManagerSrv.prototype.aggiornaMessaggiASchermo = aggiornaMessaggiASchermo;

/**
 * Mostra una notifica contenente il messaggio passato come primo parametro
 * 
 * @memberOf ErrorManagerSrv.prototype
 * @param {string} messaggio
 * @param {string} [tipo='error'] 
 * 
 * @example
 * ErrorManagerSrv.show("messaggio di errore in box rosso");
 * ErrorManagerSrv.show("messaggio di warning in box giallo", "warning");
 * ErrorManagerSrv.show("messaggio di info in box blu", "info");
 * ErrorManagerSrv.show("messaggio di success in box verde", "success");
 */
function show(messaggio, tipo){
    var self = this;
    self.aggiornaMessaggiASchermo(messaggio, tipo).then(function(){
        self.notifier[tipo || 'error'](messaggio);
    })    
}

/**
 * Mostra una notifica contenente il messaggio ottenuto dal servizio di traduzione
 * 
 * @example
 * ErrorManagerSrv.showT("SYSTEMERR.NONGESTITO", "error", {codice: 1234});
 * 
 * @memberOf ErrorManagerSrv.prototype
 * @param {string} messaggio è una translationKey
 * @param {string} [tipo='error'] uno tra: info, success, warning, error
 * @param {Object} parametri parametri da sostituire ai placeholder del messaggio
 */
function showT(translationKey, tipo, parametri){
    var self = this;
    self.trsrv(translationKey, parametri).then(function(messaggio){
        self.show(messaggio, tipo);
    });
    
}


/**
 * Si occupa di ritornare un array di messaggi
 * data è un array ogni elemento di questo array è qualcosa del tipo {message:}
 * status è lo status code delle response
 * config è un oggetto con la proprietà url
 * Per status e config dipendono dal fatto che ErrorManager gestisce gli errori dei webservice
 * e quindi rispettano la sintassi di una response http
 * Per data invece la forma dipende dalla response degli errori gestiti da BssWebCore
 * 
 * @memberOf ErrorManagerSrv.prototype
 * @param {Object} data da response.data
 * @param {string} status status code della response (response.status)
 * @param {Object} config response.config
 * @return {Array} messaggi array di messaggi
 */
function getPredefinedMessage(data, status, config){
    var messaggi = [];
    var self = this;
    
    console.log("status: ", status);
    
    if(status === 404){
        //console.error("Mostro notifica errore " + status);
        messaggi.push(config.url + " - Non trovato!");
    }
    
    if(status === 401 || status === 403){
        //console.error("Mostro notifica errore " + status);
        messaggi.push("Accesso non consentito!");
        
        //$state.go("login");
    }
    
    if([403, 404].indexOf(status) === -1){
        //console.error("Mostro notifica errore ", status, "  dati ", data);
        if(data.byteLength !== undefined){
            var strfrombuffer = window.ab2str(data);
            data = JSON.parse(strfrombuffer);
        }


        if(Array.isArray(data)){
            
            //console.error("scorro gli errori ("+data.length+")");
            for(var x = 0; x < data.length; x++){
                //console.error("errore iterazione: ");
                //console.error(data[x]);
                if(data[x].message){//messaggi di errore formattati
                    //console.error("mostro notifica d'errore da message");
                    //messaggi.push(data[x].message);
                    self.show(data[x].message, data[x].type);
                }
                else {
                    //console.error("mostro notifica d'errore generico da array di errori");
                    messaggi.push("Ops, si è verificato un errore.");
                }
            }
        }
        else {
            //console.error("mostro notifica d'errore generico senza array");
            messaggi.push("Ops, si è verificato un errore.");
        }
    }
    
    return messaggi;
}

/**
 * Mostra un messaggio di errore in base a una response
 *
 * @memberOf ErrorManagerSrv.prototype
 * @param {response} response
 */
function errorCB(response){
    var self = this;
//    console.log("notifier da error manager:");
//    console.error("log errore: ");
//    console.error(response);
    var data = response.data;
    var status = response.status;
    var config = response.config;
    
    var messaggi = self.getPredefinedMessage(data, status, config);
    
    for(var x = 0; x < messaggi.length; x++){
        self.show(messaggi[x]);
    }
    
}

function aggiornaMessaggiASchermo (msg, tipo) {
    var self = this;
    return self.$timeout(function(){
        angular.forEach(angular.element(".ui-notification"), function(el){
            var notscop = angular.element(el).scope();
            var messaggio = self.$sce.valueOf(notscop.message);
            if(messaggio === msg) {
                notscop.kill(true);
            }        
        })
    })
    
}

module.exports = ErrorManagerSrv;