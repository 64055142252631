"use strict";

/**
 * Controlla che il valore sia non null
 * 
 * @class
 * @param {$translate} $translate
 */
function NullableFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf NullableFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */    
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.NULLABLE", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
            //console.log(self.message);
        }
//            ,function(k){
//                alert(k + " non trovata tra le chiavi");
//            }
        );

    }
    
    Costruttore.prototype.expression = expression;
    
    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf NullableFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */      
    function expression(viewValue, modelValue, scope){
//        var valore = modelValue || viewValue || null;
//        console.log("NULLABLE) modelvalue", modelValue, "viewvalue", viewValue, "valore", valore);
//        if(modelValue === 0 || viewValue === 0){//se il valore è zero non deve annullarsi
//            valore = 0;
//        }
//        console.log("valore cambiato: ", valore);
        var valore = viewValue;

        //console.log("nullable", scope.options.data.modello.validations)
        
        if(scope.options.data.modello.validations.nullable){
            return true;
        }
        else {
            //console.log("NULLABLE FALSE) modelvalue", modelValue, "viewvalue", viewValue, "valore", valore);
            return valore !== null && typeof valore !== 'undefined' && valore !== '';
        }
    }
        
    return Costruttore;
}

module.exports = NullableFct;