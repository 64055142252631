'use strict';

/**
 * Wrapper che aggiunge la label
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BssLegendWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "bsslegendwrapper",
        template: require("./templates/bsslegend.html"),
        types: ["bsslocalita", "bssrangedate"],
    })
}

module.exports = BssLegendWrapper;