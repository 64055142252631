'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");

/**
 * @module bss/modulobase
 * @description Modulo base
 * 
 */
var bssmodulobase = angular.module("bss.modulobase", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.genericidentity", "bss.toolbar"])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/Routes")])   
;

module.exports = bssmodulobase;