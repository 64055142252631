'use strict';

/**
 * Wrapper che aggiunge gli errori
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BssErrorWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "BssErrorWrapper",
        template: require("./templates/bsserror.html"),
        types: ["bssinput", "bssiconpicker", "bsstextarea", "bssselect", "bssfile",  "bssfile2", "bsssyncfile", 
            "bssboolcheckbox", "bssnumeric", "bssdatetime", "bssdate", "bsstime", 
            "bsszoom", "bsspassword", "bssmultiselect", "bsslaum", "bsssingleselect", 
            "bssplaceautocomplete", "bsslocalita", "bsstags", "bsslocalizedstring", "bsszoom", "bsscalendario", "bsscolorpicker"],
    })
}

module.exports = BssErrorWrapper;



