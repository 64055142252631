'use strict';

/**
 * 
 * @example
 * 
 * {{num | bssnumberfilter: {fractlen: 2, fractsep: ',', neg: true} }}
 */
function BSSNumberFilter(){
    var cfgStd = {
        fractlen: 2,
        fractsep: ',',
        neg: true
    };
    
    return function(input, cfg){
        var cfg = angular.extend({}, cfgStd, cfg);
        input = Number(input);
        
        
        if(!cfg.neg && input < 0) {
            input = 0;
        }
        
        input = input.toFixed(cfg.fractlen);
        input = ("" + input).replace(".", ",");
        return input;
    }
}

module.exports = BSSNumberFilter;