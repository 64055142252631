'use strict';

/**
 * 
 * @issue non riesco ad impostare il valore programmaticamente
 * Il valore viene aggiornato correttamente ma non visualizzato a schermo tranne che per il defaultValue (angular-formly)
 * Prove fatte:
 * - ng-model al posto di k-ng-model => model[options.key] viene impostato a "1" cioè al primo id che trova nel datasource
 * - k-ng-model con k-value-primitive true/false (a false dovrebbe accettare anche oggetti come valori) => non cambia niente
 * - lanciare manualmente trigger("change") sull'elemento del widget => non cambia niente
 * - usato il metodo setter widget.value(valore) => non cambia niente
 * Controller del campo singleselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSSingleselectController($scope, $rootScope, $filter, $timeout, $http, $state, ErrorManager, DSF){
    
    $scope.dsf = DSF;
    
    var modello = $scope.options.data.modello;
    
    //console.log("modello multiselect: ", modello);
    
    //    var eccezione = _.some(["strentity", "codmodulo", "datatextfield", "datavaluefield"], function(el){
    //        return (typeof modello[el] === 'undefined');
    //    });
    //    
    //    if(eccezione){
    //        //var messaggio = "Il campo '" + $scope.to.label + "' è di tipo combo, il modello dovrebbe avere: 'strentity', 'codmodulo', 'datatextfield', 'datavaluefield'. Vedi console per ulteriori dettagli" 
    //        //ErrorManager.show(messaggio, "error");
    //        var messaggioConsole = "Configurazione errata: " + JSON.stringify(modello);
    //        ErrorManager.showT("FORMBUILDER.COMBOCFGERROR", "error", {campo: $scope.to.label});
    //        throw messaggioConsole;
    //       
    //    }
    

    /**
     * 
     * @description data chiavemodel ritorna il valore (letto $scope.model cioè il model del form corrente)
     * @example
     * $scope.model = {valore: {id: 1, label: "val1"}, altrovalore: {sottoobj :{text: "val2", value: 5}}, valoresemplice: 42}
     * //es 1:
     * chiavemodel = 'valore.id';
     * valoremodel = 1
     * //es 2:
     * chiavemodel = 'altrovalore.sottoobj.value'
     * valoremodel = 5
     * //es 3:
     * chiavemodel = 'valoresemplice'
     * valoremodel = 42
     * 
     * @param {string} chiavemodel 
     * @returns {*} valoremodel 
     */
    // function calcolaValoreSegmento(chiavemodel){
    //     return chiavemodel.split(".").reduce(function(valore, prop){
    //         return valore[prop] !== undefined ? valore[prop] : {}
    //     }, $scope.model)
        
    // }


    /**
     * 
     * @issue i campi vengono inizializzati subito quindi se il campo da cui dipendono non è valorizzato all'inizializzazione si verificano errori e il campo diventa inutilizzabile
     * @description Permette di calcolare l'strentity sulla base di altri valori nello stesso form
     * @example
     *  //es 1
     *  strentity = 'tnazioni' => 'tnazioni' //nessuna modifica
     *  //es 2
     *  strentity = 'documentistd/@raggruppamento.id@/@tipodoc.id@' => 'documentistd/1/2'
     * @param {string} strentity stringa contenente l'strentity
     * @returns {string} strentity dinamica
     * 
     */
    function leggiStrentity(strentity){
        return strentity;

        var regex = /@.*@/gm;
      
        var segmenti = strentity.split("/").map(function(segmento){
          var matches = segmento.match(regex)
          var chiavemodel = matches[0].substring(1, matches[0].length-1);
          return matches === null ? segmento : bssRecursiveGetter($scope.model, chiavemodel, null) 
        });
        
        //console.log(segmenti)
        
        return segmenti.reduce(function(segmentiparsed, segmento){
          segmentiparsed.push(segmento);
          return segmentiparsed;
        }, []).join("/");
      
      
      }
      
    
    
    $scope.singlesel = {
        strentity: modello.strentity || null, //se non passo questo devo passare ds
        codmodulo: modello.codmodulo || null, //se non passo questo devo passare ds
        pagination: modello.pagination || null,
        codapp: modello.codapp || 'aaa',
        dataTextField: modello.datatextfield || modello.datavaluefield,
        dataValueField: modello.datavaluefield,
        template: modello.template || "#= "+modello.datatextfield+" #",
        footerTemplate: modello.footertemplate || "<div class='bssmultiselectFooterWrapper'><span class='bssmultiselectFooter'>Stai visualizzando <b>#= Math.min(instance.dataSource.data().length, instance.dataSource.pageSize()) #</b> di  <b>#= instance.dataSource.total() #</b> elementi.</span></div>",
        caricamento: true,
        ds: modello.ds || null, //elementi da mostrare
        opzioniagg: modello.opzioniagg || {},
        onSelect: modello.onSelect || null,
        onCascade: modello.onCascade || null,
        onDeselect: modello.onDeselect || null,
        onChange: modello.onChange || null,
        groupField: modello.groupField || null,
        readUrl: modello.readUrl || null,
        dataRead: modello.dataRead || null,
        modelOverride: modello.modelOverride || null,
        externalUrl: modello.externalUrl || null,
        externalUrlCfg: modello.externalUrlCfg || null,
        //height: 325,
        //decommentare non appena il webservice /indicedaid sarà attivo
        virtual: modello.virtual || {
            itemHeight: 26,
            //mapValueTo: 'dataItem',
            valueMapper: function(options){
                var parametri = {};
                parametri[modello.datavaluefield] = options.value[modello.datavaluefield];
                $http({
                    url: utils.urls.wsbase + "/" + modello.strentity + "/indicedaid",
                    data: $.param(parametri)
                    //se mapValueTo vale dataItem (di default index)
                    //                    url: utils.urls.wsbase + "/" + modello.strentity + "/cerca",
                    //                    data: $.param(parametri),
                    //                    method: "POST",
                }).then(function(response){
                    console.log("response valuemapper");
                    options.success(response.data); 
                });
            }
        },        
        
        //se true aggiunge la voce di default 'tutti' NON FUNZIONA
        //voceTutti: modello.voceTutti
    };    
    
    if($scope.singlesel.externalUrlCfg){
        $scope.singlesel.externalUrl = $state.href($scope.singlesel.externalUrlCfg.url,  $scope.singlesel.externalUrlCfg.parametri);
    } 
    
    
    if($scope.singlesel.groupField){ 
        $scope.singlesel.opzioniagg.group = {field: $scope.singlesel.groupField};
    }
    
    if($scope.singlesel.readUrl){
        $scope.singlesel.opzioniagg.transport = {read: { url: $scope.singlesel.readUrl, type: 'POST' } };       
        if($scope.singlesel.dataRead){
//            console.log("single select, data read: ", $scope.singlesel.dataRead);
            $scope.singlesel.opzioniagg.transport.read.data = $scope.singlesel.dataRead;       
        }
    }

    
    if($scope.singlesel.modelOverride){
        $scope.singlesel.opzioniagg.options = {
            schema: {
                model: $scope.singlesel.modelOverride 
            } 
        }; 
    } 
    
    var opzioni = {
        template: $scope.singlesel.template,
        
        footerTemplate: $scope.singlesel.footerTemplate,
        tagTemplate: $scope.singlesel.template,
        dataTextField: $scope.singlesel.dataTextField,
        dataValueField: $scope.singlesel.dataValueField,
        filter: $scope.singlesel.filter || "contains",
        autoBind: true,
        externalUrl: $scope.singlesel.externalUrl,
        //minLength: 0, //per mostrare le opzioni anche senza inserire niente occorre rimuovere minLength, impostandolo a 0 non funziona anche se la documentazione dice che di default vale 0
        maxSelectedItems: $scope.singlesel.maxSelectedItems,
        //virtual: $scope.singlesel.virtual,
        height: $scope.singlesel.height,
    }; 
    
    if(modello.cascadeFrom){
        opzioni.cascadeFrom = modello.cascadeFrom;
    }
    
    if(modello.cascadeFromField){
        opzioni.cascadeFromField = modello.cascadeFromField;
    }
    
    if(_.isFunction($scope.singlesel.onSelect)){
        opzioni.select = function(e){
            $scope.singlesel.onSelect(e);
        }
    }
    
    if(_.isFunction($scope.singlesel.onCascade)){
        opzioni.cascade = function(e){
            $scope.singlesel.onCascade(e);
        }
    }
    
    
    if(_.isFunction($scope.singlesel.onDeselect)){
        opzioni.deselect = function(e){
            $scope.singlesel.onDeselect(e);
        }
    }
    
    
    if(_.isFunction($scope.singlesel.onChange)){
        opzioni.change = function(e){
            $scope.singlesel.onChange(e);
        }
    }     

    /**
     * dovrebbe assicurare che il datasource paginato contenga il valore del campo, quindi aggiorna il valore del campo
     * serve a modificare programmaticamente il valore della single select
    **/
    $scope.aggiornaDSDaValore = function(valore){

        console.log($scope.singleselwidget, $scope.options.data.modello)

        $scope.filtroBase = {
            field: "id", operator: 'eq', value: valore.id          
        };            
        $scope.singlesel.opzioni.dataSource.filter($scope.filtroBase);            
        $scope.singlesel.opzioni.dataSource.read().then(function(resp){
            $timeout(function(){
                $scope.model[$scope.options.key] = valore;
                $scope.singleselwidget.value(valore)
                $scope.singleselwidget.element.trigger('change')
            })
            
        });            

        
    }


    $scope.$on("kendoWidgetCreated", function(e){
        if(e.targetScope.singleselwidget !== undefined){
            $scope.singleselwidget = e.targetScope.singleselwidget
            //console.log("kendo widget dropdownlist", e);
        }
    })

    $scope.configuraDS = function(kendoDS){     
        //paginazione da configurazione           
        if($scope.singlesel.pagination){
            if($scope.singlesel.pagination.pageSize){
                kendoDS.pageSize($scope.singlesel.pagination.pageSize);
            }
            if($scope.singlesel.pagination.page){
                kendoDS.page($scope.singlesel.pagination.page);
            }
            if($scope.singlesel.pagination.skip){
                kendoDS.skip($scope.singlesel.pagination.skip);
            }
        }


        //$scope.singlesel.opzioni.dataSource = kendoDS;                                 
        
        /**
         * aggiunge ai filtri il filtro per id del valore serve ad impostare il valore iniziale ma non permette di impostare successivamente il valore in maniera programmatica
         * vedi $scope.aggiornaDSDaValore
        **/
        if($scope.model[$scope.options.key] && ($scope.model[$scope.options.key].id !== undefined)){            
            $scope.filtroBase = {
                field: "id", operator: 'eq', value: $scope.model[$scope.options.key].id                
            };            
            kendoDS.filter($scope.filtroBase);            
        }
        
        //opzioni.optionLabel = " ";
        opzioni.dataSource = kendoDS;
        //console.log("kendoDS: ", kendoDS);
        $scope.singlesel.opzioni = opzioni;
        
        /*
         * svuota filtri all'apertura
         */
        $scope.singlesel.opzioni.open = function(e){        
            $scope.singlesel.opzioni.dataSource.filter([]);
        }        
        
        $scope.singlesel.caricamento = false;


    }

/**
 * @todo
 * NON VA 
 */
    $scope._configuraDS = function(kendoDS){                
        //$scope.singlesel.opzioni.dataSource = kendoDS;           
        
        kendoDS.transport.parameterMap = function(data, type){
            if(type === 'read'){
                if($scope.model[$scope.options.key] && ($scope.model[$scope.options.key].id !== undefined)){            
                    $scope.filtroBase = {
                        field: "id", operator: 'eq', value: $scope.model[$scope.options.key].id                
                    };     
                    var currfilters = kendoDS.filter().filters();
                    currifilters.push({logic: 'or', filters: $scope.filtroBase});       
                    kendoDS.filter(currfilters);            
                }
            }
            return data
            
        }                
                
        //opzioni.optionLabel = " ";
        opzioni.dataSource = kendoDS;
        //console.log("kendoDS: ", kendoDS);
        $scope.singlesel.opzioni = opzioni;
               
        $scope.singlesel.caricamento = false;


    }

    
    if($scope.singlesel.strentity !== null){
        //console.log("dynstr", leggiStrentity($scope.singlesel.strentity))


        new DSF(leggiStrentity($scope.singlesel.strentity), $scope.singlesel.codapp, $scope.singlesel.codmodulo, $scope.singlesel.opzioniagg).inizializza().then(function(kendoDS){
            //console.log("specificata strentity ds:", kendoDS)
            /**
             * rendo dinamico l'url di lettura del datasource per gestire i campi che dipendono da altri campi
             */
            kendoDS.transport.read.url = function(options){
                var url = utils.urls.wsbase + "/" + leggiStrentity($scope.singlesel.strentity);
                console.log("calcolata strentity da", $scope.singlesel.strentity, "ottengo", url)
                return url
            }
            $scope.configuraDS(kendoDS);
        });
    }
    else {
        //caso in cui ho il datasource non mi serve crearlo da strentity
        var ds = new kendo.data.DataSource($scope.singlesel.ds);
        //console.log("datasource motivazioni giacenze: ", ds);
        $scope.configuraDS(ds);
    }
    
    
    
    
    
    
    $scope.apriLinkEsterno = function(){  
        /*      
        var ah = window.screen.availHeight - 30;
        var aw = window.screen.availWidth - 10;
        var width = 1200;
        var height = 800;
        var xc = (aw - width) / 2;
        var yc = (ah - height) / 2;        
        var opzioni = "height="+height+",innerHeight="+height+",width="+width+",innerWidth="+width+",left="+xc+",screenX="+xc+",top="+yc+",screenY="+yc+"scrollbars=yes";
        console.log("opzioni finestra: ", opzioni);
        window.open($scope.singlesel.externalUrl, $scope.singlesel.opzioni.label , opzioni);
        */
        $rootScope.genericwin.content("<iframe src='"+$scope.singlesel.externalUrl+"' width='99%' height='99%' />");
        $rootScope.genericwin.open().center();
    }
    
}

module.exports = BSSSingleselectController;