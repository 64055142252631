'use strict';

require("moduleFold/genericidentity/scripts/templates/genericmanager.html");
require("moduleFold/mastrodettaglio/scripts/templates/mastrodettaglio.html");
require("moduleFold/mastrodettaglio/scripts/templates/mastrodettagliov2.html");
require("moduleFold/mastrodettaglio/scripts/templates/mastrodettagliov3.html");

var GridResizer = require("moduleFold/estensioni/Grid/GridResizer.js");

/**
 * Classe astratta relativa alle grid degli identity manager
 * 
 * @class
 */

function GridIdentity(DSCfgFactory, GridCfgFactory, ToolbarS, GridNav, $timeout, ActFct, $rootScope, $state, $translate, ErrorManager, StatoApp, Valida){
    
    function Costruttore(avoidInit, opzioniAggiuntive){
        var self = this;

        self.opzioniAggiuntive = opzioniAggiuntive;
        
        //this.identity = UtentiIdentity; !IMPORTANTE SETTARE NELLA CLASSE CHE SPECIALIZZA GridIdentity
        //this.rs = UtentiRS; !IMPORTANTE SETTARE NELLA CLASSE CHE SPECIALIZZA GridIdentity
        self.statoapp = StatoApp;
        self.mode = "griglia";
        self.valida = Valida;
        var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
        var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
        angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype);
        
        this.dscfgf = DSCfgFactory;
        this.gcfgf = GridCfgFactory;
        this.gn = GridNav;
        this.af = ActFct;
        this.ts = ToolbarS;
        this.$state = $state;
        this.tr = $translate;
        this.timeout = $timeout; 
        this.em = ErrorManager;
        
        if(avoidInit){}
        else {
            this.inizializza();
        }
        
             
    }
    
    Costruttore.prototype.inizializza = inizializza;
    //controlla in base a determinati criteri la dimensione della selezione
    Costruttore.prototype.crea = crea;
    Costruttore.prototype.duplica = duplica;
    Costruttore.prototype.modifica = modifica;
    Costruttore.prototype.visualizza = visualizza;
    Costruttore.prototype.elimina = elimina;
    Costruttore.prototype.preSalva = preSalva;
    Costruttore.prototype.salva = salva;
    Costruttore.prototype.salvacrea = salvacrea;
    Costruttore.prototype.salvamodifica = salvamodifica;
    Costruttore.prototype.ricerca = ricerca;
    Costruttore.prototype.getGridCfg = getGridCfg;
    Costruttore.prototype.getDSCfg = getDSCfg;
    Costruttore.prototype.mostraGriglia = mostraGriglia;
    Costruttore.prototype.aggiornaForm = aggiornaForm;
    Costruttore.prototype.disabilitaSalva = disabilitaSalva;
    Costruttore.prototype.ascoltaCambioSelezione = ascoltaCambioSelezione;
    Costruttore.prototype.ascoltaCambioDS = ascoltaCambioDS;
    Costruttore.prototype.getAzioni = getAzioni;
    Costruttore.prototype.dopoSalva = dopoSalva;
    Costruttore.prototype.controlloCambioStato = controlloCambioStato;
    
    
    /**
     * @memberOf GridIdentity
     */  
    function inizializza(){
        this.dsf = new this.dscfgf(null, null, null, null, true);
        this.grf = new this.gcfgf(null, null, null, null, true);
        this.dsf.setOpzioni(this.getDSCfg());
        this.grf.setOpzioni(this.getGridCfg());
        this.dataSource = this.dsf.opzioni;
        
        this.grf.opzioni.dataSource = new kendo.data.DataSource(this.dataSource);
        this.grigliaOpts = this.grf.opzioni;               
        //se cambia la selezione aggiorno il servizio
        this.ascoltaCambioSelezione();        
        //se cambiano i dati svuoto la selezione e aggiorno le action disponibili dalla toolbar
        this.ascoltaCambioDS();           
    }
    
    /**
     * @memberOf GridIdentity
     */  
    function dopoSalva(resp, self, vaiAGrid, modalita){
        this.statoapp.stato = null;
        if(vaiAGrid){
            self.mostraGriglia(); 
            self.ts.griglia.dataSource.read();            
        }        
        
    }
    
    /**
     * @memberOf GridIdentity
     */  
    function preSalva(){}
    
    
    /**
     * @memberOf GridIdentity
     */   
    function getAzioni(){
        var self = this;

        console.log("opzioni aggiuntive", self.opzioniAggiuntive)

        var azioni = {
            aggiungi: new self.af("fa fa-plus",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "crea", parametri: []}), 6, null, "GRIDIDENTITY.CREA", "alt+shift++"),
            duplica: new self.af("fa fa-copy", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "duplica", parametri: []}), 7, null, "GRIDIDENTITY.DUPLICA", "alt+shift+d"),            
            modifica: new self.af("fa fa-edit", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "modifica", parametri: []}), 7, null, "GRIDIDENTITY.MODIFICA", "alt+shift+e"),            
            elimina:  new self.af("fa fa-trash", self.esponi({metodo: "dimSelezioneInMode", parametri: ["lt", 1, "griglia"]}), self.esponi({metodo: "elimina", parametri: []}), 8, null, "GRIDIDENTITY.ELIMINA", "alt+shift+backspace"),
            salva: new self.af("fa fa-save", self.esponi({metodo: "disabilitaSalva", parametri: []}), self.esponi({metodo: "salva", parametri: []}), 9, null, "GRIDIDENTITY.SALVA", "alt+shift+s"),
            aggiorna: new self.af("fa fa-undo", self.esponi({metodo: "disabilitaSalva", parametri: []}), self.esponi({metodo: "aggiornaForm", parametri: []}), 10, null, "GRIDIDENTITY.ANNULLA", "alt+shift+z"),            
            griglia: new self.af("fa fa-th", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), self.esponi({metodo: "mostraGriglia", parametri: []}), 12, null, "GRIDIDENTITY.GRIGLIA", "alt+shift+g"),                    
            visualizza: new self.af("fa fa-eye", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "visualizza", parametri: []}), 13, null, "GRIDIDENTITY.VISUALIZZA", "alt+shift+v"),
        }


        var escludiAzioni = self.opzioniAggiuntive?.escludiAzioni ?? null;

        if(escludiAzioni !== null){
            azioni = Object.entries(azioni).filter(function(azione){
                var nomeazione = azione[0];
                return !escludiAzioni.includes(nomeazione);
            }).reduce(function(obj, azione){
                var nomeazione = azione[0];
                var fnazione = azione[1];
                obj[nomeazione] = fnazione;
                return obj;
            }, {})
        }

        var arrayAzioni = Object.values(azioni);

        return arrayAzioni;
    }
    
    /**
     * @memberOf GridIdentity
     */   
    function ascoltaCambioSelezione(){
        var self = this;
        self.grigliaOpts.change = function(e){
            var griglia = e.sender;    
            self.timeout(function(){
                var uidprecedenti = [];
                if(Array.isArray(self.ts.selezione) && self.ts.selezione.length){
                    uidprecedenti = self.ts.selezione.map(function(sel){return sel.uid;})
                }
                self.ts.selezione = [];
                var selEl = griglia.select();
                
                for(var x = 0; x < selEl.length; x++){
                    self.ts.selezione.push(griglia.dataItem(selEl[x]));
                }      
                
                if(selEl.length > 0){
                    griglia.table.focus();
                }                                
                
                if(
                    (self.ts.selezione.length === 1) && 
                    (uidprecedenti.indexOf(self.ts.selezione[0].uid) === -1)
                ) {
                    //console.log("SELEZIONE DI UN SINGOLO ELEMENTO NON CONTENUTO NELLA SELEZIONE PRECEDENTE", uidprecedenti, self.ts.selezione[0].uid)
                    self.aggiornaForm();
                } else {
                    //console.log("NON NECESSARIO AGGIORNARE IL FORM", uidprecedenti, self.ts.selezione[0].uid)
                }
                
                
            });
        }
    }
    
    /**
     * @memberOf GridIdentity
     */   
    function ascoltaCambioDS(){
        var self = this;
        var azioni = self.getAzioni(); 
        
        //se cambiano i dati svuoto la selezione e aggiorno le action disponibili dalla toolbar
        self.grigliaOpts.dataBound = function(e){
            //$timeout(function(){
                
            self.ts.griglia = e.sender;
            
            //self.ts.svuotaProviders();
            
            self.gni = new self.gn(self.ts.griglia, self.grigliaOpts);

            var providers = {                    
                gridNavigation: self.gni,
                dataSourceCrud: {exposed: azioni}
            };
                          
            //console.log("databound, aggiorno le azioni legate a griglia e datasource da ", self.ts.actionsProviders);
            self.ts.setProviders(providers);
            //console.log("databound, aggiorno le azioni legate a griglia e datasource a ", self.ts.actionsProviders);
                                    
            

            //self.controlloCambioStato(providers);

            self.ts.selezione = [];   
                        
            //new GridResizer("moduleFold/estensioni/Grid/GridResizer.js");
            
        }
    }
    
    /**
     * 
     * Controlla che l'aggiornamento del datasource aggiorni la toolbar opportuna.
     * Risolve il problema dell'aggiornamento del datasource asincrono, che non tiene conto
     * del possibile cambio di stato concorrente.
     * 
     * @memberOf GridIdentity
     * @param {Array<Array<ActionFactory>>} providers
     */
    function controlloCambioStato(providers){
        var self = this;
        if(self.$state.current.data.modulo === self.ts.modulo){
            self.ts.setProviders(providers, true);
        }
        else {
            self.ts.svuotaProviders();
        }
    }
    
    /**
     * @memberOf GridIdentity
     */   
    function disabilitaSalva(){
        var self = this;
        var esitoinvisualizza = typeof self.form !== 'undefined' && self.form.inVisualizza === true;
        return (esitoinvisualizza || self.mode !== "form");
    }
    
    /**
     * @memberOf GridIdentity
     */   
    function aggiornaForm(){
        var self = this;
        self.form = new this.identity();
        if(self.saveMode == 'modifica'){
            self.form.costruisciDaEsistente(self.ts.selezione[0]);
        }
        
        if(self.ts.selezione.length){
            self.saveMode = 'modifica';
            self.form.generaFormModifica();
        }
        else {
            self.saveMode = 'crea';
            self.form.generaFormInserimento();
        }
    }
    
    /**
     * Sarà la classe che specializza GridIdentity a ritornare le columns
     * 
     * @memberOf GridIdentity
     * @returns {Object}
     */
    function getGridCfg(){
        return {
            columns: [
//                {field: 'loginName', title: 'Username', width: '120'},                
//                {field: 'email', title: 'Email', width: '120'},
//                {field: 'lastName', title: 'Cognome', width: '120'},
//                {field: 'firstName', title: 'Nome', width: '120'},
//                {field: 'scadenza', title: 'Scadenza', width: '120'},
            ]
        };
    }
    
    /**
     * Sarà la classe che specializza GridIdentity a ritornare questo oggetto, soprattutto schema.model
     * to do dividere la cfg da schema.model perchè in genere è l'unica parte che cambia
     * 
     * @memberOf GridIdentity
     * @returns {Object}
     */
    function getDSCfg(){
        var self = this;
        return {
//            serverSorting: false,
//            serverPaging: false,
//            serverGrouping: false,
//            serverFiltering: false,
//            serverAggregates: false,
//            transport: {
//                read: {
//                    type: "POST",
//                    url: self.rs.baseUrl + "/cercatutti",
//                    dataType: 'json',
//                    cache: true,
//                    /*
//			data: function(){
//				//return dei dati da passare sempre
//			}
//                     */
//                },
//            },
//            schema: {
//                model: {
//                    id: 'id',
//                    fields: {
//                        loginName: {type:'string'},
//                        password: {type:'string'},
//                        email: {type:'string'},
//                        lastName: {type:'string'},
//                        firstName: {type:'string'},
//                        scadenza: {type:'date'}
//                    }
//                }
//            }
        };
    }    

    

    
    
    /**
     * @memberOf GridIdentity
     */
    function crea(){
        var self = this;
        self.inDuplica = false;
        self.form = new this.identity();        
        self.form.inVisualizza = false;
        self.form.generaFormInserimento();
        self.ts.griglia.clearSelection();
                
        self.mode = "form";
        self.saveMode = 'crea';
    }
    
    /**
     * @memberOf GridIdentity
     */
    function duplica(){        
        var self = this;
        self.inDuplica = true;
        self.form = new this.identity();
        self.form.generaFormInserimento();
        self.form.inVisualizza = false;

        self.mode = "form";
        self.saveMode = 'crea';
    }

    /**
     * @memberOf GridIdentity
     */
    function visualizza(){
        var self = this;
        self.inDuplica = false;
        self.form.inVisualizza = true;
        self.mode = "form";
    }

    
    /**
     * @memberOf GridIdentity
     */
    function modifica(){
        var self = this;
        self.inDuplica = false;
        self.form.inVisualizza = false;
        self.mode = "form";
        self.saveMode = 'modifica';
    }
    
    /**
     * Sarà la classe che specializza GridIdentity a definire questo metodo
     * todo parametrizzare traduzione e parametri di elimina
     * @memberOf GridIdentity
     */
    function elimina(){
        var self = this;
        var selezione = self.ts.selezione[0];
        self.tr("UTENTIMANAGER.CONFIRMELIMINA", {utente: selezione.loginName}).then(function(messaggio){
            if(confirm(messaggio)){
                self.timeout(function(){
                    console.log("procedo con la cancellazione di ", selezione);
                    //da testare
                    //self.rs.cancella({idutente: selezione.id})
                });
            }
        });
    }
    
    /**
     * @memberOf GridIdentity
     */
    function mostraGriglia(){
        var self = this;
        self.form.inVisualizza = false;
        self.mode = "griglia";
    }
    
    
    /**
     * @memberOf GridIdentity
     */
    function salva(){
        var self = this;
        //console.log("===========>salva, utilizzo quello in grididentity")
        console.log("incremento disabilitatutto", "inizio salva")
        self.ts.disabilitatutto++;
        self.formObj.$submitted = true;
        /**
         * @todo trovare il modo di passare quali campi ignorare. Es. dettaglio articolo aggiunge un form annidato che non andrebbe validato
         */
        if(!self.valida.validaForm(self.formObj)){
            console.log("decremento disabilitatutto", "salva errore validazione")
            self.ts.disabilitatutto--;
            self.em.showT("VALIDATORI.CORREGGI", "error");
        }
        else {       
            self.preSalva();
            self['salva'+self.saveMode]().then(function(resp){
                self.dopoSalva(resp, self, true, self.saveMode);
                console.log("decremento disabilitatutto", "fine salva in successo")
                self.ts.disabilitatutto--;
                //self.em.show("ok", 'success');
            }, function(resp){
                self.dopoSalva(resp, self, false, self.saveMode);
                console.log("decremento disabilitatutto", "fine salva in errore")
                self.ts.disabilitatutto--;
                self.em.errorCB(resp);
            });
        }
        
    }
    
    /**
     * @memberOf GridIdentity
     */    
    function salvacrea(){
        var self = this;
        return self.rs.crea(self.form.modello).then(function(resp){
            return resp;
        }, function(err){
            throw err;
        });
    }
    
    /**
     * @memberOf GridIdentity
     */    
    function salvamodifica(){
        var self = this;
        return self.rs.modifica(self.form.modello).then(function(resp){
            return resp;
        }, function(err){
            throw err;
        });
    }
    
    /**
     * @memberOf GridIdentity
     */
    function ricerca(){
        
    }
    

    
    return Costruttore;
    
}

module.exports = GridIdentity;