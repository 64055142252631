'use strict';

/**
 * Servizio che gestisce la correlazione tra tipo originario e tipi a disposizione per i form.
 * Permette di estrapolare le opzioni da una KendoGrid
 * 
 * @class
 */
function FieldService(){
    /**
     * tipi particolari
     * templateasis prende il template definito per la colonna e lo usa nel form, utilizzo in Ricambiweb.articoli
     */
    this.mappaTipi = {
        date: "bssdate",
        time: "bsstime",
        timestamp: "bssdatetime",
        string: "bssinput",
        text: "bsstextarea",
        //text: "bssjsoneditor",
        boolean: "bssboolcheckbox",
        enums: "bssselect",
        object: "bssjsoneditor",
        file: "bssfile",
        number: "bssnumeric",
        decimal: "bssnumeric",
        integer: "bssnumeric",
        coords: "bssnumeric",
        //bsszoom diventerà bssmultiselect non appena avremo sistemato la virtualizzazione (caricamento allo scroll)
        color: "bsscolorpicker",
        zoom: "bsszoom",
        bssmultiselect: "bssmultiselect",
        bsssingleselect: "bsssingleselect",
        html: "bsswysiwyg",
        bssplaintext: "bssplaintext",
        bssstatodistinta: "bssstatodistinta",
        bssvettautmezz: "bssvettautmezz",
        bsslocalizedstring: "bsslocalizedstring",
        bsslocalizedtext: "bsslocalizedstring",
        bsslocalizedhtml: "bsslocalizedstring",
        bssincassi: "bssincassi",
        bsslocalita: "bsslocalita",
        bssiconpicker: "bssiconpicker",
        bsslaum: "bsslaum",
        password: "bsspassword",
        bssstatiordine: "bssstatiordine",
        bssmappastatitracking: "bssmappastatitracking"
    };
}

FieldService.prototype.cfgGrid = cfgGrid;
FieldService.prototype.cfgCampoDaGrid = cfgCampoDaGrid;
FieldService.prototype.cfgHeader = cfgHeader;
FieldService.prototype.cfgCampoDaHeader = cfgCampoDaHeader;
FieldService.prototype.cfgCampoDa = cfgCampoDa;

FieldService.prototype.tipo = tipo;
//da un campo, in base alla modalità del form dice se il campo va nascosto o meno
FieldService.prototype.isCampoNascosto = isCampoNascosto;

/**
 * Definisce se il campo va nascosto o meno
 *
 * @memberOf FieldService
 * @param {FieldFactory} campo
 * @param {string} mode uno tra Inserisci, Modifica, Duplica, Dettaglio
 * @returns {Boolean}
 */
function isCampoNascosto(campo, mode){
    var colonna = campo.data.colonna;
//    if(colonna.hidden) return true;
//    if(typeof colonna.formhidden !== 'undefined' && colonna.formhidden[mode]) return true;                
//    return false;    
    return !colonna['visibleForm'+mode];
}

/**
 * Ottiene la configurazione della griglia KendoGrid
 * 
 * @memberOf FieldService
 * @param {KendoGrid} griglia
 * @returns {Object} con columns di KendoGrid e model del datasource della griglia
 */
function cfgGrid(griglia){
    return {
        //decommenta per riprendere la configurazione dalle columns
        //columns: griglia.columns, 
        columns: griglia.options.form,
        model: griglia.dataSource.options.schema.model.fields
    };
}

function cfgHeader(header){
    return {
        //decommenta per riprendere la configurazione dalle columns
        //columns: griglia.columns, 
        columns: header.form,
        model: header.formmodel.fields
    };
}


function cfgCampoDa(src, campo, srcCfg){
    var modello = srcCfg(src).model[campo];
    var colonna = _.findWhere(srcCfg(src).columns, {field: campo});
    //console.log("configurazione campo da griglia: ", colonna);
    var toReturn = null;
    
    if(colonna && modello){
        var tipo = this.tipo(modello, campo);
        var placeholder = (colonna.placeholder || colonna.title || colonna.field);
        
        var toReturn = {
            key: colonna.field,
            tipo: tipo,
            label: colonna.title || colonna.placeholder || placeholder,
            placeholder: placeholder,
            colonna: colonna,
            modello: modello,
        };        

        if(tipo === 'templateasis') {
            toReturn.template = colonna.template;
        }
            
    }
    
    return toReturn;
}

/**
 * Partendo dalla griglia genera tutte le opzioni del campo
 * 
 * @memberOf FieldService
 * @param {KendoGrid} griglia
 * @param {FieldFactory} campo
 * @returns {cfgCampoDaGrid.toReturn}
 */
function cfgCampoDaGrid(griglia, campo){
    return this.cfgCampoDa(griglia, campo, this.cfgGrid);
}

function cfgCampoDaHeader(header, campo){
    return this.cfgCampoDa(header, campo, this.cfgHeader);
}

/**
 * Partendo dal modello del campo e dal campo stesso ottiene il tipo di formly
 * 
 * @memberOf FieldService
 * @param {Object} modello
 * @param {FieldFactory} campo
 * @returns {String} tipo 
 */
function tipo(modello, campo){
    var tipo = modello.xtype || modello.type || "string";
    //console.log("debug tipo fieldservice", campo, modello);
    if(tipo === 'templateasis') return tipo;
    return this.mappaTipi[tipo];
}

module.exports = FieldService;