/* global opzioni */
'use strict';

/**
 * Factory identity modulemanager
 *
 * @class
 * @param {type} ModulemanagerRS
 * @param {type} FieldFactory
 * @param {type} $q
 */
function ModulemanagerIdentity(ModulemanagerRS, FieldFactory, $q){

    /**
     * @memberOf ModulemanagerIdentity
     * @constructor
     */
    function Costruttore(){


        this.modello = {};

        this.ff = FieldFactory;
        this.rs = ModulemanagerRS;

    }

    Costruttore.prototype.costruisciDaEsistente = costruisciDaEsistente;
    Costruttore.prototype.getCampoMenuModulo = getCampoMenuModulo;
    Costruttore.prototype.generaFormInserimento = generaFormInserimento;
    Costruttore.prototype.generaFormModifica = generaFormModifica;

    /**
     *
     * @memberOf ModulemanagerIdentity
     * @returns {formly-field}
     */
    function costruisciDaEsistente(oggetto){
        this.modello = angular.extend(this.modello, oggetto);
    }



    /**
     *
     * @memberOf ModulemanagerIdentity
     * @returns {formly-field}
     */
    function getCampoMenuModulo(){
        return new this.ff(
            {
                key: 'loginName',
                tipo: 'bssinput',
                label: 'Username',
                colonna: {},
                modello: {validations: {required: true}}
            },
            false,
            {className: 'col-xs-12'}
        ).opzioni;
    }

    /**
     *
     * @memberOf UtentiIdentity
     * @returns {Array(formly-field)}
     */
    function generaFormInserimento(){
        this.campi = [
        ];
    }

    /**
     *
     * @memberOf ModulemanagerIdentity
     * @returns {Array(formly-field)}
     */
    function generaFormModifica(){
        this.campi = [
        ];
    }

    return Costruttore;
}

module.exports = ModulemanagerIdentity;
