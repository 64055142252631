'use strict';

/**
 * @module bss/statoapp
 * @description Modulo che mantiene lo stato dell'applicazione
 */
var bssstatoapp = angular.module("bss.statoapp", [])
    .service("StatoApp", ["$rootScope", "$q", "$timeout", require("./StatoApp")])
;

module.exports = bssstatoapp;