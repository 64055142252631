'use strict';

function GridResizer(){
    var gridEl = $(".k-grid");
    if(gridEl.size()){
        //console.log("finestra ridimensionata, ridimensiono anche la grid");
        //gridEl.data("kendoGrid").resize();
        var grid = $(".k-grid");

        //Other variables
        var minimumAcceptableGridHeight = 250; //This is roughly 5 rows
        var otherElementsHeight = 0;

        //Get Window Height
        var windowHeight = $(window).innerHeight();



        //Get the Grid Element and Areas Inside It
        var contentArea = grid.find(".k-grid-content");  //This is the content Where Grid is located
        var otherGridElements = grid.children().not(".k-grid-content"); //This is anything ather than the Grid iteslf like header, commands, etc
        //console.debug(otherGridElements);

        //Calcualte all Grid elements height
        otherGridElements.each(function () {
            otherElementsHeight += $(this).outerHeight(true);
        });

        //Get other elements same level as Grid
        var parentDiv = grid.parent("div");

        var hasMainContent = parentDiv.length;
        if (hasMainContent) {
            var otherSiblingElements = $("body").children()
                    .not(".k-grid")
                    .not("script");

            //Calculate all Sibling element height
            otherSiblingElements.each(function () {
                //otherElementsHeight += $(this).outerHeight(true);
            });
        }

        //Padding you want to apply below your page
        var bottomPadding = 20;

        //console.log("altezza totale: " + windowHeight);
        //console.log("altro: " + (grid.offset().top+otherElementsHeight+bottomPadding));

        //Check if Calculated height is below threshold
        var calculatedHeight = windowHeight - grid.offset().top - otherElementsHeight - bottomPadding;
        var finalHeight = calculatedHeight < minimumAcceptableGridHeight ? minimumAcceptableGridHeight : calculatedHeight;

        //Apply the height for the content area
        contentArea.height(finalHeight);
    }
}

module.exports = GridResizer;