const moment = require("moment");

var keycloak = null;


window['saveAs'] = require("moduleFold/fileutilities/scripts/FileSaver.js").saveAs;
window['bsslodash'] = require("lodash");
/**
 * permette di accedere dinamicamente a property nested (path) dell'oggetto (obj) ritorna un valore di default altrimenti
 * (obj, path, def) => obj[path] || null
 * 
 * Ovvia al problema delle property dinamiche annidate (con punti all'interno) e 
 * si occupa di controllare che tutte le property siano non nulle e definite
 * 
 * test
 * 
 * console.log(window['bssRecursiveGetter']({a: {b: {c: 5}}}, "a.b.c", null)); => 5
 * console.log(window['bssRecursiveGetter']({a: {b: 3}}, "a.b.c", null)); => null
 *
 * 
 * ES6
 * 
 * (obj, path, def) => path.split(".").reduce((tmp, seg) => (tmp !== undefined && tmp !== null && tmp[seg] !== undefined && tmp[seg] !== null) ? tmp[seg] : def, obj);
 */
window['bssRecursiveGetter'] = function(obj, path, def){
  return path.split(".").reduce(function(tmp, seg){
    if(tmp !== undefined && tmp !== null && tmp[seg] !== undefined && tmp[seg] !== null){
      return tmp[seg];
    } 
    return def
  }, obj);
};

/**
 * @usage window['sortBySetlist'](
 *  [{key: 'bar', val: 'bar'}, {key: 'baz', val: 'baz'}, {key: 'foo', val: 'foo'}, {key: 'faz', val: 'faz'}], 
 *  ['foo', 'bar', 'faz', 'baz'],
 *  'key'
 * ) => [{key: 'foo', val: 'foo'}, {key: 'bar', val: 'bar'}, {key: 'faz', val: 'faz'}, {key: 'baz', val: 'baz'}]
 * 
 * @param {*} list array di oggetti con property keyField
 * @param {*} setlist array che indica l'ordine in cui dovranno presentarsi i keyField
 * @param {*} keyField default value: 'key'
 */
window['sortBySetlist'] = function(list, setlist, keyField){
  
  //keyField di default viene valorizzato a 'key'
  if(keyField === undefined){
    keyField = 'key';
  }
  
  /**
   * la setlist deve essere nel formato {chiave: ordine} nell'esempio in usage
   * ['foo', 'bar', 'faz', 'baz'] => {foo: 0, bar: 1, faz: 2, baz: 3}
   */
  setlist = setlist.reduce(function(obj, el, index){
    obj[el] = index;
    return obj;
  }, {})
  
  return list.sort(function(a,b){return setlist[a[keyField]] - setlist[b[keyField]]})
}


/**
 * converte arraybuffer in stringa
 * @param {*} buf 
 */
window["ab2str"] = function(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

/**
 * 
 * @param {*} successConditionCB funzione che ritorna true se la condizione di waitUntil è stata raggiunta
 * @param {*} maxAttempts  numero massimo di tentativi prima di uscire default 10
 * @param {*} delay millisecondi che intercorrono tra ogni esecuzione del controllo default 200ms
 */
window["waitUntil"] = function(successConditionCB, maxAttempts, delay){
  if(delay === null || delay === undefined){delay = 200}
  if(maxAttempts === null || maxAttempts === undefined){maxAttempts = 100}

  var attempt = 0;
  return new Promise(function(resolve, reject){
    var ci = setInterval(function(){
      attempt++;
      if(attempt>10){console.log("tentativo " + attempt + " / " + maxAttempts + " prossimo controllo tra " + delay + "ms")}
      if(attempt >= maxAttempts){
        clearInterval(ci)
        reject("troppi tentativi")
      } else {
        if(successConditionCB()){
          clearInterval(ci)
          resolve("ok")
        }
      }
      
    }, delay);
  })
}


"use strict";

/**
 * @module bss/extincluder
 * @description Modulo che include nel bundle tutte le librerie esterne minified e concatenate
 * @example
 * require("moduleFold/extincluder/scripts/index.js");
 */
require("moduleFold/bloccaschermo/styles/bloccaschermo.scss");
//require("extlib/font-awesome/css/font-awesome.min.css");//31
require("extlib/font-awesome5/css/all.min.css");
require("extlib/font-awesome-animation/dist/font-awesome-animation.min.css");
require("extlib/bootstrap/dist/css/bootstrap.min.css");//119 !
require("extlib/kendo/styles/kendo.common.min.css");//245 !!
require("extlib/kendo/styles/kendo.silver.min.css");//71
require("extlib/kendo/styles/kendo.dataviz.silver.min.css");//4.2
require("extlib/kendo/styles/kendo.mobile.material.min.css");//150 !
require("extlib/json-formatter/dist/json-formatter.min.css");//3.3
require("extlib/ui-select/dist/select.min.css");//4.2
require("extlib/angular-hotkeys/build/hotkeys.min.css");//1.4  
require("extlib/angular-ui-notification/dist/angular-ui-notification.min.css"); //1.2
require("extlib/jsoneditor/dist/jsoneditor.min.css");//11
require("extlib/trix/dist/trix.css");//11
require("extlib/angular-block-ui/dist/angular-block-ui.min.css");//1.2
require("extlib/adminlte/dist/css/AdminLTE.min.css");//89
require("extlib/adminlte/dist/css/skins/_all-skins.min.css");//40
require("extlib/angular-timeline/dist/angular-timeline.css");//4
require("extlib/angular-timeline/dist/angular-timeline-animations.css");//3.6
require("extlib/angular-timeline/dist/angular-timeline-bootstrap.css");//0.4
require("extlib/ui-iconpicker/dist/styles/ui-iconpicker.min.css");//9.5
require("extlib/angular-wizard/dist/angular-wizard.min.css");
require("extlib/angular-google-places-autocomplete/dist/autocomplete.min.css");
require("extlib/ng-tags-input/ng-tags-input.min.css");
require("extlib/ng-tags-input/ng-tags-input.bootstrap.min.css");
require("extlib/angular-ui-clock/dist/angular-clock.css");
//require("extlib/angular-filemanager-master/dist/angular-filemanager.min.css");
require("extlib/fileformaticons/font/flaticon.css");
require("extlib/pivotui/pivot.min.css");//40


require("../styles/style.scss");
require("../styles/anima.scss");

require("extlib/jszip/dist/jszip.min.js");//79
require("extlib/jquery/dist/jquery.js");//84
require("moduleFold/bloccaschermo/scripts/backspace.js");
require("extlib/ace-builds/src-min-noconflict/ace.js");//347 !!!
require("extlib/ace-builds/src-min-noconflict/mode-javascript.js");//18
require("extlib/ace-builds/src-min-noconflict/theme-chrome.js");//2.7
require("extlib/clipboard/dist/clipboard.min.js");//11
require("extlib/pivotui/jquery-ui.min.js");//40
require("extlib/pivotui/pivot.min.js");//40
require("extlib/pivotui/pivot.it.min.js");//2


// require("extlib/angular178/angular");
// require("extlib/angular178/angular-cookies");
// require("extlib/angular178/angular-resource");
// require("extlib/angular178/angular-sanitize");
// require("extlib/angular178/angular-messages");
// require("extlib/angular178/i18n/angular-locale_it-it");
// require("extlib/angular-translate2181/angular-translate.js");//22
// require("extlib/angular-translate2181/angular-translate-storage-cookie.js");//22
// require("extlib/angular-translate2181/angular-translate-storage-local.js");//22

require("extlib/angular/angular.js");//157 !
require("extlib/angular-cookies/angular-cookies.min.js");//1.5
require("extlib/angular-resource/angular-resource.min.js");//4
require("extlib/angular-sanitize/angular-sanitize.min.js");//5.9
require("extlib/angular-messages/angular-messages.min.js");//2.7
require("extlib/angular/angular-locale_it-it.js");//2.7
require("extlib/angular-translate/angular-translate.min.js");//22
require("extlib/angular-translate-storage-cookie/angular-translate-storage-cookie.min.js");//0.6
require("extlib/angular-translate-storage-local/angular-translate-storage-local.min.js");//0.9
require("extlib/angular-translate-loader-url/angular-translate-loader-url.min.js");//0.7
require("extlib/angular-translate-loader-static-files/angular-translate-loader-static-files.min.js");//1.3
require("extlib/angular-translate-loader-partial/angular-translate-loader-partial.min.js");//4


require("extlib/angular-timeline/dist/angular-timeline.js");//3.6
require("extlib/ngclipboard/dist/ngclipboard.min.js");//0.6
require("extlib/angular-ui-ace/ui-ace.min.js");//3.3
require("extlib/adminlte/dist/js/app.min.js");//9.6

require("extlib/bootstrap/dist/js/bootstrap.min.js");//37
require("extlib/jqueryfix/asyncfalse.js");




require("extlib/angular-ui-bootstrap/ui-bootstrap.min.js");//86
require("extlib/angular-ui-bootstrap/ui-bootstrap-tpls.min.js");//108 !
require("extlib/api-check/dist/api-check.min.js");//16
require("extlib/angular-formly/dist/formly.min.js");//40
require("extlib/angular-formly-templates-bootstrap/dist/angular-formly-templates-bootstrap.min.js");//9.6
require("extlib/kendo/js/kendo.all.min.js");//2.8m !!!!!!!!!!!!!!!!!!!!!!

//per knob non utilizzati
//require("extlib/d3/d3.min.js");
//require("extlib/ng-knob/dist/ng-knob.min.js");
require("extlib/kendo/js/cultures/kendo.culture.it-IT.min.js");//5.4
require("extlib/kendo/js/pako_deflate.min.js");
require("extlib/angular-hotkeys/build/hotkeys.min.js");//10
require("extlib/underscore/underscore-min.js");//17
require("extlib/angular-underscore-module/angular-underscore-module.js");//0.39
require("extlib/json-formatter/dist/json-formatter.min.js");//4.1
require("extlib/ui-select/dist/select.min.js");//37
require("extlib/ngMask/dist/ngMask.min.js");//6.7
require("extlib/moment/min/moment-with-locales.js");//362 !!
require("extlib/angular-moment/angular-moment.min.js");//5.1
require("extlib/angular-middleware/dist/angular-middleware.min.js");//2.4
require("extlib/angular-ui-router/release/angular-ui-router.min.js");//32
require("extlib/angular-ui-notification/dist/angular-ui-notification.min.js");//4
require("extlib/checklist-model/checklist-model.js");//4.8
require("extlib/angular-datetime/dist/datetime.js");//31
require("extlib/angular-dynamic-number/release/dynamic-number.js");//31
require("extlib/humanize-duration/humanize-duration.js");
//require("extlib/angular-timer/dist/angular-timer.min.js");
require("extlib/jsoneditor/dist/jsoneditor.min.js");//465
require("extlib/ng-jsoneditor/ng-jsoneditor.min.js");//2.2
require("extlib/trix/dist/trix.js");//187 !
require("extlib/angular-trix/dist/angular-trix.min.js");//1.2

//translate

require("extlib/angular-block-ui/dist/angular-block-ui.min.js");//6.9
require("extlib/cryptofoo/cryptofoo.min.js");//11
require("extlib/angular-file-upload/dist/angular-file-upload.min.js");//21
require("extlib/ui-iconpicker/dist/scripts/ui-iconpicker.min.js");//11
require("extlib/angular-wizard/dist/angular-wizard.min.js");
require("extlib/angular-google-places-autocomplete/dist/autocomplete.min.js");
require("extlib/ngGeolocation/ngGeolocation.min.js");
require("extlib/ng-tags-input/ng-tags-input.min.js");
require("extlib/angular-ui-clock/dist/angular-clock.min.js");
require("extlib/ng-file-upload/ng-file-upload.min.js");
require("extlib/angular-filemanager-master/dist/angular-filemanager.min.js");
require("extlib/angular-websocket/dist/angular-websocket.min.js");
require("extlib/ngmap/build/scripts/ng-map.min.js");
require("extlib/angularjs-gauge/src/angularjs-gauge.js");
//require("extlib/angular-timer/dist/angular-timer.min.js");
require("extlib/ngFitText/dist/ng-FitText.min.js");
require("extlib/angular-slugify/angular-slugify.js");
require("extlib/ngfu/ngfu.min.js");
require("extlib/angular-recorder/angular-audio-recorder.min.js");


require("moduleFold/estensioni/jquery/utilities.js");
require("moduleFold/estensioni/String/utilities.js");


kendo.culture("it-IT");
moment.locale("it");

var myApiCheck = require('api-check')({
  disabled: true // <-- defaults to false, set this to true in production
});

window.resizeGrid = function(){
  setTimeout(function(){
    var g = $(".k-grid:visible").not(".k-window .k-grid").not(".k-grid[data-ignore-resize='true']");
    if(g.size() === 1){
      g = $(".k-grid").data("kendoGrid");
      var ytable = g.table[0].getBoundingClientRect().top;

      var havailable = window.innerHeight;
      var newheight = Math.max(350, havailable - ytable);
      console.log("resize della griglia, altezza disponibile", g, havailable, "altezza di partenza", ytable);
      g.setOptions({height: newheight})
      

    }
  }, 200)
  
};

$(window).on("resize", function(){
  window.resizeGrid();
})


