'use strict';

/**
 * Registrazione delle route per il modulo bss/menu
 * 
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 * @returns {undefined}
 */
function Routes($stateProvider, $urlRouterProvider, $locationProvider){
    $stateProvider
        .state('menueditor', {
            url: "/menu-editor",
            views: {
                "maincontent.custommodule" : {
                    template: require("../templates/maintpl.html"),
                    controller: ["MenuIdentity", "$timeout", "ErrorManager", "ToolbarSrv", "SideMenuSrv", require("../MenuManagerCtrl")],
                    controllerAs: 'menu'
                },
            },
            middleware: ['loggato'],
            data: {
                modulo: 'bss.menu'
            }
        })
    ;
}

module.exports = Routes;


