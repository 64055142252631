'use strict';


/**
 * Controller relativo al template della route /wizardlocalita
 *
 * @param {type} ToolbarSrv
 * @param {type} ActionFactory
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $translate
 * @param {type} ErrorManager
 */
function WizardlocalitaFunzioniAggiuntive(ToolbarSrv, ActionFactory, $http, $q, $timeout, $translate, $state, ErrorManager){
    var self = this;
    self.ts = ToolbarSrv;
    self.af = ActionFactory;
    self.$http = $http;
    self.$q = $q;
    self.$timeout = $timeout;
    self.$translate = $translate;
    self.$state = $state;
    self.em = ErrorManager;
    var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
    var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
    angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype);
}

WizardlocalitaFunzioniAggiuntive.prototype.aggiornaProvider = aggiornaProvider;
WizardlocalitaFunzioniAggiuntive.prototype.azione = azione;

/**
 * @memberof WizardlocalitaFunzioniAggiuntive
 *
 * Registra azioni nella toolbar
 *
 */
function aggiornaProvider(){
    var self = this;
    /* es. di azione, in questo caso occorre definire il metodo mostraTimeLine
    var azione = new self.af("fa fa-history",  self.esponi({metodo: "dimSelezioneConfronta", parametri: ["ne", 1]}), self.esponi({metodo: "azione", parametri: []}), 999, null, "WIZARDLOCALITA.SHOWHISTORY");

    self.ts.setProviders({wizardlocalitaprovider: {exposed: [
                azione
            ]}});
    */
}

/**
 * @memberof WizardlocalitaFunzioniAggiuntive
 *
 * azione di esempio
 *
 */
function azione(){
  //var istanzaSel = this.ts.griglia.dataItem(this.ts.griglia.select());
  //var url = this.$state.href('statoAzione', {/*parametri*/});
  //window.open(url, '_blank');
}

module.exports = WizardlocalitaFunzioniAggiuntive;
