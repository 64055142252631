'use strict';

require("moduleFold/toolbar/scripts/index.js");
require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/sidebarmenu/scripts/index.js");
require("moduleFold/modulemanager/scripts/index.js");

/**
 * @module bss/menu
 * @description Modulo menu
 * 
 */
var bssmenu = angular.module("bss.menu", ["bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar", "bss.sidebarmenu", "bss.modulemanager"])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/Routes")])
    .service("MenuManagerRS", ['$http', "ErrorManager", "ModulemanagerRS", require("./MenuManagerRS")])
    .service("MenuAccessManagerRS", ['$http', "ErrorManager", require("./MenuAccessManagerRS")])
    .factory("MenuIdentity", ['MenuManagerRS', 'ModulemanagerRS', "MenuAccessManagerRS", "FieldFactory", "$q", "$timeout", require("./MenuIdentity")])
;

module.exports = bssmenu;