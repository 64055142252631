'use strict';

require("../styles/menuprovider.scss");
require("moduleFold/router/scripts/index.js");
require("moduleFold/menu/scripts/index.js");

var bsssidebarmenu = angular.module("bss.sidebarmenu", ["bss.router", "bss.menu"])
  //dovrebbe essere inutilizzato, ma commentando la righe partono richieste ajax a raffica (capire perchè)
  .service("SideMenuSrvOld", ["$rootScope", "$q", "$timeout", "$filter", "$httpParamSerializerJQLike", "$http", "$state", "MenuManagerRS", require("./SideMenuSrv")])
  //IMPORTANTE QUESTO È IL SERVIZIO EFFETTIVAMENTE UTILIZZATO AL POSTO DI QUELLO SOPRA
  .service("SideMenuSrv", ["$http", "$timeout", "$interval", "$state", "$templateCache", "MenuManagerRS", "FieldFactory", "Valida", "ErrorManager", require("./AdminLTE2SideMenu")])
  .directive("navigali", ["$timeout", require("./NavigaLiDir")])
;

module.exports = bsssidebarmenu;