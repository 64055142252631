"use strict";

require("moduleFold/configuratore/scripts/index.js");

/**
 * @module bss/datasource
 * @description Modulo per al creazione di DataSource Kendo
 */
var bssdatasource = angular.module("bss.datasource", ["bss.configuratore"])
    .factory("DSCfgFactory", ["CfgFactory", "ToolbarSrv", "$http", "ErrorManager", require("./DSCfgFactory")])
    .factory("DSFactory", ["DSCfgFactory", "ErrorManager", require("./DSFactory")])
;

module.exports = bssdatasource;