'use strict';

function RowDblClick() {
    return {
        link: function (scope, element, attrs) {
            scope.$on("kendoWidgetCreated", function (event, widget) {                                
                
                var griglia = element.data("kendoGrid");
                
                if(griglia !== undefined){
                    console.log("RowDblClick widget: ", widget, " elemento: ", griglia);
                }
                                                                                               
                //if (widget != element.getKendoGrid()) {
                if(widget !== griglia){
                    return;
                }                                

                attachDblClickToRows(scope, element, attrs);
                                
                console.log("RowDblClick griglia: ", griglia);

                griglia.bind('dataBound', function () {
                    console.log("RowDblClick: databound faccio l'attach");
                    attachDblClickToRows(scope, element, attrs);
                });
            });
        }
    };

    function attachDblClickToRows(scope, element, attrs) {
        console.log("RowDblClick: faccio l'attach della logica al doppio click, attributi disponibili: ", attrs);
        element.find('tbody tr').on('dblclick', function (event) {      
            console.log("RowDblClick: doppio click su riga della grid");
            var rowScope = angular.element(event.currentTarget).scope();
            scope.$eval(attrs.bssRowDblClick, { rowData: rowScope.dataItem });
        });
    }
}

module.exports = RowDblClick;