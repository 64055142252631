'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
var MastroDettaglioMixin = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixin");
var MastroDettaglioMixinV2 = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixinV2");



/**
 * Controller relativo al template della route /listinistd
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} ListinistdFunzioniAggiuntive
 */                                    
function ListinistdCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, GCF, ToolbarS, idstato, $scope, $compile, ActionFactory, ErrorManager, FormDaGridFactory, Valida, ListinistdFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q
    self.fa = ListinistdFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$scope = $scope;
    self.$compile = $compile;
    self.$sp = $stateParams;
    self.fgf = FormDaGridFactory;
    self.valida = Valida;
    self.em = ErrorManager;

    self.righe = [];
    self.righeeliminate = [];
    self.righedaeliminare = [];

    self.parametri = {
        strentity: "tlistinistd",
        codmodulo: "BSGTListin",
        //wssalvamd: "/dbplc/creamasdet",
        //campi request
        //campotestata: "testata", //campo che conterrà il model della testata
        //campoim: "imrighe", //campo che conterrà i model delle righe inserite/modificate
        //campoe: "erighe" //campo che conterrà i model delle righe eliminate (con id)
    };

    self.parametriDettaglio = {
        // prezzistd: {
            //idtestata: "idtestata",
            strentity: "prezzistd",
            wsread: "/prezzistd/listini/righetestata",
            //wsread: "/prezzistd/grid",
            fnAdditionalParameters: function(){
                return {
                    articolo: JSON.stringify({id: typeof self.articololis !== 'undefined' ? self.articololis.id : null}),
                    page: 1,
                    pageSize: 9999,
                    take: 9999,
                    skip: 0                   
                }
            }
            //nascondicomandi: {crea: true, elimina: true}
        // }
    }


    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype, MastroDettaglioMixin.prototype, MastroDettaglioMixinV2.prototype);



    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza().then(function(){
        self.gfi.eliminabck = self.elimina;
        /**
         * La cancellazione di un listino richiede una verifica.
         * La verifica ritorna un array di BssError. 
         * Se l'array è vuoto posso procedere con elimina standard (self.gfi.eliminabck)
         * Se l'array non è vuoto devo mostrare un popoup.
         * 
         * Il popup dovrà mostrare 2 elenchi puntati. Uno per gli id che hanno un BssError con type = 'error' e uno per gli id con BssError in warning.
         * Gli id in errore vanno deselezionati.
         * Per quelli con type = 'warning' sarà possibile, a discrezione dell'utente lanciare elimina standard (self.gfi.eliminabck)
         * Il campo errorClass conterrà l'id del listino in error/warning
         */
        self.gfi.elimina = function(){
            var righesel = self.ts.griglia.select();

            /**
             * @typedef {{codice: string, riga: HTMLElement, message?: string}} RigaDaDel             
             * 
             * 
             * @type {Object.<number, RigaDaDel>}
             */
            var codicielimina = {};
            var selezione = $.makeArray(righesel).map(function(rigael){
                var riga = self.ts.griglia.dataItem(rigael);
                var id = riga.id;
                var codice = riga.codice
                codicielimina[id] = {codice: codice, riga: rigael};
                return id;
            });
    
    
            //controllo che la selezione sia eliminabile
            self.$http({
                url: utils.urls.wsbase + "/tlistinistd/checkdel", //ritorna un array di bsserror, in warning posso 
                method: "POST",
                data: $.param({id: JSON.stringify(selezione)})
            }).then(function(resp){
                // console.log("======>risultato checkdel", resp.data)

                var idsresp = resp.data.filter(function(res){
                    return res.errorClass !== null;
                }).map(function(res){
                    return Number(res.errorClass);
                })

                var idswarning = resp.data.filter(function(res){
                    return res.type === 'warning' && res.errorClass !== null;
                }).map(function(res){
                    codicielimina[res.errorClass].message = res.message;
                    return Number(res.errorClass);
                });

                var idserror = resp.data.filter(function(res){
                    return res.type === 'error' && res.errorClass !== null;
                }).map(function(res){
                    codicielimina[res.errorClass].message = res.message;
                    return Number(res.errorClass);
                });

                var idssuccess = selezione.filter(function(sel){
                    return idsresp.indexOf(Number(sel)) === -1
                });

                self.codicielimina = codicielimina;
                self.idserror = idserror;
                self.idswarning = idswarning;

                //console.log("richiesta iniziale", self.codicielimina, "solo id", selezione)
                //console.log("id che si possono cancellare", idssuccess);
                self.eliminaPerId(idssuccess).then(function(){
                    if(self.idswarning.length || self.idserror.length){
                        self.resocontoDelWin.open().center();
                        //console.log("id per cui posso forzare la cancellazione", idswarning)
                        //console.log("id che non si possono cancellare", idserror);
                    }
                })                                    
                
                
            })
    
            //se è eliminabile procedo con elimina standard
    
            //se è eliminabile solo forzando mostro popup e poi richiamo... non lo so
    
            //se non è eliminabile mostro il messaggio di errore
        }
    });
    
    //osserva la selezione testata e aggiorna le righe
    self.osservaSelezione();
}

ListinistdCtrl.prototype.eliminaPerId = eliminaPerId;
ListinistdCtrl.prototype.filtrarigheperarticolo = filtrarigheperarticolo;
ListinistdCtrl.prototype.blurarticololis = blurarticololis;
ListinistdCtrl.prototype.resetarticololis = resetarticololis;
ListinistdCtrl.prototype.setarticololis = setarticololis;
ListinistdCtrl.prototype.getarticolilis = getarticolilis;
ListinistdCtrl.prototype.apripopuparticolilis = apripopuparticolilis;
ListinistdCtrl.prototype.initgridarticolilis = initgridarticolilis;
ListinistdCtrl.prototype.svuotaarticololis = svuotaarticololis;

/**
 * 
 * @param {Array[number]} ids 
 */
function eliminaPerId(ids, chiudiriepilogo){
    var self = this;
    ids.forEach(function(id){
        self.ts.griglia.removeRow(self.codicielimina[id].riga)
    });
       
    return self.gfi.sincronizzaDS(self.ts.griglia.dataSource, "elimina").then(function(){
        if(chiudiriepilogo){
            self.resocontoDelWin.close();
        }
    });
}

function filtrarigheperarticolo(){
    var self = this;
    self.righeGrid.dataSource.read()
    
}

function blurarticololis(){
    var self = this;

    self.getarticolilis().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setarticololis(opzioni[0])
        } else {
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOARTICOLOFOUND", "warning");
                self.resetarticololis()
            }
            self.apripopuparticolilis()
        }
    })
}

function resetarticololis(){
    var self = this;
    self.articololis = null;
    self._articololis = null;
    self.disabilitaarticololis = false;
}


function setarticololis(art){
    var self = this;
    self.articololis = art;
    self._articololis = art.codarticolo + " " + art.descrizione;
    self.disabilitaarticololis = true;
    self.articololiswin.close();
}


function getarticolilis(){
    var self = this;
    var term = self.filtrarighe.articololis.$viewValue;
    var parametri = { page: 1, pageSize: 2, take: 2, skip: 0, search: term};
    var strentity = "articolirdc";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param(parametri)
    }).then(function (resp) {
        self.possibiliarticolilis = resp.data.results;
        return self.possibiliarticolilis;
    })
}

function apripopuparticolilis(){
    var self = this;
    self.articololiswin.open().center();
    self.initgridarticolilis()
}

function initgridarticolilis(){
    var self = this;
    var strentity = "articolirdc";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setarticololis(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.filtrarighe.articololis ? self.filtrarighe.articololis.$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridarticolilis !== 'undefined') {
                    self.gridarticolilis.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliarticolilis = self.gridarticolilis.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridarticolilisOpts = angular.extend({}, gridcfg);
            self.gridarticolilisRebind = moment().format();
            //return ds.read();
        })
    });
}

function svuotaarticololis(){
    var self = this;
    self.resetarticololis();
}


module.exports = ListinistdCtrl;
