_.mixin({
    /**
    * Scorre ricorsivamente un albero, visitando i figli in base al parametro chieldField. 
    * Per ogni nodo visitato esegue la funzione passata come parametro op.
    * 
    * @namespace estensioni  
    * @param {type} tree
    * @param {string} childField nome del campo che contiene i figli
    * @param {callback} op callback da richiamare per ogni nodo
    * 
    * @example
    * vedere _.tree2array
    */
    recursive: function(tree, childField, op) {
        function recurse(item) { op(item); _.each(item[childField], recurse); }
        _.each(tree, recurse);
    },
    /**
    * Trasforma un albero in array richiede _.recursive
    * 
    * @namespace estensioni
    * @param {type} tree
    * @param {string} chieldField
    * @param {bool} includeChield determina se per il campo vanno preservati i figli o meno (evitare)
    * @returns {Array}
    * 
    * @example
    * var albero = [{id:"1",items:[{id:"1.1",items:[]},{id:"1.2",items:[{id:"1.2.1",items:[{id:"1.2.1.1",items:[]},]},]},]},{id:"2",items:[{id:"2.1",items:[{id:"2.1.1",items:[{id:"2.1.1.1",items:[]},]},]},{id:"2.2",items:[]},]}];
    * var arr = _.tree2array(albero, 'items'); // senza elementi figli => [{"id":"1"},{"id":"1.1"},{"id":"1.2"},{"id":"1.2.1"},{"id":"1.2.1.1"},{"id":"2"},{"id":"2.1"},{"id":"2.1.1"},{"id":"2.1.1.1"},{"id":"2.2"}]
    * var arr2 = _.tree2array(albero, 'items', true); // con elementi figli
    * 
    */
    tree2array: function(tree, chieldField, includeChield){
        var arr = [];
        _.recursive(tree, chieldField, function(item){      
            arr.push( includeChield ? item : _.omit(item, chieldField) );
        });
        return arr;
    }
});


