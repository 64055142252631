'use strict';



/**
 * Factory che crea una action, ovvero un oggetto che condivide le proprietà con quelle dello scope di ActionCmp.
 * @class
 */
function ToogleActionFactory(ActFct){

    /**
     * 
     * @memberOf ToogleActionFactory
     * @constructor
     * @param {callback} disabilita disabilita
     * @param {Number} ordine posizione tasto
     * @param {ActionFactory} act1 prima opzione del toggle
     * @param {ActionFactory} act2 seconda opzione del toggle
     * 
     */
    function Costruttore(disabilita, ordine, act1, act2){
        var self = this;
        this.ActFct = ActFct;
        this.disabilita = disabilita;
        this.ordine = ordine;
        this.act1 = act1;
        this.act2 = act2;
                
    }
    
    return Costruttore;
}




module.exports = ToogleActionFactory;