'use strict';

/**
 * Rest client per l'endpoin menumanager
 * 
 * @class
 * @param {type} $http
 */
function MenuManagerRS($http, ErrorManager, ModulemanagerRS){
    this.baseurl = utils.urls.wsbase + "/menumanager";
    this.$http = $http;
    this.form = {menu: null};
    this.em = ErrorManager;
    this.mmrs = ModulemanagerRS;
    this.nomeEntity = 'menu';
}

var IdentityRS = require("moduleFold/genericidentity/scripts/IdentityRS");
angular.extend(MenuManagerRS.prototype, IdentityRS.prototype);

MenuManagerRS.prototype.utente = utente;
MenuManagerRS.prototype.tuttearee = tuttearee;
MenuManagerRS.prototype.crea = crea;
MenuManagerRS.prototype.modifica = modifica;
MenuManagerRS.prototype.accessoutente = accessoutente;
MenuManagerRS.prototype.menuarea = menuarea;
MenuManagerRS.prototype.menu = menu;
MenuManagerRS.prototype.tipimenu = tipimenu;
MenuManagerRS.prototype.parent = parent;
MenuManagerRS.prototype.cerca = cerca;
MenuManagerRS.prototype.elimina = elimina;
MenuManagerRS.prototype.listaaccessi = listaaccessi;
MenuManagerRS.prototype.accessoutentegruppo = accessoutentegruppo;

/**
 * Associa utente o gruppo a menu
 * 
 * @memberOf MenuManagerRS
 * @params {object} parametri {idmenu: , idgruppo: , idutente:, visibile} //idutente e idgruppo si escludono a vicenda, visibile è boolean
 * @returns {promise}
 */
function accessoutentegruppo(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/accessoutentegruppo",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function utente(){
    var self = this;
    return self.$http({
        url: self.baseurl + "/utente",
        method: 'POST',
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function tuttearee(){
    var self = this;
    return self.$http({
        url: self.baseurl + "/tuttearee",
        method: 'POST',
    }).then(function(resp){
        return resp.data;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function crea(parametri, idmodulo){
    var self = this;
    return self.$http({
        url: self.baseurl + "/crea",
        method: 'POST',
        data: $.param({elemento: JSON.stringify(parametri)})
    }).then(function(resp){
        if(typeof idmodulo !== 'undefined' && idmodulo !== null){
            self.mmrs.aggiornaRelazioneMenu({idmenu: resp.data.id, idmodulo: idmodulo}).then(function(resp){
                return resp.data;
            });        
        }        
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function accessoutente(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/accessoutente",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise} La promise contiene un array di kendoTreeNode
 */
function menuarea(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/menuarea",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp.data;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise} La promise contiene un array di kendoTreeNode
 */
function menu(){
    var self = this;
    return self.$http({
        url: self.baseurl + "/menuarea",
        method: 'POST',
    }).then(function(resp){
        return resp.data;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function tipimenu(){
    var self = this;
    return self.$http({
        url: self.baseurl + "/tipimenu",
        method: 'POST',
    }).then(function(resp){
        return _.map(resp.data, function(ris){
            return {text: ris, value: ris};
        });        
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @param {type} [parametri={idarea: idarea}]
 * @returns {promise}
 */
function parent(parametri){
    var self = this;
    
    var httpCfg = {
        url: self.baseurl + "/parent",
        method: 'POST',
    };
    
    if(parametri){
        if(typeof parametri.idarea.id !== 'undefined'){
            parametri.idarea = parametri.idarea.id;
        }
        //parametri.idarea = parametri.idarea.id; //PROBLEMA AREA NON TROVATA VENIVA PASSATO L'INTERO OGGETTO DELL'AREA E INVECE VA PASSATO SOLO L'ID
        httpCfg.data = $.param(parametri);
    }
    
    return self.$http(httpCfg).then(function(resp){
        return resp.data;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function cerca(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/cerca",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function modifica(parametri, idmenu, idmodulo){
    var self = this;
    return self.$http({
        url: self.baseurl + "/modifica",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){        
        self.mmrs.aggiornaRelazioneMenu({idmenu: idmenu, idmodulo: idmodulo}).then(function(resp){
            return resp.data;
        }); 
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function elimina(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/elimina",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

/**
 * @memberOf MenuManagerRS
 * @returns {promise}
 */
function listaaccessi(parametri){
    var self = this;
    return self.$http({
        url: self.baseurl + "/listaaccessi",
        method: 'POST',
        data: $.param(parametri)
    }).then(function(resp){
        return resp;
    }, function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    });
}

module.exports = MenuManagerRS;