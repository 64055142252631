'use strict';

var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");

/**
 * Configurazione di un campo numerico
 */
/**
 * Configurazione del campo di tipo numerico
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSNumeric(formlyConfig, formlyValidationMessages, Valida){
        
    function forceNumber(value){
        //console.log(value);
        return Number(value);
    }
    
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bssnumeric',
        extends: 'bssinput',
        template: require("./bssnumeric.html"),
        controller: ["$scope", "$filter", "$timeout", "$http", "ErrorManager", require("./BssNumericController")],
        defaultOptions: {
            parsers: [forceNumber],
            ngModelElAttrs: {
                //implementazioni di awnum
                'awnum': '',
                'num-int': '6',
                'num-frac': '2',
                'num-fixed': 'true',
                'num-sep': ',',
                'num-thousand': 'true',
                'num-thousand-sep': '.',
                'num-pos': 'true',
                'num-neg': 'true',
                'num-prepend': '',
                'num-append': '',
                'num-round': 'round',
                'ng-trim': 'false',
                //implementazioni mie future
                'step': '1',      
                'cssCategory': 'bssnumeric'
            },
            validators: {
                syncMin: Valida.min, //model.validation.min
                syncMax: Valida.max, //model.validation.max
            },
        },
    }));
}

module.exports = BSSNumeric;