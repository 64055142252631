'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");
require("bssricambiwebFold/ricercadoc/scripts/templates/generadoc.html");
require("bssricambiwebFold/ricercadoc/scripts/templates/datiforncor.html");
require("bssricambiwebFold/ricercadoc/scripts/templates/gestdocfor.html");
require("bssricambiwebFold/documentistd/scripts/templates/storico.html");


/**
 * Registrazione delle route per il modulo bss/ricercadoc
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function RicercadocRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "ricercadoc",
        url: "/ricercadoc/:raggrdoc?codprocedura",
        //templateUrl: "ricambiweb/ricercadoc/scripts/templates/ricercadoc.html",
        template: require("../templates/ricercadoc.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "$filter", "$sce", "DSFactory", "DSCfgFactory", "GridFactory", "GridCfgFactory", "GridNavigator", "ToolbarSrv", "identifica", "$scope", "ToggleActionFactory", "ActionFactory", "ErrorManager", "RicercadocFunzioniAggiuntive", "SessioneService", "SideMenuSrv", require("../RicercadocCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = RicercadocRoutes;
