'use strict';

require("../styles/previewart.scss");

/**
 * 
 * @example
 * <previewarticolo idarticolo='1' /> 
 * 
 */
function PreviewArticolo(){
    return {
        restrict: 'E',
        template: require("./templates/previewart.html"),
        scope: {
            idarticolo: '=',
        },
        controller: ["$scope", "$http", "$q", "$timeout", "ErrorManager", function(s, $http, $q, $timeout, ErrorManager){      
            var self = this;
            self.moment = moment;

            s.$watch(function(){
                return s.idarticolo
            }, function(n, o){
                console.log(n, o)
                if(n!== null && typeof n !== "undefined"){
                    self.caricamento = true;
                    console.log("idarticolo cambiato reinizializzo", s.idarticolo);

                    self.inizializza().then(function(articolo){
                        self.caricamento = false;
                    })
                }
            })

            self.inizializza = function(){
                console.log("inizializzo preview articolo", s)
                self.idarticolo = s.idarticolo;
                self.loading = true;
                return $http({
                    method: "POST",
                    url: utils.urls.wsbase + "/articolirdc/cerca",
                    data: $.param({id: self.idarticolo})
                }).then(function(resp){
                    self.articolo = resp.data;
                    return self.articolo
                })
            }
            
        }],
        controllerAs: "prevartCtrl"
    };
}

module.exports = PreviewArticolo;