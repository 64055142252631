'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/importstd
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */

function ImportstdRoutes($stateProvider, $urlRouterProvider, $locationProvider){

  var self = {
        $stateProvider: $stateProvider,
        stato: "importstd",
        url: "/importstd/:entity",
        //templateUrl: "ricambiweb/importstd/scripts/templates/importstd.html",
        template: require("../templates/importstd.html"),
        controller: ["$http", "$q", "$timeout", "$state", "$stateParams", "DSFactory", "GridFactory", "GridCfgFactory", "ToolbarSrv", "identifica", "$scope", "ActionFactory", "FieldFactory", "Valida", "ErrorManager", "ImportstdFunzioniAggiuntive", require("../ImportstdCtrl")],
        //controllerAs
        //middleware
        //data
        //identifica
    };

    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = ImportstdRoutes;
