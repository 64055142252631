'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @param {type} FieldFactory
 * @returns {undefined}
 */
function BSSLocalitaController($scope, $filter, $timeout, $http, $sce, ErrorManager, DSF, FieldFactory, Valida){                
    
//    console.log("campo localita: ", $scope.options.key, $scope.options.data.modello.validations);    
    
    $scope.stato = {comuneSelezionato: false};
    
    //console.log($scope.options);

    $scope.modelloLocalita = {
        nazione: null,
        comune: null
    };
    
    if(typeof $scope.model[$scope.options.key] === "undefined" || $scope.model[$scope.options.key] === null){
        $scope.model[$scope.options.key] = {}; 
    }
    else {
        $scope.stato.comuneSelezionato = true;
        $scope.modelloLocalita.comune = $scope.model[$scope.options.key]

        $scope.model[$scope.options.key].tipolocalita = 'comune';
    }
    
    $scope.wizardlocalitaUrl = $sce.trustAsResourceUrl("wizardlocalita?view=iframe");
    
    $scope.getCampoCap = function(){
        var modello = {
            validations: $scope.options.data.modello.validations,
            strentity: "tcomuni", 
            codmodulo: 'a', 
            datatextfield: 'cap', 
            datavaluefield: 'id', 
            template: '#= cap # - #= comune #',
            groupField: 'group',
            readUrl: utils.urls.wsbase + '/tcomuni/ricerca',
            externalUrlCfg: {url: "wizardlocalita", parametri: {view: 'iframe'}},
            onSelect: function(e){
                $timeout(function(){
                    var valore = e.sender.dataItem(e.item);
                    $scope.stato.comuneSelezionato = true;
                    $scope.modelloLocalita.nazione = null;
                    $scope.model[$scope.options.key] = valore;
                    $scope.model[$scope.options.key].tipolocalita = 'comune';
                    $scope.model[$scope.options.key].idcomune = valore.id;
                });
            },
        };
        
        
        return new FieldFactory({
            key: "comune", 
            tipo: 'bsssingleselect', 
            label: "CAP",
            colonna: {}, 
            modello: modello
        }, 
        false, 
        {className: 'col-lg-4'}).opzioni;
    }
    
    $scope.getCampoComune = function(){
        var modello = {
            validations: $scope.options.data.modello.validations,
            strentity: "tcomuni", 
            codmodulo: 'a', 
            datatextfield: 'comune', 
            datavaluefield: 'id', 
            template: '#= comune # - #= cap #', //#= label #
            groupField: 'group',
            readUrl: utils.urls.wsbase + '/tcomuni/ricerca',
            externalUrlCfg: {url: "wizardlocalita", parametri: {view: 'iframe'}},
            onSelect: function(e){
                $timeout(function(){
                    var valore = e.sender.dataItem(e.item);
                    $scope.stato.comuneSelezionato = true;
                    $scope.modelloLocalita.nazione = null;
                    $scope.model[$scope.options.key] = valore;
                    $scope.model[$scope.options.key].tipolocalita = 'comune';
                    $scope.model[$scope.options.key].idcomune = valore.id;
                });
            },
        };
        
        return new FieldFactory({
            key: "comune", 
            tipo: 'bsssingleselect', 
            label: "Comune",
            colonna: {}, 
            modello: modello
        }, 
        false, 
        {
            className: 'col-lg-8',            
        }).opzioni;
    }
    
    $scope.campiLocalita = [
        //$scope.getCampoNazione(),
        $scope.getCampoCap(),
        $scope.getCampoComune(),                  
    ];
}

module.exports = BSSLocalitaController;