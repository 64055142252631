'use strict';

require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
var BssBasicField = require("moduleFold/formbuilder/scripts/mixins/BssBasicField");


/**
 * Campo di tipo entity su entity esterna
 * 
 * @class  
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 * @param {Valida} Valida
 */
function BSSTreeviewcheckbox(formlyConfig, formlyValidationMessages, Valida){
    formlyConfig.setType($.extend(true, {}, new BssBasicField(), {
        name: 'bsstreeviewcheckbox',
        template: require("./bsstreeviewcheckbox.html"),
        controller: ["$scope", "$filter", "$timeout", "$http", "ErrorManager", "DSFactory", require("./BssTreeviewcheckboxController")],
        defaultOptions: {
            ngModelElAttrs: {
                'cssCategory': 'bsstreeviewcheckbox'
            },
        }
    }));
}

module.exports = BSSTreeviewcheckbox;