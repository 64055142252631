'use strict';

/**
 * Controller del campo multiselect
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSTreeviewcheckboxController($scope, $filter, $timeout, $http, ErrorManager, DSF){
    
    $scope.dsf = DSF;
    
    var modello = $scope.options.data.modello;

    
    $scope.treechk = {
        strentity: modello.strentity,
        codmodulo: modello.codmodulo,
        codapp: modello.codapp || 'aaa',
        dataTextField: modello.datatextfield,
        dataValueField: modello.datavaluefield,
        itemTemplate: modello.itemtemplate || modello.datatextfield,
        opzioniagg: modello.opzioniagg || {},
        onSelect: modello.onSelect || null,
        onDeselect: modello.onDeselect || null,
        //se true aggiunge la voce di default 'tutti' NON FUNZIONA
        //voceTutti: modello.voceTutti
    }; 
    
    $scope.treechk.opzioni = {
        itemTemplate: "#="+$scope.treechk.itemTemplate+"#",
        dataTextField: $scope.treechk.dataTextField,
        dataValueField: $scope.treechk.dataValueField,
        filter: "contains",
        autoBind: true,
        minLength: 1,
    };
        
//        if($scope.treechk.onSelect !== undefined){
//            $scope.treechk.opzioni.select = function(e){
//                $scope.treechk.onSelect(e);
//            }
//        }
//        
//        if($scope.treechk.onDeselect !== undefined){
//            $scope.treechk.opzioni.deselect = function(e){
//                $scope.treechk.onDeselect(e);
//            }
//        }
    
    if(!$scope.treechk.opzioniagg.dataSource){
        new DSF($scope.treechk.strentity, $scope.treechk.codapp, $scope.treechk.codmodulo, $scope.treechk.opzioniagg).inizializza().then(function(kendoDS){
            $scope.treechk.opzioni.dataSource = kendoDS;
        });
    }
    else {
        $scope.treechk.opzioni.dataSource = $scope.treechk.opzioniagg.dataSource;
    }
    
    
    
}

module.exports = BSSTreeviewcheckboxController;