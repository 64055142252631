"use strict";



/**
 * Classe che implementa tutte le action sulla toolbar.
 * Estende EsponiMixin e ConfrontaMixin
 */
function GridNavigator($timeout, $q, LoginService, $rootScope, $state, $stateParams, FormDaHeaderFactory, Valida, ActFct, ToggleActFct, $http, ErrorManager, StatoApp, ToolbarService){
    
    /**
     * Estende EsponiMixin e ConfrontaMixin, si associa ad una kendoGrid ed espone tutte le action alla toolbar.
     * 
     * @memberOf GridNavigator
     * @constructor
     * @param {kendoGrid} griglia
     */
    function Costruttore(griglia, cfgGriglia){
        var self = this;
        self.$http = $http;
        self.em = ErrorManager;
        self.lockColonneAbilitato = true;                
        self.ts = ToolbarService;
        self.ls = LoginService;
        self.rs = $rootScope;
        self.$q = $q;
        self.fdhf = FormDaHeaderFactory;
        self.valida = Valida;
        self.$timeout = $timeout;
        self.$state = $state;
        self.$sp = $stateParams;
        
        var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
        var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
        var StampaDettaglioMixin = require("moduleFold/mixins/StampaDettaglioMixin");
        angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype, StampaDettaglioMixin.prototype);
        //angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype);
        self.griglia = griglia;
        self.cfgGriglia = cfgGriglia;
        self.dataSource = griglia.dataSource;
        //esporre alla toolbar precedente successivo primo ultimo pulisci selezione
        self.statoapp = StatoApp;
        self.espr = 'ac';
        self.exposed = [
            new ToggleActFct(
                self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}),
                9,
                new ActFct("fa fa-arrows-alt-h", self.esponi({metodo: "inSelectableMode", parametri: ["row"]}), self.esponi({metodo: "setSelectable", parametri: ["multiple, row"]}), null, null, "GRIDNAV.SINGLESEL", "alt+shift+1"),
                new ActFct("fa fa-arrows-alt-v", self.esponi({metodo: "inSelectableMode", parametri: ["multiple, row"]}), self.esponi({metodo: "setSelectable", parametri: ["row"]}), null, null, "GRIDNAV.MULTISEL", "alt+shift+2")
            ),
            new ActFct("fa fa-cog", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), null/*self.esponi({metodo: "salvaCfgUtente", parametri: []})*/, 10, null, "GRIDNAV.SALVACFG", null, [
                new ActFct("fa fa-save", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "salvaCfgUtente", parametri: []}), null, null, "GRIDNAV.SALVACFG", "alt+shift+c"),
                self.ls.isSistemista() ? new ActFct("fa fa-cog", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), self.esponi({metodo: "configura", parametri: []}), null, null, "GRIDNAV.INPLACECFG", "alt+shift+8") : undefined
            ]),
            new ActFct("fa fa-print", self.esponi({metodo: "dimSelezioneConfronta", parametri: ["eq", -1]}), null/*self.esponi({metodo: "salvaCfgUtente", parametri: []})*/, 15, null, "GRIDNAV.STAMPA", null, [
                new ActFct("fa fa-file-pdf", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "stampapdf", parametri: []}), null, null, "GRIDNAV.STAMPAGRIGLIAPDF", "alt+shift+k"),
                new ActFct("fa fa-info",  self.esponi({metodo: "dimSelezioneInMultipleMode", parametri: ["lt", 1, ["form", "griglia"]]}), self.esponi({metodo: "stampa", parametri: ['/'+self.griglia.options.strentity+'/stampa']}), 999, null, "GRIDNAV.STAMPADETTAGLIO"),
                //new ActFct("fa fa-file-excel-o", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "stampaexcel", parametri: []}), null, null, "GRIDNAV.STAMPAGRIGLIAEXCEL")
            ]),
            // new ActFct("fa fa-i-cursor", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), null, 10, null, "GRIDNAV.TIPOSEL", null, [
            //     new ActFct("fa fa-arrows-alt-h", self.esponi({metodo: "inSelectableMode", parametri: ["row"]}), self.esponi({metodo: "setSelectable", parametri: ["row"]}), null, null, "GRIDNAV.SINGLESEL", "alt+shift+1"),
            //     new ActFct("fa fa-arrows-alt-v", self.esponi({metodo: "inSelectableMode", parametri: ["multiple, row"]}), self.esponi({metodo: "setSelectable", parametri: ["multiple, row"]}), null, null, "GRIDNAV.MULTISEL", "alt+shift+2")
            // ]),
            
            new ActFct("fa fa-sync",  self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "ricarica", parametri: []}), 20, null, "GRIDNAV.RICARICA", "alt+shift+r"),
            new ActFct("fa fa-fast-backward", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "primo", parametri: [true]}), 30, null, "GRIDNAV.PRIMO", "alt+shift+up"),                        
            new ActFct("fa fa-backward", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "precedente", parametri: []}), 40, null, "GRIDNAV.PRECEDENTE", "alt+shift+left"),            
            new ActFct("fa fa-forward", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "successivo", parametri: []}), 50, null, "GRIDNAV.SUCCESSIVO", "alt+shift+right"),                        
            new ActFct("fa fa-fast-forward", self.esponi({metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]}), self.esponi({metodo: "ultimo", parametri: [true]}), 60, null, "GRIDNAV.ULTIMO", "alt+shift+down"),
            new ActFct("fa fa-eraser", self.esponi({metodo: "dimSelezioneInMode", parametri: ["lt", 1, "griglia"]}), self.esponi({metodo: "pulisciSelezione", parametri: []}), 70, null, "GRIDNAV.PULISCISELEZIONE", "alt+shift+h"),
            new ActFct(["fa fa-filter", "fa fa-ban text-danger"], self.esponi({metodo: 'sonoPresentiFiltri', parametri: []/*metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]*/}), self.esponi({metodo: "rimuoviFiltri", parametri: []}), 80, null, "GRIDNAV.RIMUOVIFILTRI", "alt+shift+x"),
            new ActFct(["fa fa-sort", "fa fa-ban text-danger"], self.esponi({metodo: 'sonoPresentiOrdinamenti', parametri: []/*metodo: "dimSelezioneInMode", parametri: ["eq", -1, "griglia"]*/}), self.esponi({metodo: "rimuoviOrdinamenti", parametri: []}), 80, null, "GRIDNAV.RIMUOVIORDINAMENTI", "alt+shift+y"),
        ];

        self.rs.configurazione = null;

        self.$http({
            url: utils.urls.wsbase + "/table/header",
            method: "GET",
            cache: true,
            params: {entity: "com.bsssrl.bsswebcore_ent.Cfgutenti", strentity: "cfgutenti"}
        }).then(function(resp){
            self.rs.headermodulocfg = resp.data;
            self.rs.cfgformcfg = new self.fdhf("", self.rs.headermodulocfg, null, null, self.rs.formcfg, true);
        })

        self.rs.selezionacfg = function(configurazione){
            self.$timeout(function(){
                angular.copy(configurazione, self.rs.configurazione)
            })
        }
        
        self.rs.salvacfg = function(){
            if(self.valida.validaForm(self.rs.formcfg)){
                console.log("da implementare salvataggio", self.rs.configurazione);
                self.$http({
                    method: "POST",
                    url: utils.urls.wsbase + "/cfgutenti/modifica",
                    data: $.param({elemento: JSON.stringify(self.rs.configurazione)})
                }).then(function(resp){
                    self.rs.chiudicfg()
                })
            }
        }

        self.rs.chiudicfg = function(){
            self.$timeout(function(){

                
                
                if(self.ts.mode === "griglia"){//sono in griglia 
                    window.location.reload()
                } else {//sono in form
                    var ctrl = self.griglia.$angular_scope.moduloCtrl;

                    var statecfgobj = {
                        strentity: self.$sp.strentity,
                        codapp: self.$sp.codapp,
                        codmodulo: self.$sp.codmodulo,
                        mode: null,
                        selezione: (self.ts.selezione.length ? self.ts.selezione[0].id : null)
                    };

                    if(ctrl.gridCfg.form.inVisualizza){//inVisualizza è true
                        statecfgobj = angular.extend({}, statecfgobj, {mode: "visualizza"})
                        self.$state.go("moduli", statecfgobj, {reload: true})
                    } else {
                        if(self.ts.selezione.length){

                            

                            if(ctrl.gridCfg.saveMode === 'modifica'){//sono in modifica
                                statecfgobj = angular.extend({}, statecfgobj, {mode: "modifica"})
                                self.$state.go("moduli", statecfgobj, {reload: true})
                            } else {//sono in duplica
                                statecfgobj = angular.extend({}, statecfgobj, {mode: "duplica"})
                                self.$state.go("moduli", statecfgobj, {reload: true})
                            }
                        } else {//sono in crea
                            statecfgobj = angular.extend({}, statecfgobj, {mode: "crea"})
                            self.$state.go("moduli", statecfgobj, {reload: true})
                        }
                    }
                }
                                    
            })
        }
    }
    
    //pagina attuale
    Costruttore.prototype.paginaAttuale = paginaAttuale;
    //dimensione pagina
    Costruttore.prototype.dimPagina = dimPagina;
    //dimensione selezione
    Costruttore.prototype.dimSelezione = dimSelezione;
    //totale record
    Costruttore.prototype.totRecord = totRecord;
    //totale pagine
    Costruttore.prototype.totPagine = totPagine;
    //dato un numero di record ritorna la pagina in cui si trova
    Costruttore.prototype.paginaPerRecord = paginaPerRecord;
    //dato un numero di record ritorna l'offset in cui si trova
    Costruttore.prototype.offsetPerRecord = offsetPerRecord;
    //dato un numero di record ritorna la pagina in cui si trova e l'offset
    Costruttore.prototype.offsetEPagina = offsetEPagina;
    //cambia pagina e ritorna una promise
    Costruttore.prototype.cambiaPaginaSinc = cambiaPaginaSinc;
    //seleziona un record e ritorna una promise
    Costruttore.prototype.selezioneSinc = selezioneSinc;
    //seleziona un record specificato come parametro, opzionalmente fa lo scroll al suddetto record
    Costruttore.prototype.vaiA = vaiA;  
    //seleziona un record specificato come parametro, opzionalmente fa lo scroll al suddetto record
    Costruttore.prototype.selettoreRiga = selettoreRiga;   
    //vai al primo record
    Costruttore.prototype.primo = primo;
    //vai all'ultimo record
    Costruttore.prototype.ultimo = ultimo;
    //numero record (relativo alla pagina) selezionato
    Costruttore.prototype.nRecR = nRecR;
    //numero record (assoluto) selezionato
    Costruttore.prototype.nRecA = nRecA;
    //vai al record precedente
    Costruttore.prototype.precedente = precedente;
    //vai al record successivo
    Costruttore.prototype.successivo = successivo;
    //scroll al record selezionato
    Costruttore.prototype.scrollASelezione = scrollASelezione;
    //pulisci selezione
    Costruttore.prototype.pulisciSelezione = pulisciSelezione;
    //abilita lock delle colonne
    Costruttore.prototype.abilitaBloccaColonne = abilitaBloccaColonne;
    //controlla lo stato di lock delle colonne
    Costruttore.prototype.statoLockBlocco = statoLockBlocco;
    //controlla lo stato di lock delle colonne
    Costruttore.prototype.ridimensiona = ridimensiona;
    //salva la configurazione della griglia
    Costruttore.prototype.salvaCfgUtente = salvaCfgUtente;
    //ricarica il datasource
    Costruttore.prototype.ricarica = ricarica;
    //stampa pdf
    Costruttore.prototype.stampapdf = stampapdf;
    //stampa excel
    Costruttore.prototype.stampaexcel = stampaexcel;

    //rimuovi filtri
    Costruttore.prototype.rimuoviFiltri = rimuoviFiltri;
    Costruttore.prototype.sonoPresentiFiltri = sonoPresentiFiltri;
    //rimuovi ordinamenti
    Costruttore.prototype.rimuoviOrdinamenti = rimuoviOrdinamenti;
    Costruttore.prototype.sonoPresentiOrdinamenti = sonoPresentiOrdinamenti;


    Costruttore.prototype.setSelectable = setSelectable;
    Costruttore.prototype.inSelectableMode = inSelectableMode;

    Costruttore.prototype.configura = configura;


    function sonoPresentiFiltri(){
        return this.dimSelezioneInMode("eq", -1, "griglia") && typeof this.griglia.dataSource.filter() !== 'undefined';
    }

    function sonoPresentiOrdinamenti(){
        return this.dimSelezioneInMode("eq", -1, "griglia") && this.griglia.dataSource.sort() !== 'undefined';
    }

    function rimuoviFiltri(){
        this.griglia.dataSource.filter({});
    }

    function rimuoviOrdinamenti(){
        this.griglia.dataSource.sort({});
    }

    function stampapdf(){
        this.griglia.saveAsPDF();
    }
    
    function stampaexcel(){
        this.griglia.saveAsExcel();
    }
    
    function salvaCfgUtente(){
        var self = this;
        var parametri = {
            nome: this.griglia.options.nomeCfgGriglia,
            configurazione: this.griglia.columns,
        };
        
        parametri.configurazione = _.map(parametri.configurazione, function(colonna){
            if(!isNaN(colonna.width)){
                colonna.width = colonna.width+"px";
            }
            return colonna;
        });
        
        console.log("configurazione da salvare per ", parametri.nome, parametri.configurazione);
        parametri.configurazione = JSON.stringify(parametri.configurazione);
        
        return self.$http({
            url: utils.urls.wsbase + "/cfgutenti",
            method: "POST",
            data: $.param(parametri),
        }).then(function(resp){
            self.em.showT("GRIDNAV.CFGSALVATA", "success");            
            return resp.data;
        });
        
    }
    
    function ridimensiona(){
        var self = this;
        
        //risoluzione finestra
        var dimensioni = {
            width: window.innerWidth,
            height: window.innerHeight
        };
        
        //primo pixel grilia
        var posizione = self.griglia.content.offset();
        console.log("dimesione schermo:  ", dimensioni , "posizione griglia", posizione);
        
        self.griglia.content.height(dimensioni.height-posizione.top-55);
    }
    
    /**
     * Ricarica la griglia
     * 
     * @memberOf GridNavigator
     */
    function ricarica(){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.griglia.dataSource.read();
        });
        
    }
    
    /**
     * Ottiene il numero di pagina
     * 
     * @memberOf GridNavigator
     * @returns {number}
     */
    function paginaAttuale(){
        var self = this;
        return self.dataSource.page();
    }
    
    /**
     * Ottiene la dimensione della pagina
     * 
     * @memberOf GridNavigator
     * @returns {number}
     */
    function dimPagina(){
        var self = this;
        return self.dataSource.pageSize();
    }
    
    /**
     * Ottiene la dimensione della selezione
     * 
     * @memberOf GridNavigator
     * @returns {number}
     */
    function dimSelezione(){
        var self = this;
        return self.griglia.select().length;
    }
    
    /**
     * Ottiene il numero totale (in tutte le pagine) degli elementi nel datasource della griglia
     * 
     * @memberOf GridNavigator
     * @returns {number}
     */
    function totRecord(){
        var self = this;
        return self.dataSource.total();
    }
    
    /**
     * Ottiene il numero delle pagine
     * 
     * @memberOf GridNavigator
     * @returns {number}
     */
    function totPagine(){
        var self = this;
        return self.dataSource.totalPages();
    }
    
    /**
     * Dato un record restituisce la pagina in cui si trova
     * 
     * @memberOf GridNavigator
     * @param {number} record numero assoluto del record 
     * @returns {number}
     */
    function paginaPerRecord(record){
        var self = this;
        return (Math.ceil(record / self.dimPagina())) % (self.totPagine() + 1);
    }
 
    /**
     * Dato un record restituisce l'offset nella pagina
     * 
     * @memberOf GridNavigator
     * @param {number} record numero assoluto del record 
     * @returns {number}
     */    
    function offsetPerRecord(record){
        var self = this;
        return record % self.dimPagina();
    }
    
    /**
     * Ottiene oggetto con pagina e offset del record passato
     * 
     * @memberOf GridNavigator
     * @param {number} record numero assoluto del record 
     * @returns {Object}
     */
    function offsetEPagina(record){
        var self = this;
        return {
            pagina: self.paginaPerRecord(record),
            offset: self.offsetPerRecord(record),
        };
    }
    
    /**
     * Cambia la pagina e ritorna una promise, permettendo dunque di selezionare un record specifico
     * 
     * @memberOf GridNavigator
     * @param {number} pagina
     * @returns {promise}
     */
    function cambiaPaginaSinc(pagina){
        var d = $q.defer();        
        var self = this;
        var pagAtt = self.paginaAttuale();
        if(pagina !== pagAtt){
            self.dataSource.page(pagina);
            
            self.griglia.one("dataBound", function(e){
                var pa = self.paginaAttuale();
                if(pa !== pagAtt){
                    d.resolve("pagina cambiata in " + pa);
                }
            });
        }
        else {
            d.resolve("pagina invariata");
        }
        
        return d.promise;
    }
    
    /**
     * Chiamato da vaiA
     * 
     * @memberOf GridNavigator
     * @param {jQuerySelector} selettore
     * @returns {promise}
     */
    function selezioneSinc(selettore){
        var d = $q.defer();
        
        var self = this;
        var selAtt = self.griglia.select();
        
        self.griglia.one("change", function(e){
            var selNuova = self.griglia.select();
            if(selNuova !== selAtt){
                d.resolve("cambiata selezione");
            }
        });
        
        self.griglia.select(selettore);
        
        return d.promise;
    }
    
    /**
     * Scroll in maniera da rendere visibile il selezionato
     * 
     * @memberOf GridNavigator
     */
    function scrollASelezione(){
        var self = this;
        var sel = self.griglia.select()[0];
        var topoffset = parseInt($(sel).offset().top)-80;
        $('html, body').animate({scrollTop: '+='+topoffset+'px'}, 800);
        
    }
    
    /**
     * Ottiene un elemento jQuery che corrisponde al record della griglia selezionato
     * 
     * @memberOf GridNavigator
     * @param {number} numriga
     * @returns {jQueryElement}
     */
    function selettoreRiga(numriga){
        var self = this;
        return self.griglia.tbody.find("tr:eq("+(numriga-1)+")");
    }
    
    /**
     * Pulisce la selezione, se necessario cambia pagina e seleziona il record desiderato.
     * Se scroolTo è true fa in modo che il record selezionato sia visibile.
     * 
     * @memberOf GridNavigator
     * @param {number} record
     * @param {boolean} scrollTo
     */
    function vaiA(record, scrollTo){
        var self = this;
        console.log("vado al record: ", record);
        var spostamento = self.offsetEPagina(record);
        self.griglia.clearSelection();
        self.cambiaPaginaSinc(spostamento.pagina).then(function(cp){
            self.selezioneSinc(self.selettoreRiga(spostamento.offset)).then(function(se){
                if(scrollTo){
                    self.scrollASelezione();
                }
            });                        
        });
    }
  
    /**
     * Come vaiA seleziona il primo record in girglia
     * 
     * @memberOf GridNavigator
     * @param {boolean} scrollTo
     */    
    function primo(scrollTo){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.vaiA(1, scrollTo);
        });
        
    }

    /**
     * Come vaiA seleziona l'ultimo record in griglia
     * 
     * @memberOf GridNavigator
     * @param {boolean} scrollTo
     */ 
    function ultimo(scrollTo){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            var ultimorec = self.totRecord();
            self.vaiA(ultimorec, scrollTo);
        });
        
    }

    /**
     * Numero record relativo alla pagina attuale
     * 
     * @memberOf GridNavigator
     * returns {number}
     */ 
    function nRecR(){
        var self = this;
        var selezione = self.griglia.select();
        var selettoreRighe = $(self.griglia.tbody).first().getPath() + " tr";
        var nrr = selezione.index(selettoreRighe) + 1;
        return nrr;
    }
    
    /**
     * Numero record assoluto
     * 
     * @memberOf GridNavigator
     * returns {number}
     */    
    function nRecA(){
        var self = this;
        var nrr = self.nRecR();
        var pagina = self.paginaAttuale();
        var dimP = self.dimPagina();
        var nra = (dimP * (pagina-1)) + nrr;
        return nra;
    }
    
    
    /**
     * Come vaiA seleziona il record precedente
     * 
     * @memberOf GridNavigator
     * @param {boolean} scrollTo
     */    
    function precedente(scrollTo){
        var self = this;
        
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            var record = self.nRecA() - 1;
            if(record === 0){
                self.ultimo();
            }
            else {
                self.vaiA(record, scrollTo);
            }    
        });
        
        
    }
    
    /**
     * Come vaiA seleziona il record successivo
     * 
     * @memberOf GridNavigator
     * @param {boolean} scrollTo
     */     
    function successivo(scrollTo){
        var self = this;
        
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            var record = self.nRecA() + 1;
            var totRec = self.totRecord();
            if(record > totRec){
                self.primo();
            }
            else {
                self.vaiA(record, scrollTo);
            } 
        });
       
        
    }
    
    /**
     * Pulisce la selezione della griglia
     * 
     * @memberOf GridNavigator
     */ 
    function pulisciSelezione(){
        var self = this;
        self.statoapp.confermaProcedi().then(function(conferma){
            if(!conferma) return;
            self.griglia.clearSelection();
        });
        
    }
    
    /**
     * Controllo il lock delle colonne
     * 
     * @memberOf GridNavigator
     */ 
    function statoLockBlocco(){
        return !this.lockColonneAbilitato;
    }
    
    
    /**
     * Abilita il lock delle colonne
     * 
     * @memberOf GridNavigator
     */ 
    function abilitaBloccaColonne(){
        //non funziona se non è presente almeno una colonna locked
        //this.griglia.lockColumn(1);
        console.log("abilito blocco colonne: ", this.cfgGriglia.columns[0]);
        this.cfgGriglia.columns[0].locked = true;
        console.log("abilito blocco colonne: ", this.cfgGriglia.columns[0]);
        this.lockColonneAbilitato = true;
    }

    function setSelectable(mode, suppressNotification){
        var self = this;
        self.griglia.setOptions({selectable: mode});
        var label = "GRIDNAV." + (mode === "row" ? "SINGLESELENABLED" : "MULTISELENABLED");
        if(suppressNotification !== true){
            self.em.showT(label, "success");
        }
    }

    function inSelectableMode(mode){
        var self = this;
        return self.griglia.options.selectable === mode;
    }

    function configura(){
        var self = this;

        var esito = confirm("Procedendo accederai al form di configurazione. Il salvataggio o la chiusura del suddetto form causeranno il reload della pagina. Vuoi continuare?")
        
        if(!esito) return;

        var cfgsuffix = "@" + self.cfgGriglia.entity + "@" + (self.cfgGriglia.codmodulo !== undefined ? self.cfgGriglia.codmodulo : "");
        var cfgs = [
            "GRID@" + cfgsuffix,
            "FORM@" + cfgsuffix,
            "MODEL@" + cfgsuffix,
        ];

        //devo recuperare gli id delle configurazioni di [GRID,MODEL,FORM]@{entity}@{codmodulo}
        self.$http({
            url: utils.urls.wsbase + "/cfgutenti/grid",
            method: "POST",
            data: $.param({
                filter: {
                    logic: 'and',
                    filters: [
                        {operator: "contains", value: cfgsuffix, field: "nomecfg"}
                    ]
                }
            })
        }).then(function(resp){            
            self.rs.configurazioniinmodifica = resp.data.results; 
            self.rs.configurazione = null;
            self.rs.configuraWin.open().center();
        })

    }

    
    
    return Costruttore;
}

module.exports = GridNavigator;