"use strict";

/**
 * Controlla che la lunghezza del valore sia minore di scope.options.data.modello.validations.maxlength
 * 
 * @class
 * @param {$translate} $translate
 */
function MaxLengthFct($translate){
    
    /**
     * Definisce il messaggio da mostrare in caso di validazione fallita. 
     * Il messaggio viene ottenuto dal servizio $translate. 
     * Viene data la possibilità di passare chiave di validazione e parametri (oppure il messaggio) quando si istanzia il validatore.
     * Priorità:
     * - messaggio
     * - chiave e parametri
     * - chiave di default
     * 
     * @memberOf MaxLengthFct
     * @constructor
     * @param {string} [messaggio = null] stringa con il messaggio di validazione
     * @param {string} [chiave = null] chiave di traduzione
     * @param {Object} [parametri = null] parametri da passare, può servire solo nel caso in cui è presente la chiave
     */        
    function Costruttore(messaggio, chiave, parametri){
        var self = this;
        $translate(chiave || "VALIDATORI.MAXLENGTH", parametri).then(function(_defaultMessage){
            self.message = messaggio || _defaultMessage;
        });
    }
    
    Costruttore.prototype.expression = expression;

    /**
     * Applica la regola di validazione e ritorna un boolean
     * 
     * @memberOf MaxLengthFct
     * @param {type} viewValue
     * @param {type} modelValue
     * @param {type} scope
     * @returns {Boolean}
     */      
    function expression(viewValue, modelValue, scope){
        var valore = modelValue || viewValue || null;
        if(typeof valore !== "undefined" && valore !== null){
            return (typeof valore === 'string') ? (valore.length <= Number(scope.options.data.modello.validations.maxlength)) : true;
        }
        return true;
    }
        
    return Costruttore;
}

module.exports = MaxLengthFct;