"use strict";


/**
 * Classe che permette di fare confronti, utile per implementare 
 * strategy pattern. Creata come mixin per essere specializzata
 * assieme ad altre classi (ereditarietà multipla).
 * 
 * @class 
 * 
 * @example
 * angular.extend(this, ConfrontaMixin.prototype, AltroMixin.prototype);
 * this.eq(5,6); //ritorna false
 * //per utilizzarla ovunque
 * var cmInstantiator = require("moduleFold/mixins/ConfrontaMixin");
 * var cm = new cmInstantiator();
 * cm.eq(5,6); //ritorna false
 * 
 * 
 */
function StampaDettaglioMixin(){}

StampaDettaglioMixin.prototype.aggiungiPulsanteStampa = aggiungiPulsanteStampa;
StampaDettaglioMixin.prototype.stampa = stampa;

function aggiungiPulsanteStampa(urlWS){
    var self = this;

    var azioniGN = self.ts.actionsProviders.gridNavigation.exposed;
    azioniGN = _.map(azioniGN, function(azione){
        //if(azione.descrizione === 'Stampe Modulo'){
        if(azione.chiavetraduzione === 'GRIDNAV.STAMPA'){
            var stampaDettaglio = new self.af("fa fa-info",  self.esponi({metodo: "dimSelezioneInMultipleMode", parametri: ["lt", 1, ["form", "griglia"]]}), self.esponi({metodo: "stampa", parametri: [urlWS/*es. /contratto/stampa*/]}), 999, null, "GRIDNAV.STAMPADETTAGLIO", "alt+shift+p");            
            azione.azioni.push(stampaDettaglio);
        }
        return azione;
    });
    self.$timeout(function(){
        self.ts.setProviders({gridNavigation: {exposed: azioniGN }}, false);
//        console.log("mbdistinte azioni dopo: ", self.ts.actionsProviders.gridNavigation.exposed);
    }, 1000);
}



function stampa(urlWS, griglia, listaid, onlyPreview){
    var self = this;
    if(typeof griglia === 'undefined' || griglia === null){
        griglia = self.griglia;
    }

    if(typeof listaid === 'undefined' || listaid === null){
        var listaid = _.pluck(_.map(_.uniq(griglia.select(), function(tr){ 
            //console.log("selezione", angular.element(tr).attr("data-uid"));
            return angular.element(tr).attr("data-uid"); 
        }), function(riga){
            return griglia.dataItem(riga);
        }), 'id');
    }

    
    return self.$http({
        url: utils.urls.wsbase + urlWS,
        method: 'POST',
        data: $.param({listaid: JSON.stringify(listaid)}),
        headers: {type: 'application/octet-stream'},
        responseType: 'arraybuffer'
    }).then(function(resp){
        var file = new Blob([resp.data], { type: 'application/pdf' });
        if(onlyPreview === true){
            return file;
        } else {
            saveAs(file, moment().format("DDMMYYYYHHmmss")+'.pdf');
        }
        
        
        
    }, function(err){
        self.em.errorCB(err);
    });
}


module.exports = StampaDettaglioMixin;