'use strict';

var RouteModuloBaseMixin = require("moduleFold/modulobase/scripts/mixins/RouteModuloBaseMixin");


/**
 * Registrazione delle route per il modulo bss/modulobase
 * 
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 * @returns {undefined}
 */

function Routes($stateProvider, $urlRouterProvider, $locationProvider){
    
    var self = {
        $stateProvider: $stateProvider
    };
    
    angular.extend(self, RouteModuloBaseMixin.prototype);
    self.registra();
}

module.exports = Routes;


