'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bssricambiwebFold/articoli/styles/articoli.scss");

var bssarticoli = angular.module("bss.bssricambiweb.articoli", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])
    .service("ArticoliFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./ArticoliFunzioniAggiuntive")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/ArticoliRoutes")])
    .directive("prezziarticolo", [require("./PrezziArticolo")])
    .directive("compatibility", [require("./Compatibility")])
    .directive("codartforn", [require("./CodArtForn")])
    .directive("previewarticolo", [require("./PreviewArticolo")])
    .directive("detart", [require("./DetArt")])
;

module.exports = bssarticoli;
