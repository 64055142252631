'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");


var defaultRead = require("moduleFold/datasource/scripts/utils.js").defaultRead;

/**
 * Controller relativo al template della route /anagrafiche
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} AnagraficheFunzioniAggiuntive
 */
function AnagraficheCtrl($http, $q, $timeout, $state, $stateParams, FormDaHeaderFactory, Valida, FieldFactory, DSF, GF, GCF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, AnagraficheFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = AnagraficheFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$sp = $stateParams;
    self.fdhf = FormDaHeaderFactory;
    self.valida = Valida;
    self.ff = FieldFactory;
    self.em = ErrorManager;

    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = $stateParams;
    
    self.anagraficasel = null;

    if( ["clienti", "fornitori", "agenti", "treceiver"].indexOf(self.parametri.strentity) === -1 ){
        self.$state.go("moduli", {strentity: self.parametri.strentity}, {reload: true})
    }

    

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype);


    if(self.parametri.strentity === 'treceiver'){
        var azioni = [
            new self.af("fas fa-user-plus", self.esponi({metodo: "dimSelezioneInMode", parametri: ["ne", 1, "griglia"]}), self.esponi({metodo: "popupReceiver", parametri: []}), 999, null, "ANAGRAFICHE.ADDRECEIVER", null),
        ]
    
        self.ts.setProviders({"anagraficheprovider": {exposed: azioni}}, false);
    }

    

    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza().then(function(){
        self.initFormReceiver();
        self.initGridIntestazione()
    })
        
    $scope.$on("kendoWidgetCreated", function(event, widget){
        if (widget === self.griglia) {
            $scope.$watch(function(){
                return self.griglia.dataItem(self.griglia.select())
            }, function(n, o){     
                self.$timeout(function(){
                    self.anagraficasel = null;
                    if(n !== null && n.length !== 0 && n !== o){
                        var selezione = self.griglia.select().toArray().map(function(rigael){
                            return self.griglia.dataItem(rigael);
                        });
                        if(selezione.length === 1){//inizializzo la griglia dei datiintestazione
                            //console.log("mostro di dati di intestazione per", selezione)  
                            var ana =  angular.copy(selezione[0]);                     
                            self.anagraficasel = ana;
                            self.gridDatiIntestazione.dataSource.read();
                        } else {//nascondo la griglia di datitintestazione                        
                            self.anagraficasel = null;
                        }
                    }
                })
                
                            
                
                
            })
            
        }
    });
    
}

AnagraficheCtrl.prototype.initGridIntestazione = initGridIntestazione;
AnagraficheCtrl.prototype.initFormReceiver = initFormReceiver;
AnagraficheCtrl.prototype.popupReceiver = popupReceiver;
AnagraficheCtrl.prototype.addReceiver = addReceiver;

/**
 * @description
 * Inizializzo la griglia dell'intestazione da Maxpho
 */
function initGridIntestazione() {
    var self = this;

    var strentity = "datiintestazione";

    function apriPopupDatiIntestazione(e, griglia){
        var dataItem = griglia.dataItem(e.currentTarget);
        self.datiIntestazioneSel = dataItem;
        self.datiIntestazioneWin.open().center();
    }


    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, null, {sort: [{field: "usata", dir: "desc"}]}).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, null, null, true).inizializza().then(function (gridcfg) {            
            gridcfg.filterable = { mode: "row" }

            ds.transport.parameterMap = function (data, type) {
                var newdata = angular.extend({}, defaultRead(data, gridcfg.dataSource.options));
                console.log("anagraficasel definita???? ====>", self.anagraficasel)
                if(!self.nou(bssRecursiveGetter(self, "anagraficasel.id", null))){//solo se ho l'id dell'anagrafica
                    
                    newdata.idanagrafica = self.anagraficasel.id;

                    /**
                     * @todo vedere se è possibile evitare questo, a monte dovrebbe essere sempre filtrato in base a newdata.idanagrafica
                     * vedere come funziona in indirizzi/grid
                     */
                    var filtro = {field: 'anagraficaPGFStd.id', value: newdata.idanagrafica, operator: 'eq'};
                    
                    var currentFilters = self.gridDatiIntestazione.dataSource.filter();
                    if(self.nou(currentFilters)){//non esistono filtri
                        newdata.filter = {
                            logic: 'and',
                            filters: [
                                filtro
                            ]
                        }
                    } else {//esistono filtri provo ad aggiornare il filtro per anagraficaPGFStd.id
                        newdata.filter = currentFilters;
                        var trovato = false;
                        newdata.filter.filters = newdata.filter.filters.map(function(exfilter){
                            if(exfilter.field === 'anagraficaPGFStd.id'){
                                trovato = true;
                                exfilter.value = newdata.idanagrafica;
                            } 
                            return exfilter;
                        })
                        if(!trovato){//erano presenti filtri ma nessun filtro riguardava anagraficaPGFStd.id
                            newdata.filter.filters.push(filtro);
                        }
                    }
                }                      
                return newdata
            }

            gridcfg.dataBound = function (e) {
                if (typeof self.gridDatiIntestazione !== 'undefined') {
                    //console.log("databound", self.gridDatiIntestazione);                   
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        apriPopupDatiIntestazione(e, griglia)
                    })

                }
            }

            gridcfg.dataSource = ds;

            self.gridDatiIntestazioneOpts = angular.extend({}, gridcfg);
            self.gridDatiIntestazioneRebind = moment().format();
            //return ds.read();
            return self.$q.resolve(self.gridDatiIntestazioneOpts)
        })
    });
}

function initFormReceiver(){
    var self = this;

    var entitydataintest = "com.bsssrl.bssstdgestent.DatiIntestazione";
    var strentitydataintest = "datiintestazione"

    self.$q.all([
        self.$http({
            url: utils.urls.wsbase + "/tnazioni/grid",
            method: "POST",
            data: $.param({
                take: 300,
                skip: 0,
                page: 1,
                pageSize: 300,                
            })
        }),//0 - nazioni
        self.$http({
            url: utils.urls.wsbase + "/table/header",
            method: "POST",
            data: $.param({ codapp: null, codmodulo: "ORD", entity: entitydataintest, strentity: strentitydataintest })
        }),//1 - header form intestazione
    ]).then(function(resps){
        self.nazioni = resps[0].data.results;
        self.italia = self.nazioni.find(function(naz){return naz.iso === 'IT'});        
        self.headerReceiver = resps[1].data;

        var objcampi = new self.fdhf("", self.headerReceiver, null, null, self.formReceiver, true);

        objcampi.campi = objcampi.campi.map(function(campo, indice){
            if(campo.key === 'nazione') {

                campo = new self.ff({
                    "key": "nazione",
                    "label": "Nazione",
                    "tipo": "bssselect", 
                    "colonna": {},
                    "modello": {

                    }
                },
                false,
                {
                    "className": "col-lg-4",       
                    defaultValue: self.italia,
                    templateOptions: {
                        options: self.nazioni,
                        valueProp: 'id',
                        labelProp: 'nazione'
                    }
                }).opzioni


            }            

            return campo;
        })

        
        self.cfgFormReceiver = angular.copy(objcampi);
        self.cfgFormReceiver.campi = angular.copy(self.cfgFormReceiver.campi.filter(function(campo){
            return campo.key !== 'nome'
        }).map(function(campo){
            if(campo.key === 'cognome'){
                campo.templateOptions.label = "Nominativo";
                campo.templateOptions.placeholder = "Nominativo";
            }
            return campo;
        }));
    })

    
}

function popupReceiver(){
    var self = this;
    self.addreceiverwin.open().center();
}

function addReceiver(){
    var self = this;

    if (self.valida.validaForm(self.formReceiver)) {
        var data = angular.extend({}, self.receiver);
        data.anagraficaPGFStd = {id: self.anagraficasel.id};
        //console.log("salgo i dati del receiver", self.receiver);

        //@todo aggiornare con il webservice effettivo
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/anagraficapgfstd/creareceiver",
            data: $.param({
                //idanagrafica: self.anagraficasel.id,
                datiintestazione: JSON.stringify(data)
            })
        }).then(function(resp){
            self.gridDatiIntestazione.dataSource.read();
            self.opzioniFormReceiver.resetModel();
            self.addreceiverwin.close();
        }, function(err){
            self.em.errorCB(err);
        })
    }
}

module.exports = AnagraficheCtrl;
