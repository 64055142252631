'use strict';

/**
 * Resource Manager di base per tutte le identity con ws crud
 * 
 * @class
 * @param {type} $http
 * @param {type} ErrorManager
 * 
 * @example 
 * chi estende questa classe per avere le funzionalità di base dovrà avere semplicemente le property: 
 * baseUrl, nsTraduzione, $http , em (ErrorManager)
 */
function IdentityRS(){}

IdentityRS.prototype.genericWS = genericWS;
IdentityRS.prototype.crea = crea;
IdentityRS.prototype.modifica = modifica;
IdentityRS.prototype.cancella = cancella;
IdentityRS.prototype.cercatutti = cercatutti;
IdentityRS.prototype.cercaperid = cercaperid;

/**
 * 
 * @memberOf IdentityRS
 * @param {type} metodo
 * @param {type} parametri
 * @param {type} chiaveTraduzione
 * @param {type} httpCfg
 * @param {type} successo
 * @param {type} errore
 * @returns {genericWS}
 */
function genericWS(metodo, parametri, chiaveTraduzione, httpCfg, successo, errore){
    var self = this;
    
    var genHttpCfg = {
        url: self.baseUrl + "/" + metodo,
        method: 'POST',
    };
    
    if(parametri) genHttpCfg.data = $.param(parametri);
    
    var httpCfg = angular.extend({}, genHttpCfg, httpCfg);
    
    var succCB = successo || function(resp){
        if(chiaveTraduzione){
            self.em.showT(self.nsTraduzione+"."+chiaveTraduzione, "success");
        }
        return resp.data;
    };
    
    var errCB = errore || function(resp){ 
        self.em.errorCB(resp);
        throw resp;
    };
    
    return self.$http(httpCfg).then(succCB, errCB);
}

/**
 * @memberOf IdentityRS
 * @param {type} parametri
 */
function crea(parametri){
    var metodo = 'crea';
    return this.genericWS(metodo, parametri, "CREATO" );
}

/**
 * @memberOf IdentityRS
 * @param {type} parametri
 */
function modifica(parametri){
    var metodo = 'modifica';
    var self = this;
    var params = {};
    params[self.nomeEntity] = JSON.stringify(parametri);
    console.log("parametri da argomenti funzione: ", parametri);
    console.log("parametri per il server: ", params);
    return this.genericWS(metodo, null, "MODIFICATO", {
        url: self.baseUrl + "/" + metodo,
        method: 'POST',
        data: $.param(params)
    });
}

/**
 * @memberOf IdentityRS
 * @param {type} parametri
 */
function cancella(parametri){
    var metodo = 'cancella';
    return this.genericWS(metodo, parametri, "ELIMINATO" );
}

/**
 * @memberOf IdentityRS
 * @param {type} parametri
 */
function cercatutti(parametri){
    var metodo = 'cercatutti';
    return this.genericWS(metodo, parametri, null);
}

/**
 * @memberOf IdentityRS
 * @param {type} parametri
 */
function cercaperid(parametri){
    var metodo = 'cercaperid';
    return this.genericWS(metodo, parametri, null);
}

module.exports = IdentityRS;