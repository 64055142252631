'use strict';

function BssNumericController($scope, $filter, $timeout, $http, ErrorManager){

    $scope.svuota = function(){
        var setter = require("lodash").set;
        setter($scope.model, $scope.options.key, null);
    }

}

module.exports = BssNumericController;