'use strict';

/**
 * Wrapper che aggiunge la il div esterno di base lo usano tutti i campi in automatico,
 * serve solo per i campi che vengono generati da zero (senza tipo)
 * 
 * @class
 * @param {type} formlyConfig
 * @param {type} formlyValidationMessages
 */
function BssBaseWrapper(formlyConfig, formlyValidationMessages){
    formlyConfig.setWrapper({
        name: "bssbase",
        template: require("./templates/bssbase.html"),
    })
}

module.exports = BssBaseWrapper;