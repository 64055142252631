'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/genericidentity/scripts/index.js");


/**
 * @module bss/modulemanager
 * @description Modulo modulemanager
 *
 */
var bssmodulemanager = angular.module("bss.modulemanager", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.genericidentity"])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/Routes")])
    .service("ModulemanagerRS", ['$http', "ErrorManager", "IdentityRS", require("./ModulemanagerRS")])
    .factory("ModulemanagerIdentity", ['ModulemanagerRS', "FieldFactory", "$q", require("./ModulemanagerIdentity")])
    .factory("ModulemanagerGridFct", ["ModulemanagerIdentity", "ModulemanagerRS", "DSCfgFactory", "GridCfgFactory", "ToolbarSrv", "GridNavigator", "$timeout", "ActionFactory", "$rootScope", "$state", "$translate", "GridIdentity", require("./ModulemanagerGridFct")])
;

module.exports = bssmodulemanager;
