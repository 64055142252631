'use strict';



var crypto = require("crypto");
var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var EsponiMixin = require("moduleFold/mixins/EsponiMixin");
var StampaDettaglioMixin = require("moduleFold/mixins/StampaDettaglioMixin");

var defaultRead = require("moduleFold/datasource/scripts/utils.js").defaultRead;
var normalizeFilter = require("moduleFold/datasource/scripts/utils.js").normalizeFilter;

//var MastroDettaglioMixin = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixin");
var MastroDettaglioMixinV3 = require("moduleFold/mastrodettaglio/scripts/MastroDettaglioMixinV3");
var dinerohelpers = require("moduleFold/valuta/dinerohelpers.js");
const moment = require("moment");
var valuefordinero = dinerohelpers.valuefordinero;
var imponibileivato = dinerohelpers.imponibileivato;

/**
 * Controller relativo al template della route /documentistd
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $state
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} DocumentistdFunzioniAggiuntive
 */
function DocumentistdCtrl($http, $q, $timeout, $interval, $state, $stateParams, $window, $filter, DSF, GF, GCF, ToolbarS, idstato, $scope, $rootScope, $compile, ActionFactory, ErrorManager, FormDaGridFactory, FormDaHeaderFactory, FieldFactory, Valida, DocumentistdFunzioniAggiuntive, Sessione) {
    var self = this;
    self.$rootScope = $rootScope;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.gcf = GCF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$interval = $interval;
    self.$q = $q
    self.fa = DocumentistdFunzioniAggiuntive;
    self.af = ActionFactory;
    self.$state = $state;
    self.$scope = $scope;
    self.$compile = $compile;
    self.$sp = $stateParams;
    self.fgf = FormDaGridFactory;
    self.fdhf = FormDaHeaderFactory;
    self.valida = Valida;
    self.em = ErrorManager;
    self.ff = FieldFactory;
    self.$window = $window;
    self.$filter = $filter;
    self.ss = Sessione;

    self.bloccato = true;
    self.insostituzioneriga = false;
    self.intestazionedefault = true;

    moment.locale("it");

    self.nuovoIndirizzo = {};

    self.classirecupero = [                
        "fas fa-hourglass-start",
        "fas fa-hourglass-half",
        "fas fa-hourglass-end",

    ]

    //array dei campi da nascondere
    self.campidanascondereintestazione = ["codicefiscale", "partitaiva", "codiceSdi", "vies", "ragionesociale", "pec"];

    angular.extend(self, EsponiMixin.prototype, ConfrontaMixin.prototype, StampaDettaglioMixin.prototype);

    self.nonpaginato = { page: 1, pageSize: 9999, skip: 0, take: 9999 };
    self.ivacalcolata = {};
    self.currenthash = null;

    self.documentoInModifica = false;

    // self.righe = [];
    // self.righeeliminate = [];
    // self.righedaeliminare = [];

    self.imrighe = [];
    self.erighe = [];
    self.disabilitaCondizioniTrasporto = []
    self.modelrigacorriere = {
        condizioniTrasporto: [],
        _condizioniTrasporto: [],
        colli: [{quantita: 1, weightkg: 2}]
    }

    self.mostraformrigacorriere = false;

    self.raggruppamento = self.$sp.raggrdoc;

    self.contestodoc = self.raggruppamento === 'ORCA' ? {title: 'C', label: "CAR.NET"} : {title: "R", label: "RDC"}

    //attiva/disattiva il tasto per i rimborsi parziali e, in base al raggruppamento sceglie il tipo di rimborso da utilizzare
    self.attivaRimbParz = false;
    self.tipoRimb = null;
    self.inRimb = false;
    if(["ORD"].includes(self.raggruppamento) &&
        (!bssRecursiveGetter(self, "modeltestata.fatturare", true))
    ){
        self.tipoRimb = "rimborso";
        self.attivaRimbParz = true;
    }
    if(["Fatt", "FATT"].includes(self.raggruppamento)){
        self.tipoRimb = "nota credito";
        self.attivaRimbParz = true;
    }
    if(["MACRONCC", "RIMB"].includes(self.raggruppamento)){
        self.inRimb = true;
    }    

    //flag che indica se il documento recuperato in modifica necessita di un salvataggio perchè sul db non presenta righe castelletto
    self.adeguadocrighecastelletto = false;

    self.modeltestata = {
        datadoc: moment().toDate()
    };
    self.modelfooter = {
        scontoaval: 0,
        scontoperc: 0
    }

    self.modelcorrieri = [];
    self.tipidoc = [];

    self.tipiporto = [
        {value: 'A', text: 'Porto Assegnato'},
        {value: 'F', text: 'Porto Franco'}
    ]

    self.sostitutivipercodartforn = {};

    var entitydataintest = "com.bsssrl.bssstdgestent.DatiIntestazione";
    var strentitydataintest = "datiintestazione"
    var entityindirizzo = "com.bsssrl.bssstdgestent.IndirizzoStd";
    var strentityindirizzo = "indirizzi";

    self.campiformNuovaAssAF = [
        new self.ff({
            key: 'fornitore', 
            tipo: 'bsssingleselect', 
            label: 'Associa al Fornitore',
            colonna: {}, 
            modello: {
                validations: {required: true},
                strentity: 'fornitori', 
                codmodulo: 'a', 
                datatextfield: 'ragsoc', 
                datavaluefield: 'id', 
                template: '#= ragsoc #',
            }
        }, 
        false, 
        {
            className: 'col-lg-12', 
        }).opzioni,
        new self.ff({
            "key": "codartforn",
            "tipo": "bssinput", 
            "label": "con Cod. Articolo Fornitore",
            "colonna": {},
            "modello": {
                validations: {required: true},
            }
        },
        false,
        {
            "className": "col-lg-12",        
        }).opzioni,
        new self.ff({
            key: "prezzo", 
            tipo: 'bssnumeric', 
            label: 'al Prezzo',
            placeholder: 'Prezzo',
            colonna: {}, 
            modello: {
                validations: {required: true},
            }
        }, 
        false, 
        {
            className: 'col-lg-12', 
            "ngModelElAttrs": {"num-neg": 'false', "num-fract": "2", "num-fixed": 'true'}

        }).opzioni
    ];

    var mappaTipiRiga = {
        "A": "Articolo",
        "D": "Estemporaneo",
        "T": "Trasporto",
        "C": "Commento",
        "S": "Servizio",
        //"S": "Sconto",
        //"M": "Maggiorazione",
        //"DS": "Servizio libero"
    };

    self.mappaTipiRiga = Object.keys(mappaTipiRiga).reduce(function(obj, k){
        obj[k] = {text: mappaTipiRiga[k], value: k};
        return obj;
    }, {})

    self.mappaTplRighe = {
        "A": "A.html",
        "D": "A.html",
        "DS": "A.html",
        "T": "T.html",
        "C": "C.html",
        "S": "S.html"
    }

    //indica se il campo per l'inserimento dell'anagrafica è visibile o meno
    self.mostraanagrafica = false;
    self.mostradaticliente = false;

    var azioni = [
        new self.af("fas fa-binoculars", null, self.esponi({metodo: "confirmapriricercadoc", parametri: []}), 8, null, "DOCUMENTISTD.VAIARICERCA", null),
        new self.af("fas fa-comment-dots", self.esponi({metodo: "ininserimento", parametri: []}), self.esponi({metodo: "storico", parametri: []}), 8, null, "RICERCADOC.STORICO", "alt+shift+s"),
    ]

    if(self.$sp.iddoc){//sono in modifica, aggiungo stampa
        self.documentoInModifica = true;

        azioni.push(new self.af("fas fa-file-medical", self.esponi({metodo: "verificaReceiver", parametri: []}), self.esponi({metodo: "rigeneraUkDoc", parametri: []}), 2000, null, "RICERCADOC.REGENUK"));

        azioni.push(
            (self.raggruppamento !== 'ORD') ? 
                new self.af("fa fa-print",  null, self.esponi({metodo: "stampa", parametri: ['/documentistd/stampa', null, [Number(self.$sp.iddoc)]]}), 999, null, "GRIDNAV.STAMPADETTAGLIO") :
                new self.af("fa fa-print",  null, self.esponi({metodo: "stamparicevuta", parametri: []}), 1999, null, "RICERCADOC.STAMPARICEVUTA")
                
            , new self.af("fa fa-file-excel", self.esponi({metodo: "disabilitaTastoStoricoXML", parametri: []}), self.esponi({metodo: "mostraStoricoXML", parametri: []}), 9, null, "RICERCADOC.STORICOXML", ""),            
        );
    } else {//non sono in modifica
        
    }

    self.ts.setProviders({"documentistdprovider": {exposed: azioni}}, false);

    self.ricercaInCorso = true;
    self.$q.all([
        self.$http({
            url: utils.urls.wsbase + "/ttipodocumento/" + self.raggruppamento + "/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//0 - tipi documento
        self.$http({
            url: utils.urls.wsbase + "/table/header",
            method: "POST",
            data: $.param({ codapp: null, codmodulo: self.raggruppamento, entity: entitydataintest, strentity: strentitydataintest })
        }),//1 - header intestatario
        self.$http({
            url: utils.urls.wsbase + "/tvaluta/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//2 - lista valute
        self.$http({
            url: utils.urls.wsbase + "/table/header",
            method: "POST",
            data: $.param({ codapp: null, codmodulo: self.raggruppamento, entity: entityindirizzo, strentity: strentityindirizzo })
        }),//3 - header indirizzo
        self.$http({
            url: utils.urls.wsbase + "/tiva/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//4 - lista aliquote
        self.initGridIntestazione(), //5 - grid intestazioni da maxpho        
        self.initGridRighe(), //6 - grid righe documento
        self.$http({
            url: utils.urls.wsbase + "/unitamisura/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//7 - lista u.m
        self.$http({
            url: utils.urls.wsbase + "/tipicontrassegni/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//8 - tipi contrassegni
        self.$http({
            url: utils.urls.wsbase + "/metodipagamento/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }),//9 - metodi pagamento
        self.$http({
            url: utils.urls.wsbase + "/tnazioni/grid",
            method: "POST",
            data: $.param({
                take: 300,
                skip: 0,
                page: 1,
                pageSize: 300,
                /*filter: {
                    logic: 'and',
                    filters: [
                        {operator: 'STARTSWITH', value: 'it', field: 'iso'}
                    ]
                }*/
            })
        }),//10 - nazione italia
        self.initGridComuni(),//11 - lista dei comuni 
        self.cercaTipiDocDaRaggr(), //12 - dal tipodoc in path param (erroneamente chiamato raggruppamento) ottengo il raggruppamento
        self.$http({
            url: utils.urls.wsbase + "/incoterm/grid",
            method: "POST",
            data: $.param({
                take: 300,
                skip: 0,
                page: 1,
                pageSize: 300,
                /*filter: {
                    logic: 'and',
                    filters: [
                        {operator: 'STARTSWITH', value: 'it', field: 'iso'}
                    ]
                }*/
            })
        }),//13 - lista incoterm
        self.$http({
            url: utils.urls.wsbase + "/reasonexport/grid",
            method: "POST",
            data: $.param({
                take: 300,
                skip: 0,
                page: 1,
                pageSize: 300,
                /*filter: {
                    logic: 'and',
                    filters: [
                        {operator: 'STARTSWITH', value: 'it', field: 'iso'}
                    ]
                }*/
            })
        }),//14 - lista motivoesportazione
    ]).then(function (resps) {
        self.tipidoc = resps[0].data.results;

        self.tipodoccorrente = resps[12];
        self.gruppotipodoc = bssRecursiveGetter(self.tipodoccorrente, "raggruppamento.label", null);

        self.nazioni = resps[10].data.results;
        self.italia = self.nazioni.find(function(naz){return naz.iso === 'IT'});        

        self.tipiiva = resps[4].data.results;

        //console.log("tipi iva", self.tipiiva);
        self.headerIntestazione = resps[1].data;
        self.valute = resps[2].data.results.map(function (valuta) {
            valuta.label = valuta.codice + " - " + valuta.descrizione;
            return valuta;
        });
        self.headerIndirizzo = resps[3].data;
        self.cfgFormIntestazione = new self.fdhf("", self.headerIntestazione, null, null, self.formIntestazione, true);

        self.cfgFormIntestazione.campi = self.cfgFormIntestazione.campi.map(function(campo, indice){
            if(campo.key === 'nazione') {

                campo = new self.ff({
                    "key": "nazione",
                    "label": "Nazione",
                    "tipo": "bssselect", 
                    "colonna": {},
                    "modello": {

                    }
                },
                false,
                {
                    "className": "col-lg-4",       
                    defaultValue: self.italia,
                    templateOptions: {
                        options: self.nazioni,
                        valueProp: 'id',
                        labelProp: 'nazione'
                    }
                }).opzioni


            }            

            return campo;
        })

        

        self.oriCampiIntestazione = angular.copy(self.cfgFormIntestazione.campi.filter(function(campo){
            return campo.key !== 'nome'
        }).map(function(campo){
            if(campo.key === 'cognome'){
                campo.templateOptions.label = "Nominativo";
                campo.templateOptions.placeholder = "Nominativo";
            }
            return campo;
        }));

        if(self.tipidoc.length === 1){//un solo tipodoc lo seleziono
            self.setTipoDoc(self.tipidoc[0])
        }
                   

        self.cfgFormIndirizzo = new self.fdhf("", self.headerIndirizzo, null, null, self.formIndirizzo, true);
        self.ums = resps[7].data.results;   
        self.umpezzi = self.ums.find(function(um){
            return um.codice === 'PZ';
        }) 
        self.tipicontrassegni = resps[8].data.results;    
        self.metodi = resps[9].data.results;

        if(self.$sp.iddoc){//se ho come parametro l'id del documento sono in modifica di un documento esistente        
            self.mode = 'modifica';
            self.iddoc = Number(self.$sp.iddoc);
            self.inrecupero =  true;
            
            self.statorecupero = 0;
            
            self.escirecupero = self.$interval(function(){
                self.statorecupero = (self.statorecupero + 1) % self.classirecupero.length
            }, 500)

            self.bloccato = true;
            self.recuperadoc();            
        }
        else {
            self.mode = 'crea';            
            self.fatturareChanges(self.modeltestata.fatturare);       
            self.bloccato = false;
        }

        self.listaincoterm = bssRecursiveGetter(resps[13], "data.results", []);
        self.listarexport = bssRecursiveGetter(resps[14], "data.results", []);

        self.ricercaInCorso = false;
        

    })


}

DocumentistdCtrl.prototype.cercaTipiDocDaRaggr = cercaTipiDocDaRaggr;

function cercaTipiDocDaRaggr(){
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/ttipodocumento/grid",
        data: $.param({
            take: 1,
            skip: 0,
            page: 1,
            pageSize: 1,
            filter: {
                logic: 'AND',
                filters: [
                    {field: 'codice', operator: 'eq', value: self.raggruppamento}
                ]
            }
        })
    }).then(function(resp){
        var risultati = bssRecursiveGetter(resp, "data.results", []);
        var risultato = null;
        if(risultati.length){
            risultato = risultati[0];
        }
        return risultato;
    }, function(err){
        self.em.errorCB(err);
    })
}

/**
 * TOOLBAR STORICO XML
 */
DocumentistdCtrl.prototype.mostraStoricoXML = mostraStoricoXML;
DocumentistdCtrl.prototype.disabilitaTastoStoricoXML = disabilitaTastoStoricoXML;

/**
 * torna true se il documento NON genera xml
 */
function disabilitaTastoStoricoXML() {
    var self = this;

    var esitodoc = !self.nou(self.docrecuperato);
    var esitogenxml = false;

    if(esitodoc){
        esitogenxml = self.docrecuperato.testata.tipodoc.generaxml;                
    }
    

    var esitodisabilita = !esitodoc || !esitogenxml;//disabilito il tasto se non sono in modifica o se il doc non genera xml
    
    return esitodisabilita;
}

function mostraStoricoXML(){
    var self = this;
    self.documentoPerXML = self.docrecuperato;
    self.storicoxmlwin.open().center();
}

/**
 * FINE TOOLBAR STORICO XML
 */

/**
 * DocumentistdCtrl PROTOTYPE
 */
DocumentistdCtrl.prototype.fatturareChanges = fatturareChanges;
DocumentistdCtrl.prototype.storico = storico;
DocumentistdCtrl.prototype.ininserimento = ininserimento;
DocumentistdCtrl.prototype.vaiallasezione = vaiallasezione;
DocumentistdCtrl.prototype.apriricercadoc = apriricercadoc;
DocumentistdCtrl.prototype.confirmapriricercadoc = confirmapriricercadoc;
DocumentistdCtrl.prototype.ricarica = ricarica;
DocumentistdCtrl.prototype.focusField = focusField;
DocumentistdCtrl.prototype.oraist = oraist;
DocumentistdCtrl.prototype.salvaOrdine = salvaOrdine;
DocumentistdCtrl.prototype.generaOrdine = generaOrdine;
DocumentistdCtrl.prototype.verificaReceiver = verificaReceiver;
DocumentistdCtrl.prototype.rigeneraUkDoc = rigeneraUkDoc;

function fatturareChanges(fatturare){
    var self = this;

    var generaxml = !self.disabilitaTastoStoricoXML();
    
    var esito = fatturare || generaxml; 

    //se da fatturare o generaxml non nascondo nessun campo
    self.cfgFormIntestazione.campi = esito ? 
        angular.copy(self.oriCampiIntestazione).filter(function(campo){
            return !["cognome", "indirizzo2"].includes(campo.key)
        }) :
        angular.copy(self.oriCampiIntestazione.filter(function(campo){
            return !self.campidanascondereintestazione.includes(campo.key)
        }));

    //se è ORD non è da fatturare allora mostro il tasto per il rimborso
    if(["ORD"].includes(self.raggruppamento) &&
        (!bssRecursiveGetter(self, "modeltestata.fatturare", true))
    ){
        self.tipoRimb = "rimborso";
        self.attivaRimbParz = true;
    }
}

function storico(){
    var self = this;
    self.docselticket = angular.copy(self.modeltestata);
    self.docselticket.tipodoc.label = self.docselticket.tipodoc.codice;
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/documentistd/" + self.docselticket.id + "/eventi",
    }).then(function(resp){
        self.ticketdocumento = resp.data.map(function(t){
            t.data = moment.utc(t.dataora).format("LLLL")
            return t;
        });
        self.storicoticketwin.open().center();
    })
    
}

function ininserimento(){
    var self = this;
    return [null, undefined].includes(self.$sp.iddoc);
}

function vaiallasezione(sez){
    var self = this;
    $('html,body').animate({scrollTop: $("a[name='"+sez+"']").offset().top},'slow');
}

function apriricercadoc(){
    var self = this;
    //console.log("==========> previous state: ", self.$rootScope.previousState);
    if(!self.nou(self.$rootScope.previousState) && self.$rootScope.previousState.state.name === 'ricercadoc'){
        self.$state.go(self.$rootScope.previousState.state.name, self.$rootScope.previousState.params)
    } else {
        self.$state.go("ricercadoc", {raggrdoc: self.raggruppamento}, {reload: true});
    }        
}

function confirmapriricercadoc(){
    var self = this;
    self.confirmapriricercadocwin.open().center();
}

function ricarica(){
    self.ordine = {}
    window.location.reload()
}

function verificaReceiver(){
    var self = this;

    var idReceiver = bssRecursiveGetter(self, "modeltestata.anagraficareceiver", null);
    var esito = (idReceiver === null);

    return esito;
}

function rigeneraUkDoc(){
    var self = this;
    var iddoc = self.iddoc;
    self.$http({
        url: utils.urls.wsbase + "/documentirdc/rigenerainvoice/" +iddoc,
        method: "POST"
    }).then(function(resp){
        self.em.showT("RICERCADOC.REGENUKSUCCESS", "success");    
    }, function(err){
        self.em.errorCB(err);
    });
}


/**
 * @typedef {Object} TestataDoc
 * @property {Object | null} intestazione - da sistemare, attualmente anche se non necessario è uguale a {comune: {}, vies: false}
 * @property {Object} tipodoc
 * @property {number} valuta
 * @property {number} listino
 * @property {Object} pagamento
 * @property {string} sezionale
 * @property {number} protocollo
 * @property {number} totpagamento
 * @property {Object} indirizzo
 * @property {Object} anagrafica
 * @property {string} numerodoc
 * @property {Date} datadoc
 * @property {Object} agente
 * @property {string} rifdoc
 * 
 * 
 * @typedef {Object} RigaDoc
 * @property {Object} articolo
 * @property {Object} um
 * @property {Object} iva
 * @property {number} quantita
 * @property {number} sconto1
 * @property {number} sconto2
 * @property {number} ivatounitario
 * @property {number} impostaunitario
 * @property {number} imponibileUnitLordo - imponibile singolo prodotto non scontato
 * @property {number} imponibiletotale
 * @property {number} id
 * @property {Object} tipo
 * 
 * 
 * @typedef {Object} FooterDoc
 * @property {number} scontoaval
 * @property {number} scontoperc
 * @property {string} note1
 * @property {string} note2
 * @property {Castelletto} castelletto
 * 
 * 
 * @typedef {Object} TrasportoDoc
 * 
 * 
 * @typedef {Object} Ordine
 * @property {TestataDoc} testata
 * @property {RigaDoc[]} imrighe
 * @property {RigaDoc[]} erighe
 * @property {FooterDoc} footer
 * @property {TrasportoDoc[]} trasporti
 */




function salvaOrdine(raggruppamento, rimani){
    var self = this;
    

    var isonazdef = bssRecursiveGetter(self, "modeltestata.tipodoc.nazioneDefault.iso", null);
    if(isonazdef === 'GB'){
        var forminco = bssRecursiveGetter(self, "testata.incoterm", {"$invalid": true});
        var formreex = bssRecursiveGetter(self, "testata.motivoesportazione", {"$invalid": true});
        if(forminco.$invalid || formreex.$invalid){          
            self.testata.$setSubmitted();
            self.em.showT("DOCUMENTISTD.INVALIDINCO", "error");
            return;
        }
    }

    self.generaOrdine();

    var raggruppamentosalva = self.raggruppamento;

    var documento = angular.copy(self.ordine);

    

    

    if(self.iddoc){//se sono in modifica aggiungo l'id del documento
        documento = angular.extend({}, documento, {id: self.iddoc})
    }
    var cfg = {
        url: utils.urls.wsbase + "/documentistd/salva",
        method: "POST",
        data: $.param({documento: JSON.stringify(documento)})
    };

    var tipodocprevedepagamenti = self.modeltestata.tipodoc.haPagamenti;
    var esitopagamenticonformi = !tipodocprevedepagamenti || (tipodocprevedepagamenti  && 
        self.modeltestata.totpagamento === self.modelfooter.castelletto.totali._totale);

    if(!self.nou(raggruppamento)){
        raggruppamentosalva = raggruppamento;
    }

    if(esitopagamenticonformi){
        self.salvataggioincorso = true;

        self.$http(cfg).then(function(resp){
            

            self.ss.aggiorna("flash", {contesto: self.iddoc ? 'modifica' : 'inserimento', documento: resp.data})
            if(rimani !== true){
                if(["RIMB", "MACRONCC"].includes(raggruppamentosalva)){

                    //recupero il raggruppamento dal documento appena generato
                    raggruppamentosalva = bssRecursiveGetter(resp, "data.testata.tipodoc.raggruppamento.label", raggruppamentosalva);
                    //var iddoc = bssRecursiveGetter(resp, "data.id", null);
    
                    //self.$state.go("documentistd", {raggrdoc: raggruppamentosalva, iddoc: iddoc}, {reload: true})
                    self.$state.go("ricercadoc", {raggrdoc: raggruppamentosalva}, {reload: true})
                } else {
                    self.$state.go("ricercadoc", {raggrdoc: raggruppamentosalva}, {reload: true})
                }

                if(["ORD"].includes(raggruppamentosalva)){

                    self.$state.go("ricercadoc", {raggrdoc: raggruppamentosalva, codprocedura: "OrdFor"}, {reload: true})
                }
            }  
            else {
                if(self.adeguadocrighecastelletto){
                    self.adeguadocrighecastelletto = false;
                    self.em.showT("DOCUMENTISTD.RIGHECASTELLETTOSALVATE", "success");
                }
            }          
            
            self.salvataggioincorso = false;
        }, function(err){
            self.salvataggioincorso = false;
            self.em.errorCB(err);
        })
    } else {
        self.salvataggioincorso = false;
        self.em.showT("DOCUMENTISTD.PAGAMENTINONCONFORMI")
    }


    
}

/**
 * @description
 * Si occupa di generare l'oggetto da inviare al server per salvare l'intero ordine
 * 
 * @return {Ordine}
 */
function generaOrdine(){
    var self = this;

    function righemapper(riga){
        var newriga = JSON.parse(JSON.stringify(riga));
        if(riga.codartforn !== null && typeof riga.codartforn !== 'undefined'){
            newriga.codartforn = {
                codartforn: typeof riga.codartforn !== 'object' ? riga.codartforn : riga.codartforn.codartforn, // prima era solo riga.codartforn
                id: riga.idcodartforn,
                fornitore: riga.fornitore,
            }
            if(riga.articolo){
                newriga.codartforn.articolo = riga.articolo;
            }
        }

        return newriga;
    }

    //al server invio i numeri con la massima precisione
    var footer = angular.copy(self.modelfooter);
    var campiprecisione = ["imponibile", "iva", "sconto", "imponibilenetto", "totale"]
    footer.castelletto.righe = footer.castelletto.righe.map(function(riga){
        campiprecisione.forEach(function(campo){
            //console.log("formatto il campo", campo, "della riga castelletto", riga);
            var decimalpart = Array.from({length: riga[campo].getPrecision()}).reduce(function(stringa, val){
                return stringa + "0";
            }, "");
            riga["_"+campo] = riga[campo].toFormat("0."+decimalpart);
        })
        return riga;
    });

    // documento.footer.castelletto.totali = document.footer.castelletto.totali.map(function(riga){
    //     return riga;
    // });


    var ordine = {
        testata: self.modeltestata,
        imrighe: self.imrighe.map(righemapper),
        erighe: self.erighe.map(righemapper),
        footer: footer,
        trasporti: self.modelcorrieri,
        intestazionedefault: self.intestazionedefault
    }

    console.log("[CREAINDIRIZZO] 5. AGGIORNATO ORDINE DA SALVARE", ordine);

    self.ordine = ordine;
    return self.ordine;
}

function focusField($event) {
    var self = this;
    var el = $event.currentTarget;
    var oritype = el.type;
    el.type = 'text';
    el.setSelectionRange(0, el.value.length);
    el.type = oritype;
    //console.log("focus su campo", $event);
}

function oraist() {
    return moment().toDate();
}
DocumentistdCtrl.prototype.focusFirstInput = focusFirstInput;
DocumentistdCtrl.prototype.recuperadoc = recuperadoc;
DocumentistdCtrl.prototype.recuperaTestata = recuperaTestata;
DocumentistdCtrl.prototype.recuperaPagamenti = recuperaPagamenti;
DocumentistdCtrl.prototype.recuperaRighe = recuperaRighe;
DocumentistdCtrl.prototype.adeguaPagamentiRimb = adeguaPagamentiRimb;
DocumentistdCtrl.prototype.aggiornaanareceiver = aggiornaanareceiver;

function aggiornaanareceiver(){
    var self = this;


    if(!self.nou(self.idanarec)){
        console.log("aggiorno anagrafica receiver", self.idanarec, self.dtypeanarec, self.listareceivers)
        
        if(self.dtypeanarec === '.ClienteStd'){
            var anareceiver = self.listareceivers.find(function(rec){
                return rec.copiadati;
            })

            console.log("aggiorno id anagrafica receiver", anareceiver)

            self.idanarec = bssRecursiveGetter(anareceiver, "id", null);
        } 
        
        self.modeltestata.anagraficareceiver = self.idanarec;
        
    } 
}


function focusFirstInput(){
    var self = this;
    self.righewin.element.find("input:first").focus()
}

 /**
 * @description
 * se la rotta di questo modulo contiene iddoc in query string allora mi trovo in modifica
 * Questo metodo si occupa di recuperare i dati del documento da modificare
 */
function recuperadoc(){
    var self = this;
    
    return self.$http({
        url: utils.urls.wsbase + "/documentistd/" + self.iddoc,
        method: "GET",
    }).then(function(resp){
        var doc = resp.data;
        self.docrecuperato = angular.copy(doc);
        self.recuperaTestata(doc);
        self.recuperaPagamenti(doc);
        self.recuperaRighe();
        self.modelfooter  = angular.extend({}, doc.footer);
        self.modelcorrieri = doc.trasporti;
        if(Array.isArray(self.modelcorrieri) && self.modelcorrieri.length){
            self.packages = self.modelcorrieri[0].packages;
            self.modificaModelRigaCorriere(self.modelcorrieri[0], 0);
        }
        self.inrecupero = false;     
        self.$interval.cancel(self.escirecupero)   
       

        return doc;
    })
}

function recuperaTestata(doc){
    var self = this;
    var testata = doc.testata;
    self.bloccato = false;
    if(!self.nou(doc.bloccato)){
        self.bloccato = doc.bloccato;
    }
 
    
    //1. tipo doc
    self.setTipoDoc(testata.tipodoc, true, true).then(function(){
        //1.1 aggiorno manualmente i campi che ottengono valori di default alla selezione del tipodoc (todo controllare che non ci siano valori di default assegnati in maniera asincrona questo potrebbe rendere inutile aggiornare manualmente i valori)
        self.modeltestata.id = self.iddoc;
        self.modeltestata.valuta = testata.valuta;
        self.modeltestata.protocollo = Number(testata.protocollo);
        self.modeltestata.numerodoc = testata.numerodoc;
        self.modeltestata.fatturare = testata.fatturare;  
        
        self.idanarec = bssRecursiveGetter(testata, "anagraficareceiver.id", null);
        self.dtypeanarec = bssRecursiveGetter(testata, "anagraficareceiver.dtype", null);

        self.fatturareChanges(self.modeltestata.fatturare);       
        //1.2 aggiorno tutti i campi che vengono resi visibili alla selezione del tipodoc 
        self.modeltestata.rifdoc = testata.rifdoc;
        if(!self.nou(testata.datadoc)){
            self.modeltestata.datadoc = moment(testata.datadoc, "x").toDate();
        } 

        //settipodoc richiamato da qui non aggiorna la lista dei listini per il tipodoc quindi li aggiorno manualmente
        self.aggiornaListini().then(function(listini){
            self.modeltestata.listino = Number(testata.listino);
        });    
        //2. anagrafica
        self.setAnagrafica(testata.anagrafica, true, true);
        //2.1 aggiorno manulmente i campi che ottengono valori di default alla selezione dell'anagrafica        
        self.modeltestata.intestazione = testata.intestazione;
        self.setIndirizzo(testata.indirizzo)
        //3. agente
        self.setAgente(testata.agente);
        //4. deposito
        if(testata.deposito !== null && typeof testata.deposito !== 'undefined') {
            self.setDeposito(testata.deposito);
        }

        self.adeguaTestataGB(testata);
    })

    //se il documento è nota credito e ha xml valido devo bloccare l'ordine
    if( self.raggruppamento === 'MACRONCC'){
        self.$http({
            url: utils.urls.wsbase + "/trasmissionife/" + self.iddoc,
            method: "POST",
        }).then(function(resp){

            var haXML = false;
        
            if(Array.isArray(resp.data)){
                if(resp.data.length){
                    self.storicoXML = resp.data;
                    haXML = resp.data.reduce(function(esito, file){
                        esito = esito || (!file.errore);
                        return esito;
                    }, false);                    
                }                 
            }

            console.log("la nota credito ha xml validi?", haXML)
            self.bloccato = haXML;
            self.inRimb = !haXML;
            
        }, function(err){
            self.em.errorCB(err);
        })
    }
        
}

function recuperaPagamenti(doc){
    var self = this;
    self.setPagamento(doc.testata.pagamento);
    self.modeltestata.totpagamento = doc.testata.totpagamento;

    self.disabilitaMetodo = {};

    self.modeltestata.scadenzepagamenti = angular.copy(doc.testata.scadenzepagamenti).map(function(scadenza, indicescadenza){
        self.disabilitaMetodo[indicescadenza] = true;
        scadenza.datascadenza = moment(scadenza.datascadenza, "YYYY-MM-DD").toDate();
        return angular.extend({}, scadenza, {_metodo: bssRecursiveGetter(scadenza, "metodo.label", null)})
    });
    if (self.modeltestata.scadenzepagamenti.length === 0) {
        if(!["MACRONCC", "RIMB"].includes(self.raggruppamento)){
            self.nuovaRigaPagamento();
        }          
    }    
}

/**
 * Quando apro la prima volta in modifica un RIMB o NOTACREDITO parziali i pagamenti sono a zero, invece dovrebbero recuperare i totali
 * @param {Castelletto} castelletto 
 */
function adeguaPagamentiRimb(castelletto){
    var self = this;
    if(["MACRONCC", "RIMB"].includes(self.raggruppamento)){
        if(self.modeltestata.totpagamento === 0){
            self.em.showT("DOCUMENTISTD.SCADENZEREMINDER", "warning");
            self.modeltestata.totpagamento = castelletto.totali._totale;
        }
    }
}

function recuperaRighe(){
    var self = this;
    self.initGridRighe();

}

/**
 * ============= Fine Recupero Documento per modifica =============
 */

/**
* Tipo doc
*/

DocumentistdCtrl.prototype.svuotaTipoDoc = svuotaTipoDoc;
DocumentistdCtrl.prototype.blurTipoDoc = blurTipoDoc;
DocumentistdCtrl.prototype.infoTipoDoc = infoTipoDoc;
DocumentistdCtrl.prototype.setTipoDoc = setTipoDoc;
DocumentistdCtrl.prototype.resetTipoDoc = resetTipoDoc;
DocumentistdCtrl.prototype.aggiornaInfoTipoDoc = aggiornaInfoTipoDoc;
DocumentistdCtrl.prototype.aggiornaListini = aggiornaListini;
DocumentistdCtrl.prototype.adeguaTestataGB = adeguaTestataGB;

function svuotaTipoDoc() {
    var self = this;
    //self.$timeout(function(){
    console.log("svuoto il tipo documento")
    self.resetTipoDoc()
    self.testata.tipodoc.$setViewValue("");
    //}, 500)

}

/**
 * @description 
 *  Quando esco dal campo tipodoc, se per il termine di ricerca esiste un solo valore che matcha aggiorno il model 
 *  se ci sono più valori o nessun valore che matchano apro la finestra con i valori ammessi
 *  se non ci sono valori possibili il filtro viene svuotato e viene mostrato un warning, quindi vengono mostrate tutte le opzioni (non filtrate)
 * @param {*} $event 
 */
function blurTipoDoc($event) {
    var self = this;
    console.log("blurtipodoc");

    var term = self.testata.tipodoc.$viewValue;

    if (typeof term === 'undefined') {
        return;
    }

    term = term.toUpperCase();
    var opzioni = self.$filter('filter')(self.tipidoc, term)
    //  self.tipidoc.filter(function (tipodoc) {
    //     //console.log(tipodoc, term)
    //     return tipodoc.nome.toUpperCase().indexOf(term) !== -1 ||
    //         tipodoc.codice.toUpperCase().indexOf(term) !== -1
    // })

    if (opzioni.length === 1) {
        self.setTipoDoc(opzioni[0])
    } else {
        $event.stopImmediatePropagation();
        if (opzioni.length === 0) {
            self.em.showT("DOCUMENTISTD.NOTIPODOCFOUND", "warning");
            self.resetTipoDoc()
        }
        self.tipodocwin.open().center();
    }
}

/**
 * @description
 * Ritorna i dati aggiuntivi del tipodoc
 * es. numerodoc, protocollodoc, sezionaledoc, valutadoc
 * @returns {Promise<infoTipoDoc>}
 */
function infoTipoDoc() {
    var self = this;
    return self.$http({
        url: utils.urls.wsbase + "/ttipodocumento/" + self.modeltestata.tipodoc.codice + "/info",
        method: "GET"
    })
}


/**
 * aggiorna il termine di ricerca per tipodoc e il model
 * aggiorna mostraanagrafica
 * 
 * Quando si va in modifica il valore del listino e le info aggiuntive della testata vanno reperite dal documento stesso
 * ignorando quindi i valori di default legati al tipodoc e al cliente
 * 
 * @param {*} tdoc 
 * @param {*} disabilitaAggiornaInfo - in recupero per modifica va messo a true
 * @param {*} disabilitaAggiornaListino - in recupero per modifica va messo a true
 */
function setTipoDoc(tdoc, disabilitaAggiornaInfo, disabilitaAggiornaListino) {
    var self = this;
    self.modeltestata.tipodoc = tdoc;
    self.modeltestata._tipodoc = tdoc.codice;// + " - " + tdoc.nome;
    var valutaTipoDoc = tdoc.valutaDefault;
    self.modeltestata.valuta = valutaTipoDoc.id;
    self.tipodocwin.close();
    self.disabilitaTipoDoc = true;


    if(self.nou(self.modeltestata.datadoc)){
        self.modeltestata.datadoc = moment().toDate();
    }

    self.aggiornaMostraAnagrafica();
    self.aggiornaMostraDataDoc();
    self.aggiornaMostraDatiCliente();
    self.aggiornaMostraAgente();
    self.aggiornaMostraDeposito();
    var reqs = []
    if(disabilitaAggiornaInfo === null || typeof disabilitaAggiornaInfo === 'undefined' || !disabilitaAggiornaInfo){
        reqs.push(self.aggiornaInfoTipoDoc());
    }
    if(disabilitaAggiornaListino === null || typeof disabilitaAggiornaListino === 'undefined' || !disabilitaAggiornaListino){
        reqs.push(self.aggiornaListino());
    }
    self.aggiornaMostraIndirizzo();
    self.resetPagamento();

    self.$timeout(function(){
        angular.element("input[ng-model='moduloCtrl.modeltestata._anagrafica']").focus()
    })

    

    return self.$q.all(reqs)
}

function adeguaTestataGB(testata){
    var self = this;

    var tdoc = testata.tipodoc;

    if(!self.nou(bssRecursiveGetter(tdoc, "nazioneDefault.iso", null))){
        if(tdoc.nazioneDefault.iso === 'GB'){
            self.modeltestata.motivoesportazione = testata.motivoesportazione.id;
            self.modeltestata.incoterm = testata.incoterm.id;
        }
    }
}

function resetTipoDoc() {
    var self = this;

    self.modeltestata = { tipodoc: null };
    self.disabilitaTipoDoc = false;
    self.aggiornaMostraAnagrafica();
    self.resetAnagrafica();
    self.resetPagamento();
    self.aggiornaMostraAgente();
    self.aggiornaMostraDataDoc();
    self.aggiornaMostraDatiCliente();
    self.aggiornaMostraDeposito();
    self.aggiornaMostraIndirizzo();

}

/**
 * @description
 * Se tipodoc è definito scarico le info aggiuntive e aggiorno modeltestata di conseguenza
 */
function aggiornaInfoTipoDoc() {
    var self = this;

    if (self.modeltestata.tipodoc) {
        self.infoTipoDoc().then(function (resp) {
            //self.modeltestata = angular.extend({}, self.modeltestata, resp.data);
            
            self.modeltestata.numerodoc = resp.data.numerodoc;
            self.modeltestata.protocollo = resp.data.protocollo;

            if(!self.nou(resp.data.intestazione)){
                Object.keys(resp.data.intestazione).forEach(function(k){
                    self.modeltestata.intestazione[k] = resp.data.intestazione[k];
                })
            }
            
            if(!self.nou(resp.data.modalitaPagamento)){
                self.setPagamento(resp.data.modalitaPagamento)
            }
            
            self.info = angular.copy(resp.data.defaults);
        })
    }

}

function aggiornaListini(){
    var self = this;
    var tipodoc = self.modeltestata.tipodoc;

    if (typeof tipodoc !== 'undefined' && tipodoc !== null) {
        return self.$http({
            url: utils.urls.wsbase + "/tlistinistd/" + tipodoc.codice + "/grid",
            method: "POST",
            data: $.param(self.nonpaginato)
        }).then(function (resp) {
            self.listini = resp.data.results;
            return self.listini;
        }, function(err){
            self.em.errorCB(err)
        })
    }
}

/**
 * ===================================================================== Fine Tipo Doc =======================================================================================================
 */


/**
 * Data Doc
 */

DocumentistdCtrl.prototype.aggiornaMostraDataDoc = aggiornaMostraDataDoc;


function aggiornaMostraDataDoc() {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {
        self.mostradatadoc = false;
    } else {
        self.mostradatadoc = true;
    }
}

/**
 * ===================================================================== Fine Data Doc =======================================================================================================
 */


/**
 * Dati Cliente
*/
DocumentistdCtrl.prototype.aggiornaMostraDatiCliente = aggiornaMostraDatiCliente;
DocumentistdCtrl.prototype.initGridIntestazione = initGridIntestazione;
DocumentistdCtrl.prototype.setIntestazioneMaxpho = setIntestazioneMaxpho;
DocumentistdCtrl.prototype.salvaCfgGridMaxpho = salvaCfgGridMaxpho;
DocumentistdCtrl.prototype.salvaDatiIntestazione = salvaDatiIntestazione; 
DocumentistdCtrl.prototype.soddisfaRequisitiTrasporti = soddisfaRequisitiTrasporti

function aggiornaMostraDatiCliente() {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {
        self.mostradaticliente = false;
        return;
    }

    var fatturabilita = self.modeltestata.tipodoc.fatturabilita.value;
    var assocana = self.modeltestata.tipodoc.associaAnagrafica.value;
    var ffaf = (fatturabilita === 'FACOLTATIVO' && assocana === 'FACOLTATIVO');
    var ffam = (fatturabilita === 'FACOLTATIVO' && assocana === 'MAI');
    var fmaf = (fatturabilita === 'MAI' && assocana === 'FACOLTATIVO');
    var fattsempre = fatturabilita === 'SEMPRE'; 
    //console.log("aggiornamostradaticliente ffaf ffam fmaf", ffaf, ffam, fmaf);

    if ((fattsempre) || (ffaf) || (ffam) || (fmaf)) {
        self.mostradaticliente = true;
    } else {
        self.mostradaticliente = false;
    }

}

/**
 * @description
 * Inizializzo la griglia dell'intestazione da Maxpho
 */
function initGridIntestazione() {
    var self = this;

    var strentity = "datiintestazione";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setIntestazioneMaxpho(dataItem);
        self.gridmaxphowin.close();
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, {filter: {field: 'insUser', operator: 'eq', value: 'MAXPHO'}}).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {            
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridMaxpho !== 'undefined') {
                    //console.log("databound", self.gridMaxpho);
                    self.gridMaxpho.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridMaxphoOpts = angular.extend({}, gridcfg);
            self.gridMaxphoRebind = moment().format();
            //return ds.read();
            return self.$q.resolve(self.gridMaxphoOpts)
        })
    });
}


/**
 * 

 * 
 * @param {*} intestazione 
 */
function setIntestazioneMaxpho(intestazione){
    var self = this;
    
    self.modeltestata.intestazione = angular.extend({}, intestazione);    

}

function salvaCfgGridMaxpho(){
    var self = this;
    var parametri = {
        nome: self.gridMaxpho.options.nomeCfgGriglia,
        configurazione: self.gridMaxpho.columns,
    };
    
    parametri.configurazione = _.map(parametri.configurazione, function(colonna){
        if(!isNaN(colonna.width)){
            colonna.width = colonna.width+"px";
        }
        return colonna;
    });
    
    console.log("configurazione da salvare per ", parametri.nome, parametri.configurazione);
    parametri.configurazione = JSON.stringify(parametri.configurazione);
    
    return self.$http({
        url: utils.urls.wsbase + "/cfgutenti",
        method: "POST",
        data: $.param(parametri),
    }).then(function(resp){
        self.em.showT("GRIDNAV.CFGSALVATA", "success");            
        return resp.data;
    });
        
}

function soddisfaRequisitiTrasporti(){
    var self = this;

    self.inrequisititrasporti = true;

    self.initGridIndirizzo().then(function(){
        try {
            self.salvaDatiIntestazione().then(function(intestazionecorrente){//anagraficamanuale evita di creare dati intestazione se non ci sono variazioni?
                console.log("dati intestazione appena creati", intestazionecorrente);            
                try {
                    self.creaIndirizzo().then(function(){
                        self.$timeout(function(){
                            self.inrequisititrasporti = false;
                            self.generaTrasporti();
                        })
                    })
                } catch (err) {//errori nel salvataggio della destinazione
                    self.inrequisititrasporti = false;
                    switch (err) {
                        case "INDIRIZZO_NON_VALIDO":
                            //@todo scroll al form destinazione
                            self.em.show("Dati destinazione non validi", "error");
                            break;            
                        default:
                            self.em.show("Errore nel salvataggio della destinazione, vedi console!", "error");
                            console.error("|~~~~~~~~~~~~~~~~> Errore nel salvataggio della destinazione", err)
                            break;
                    }                    
                }            
            })
        } catch(err){//errori nella creazione dei dati intestazione
            self.inrequisititrasporti = false;

            switch (err) {
                case "CREA_ANAGRAFICA_DA_DATI_INTESTAZIONE":
                    //@todo scroll al form intestazione
                    self.em.show("Non è stato possibile creare l'anagrafica dai dati di intestazione", "error");
                    break;
                case "DATIINTESTAZIONE_NON_VALIDI":
                    //@todo scroll al form intestazione
                    self.em.show("Dati intestazione non validi", "error");
                    break;            
                default:
                    self.em.show("Errore nel salvataggio dell'intestazione, vedi console!", "error");
                    console.error("|~~~~~~~~~~~~~~~~> Errore nel salvataggio dell'intestazione", err)
                    break;
            }
        }
    },function(err){
        self.em.show("Errore inizializzazione indirizzi!", "error");
        console.error("|~~~~~~~~~~~~~~~~> Errore nel salvataggio dell'intestazione", err);
    })
    
    
    
    
}

function salvaDatiIntestazione(){
    var self = this;

    var intestazionecorrente =  self.$q.when(self.modeltestata.intestazione);
    
    if (self.valida.validaForm(self.formIntestazione)) {
        var data = angular.extend({}, self.modeltestata.intestazione);
        var dafatturare = self.modeltestata.fatturare;
        var anagrafica = angular.extend({}, self.modeltestata.anagrafica);
        var filters = dafatturare ? [
            {field: "partitaiva", operator: "eq", value: data.partitaiva},
            {field: "codicefiscale", operator: "eq", value: data.codicefiscale},

        ] : [
            {field: "cognome", operator: "eq", value: data.cognome}
        ];

        //if(self.nou(anagrafica.id)){
        var modello = self.modeltestata.intestazione;

        return self.$http({
            url: utils.urls.wsbase + "/documentistd/anagraficamanuale",
            method: "POST",
            data: $.param({intestatario: JSON.stringify(modello)})
        }).then(function(resp){
            var datiintestazione = resp.data;
            self.modeltestata.intestazione = angular.copy(datiintestazione);
            self.setAnagrafica(angular.copy(datiintestazione.anagraficaPGFStd), true, true);
            console.log("datiintestazione", datiintestazione);
            return datiintestazione
        }, function(err){
            self.em.errorCB(err);
            throw("CREA_ANAGRAFICA_DA_DATI_INTESTAZIONE")
        })

        /*} else {
            return intestazionecorrente
        }*/
       
    }

    throw("DATIINTESTAZIONE_NON_VALIDI")
}

/**
 * ===================================================================== Fine Dati Cliente =======================================================================================================
 */

/**
 * Listino
 */
DocumentistdCtrl.prototype.aggiornaListino = aggiornaListino;

/**
 * @description
 * Viene richiamato quando aggiorno il tipodoc o anagrafica.
 * Si occupa di valorizzare il listino in base al valore di anagrafica, qualora l'anagrafica non fosse collegata al listino
 * cerca di valorizzare il listino in base al valore di tipodoc, qualora anche tipodoc non avesse un listino collegato non farebbe nulla
 * 
 */
function aggiornaListino() {
    var self = this;
    var ana = self.modeltestata.anagrafica;
    var tipodoc = self.modeltestata.tipodoc;


    if (typeof ana !== 'undefined' && ana !== null && ana.listino) {
        self.modeltestata.listino = ana.listino.id;
    } else {
        if (typeof tipodoc !== 'undefined' && tipodoc !== null && tipodoc.listino) {
            self.modeltestata.listino = tipodoc.listino.id;
        }
    }

    self.aggiornaListini();


    // if (typeof tipodoc !== 'undefined' && tipodoc !== null) {

    //     self.$http({
    //         url: utils.urls.wsbase + "/tlistinistd/" + tipodoc.codice + "/grid",
    //         method: "POST",
    //         data: $.param(self.nonpaginato)
    //     }).then(function (resp) {
    //         self.listini = resp.data.results;
    //     })
    // }

}

/**
 * ===================================================================== Fine listino =======================================================================================================
 */


/**
 * Anagrafica
 */

DocumentistdCtrl.prototype.creaClifor = creaClifor;
DocumentistdCtrl.prototype.blurAnagrafica = blurAnagrafica;
DocumentistdCtrl.prototype.setAnagrafica = setAnagrafica;
DocumentistdCtrl.prototype.resetAnagrafica = resetAnagrafica;
DocumentistdCtrl.prototype.aggiornaMostraAnagrafica = aggiornaMostraAnagrafica;
DocumentistdCtrl.prototype.initGridAnagrafica = initGridAnagrafica;
DocumentistdCtrl.prototype.apriPopupAnagrafica = apriPopupAnagrafica;
DocumentistdCtrl.prototype.getAnagrafiche = getAnagrafiche;
DocumentistdCtrl.prototype.filtraIntestatario = filtraIntestatario;
DocumentistdCtrl.prototype.disabilitaFiltraIntestatario = disabilitaFiltraIntestatario;

/**
 * 
 * @param {Object} tipoClifor - tipo anagrafica cliente o fornitore
 * @param {Boolean} usaInDocumento - se impostato a true il nuovo clifor creato viene impostato nella testata del documento altrimenti si limita ad aggiornare la griglia anagrafica
 */
function creaClifor(tipoClifor, usaInDocumento){
    var self = this;

    if (self.valida.validaForm(self.formNuovoClifor)) {
        var data = angular.extend({}, self.nuovoClifor, );

        /*
        return self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/anagraficapgfstd/crea",
            data: $.param({ elemento: JSON.stringify(data) })
        }).then(function (resp) {
            self.nuovoClifor = {};
            self.nuovocliforwin.close();
            self.gridAnagrafica.dataSource.read();
            if(usaInDocumento){
                self.setAnagrafica(resp.data);
                self.anagraficawin.close();
            }
        })
        */
    }
    
}

/**
 * 
 * @param {*} $event 
 * @param {("codicefiscale"|"partitaiva"|"codiceGestExt")} ricerca 
 */
function blurAnagrafica($event, ricerca) {
    var self = this;


    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {//blur da anagrafica per reset tipodoc
        return;
    }

    var filtri = null;

    if(!self.nou(ricerca)){
        filtri = {
            logic: "AND",
            filters: [
                {field: ricerca, operator: "startsWith", value: self.testata["ana"+ricerca].$viewValue}
            ]
        }
    }


    self.getAnagrafiche(filtri).then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setAnagrafica(opzioni[0]);
        } else {
            if(!self.nou($event)){
                $event.stopImmediatePropagation();
            }
            
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOANAGRAFICAFOUND", "warning");
                var anagraficaDaInput = angular.copy(self.modeltestata._anagrafica);
                self.resetAnagrafica()
                self.modeltestata.intestazione = {};
                var generaxml = !self.disabilitaTastoStoricoXML();
                var esito = self.modeltestata.fatturare || generaxml; 
                if(esito){//popolo e focus su ragsoc

                    if(!self.nou(ricerca)){
                        //self.modeltestata.intestazione.ragionesociale = anagraficaDaInput;
                        self.modeltestata.intestazione[ricerca] = self.testata["ana"+ricerca].$viewValue;

                        self.$timeout(function(){
                            angular.element("input[name*='"+ricerca+"']").focus();
                        })
                    }
                    


                } else{//popolo e focus su cognome
                    self.modeltestata.intestazione.cognome = anagraficaDaInput
                    self.$timeout(function(){
                        angular.element("input[name*='cognome']").focus();
                    })
                    
                }

            } else {
                if (self.modeltestata.tipodoc !== null && typeof self.modeltestata.tipodoc !== 'undefined') {//blur da anagrafica per reset tipodoc
                    self.apriPopupAnagrafica(ricerca)
                }
            }
            
        }
    })
    //se il datasource contiene un solo elemento
}



/**
 * aggiorna il termine di ricerca per anagrafica e il model
 * @param {*} ana 
 * @param {Boolean} disabilitaAggiornaListino
 * @param {Boolean} disabilitaAggiornaIntestazione
 *
 */
function setAnagrafica(ana, disabilitaAggiornaListino, disabilitaAggiornaIntestazione) {
    var self = this;

    if(typeof ana !== 'undefined' && ana !== null){
        self.modeltestata.anagrafica = ana;
        self.blurRifdoc()


        if(self.nou(disabilitaAggiornaIntestazione) || !disabilitaAggiornaIntestazione){//solo in creazione
            self.$http({url: utils.urls.wsbase + "/datiintestazione/daanagrafica/" + ana.id, method: "GET"}).then(function(resp){            
                self.modeltestata.intestazione = angular.extend({}, ana, resp.data, {id: null});        
            }, function(err){
                self.em.errorCB(err)
            });
        }         
        
        self.modeltestata._anagrafica = ana.cognome ? ana.cognome : (ana.ragsoc + " " + ana.codicefiscale + " " + ana.partitaiva);
                  
        self.anagraficawin.close();
        self.disabilitaAnagrafica = true;
        
        if(self.nou(disabilitaAggiornaListino) || !disabilitaAggiornaListino){
            self.aggiornaListino();
        }
        self.aggiornaMostraIndirizzo();
        
        

        //id anagrafica dal documento ritirato o null
        var idanarec = bssRecursiveGetter(self, "docrecuperato.testata.intestazione.anagraficaPGFStd.id", null);
        
        /**
         * ottengo l'indirizzo dall'anagrafica solo in 2 casi:
         * 1. non sono in recupero documento quindi id dell'anagrafica recuperata è null
         * 2. sono in recupero documento ma l'id della nuova anagrafca è differente da quella receperata
         */
        if(self.nou(idanarec) || (ana.id !== idanarec) ){
            self.indirizzoDaAnagrafica();
        }
        

        if(ana.modpagamento !== null){
            //se datadoc non è definita il pagamento non verrebbe mostrato, metto la data odierna
            if(self.modeltestata.datadoc === null || typeof self.modeltestata.datadoc === 'undefined'){
                self.modeltestata.datadoc = moment().toDate();            
            }
            self.setPagamento(ana.modpagamento);
        }
        

        self.modeltestata._anacodiceGestExt = ana.codiceGestExt;
        
    }

}

function resetAnagrafica() {
    var self = this;
    self.modeltestata.anagrafica = null;
    self.modeltestata._anagrafica = null;
    self.modeltestata._anacodicefiscale = null;
    self.modeltestata._anapartitaiva = null;
    self.modeltestata._anacodiceGestExt = null;
    self.modeltestata.intestazione = {};
    self.disabilitaAnagrafica = false;
    self.aggiornaMostraIndirizzo();
    self.resetIndirizzo();
}

/**
 * @description
 * Aggiorno la variabile che mostra/nasconde/rende required il campo anagrafica
 */
function aggiornaMostraAnagrafica() {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {
        self.mostraanagrafica = false;
        return;
    }


    var fatturabilita = self.modeltestata.tipodoc.fatturabilita.value;
    var assocana = self.modeltestata.tipodoc.associaAnagrafica.value;


    if (fatturabilita === 'MAI') {
        self.modeltestata.fatturare = false;
    }

    if (fatturabilita === 'SEMPRE') {
        self.modeltestata.fatturare = true;
        if (assocana === 'SEMPRE') {
            self.mostraanagrafica = 'required';
        }
        else {
            console.warn("Quando il tipodoc.fatturabilita === 'SEMPRE' anche tipodoc.associaAnagrafica deve essere 'SEMPRE', e invece è", assocana)
        }
    } else {
        switch (assocana) {
            case 'SEMPRE':
                self.mostraanagrafica = 'required';
                break;
            case 'FACOLTATIVO':
                self.mostraanagrafica = true;
                break;
            case 'MAI':
                self.mostraanagrafica = false;
                break;
        }
    }

    self.fatturareChanges(self.modeltestata.fatturare);      


}

/**
 * @description
 * Inizializzo la griglia dell'anagrafica
 */
function initGridAnagrafica(ricerca) {
    var self = this;
    var strentity = "anagraficapgfstd/" + self.modeltestata.tipodoc.codice;

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setAnagrafica(dataItem);
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, {sort: [{field: "insTime", dir: "desc"}]}).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            
            self.cfgFormClifor = new self.fdhf("", {form: gridcfg.form, formmodel: gridcfg.formmodel}, null, null, self.formNuovoClifor, true);


            var oriParameterMap = angular.copy(ds.transport.parameterMap)

            ds.transport.parameterMap = function (data, type) {
                var term = self.testata.anagrafica.$viewValue;

                var parricerca = {search : term};
                if(!self.nou(ricerca)){
                    parricerca = {
                        filter: {
                            logic: "AND",
                            filters: [
                                {field: ricerca, operator: "startsWith", value: self.testata["ana"+ricerca].$viewValue}
                            ]
                        }
                    }
                }

                var newdata = angular.extend({}, data, oriParameterMap(data, type), parricerca);
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }
            /*gridcfg.columns = gridcfg.columns.map(function(colonna){
                if(["ragsoc", "partitaiva", "codice", "nome", "cognome", "codicefiscale", "listino"].indexOf(colonna.field) === -1){
                    colonna.hidden = true;
                }
                return colonna
            })*/
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridAnagrafica !== 'undefined') {
                    //console.log("databound", self.gridAnagrafica);
                    self.gridAnagrafica.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliAnagrafiche = self.gridAnagrafica.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridAnagraficaOpts = angular.extend({}, gridcfg);
            self.gridAnagraficaRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupAnagrafica(ricerca) {
    var self = this;
    self.anagraficawin.open().center();
    self.initGridAnagrafica(ricerca)
}


/**
 * Ritorna una lista di al più 2 intestatari per tipodoc e _anagrafica
 * Posso usarlo al blur da anagrafica e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 * @returns {Promise<AnagraficheStd<TipoDoc>>}
 */
function getAnagrafiche(filtri) {
    var self = this;
    var term = self.testata.anagrafica.$viewValue;
    var strentity = "anagraficapgfstd/" + self.modeltestata.tipodoc.codice;
    var parametri = { page: 1, pageSize: 2, take: 2, skip: 0};
    if(self.nou(filtri)){
        parametri.search = term;
    } else {
        parametri.filter = filtri;
    }
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param(parametri)
    }).then(function (resp) {
        self.possibiliAnagrafiche = resp.data.results;
        return self.possibiliAnagrafiche
    })
}



function filtraIntestatario(filtro){
    var self = this;
    self.gridAnagrafica.dataSource.filter(filtro !== 'MAXPHO' ? {
        field: 'insUser',
        operator: 'eq',
        value: filtro
    } : {
        logic: 'or', filters: [
            {
                field: 'insUser',
                operator: 'eq',
                value: filtro
            },
            {
                field: 'insUser',
                operator: 'eq',
                value: 'IMPORTER'
            }
        ]
    })
}

function disabilitaFiltraIntestatario(filtro){
    var self = this;

    return (bssRecursiveGetter(self, "gridAnagrafica.dataSource", false) && 
        self.gridAnagrafica.dataSource.filter() !== undefined && 
        self.gridAnagrafica.dataSource.filter().filters.find(function(f){
            return f.field === 'insUser' && f.operator === 'eq' && f.value === filtro
        }))
}

/**
 * ===================================================================== Fine Anagrafica =======================================================================================================
 */


/**
 * ===================================================================== Inizio Rifdoc =======================================================================================================
 */
DocumentistdCtrl.prototype.resetRifdoc = resetRifdoc;
DocumentistdCtrl.prototype.blurRifdoc = blurRifdoc;
DocumentistdCtrl.prototype.getInevasi = getInevasi;
DocumentistdCtrl.prototype.apriPopupRifdoc = apriPopupRifdoc;
DocumentistdCtrl.prototype.initgridrifdoc = initgridrifdoc;
DocumentistdCtrl.prototype.setRifdoc = setRifdoc;

function resetRifdoc(){
    var self = this;
    self.modeltestata.rifdoc = "";
    if (typeof self.gridrifdoc !== 'undefined') {
        self.gridrifdoc.dataSource.read();
    }
}

function blurRifdoc($event){
    var self = this;

    self.$timeout(function(){
        var anagrafica = bssRecursiveGetter(self, "modeltestata.anagrafica", {id: null})
        
    
        self.getInevasi(anagrafica).then(function (opzioni) {
            if (opzioni.length === 1) {
                //self.setRifdoc(opzioni[0].riferimento_ordine)
                self.setRifdoc(opzioni[0])
            } else {
                //$event.stopImmediatePropagation();
                if (opzioni.length === 0) {
                    
                } else {
                    self.apriPopupRifdoc()
                }            
            }
        })
    })
    
}

function getInevasi(anagrafica){
    var self = this;

    var term = self.modeltestata.rifdoc;

    var filters = null;
    if(!self.nou(anagrafica.id)){
        filters = [
            {field: "anagraficacliente.id", value: anagrafica.id, operator: "eq"}
        ];
    }

    var req = self.$http({
        url: utils.urls.wsbase + "/riferimentomaxpho/grid",
        method: "POST",
        data: $.param({ 
            page: 1, 
            pageSize: 2, 
            take: 2, 
            skip: 0, 
            search: term, 
            filter: {
                logic: 'and',
                filters: filters
            }            
        })
    })

    // var req = self.$q.when({data: {
    //     results: [{codice: "CODICEPROVA"}]
    // }})


    return req.then(function (resp) {
        self.possibiliRifdoc = resp.data.results;
        return self.possibiliRifdoc;
    })
}

function apriPopupRifdoc() {
    var self = this;
    self.initgridrifdoc();
    self.gridrifdocwin.open().center();
}

/**
 * @todo rivedere inizializzazione grid ammesso che sia una grid e non un webservice a parte
 */
function initgridrifdoc(){
    var self = this;

    var strentity = "riferimentomaxpho"

    function selezionaDaGriglia(e, griglia) {
        console.log("selezione rif doc", e.currentTarget);
        var dataItem = griglia.dataItem(e.currentTarget); //angular.extend({}, griglia.dataItem(e.currentTarget), {label: e.currentTarget});
        self.setRifdoc(dataItem);
        self.gridrifdocwin.close()
    }

    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.modeltestata.rifdoc;
                var newdata = angular.extend({}, defaultRead(data, gridcfg.dataSource.options), {search: term});
                var idana = bssRecursiveGetter(self, "modeltestata.anagrafica.id", null);
                if(!self.nou(idana)){//solo se ho l'id dell'anagrafica
                    var filtri =  normalizeFilter(ds);
                    filtri.filters.unshift({field: "anagraficacliente.id", value: idana, operator: "eq"});
                    newdata.filter = filtri;
                }                      
                return newdata
            }
            
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {

                if (typeof self.gridrifdoc !== 'undefined') {
                    //console.log("databound", self.gridrifdoc);
                    self.gridrifdoc.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliRifdoc = self.gridrifdoc.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                    // griglia.element.on("click", "tbody tr[data-uid]", function (e) {
                    //     popolaFormDaGriglia(e, griglia)
                    // })

                }
            }

            self.gridrifdocOpts = angular.extend({}, gridcfg);
            self.gridrifdocRebind = moment().format();
            //return ds.read();
        })
    });
}

function setRifdoc(rifdoc){
    var self = this;
    self.modeltestata.rifdoc = bssRecursiveGetter(rifdoc, "riferimento_ordine", rifdoc);
    if(!self.nou(bssRecursiveGetter(rifdoc, "anagraficacliente", null))){
        var idanatestata = bssRecursiveGetter(self, "modeltestata.anagrafica.id", null);
        var idanarifdoc = rifdoc.anagraficacliente.id;
        if(self.nou(idanatestata)){
            self.setAnagrafica(rifdoc.anagraficacliente);
        }  else {
            if(idanatestata !== idanarifdoc){
                alert("L'anagrafica inserita risulta diversa da quella del rif. int. ord.");
            }
        }       
    }
}
/**
 * ===================================================================== Fine Rifdoc =======================================================================================================
 */ 




/**
 * Indirizzo
*/

DocumentistdCtrl.prototype.localitadaidcomune = localitadaidcomune;
DocumentistdCtrl.prototype.intestazionedaindirizzo = intestazionedaindirizzo;
DocumentistdCtrl.prototype.blurIndirizzo = blurIndirizzo;
DocumentistdCtrl.prototype.aggiornaMostraIndirizzo = aggiornaMostraIndirizzo;
DocumentistdCtrl.prototype.indirizzoDaAnagrafica = indirizzoDaAnagrafica;
DocumentistdCtrl.prototype.setIndirizzo = setIndirizzo;
DocumentistdCtrl.prototype.resetIndirizzo = resetIndirizzo;
DocumentistdCtrl.prototype.creaIndirizzo = creaIndirizzo;
DocumentistdCtrl.prototype.initGridIndirizzo = initGridIndirizzo;
DocumentistdCtrl.prototype.apriPopupIndirizzo = apriPopupIndirizzo;
DocumentistdCtrl.prototype.editIndirizzo = editIndirizzo;
DocumentistdCtrl.prototype.getIndirizzi = getIndirizzi;
DocumentistdCtrl.prototype.setNuovoIndirizzo = setNuovoIndirizzo;
DocumentistdCtrl.prototype.reqLocalitaManuale = reqLocalitaManuale;

function localitadaidcomune(idcomune){
    var self = this;
    return self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tcomuni/cerca",
        data: $.param({id: idcomune})
    }).then(function(resp){
        var comune = resp.data;

        return comune;

        //non occorre il comune serializzato contiene già provincia e nazione
        // console.log("comune ottenuto da idcomune", idcomune, comune);
        // return self.$q.all([
        //     self.$http({
        //         method: "POST",
        //         url: utils.urls.wsbase + "/tcomuni/cerca",
        //         data: $.param({id: comune.provincia.id})
        //     }),
        //     self.$http({
        //         method: "POST",
        //         url: utils.urls.wsbase + "/tnazioni/cerca",
        //         data: $.param({id: comune.nazione.id})
        //     }),
        // ]).then(function(resps){
        //     var provincia = resps[0].data;
        //     var nazione = resps[1].data;
        //     comune.provincia = provincia;
        //     comune.nazione = nazione;

        //     return comune
        // })
        
    })
}

//popola intestazione da indirizzo
function intestazionedaindirizzo(){
    var self = this;


    if(self.valida.validaForm(self.formNuovoIndirizzo)){
    
        var indirizzo = bssRecursiveGetter(self, "modeltestata.indirizzo", null);

        self.reqLocalitaManuale(self.nuovoIndirizzo).then(function(resp){
            var comuneindirizzo = resp.data;

            var daticopiati = {
                cap: comuneindirizzo.cap,
                citta: comuneindirizzo.comune, //in precedenza comune cambiato in città verificare che non causi problemi
                provincia: bssRecursiveGetter(comuneindirizzo, "provincia.iso", null),
                nazione: bssRecursiveGetter(comuneindirizzo, "nazione", null),                
            };

            indirizzo = angular.extend({
                indirizzo: null,
                telefono: null,
                email: null
            }, indirizzo, self.nuovoIndirizzo);                
            

            daticopiati.indirizzo = indirizzo.indirizzo;                                
            daticopiati.telefono = indirizzo.telefono1;
            daticopiati.email = indirizzo.email;

            if(!self.modeltestata.fatturare){
                daticopiati.cognome = indirizzo.descrizione;
            } else {
                daticopiati.ragsoc = indirizzo.descrizione;
            }
                    
            self.modeltestata.intestazione = angular.extend({}, self.modeltestata.intestazione, daticopiati)
        });
    }        

}

function blurIndirizzo($event) {
    var self = this;

    if (self.modeltestata.anagrafica === null || typeof self.modeltestata.anagrafica === 'undefined') {//blur da anagrafica per reset tipodoc
        //return;
    }

    self.getIndirizzi().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setIndirizzo(opzioni[0])
        } else {
            $event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOINDIRIZZIFOUND", "warning");
                self.resetIndirizzo()
            }
            //if (self.modeltestata.anagrafica !== null && typeof self.modeltestata.anagrafica !== 'undefined') {
                self.apriPopupIndirizzo()
            //}
        }
    })
    //se il datasource contiene un solo elemento
}

function aggiornaMostraIndirizzo() {
    var self = this;

    if (self.modeltestata.anagrafica !== null && typeof self.modeltestata.anagrafica !== 'undefined') {
        self.mostraindirizzo = true;
    } else {
        self.mostraindirizzo = false;
    }
}

function indirizzoDaAnagrafica() {
    var self = this;
    self.$http({
        method: 'get',
        url: utils.urls.wsbase + "/indirizzi/" + self.modeltestata.anagrafica.id + "/default"
    }).then(function (resp) {
        if (resp.data !== null && resp.data !== "") {

            /**
             * se l'indirizzo di anagrafica non ha descrizione (destinazione) 
             * ma in testata ho ottenuto l'anagrafica metto come descrizione dell'indirizzo la label dell'anagrafica 
             */            
            if(self.nou(resp.data.descrizione) && !self.nou(bssRecursiveGetter(self, "modeltestata._anagrafica", null))){
                resp.data.descrizione = angular.copy(self.modeltestata._anagrafica);
            }
            self.setIndirizzo(resp.data);
        }
        else {
            self.em.showT("DOCUMENTISTD.NODEFAULTINDIRIZZIFOUND", "warning")
        }
    })
}

function setIndirizzo(indirizzo, disabilitaChiudi) {
    var self = this;
    if(typeof indirizzo !== 'undefined' && indirizzo !== null){
        var template = self.headerIndirizzo.model.fields.label.template;
        //template = "#=descrizione+' - '+indirizzo+ ' ' + (typeof comune === 'undefined' || comune === null ? '' : (comune.comune === null || typeof comune.comune === 'undefined') ? comune : comune.comune ) #";
        var labelIndirizzo = kendo.template(template)(indirizzo);
        //console.log("label indirizzo", labelIndirizzo);
        if(disabilitaChiudi !== true){
            self.modeltestata.indirizzo = indirizzo;
            self.modeltestata._indirizzo = labelIndirizzo;
            self.indirizzowin.close();
            self.disabilitaIndirizzo = true;
        }
        self.editIndirizzo(true);
        
    } else {
        self.apriPopupIndirizzo(true);
    }
}


function resetIndirizzo() {
    var self = this;
    self.modeltestata.indirizzo = null;
    self.modeltestata._indirizzo = null;
    self.disabilitaIndirizzo = false;
}

/**
 * 
 * Richiama il webservice che cerca un comune per cap, comune, iso provincia e id nazione, e ritorna il comune (creandolo se non esiste)
 * 
 * @param {{_comune: String, _cap: String, _provincia: String, _nazione: (Object | Number)}} data - _cap e _comune sono le stringhe (97100 e Ragusa), _provincia è la stringa con la iso provincia (RG), _nazione è l'id o l'oggetto contenente l'id
 * @return promise del webservice /tcomuni/trovamanuale
 */
function reqLocalitaManuale(data) {
    var self = this; 

    var localita = {
        comune: data._comune, //sempre comune in chiaro
        cap: data._cap, //sempre cap in chiaro
        isoprov: data._provincia, // sempre iso provincia in chiaro
        idnazione: (bssRecursiveGetter(data, "_nazione.id", null) === null) ? data._nazione : data._nazione.id, // sempre l'id della nazione
    }

    var req = self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tcomuni/trovamanuale",
        data: $.param(localita)                
    })

    return req;
}

function creaIndirizzo(){
    var self = this;

    if(self.valida.validaForm(self.formNuovoIndirizzo)){
        var data = angular.extend({}, self.nuovoIndirizzo, {
            intestatario: null
        });

        if(!self.nou(bssRecursiveGetter(self, "modeltestata.anagrafica.id", null))){
            data.intestatario = { id: self.modeltestata.anagrafica.id };
        }

        var getEndpoint = function(){
            var suffisso = "dastringa";

            var endpointcrea = "/indirizzi/crea" + suffisso;    
            var endpointmodifica = "/indirizzi/modifica" + suffisso;    

            if(self.nou(data.id)){//l'indirizzo non ha id
                return self.$q.when(endpointcrea);
            } else {//l'indirizzo ha id

                var filters = [{field: "destinazione.id", value: data.id, operator: 'eq'}];
                if(!self.nou(self.iddoc)){
                    filters.push({field: "id", value: self.iddoc, operator: 'neq'});
                }
                
                //ottengo tutti gli altri documenti che utilizzano questo indirizzo
                return self.$http({
                    method: "POST",
                    url: utils.urls.wsbase + "/documentistd/"+self.raggruppamento+"/grid",
                    data: $.param({                        
                        filter: {
                            logic: 'AND',
                            filters: filters
                        }
                    })
                }).then(function(resp){
                    var risultati = resp.data.results;
                    

                    var endpoint = (risultati.length) 
                        ? endpointcrea 
                        : endpointmodifica

                    console.log("[CREAINDIRIZZO] 2. RECUPERATI TUTTI GLI ALTRI DOCUMENTI CON LO STESSO INDIRIZZO", risultati, endpoint);
                    

                    return endpoint;
                    ;                    
                }, function(err){
                    console.log("[CREAINDIRIZZO] 2. errore webservice altri  documenti filtrati per indirizzo")
                })
            }

        }

        var idcomune = data.idcomune;        
        //var req = self.$q.when({data: {id: idcomune}});

        //if(self.nou(idcomune)){//l'utente ha inserito manualmente i valori devo chidere al server un id comune
            //var oggettoprovincia = (self.soloisoprovincia || self.nou(self.soloisoprovincia) || self.nou(data.provincia)) ? data._provincia : {id: data.provincia}

        // var localita = {
        //     comune: data._comune, //sempre comune in chiaro
        //     cap: data._cap, //sempre cap in chiaro
        //     isoprov: data._provincia, // sempre iso provincia in chiaro
        //     idnazione: (bssRecursiveGetter(data, "_nazione.id", null) === null) ? data._nazione : data._nazione.id, // sempre l'id della nazione
        // }

        // var req = self.$http({
        //     method: "POST",
        //     url: utils.urls.wsbase + "/tcomuni/trovamanuale",
        //     data: $.param(localita)                
        // })
        //}
        var req = self.reqLocalitaManuale(data);

        return req.then(function(resp){


            
            idcomune = resp.data.id;

            var jsondata = angular.extend(data);
            jsondata.comune = {id: idcomune};

            console.log("[CREAINDIRIZZO] 1. OTTENUTO IL COMUNE DELL'INDIRIZZO (EVENTUALMENTE CREANDOLO)", resp.data);


            return getEndpoint().then(function(endpoint){
//                console.log("richiamo l'endpoint", endpoint, "passo", jsondata, $.param(jsondata))
                

                return self.$http({
                    method: "POST",
                    url: utils.urls.wsbase + endpoint,
                    data: $.param({elemento: JSON.stringify(jsondata)})
                }).then(function (resp) {
                    self.nuovoIndirizzo = {};
                    self.setIndirizzo(resp.data);
                    self.indirizzowin.close();
                    self.disabilitacampilocalita = false;            
                    self.disabilitacampoprovincia = false;            
                    self.formNuovoIndirizzo.$setUntouched();
                    console.log("[CREAINDIRIZZO] 3. OTTENUTO L'INDIRIZZO", resp.data);

                    return self.$q.when([]);

                    //in alcuni casi fa bloccare genere trasporti
                    /*
                    return self.gridIndirizzo.dataSource.read().then(function(resp){
                        console.log("[CREAINDIRIZZO] 4. AGGIORNATA GRID INDIRIZZI", resp.data);

                    }, function(err){
                        console.log("[CREAINDIRIZZO] 4. ERRORE AGGIORNAMENTO GRID INDIRIZZI", resp.data);

                    });
                    */            
                }, function(err){
                    self.em.errorCB(err)
                    return err;
                })
            }, function(err){
                self.em.errorCB(err)
                return err;
            });
                          

        }, function(err){
            self.em.errorCB(err);
            return err;
        })

    } else {
        throw("INDIRIZZO_NON_VALIDO")
    }
}

function creaIndirizzo_old() {
    var self = this;

    //var modello = new self.gf().convertiModelloRaggruppato(self.nuovoindirizzo);

    if (self.valida.validaForm(self.formNuovoIndirizzo)) {
        
        
        var data = angular.extend({}, self.nuovoIndirizzo, {
            intestatario: null
        });

        var idcomune = data.idcomune;

        if(self.nou(idcomune)){//l'utente ha inserito manualmente i valori
            data.comune = data._comune;
            data.cap = data._cap;
            data.provincia = data._provincia            
            data.nazione = {id: data._nazione}; 
        } else {//l'utente ha selezionato un comune tra quelli in db
            var oggettoprovincia = self.soloisoprovincia ? {iso: data.provincia} : {id: data.provincia}

            data.comune = {id: data.comune, provincia: oggettoprovincia, nazione: {id: data.nazione}};
            data.nazione = {id: data.nazione};    
            data.provincia = oggettoprovincia;
            //data.provincia = data._provincia;
        }

        if(!self.nou(bssRecursiveGetter(self, "modeltestata.anagrafica.id", null))){
            data.intestatario = { id: self.modeltestata.anagrafica.id };
        }

        var jsondata = angular.extend(data);

        if(self.nou(idcomune)){
            jsondata.nazione = JSON.stringify(data.nazione);
            jsondata.intestatario = JSON.stringify(data.intestatario);
            jsondata.indirizzo = data.indirizzo;
        } else {
            jsondata = {elemento: JSON.stringify(jsondata)};
        }

        var getEndpoint = function(){
            var suffisso = !self.nou(idcomune) ? "dastringa" : "manuale";

            var endpointcrea = "/indirizzi/crea" + suffisso;    
            var endpointmodifica = "/indirizzi/modifica" + suffisso;    

            if(self.nou(data.id)){//l'indirizzo non ha id
                return self.$q.when(endpointcrea);
            } else {//l'indirizzo ha id

                var filters = [{field: "destinazione.id", value: data.id, operator: 'eq'}];
                if(!self.nou(self.iddoc)){
                    filters.push({field: "id", value: self.iddoc, operator: 'neq'});
                }
                
                //ottengo tutti gli altri documenti che utilizzano questo indirizzo
                return self.$http({
                    method: "POST",
                    url: utils.urls.wsbase + "/documentistd/"+self.raggruppamento+"/grid",
                    data: $.param({                        
                        filter: {
                            logic: 'AND',
                            filters: filters
                        }
                    })
                }).then(function(resp){
                    var risultati = resp.data.results;
                    
                    return (risultati.length) 
                        ? endpointcrea 
                        : endpointmodifica
                    ;                    
                })
            }


        }                

        return getEndpoint().then(function(endpoint){
            console.log("richiamo l'endpoint", endpoint, "passo", jsondata, $.param(jsondata))
            return self.$http({
                method: "POST",
                url: utils.urls.wsbase + endpoint,
                data: $.param(jsondata)
            }).then(function (resp) {
                self.nuovoIndirizzo = {};
                self.setIndirizzo(resp.data);
                self.indirizzowin.close();
                self.disabilitacampilocalita = false;            
                self.disabilitacampoprovincia = false;            
                self.formNuovoIndirizzo.$setUntouched();
                return self.gridIndirizzo.dataSource.read();            
            }, function(err){
                self.em.errorCB(err)
                return err;
            })
        });
    }

}

/**
 * @description
 * Inizializzo la griglia dell'indirizzo
 */
function initGridIndirizzo() {
    var self = this;
    var strentity = "indirizzi";

    function selezionaDaGriglia(e, griglia) {
        console.log("selezione indirizzo", e.currentTarget);
        var dataItem = griglia.dataItem(e.currentTarget); //angular.extend({}, griglia.dataItem(e.currentTarget), {label: e.currentTarget});
        self.setIndirizzo(dataItem);
        self.selezionaindirizzowin.close();
    }

    function popolaFormDaGriglia(e, griglia) {
        console.log("selezione indirizzo", e.currentTarget);
        var dataItem = griglia.dataItem(e.currentTarget); //angular.extend({}, griglia.dataItem(e.currentTarget), {label: e.currentTarget});
        self.setNuovoIndirizzo(dataItem);
        self.selezionaindirizzowin.close();
    }


    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.modeltestata._indirizzo;
                var newdata = angular.extend({}, defaultRead(data, gridcfg.dataSource.options), {search: term});
                if(!self.nou(bssRecursiveGetter(self, "modeltestata.anagrafica.id", null))){//solo se ho l'id dell'anagrafica
                    newdata.idanagrafica = self.modeltestata.anagrafica.id;
                }                      
                return newdata
            }
            
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {

                if (typeof self.gridIndirizzo !== 'undefined') {
                    //console.error("|~~~~~~~~~~> databound gridindirizzo", self.gridIndirizzo);
                    self.gridIndirizzo.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliIndirizzi = self.gridIndirizzo.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                    griglia.element.on("click", "tbody tr[data-uid]", function (e) {
                        popolaFormDaGriglia(e, griglia)
                    })

                }
            }

            self.gridIndirizzoOpts = angular.extend({}, gridcfg);
            self.gridIndirizzoRebind = moment().format();
            return ds.read();
        })
    });
}

function apriPopupIndirizzo(withoutpopup) {
    var self = this;
    if(withoutpopup !== true){
        self.indirizzowin.open().center();
    }    
    self.initGridIndirizzo().then(function(){
        self.disabilitacampilocalita = false;            
        self.disabilitacampoprovincia = false;  
        if(!self.nou(self.formNuovoIndirizzo)){
            self.formNuovoIndirizzo.$setPristine();
            self.formNuovoIndirizzo.$setUntouched();
        }          
        self.setNuovoIndirizzo({});
    })
}

function editIndirizzo(withoutpopup){
    var self = this;
    if(withoutpopup !== true){
        self.indirizzowin.open().center();
    }
    self.initGridIndirizzo().then(function(){
        self.setNuovoIndirizzo(self.modeltestata.indirizzo)
    })
}


function setNuovoIndirizzo(indirizzo){
    var self = this;
    console.log("imposto l'indirizzo a partire da", indirizzo)

    var cap = bssRecursiveGetter(indirizzo, "comune.cap", null);
    var comune = bssRecursiveGetter(indirizzo, "comune.comune", null);
    var idcomune = bssRecursiveGetter(indirizzo, "comune.id", null);
    
    var ricerca = idcomune !== null ? 
        self.$http({
            url: utils.urls.wsbase + "/tcomuni/cerca",
            method: "POST",
            data: $.param({id: idcomune})
        }).then(function(resp){return resp.data}) 
        : self.$q.when({cap: cap, comune: comune})

    
    ricerca.then(function(resp){
        console.log("risultato ricerca", resp);
        self.nuovoIndirizzo = angular.extend({}, indirizzo, {
            "_cap": cap,
            "_comune": comune
        });

        if(resp.id){
            self.setlocalitanuovoindirizzo(resp);
        }
    }, function(err){
        self.em.errorCB(err);
    })
    

    
}

/**
 * Ritorna una lista di al più 2 intestatari per tipodoc e _anagrafica
 * Posso usarlo al blur da anagrafica e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 */
function getIndirizzi() {
    var self = this;

    return self.$timeout(function(){
        var term = self.modeltestata._indirizzo;
        var strentity = "indirizzi";
        return self.$http({
            url: utils.urls.wsbase + "/" + strentity + "/grid",
            method: "POST",
            data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
        }).then(function (resp) {
            self.possibiliIndirizzi = resp.data.results;
            return self.possibiliIndirizzi;
        })
    })
    
}

/**
 * ===================================================================== Fine Indirizzo =======================================================================================================
 */


/**
 * Agente
 */
DocumentistdCtrl.prototype.blurAgente = blurAgente;
DocumentistdCtrl.prototype.setAgente = setAgente;
DocumentistdCtrl.prototype.resetAgente = resetAgente;
DocumentistdCtrl.prototype.aggiornaMostraAgente = aggiornaMostraAgente;
DocumentistdCtrl.prototype.initGridAgente = initGridAgente;
DocumentistdCtrl.prototype.apriPopupAgente = apriPopupAgente;
DocumentistdCtrl.prototype.getAgenti = getAgenti;

function blurAgente($event) {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {//blur da anagrafica per reset tipodoc
        return;
    }

    self.getAgenti().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setAgente(opzioni[0])
        } else {
            $event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOAGENTEFOUND", "warning");
                self.resetAgente()
            }
            if (self.modeltestata.tipodoc !== null && typeof self.modeltestata.tipodoc !== 'undefined') {//blur da anagrafica per reset tipodoc
                self.apriPopupAgente()
            }
        }
    })
    //se il datasource contiene un solo elemento
}


/**
 * aggiorna il termine di ricerca per agente e il model
 * @param {*} agente 
 */
function setAgente(agente) {
    var self = this;
    if(agente !== null && typeof agente !== 'undefined'){
        self.modeltestata.agente = agente;
        self.modeltestata._agente = agente.codice + " - " + agente.nome + " - " + agente.cognome + " - " + agente.ragsoc + " - " + agente.partitaiva;
        self.agentewin.close();
        self.disabilitaAgente = true;
    }
    
}


function resetAgente() {
    var self = this;
    self.modeltestata.agente = null;
    self.modeltestata._agente = null;
    self.disabilitaAgente = false;
}

/**
 * @description
 * Aggiorno la variabile che mostra/nasconde/rende required il campo anagrafica
 */
function aggiornaMostraAgente() {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {
        self.mostraagente = false;
        return;
    }

    switch (self.modeltestata.tipodoc.haAgente.value) {
        case 'SEMPRE':
            self.mostraagente = 'required';
            break;
        case 'FACOLTATIVO':
            self.mostraagente = true;
            break;
        case 'MAI':
            self.mostraagente = false;
            break;
    }

}

/**
 * @description
 * Inizializzo la griglia degli agenti
 */
function initGridAgente() {
    var self = this;
    var strentity = "agenti";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setAgente(dataItem);
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.testata.agente.$viewValue;
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }
            /*gridcfg.columns = gridcfg.columns.map(function(colonna){
                if(["ragsoc", "partitaiva", "codice", "nome", "cognome", "codicefiscale", "listino"].indexOf(colonna.field) === -1){
                    colonna.hidden = true;
                }
                return colonna
            })*/
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridAgente !== 'undefined') {
                    //console.log("databound", self.gridAgente);
                    self.gridAgente.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliAnagrafiche = self.gridAgente.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }

            
            self.gridAgenteOpts = angular.extend({}, gridcfg);
            self.gridAgenteRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupAgente() {
    var self = this;
    self.agentewin.open().center();
    self.initGridAgente()
}

/**
 * Ritorna una lista di al più 2 agenti
 * Posso usarlo al blur da anagrafica e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 * @returns {Promise<AnagraficheStd<TipoDoc>>} 
 */
function getAgenti() {
    var self = this;
    var term = self.testata.agente.$viewValue;
    var strentity = "agenti";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibiliAgenti = resp.data.results;
        return self.possibiliAgenti
    })
}


/**
 * ===================================================================== Fine Agente =======================================================================================================
 */


/**
 * Deposito
 */
DocumentistdCtrl.prototype.selAllRate = selAllRate;
DocumentistdCtrl.prototype.deselAllRate = deselAllRate;
DocumentistdCtrl.prototype.confermaCancSingolaRata = confermaCancSingolaRata;
DocumentistdCtrl.prototype.blurDeposito = blurDeposito;
DocumentistdCtrl.prototype.setDeposito = setDeposito;
DocumentistdCtrl.prototype.resetDeposito = resetDeposito;
DocumentistdCtrl.prototype.aggiornaMostraDeposito = aggiornaMostraDeposito;
DocumentistdCtrl.prototype.initGridDeposito = initGridDeposito;
DocumentistdCtrl.prototype.apriPopupDeposito = apriPopupDeposito;
DocumentistdCtrl.prototype.getDepositi = getDepositi;

function selAllRate() {
    var self = this;
    self.selrate = {};
    self.modeltestata.scadenzepagamenti.forEach(function (rata, indicerata) { self.selrate[indicerata] = true });
}

function deselAllRate() {
    var self = this;
    self.selrate = {};
}




/**
 * @description
 * Deseleziona tutte le rate.
 * Seleziona solo la rata all'indice
 * Apre il popup di cancellazione
 * 
 * @param {int} indice 
 */
function confermaCancSingolaRata(indice) {
    var self = this;

    //deseleziono tutto
    self.selrate = {};

    //seleziono solo 'indice'
    self.selrate[indice] = true;

    self.eliminarateconfirmwin.open().center();

}

function blurDeposito($event) {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {//blur da anagrafica per reset tipodoc
        return;
    }

    self.getDepositi().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setDeposito(opzioni[0])
        } else {
            $event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NODEPOSITOFOUND", "warning");
                self.resetDeposito()
            }
            if (self.modeltestata.tipodoc !== null && typeof self.modeltestata.tipodoc !== 'undefined') {//blur da anagrafica per reset tipodoc
                self.apriPopupDeposito()
            }
        }
    })
    //se il datasource contiene un solo elemento
}


/**
 * aggiorna il termine di ricerca per deposito e il model
 * @param {*} deposito 
 */
function setDeposito(deposito) {
    var self = this;
    self.modeltestata.deposito = deposito;
    self.modeltestata._deposito = deposito.codice + " - " + deposito.nome + " - " + deposito.cognome + " - " + deposito.ragsoc + " - " + deposito.partitaiva;
    self.depositowin.close();
    self.disabilitaDeposito = true;
}


function resetDeposito() {
    var self = this;
    self.modeltestata.deposito = null;
    self.modeltestata._deposito = null;
    self.disabilitaDeposito = false;
}

/**
 * @description
 * Aggiorno la variabile che mostra/nasconde/rende required il campo anagrafica
 */
function aggiornaMostraDeposito() {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {
        self.mostradeposito = false;
        return;
    }


    if (self.modeltestata.tipodoc.tipomovmag !== null) {
        self.mostradeposito = true;
    }

    if (self.modeltestata.tipodoc.deposito !== null) {
        self.setDeposito(self.modeltestata.tipodoc.deposito)
    }



}

/**
 * @description
 * Inizializzo la griglia degli agenti
 */
function initGridDeposito() {
    var self = this;

    var entity = "com.bsssrl.bssstdgestent.DepositoStd"
    var strentity = "depositostd";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setDeposito(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.testata.deposito.$viewValue;
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }
            /*gridcfg.columns = gridcfg.columns.map(function(colonna){
                if(["ragsoc", "partitaiva", "codice", "nome", "cognome", "codicefiscale", "listino"].indexOf(colonna.field) === -1){
                    colonna.hidden = true;
                }
                return colonna
            })*/
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridDeposito !== 'undefined') {
                    //console.log("databound", self.gridDeposito);
                    self.gridDeposito.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliDepositi = self.gridDeposito.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridDepositoOpts = angular.extend({}, gridcfg);
            self.gridDepositoRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupDeposito() {
    var self = this;
    self.depositowin.open().center();
    self.initGridDeposito()
}

/**
 * Ritorna una lista di al più 2 depositi
 * Posso usarlo al blur da tipodoc e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 */
function getDepositi() {
    var self = this;
    var term = self.testata.deposito.$viewValue;
    var strentity = "depositostd";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibiliDepositi = resp.data.results;
        return self.possibiliDepositi
    })
}


/**
 * ===================================================================== Fine Deposito =======================================================================================================
 */


/**
* Pagamenti
*/
DocumentistdCtrl.prototype.nuovaRigaPagamento = nuovaRigaPagamento;
DocumentistdCtrl.prototype.contaRighePagamentoInvalide = contaRighePagamentoInvalide;
DocumentistdCtrl.prototype.contaRighePagamentoSelezionate = contaRighePagamentoSelezionate;
DocumentistdCtrl.prototype.rigaPagamentoInvalida = rigaPagamentoInvalida;
DocumentistdCtrl.prototype.sommarate = sommarate;
DocumentistdCtrl.prototype.eliminaratesel = eliminaratesel;
DocumentistdCtrl.prototype.blurPagamento = blurPagamento;
DocumentistdCtrl.prototype.setPagamento = setPagamento;
DocumentistdCtrl.prototype.resetPagamento = resetPagamento;
DocumentistdCtrl.prototype.initGridPagamento = initGridPagamento;
DocumentistdCtrl.prototype.apriPopupPagamento = apriPopupPagamento;
DocumentistdCtrl.prototype.getPagamenti = getPagamenti;
DocumentistdCtrl.prototype.generaRighePagamenti = generaRighePagamenti;
DocumentistdCtrl.prototype.resetMetodo = resetMetodo;
DocumentistdCtrl.prototype.setMetodo = setMetodo;
DocumentistdCtrl.prototype.apriPopupMetodo = apriPopupMetodo;

/**
 * @description
 * Inserisce una nuova riga pagamento all'indice specificato. 
 * Se non specificato la riga inserita sarà la prima.
 * Di default la riga è popolata con data odierna, descrizione 'SALDO' e importo rimanente per arrivare a totpagamento
 * @param {int | undefined} indice 
 */
function nuovaRigaPagamento(indice) {
    var self = this;
    var rata = self.modeltestata.totpagamento - self.sommarate();
    var scadobj = { datascadenza: self.oraist(), descrizione: 'A saldo', totale: rata }
    if (indice === null || typeof indice === 'undefined') {
        self.modeltestata.scadenzepagamenti.unshift(scadobj);
    }
    else {
        self.modeltestata.scadenzepagamenti.splice(indice + 1, 0, scadobj);
    }
}

/**
 * @returns {int} //numero di righe pagamento selezionate
 */
function contaRighePagamentoSelezionate() {
    var self = this;

    if (self.selrate === null || typeof self.selrate === 'undefined') {
        return 0;
    }

    return Object.keys(self.selrate)
        .filter(function (sel) {
            return sel !== false && sel !== null && typeof sel !== 'undefined'
        }).length;
}

/**
 * @description
 * Conta il numero di righe scadenze non valide (internamente usa rigaPagamentoInvalida)
 * @returns {int} //numero di righe pagamenti non valide
 */
function contaRighePagamentoInvalide() {
    var self = this;
    return self.modeltestata.scadenzepagamenti.reduce(function (conteggio, pagamento, indice) {
        if (self.rigaPagamentoInvalida(indice)) conteggio += 1;
        return conteggio;
    }, 0)
}

/**
 * @description
 * Indica se la riga scadenza all'indice è valida
 * @param {int} indice 
 * @returns {bool} //true se riga non è valida
 */
function rigaPagamentoInvalida(indice) {
    var self = this;
    var rata = self.testata['scadenzepagamenti_' + indice + '_totale'];
    //var descr = self.testata['scadenzepagamenti_' + indice + '_descrizione'];
    var scadenza = self.testata['scadenzepagamenti_' + indice + '_datascadenza'];

    //console.log("============>rata", rata);
    //console.log("============>rata", descr);
    //console.log("============>scadenza", scadenza);


    if (typeof rata === 'undefined' || typeof scadenza === 'undefined'){// || typeof descr === 'undefined') {
        return true;
    }

    return rata.$invalid || scadenza.$invalid; // || descr.$invalid;
}

function sommarate() {
    var self = this;
    return self.modeltestata.scadenzepagamenti.reduce(function (tot, scadenza) { return tot + Number(scadenza.totale) }, 0)
}

function eliminaratesel() {
    var self = this;
    var listaindici = Object.keys(self.selrate).filter(function (indice) {
        return self.selrate[indice] === true;
    });

    console.log("lista indici da cancellare", listaindici);
    self.modeltestata.scadenzepagamenti = self.modeltestata.scadenzepagamenti.filter(function (scadenza, indiceScadenza) {
        return listaindici.indexOf("" + indiceScadenza) === -1;
    })
    self.selrate = {};
    self.eliminarateconfirmwin.close()
}


function blurPagamento($event) {
    var self = this;

    if (self.modeltestata.tipodoc === null || typeof self.modeltestata.tipodoc === 'undefined') {//blur da anagrafica per reset tipodoc
        return;
    }

    self.getPagamenti().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setPagamento(opzioni[0])
        } else {
            $event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOPAGAMENTOFOUND", "warning");
                self.resetPagamento()
            }
            if (self.modeltestata.tipodoc !== null && typeof self.modeltestata.tipodoc !== 'undefined') {//blur da anagrafica per reset tipodoc
                self.apriPopupPagamento()
            }
        }
    })
    //se il datasource contiene un solo elemento
}


/**
 * aggiorna il termine di ricerca per pagamento e il model
 * @param {*} paramento 
 */
function setPagamento(pagamento) {
    var self = this;
    if(typeof pagamento !== 'undefined' && pagamento !== null){
        self.modeltestata.pagamento = pagamento;
        self.modeltestata._pagamento = pagamento.descrizione || pagamento.label;
        self.pagamentowin.close();
        self.disabilitaPagamento = true;
    }
    
}


function resetPagamento() {
    var self = this;
    self.modeltestata.pagamento = null;
    self.modeltestata._pagamento = null;
    self.disabilitaPagamento = false;
}

/**
 * @description
 * Inizializzo la griglia degli agenti
 */
function initGridPagamento() {
    var self = this;
    var strentity = "tmodpag";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setPagamento(dataItem);
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, null, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, null, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.testata.pagamento.$viewValue;
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }
            /*gridcfg.columns = gridcfg.columns.map(function(colonna){
                if(["ragsoc", "partitaiva", "codice", "nome", "cognome", "codicefiscale", "listino"].indexOf(colonna.field) === -1){
                    colonna.hidden = true;
                }
                return colonna
            })*/
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridPagamento !== 'undefined') {
                    self.gridPagamento.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliAnagrafiche = self.gridPagamento.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridPagamentoOpts = angular.extend({}, gridcfg);
            self.gridPagamentoRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupPagamento() {
    var self = this;
    self.pagamentowin.open().center();
    self.initGridPagamento()
}

/**
 * Ritorna una lista di al più 2 pagamenti
 * Posso usarlo al blur da pagamento e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 * @returns {Promise<AnagraficheStd<TipoDoc>>} 
 */
function getPagamenti() {
    var self = this;
    var term = self.testata.pagamento.$viewValue;
    var strentity = "tmodpag";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibiliPagamenti = resp.data.results;
        return self.possibiliPagamenti
    })
}

function generaRighePagamenti() {
    var self = this;
    var params = {
        idmetodo: self.modeltestata.pagamento.id,
        datadoc: moment(self.modeltestata.datadoc).format("YYYY-MM-DD"), 
        importo: self.modeltestata.totpagamento
    };

    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/tmodpag/calcolascadenze",
        data: $.param(params)
    }).then(function (resp) {
        /**
         * non toccare l'utente modificherà solo scadenzepagamenti in questo modo posso sempre sapere se ci sono modifiche
         */
        resp.data = resp.data.map(function(scad){
            scad.datascadenza = moment(scad.datascadenza, "YYYYY-MM-DD").toDate();
            return scad;
        })
        self.scadenzeprecalcolate = angular.copy(resp.data);
        self.disabilitaMetodo = {};
        self.modeltestata.scadenzepagamenti = angular.copy(resp.data).map(function(scadenza, indicescadenza){
            self.disabilitaMetodo[indicescadenza] = true;
            return angular.extend({}, scadenza, {_metodo: scadenza.metodo.label})
        });
        if (self.modeltestata.scadenzepagamenti.length === 0) {
            self.nuovaRigaPagamento();
        }
    })

    // function (err) {
    //     console.warn("Scadenze finte. Test!!!")
    //     self.scadenzeprecalcolate = [];
    //     self.modeltestata.scadenzepagamenti = [];
    //     self.nuovaRigaPagamento();
    // }
}

function resetMetodo(indice){
    var self = this;
    self.modeltestata.scadenzepagamenti[indice].metodo = null;
    self.modeltestata.scadenzepagamenti[indice]._metodo = null;
    self.disabilitaMetodo[indice] = false;
    self.filtrometodi = "";
    self.metodifiltrati = angular.copy(self.metodi);
}

function setMetodo(metodo, indice){
    var self = this;

    self.modeltestata.scadenzepagamenti[indice].metodo = angular.copy(metodo);
    self.modeltestata.scadenzepagamenti[indice]._metodo = angular.copy(metodo.descrizione);
    self.disabilitaMetodo[indice] = true;

    self.metodoscadenzawin.close()
}

function apriPopupMetodo(indice){
    
    var self = this;
    
    self.indicescadenza = indice;

    self.filtrometodi = self.testata['scadenzepagamenti_'+indice+'_metodo'].$viewValue;

    var res = self.$filter('filter')(self.metodi, self.filtrometodi);

    if(res.length === 1){
        self.setMetodo(res[0], indice);
    } else {
        if(res.length === 0){
            self.metodifiltrati = angular.copy(self.metodi);
        } else {
            self.metodifiltrati = angular.copy(res);
        }
        self.metodoscadenzawin.open().center();
    }
    
}

/**
 * ===================================================================== Fine Pagamenti =======================================================================================================
 */

/**
 * Righe Documento
 */
DocumentistdCtrl.prototype.ivarighecalcolata = ivarighecalcolata;
DocumentistdCtrl.prototype.popolaArticoloDaSostitutivo = popolaArticoloDaSostitutivo;
DocumentistdCtrl.prototype.vaiModificaRiga = vaiModificaRiga;
// DocumentistdCtrl.prototype.vaiAnteprimaRiga = vaiAnteprimaRiga;
DocumentistdCtrl.prototype.ripristinaRighe = ripristinaRighe;
DocumentistdCtrl.prototype.vaiAggiungiRiga = vaiAggiungiRiga;
DocumentistdCtrl.prototype.salvaRiga = salvaRiga;
DocumentistdCtrl.prototype.buildRigaArticoloInModificaDocumento = buildRigaArticoloInModificaDocumento;
DocumentistdCtrl.prototype.buildRigaDescrittivaInModificaDocumento = buildRigaDescrittivaInModificaDocumento;
DocumentistdCtrl.prototype.buildRigaTrasportoInModificaDocumento = buildRigaTrasportoInModificaDocumento;
DocumentistdCtrl.prototype.vaiInSostituzione = vaiInSostituzione;
DocumentistdCtrl.prototype.esciSostituzione = esciSostituzione;
DocumentistdCtrl.prototype.toggleModeSostituzione = toggleModeSostituzione;
DocumentistdCtrl.prototype.confermaSostituzione = confermaSostituzione;
DocumentistdCtrl.prototype.sostituisciSelezione = sostituisciSelezione;

/**
 * @typedef {any} IvaCalcolata
 * 
 * L'iva delle righe può essere calcolata a partire dal documento 
 * Questo metodo calcola l'hash dell'oggetto che rappresenta il documento
 * E popola un oggetto che fa corrispondere all'hash l'iva calcolata.
 * Se l'iva per l'hash è già stata calcolata la ritorna, altrimenti la calcola e la ritorna
 * @return {Promise<IvaCalcolata>}
 */
function ivarighecalcolata(){
    var self = this;

    var documento = self.generaOrdine();
    self.currenthash = crypto.createHash("md5").update(JSON.stringify(documento.testata)).digest("hex");
    var ic = self.ivacalcolata[self.currenthash];
    if(typeof ic === 'undefined' || ic === null){
        return self.$http({
            url: utils.urls.wsbase + "/documentirdc/calcolavalori",
            method: "POST",
            data: $.param({documento: JSON.stringify(documento)})
        }).then(function(resp){
            self.ivacalcolata[self.currenthash] = resp.data
            return resp.data;
        }, function(err){
            self.em.errorCB(err)
        })
    } else {
        return self.$q.when(ic)
    }
}
/**
 * Si occupa di costruire la riga articolo documento da modificare, mappando la riga ricevuta dal server e che non è di default compatibile con il form
 */
function buildRigaArticoloInModificaDocumento(riga){
    var self = this;

    var idriga = riga.id;
    var progressivo = riga.progressivoRiga;
    var codart = riga.articolo.codarticolo;
    var descrart = riga.descrizione;

    //(bssRecursiveGetter(riga, codartforn.id", null) !== null)

    var idcodartforn = riga.codartforn.id;
    var codartforn = riga.codartforn.codartforn;                    
    var idforn = riga.codartforn.fornitore.id;
    var ragsocforn = riga.codartforn.fornitore.label;
    var codforn = riga.codartforn.fornitore.codice || ragsocforn;

    var imponibiletotale = riga.imponibiletotale;
    var imponibileUnitLordo = riga.imponibileUnitLordo;     
    var imponibiledin = valuefordinero({valore: imponibileUnitLordo});
    var impostaunitario = 0;
    var ivatounitario = 0;

    if(!self.nou(riga.iva)){
        var idiva = riga.iva.id;
        var codiva = riga.iva.codice;
        var labeliva = riga.iva.label || riga.iva.codice;
        var aliquota = riga.iva.aliquota || 22;

        var impostadin = imponibiledin.multiply(aliquota/100);
        var ivatodin = imponibiledin.add(impostadin)
        var impostaunitario = impostadin.toUnit();
        var ivatounitario = ivatodin.toUnit();
    } else {
        alert("Attenzione, iva non disponibile per il record selezionato!");
        console.error("niente iva per il record selezionato, è un anomalia???");
    }   

    var labelum = riga.um.label;
    var articolo = {
        id: riga.articolo.id,
        codarticolo: codart,
        descrizione: descrart,
        label: codart + " - " + descrart,
        linkFoto: riga.articolo.linkFoto || "https://www.rdc-online.it/rdc/_upload/"+codart+".jpg",
        note: riga.articolo.note || "",
    }
    var iva = {
        id: idiva,
        codice: codiva,
        label: labeliva,
        aliquota: aliquota,
    };
    var fornitore = {
        id: idforn,
        codice: codforn,
        ragsoc: ragsocforn
    }

                  
    
    
    
    
    var prezzoacquisto = riga.prezzoacquisto || 0;
    var quantita = riga.quantita;
    var sconto1 = riga.sconto1;
    var sconto2 = riga.sconto2;
    var tipo = riga.tipo;
    var um = riga.um;

    var res = angular.extend({}, riga, {
        id: idriga,
        progressivoRiga: progressivo,
        _articolo: codart + " - " + descrart,
        _articoloparts: {'Cod. Articolo': codart, 'Descrizione': descrart},
        _codartforn: codartforn + " presso " + codforn + " - " + ragsocforn,
        _codartfornparts: {'Cod. Art. Fornitore': codartforn, "Fornitore": codforn + " - " + ragsocforn},
        _codartfornparts3: {codartforn: codartforn, codforn: codforn, ragsocforn: ragsocforn },
        _iva: codiva,
        _um: labelum,
        articolo: articolo,
        codartforn: codartforn,
        descrizione: descrart,
        idcodartforn: idcodartforn,
        iva: iva,
        fornitore: fornitore,
        imponibiletotale: imponibiletotale,
        imponibileUnitLordo: imponibileUnitLordo,
        impostaunitario: impostaunitario,
        ivatounitario: ivatounitario,
        prezzoacquisto: prezzoacquisto,
        quantita: quantita,
        rigaOrigine: riga.rigaOrigine,
        sconto1: sconto1,
        sconto2: sconto2,
        tipo: tipo,
        um: um,
    });

    return res;
}


/**
 * Si occupa di costruire la riga descrittiva documento da modificare, mappando la riga ricevuta dal server e che non è di default compatibile con il form
 * NB. alcune righe presentano l' annotazione @atd che indica parametro non ottenuto, devo chiedere a daniele se è possibile riceverli
 */
function buildRigaDescrittivaInModificaDocumento(riga){
    
    var idriga = riga.id;
    var progressivo = riga.progressivoRiga;

    // var codart = riga.articolo.codice;
    var descrart = riga.descrizione;
    var idcodartforn = riga.codartforn.id;
    var codartforn = riga.codartforn.codartforn;                    
    var idforn = riga.codartforn.fornitore.id;
    var ragsocforn = riga.codartforn.fornitore.label;
    var codforn = riga.codartforn.fornitore.codice || ragsocforn;
    var idiva = riga.iva.id;
    var codiva = riga.iva.codice;
    var labeliva = riga.iva.label || riga.iva.codice;
    var aliquota = riga.iva.aliquota || 22;
    var labelum = riga.um.label;
    var iva = {
        id: idiva,
        codice: codiva,
        label: labeliva,
        aliquota: aliquota,
    };
    var fornitore = {
        id: idforn,
        codice: codforn,
        ragsoc: ragsocforn
    }

    var imponibiletotale = riga.imponibiletotale;
    var imponibileUnitLordo = riga.imponibileUnitLordo;                    
    
    
    var imponibiledin = valuefordinero({valore: imponibileUnitLordo});
    var impostadin = imponibiledin.multiply(aliquota/100);
    var ivatodin = imponibiledin.add(impostadin)
    
    var impostaunitario = impostadin.toUnit();
    var ivatounitario = ivatodin.toUnit();
    var prezzoacquisto = riga.prezzoacquisto || 0;
    var quantita = riga.quantita;
    var sconto1 = riga.sconto1;
    var sconto2 = riga.sconto2;
    var tipo = riga.tipo;
    var um = riga.um;

    var res = angular.extend({}, riga, {
        id: idriga,
        progressivoRiga: progressivo,
        // _articolo: codart + " - " + descrart,
        // _articoloparts: {'Cod. Articolo': codart, 'Descrizione': descrart},
        _codartforn: codartforn + " presso " + codforn + " - " + ragsocforn,
        _codartfornparts: {'Cod. Art. Fornitore': codartforn, "Fornitore": codforn + " - " + ragsocforn},
        _codartfornparts3: {codartforn: codartforn, codforn: codforn, ragsocforn: ragsocforn },
        _iva: codiva,
        _um: labelum,
        // articolo: articolo,
        codartforn: codartforn,
        descrizione: descrart,
        idcodartforn: idcodartforn,
        iva: iva,
        fornitore: fornitore,
        _fornitore: codforn + " - " + ragsocforn,
        imponibiletotale: imponibiletotale,
        imponibileUnitLordo: imponibileUnitLordo,
        impostaunitario: impostaunitario,
        ivatounitario: ivatounitario,
        prezzoacquisto: prezzoacquisto,
        quantita: quantita,
        rigaOrigine: riga.rigaOrigine,
        sconto1: sconto1,
        sconto2: sconto2,
        tipo: tipo,
        um: um,
    });

    return res;
}

/**
 * @todo sistemare
 * @param {*} riga 
 * @returns 
 */
function buildRigaTrasportoInModificaDocumento(riga){
    
    var idriga = riga.id;
    var progressivo = riga.progressivoRiga;

    var descrart = riga.descrizione;
    var idiva = riga.iva.id;
    var codiva = riga.iva.codice;
    var labeliva = riga.iva.label || riga.iva.codice;
    var aliquota = riga.iva.aliquota || 22;
    var iva = {
        id: idiva,
        codice: codiva,
        label: labeliva,
        aliquota: aliquota,
    };
   
    var imponibiletotale = riga.imponibiletotale;
    var imponibileUnitLordo = riga.imponibileUnitLordo;                    
    
    
    var imponibiledin = valuefordinero({valore: imponibileUnitLordo});
    var impostadin = imponibiledin.multiply(aliquota/100);
    var ivatodin = imponibiledin.add(impostadin)
    
    var impostaunitario = impostadin.toUnit();
    var ivatounitario = ivatodin.toUnit();
    var prezzoacquisto = riga.prezzoacquisto || 0;
    var quantita = riga.quantita;
    var sconto1 = riga.sconto1;
    var sconto2 = riga.sconto2;
    var tipo = riga.tipo;
    var um = riga.um;

    var res = angular.extend({}, riga, {
        id: idriga,
        progressivoRiga: progressivo,
        _iva: codiva,
        descrizione: descrart,
        iva: iva,
        imponibiletotale: imponibiletotale,
        imponibileUnitLordo: imponibileUnitLordo,
        impostaunitario: impostaunitario,
        ivatounitario: ivatounitario,
        prezzoacquisto: prezzoacquisto,
        quantita: quantita,
        rigaOrigine: riga.rigaOrigine,
        sconto1: sconto1,
        sconto2: sconto2,
        tipo: tipo,
        um: um,
    });

    return res;
}

function vaiInSostituzione(rigasel){
    var self = this;
    self.bloccato = false;
    self.modelrigadoc.anteprima = false;
    self.insostituzioneriga = true;
    self.rigasostituita = rigasel;
}

function esciSostituzione(){
    var self = this;
    self.bloccato = true;
    self.modelrigadoc.anteprima = true;
    self.insostituzioneriga = false;
    self.rigasostituita = null;
}

/**
 * attiva/disattiva la modalità sostituzione.
 * Quando si attiva:
 *  1. la selezione si svuota
 *  2. il tipo di selezione diventa 'row'
 *  3. nascondo la colonna delle checkbox 
 * 
 * Quando si disattiva:
 *  1. la selezione si svuota
 *  2. il tipo di selezione diventa 'multiple, row'
 *  3. mostro la colonna delle checkbox
 */
function toggleModeSostituzione(){
    var self = this;
    if(self.righeInSostituzione){
        
        self.gridRighe.clearSelection();
        self.gridRighe.setOptions({
            selectable: 'row',
            columns: self.gridRighe.options.columns.slice(1)
        })
        self.righeInSostituzione = false;

    } else {
        var nuovecolonne = self.gridRighe.options.columns.slice();
        nuovecolonne.unshift({selectable: true, widht: '40px'});

        self.gridRighe.clearSelection();
        self.gridRighe.setOptions({
            selectable: 'multiple, row',
            columns: nuovecolonne
        })
        self.righeInSostituzione = true;
    }
}

function confermaSostituzione(){
    var self = this;
    self.confsostwin.open().center();
}

function sostituisciSelezione(){
    var self = this;
    var ids = self.righeSel.map(function(riga){return riga.id});
    console.log("vado in sostituzione per gli id", ids);
    self.$http({
        method: 'POST',
        url: utils.urls.wsbase + "/documentistd/sostituzione",
        data: $.param({idrighe: JSON.stringify(ids)})
    }).then(function(resp){
        self.$state.go("documentistd", {raggrdoc: self.raggruppamento, iddoc: resp.data.id}, {reload: true})
    }, function(err){
        self.em.errorCB(err);        
    });    
}

/**
 * Quando vado in modifica di un record sostitutivo la prima volta l'utente vede solo il campo articolo popolato
 * Per popolare il fornitore dovrebbe svuotarlo, ricordarsi qual'è l'articolo e ricercarlo.
 * Questa procedura fa partire in automatico la ricerca per il codice articolo
 */
function popolaArticoloDaSostitutivo(){
    var self = this;
    var rigaorigine = self.modelrigadoc.rigaOrigine;
    self.modelrigadoc._articolo = rigaorigine.articolo.codarticolo;
    self.modelrigadoc._codartfornparts = null;
    self.blurrigadocArticolo();
}

/**
 * 
 * @param {Boolean} soloAnteprima se true la riga verrà aperta in anteprima cioè non saranno presenti i tasti per il salvataggio delle modifiche
 * e un overlay trasparente non permetterà all'utente di modificare il valore dei campi
 */
function vaiModificaRiga(soloAnteprima, inSostituzione){
    var self = this;

    //self.ivarighecalcolata().then(function(resp){
        self.righewin.close();
        
        var rigasel = self.righeSel[0]; 

        var idsel = rigasel.id;
        var riga = !inSostituzione ? rigasel : angular.extend({}, rigasel, {id: null, sostituisce: idsel});
        var tipo = riga.tipo;
        self.tipoRigaSel = tipo;

        var imrighe = self.imrighe;
        console.log("righe tmp", self.imrighe);
        var rigainimrighe = imrighe.find(function(imriga){
            return riga.uid === imriga.uid;
        });

        if(self.documentoInModifica && (riga.id !== 0) && (rigainimrighe === undefined)){
            switch(tipo.value){
                case 'A':                                         
                    self.modelrigadoc = self.buildRigaArticoloInModificaDocumento(riga);
                    break;
                case 'D':
                    self.modelrigadoc = self.buildRigaDescrittivaInModificaDocumento(riga);
                    break;
                case 'T':
                    self.modelrigadoc = self.buildRigaTrasportoInModificaDocumento(riga);
                    break;    
                default: break;
            }
        } else {
            self.modelrigadoc = riga;
        }

        
        if(soloAnteprima === true){
            self.modelrigadoc.anteprima = true;
            console.log("vai anteprima riga", riga, self.modelrigadoc);
        } else {
            console.log("vai modifica riga", riga, self.modelrigadoc);

        }

        if(inSostituzione === true){
            self.vaiInSostituzione(angular.copy(rigasel))
        } else {
            self.insostituzioneriga = false;
        }

        self.disabilitarigadocArticolo = true;
        self.disabilitarigadocCodartforn = true;
        self.disabilitarigadocIVA = true;

        var idcodartfornori = bssRecursiveGetter(self, "modelrigadoc.rigaOrigine.codartforn.id", null)
        var idartori = bssRecursiveGetter(self, "modelrigadoc.rigaOrigine.codartforn.articolo.id", null)
        var idfornori = bssRecursiveGetter(self, "modelrigadoc.rigaOrigine.codartforn.fornitore.id", null)
        if(idcodartfornori !== null){
            self.sostitutivipercodartforn[idcodartfornori] = "loading"; 
            self.$http({
                // url: utils.urls.wsbase + "/documentirdc/sostitutivi/" + idartori + "/" + idfornori,
                // method: "GET",
                url: utils.urls.wsbase + "/vartforn/grid",
                method: "POST",
                data: $.param({
                    take: 100,
                    skip: 0,
                    page: 1,
                    pageSize: 100,
                    idarticolo: idartori,
                    altroidfornitore: idfornori,
                    idlistino: self.modeltestata.listino
                })
            }).then(function(resp){
                self.sostitutivipercodartforn[idcodartfornori] = resp.data.results;
            }, function(err){
                self.sostitutivipercodartforn[idcodartfornori] = null;
            })
        }
        
        

        self.righewin.open().center();
    //    self.getAssAF("articolo")
    //})

    
}

/**
 * @deprecated
 * é stata unificata a vaiModificaRiga
 */
// function vaiAnteprimaRiga(){
//     var self = this;

//     //self.ivarighecalcolata().then(function(resp){
//         self.righewin.close();
//         var riga = self.righeSel[0];
//         var tipo = riga.tipo;
//         self.tipoRigaSel = tipo;
//         self.modelrigadoc = riga;
//         self.modelrigadoc.anteprima = true;
//         self.disabilitarigadocArticolo = true;
//         self.disabilitarigadocCodartforn = true;
//         self.disabilitarigadocIVA = true;
//         self.righewin.open().center();
//     //    self.getAssAF("articolo")
//     //})

    
// }

/**
 * @description
 * sposta le righe da erighe ad imrighe
 */
function ripristinaRighe(){
    var self = this;
    var uidsel = self.righeSel.map(function(riga){return riga.uid});
    //rimuovo la selezione da erighe
    self.erighe = self.erighe.filter(function(riga){return uidsel.indexOf(riga.uid) === -1 })
    //aggiungo la selezione in imrighe
    self.righeSel.forEach(function(riga){
        self.imrighe.push(riga);
    })
    self.evidenziaRighe();


}

function vaiAggiungiRiga(tipo) {
    var self = this;


    self.ivarighecalcolata().then(function(resp){
        self.righewin.close();

        self.tipoRigaSel = tipo;
        self.modelrigadoc = {};

        self.setIvaRiga(resp);
        if(tipo.value !== 'A'){//prepopolo imponibile,quantita,sconto1,sconto2 e ricalcolo
            self.modelrigadoc.quantita = 1;
            self.modelrigadoc.sconto1 = 0;
            self.modelrigadoc.sconto2 = 0;
            self.modelrigadoc.imponibileUnitLordo = 0;            
        }  
        
        if(tipo.value === 'D'){
            self.modelrigadoc.pesokg = 2;
            self.setUmRiga(self.umpezzi);
        }

        self.disabilitaRigaFornitore = false;
        self.disabilitarigadocIVA = (typeof self.modelrigadoc.iva !== 'undefined' && self.modelrigadoc.iva !== null ) ? true : false;
        //self.disabilitarigadocUm = false;

        if(typeof self.rigadoc !== 'undefined'){
            self.rigadoc.$setUntouched();
            self.rigadoc.$setPristine();
        }

        if(tipo.value === 'T'){
            console.log("info", self.info, self.nou(self.info), self.nou(self.info.trasportoDescrizione))
            if(!self.nou(self.info) && !self.nou(self.info.trasportoDescrizione)){
                console.log("aggiorno descrizione trasporto in ", self.info.trasportoDescrizione)
                self.modelrigadoc.descrizione = self.info.trasportoDescrizione;
            }
        }

        self.righewin.open().center();
    })


    
}

function salvaRiga(tipo){
    var self = this;
    

    if(self.rigadoc.$invalid){
        self.rigadoc.$setSubmitted();
        //self.rigadoc.$setDirty()
        self.em.showT("DOCUMENTISTD.INVALIDRIGA", "error")
        return;
    }    
    self.modelrigadoc.tipo = self.tipoRigaSel;
    self.modelrigadoc.testata = {id: self.modeltestata.id};

    console.log("uid modelrigadoc da salvariga", self.modelrigadoc.uid);
    if(typeof self.modelrigadoc.uid !== 'undefined'){
        self.editRigaLocally();
    } else {
        self.addRigaLocally();
    }
    

    self.resetAssAF();
    self.righewin.close();
    if(self.insostituzioneriga === true){
        self.esciSostituzione();
    }
    if(typeof tipo !== 'undefined'){
        self.vaiAggiungiRiga(tipo)
    }
}

/**
 * Articolo in Righe di tipo Articolo (A)
 */
DocumentistdCtrl.prototype.copiadescrizionedacodice = copiadescrizionedacodice;
DocumentistdCtrl.prototype.initGridRighe = initGridRighe;
DocumentistdCtrl.prototype.blurrigadocArticolo = blurrigadocArticolo;
DocumentistdCtrl.prototype.blurrigadocCodartforn = blurrigadocCodartforn;
DocumentistdCtrl.prototype.blurrigadocIVA = blurrigadocIVA;
DocumentistdCtrl.prototype.svuotaIvaRiga = svuotaIvaRiga;
DocumentistdCtrl.prototype.setIvaRiga = setIvaRiga;
DocumentistdCtrl.prototype.blurrigadocUm = blurrigadocUm;
DocumentistdCtrl.prototype.svuotaUmRiga = svuotaUmRiga;
DocumentistdCtrl.prototype.setUmRiga = setUmRiga;
DocumentistdCtrl.prototype.ricalcolaIvato = ricalcolaIvato;
DocumentistdCtrl.prototype.ricalcolaImponibile = ricalcolaImponibile;
DocumentistdCtrl.prototype.ricalcolaTotaleRiga = ricalcolaTotaleRiga;
DocumentistdCtrl.prototype.addRigaLocally = addRigaLocally;
DocumentistdCtrl.prototype.editRigaLocally = editRigaLocally;
DocumentistdCtrl.prototype.deleteRigheLocally = deleteRigheLocally;
DocumentistdCtrl.prototype.evidenziaRighe = evidenziaRighe;
DocumentistdCtrl.prototype.disabilitaAggiungiRiga = disabilitaAggiungiRiga;
DocumentistdCtrl.prototype.disabilitaModificaRiga = disabilitaModificaRiga;
DocumentistdCtrl.prototype.disabilitaGenParz = disabilitaGenParz;
DocumentistdCtrl.prototype.disabilitaRipristinaRighe = disabilitaRipristinaRighe;
DocumentistdCtrl.prototype.disabilitaEliminaRighe = disabilitaEliminaRighe;
DocumentistdCtrl.prototype.inRimbDa0 = inRimbDa0;
DocumentistdCtrl.prototype.rimbparz = rimbparz;
DocumentistdCtrl.prototype.toggleSelectableRighe = toggleSelectableRighe;
DocumentistdCtrl.prototype.ripulisciAltroAF = ripulisciAltroAF;
DocumentistdCtrl.prototype.blurrigadocServizio = blurrigadocServizio;
DocumentistdCtrl.prototype.setRigaDaAssAF = setRigaDaAssAF;
DocumentistdCtrl.prototype.resetAssAF = resetAssAF;
DocumentistdCtrl.prototype.getAssAF = getAssAF;
DocumentistdCtrl.prototype.initGridAssAF = initGridAssAF;
DocumentistdCtrl.prototype.creaAssAF = creaAssAF;
DocumentistdCtrl.prototype.svuotaRileggiAssAF = svuotaRileggiAssAF;
DocumentistdCtrl.prototype.stamparicevuta = stamparicevuta;

function stamparicevuta(){
    var self = this;        
    
    return self.$http({
        url: utils.urls.wsbase + "/documentirdc/stamparicevuta",
        method: 'POST',
        data: $.param({iddocs: JSON.stringify(self.$sp.iddoc)}),
    }).then(function(resp){

        var res = angular.extend({}, resp);
        if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
            res = angular.extend({}, res, {data: resp.data.bssmessages});
        }
        
        self.em.errorCB(res);
        
        
    }, function(err){
        self.em.errorCB(err);
    });
}

function inRimbDa0(){
    var self = this;
    var raggruppamento = self.raggruppamento;
    var esito = (raggruppamento === "RIMB");
    var nrighe = bssRecursiveGetter(self, "gridRighe.dataSource._data.length", 0);
    esito = esito && (nrighe === 0);
    return esito;
}

function rimbparz(){
    var self = this;
    var idrighe = self.righeSel.map(function(riga){return riga.id});
    console.log("genero documento rimb/nc parziale dalle righe con id", idrighe)
    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/generadoc/generazioneparziale",
        data: $.param({idrighe: JSON.stringify(idrighe)})
    }).then(function(resp){

        // var res = angular.extend({}, resp);
        // if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
        //     res = angular.extend({}, res, {data: resp.data.bssmessages});
        // }            
        //self.em.errorCB(res);

        self.confrimbparzwin.close();
        self.gridRighe.dataSource.read();
        


        var raggruppamentoRedirect = "RIMB";
        // if(["ORD"].includes(self.raggruppamento)){
        //     raggruppamentoRedirect = "RIMB";
        // }
        var esitofatt = self.righeSel.find(function(riga){
            return riga.statoriga.codice === 'FATT';
        })

        if(!self.nou(esitofatt)){
            raggruppamentoRedirect = "MACRONCC";
        }

        if(raggruppamentoRedirect === 'RIMB'){
            self.salvaOrdine(raggruppamentoRedirect);
        } else {
            //recupero il raggruppamento dal documento appena generato
            var raggruppamentosalva = bssRecursiveGetter(resp, "data.testata.tipodoc.raggruppamento.label", raggruppamentosalva);
            var iddoc = bssRecursiveGetter(resp, "data.id", null);

            self.$state.go("documentistd", {raggrdoc: raggruppamentosalva, iddoc: iddoc}, {reload: true})
        }

        
    }, function(err){
        self.em.errorCB(err)
    })

}

function toggleSelectableRighe(){
    var self = this;
    var label = "GRIDNAV." + (self.gridRighe.options.selectable === "row" ? "SINGLESELENABLED" : "MULTISELENABLED");

    var currentmode = self.gridRighe.options.selectable;
    var mode =  ((currentmode === "row") ? "multiple, row" : "row");

    var opzioni = {
        selectable: mode,
    };


    self.gridRighe.setOptions(opzioni);

    
    self.em.showT(label, "success");
    
    
}

function copiadescrizionedacodice($event){
    var self = this;
    $event.stopPropagation();
    self.disabilitadescrizioneriga = true;

    var params = { page: 1, pageSize: 1, take: 1, skip: 0};
    params.codarticolo = self.modelrigadoc.descrizione;        

    //params[campoinput[da]] = self.rigadoc[da] ? self.rigadoc[da].$viewValue : "";
    params.idlistino = self.modeltestata.listino

    self.$http({
        url: utils.urls.wsbase + "/vartforn/grid",
        method: "POST",
        data: $.param(params)
    }).then(function(resp){
        self.disabilitadescrizioneriga = false;
        console.log("resp", resp.data)
        if(resp.data.results.length){//almeno un risultato posso estrarre la label come faccio per _articolo
            self.modelrigadoc.descrizione = resp.data.results[0].articolo.codarticolo + " - " + resp.data.results[0].articolo.descrizione;
        } else {
            self.em.showT("DOCUMENTISTD.NODESCRBYCODE", "warning")
        }
    }, function(err){
        self.disabilitadescrizioneriga = false;
        console.log("err", err);
    })
}

function initGridRighe(){
    var self = this;
    var strentity = "righedocumentistd";
    var entity = "com.bsssrl.bssstdgestent.RigaDocumento";

    var opzAdd = {                    
        serverPaging: false,                    
        pageSize: 999999,
        transport:{
            read: {
                url: utils.urls.wsbase + "/" + strentity + "/righetestata", 
                data: function(){        
                    var parametri = {
                        idtestata: self.modeltestata.id || null
                    };                                                            
                    return parametri;                                                            
                }, 
                method: 'POST' 
            }
        }
    };


    return new self.dsf(strentity, null, self.raggruppamento, opzAdd).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, {height: "auto"}, true).inizializza().then(function (gridcfg) {            
            //gridcfg.filterable = { mode: "row"};
            //gridcfg.columns.unshift({selectable: true, width: "40px"});
            
            gridcfg.pageable = false;
            gridcfg.sortable = false;
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                //console.log("databound grid righe");
                if (typeof self.gridRighe !== 'undefined') {
                    
                    var griglia = e.sender;

                    griglia.element.find(".k-grid-content").height("auto");

                    console.log("prima di aggiornare le righe del castelletto ==========>", self.modelfooter.castelletto);
                    if(self.raggruppamento === 'MACRONCC' && self.iddoc){
                        var righecastelletto = bssRecursiveGetter(self, "modelfooter.castelletto.righe", [])
                        if(!Array.isArray(righecastelletto) || !righecastelletto.length){
                            //non può esistere la nota credito senza righe castelletto, imposto un flag che indica che devo salvare il documento per adeguarlo sul db
                            self.adeguadocrighecastelletto = true;
                        }
                    }
                    
                    self.aggiornaRigheCalcTotali();
                    
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        //selezionaDaGriglia(e, griglia)
                        if(self.bloccato){
                            self.vaiModificaRiga(true);
                        } else {
                            if(self.sonoPresentiCorrieri()){
                                self.eliminacorriericonfirmwin.open().center()
                            } else {
                                self.vaiModificaRiga()
                            }
                        }
                        
                    })
                            
                }
            },
            gridcfg.change = function(e){
                self.$timeout(function(){
                    var griglia = e.sender;
                    var nuovaSel = $.makeArray(griglia.select()).map(function(elriga){
                        return griglia.dataItem(elriga)
                    })
                    if(nuovaSel != self.righeSel){
                        self.righeSel = angular.copy(nuovaSel);
                        if(self.righeSel.length === 1 && !self.nou(self.righeSel[0].articolo)){ 
                            self.idarticolosel = self.righeSel[0].articolo.id;
                        } else {
                            self.idarticolosel = null;
                        }
                    }
                    
                })
            }

            self.gridRigheOpts = angular.extend({}, gridcfg);
            self.gridRigheRebind = moment().format();
            return self.$q.resolve(self.gridRigheOpts)
        })
    });
}

/**
 * @description
 * Dato il nome di un campo ripulisce l'altro 
 * Usato per fare in modo che l'utente acceda alle associazioni partendo da articolo O da codartforn (mai da entrambe)
 * @param {'articolo' | 'codartforn'} da 
 */
function ripulisciAltroAF(da){
    var self = this;
    var mappaaltro = {
        articolo: 'codartforn',
        codartforn: 'articolo'
    };
    var altro = mappaaltro[da];
    // console.log("interazione con il campo ", da);
    // if(typeof self.rigadoc[altro] !== 'undefined' && self.rigadoc[altro] !== null){
    //     console.log("ripulisco il viewvalue di", altro);
    //     self.rigadoc[altro].$setViewValue("");
    //     self.rigadoc[altro].$render();
    // }
    if(typeof self.modelrigadoc !== 'undefined' && self.modelrigadoc !== null){
        console.log("ripulisco il model di", altro);
        self.modelrigadoc["_"+altro] = "";
        self.modelrigadoc[altro] = "";
    }
}

function blurrigadocServizio($event){
    var self = this;

    // if(self.rigadoc.articolo.$invalid){
    //     console.warn("non procedo correggi prima gli errori per articolo");
    //     return;
    // } 

    var da = 'servizio';    
    
    self.ricercaInCorso = true;

    self.getAssAF(da).then(function (opzioni) {
        self.ricercaInCorso = false;

        var res = opzioni.data.results;
        //console.log("uscita dal campo articolo ho trovato", opzioni);
        if (res.length === 0) {
            self.em.showT("DOCUMENTISTD.NOSERVIZIOFOUND", "warning");
            //self.resetrigadocArticolo()
        }
        else {
            if(res.length === 1){
                self.setRigaDaAssAF(res[0])
            } else {
                $event.stopImmediatePropagation();
                self.initGridAssAF(da)
                self.assafwin.open().center();
            }
            
        }
    })
}


function blurrigadocArticolo($event){
    var self = this;

    // if(self.rigadoc.articolo.$invalid){
    //     console.warn("non procedo correggi prima gli errori per articolo");
    //     return;
    // } 

    var da = 'articolo';
    self.ripulisciAltroAF(da);
    
    self.ricercaInCorso = true;

    self.getAssAF(da).then(function (opzioni) {
        self.ricercaInCorso = false;

        //console.log("uscita dal campo articolo ho trovato", opzioni);
        var res = opzioni.data.results;
        if (res.length === 0) {
            self.em.showT("DOCUMENTISTD.NOARTICOLOFOUND", "warning");
            //self.resetrigadocArticolo()
        }
        else {
            if(res.length === 1){            
                self.setRigaDaAssAF(res[0]);
            } else {
                //$event.stopImmediatePropagation();
                self.initGridAssAF(da)
                self.assafwin.open().center();
            }            
        }
    })
}

function blurrigadocCodartforn($event){
    var self = this;
    // if(self.rigadoc.codartforn.$invalid){
    //     console.warn("non procedo correggi prima gli errori per codartforn");
    //     return;
    // }

    var da = "codartforn";
    
    self.ripulisciAltroAF(da);

    self.ricercaInCorso = true;


    self.getAssAF(da).then(function (opzioni) {
        self.ricercaInCorso = false;
        console.log("uscita dal campo codartforn, ho trovato", opzioni);
        var res = opzioni.data.results;
        if (res.length === 0) {
            self.em.showT("DOCUMENTISTD.NOCODARTFORNFOUND", "warning");
            //self.resetrigadocCodartforn()
        }
        else {
            if(res.length === 1){
                self.setRigaDaAssAF(res[0])
            } else {
                if(!self.nou($event)){
                    $event.stopImmediatePropagation();
                }
                self.initGridAssAF(da);
                self.assafwin.open().center();
            }
            
        }
    })
}

function blurrigadocIVA($event){
    var self = this;

    var res = self.$filter('filter')(self.tipiiva, self.rigadoc.iva.$viewValue);

    if(res.length === 1){
        self.setIvaRiga(res[0])
    } else {
        self.rigaivawin.open().center();        
    }

}

function svuotaIvaRiga(){
    var self = this;
    self.modelrigadoc.iva = "";
    self.modelrigadoc._iva = "";
    self.disabilitarigadocIVA = false;
    //self.rigadoc.iva.$setViewValue("");
    //self.rigadoc.iva.$render();

}

function setIvaRiga(iva){
    var self = this;
    self.modelrigadoc.iva = iva;
    self.modelrigadoc._iva = iva.codice + " - " + iva.aliquota + "%";
    self.disabilitarigadocIVA = true;
    // self.rigadoc.iva.$setViewValue("");

    /**
     * al cambio dell'iva aggiorno solo l'ivato, d'altro canto solo il campo imponibile è editabile
     */
    var objprezzo = imponibileivato({
        valore: self.modelrigadoc.ivatounitario || 0,
        pivato: true,
        aliquota: iva.aliquota,
    })

    self.modelrigadoc.ivatounitario = objprezzo.ivato;
    self.modelrigadoc.imponibileUnitLordo = objprezzo.imponibile;
    self.modelrigadoc.impostaunitario = objprezzo.imposta;

    self.ricalcolaTotaleRiga();

    if(typeof self.rigaivawin !== 'undefined'){
        self.rigaivawin.close();
    }
}

function blurrigadocUm(){
    var self = this;

    var res = self.$filter('filter')(self.ums, self.rigadoc.um.$viewValue);
    if(res.length === 1){
        self.setUmRiga(res[0])
    } else {
        self.rigaumwin.open().center();
    }

}

function svuotaUmRiga(){
    var self = this;
    self.modelrigadoc.um = "";
    self.modelrigadoc._um = "";
    self.disabilitarigadocUm = false;
}

function setUmRiga(um){
    var self = this;
    self.modelrigadoc.um = um;
    self.modelrigadoc._um = um.codice + " - " + um.multiDescr.testo;
    self.disabilitarigadocUm = true;
    
    
    if(!self.nou(self.rigaumwin)){
        self.rigaumwin.close();
    }
    
}



/**
 * @description
 * All'uscita dal campo imponibile della riga articolo ricalcolo ivato (aggiorno anche imponibile per arrotondamenti)
 * @param {$event} e 
 */
function ricalcolaIvato(e){
    var self = this;
    var imponibile = self.modelrigadoc.imponibileUnitLordo;
    var iva = self.tipiiva.find(function(ivai){
        return ivai.id === self.modelrigadoc.iva.id;
    })
    
    var objprezzo = imponibileivato({
        valore: imponibile || 0,
        pivato: false,
        aliquota: iva.aliquota,
    })

    self.modelrigadoc.imponibileUnitLordo = objprezzo.imponibile;
    self.modelrigadoc.ivatounitario = objprezzo.ivato;
    self.modelrigadoc.impostaunitario = objprezzo.imposta;
    self.ricalcolaTotaleRiga();
}

/**
 * @description
 * All'uscita dal campo ivato della riga articolo ricalcolo imponibile
 * @param {$event} e 
 */
function ricalcolaImponibile(e){
    var self = this;
    var ivato = self.modelrigadoc.ivatounitario;
    var iva = self.tipiiva.find(function(ivai){
        return ivai.id === self.modelrigadoc.iva.id;
    })
    
    var objprezzo = imponibileivato({
        valore: ivato || 0,
        pivato: true,
        aliquota: iva.aliquota,
    })

    self.modelrigadoc.imponibileUnitLordo = objprezzo.imponibile;
    self.modelrigadoc.ivatounitario = objprezzo.ivato;
    self.modelrigadoc.impostaunitario = objprezzo.imposta;
    self.ricalcolaTotaleRiga();
}

/**
 * @description
 * Quando si esce da sconto1, sconto2 o quantita devo ricalcolare il totale, ma solo se imponibileUnitLordo, sconto1, sconto2, quantita sono validi
 */
function ricalcolaTotaleRiga(){
    var self = this;

    if(self.modelrigadoc.quantita === undefined){
        self.modelrigadoc.quantita = 1;
    }

    if(self.modelrigadoc.sconto1 === undefined){
        self.modelrigadoc.sconto1 = 0;
    }

    if(self.modelrigadoc.sconto2 === undefined){
        self.modelrigadoc.sconto2 = 0;
    }
    

    var valido = ["sconto1", "sconto2", "imponibileUnitLordo", "quantita"].reduce(function(esito, chiave){
        return esito && 
            typeof self.modelrigadoc[chiave] !== 'undefined';
            //self.rigadoc[chiave].$valid;
    }, true);

    if(valido){
        var imponibileUnitLordo = valuefordinero({valore: self.modelrigadoc.imponibileUnitLordo});
        var quantita = self.modelrigadoc.quantita;
        var sconto1 = self.modelrigadoc.sconto1;
        var sconto2 = self.modelrigadoc.sconto2;

        var imponibiletotale = imponibileUnitLordo
            .multiply(quantita)
            .multiply((100 - sconto1) / 100)
            .multiply((100 - sconto2) /100)
        ;

//        console.log("imponibile unitario", imponibileUnitLordo.getAmount(), "quantita", quantita, "sconti", sconto1, sconto2, "imponibile totale", imponibiletotale.getAmount())

        var objprezzo = imponibileivato({
            valore: imponibiletotale || 0,
            pivato: false,
            aliquota: 1,
        })

 //       console.log("imponibile totale", imponibiletotale, objprezzo);
        self.modelrigadoc.imponibiletotale = objprezzo.imponibile;

    }
}

/**
 * @description
 * salva la riga nel datasource locale e richiama evidenziaRighe
 */
function addRigaLocally(){
    var self = this;    

    var mrd = self.modelrigadoc;

    var modelloriga = angular.extend({}, self.modelrigadoc, {
        articolo: null, //angular.extend({}, mrd.articolo, {label: mrd._articolo}),
        codartforn: typeof mrd.codartforn === 'object' ? angular.extend({}, mrd.codartforn, {label: mrd._codartforn}) : mrd.codartforn,
        iva: angular.extend({}, mrd.iva, {codice: self.modelrigadoc._iva}),
        um: angular.extend({}, mrd.um, {label: mrd._um})
    })

    if(["A", "D"].includes(mrd.tipo.value)){
        if(typeof mrd.articolo !== 'undefined' && mrd.articolo !== null){
            modelloriga.articolo = mrd.articolo
        }
    
        if(typeof mrd._articolo !== 'undefined' && mrd._articolo !== null){
            modelloriga.articolo.label = mrd._articolo
        }
    
    }
    
    var nuovariga = self.gridRighe.dataSource.add(modelloriga);

    //rimuovo da erighe la riga che ho appena inserito/modificato
    self.erighe = self.erighe.filter(function(riga){return riga.uid !== nuovariga.uid})

    self.imrighe.push(nuovariga);
    
    self.evidenziaRighe();

}


function disabilitaAggiungiRiga(){
    var self = this;
    return false;
}

/**
 * @description
 * Il tasto di modfica riga va disabilitato se la selezione è maggiore di uno o se la riga selezionata è stata eliminata
 */
function disabilitaGenParz(){
    var self = this;
    if(typeof self.righeSel === 'undefined') return true;

    var selezionenonvalida = self.righeSel.find(function(riga){
        var codicestato = bssRecursiveGetter(riga, "statorica.codice", null);
        return ["NCGEN", "RIMBGEN"].includes(codicestato);
    })

    return (self.righeSel.length === 0 || selezionenonvalida !== undefined);
    
}

/**
 * @description
 * Il tasto di modfica riga va disabilitato se la selezione è maggiore di uno o se la riga selezionata è stata eliminata
 */
function disabilitaModificaRiga(){
    var self = this;
    if(typeof self.righeSel === 'undefined') return true;
    return (self.righeSel.length !== 1 || self.erighe.find(function(riga){return riga.uid === self.righeSel[0].uid}))
    
}

/**
 * @description
 * il tasto ripristina righe deve essere disabilitato se la selezione è vuota o se nella selezione è presenta anche solo un record non eliminato
 */
function disabilitaRipristinaRighe(){
    var self = this;
    if(typeof self.righeSel === 'undefined') return true;
    var uidimrighe = self.imrighe.map(function(riga){return riga.uid;})
    return (self.righeSel.length === 0 || self.righeSel.find(function(riga){return uidimrighe.indexOf(riga.uid) !== -1}))
}

/**
 * @description
 * il tasto elimina righe va disabilitato se non ho selezionato un record
 */
function disabilitaEliminaRighe(){
    var self = this;
    return (typeof self.righeSel !== 'undefined') && (self.righeSel.length <= 0)
}


/**
 * @description
 * salva la riga nel datasource locale e richiama evidenziaRighe
 */
function editRigaLocally(){
    var self = this;
    var dato = self.gridRighe.dataSource.getByUid(self.righeSel[0].uid);
    var oridato = angular.copy(dato);

    Object.keys(self.modelrigadoc).forEach(function(campo){
        dato.set(campo, self.modelrigadoc[campo]);
    });

    if( ["A", "D"].includes(oridato.tipo.value) ){
        /* dopo la modifica l'oggetto cambia e la generazione del trasporto va in eccezione */
        var codartfornaggiornato = {
            id: dato.idcodartforn,
            codartforn: dato.codartforn,
            fornitore: {
                id: bssRecursiveGetter(dato, "fornitore.id", null),
                codice: bssRecursiveGetter(dato, "fornitore.codice", null),
                label: bssRecursiveGetter(dato, "fornitore.ragsoc", null)
            },
            articolo: {
                id: bssRecursiveGetter(dato, "articolo.id", null),
                label: bssRecursiveGetter(dato, "articolo.label", null)
            }
        }

        dato.set("codartforn", codartfornaggiornato);


    }
    
    
    //rimuovo da erighe la riga che ho appena inserito/modificato
    self.erighe = self.erighe.filter(function(riga){return riga.uid !== dato.uid})

    /**
     * quando il documento è nuovo andrà a modificare sempre righe che sono già contenute in imriga
     * quando il documento è in modifica non è detto che imrighe contenga il record che sto modificando
     */
    var indexrigadaimriga = self.imrighe.findIndex(function(riga){
        return riga.uid === dato.uid;
    })

    if(indexrigadaimriga !== -1){
        self.imrighe[indexrigadaimriga] = angular.copy(dato);
    } else {
        self.imrighe.push(angular.copy(dato));
    }

    // funzionava solo se imrighe conteneva già la riga cosa che non accade per i record in modifica in caso di documento in modifica 
    // self.imrighe = self.imrighe.map(function(riga){
    //     if(riga.uid === dato.uid){
    //         riga = angular.copy(dato);
    //     }
    //     return riga;
    // })
    
    
    self.evidenziaRighe();

}

/**
 * @description
 * elimina le righe selezione e richiava evidenziaRighe
 */
function deleteRigheLocally(){
    var self = this;
    self.righeSel.forEach(function(rigadadel){
        
        //rimuovo dall'elenco delle righe inserite/modificate la riga appena eliminata
        self.imrighe = self.imrighe.filter(function(riga){
            return riga.uid !== rigadadel.uid;
        })

        self.erighe.push(rigadadel);        
        self.iderighe = self.erighe.map(function(riga){return riga.id})
        //self.gridRighe.dataSource.remove(riga);
    })    
    self.evidenziaRighe();
    self.aggiornaRigheCalcTotali();
}

/**
 * @description
 * aggiunge le classi css alle righe della grid per distingere quelle nuove/modificate/eliminate (solo in locale)
 */
function evidenziaRighe(){
    var self = this;

    function onlyUid(riga){
        return riga.uid;
    }

    var uidimrighe = self.imrighe.map(onlyUid);
    self.uiderighe = self.erighe.map(onlyUid);

    var tbody = self.gridRighe.tbody;

    uidimrighe.forEach(function(uid){
        var rigah = tbody.find("tr[data-uid='"+uid+"']");
        if(rigah.length){
            rigah.removeClass("nuovorecord modificarecord eliminarecord");

            var riga = self.gridRighe.dataItem(rigah);
            if(riga.id == "" || riga.id === 0){
                rigah.addClass("nuovorecord");
            } 
            else {
                rigah.addClass("modificarecord");
            }
        }
        
    })

    self.uiderighe.forEach(function(uid){
        var rigah = tbody.find("tr[data-uid='"+uid+"']");
        if(rigah.length){
            rigah.removeClass("nuovorecord modificarecord eliminarecord");
            rigah.addClass("eliminarecord");        
        }
        
    })
    

    
}


/**
 * 
 * @param {*} assAF //riga da grid di associazioni articoli fornitori
 */
function setRigaDaAssAF(assAF){
    var self = this;
    //self.assAFSel = assAF;
    //console.log("selezionato", assAF);
    if(typeof self.modelrigadoc === 'undefined'){
        self.modelrigadoc = {};
    }

    
    self.modelrigadoc._articolo = assAF.articolo.codarticolo + " - " + assAF.articolo.descrizione;
    self.modelrigadoc._articoloparts = {"Cod. Articolo": assAF.articolo.codarticolo, "Descrizione": assAF.articolo.descrizione};
    self.modelrigadoc.articolo = assAF.articolo;//.id;
    
    if(assAF.articolo.servizio !== true){
        self.modelrigadoc._codartforn = assAF.codiceFornitore + " presso " + assAF.fornitore.codice + " - " + assAF.fornitore.ragsoc;        
        self.modelrigadoc._codartfornparts = {"Cod. Art. Fornitore": assAF.codiceFornitore, "Fornitore": assAF.fornitore.codice + " - " + assAF.fornitore.ragsoc};
        self.modelrigadoc._codartfornparts3 = {"codartforn": assAF.codiceFornitore, "codforn": assAF.fornitore.codice, "ragsocforn": assAF.fornitore.ragsoc};
        self.modelrigadoc.codartforn = assAF.codiceFornitore;
        self.modelrigadoc.idcodartforn = assAF.codiciFornitore.id;
        self.modelrigadoc.fornitore = assAF.fornitore;
        self.modelrigadoc._um = assAF.articolo.um.label;
        self.modelrigadoc.um = assAF.articolo.um;//.id;
        self.modelrigadoc.quantita = 1;
    }

    if([null, ""].indexOf(self.modelrigadoc.descrizione) !== -1 || typeof self.modelrigadoc.descrizione === 'undefined') {
        self.modelrigadoc.descrizione = assAF.articolo.descrizione;
    }
    
    

    if([null, ""].indexOf(self.modelrigadoc.note) !== -1 || typeof self.modelrigadoc.note === 'undefined') {
        self.modelrigadoc.note = assAF.articolo.note;
    }
    
    if(self.nou(self.modelrigadoc._iva) || self.modelrigadoc._iva === ""){
        self.modelrigadoc._iva = assAF.articolo.iva.label;
        self.modelrigadoc.iva = assAF.articolo.iva;//.id;
    }    
    self.modelrigadoc.prezzoacquisto = assAF.prezzoacquisto;
    self.modelrigadoc.sconto1 = 0;
    self.modelrigadoc.sconto2 = 0;

    console.log("iva da doc", self.modelrigadoc.iva, "iva da art", assAF.articolo.iva);
    var idivaconfronto = (typeof self.modelrigadoc.iva !== 'undefined' && self.modelrigadoc.iva) !== null ? self.modelrigadoc.iva.id : assAF.articolo.iva.id;

    var iva = self.tipiiva.find(function(ivai){ return ivai.id === idivaconfronto;});    
    if(typeof iva === 'undefined'){
        iva = {aliquota: 22};
    }
    var objprezzo = imponibileivato({
        valore: self.modeltestata.tipodoc.tipoCliFor.value === 'C' ? (assAF.prezzovendita || 0) : (assAF.prezzoacquisto || 0),
        pivato: self.modeltestata.tipodoc.tipoCliFor.value === 'C' ? assAF.pvivato : assAF.paivato,
        aliquota: iva.aliquota,
    })

    self.copiaprezzo = {
        pivato: self.modeltestata.tipodoc.tipoCliFor.value === 'C' ? assAF.pvivato : assAF.paivato,
        prezzo: self.modeltestata.tipodoc.tipoCliFor.value === 'C' ? (assAF.prezzovendita || 0) : (assAF.prezzoacquisto || 0),
    }

    //se mi trovo in sostituzione i prezzi devono restare a zero
    var idrigaorigine = bssRecursiveGetter(self, "modelrigadoc.rigaOrigine.id", null);
    var insost = !self.nou(idrigaorigine);
    if(insost){
        console.log("sono in sostituzione quindi azzero i prezzi")
        objprezzo.ivato = 0;
        objprezzo.imposta = 0;
        objprezzo.imponibile = 0;
    }

    self.modelrigadoc.ivatounitario = objprezzo.ivato;
    self.modelrigadoc.impostaunitario = objprezzo.imposta;
    self.modelrigadoc.imponibileUnitLordo = objprezzo.imponibile;
    self.disabilitarigadocArticolo = true;
    self.disabilitarigadocCodartforn = true;
    self.disabilitarigadocIVA = true;


    self.ricalcolaTotaleRiga();

    //console.log("dall'articolo/codartforn", self.modelrigadoc)
}

function getAssAF(da){
    var self = this;


    var strentity = "vartforn"
    if(typeof da === 'undefined'){
        da = "articolo"
    }

    var campoinput = {
        servizio: "codarticolo",
        articolo: 'codarticolo',
        codartforn: 'codfornitore'
    }

    var mappamodel = {
        servizio: "articolo",
        articolo: da,
        codartforn: da
    }

    var params = { page: 1, pageSize: 2, take: 2, skip: 0};
    var modelriga = self.modelrigadoc;
    var campo = mappamodel[da];
    var campoparam = campoinput[da];
    
    if(bssRecursiveGetter(modelriga, campo+".id", null) !== null){
        
        if(bssRecursiveGetter(modelriga, "_articoloparts", null) && bssRecursiveGetter(modelriga, "_codartfornparts", null)){
            //console.log("====================>sono in inserimento del documento e modifica della riga",  modelriga)
            params.codarticolo = modelriga._articoloparts["Cod. Articolo"];
            params.codfornitore = modelriga._codartfornparts["Cod. Art. Fornitore"];

        } else {
            //sono in modifica del documento e della riga
        }
        
    } else {
        params[campoparam] = modelriga ? modelriga["_"+campo] : "";
    }
    

    params.servizio = da === "servizio";
    

    //params[campoinput[da]] = self.rigadoc[da] ? self.rigadoc[da].$viewValue : "";
    params.idlistino = self.modeltestata.listino

    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param(params)
    })
}

/**
 * 
 * @param {'articolo' | 'codartforn'} da //indica per cosa sto ricercando
 */
function initGridAssAF(da) {
    var self = this;
    var strentity = "vartforn"
    if(typeof da === 'undefined'){
        da = "articolo"
    }

    var campoinput = {
        servizio: "codarticolo",
        articolo: 'codarticolo',
        codartforn: 'codfornitore'
    }

    var campoda = da === 'servizio' ? 'articolo' : da;

    /**
     * @param {*} e 
     * @param {*} griglia 
     */
    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setRigaDaAssAF(dataItem);
        self.assafwin.close();        
    }



    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {

                if(type === 'read'){
                    var term = self.modelrigadoc ? self.modelrigadoc["_"+campoda] : "";

                    var newdata = angular.extend({}, defaultRead(data, gridcfg.dataSource.options));

                    newdata[campoinput[da]] = term;
                    newdata.servizio = da === 'servizio';
                    newdata.idlistino = self.modeltestata.listino
                    //console.log("parametermap ds", data, newdata);                
                    return newdata
                }


            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridAssAF !== 'undefined') {
                    //console.log("databound", self.gridAssAF);
                    self.gridAssAF.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliAssAF = self.gridAssAF.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                    self.ricercaInCorso = false;

                }
            }
            gridcfg.change = function(e){
                var griglia = e.sender;
                var rigasel = griglia.dataItem(griglia.select());
                self.$timeout(function(){
                    self.articoloAssociabile = rigasel;
                })
            }
            self.gridAssAFOpts = angular.extend({}, gridcfg);
            self.gridAssAFRebind = moment().format();
            //return ds.read();
        })
    });
}

function resetAssAF(){
    var self = this;
        
    self.modelrigadoc = angular.extend({}, self.modelrigadoc, {
        _articolo: "",
        _codartforn: "",
        articolo: "",
        codartforn: "",
        note: ""
    });
        
    // self.modelrigadoc = {};

    // self.rigadoc.$setPristine()
    // self.rigadoc.$setUntouched()

    self.disabilitarigadocArticolo = false;
    self.disabilitarigadocCodartforn = false;
}

function creaAssAF(){

    var self = this;

    if(self.valida.validaForm(self.formNuovaAssAF)){
        
        // var dati = angular.extend({}, self.nuovaAssAF, {idarticolo: self.articoloAssociabile.articolo.id})
        var dati = {
            codice: self.nuovaAssAF.codartforn,
            idarticolo: self.articoloAssociabile.articolo.id,
            idfornitore: self.nuovaAssAF.fornitore.id,
            prezzo: self.nuovaAssAF.prezzo
        };
        console.log("posso creare una nuova associazione", dati)
        self.ricercaInCorso = true;
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/articolirdc/associafornitore",
            data: $.param(dati)
        }).then(function(resp){
            self.em.showT("DOCUMENTISTD.NUOVAASSAF", "success");
            self.gridAssAF.dataSource.read();
            self.ricercaInCorso = false;
            self.nuovaAssAF = {};
            self.newassafwin.close();
        })
    }
    
}

function svuotaRileggiAssAF(){
    var self = this;
    self.resetAssAF();
    self.$timeout(function(){
        self.gridAssAF.dataSource.read();        
    })
}

/**
 *   =================================================================== Fine Articolo in Righe di tipo Articolo ====================================================================================
 **/ 

/**
 * ===================================================================== Fine Righe Documento =======================================================================================================
 */

/**
 * Fornitore in riga documento
 */

DocumentistdCtrl.prototype.blurRigaFornitore = blurRigaFornitore;
DocumentistdCtrl.prototype.setRigaFornitore = setRigaFornitore;
DocumentistdCtrl.prototype.resetRigaFornitore = resetRigaFornitore;
DocumentistdCtrl.prototype.initGridRigaFornitore = initGridRigaFornitore;
DocumentistdCtrl.prototype.apriPopupRigaFornitore = apriPopupRigaFornitore;
DocumentistdCtrl.prototype.getRigaFornitori = getRigaFornitori;

function blurRigaFornitore($event) {
    var self = this;

    self.getRigaFornitori().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setRigaFornitore(opzioni[0])
        } else {
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NORIGAFORNFOUND", "warning");
                self.resetRigaFornitore()
            }
            self.apriPopupRigaFornitore()
        }
    })
    //se il datasource contiene un solo elemento
}

/**
 * aggiorna il termine di ricerca per anagrafica e il model
 * @param {*} ana 
 */
function setRigaFornitore(fornitore) {
    var self = this;
    self.modelrigadoc.fornitore = fornitore;
    self.modelrigadoc._fornitore = fornitore.codice + " - " + fornitore.nome + " - " + fornitore.cognome + " - " + fornitore.ragsoc + " - " + fornitore.partitaiva;
    self.rigafornitorewin.close();
    self.disabilitaRigaFornitore = true;

}

function resetRigaFornitore() {
    var self = this;
    self.modelrigadoc.fornitore = null;
    self.modelrigadoc._fornitore = null;
    self.disabilitaRigaFornitore = false;
}


/**
 * @description
 * Inizializzo la griglia dell'anagrafica
 */
function initGridRigaFornitore() {
    var self = this;
    var strentity = "fornitori";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setRigaFornitore(dataItem);
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = bssRecursiveGetter(self, "rigadoc.fornitore.$viewValue", undefined);
                var newdata = angular.extend({}, data, {search: term}, {datiCfg: {
                    strentity: strentity,
                    codapp: null,
                    codmodulo: self.raggruppamento
                }});
                // if(term !== "" && typeof term !== 'undefined' && term !== null){
                //     newdata.search = term;
                // }                
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }
            /*gridcfg.columns = gridcfg.columns.map(function(colonna){
                if(["ragsoc", "partitaiva", "codice", "nome", "cognome", "codicefiscale", "listino"].indexOf(colonna.field) === -1){
                    colonna.hidden = true;
                }
                return colonna
            })*/
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridRigaFornitore !== 'undefined') {
                    //console.log("databound", self.gridRigaFornitore);
                    self.gridRigaFornitore.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliFornitori = self.gridRigaFornitore.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridRigaFornitoreOpts = angular.extend({}, gridcfg);
            self.gridRigaFornitoreRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupRigaFornitore() {
    var self = this;
    self.rigafornitorewin.open().center();
    self.initGridRigaFornitore()
}


/**
 * Ritorna una lista di al più 2 intestatari per tipodoc e _anagrafica
 * Posso usarlo al blur da anagrafica e comportarmi opportunamente a seconda del numero di dati ritornati 1 nessuno o più di uno
 * @returns {Promise<AnagraficheStd<TipoDoc>>}
 */
function getRigaFornitori() {
    var self = this;
    var term = self.rigadoc.fornitore.$viewValue;
    var parametri = { page: 1, pageSize: 2, take: 2, skip: 0, search: term, 
        datiCfg: {
            strentity: 'fornitore',
            codapp: null,
            codmodulo: self.raggruppamento
        }
    };
    // if(term !== "" && typeof term !== 'undefined' && term !== null){
    //     parametri.search = term;
    // }
    var strentity = "fornitori";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param(parametri)
    }).then(function (resp) {
        self.possibiliFornitori = resp.data.results;
        return self.possibiliFornitori
    })
}

/**
 * ===================================================================== Fine Fornitore in riga documento =======================================================================================================
 */
DocumentistdCtrl.prototype.calcolaDatiCastelletto = calcolaDatiCastelletto;
DocumentistdCtrl.prototype.aggiornaRigheCalcTotali = aggiornaRigheCalcTotali;


/**
 * Da righe documento prendo tutte le righe in cui ho imponibileunitlordo così da escludere le righe descrittive ad esempio
 */
function aggiornaRigheCalcTotali(){
    var self = this;
    self.$timeout(function(){
        self.righecalctotali = self.gridRighe.dataSource._data.filter(function(riga){
            return riga.tipo.value !== 'C' 
                && riga.imponibileUnitLordo !== null && typeof riga.imponibileUnitLordo !== 'undefined'/* && riga.imponibileUnitLordo !== 0*/
                && (typeof self.uiderighe === 'undefined' || self.uiderighe.indexOf(riga.uid) === -1);
        })
    
        self.mostrafooter = true;// || self.righecalctotali.length;
    
        self.calcolaDatiCastelletto()
    })
    
}

/**
* @typedef {Object} RigheCastelletto
* @property {string} codiceiva - 
* @property {number} aliquota - booleano se true valore è ivato altrimenti è imponibile
* @property {number} pesoimponibile - peso dell'imponibile rispetto al totale serve distribuire gli sconti
* @property {Dinero} imponibile - somma degli imponibili al lordo degli sconti per codiceiva
* @property {Dinero} sconto - somma degli sconti per codiceiva
* @property {Dinero} imponibilenetto - somma degli imponibili al netto degli sconti per codiceiva
* @property {Dinero} iva - somma iva per codiceiva
* @property {Dinero} totale - somma totale (imponibile + iva) per codiceiva
* @property {number} _imponibile - somma degli imponibili al lordo degli sconti per codiceiva
* @property {number} _sconto - somma degli sconti per codiceiva
* @property {number} _imponibilenetto - somma degli imponibili al netto degli sconti per codiceiva
* @property {number} _iva - somma iva per codiceiva
* @property {number} _totale - somma totale (imponibile + iva) per codiceiva
* 
*/

/**
* @typedef {Object} TotaleCastelletto
* @property {Dinero} imponibile - somma degli imponibili al lordo degli sconti
* @property {Dinero} sconto - somma degli sconti
* @property {Dinero} imponibilenetto - somma degli imponibili al netto degli sconti
* @property {Dinero} iva - somma iva
* @property {Dinero} totale - somma totale (imponibile + iva)
* @property {number} _imponibile - somma degli imponibili al lordo degli sconti
* @property {number} _sconto - somma degli sconti
* @property {number} _imponibilenetto - somma degli imponibili al netto degli sconti
* @property {number} _iva - somma iva
* @property {number} _totale - somma totale (imponibile + iva)
*/

/**
* @typedef {Object} Castelletto
* @property {RigheCastelletto[]} righe
* @property {TotaleCastelletto} totali
* 
*/

/**
 * @description
 * Genera la struttura necessaria a popolare il castelletto
 * @return {Castelletto}
 */
function calcolaDatiCastelletto(){
    var self = this;
    //tutte le righe del documento che concorrono alla compilazione del castelletto
    var rvalcast = self.righecalctotali;
    
    /**
     * @type Castelletto
     */
    var castelletto = {
        righe: null,
        totali: {
            imponibile: valuefordinero({valore: 0}),
            sconto: valuefordinero({valore: 0}),
            imponibilenetto: valuefordinero({valore: 0}),
            iva: valuefordinero({valore: 0}),
            totale: valuefordinero({valore: 0}),
        }
    }

    function listaAliquote(){
        return _.uniq(rvalcast.map(function(riga){
            return self.tipiiva.find(function(tipo){
                return tipo.id === riga.iva.id
            }) 
        }), "id")
    }

    if(rvalcast){
        var aliquote = listaAliquote();
        //per ogni aliquota distinta aggiungo una riga al castelletto (con tutti gli importi a zero) 
        castelletto.righe = aliquote.map(function(aliquota){
            var riga = {
                idiva: aliquota.id,
                codiceiva: aliquota.codice, 
                aliquota: aliquota.aliquota,
                imponibile: valuefordinero({valore: 0}), 
                sconto: valuefordinero({valore: 0}),
                imponibilenetto: valuefordinero({valore: 0}),
                iva: valuefordinero({valore: 0}),
                totale: valuefordinero({valore: 0})

            }

            //calcolo gli imponibili del castelletto
            riga.imponibile = rvalcast.reduce(function(somma, rigaprezzo){
                if(rigaprezzo.iva.id === aliquota.id){
                    var imptot = valuefordinero({valore: rigaprezzo.imponibiletotale});
                    //console.log("imp tot", imptot.getAmount())
                    somma = somma.add(imptot);
                    
                }
                return somma;
            }, valuefordinero({valore: 0}))
            return riga;
        })

        //calcolo l'imponibile totale del castelletto
        castelletto.totali.imponibile = castelletto.righe.reduce(function(somma, riga){
                //console.log("somma imponibili netti righe", somma.getAmount(), riga.imponibile.getAmount())
            return somma.add(riga.imponibile)
        }, valuefordinero({valore: 0}))
        

        /** 
         * calcolo il peso dell'imponibile rispetto al totale, cioè uso la proporzione: 
         * castelletto.totali.imponibile : 100 = castelletto.righe[indice].imponibile: x
         * pesoimponibile così definito per ogni riga mi permetterà di distribuire gli sconti in base al valore dell'imponibile
         * Es.
         * imponibile totale 200
         * sconto totale 10
         * aliquota 22, imponibile 140, pesoimponibile 70, sconto 7
         * aliquota 4, imponibile 60, pesoimponibile 30, sconto 3
         */
        castelletto.righe.forEach(function(rigacast, indice){
            var imponibileriga = rigacast.imponibile.toRoundedUnit(2, "HALF_UP");
            var imponibiletotale = castelletto.totali.imponibile.toRoundedUnit(2, "HALF_UP");
            castelletto.righe[indice].pesoimponibile = Math.max(0, Math.min(100, imponibileriga * 100 / imponibiletotale)); 
        })


        //calcolo lo sconto totale
        castelletto.totali.sconto = castelletto.totali.imponibile
            .multiply( (self.modelfooter.scontoperc || 0) / 100)
            .add( valuefordinero({valore: self.modelfooter.scontoaval || 0}) )

        //distribuisco lo sconto per aliquota sulla base del peso imponibile
        castelletto.righe = castelletto.righe.map(function(rigacast){
            var pesoimp = !self.nou(rigacast.pesoimponibile) && !isNaN(rigacast.pesoimponibile) ? rigacast.pesoimponibile : 0;
            rigacast.sconto = castelletto.totali.sconto.percentage(pesoimp);
            rigacast.imponibilenetto = rigacast.imponibile.subtract(rigacast.sconto);
            rigacast.iva = rigacast.imponibilenetto.percentage(rigacast.aliquota);
            rigacast.totale = rigacast.imponibilenetto.add(rigacast.iva)
            return rigacast;
        })

        //calcolo i totali rimanenti
        castelletto.righe.forEach(function(rigacast, indice){
            //iva: valuefordinero({valore: 0}),
            castelletto.righe[indice]._imponibile =  castelletto.righe[indice].imponibile.toRoundedUnit(2, "HALF_UP")
            castelletto.righe[indice]._iva = castelletto.righe[indice].iva.toRoundedUnit(2, "HALF_UP")
            castelletto.righe[indice]._sconto = castelletto.righe[indice].sconto.toRoundedUnit(2, "HALF_UP")
            castelletto.righe[indice]._imponibilenetto = castelletto.righe[indice].imponibilenetto.toRoundedUnit(2, "HALF_UP")
            castelletto.righe[indice]._totale = castelletto.righe[indice].totale.toRoundedUnit(2, "HALF_UP")
            castelletto.totali.imponibilenetto = castelletto.totali.imponibilenetto.add(rigacast.imponibilenetto);
            castelletto.totali.iva = castelletto.totali.iva.add(rigacast.iva);
            castelletto.totali.totale = castelletto.totali.totale.add(rigacast.totale);
        })

        castelletto.totali._imponibile = castelletto.totali.imponibile.toRoundedUnit(2, "HALF_UP");
        castelletto.totali._imponibilenetto = castelletto.totali.imponibilenetto.toRoundedUnit(2, "HALF_UP");
        castelletto.totali._iva = castelletto.totali.iva.toRoundedUnit(2, "HALF_UP");
        castelletto.totali._sconto = castelletto.totali.sconto.toRoundedUnit(2, "HALF_UP");
        castelletto.totali._totale = castelletto.totali.totale.toRoundedUnit(2, "HALF_UP");

        self.modelfooter.castelletto = castelletto;

            
        self.adeguaPagamentiRimb(castelletto);
        if(self.adeguadocrighecastelletto){
            self.salvaOrdine(null, true)
        }        
    }

}




/**
 * ===================================================================== Fine Totali / Footer Documento =======================================================================================================
 */ 

/**
 * Corrieri
 */
DocumentistdCtrl.prototype.cambiaFormato = cambiaFormato;
DocumentistdCtrl.prototype.calcolaLabelFormatoImb = calcolaLabelFormatoImb;
DocumentistdCtrl.prototype.mappaColloDaServer = mappaColloDaServer;
DocumentistdCtrl.prototype.modificaModelRigaCorriere = modificaModelRigaCorriere;
DocumentistdCtrl.prototype.confirmAnnullaTrasporto = confirmAnnullaTrasporto;
DocumentistdCtrl.prototype.annullaTrasporto  = annullaTrasporto;
DocumentistdCtrl.prototype.sonoPresentiCorrieri = sonoPresentiCorrieri;
DocumentistdCtrl.prototype.svuotaCorrieri = svuotaCorrieri;
DocumentistdCtrl.prototype.generaTrasporti = generaTrasporti;
DocumentistdCtrl.prototype.blurBranch = blurBranch;
DocumentistdCtrl.prototype.setBranch = setBranch;
DocumentistdCtrl.prototype.getInfoCorriere = getInfoCorriere;
DocumentistdCtrl.prototype.setColli = setColli;
DocumentistdCtrl.prototype.resetBranch = resetBranch;
DocumentistdCtrl.prototype.initGridBranch = initGridBranch;
DocumentistdCtrl.prototype.apriPopupBranch = apriPopupBranch;
DocumentistdCtrl.prototype.getBranches = getBranches;
DocumentistdCtrl.prototype.aggiungiCondizioneTrasporto = aggiungiCondizioneTrasporto;
DocumentistdCtrl.prototype.blurCondizioniTrasporto = blurCondizioniTrasporto;
DocumentistdCtrl.prototype.setCondizioniTrasporto = setCondizioniTrasporto;
DocumentistdCtrl.prototype.resetCondizioniTrasporto = resetCondizioniTrasporto;
DocumentistdCtrl.prototype.initGridCondizioniTrasporto = initGridCondizioniTrasporto;
DocumentistdCtrl.prototype.apriPopupCondizioniTrasporto = apriPopupCondizioniTrasporto;
DocumentistdCtrl.prototype.getCondizioniTrasporti = getCondizioniTrasporti;
DocumentistdCtrl.prototype.cancellaCondizioniTrasporto = cancellaCondizioniTrasporto;
DocumentistdCtrl.prototype.aggiungiCollo = aggiungiCollo;
DocumentistdCtrl.prototype.cancellaCollo = cancellaCollo;
DocumentistdCtrl.prototype.salvaTrasporto = salvaTrasporto;
DocumentistdCtrl.prototype.forzaStatoTrasporto = forzaStatoTrasporto;

function cambiaFormato(indice){
    var self = this;
    var colli = self.modelrigacorriere.colli;
    var formato = colli[indice].formato;
    
    if(typeof formato !== 'undefined' && formato !== null){
        //console.log("cambio del formato selezionato", colli, formato, formato.pesokg);
        var modelloimb = self.modelliimb.find(function(modello){
            return Number(modello.id) === Number(formato);
        })

        if(typeof modelloimb !== 'undefined'){            
            self.modelrigacorriere.colli[indice].weightkg = modelloimb.pesokg
        }
        
    }
}

function calcolaLabelFormatoImb(formato){
    var label = formato.descrizione + " (" + formato.altezzacm + " x " + formato.larghcm + " x " + formato.lunghcm  + ")cm" + (formato.pesokg !== null ? " ," + formato.pesokg +"kg" : "");
    //console.log("label formato collo", label);
    return label;
}

function mappaColloDaServer(p){
    var self = this;
    var obj = JSON.parse(JSON.stringify(p));
    if(!self.nou(p.formato)){
        obj.formato = p.formato.id;
    }
    
    //console.log("package rimappato", obj)
    return obj;
}

function confirmAnnullaTrasporto(modelcorriere){
    var self = this;
    self.trasportodaannullare = angular.extend({}, modelcorriere);
    self.annullatrasportowin.open().center()
}

function annullaTrasporto(){
    var self = this;
    self.$http({
        method: "GET",
        url: utils.urls.wsbase + "/documentirdc/annullatrasporto/" + self.trasportodaannullare.id
    }).then(function(resp){

        //@TODO devo aggiornare i trasporti

        self.em.showT("DOCUMENTISTD.ANNULLATRASPORTOSUCCESS", "success");
        self.recuperadoc();
        self.trasportodaannullare = null;
        self.annullatrasportowin.close();
    }, function(err){
        self.em.errorCB(err);
    })
}

function modificaModelRigaCorriere(modello, indice){
    var self = this;
    var modelrigacorriere = angular.extend({}, modello, {uidlocale: indice});
    self.modelrigacorriere = modelrigacorriere;    
    if(typeof modelrigacorriere.porto !== 'undefined' && modelrigacorriere.porto !== null){
        self.modelrigacorriere.porto = modelrigacorriere.porto.value;
    }
    if(typeof self.modelrigacorriere.branch !== 'undefined' && self.modelrigacorriere.branch !== null){
        self.setBranch(self.modelrigacorriere.branch, true, true);
    }
    if(typeof self.modelrigacorriere.packages !== 'undefined' && self.modelrigacorriere.packages !== null){
        self.modelrigacorriere.colli = modelrigacorriere.packages.map(function(p){return self.mappaColloDaServer(p)});
    }
    if(typeof self.modelrigacorriere.condizioniTrasporto !== 'undefined' && self.modelrigacorriere.condizioniTrasporto !== null){
        self.modelrigacorriere.condizioniTrasporto.forEach(function(cond, indice){
            self.setCondizioniTrasporto(indice, cond)
        })
    } else {
        self.modelrigacorriere.condizioniTrasporto = [];
        self.modelrigacorriere._condizioniTrasporto = [];
    }

    if(typeof modelrigacorriere.dataritiro !== 'undefined' && modelrigacorriere.dataritiro !== null){
        self.modelrigacorriere.dataritiro = moment(modelrigacorriere.dataritiro, "x").toDate();
    }


    self.mostraformrigacorriere = true;
}

function sonoPresentiCorrieri(){
    var self = this;
    return self.modelcorrieri.length;
}

function svuotaCorrieri(){
    var self = this;
    self.modelcorrieri = [];
}

function generaTrasporti(){
    var self = this;

    var ordine = self.generaOrdine();

    return self.$http({
        url: utils.urls.wsbase + "/documentistd/generatrasporti",
        method: "POST",
        data: $.param({documento: JSON.stringify(ordine) })
    }).then(function(resp){
        console.log("[CREAINDIRIZZO] 6. TRASPORTI AGGIORNATI", resp.data);

        self.modelcorrieri = (resp.data !== null && typeof resp.data !== 'undefined') ? resp.data : [];
        if(Array.isArray(self.modelcorrieri) && self.modelcorrieri.length){
            self.packages = self.modelcorrieri[0].packages;
            self.modificaModelRigaCorriere(self.modelcorrieri[0], 0);
        }

        return self.modelcorrieri;
        
    },function(err){
        console.log("[CREAINDIRIZZO] 6. IMPOSSIBILE GENERARE TRASPORTI", err);

        self.em.errorCB(err);
    })
}

function blurBranch($event) {
    var self = this;

    self.getBranches().then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setBranch(opzioni[0])
        } else {
            $event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOBRANCHFOUND", "warning");
                self.resetBranch()
            }
            self.apriPopupBranch()
            
        }
    })
    //se il datasource contiene un solo elemento
}

/**
 * aggiorna il termine di ricerca per vettore e il model
 * @param {*} ana 
 */
function setBranch(branch, disabilitaAggiornaModelliimb, disabilitaAggiornaServiziocorr) {
    var self = this;
    self.modelrigacorriere.branch = branch;
    self.modelrigacorriere._branch = (typeof branch !== 'undefined' && branch !== null) ? branch.codice + " - " + branch.descrizione : null;
    self.branchwin.close();
    self.disabilitaBranch = true;

    self.getInfoCorriere().then(function(info){
        self.modelliimb = info[0].data.results;
        self.servizicorr = info[1].data.results;

        //quando cambio la filiale svuoto i colli
        if(typeof disabilitaAggiornaModelliimb === 'undefined' || disabilitaAggiornaModelliimb === null || !disabilitaAggiornaModelliimb ){
            
            

            self.setColli(branch, [{weightkg: 2, quantita: 1}])
        } 
        else {
            self.setColli(branch, self.packages.map(function(p){return self.mappaColloDaServer(p)}));
        }

        //... e il servizio
        if(typeof disabilitaAggiornaServiziocorr === 'undefined' || disabilitaAggiornaServiziocorr === null || !disabilitaAggiornaServiziocorr ){
            self.modelrigacorriere.servizio = null;

            var testata = self.modeltestata;
            var idcomunedest = bssRecursiveGetter(testata, "indirizzo.comune.id", null);

            self.$http({
                method: "POST", 
                url: utils.urls.wsbase + "/courierservice/default" , 
                data: $.param({idvettore: self.modelrigacorriere.branch.vettore.id, idcomunedest: idcomunedest})
            }).then(function(resp){
                self.modelrigacorriere.servizio = bssRecursiveGetter(resp, "data.id", null);
            }, function(err){
                self.em.errorCB(err);
            });

        }
    })
    
}

/**
 * @description
 * Ottiene i modelli imballaggio e i servizi disponibili per il corriere
 * @returns {Promise<[CourierPack, CourierService]>}
 */
function getInfoCorriere(){
    var self = this;
    var params = {idvettore: self.modelrigacorriere.branch.vettore.id, page: 1, skip: 0, take: 9999, pageSize: 9999}
    return self.$q.all([
        self.$http({method: "POST", url: utils.urls.wsbase + "/courierpack/grid" ,  data: $.param(params)}),
        self.$http({method: "POST", url: utils.urls.wsbase + "/courierservice/grid" ,  data: $.param(params)}),
    ]).then(function(resps){
        return resps;
    })
}

function setColli(branch, colli){
    var self = this;
    // self.$http({
    //     method: "GET",
    //     url: utils.urls.wsbase + "/modelliimb/" + branch.vettore         
    // })
    self.$q.when({data: []})
    .then(function(resp){
        //self.modelliimb = resp.data;
        self.modelrigacorriere.colli = colli;
    })
}

function resetBranch() {
    var self = this;
    self.modelrigacorriere.branch = null;
    self.modelrigacorriere._branch = null;
    self.disabilitaBranch = false;    
}



/**
 * @description
 * Inizializzo la griglia dei vettori
 */
function initGridBranch() {
    var self = this;
    var strentity = "filialivett";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setBranch(dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.rigacorriere.branch ? self.rigacorriere.branch.$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridBranch !== 'undefined') {
                    self.gridBranch.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliBranch = self.gridBranch.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridBranchOpts = angular.extend({}, gridcfg);
            self.gridBranchRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupBranch() {
    var self = this;
    self.branchwin.open().center();
    self.initGridBranch()
}


/**
 * Ritorna una lista di al più 2 vettori
 * @returns {Promise<filialivett>}
 */
function getBranches() {
    var self = this;
    var term = self.rigacorriere.branch ? self.rigacorriere.branch.$viewValue : "";
    var strentity = "filialivett";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibiliBranch = resp.data.results;
        return self.possibiliBranch
    })        
}

function aggiungiCondizioneTrasporto(){
    var self = this;
    console.log("aggiungi condizione trasporto", self.modelrigacorriere);

    if(typeof self.modelrigacorriere.condizioniTrasporto === 'undefined' || self.modelrigacorriere.condizioniTrasporto === null){
        self.modelrigacorriere.condizioniTrasporto = [{}];
    } else {
        self.modelrigacorriere.condizioniTrasporto.push({})
    }

    if(typeof self.modelrigacorriere._condizioniTrasporto === 'undefined' || self.modelrigacorriere._condizioniTrasporto === null){
        self.modelrigacorriere._condizioniTrasporto = [];
    } else {
        self.modelrigacorriere._condizioniTrasporto.push("")
    }
}

function blurCondizioniTrasporto(indice) {
    var self = this;

    self.getCondizioniTrasporti(indice).then(function (opzioni) {
        //console.log("uscita dal campo, ho trovato", opzioni);
        if (opzioni.length === 1) {
            self.setCondizioniTrasporto(indice, opzioni[0])
        } else {
            //$event.stopImmediatePropagation();
            if (opzioni.length === 0) {
                self.em.showT("DOCUMENTISTD.NOCONDTRASPFOUND", "warning");
                self.resetCondizioniTrasporto(indice)
            }
            self.apriPopupCondizioniTrasporto(indice)
            
        }
    })
    //se il datasource contiene un solo elemento
}

/**
 * aggiorna il termine di ricerca per condizione trasporto e il model
 * @param {*} ct 
 */
function setCondizioniTrasporto(indice, ct) {
    var self = this;
    if(typeof self.modelrigacorriere.condizioniTrasporto === "undefined"){
        self.modelrigacorriere.condizioniTrasporto = [];
    }
    self.modelrigacorriere.condizioniTrasporto[indice] = ct;
    if(typeof self.modelrigacorriere._condizioniTrasporto === "undefined"){
        self.modelrigacorriere._condizioniTrasporto = [];
    }
    self.modelrigacorriere._condizioniTrasporto[indice] = (typeof ct !== 'undefined' && ct !== null) ? ct.codice + " - " + ct.descrizione : null; 
    self.condtraspwin.close();
    self.disabilitaCondizioniTrasporto[indice] = true;
}

function resetCondizioniTrasporto(indice) {
    var self = this;
    self.modelrigacorriere.condizioniTrasporto[indice] = null;
    self.modelrigacorriere._condizioniTrasporto[indice] = null;
    self.disabilitaCondizioniTrasporto[indice] = false;    
}

/**
 * @description
 * Inizializzo la griglia delle condizioni trasporto
 */
function initGridCondizioniTrasporto(indice) {
    var self = this;

    var strentity = "tipicondtrasp";
    self.indicect = indice;
    function selezionaDaGriglia(e, griglia, indice) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setCondizioniTrasporto(indice, dataItem);
    }

    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {
            ds.transport.parameterMap = function (data, type) {
                var term = self.rigacorriere["condizioniTrasporto_"+indice] ? self.rigacorriere["condizioniTrasporto_"+indice].$viewValue : "";
                var newdata = angular.extend({}, data, { search: term });
                //console.log("parametermap ds", data, newdata);                
                return newdata
            }

            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;
            gridcfg.dataBound = function (e) {
                if (typeof self.gridCondizioniTrasporto !== 'undefined') {
                    self.gridCondizioniTrasporto.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    self.possibiliVettori = self.gridCondizioniTrasporto.dataSource.data();
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia, indice)
                    })

                }
            }
            self.gridCondizioniTrasportoOpts = angular.extend({}, gridcfg);
            self.gridCondizioniTrasportoRebind = moment().format();
            //return ds.read();
        })
    });
}

function apriPopupCondizioniTrasporto(indice) {
    var self = this;
    self.condtraspwin.open().center();
    self.initGridCondizioniTrasporto(indice)
}


/**
 * Ritorna una lista di al più 2 vettori
 * @returns {Promise<TipiCondTrasp>}
 */
function getCondizioniTrasporti(indice) {
    var self = this;
    var term = self.rigacorriere["condizioniTrasporto_"+indice] ? self.rigacorriere["condizioniTrasporto_"+indice].$viewValue : "";
    var strentity = "tipicondtrasp";
    return self.$http({
        url: utils.urls.wsbase + "/" + strentity + "/grid",
        method: "POST",
        data: $.param({ page: 1, pageSize: 2, take: 2, skip: 0, search: term })
    }).then(function (resp) {
        self.possibiliCondizioniTrasporti = resp.data.results;
        return self.possibiliCondizioniTrasporti
    })        
}

/**
 * @param {*} indiceDel 
 */
function cancellaCondizioniTrasporto(indiceDel){
    var self = this;

    if(typeof self.modelrigacorriere.condizioniTrasporto !== 'undefined'){
        self.modelrigacorriere.condizioniTrasporto = self.modelrigacorriere.condizioniTrasporto.filter(function(cond, indice){
            return Number(indice) !== Number(indiceDel);
        })
    }

    if(typeof self.modelrigacorriere._condizioniTrasporto !== 'undefined'){
        self.modelrigacorriere._condizioniTrasporto = self.modelrigacorriere._condizioniTrasporto.filter(function(cond, indice){
            return Number(indice) !== Number(indiceDel);
        })
    }
}

function aggiungiCollo(){
    var self = this;
    if(typeof self.modelrigacorriere.colli === 'undefined'){
        self.modelrigacorriere.colli = [];
    }

    self.modelrigacorriere.colli.push({
        weightkg: 2,
        quantita: 1
    })
}

/**
 * @param {*} indiceDel 
 */
function cancellaCollo(indiceDel){
    var self = this;

    if(typeof self.modelrigacorriere.colli !== 'undefined'){
        self.modelrigacorriere.colli = self.modelrigacorriere.colli.filter(function(cond, indice){
            return Number(indice) !== Number(indiceDel);
        })
    }
}

function salvaTrasporto(){
    var self = this;

    var modello = angular.copy(self.modelrigacorriere);

    // if(!self.nou(modello.dataritiro) && moment(modello.dataritiro).isValid()){
    //     var data = moment(modello.dataritiro).format("DD/MM/YYYY HH:mm:ss");
    //     modello.dataritiro = data;
    // }

    var nuovomodello = JSON.parse(JSON.stringify(modello));
    nuovomodello.packages = JSON.parse(JSON.stringify(nuovomodello.colli));
    nuovomodello.dataritiro = null;
    delete nuovomodello.colli;

    //ignoro le righe vuote
    nuovomodello.condizioniTrasporto = nuovomodello.condizioniTrasporto.filter(function(ct){
        return !_.isEmpty(ct)
    })


    var reqbody = {
        dataritiro: (!self.nou(modello.dataritiro) && moment(modello.dataritiro).isValid()) ? modello.dataritiro : moment().toDate(),
        coddoc: self.modeltestata.tipodoc.codice, 
        protocollo: self.modeltestata.protocollo,
        trasporto: JSON.stringify(nuovomodello)
    };


    var cfgSalva = {
        url: utils.urls.wsbase + "/documentistd/salvatrasporto",
        method: "POST",
        data: $.param(reqbody)
    };

    var statotrasp = bssRecursiveGetter(nuovomodello, "trasmesso.codice", null);
    
    self.$http(cfgSalva).then(function(resp){

        var modelloagg = resp.data;


        if(!self.nou(statotrasp) && ["ANN", "ERR"].includes(statotrasp)){
            self.$http({
                url: utils.urls.wsbase + "/documentirdc/inviasingolotrasporto/" + nuovomodello.id,
                method: "POST",
            }).then(function(resp){
                self.recuperadoc();
                self.em.showT("DOCUMENTISTD.TRASPINVIATO", "success")
            }, function(err){
                self.em.errorCB(err);
            })
        }
        


        self.modelcorrieri[nuovomodello.uidlocale] = angular.copy(modelloagg);        
        self.mostraformrigacorriere = false;
        self.modelrigacorriere = {
            condizioniTrasporto: [],
            _condizioniTrasporto: []
        }
        self.packages = modelloagg.packages;
        self.em.showT("DOCUMENTISTD.TRASPAGGSUCC", "success")
    }, function(err){
        self.em.errorCB(err);
    })
    
}


function forzaStatoTrasporto(modello, indice){
    var self = this;
    console.log("modello", modello);

    self.modificaModelRigaCorriere(modello, indice);

    self.$http({
        method: "POST",
        url: utils.urls.wsbase + "/documentirdc/forzastatotrasporto",
        data: $.param({idtrasporto: modello.id})
    }).then(function(resp){
        var res = angular.extend({}, resp);
        if(!self.nou(bssRecursiveGetter(resp, "data.bssmessages", null))){
            res = angular.extend({}, res, {data: resp.data.bssmessages});
        }            
        self.em.errorCB(res);    
        self.ricarica();    
        //self.salvaTrasporto();
    })
}


/**
 * ===================================================================== Fine Corrieri =======================================================================================================
 */

/**
 * ===================================================================== Logica Nuovo Indirizzo =======================================================================================================
 */

 /**
 * 
 * sono disponibili 4 campi distinti cap, comune, provincia e nazione
 * l'utente scrive sul campo cap o comune e preme invio/tab richiamando il webservice
 *      1 il webservice non ritorna alcun risultato, vado al campo successivo
 *      2 il webservice ritorna un solo risultato popolo e rendo non editabili i 4 campi, a livello di controllo tengo traccia dell'id località
 *      3 il webservice ritorna più risultati apro in popup la grid per selezionare un record, selezionato un record faccio come al punto 2
 * al salvataggio del nuovo indirizzo se ho idlocalita, passo solo quella altrimenti passo i 4 valori distinti
 * 
 */


DocumentistdCtrl.prototype.caricaReceivers = caricaReceivers;
DocumentistdCtrl.prototype.getlocalitabycapocomune = getlocalitabycapocomune;
DocumentistdCtrl.prototype.blurcapcomune = blurcapcomune;
DocumentistdCtrl.prototype.setlocalitanuovoindirizzo  = setlocalitanuovoindirizzo;
DocumentistdCtrl.prototype.svuotalocalitanuovoindirizzo  = svuotalocalitanuovoindirizzo;
DocumentistdCtrl.prototype.initGridComuni  = initGridComuni;
DocumentistdCtrl.prototype.blurprovincia = blurprovincia;
DocumentistdCtrl.prototype.svuotaprovincia = svuotaprovincia;
DocumentistdCtrl.prototype.getprovincia = getprovincia;
DocumentistdCtrl.prototype.setprovincia = setprovincia;
DocumentistdCtrl.prototype.initGridProvince = initGridProvince;
DocumentistdCtrl.prototype.resetfiltrocapcomune = resetfiltrocapcomune;
DocumentistdCtrl.prototype.resetfiltroprovincia = resetfiltroprovincia;

function caricaReceivers(idnazione){
    var self = this;

    if(idnazione !== self.lastidnazrec){//l'id nazione è cambiato ricarico i possibili receivers
        self.lastidnazrec = idnazione;
        self.listareceivers = [];
        self.$http({
            method: "POST",
            url: utils.urls.wsbase + "/treceiver/grid",
            data: $.param({
                take: 300,
                skip: 0,
                page: 1,
                pageSize: 300,
                filter: {
                    logic: "and",
                    filters: [
                        {field: "nazionereceiver.id", operator: "eq", value: idnazione}
                    ]
                }
            })
        }).then(function(resp){
            console.log("caricata la lista dei receivers per nazione con id, seleziono il receiver se presente", idnazione)
            self.listareceivers = bssRecursiveGetter(resp, "data.results", []);            
            self.aggiornaanareceiver();
        }, function(err){
            self.em.errorCB(err);
        })
    } 

    
}

function setlocalitanuovoindirizzo(value){
    var self = this;
    self.soloisoprovincia = null;

    //console.log("imposto tutti i campi della località del nuovo indirizzo e li disabilito", value);
    self.nuovoIndirizzo.idcomune = value.id;
    self.nuovoIndirizzo.cap = value.id;
    self.nuovoIndirizzo._cap = value.cap;
    self.nuovoIndirizzo.comune = value.id;
    self.nuovoIndirizzo._comune = value.comune;
    self.nuovoIndirizzo._nazione = value.nazione;
    self.nuovoIndirizzo.nazione = value.nazione.id;

    self.setprovincia(value);

    
    /**
     * la nazione è cambiata carico i possibili receivers
     */  
    self.caricaReceivers(self.nuovoIndirizzo.nazione)

    self.disabilitacampilocalita = true;
    

    /**
     * 1. devo impostare disabilitacampoprovincia
     * a false: se la nazione è italia, la provincia è vuota e non ci sono altri comuni per lo stesso cap con provincia definita
     * a true: altrimenti
     * 
     * 2. se per lo stesso cap esiste un comune con provincia prendo la provincia
    */
    var esitoabilitaprovincia = (value.nazione.iso.toUpperCase() !== 'IT') || (value.nazione.iso.toUpperCase() === 'IT' && (self.nou(value.provincia) || (bssRecursiveGetter(value, "provincia", "") === ""))); // provincia è vuota    
    

    if(value.nazione.iso.toUpperCase() === 'IT' && esitoabilitaprovincia === true){
        self.getlocalitabycapocomune("cap", value.cap, [{
            field: 'insUser',
            value: "MAXPHO",
            operator: "neq"
        }]).then(function(resp){//ottengo i comuni per cap
            var risultati = resp.results;
    
            //ottengo la provincia dal cap
            var provdacap = risultati.find(function(risultato){
                return risultato.provincia
            })

            //booleano che indica se ho trovato provincia dal cap
            var provdacapdef = !self.nou(provdacap);
            self.provdacap = provdacap;

            //esitoabilitaprovincia = esitoabilitaprovincia && !provdacapdef;

            //se ho trovato provincia aggiorno il form
            if(provdacapdef){
                //self.setprovincia({provincia: provdacap})
                self.provdacapwin.open().center();
            }
    
            self.disabilitacampoprovincia = !esitoabilitaprovincia;
    
        })
    } else {
        self.disabilitacampoprovincia = !esitoabilitaprovincia;
    }
    

    
}

function svuotalocalitanuovoindirizzo(){
    var self = this;
    
    self.nuovoIndirizzo = angular.extend({}, self.nuovoIndirizzo, {
        _cap: null,
        cap: null,
        _comune: null,
        comune: null,
        _provincia: null,
        provincia: null,
        _nazione: null,
        nazione: null,
        idcomune: null
    });
    self.disabilitacampilocalita = false;
    self.disabilitacampoprovincia = false;
}


/**
 * 
 * @param {('cap'|'comune')} contesto 
 * @param {string} term 
 * @returns {Promise}
 */
function getlocalitabycapocomune(contesto, term, additionalFilters){
    var self = this;

    if(self.nou(additionalFilters)){
        additionalFilters = [];
    }

    var filters =  [
        {field: contesto, operator: "startsWith", value: term, ignoreCase: true}
    ].concat(additionalFilters);

    return self.$http({
        url: utils.urls.wsbase + "/tcomuni/grid",
        method: "POST",
        data: $.param({
            take: 50,
            skip: 0,
            page: 1,
            pageSize: 50,
            filter: {
                logic: 'and',
                filters: filters
            }
        })
    })
    .then(function(resp){
        console.log(resp.data)
        return resp.data;
    }, function(err){
        self.em.errorCB(err);
        return err
    })
}

/**
 * 
 * @param {('cap'|'comune')} contesto 
 */
function blurcapcomune(contesto){
    var self = this;

    var mappaContestoCampoSuccessivo = {
        cap: 'comune',
        comune: 'provincia'
    }
    var selettoreCampoSuccessivo = "input[ng-model='moduloCtrl.nuovoIndirizzo._"+ mappaContestoCampoSuccessivo[contesto] +"']";

    self.$timeout(function(){
        var term = self.formNuovoIndirizzo[contesto].$viewValue;
        console.log("cerco per ", contesto, "passando ", term);
        // self.$q.when({            
        //     data: [{
        //         id: 1,
        //         cap: 97100,
        //         comune: "Ragusa",
        //         nazione: 192,
        //         provincia: "RG"
        //     }]            
        // })
        self.getlocalitabycapocomune(contesto, term).then(function(res){
            var risultati = bssRecursiveGetter(res, "results", []);

            switch (risultati.length) {
                // case 0: //nessun risultato vado al campo successivo
                //     self.$timeout(function(){
                //         angular.element(selettoreCampoSuccessivo).focus()
                //     })
                //     break;
                case 1: //seleziono la località
                    self.setlocalitanuovoindirizzo(risultati[0])
                    break;
                default: //mostro il popup
                    self.initGridComuni(contesto, term).then(function(){
                        self.indirizzocomunewin.open().center();
                    })
                    break;
            }

        })
    })

    
}


function blurprovincia(){
    var self = this;

    if((bssRecursiveGetter(self, "nuovoIndirizzo._nazione", null)  !== null) &&  (self.nuovoIndirizzo._nazione === self.italia.id)){//gestisco il blur solo se nazione è italia
        var selettoreCampoSuccessivo = "input[ng-model='moduloCtrl.nuovoIndirizzo._nazione";

        self.$timeout(function(){
            var term = self.formNuovoIndirizzo.provincia.$viewValue;
            console.log("cerco provincia passando ", term);
            self.getprovincia(term).then(function(res){
                var risultati = bssRecursiveGetter(res, "results", []);

                switch (risultati.length) {
                    // case 0: //nessun risultato vado al campo successivo
                    //     self.$timeout(function(){
                    //         angular.element(selettoreCampoSuccessivo).focus()
                    //     })
                    //     break;
                    case 1: //seleziono la località
                        self.setprovincia({provincia: risultati[0]})
                        break;
                    default: //mostro il popup
                        self.initGridProvince(term).then(function(){
                            self.indirizzoprovinciawin.open().center();
                        })
                        break;
                }

            })
        })
    }
    
}

function resetfiltroprovincia(){
    var self = this;
    if(bssRecursiveGetter(self, "formNuovoIndirizzo.provincia.$viewValue", null) !== null){
        self.formNuovoIndirizzo.provincia.$viewValue = '';
        if(bssRecursiveGetter(self, "gridProvince.dataSource", null) !== null){
            self.gridProvince.dataSource.read();
        }
    }
}

function getprovincia(term, additionalFilters){
    var self = this;

    if(self.nou(additionalFilters)){
        additionalFilters = [];
    }

    var filters =  [
        {field: "iso", operator: "startsWith", value: term, ignoreCase: true}
    ].concat(additionalFilters);

    return self.$http({
        url: utils.urls.wsbase + "/tprovince/grid",
        method: "POST",
        data: $.param({
            take: 50,
            skip: 0,
            page: 1,
            pageSize: 50,
            filter: {
                logic: 'and',
                filters: filters
            }
        })
    })
    .then(function(resp){
        console.log(resp.data)
        return resp.data;
    }, function(err){
        self.em.errorCB(err);
        return err
    })
}

function setprovincia(value){
    var self = this;
    var idprov = bssRecursiveGetter(value, "provincia.id", null);
    if(idprov !== null){
        self.soloisoprovincia = false;
        self.nuovoIndirizzo.provincia = value.provincia.id;
        self.nuovoIndirizzo._provincia = value.provincia.iso;
        self.disabilitacampoprovincia = true;
    } else {
        self.soloisoprovincia = true;
        self.nuovoIndirizzo.provincia = value.provincia;
        self.nuovoIndirizzo._provincia = value.provincia;
    }
}

function initGridProvince(term){
    var self = this;

    var strentity = "tprovince";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setprovincia(dataItem);
        self.indirizzoprovinciawin.close();
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {            
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;

            var bodyRead = {
                take: 2,
                skip: 0,
                page: 1,
                pageSize: 2,
            };

            
            

            ds.transport.parameterMap = function (data, type) {    

                bodyRead.filter = undefined;

                var termsearch = bssRecursiveGetter(self, "formNuovoIndirizzo.provincia.$viewValue", null)                                

                if(termsearch !== null){
                    bodyRead.filter =  {
                        logic: 'and',
                        filters: [
                            {field: "iso", operator: "startsWith", value: termsearch, ignoreCase: true}
                        ]
                    }
                } 
            
                return angular.extend({}, bodyRead , data);
            }


            gridcfg.dataBound = function (e) {
                if (typeof self.gridComuni !== 'undefined') {
                    //console.log("databound", self.gridComuni);
                    self.gridProvince.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridProvinceOpts = angular.extend({}, gridcfg);
            self.gridProvinceRebind = moment().format();
            //return ds.read();
            return self.$q.resolve(self.gridProvinceOpts)
        })
    });
}

function svuotaprovincia(){
    var self = this;
    self.soloisoprovincia = null;
    self.nuovoIndirizzo.provincia = null;
    self.nuovoIndirizzo._provincia = null;
    self.disabilitacampoprovincia = false;
}

/**
 * @description
 * Inizializzo la griglia dell'intestazione da Maxpho
 */
function initGridComuni(contesto, term) {
    var self = this;

    var strentity = "tcomuni";

    function selezionaDaGriglia(e, griglia) {
        var dataItem = griglia.dataItem(e.currentTarget);
        self.setlocalitanuovoindirizzo(dataItem);
        self.indirizzocomunewin.close();
    }

    //anagraficapgfstd/self.modeltestata.tipodoc.codice è l'strentity per l'anagrafica
    //terzo parametro codmodulo
    return new self.dsf(strentity, null, self.raggruppamento, null).inizializza().then(function (ds) {
        return new self.gcf(strentity, null, self.raggruppamento, null, true).inizializza().then(function (gridcfg) {            
            gridcfg.filterable = { mode: "row" }
            gridcfg.dataSource = ds;

            gridcfg.selectable = {mode: "multiple, row"}

            var bodyRead = {
                take: 50,
                skip: 0,
                page: 1,
                pageSize: 50,
            };

            

            if(!self.nou(contesto)){

                self.filtrocapcomune = {field: contesto, operator: "startsWith", value: term, ignoreCase: true, identificativo: "filtrocapcomune"}

                
            }

            ds.transport.parameterMap = function (data, type) {    
                bodyRead.filter =  {
                    logic: 'and',
                    filters: [
                        self.filtrocapcomune
                    ]
                }            
                return angular.extend({}, bodyRead , data);
            }


            gridcfg.dataBound = function (e) {
                if (typeof self.gridComuni !== 'undefined') {
                    //console.log("databound", self.gridComuni);
                    self.gridComuni.select(e.sender.tbody.find("tr:first"))
                    e.sender.tbody.find("tr:first").focus()
                    var griglia = e.sender;

                    /**
                     * @issue 
                     *  keydown keypress keyup ignorati, funziona solo dblclick
                     */
                    griglia.element.on("dblclick", "tbody tr[data-uid]", function (e) {
                        selezionaDaGriglia(e, griglia)
                    })

                }
            }
            self.gridComuniOpts = angular.extend({}, gridcfg);
            self.gridComuniRebind = moment().format();
            //return ds.read();
            return self.$q.resolve(self.gridComuniOpts)
        })
    });
}

function resetfiltrocapcomune(){
    var self = this;

    if(!self.nou(self.filtrocapcomune)){//se è attivo filtro per comune
        var ds = bssRecursiveGetter(self, "gridComuni.dataSource", null);
        if(ds !== null){//se è definito il datasource della griglia
            var filter = ds.filter();
            if(!self.nou(filter)){//se è definito filter per il datasource della griglia
                filter.filters.filter(function(filtro){//escludo dai filtri il filtro per cap comune
                    return filtro.identificativo !== self.filtrocapcomune.identificativo
                });
                self.gridComuni.dataSource.filter(filter);
                self.gridComuni.dataSource.read();
            }
        }
        self.filtrocapcomune = null;
        self.gridComuni.dataSource.read();
    }
    
}

/**
 * ===================================================================== Fine Logica Nuovo indirizzo =======================================================================================================
 */




module.exports = DocumentistdCtrl;


/**
 * @unused
 * @description 
 * viene eseguito quando la condizione ng-if per anagrafica ha successo
 */
function renderAnagrafica() {
    var self = this;
    /**
     * @issue dovrei forzare il focus sul campo anagrafica, ma non va
     * @prove
     *  timeout 
     *  angular.element
     *  querySelector
     *  getElementById
     *  $window
     *  ng-focus (ma non serve a fare il focus ma a fornire un handler all'evento focus)
     *  ng-if / ng-show
     */
    if (self.mostraanagrafica) {
        var el = self.$window.document.getElementById("anagrafica");
        console.log("RENDER ANAGRAFICA", self.mostraanagrafica, el);
        el.focus();
    }
}
