'use strict';

const moment = require("moment");

require("../styles/listacodici.scss");

/**
 * 
 * @example
 * <listacodicidir /> 
 * 
 */
function ListacodiciDir(){
    return {
        restrict: 'E',
        template: require("./templates/listacodicidir.html"),
        scope: {

        },
        controller: ["$sce", "$http", "$q", "$timeout", "$scope", "ErrorManager", "Valida", "FieldFactory", 
            function($sce, $http, $q, $timeout, $scope, ErrorManager, Valida, FieldFactory){      
            var self = this;
            
            self.sce = $sce;
            self.$http = $http;
            self.$q = $q;
            self.$timeout = $timeout;                                                
            self.valida = Valida;
            self.ff = FieldFactory;
            self.em = ErrorManager;
                        

            self.listacodici = function(){
                var self = this;                                                      

            
                if(self.valida.validaForm(self.formObj)){

                    var da = null, a = null;
                
                    if(moment(self.filtralistacodici.da).isAfter(moment(self.filtralistacodici.a))){
                        a = self.filtralistacodici.da;
                        da = self.filtralistacodici.a;                    
                    } else {
                        da = self.filtralistacodici.da;
                        a = self.filtralistacodici.a;
                    }

                    var dajsonstr = moment(da).toISOString(true);
                    var ajsonstr = moment(a).toISOString(true);

                    var parametri = angular.extend({}, {
                        da: dajsonstr,
                        a: ajsonstr
                    })

                    self.$http({
                        method: "POST",
                        url: utils.urls.wsbase + "/documentirdc/stampalistacodici",
                        data: $.param(parametri),
                        headers: {type: 'application/octet-stream'},
                        //responseType: 'arraybuffer'
                    }).then(function(resp){
                        //self.file = new Blob([resp.data], { type: 'text/csv' });
                        //self.urlFile = self.sce.trustAsResourceUrl(URL.createObjectURL(self.file));
                        self.em.show("File della lista codici generato", "success")                
                        //self.labelPDF = "Lista codici da " + moment(da).format("DD-MM-YYYY") + " a " + moment(a).format("DD-MM-YYYY") 
                        //saveAs(self.file, tipo+'.pdf');
                        // self.$timeout(function(){
                        //     angular.element("#pdfpreviewer").attr("src", self.urlFile);
                        // })
                    }, function(err){
                        self.em.errorCB(err);
                    })
                }
            }
            
            
            
            self.scarica = function() {
                var self = this;
                saveAs(self.file, 'incassi_' + self.tipoFile + '.pdf');
            }


            

            self.campi = [
                new self.ff({
                    "key": "da",
                    "tipo": "bssdatetime", 
                    "label": "Dalla Data",
                    "colonna": {},
                    "modello": {validations: {required: true},}
                },
                false,
                {
                    "className": "col-lg-6",                                
                    //defaultValue: moment().subtract(1, 'd').startOf("day").toDate()
                    defaultValue: moment().startOf("day").toDate()
                }).opzioni,
                new self.ff({
                    "key": "a",
                    "tipo": "bssdatetime", 
                    "label": "Alla Data",
                    "colonna": {},
                    "modello": {validations: {required: true},}
                },
                false,
                {
                    "className": "col-lg-6",            
                    defaultValue: moment().toDate()                    
                }).opzioni,
                
            ]

            
            
        }],
        controllerAs: "listacodicidirCtrl"
    };
}

module.exports = ListacodiciDir;