"use strict";

require("moduleFold/autentica/scripts/templates/logoutWin.html");
require("moduleFold/autentica/scripts/templates/cambiaPassWin.html");
require("incomuneFold/mainstyle.scss");
//template standard
require("defaultTemplatesFold/contenuto.header.html");
require("defaultTemplatesFold/contenuto.html");
require("defaultTemplatesFold/contenuto.maincontent.html");
require("defaultTemplatesFold/contenuto.sidebar.html");
require("defaultTemplatesFold/login.html");
                
function MainCtrl($rootScope, $state, $stateParams, $timeout, $http, WinBuilder, ErrorManager, LoginSrv, DSF, GF, FormDaGrid, SideMenuSrv, StatoApp, TokenRTService, $window, $translate, ToolbarSrv){
    this.prova = 'solo una prova';
    this.win1opts = new WinBuilder({title: "prova"}).opzioni;
    this.em = ErrorManager;
    this.state = $state;
    this.$sp = $stateParams;
    this.ls = LoginSrv;
    this.sm = SideMenuSrv;
    this.$http = $http;
    this.statoapp = StatoApp;
    this.$translate = $translate;
    this.ts = ToolbarSrv;

    this.utils = utils;
    
    this.inFrame = function(){
        return this.$sp.view && this.$sp.view === 'iframe';
    }
    
    var self = this;
    
    
    this.loadImage = function(image){
        return require("assetsPathClientFold/" + image);
    };

    $rootScope.$on("kendoWidgetCreated", function(event, widget){
        //console.log("widget kendo appena creato: ", widget);  

        if(widget.element.hasClass("k-grid")){
            $timeout(function(){
                resizeGrid();
            })            
        }
    })
    
    
}

MainCtrl.prototype.aprifinestra = aprifinestra;

function aprifinestra(){
    this.win1.open().center();
}

module.exports = MainCtrl;