'use strict';

function N2WFilter($http, $q){
    var cached = {};    
    function filtra(input, motivo){
        input = typeof input === 'undefined' ? '0.0' : ''+input;
        if (input in cached) {
            // avoid returning a promise!
            return typeof cached[input].then !== 'function' ? cached[input] : undefined;
        } else {
            cached[input] = $http({
                method: 'GET',
                url: "http://bssec.svi/testnw/"+input
            }).then(function(resp){
                console.log(input, "convertito in ", resp, motivo)
                cached[input] = resp.data;
            })

        }
    }
    
    filtra.$stateful = true;
    return filtra;
}

module.exports = N2WFilter;