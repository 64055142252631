'use strict';

//k-content-urls="['src/bsslib/permessi/scripts/templates/tabgruppi.html', 'src/bsslib/permessi/scripts/templates/tabutenti.html']"
require("moduleFold/permessi/scripts/templates/tabgruppi.html");
require("moduleFold/permessi/scripts/templates/tabutenti.html");
require("moduleFold/permessi/scripts/templates/tabmoduli.html");
require("moduleFold/permessi/scripts/templates/tabmenu.html");
require("moduleFold/permessi/scripts/templates/checkpermessi.html");

/**
 * Registrazione delle route per il modulo bss/permessi
 *
 * @class
 * @param {type} $stateProvider
 * @param {type} $urlRouterProvider
 * @param {type} $locationProvider
 */
function PermessiRoutes($stateProvider, $urlRouterProvider, $locationProvider){
    $stateProvider
        .state('permessi', {
            url: "/permessi",
            views: {
                "maincontent.custommodule" : {
                    template: require("../templates/maintpl.html"),
                    controller: ["$q", "$stateParams", "$http", "DSFactory", "GridFactory", "ToolbarSrv", "FieldFactory", "identifica", "$scope", "GrigliaPermessi", "MenuPermessi", "GrigliaModuliPermessi", "PermessiModuliManager", require("../PermessiCtrl")],
                    controllerAs: 'moduloCtrl',
                },
            },
            middleware: ['loggato'],
            data: "bss.permessi",
            resolve: {
                identifica: ['$state', '$stateParams', function(st, sp){
                    var self = this;
                    var tohash = self.toString()+JSON.stringify(sp);
                    var identificatore = cryptofoo.hash('whirlpool', tohash);
                    return identificatore;
                }]
            }
        })
    ;
}

module.exports = PermessiRoutes;
