//var rxjs = require("rxjs");

'use strict';

// var fromEvent = rxjs.Observable.fromEvent;
// var filter = rxjs.operators.filter;
// var map = rxjs.operators.map;
// var tap = rxjs.operators.tap;
// var throttleTime = rxjs.operators.throttleTime;


function NavigaLiDir($timeout){
    return {
        link: function (scope, element, attributes, ngModel) {
            
            // const focusNav = (verso) => {
            //     //console.log(document.activeElement);
            //     const focused = element[0];
            //     const selector = "[focusselector='"+focused.getAttribute("focusselector")+"']";
            //     const attrverso = verso > 0 ? "nextSibling" : "previousSibling";
            //     let tofocus = null;

            //     do{
            //         tofocus = focused[attrverso];
            //         const matcher = tofocus.matches;
            //         if(matcher !== null && matcher !== undefined && matcher(selector)) break;
            //         tofocus = tofocus[attrverso];
            //     } while(tofocus);

            //     if(tofocus === null){                
            //       const otherfocusables = document.querySelectorAll(
            //         selector
            //       );
            //       if (verso > 0) {
            //         tofocus = otherfocusables[0];
            //       } else {
            //         tofocus = otherfocusables[otherfocusables.length - 1];
            //       }
            //     }

            //     console.log("faccio il focus", focused, tofocus);
            //     tofocus.focus();
            // };


            // fromEvent(element, "keyup")
            //     .pipe(
            //         throttleTime(100),
            //         tap(function(ev){
            //             ev.preventDefault();
            //             ev.stopImmediatePropagation();
            //             ev.stopPropagation();
            //         }),
            //         filter(function(ev) {
            //             console.log(ev);
            //             return [
            //                 "ArrowDown",
            //                 "ArrowUp",
            //                 "ArrowLeft",
            //                 "ArrowRight",
            //                 "Enter"
            //                 ]
            //             .includes(ev.key);
            //         }),
            //         map(function(ev) {
            //             return { key: ev.key, shiftKey: ev.shiftKey }
            //         }),
            //         tap(function(ev){
            //             console.log("keyup============================>", ev)
            //         })
            // )
            // .subscribe(function(ev){
            //     var key = ev.key;
            //     var shiftKey = ev.shiftKey;

            //     if (key === "Enter") {
            //         alert(document.activeElement.innerHTML);
            //     }
            
            //     var verso = null;
            //     if (["ArrowDown", "ArrowRight"].includes(key)) {
            //         verso = 1;
            //     }
            //     if (["ArrowUp", "ArrowLeft"].includes(key)) {
            //         verso = -1;
            //     }
            
            //     if (verso !== null) {
            //         focusNav(verso);
            //     }
            // })
        }
    }
}

module.exports = NavigaLiDir;
