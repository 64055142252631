"use strict";

/**
 *
 * Configurazione di default di ui-notification 
 * 
 * @class   
 * @param {NotificationProvider} NotificationProvider
 */
function NotifierCfg(NotificationProvider){
    NotificationProvider.setOptions({
        delay: 100000,
        startTop: 60,
        startRight: 60,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'right',
        positionY: 'top',
        maxCount: 5,
    });
}

module.exports = NotifierCfg;