'use strict';

require("moduleFold/autentica/scripts/index.js");

/**
 * @module bss/middleware
 * @description Fornisce i middleware di autorizzazione di base
 * 
 */
var bssmiddleware = angular.module("bss.middleware", ['ui.router', 'ui.router.middleware', 'bss.autentica'])
    .config(["$middlewareProvider", require("./cfgs/MiddlewareCfg")])
;

module.exports = bssmiddleware;