'use strict';

var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");
var ConfrontaMixin = require("moduleFold/mixins/ConfrontaMixin");
var ModuloInitializerMixin = require("moduleFold/modulobase/scripts/mixins/ModuleInitializerMixin");

/**
 * Controller relativo al template della route /gruppiutenti
 *
 * @param {type} $http
 * @param {type} $q
 * @param {type} $timeout
 * @param {type} $stateParams
 * @param {DataSourceFactory} DSF
 * @param {GridFactory} GF
 * @param {type} ToolbarS
 * @param {type} idstato
 * @param {type} $scope
 * @param {type} GruppiutentiFunzioniAggiuntive
 */
function GruppiutentiCtrl($http, $q, $timeout, $state, $stateParams, DSF, GF, ToolbarS, idstato, $scope, ActionFactory, ErrorManager, GruppiutentiFunzioniAggiuntive){
    var self = this;
    self.ts = ToolbarS;
    self.ts.idAttuale = idstato;
    self.dsf = DSF;
    self.gf = GF;
    self.$http = $http;
    self.$timeout = $timeout;
    self.$q = $q;
    self.fa = GruppiutentiFunzioniAggiuntive;
    self.af = ActionFactory;
    self.sp = $stateParams;
    self.$state = $state;
    self.em = ErrorManager;
    
    self.winTitle = "Disassocia";
    
    self.g = {
        titolo: 'Gruppi',
        strentity: 'groupmanager',
        codapp: '',
        codmodulo: 'BWCGruppi',
        toStrAttr: 'name',
        campoRel: 'idgruppo',
        associati: 'utentigruppo'
    };
    
    self.u = {
        titolo: 'Utenti',
        strentity: 'usermanager',
        codapp: '',
        codmodulo: 'BWCUtenti',
        toStrAttr: 'email',
        campoRel: 'idutente',
        associati: 'gruppiutente'
    };
    
    self.e1 = {};
    self.e2 = {};
    
    if(!self.sp.da || self.sp.da === null || self.sp.da === undefined){
        self.sp.da = 'g';
    }
    if(!self.sp.a || self.sp.a === null || self.sp.a === undefined){
        self.sp.a = 'u';
    }
    
    angular.extend(self.e1, self[self.sp.da]);    
    angular.extend(self.e2, self[self.sp.a]);
        
    self.ass = {
        titolo: 'Associazione tra Gruppi e Utenti',
        strentity: '',
        codapp: '',
        codmodulo: ''
    };
        
    self.inizializza();

    /* decommenta per popolare griglia toolbar e form del modulo base
    //attualmente strentity codapp codmodulo selezione mode
    self.parametri = $stateParams;

    angular.extend(this, EsponiMixin.prototype, ConfrontaMixin.prototype, ModuloInitializerMixin.prototype);
    
    //si occupa di istanziare datasource, griglia, di fare la prima lettura e di selezionare l'entity e attivare la modalità specificata
    self.inizializza();
    */
}

GruppiutentiCtrl.prototype.inizializza = inizializza;
GruppiutentiCtrl.prototype.inizializzaGriglia = inizializzaGriglia;
GruppiutentiCtrl.prototype.scambia = scambia;
GruppiutentiCtrl.prototype.associa = associa;
GruppiutentiCtrl.prototype.getAltraEntita = getAltraEntita;
GruppiutentiCtrl.prototype.disassocia = disassocia;

function inizializza(){
    this.inizializzaGriglia('e1');
    this.inizializzaGriglia('e2');
}

function disassocia(obj, idArrivo){
    var self = this;
    var parametri = {};
    parametri[obj.campoPartenza] = obj.idPartenza;
    parametri[obj.campoArrivo] = idArrivo;
    console.log("per la disassociazione passo: ", parametri);
    self.$http({
        url: utils.urls.wsbase + "/groupmembershipmanager/disassocia",
        method: "POST",
        data: $.param(parametri)
    }).then(function(resp){
        self.em.show("Disassociazione avvenuta", success);
    });
}
 
function scambia(){
    var self = this;
    self.$state.go(self.$state.current.name, {da: self.sp.a, a: self.sp.da});
}

function inizializzaGriglia(entita){
    var self = this;
    
    return new self.dsf(self[entita].strentity, self[entita].codapp, self[entita].codmodulo).then(function(ds){
        self[entita].ds = new kendo.data.DataSource(ds);
        return new self.gf(self[entita].strentity, self[entita].codapp, self[entita].codmodulo, {
                dataSource: self[entita].ds,
                selectable: 'row',
                change: function(){
                    //self[associata].grid.clearSelection();
                    self[entita].selezione = self[entita].grid.dataItem(self[entita].grid.select());
                    if(entita === 'e1'){//se ho selezionato l'entità di partenza
                    }
                },
                dataBound: function(e){
                    var griglia = e.sender;
                    var righe = griglia.tbody.find("tr");                   
                    righe.dblclick(function(e){ 
                        console.log("doppio click entita: ", entita);
                        var dataItem = griglia.dataItem(e.currentTarget);
                        var parametri = {};
                        parametri[self[entita].campoRel] = dataItem.id;
                        self.$http({
                            url: utils.urls.wsbase + "/groupmembershipmanager/" + self[entita].associati,
                            method: "POST",
                            data: $.param(parametri)
                        }).then(function(resp){
                            console.log("doppio click entita dentro then: ", entita, self[self.getAltraEntita(entita)].titolo);
                            //console.log("associazioni relative a", dataItem, ": ", resp.data);
                            self.associazioni = resp.data;
                            self.campoDaMostrare = self[self.getAltraEntita(entita)].toStrAttr;
                            self.idPartenza = dataItem.id;
                            self.campoPartenza = self[entita].campoRel;
                            self.campoArrivo = self[self.getAltraEntita(entita)].campoRel;
                            self.objDis = {campoPartenza:  self.campoPartenza, idPartenza: dataItem.id, campoArrivo: self.campoArrivo};
                            
                            self.winTitle = self[self.getAltraEntita(entita)].titolo + " associati a " + dataItem[self[entita].toStrAttr];
                            self.winDis.setOptions({title: self.winTitle});
                            self.winDis.open().center();
                            
                        });
                    });
                }
            }).then(function(gridcfg){
            self[entita].opts = gridcfg;
                      
            return self[entita].ds.read().then(function(){
                return self.$timeout(function(){});
            });
            
            
        });       
    });
}

function getAltraEntita(entita){
    return entita === 'e1' ? 'e2' : 'e1';
}

function associa(){
    var self = this;
    
    var parametri = {};
    
    parametri[self.e1.campoRel] = self.e1.selezione[self.e1.ds.options.schema.model.id];
    parametri[self.e2.campoRel] = self.e2.selezione[self.e2.ds.options.schema.model.id];
    
    return self.$http({
        url: utils.urls.wsbase + "/groupmembershipmanager/crea",
        method: "POST",
        data: $.param(parametri)
    }).then(function(resp){
        self.em.showT("GRUPPIUTENTI.SUCCESSASSOCIAZIONE", "success");
        console.log("successo?!?!??!?!? ", resp);
        return resp;
    });
}

module.exports = GruppiutentiCtrl;
