'use strict';

function GrigliaModuliPermessi($q, $timeout, $stateParams, DSCfgFactory, GridCfgFactory, ToolbarSrv){
    function Costruttore(dataItem){
        this.strentity = dataItem.loginName ? "vlpumodulimanager" : "vlpgmodulimanager";
        var prefixgrid = '/getpermessimoduli';
        this.gridurl = dataItem.loginName ? prefixgrid + 'utente' : prefixgrid + 'gruppo';
        this.sp = $stateParams;
        this.dsf = DSCfgFactory;
        this.gf = GridCfgFactory;
        this.ts = ToolbarSrv;
        this.livello = dataItem.loginName ? 'idutente' : 'idgruppo';
        this.idlivello = dataItem.id;
    }
    
    Costruttore.prototype.inizializza = inizializza;
    
    function inizializza(){
        var self = this;
        
        return new self.dsf(self.strentity, 'a', 'a', null, true).inizializza().then(function(ds){
            ds.transport = {
                read: {
                    method: 'POST',
                    url: ds.baseUrl + self.gridurl,
                    data: {}
                }
            };
            ds.transport.read.data[self.livello] = self.idlivello;
            self.ds = new kendo.data.DataSource(ds);
            return new self.gf(self.strentity, self.codapp, self.codmodulo, {dataSource: self.ds}).then(function(gridcfg){
                self.gridCfg = gridcfg;
                //self.gridCfg.dataSource = self.ds;
                self.ds.read();
                return self.gridCfg;                
            });
        });
    }
    
    
    return Costruttore;
}


module.exports = GrigliaModuliPermessi;