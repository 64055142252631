'use strict';

require("moduleFold/middleware/scripts/index.js");
require("moduleFold/notifiche/scripts/index.js");
require("moduleFold/datasource/scripts/index.js");
require("moduleFold/grid/scripts/index.js");
require("moduleFold/formbuilder/scripts/index.js");
require("moduleFold/toolbar/scripts/index.js");
require("bssricambiwebFold/incassi/styles/incassi.scss");

var bssincassi = angular.module("bss.bssricambiweb.incassi", ["bss.datasource", "bss.griglia", "bss.middleware", "bss.traduzioni", "bss.notifiche", "bss.toolbar"])
    .service("IncassiFunzioniAggiuntive", ['ToolbarSrv', 'ActionFactory', '$http', '$q', '$timeout', "$translate", "$state", "ErrorManager", require("./IncassiFunzioniAggiuntive")])
    .config(["$stateProvider", "$urlRouterProvider", "$locationProvider", require("./routes/IncassiRoutes")])
    .directive("incassidir", [require("./Incassi")])
    .directive('embedSrc', function () {
        return {
          restrict: 'A',
          link: function (scope, element, attrs) {
            var current = element;
            scope.$watch(function() { return attrs.embedSrc; }, function () {
              var clone = element
                            .clone()
                            .attr('src', attrs.embedSrc);
              current.replaceWith(clone);
              current = clone;
            });
          }
        };
      })
;

module.exports = bssincassi;
