"use strict";

/**
 * @module bss/finestre
 * @description Fornisce delle facilitazioni per generare le opzioni di kendowindow
 */
var bssfinestre = angular.module("bss.finestre", ["kendo.directives"])
    .factory("FinestreCfgFactory", [require("./FinestreCfgFactory")])
;

module.exports = bssfinestre;