'use strict';

/**
 * Controller del campo combo
 * 
 * @class
 * @param {type} $scope
 * @param {type} $filter
 * @param {type} $timeout
 * @param {type} $http
 * @param {type} ErrorManager
 * @param {type} DSF
 * @returns {undefined}
 */
function BSSComboController($scope, $filter, $timeout, $http, ErrorManager, DSF){
    
    $scope.dsf = DSF;
    
    var modello = $scope.options.data.modello;
    
    var eccezione = _.some(["strentity", "codmodulo", "datatextfield", "datavaluefield"], function(el){
        return (typeof modello[el] === 'undefined');
    });
    
    if(eccezione){
        //var messaggio = "Il campo '" + $scope.to.label + "' è di tipo combo, il modello dovrebbe avere: 'strentity', 'codmodulo', 'datatextfield', 'datavaluefield'. Vedi console per ulteriori dettagli" 
        //ErrorManager.show(messaggio, "error");
        var messaggioConsole = "Configurazione errata: " + JSON.stringify(modello);
        ErrorManager.showT("FORMBUILDER.COMBOCFGERROR", "error", {campo: $scope.to.label});
        throw messaggioConsole;
       
    }
    
    $scope.combo = {
        strentity: modello.strentity,
        codmodulo: modello.codmodulo,
        codapp: modello.codapp || 'aaa',
        dataTextField: modello.datatextfield,
        dataValueField: modello.datavaluefield,
        comboTemplate: modello.combotemplate || modello.datatextfield,
        caricamento: true,
        opzioniagg: modello.opzioniagg || {},
        onSelect: modello.onSelect || function(){},
        //se true aggiunge la voce di default 'tutti' NON FUNZIONA
        //voceTutti: modello.voceTutti
    }; 
    
    new DSF($scope.combo.strentity, $scope.combo.codapp, $scope.combo.codmodulo, $scope.combo.opzioniagg).inizializza().then(function(kendoDS){
//        if($scope.combo.voceTutti){
//            kendoDS.bind('requestEnd', function(e){
//                if(e.type === 'read'){
//                    console.log("datasource: ", kendoDS.add);
//                    kendoDS.add($scope.combo.voceTutti);
//                }
//            });
//        }
        
        //aggiunge ai filtri il filtro per id del valroe
        if($scope.model[$scope.options.key] && ($scope.model[$scope.options.key].id !== undefined)){
            
            $scope.filtroBase = {
                field: "id", operator: 'eq', value: $scope.model[$scope.options.key].id                
            };
            
            kendoDS.filter($scope.filtroBase);
            
        }
        

        
        
        $scope.combo.opzioni = {
            template: "#="+$scope.combo.comboTemplate+"#",
            dataTextField: $scope.combo.dataTextField,
            dataValueField: $scope.combo.dataValueField,
            filter: "contains",
            autoBind: true,
            //minLength: 3,
            dataSource: kendoDS,   
            voceTutti: $scope.combo.voceTutti,
            change : function (e) {
                console.log("change combobox ", this.value(), this.selectedIndex);
                if (this.value() && this.selectedIndex == -1) {    
                    alert("Seleziona un'opzione valida");
                    this._filterSource({
                        value: "",
                        field: this.options.dataTextField,
                        operator: "contains"
                    });
                    this.value("");
                }
            }
        };
        
        if($scope.combo.onSelect !== undefined){
            $scope.combo.opzioni.select = function(e){
                $scope.combo.onSelect(e);
            }
        }
        
        
        /*
         * svuota filtri all'apertura
         */
        $scope.combo.opzioni.open = function(e){        
            $scope.combo.opzioni.dataSource.filter([]);
        }
        
        
        $scope.combo.caricamento = false;
    });
    
    
}

module.exports = BSSComboController;